import styled from 'styled-components';
import { Card } from '../../shared/card';
import { LayoutColumnContainer } from '../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../styles/device';

export const Container = styled(Card)`
  max-width: 600px;
  margin: 30px 10px;

  @media ${device.tabletL} {
    margin: 30px auto;
  }
`;

export const Header = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 14px 14px 0 0;
  padding: 5px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const Form = styled.form`
  padding: 15px;
  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto;

    @media ${device.tablet} {
      grid-template-columns: 160px auto;
    }
  }
`;

export const AddSupplier = styled.button`
  color: ${({ theme }) => theme.colors.secondary.default};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  text-align: right;
  padding: 5px 14px;
  display: flex;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-right: -10px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.secondary.default};
  }
`;
