import { RecipeFormItem } from '../../components/shared/recipe/recipe.types';
import {
  IRecipeVersionData,
  IVenueData,
} from '../clientServerShared/sharedTypes';
import { getTotalTimeSeconds } from './units';

/**
 * Converts the format of the recipe form items into something we can put into our profit calculator
 */
export function convertFormItemsToRecipeVersion(
  items: RecipeFormItem[],
  venue: IVenueData & { id: string }
): Pick<
  IRecipeVersionData,
  | 'recipeTimeItems'
  | 'totalTime'
  | 'totalStaffTime'
  | 'recipeIngredients'
  | 'recipeAsIngredients'
> {
  // filter out deleted items
  const filteredItems = items.filter((item) => {
    for (const deletedItem of items) {
      if (deletedItem.type !== 'DELETE_ITEM') continue;
      if (deletedItem.recipeItemId === item.recipeItemId) return false;
    }
    return true;
  });

  // get our organised list of items

  const recipeTimeItems = getRecipeTimeItemsFromFormState(filteredItems);
  const recipeAsIngredientItems = getRecipeAsIngredientsFromFormState(
    filteredItems,
    venue
  );
  const recipeIngredientItems = getRecipeIngredientsFromFormState(
    filteredItems,
    venue
  );

  const totalTime = getSumOfArrayObjectKey(recipeTimeItems, 'quantity');
  const totalStaffTime = getSumOfArrayObjectKey(
    recipeTimeItems.filter((t) => t.staffTime),
    'quantity'
  );

  // We get totalGrams in recipeData after this step is complete
  return {
    recipeTimeItems,
    totalTime,
    totalStaffTime,
    recipeAsIngredients: recipeAsIngredientItems,
    recipeIngredients: recipeIngredientItems,
  };
}

function getRecipeIngredientsFromFormState(
  items: RecipeFormItem[],
  venue: IVenueData & { id: string }
): IRecipeVersionData['recipeIngredients'] {
  const result: IRecipeVersionData['recipeIngredients'] = [];

  let i = 0;
  for (const item of items) {
    if (item.type === 'INGREDIENT') {
      const ingredient = venue.ingredientProducts.find(
        (ing) => ing.ingredient.id === item.ingredient?.value
      );

      result.push({
        quantity: Number(item.quantity),
        unit: item.unit,
        ingredient: {
          id: item.ingredient?.value || '',
          averageCost100g: ingredient?.ingredient.averageCost100g || 0,
          averageWastagePercentage:
            ingredient?.ingredient.averageWastagePercentage || 0,
          displayName: item.ingredient?.label || '',
          metrics: item.metrics || ingredient?.ingredient.metrics || [],
        },
        order: i,
      });
    }

    i++;
  }

  return result;
}

function getRecipeAsIngredientsFromFormState(
  items: RecipeFormItem[],
  venue: IVenueData & { id: string }
): IRecipeVersionData['recipeAsIngredients'] {
  const result: IRecipeVersionData['recipeAsIngredients'] = [];

  let i = 0;
  for (const item of items) {
    if (item.type === 'RECIPE') {
      const recipe = venue.recipes.find(
        (venueRecipe) => venueRecipe.id === item.ingredient?.value
      );

      if (!recipe) {
        continue;
      }

      result.push({
        order: i,
        quantity: Number(item.quantity),
        unit: item.unit,
        recipe: {
          id: recipe.id,
          recipeProfit: recipe.recipeProfit,
          recipeRevenue: recipe.recipeRevenue,
          serves: recipe.serves,
          totalGrams: recipe.totalGrams,
        },
      });
    }

    i++;
  }

  return result;
}

function getRecipeTimeItemsFromFormState(
  items: RecipeFormItem[]
): IRecipeVersionData['recipeTimeItems'] {
  const result: IRecipeVersionData['recipeTimeItems'] = [];

  for (const item of items) {
    if (item.type === 'PROCESS_TIME' || item.type === 'STAFF_TIME') {
      result.push({
        quantity: getTotalTimeSeconds(item.unit, Number(item.quantity)),
        unit: item.unit,
        staffTime: item.type === 'STAFF_TIME',
      });
    }
  }

  return result;
}

function getSumOfArrayObjectKey(array: any[], key: string): number {
  return array.reduce(function (a, b) {
    return a + b[key];
  }, 0);
}
