export const sortArrayObjects = (array, sortValue) => {
  return array.sort((a, b) => {
    return a[sortValue] > b[sortValue];
  });
};

export const sortArrayOfObjects = (key, order?: string) => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = a[key];
    const varB = b[key];
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};
