import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { H3, H4 } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div`
  .title {
    margin-bottom: 20px;
  }

  .CTA {
    width: 250px;
    margin-top: 30px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 90px 0;

  .heading {
    color: ${({ theme }) => theme.colors.text.faded};
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.heading};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.default};
    margin-bottom: 30px;
  }

  ${H4} {
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.faded};
  }

  @media ${device.tablet} {
    align-items: flex-start;
    text-align: left;
  }
`;
