import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const GlutenFree: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2325 5110 c-513 -52 -979 -242 -1376 -562 -154 -123 -176 -162 -124
-213 40 -41 64 -33 173 58 471 396 1048 597 1647 574 192 -8 324 -27 505 -71
367 -91 733 -285 1008 -533 l53 -48 -558 -557 c-453 -453 -563 -558 -585 -558
-42 0 -198 -49 -259 -81 -31 -17 -82 -53 -113 -81 l-56 -50 0 103 0 102 69 72
c136 144 205 259 242 405 58 227 -47 482 -287 698 -96 87 -114 86 -217 -8
-146 -135 -252 -304 -283 -452 -44 -204 38 -422 236 -632 l80 -84 0 -102 0
-102 -56 51 c-127 112 -302 172 -514 174 -229 3 -234 -1 -234 -202 1 -355 128
-585 381 -691 39 -16 78 -30 87 -30 30 0 24 -18 -20 -61 l-44 -44 -176 0 -176
0 -23 -27 c-19 -22 -24 -43 -29 -115 -4 -48 -2 -124 3 -168 l10 -80 -442 -443
-442 -443 -48 53 c-246 272 -440 638 -532 1003 -55 219 -69 342 -69 595 0 255
14 379 69 596 75 295 200 561 384 818 86 121 91 150 29 182 -44 23 -72 11
-121 -54 -260 -337 -437 -771 -498 -1222 -17 -126 -18 -517 0 -640 105 -763
511 -1409 1146 -1826 840 -552 1956 -550 2797 4 1266 835 1539 2571 591 3747
-409 507 -975 830 -1633 930 -125 19 -468 27 -595 15z m322 -940 c199 -231
222 -427 74 -652 -82 -126 -81 -128 -81 78 0 231 -13 273 -80 273 -67 0 -80
-42 -80 -273 0 -206 1 -204 -81 -78 -118 179 -127 329 -33 502 52 95 177 244
198 237 6 -2 43 -41 83 -87z m1833 -155 c194 -253 343 -566 419 -875 52 -217
65 -331 65 -580 0 -253 -14 -376 -69 -595 -213 -846 -894 -1527 -1740 -1740
-219 -55 -342 -69 -595 -69 -250 0 -365 13 -580 66 -363 89 -706 266 -993 514
l-79 67 409 409 c224 224 410 408 413 408 3 0 20 -29 38 -64 102 -196 353
-326 634 -326 l77 0 3 -249 c3 -228 5 -251 22 -270 26 -29 86 -29 112 0 17 19
19 42 22 269 l3 247 122 6 c138 6 261 37 362 90 72 38 181 141 191 180 10 42
-22 81 -69 85 -33 3 -42 -2 -84 -48 -60 -65 -176 -123 -290 -146 -143 -27
-143 -24 -6 104 136 127 153 154 129 200 -17 32 -34 42 -73 42 -20 0 -57 -30
-155 -127 l-128 -127 0 38 c0 56 29 174 60 243 76 167 256 263 503 267 l92 1
0 -75 c0 -41 4 -85 8 -96 10 -29 56 -48 88 -35 49 18 54 35 53 168 -1 188 -16
199 -254 189 -212 -8 -374 -65 -490 -173 l-60 -55 0 144 0 145 109 7 c258 16
438 97 554 248 100 133 158 367 141 577 -8 97 -21 117 -85 127 l-41 7 498 499
499 499 48 -53 c26 -29 79 -93 117 -143z m-2429 -965 c148 -27 264 -93 330
-187 44 -64 86 -186 95 -279 l7 -69 -130 128 c-118 115 -133 127 -166 127 -39
0 -77 -36 -77 -73 0 -13 49 -70 130 -152 72 -72 130 -133 130 -136 0 -9 -130
10 -189 27 -226 66 -335 225 -356 522 l-8 102 90 0 c50 0 115 -4 144 -10z
m1245 -83 c-23 -307 -129 -466 -357 -531 -59 -17 -189 -36 -189 -27 0 3 59 64
130 136 81 82 130 139 130 152 0 37 -38 73 -77 73 -33 0 -48 -12 -166 -127
l-130 -128 7 68 c21 217 127 369 301 432 95 35 143 43 259 44 l99 1 -7 -93z
m-816 -863 l0 -146 -58 53 c-32 30 -86 69 -121 88 l-63 33 58 59 58 59 63 0
63 0 0 -146z m-376 -94 c205 -52 316 -171 360 -383 9 -42 16 -91 16 -109 0
-31 -3 -29 -128 95 -106 105 -134 127 -158 127 -58 0 -97 -55 -73 -103 6 -12
65 -75 132 -140 l122 -118 -60 6 c-264 26 -417 154 -468 390 -19 87 -32 239
-22 256 11 17 180 5 279 -21z"
        />
      </g>
    </IconStyled>
  );
};

export default GlutenFree;
