import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Salad: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3053 5102 c-158 -56 -232 -170 -233 -356 0 -89 -2 -98 -17 -91 -77
31 -110 37 -176 33 -199 -13 -327 -174 -327 -410 0 -27 -2 -48 -4 -48 -2 0
-23 7 -47 16 -70 26 -174 22 -241 -8 -68 -31 -130 -86 -165 -146 -51 -87 -53
-108 -53 -787 l0 -630 -242 -242 -243 -243 55 -55 c30 -30 59 -55 65 -55 5 0
118 108 250 240 l240 240 725 0 c780 0 784 0 865 52 125 80 188 230 153 364
-8 32 -18 66 -22 75 -6 14 6 17 94 22 87 4 111 10 165 36 161 80 242 276 176
426 -26 58 -26 55 2 55 41 0 141 56 185 104 66 72 87 125 87 226 0 71 -4 94
-26 140 -31 66 -92 131 -151 161 -24 12 -45 23 -47 25 -2 2 25 34 61 71 132
138 177 255 157 412 -18 146 -103 270 -231 335 -192 97 -377 63 -550 -102 -43
-41 -79 -73 -81 -71 -1 2 -11 22 -22 43 -72 140 -260 219 -402 168z m192 -183
c53 -36 85 -108 85 -191 0 -60 3 -72 25 -93 17 -18 35 -25 61 -25 33 0 46 10
171 140 163 168 208 195 318 194 156 -1 266 -114 267 -271 1 -105 -22 -144
-189 -314 -123 -126 -143 -151 -143 -178 0 -56 33 -77 128 -83 62 -5 92 -12
125 -31 135 -79 105 -272 -49 -307 -21 -5 -103 -10 -182 -10 -138 0 -144 -1
-167 -25 -31 -30 -32 -73 -4 -108 15 -20 33 -28 70 -33 111 -15 184 -113 157
-212 -13 -48 -73 -107 -123 -121 -24 -6 -133 -11 -271 -11 -226 0 -231 0 -252
-22 -11 -13 -23 -34 -26 -48 -9 -36 25 -87 63 -94 17 -3 45 -9 62 -12 17 -3
49 -22 70 -41 67 -61 75 -156 19 -225 -59 -71 -27 -68 -736 -68 l-639 0 85 85
85 85 282 0 283 0 0 85 0 85 -197 0 -198 0 170 170 170 170 237 0 238 0 0 85
0 85 -152 0 -153 0 170 170 169 170 196 2 195 3 0 85 0 85 -105 3 -104 3 179
179 180 180 -60 60 -60 60 -182 -182 -183 -183 0 113 0 112 -85 0 -85 0 0
-197 0 -197 -170 -170 -170 -171 0 153 0 152 -85 0 -85 0 0 -237 0 -237 -167
-168 c-92 -92 -171 -168 -175 -168 -4 0 -8 86 -8 190 l0 190 -85 0 -85 0 0
-282 0 -283 -77 -77 c-43 -43 -81 -78 -85 -78 -12 0 -10 1058 2 1102 29 104
147 161 238 114 38 -20 80 -70 87 -104 23 -110 48 -139 109 -128 60 11 61 15
66 290 5 249 5 251 32 296 86 146 287 104 313 -66 13 -88 92 -125 146 -68 24
25 24 27 29 287 5 257 5 263 29 296 51 70 154 89 226 40z"
        />
        <path d="M1880 4945 l0 -86 83 3 82 3 3 83 3 82 -86 0 -85 0 0 -85z" />
        <path
          d="M644 4919 c-123 -16 -232 -96 -291 -217 -36 -72 -38 -81 -37 -167 1
-150 53 -243 192 -345 l59 -44 11 -84 c36 -303 334 -475 604 -349 140 65 226
196 244 370 3 37 10 67 14 67 21 0 140 125 168 178 150 282 -96 622 -409 564
-60 -11 -147 -49 -181 -79 -16 -14 -22 -12 -67 21 -97 71 -197 99 -307 85z
m142 -184 c19 -8 60 -34 92 -58 105 -80 126 -84 198 -32 70 51 144 85 183 85
125 0 225 -98 224 -221 -1 -81 -28 -125 -130 -211 l-88 -74 -6 -85 c-8 -103
-15 -133 -45 -181 -61 -97 -185 -140 -296 -102 -110 37 -165 125 -175 276 l-6
89 -51 44 c-28 24 -76 63 -106 87 -67 54 -90 92 -97 161 -10 93 50 191 137
226 38 15 126 13 166 -4z"
        />
        <path
          d="M4610 4526 l0 -85 39 -11 c52 -14 90 -45 112 -94 24 -53 24 -90 -2
-143 -24 -50 -65 -82 -114 -90 l-35 -6 0 -85 0 -85 48 5 c102 10 213 93 260
196 37 82 37 194 0 273 -47 102 -138 178 -233 194 -22 4 -48 9 -57 12 -16 4
-18 -5 -18 -81z"
        />
        <path d="M0 4095 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M170 3755 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path
          d="M590 3401 c-93 -30 -174 -101 -215 -186 -22 -48 -29 -55 -63 -60
-143 -25 -225 -84 -281 -205 -21 -45 -25 -70 -25 -135 0 -98 29 -171 96 -237
63 -63 125 -90 235 -102 l93 -10 0 -141 c0 -139 0 -142 26 -166 25 -23 31 -24
209 -27 204 -4 247 5 265 53 6 15 10 84 10 153 l0 128 93 11 c107 12 171 40
230 100 101 100 128 251 68 378 -50 108 -139 175 -259 196 -59 11 -60 12 -77
55 -23 62 -96 138 -162 170 -44 22 -73 28 -137 31 -45 1 -92 -1 -106 -6z m194
-195 c44 -33 54 -51 66 -118 15 -76 40 -98 112 -98 72 0 135 -15 165 -39 91
-75 83 -211 -16 -278 -34 -23 -35 -23 -425 -23 -362 0 -394 1 -426 19 -92 48
-115 171 -49 255 33 42 75 58 168 64 104 6 124 22 135 100 8 59 49 115 101
139 47 21 127 12 169 -21z m-14 -821 l0 -85 -85 0 -85 0 0 85 0 85 85 0 85 0
0 -85z"
        />
        <path
          d="M4382 3226 c-207 -52 -383 -227 -436 -432 -20 -79 -20 -219 0 -298
42 -163 164 -312 316 -385 103 -49 183 -65 296 -59 252 15 452 174 538 428 14
40 19 84 19 165 0 122 -13 172 -72 286 -64 124 -199 238 -332 280 -83 27 -252
34 -329 15z m243 -170 c214 -57 353 -267 316 -477 -51 -294 -378 -450 -631
-302 -238 140 -283 471 -91 663 110 110 264 154 406 116z"
        />
        <path
          d="M4435 2884 c-221 -78 -218 -403 4 -479 136 -46 285 30 325 166 20 66
20 82 0 148 -40 136 -194 213 -329 165z m141 -178 c73 -62 -5 -179 -89 -134
-32 17 -46 39 -46 73 0 73 80 109 135 61z"
        />
        <path
          d="M3245 2377 c-69 -18 -104 -38 -155 -89 -49 -49 -91 -131 -98 -194
l-5 -44 87 0 c81 0 86 1 86 21 0 33 37 92 73 116 97 65 224 17 258 -97 l12
-40 84 0 85 0 -7 43 c-11 67 -53 151 -98 195 -82 80 -214 116 -322 89z"
        />
        <path d="M2390 2215 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path
          d="M53 1870 c-47 -19 -56 -51 -48 -168 29 -456 195 -835 509 -1160 270
-280 621 -459 1026 -523 124 -20 1916 -20 2040 0 405 64 756 243 1026 523 315
326 481 706 509 1164 7 108 7 111 -18 140 l-25 29 -2499 2 c-1433 1 -2507 -2
-2520 -7z m4888 -165 c3 -2 2 -40 -3 -82 -41 -391 -219 -745 -510 -1018 -258
-241 -585 -388 -947 -424 -139 -15 -1703 -15 -1842 0 -279 28 -519 114 -749
268 -396 265 -658 699 -708 1174 -5 42 -6 80 -3 82 6 6 4756 6 4762 0z"
        />
      </g>
    </IconStyled>
  );
};

export default Salad;
