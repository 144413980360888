import React, { FC } from 'react';
import { IInfoModalProps } from '../../SelectAccount';
import * as S from './Extras.styles';

interface IExtrasProps {
  setInfoModal: React.Dispatch<React.SetStateAction<IInfoModalProps>>;
}

export const Extras: FC<IExtrasProps> = ({ setInfoModal }) => {
  return (
    <S.Container>
      {/* <H3 className="heading">Extras</H3>
      {extras.map((extra, idx) => {
        return <Extra key={idx} extra={extra} setInfoModal={setInfoModal} />;
      })}
      <H4 className="footer">
        *All prices are in Australian Dollars (AUD) and exclude GST
      </H4> */}
    </S.Container>
  );
};
