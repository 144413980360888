import styled from 'styled-components/macro';
import { Span } from '../../typefaces/Typefaces.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: fle;
  background-color: ${({ theme }) => theme.colors.backgroundFaded};
  color: ${({ theme }) => theme.colors.text.grey};
  padding: 15px;
  border-radius: ${({ theme }) => theme.layout.radiusTiny};
`;

export const Icon = styled.div``;

export const Content = styled(Span)`
  margin-left: 15px;
  line-height: ${({ theme }) => theme.lineHeight.medium};
`;
