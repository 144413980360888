import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const CafeMenu: React.FC<IIconProps> = ({ color, size, faded, className }) => {
  return (
    <IconStyled viewBox="0 0 490 490" {...{ color, size, faded, className }}>
      <path
        d="M338.137,85.049V0L70.623,85.291l0,0V490h348.754V85.049H338.137z M317.449,28.316v56.733H139.503L317.449,28.316z
        M398.688,469.311H91.312V105.737h307.377V469.311z"
      />
      <polygon
        points="135.657,205.161 150.929,236.193 158.485,236.193 173.836,205.161 173.836,240.903 186.509,240.903
        186.509,184.117 169.452,184.117 154.744,215.315 140.126,184.117 122.984,184.117 122.984,240.903 135.657,240.903 	"
      />
      <polygon
        points="242.319,229.693 213.158,229.693 213.158,217.913 238.584,217.913 238.584,207.107 213.158,207.107
        213.158,195.409 241.427,195.409 241.427,184.117 200.484,184.117 200.484,240.903 242.319,240.903 	"
      />
      <polygon
        points="266.284,205.322 293.336,240.903 306.01,240.903 306.01,184.117 293.336,184.117 293.336,220.676 265.471,184.117
        253.611,184.117 253.611,240.903 266.284,240.903 	"
      />
      <path
        d="M343.534,241.471c7.093,0,12.917-2.205,17.465-6.621c4.548-4.413,6.823-10.736,6.823-18.966v-31.767h-12.674v31.36
        c0,4.548-1.038,8.138-3.126,10.762c-2.083,2.627-4.912,3.94-8.489,3.94c-3.57,0-6.414-1.313-8.529-3.94
        c-2.108-2.624-3.166-6.214-3.166-10.762v-31.36h-12.673v31.767c0,8.177,2.287,14.487,6.862,18.927
        C330.603,239.25,336.44,241.471,343.534,241.471z"
      />
      <rect x="129.219" y="285.391" width="231.158" height="20.689" />
      <rect x="129.219" y="346.674" width="231.158" height="20.689" />
      <rect x="129.219" y="407.957" width="231.158" height="20.689" />
    </IconStyled>
  );
};

export default CafeMenu;
