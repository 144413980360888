import React, { useContext } from 'react';
import { NavbarContainer, MenuToggle } from './Navbar.styles';
import { INavbarProps } from './navbar.types';
import { NavbarHeadings, NavbarActions } from '.';
import { CafeMenu } from '../icons';
import { LayoutContext } from '../../../contexts/layoutContext';

const Navbar: React.FC<INavbarProps> = ({
  heading,
  subHeading,
  actionArray,
  backButton,
  backButtonCTA,
  withLine,
  slider,
}) => {
  const { dispatch } = useContext(LayoutContext);

  const handleClick = () => {
    dispatch({ type: 'TOGGLE_MENU' });
    dispatch({ type: 'OPEN_MENU' });
  };

  return (
    <NavbarContainer withLine={withLine}>
      {!backButton && (
        <MenuToggle onClick={() => handleClick()}>
          <CafeMenu size="medium" color="grey" />
        </MenuToggle>
      )}
      <NavbarHeadings
        heading={heading}
        subHeading={subHeading}
        backButton={backButton}
        backButtonCTA={backButtonCTA}
      />
      <NavbarActions slider={slider} actionArray={actionArray} />
    </NavbarContainer>
  );
};

export default Navbar;
