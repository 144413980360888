import { StylesProvider } from '@material-ui/core';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { Route, Switch } from 'react-router-dom';
import Account from './components/pages/account';
import BuildNo from './components/pages/buildNo';
import ConfirmEmailWithToken from './components/pages/confirmEmailWithToken/ConfirmEmailWithToken';
import Dashboard from './components/pages/dashboard';
import EditRecipe from './components/pages/editRecipe';
import FormExample from './components/pages/formExample';
import GetStarted from './components/pages/getStarted';
import Homepage from './components/pages/homepage';
import Ingredients from './components/pages/ingredients';
import Stocktakes from './components/pages/stocktakes';
import Inventory from './components/pages/inventory';
import EditStocktake from './components/pages/editStocktake';
import Login from './components/pages/login';
import NotFound from './components/pages/notFound';
import Payment from './components/pages/payment';
import PaymentWrapper from './components/pages/paymentWrapper';
import PrivacyPolicy from './components/pages/privacyPolicy';
import Recipes from './components/pages/recipes';
import ResetPasswordWithToken from './components/pages/resetPasswordWithToken';
import { SelectAccount } from './components/pages/selectedAccount';
import Users from './components/pages/users';
import {
  LayoutContent,
  LayoutOverlay,
  MenuToggle,
} from './components/shared/layout';
import { LayoutGlobal } from './components/shared/layout/Layout.styles';
import {
  ExtraLayoutSlider,
  LayoutSlider,
  TopLayoutSlider,
} from './components/shared/layout/layoutSlider';
import { LogoLoading } from './components/shared/loading';
import MenuMain from './components/shared/menuMain/MenuMain';
import { MenuOverlay } from './components/shared/menuMain/menuOverlay';
import { Popup } from './components/shared/popup';
import { AuthRoute } from './components/shared/routes';
import { ToolTip } from './components/shared/toolTip';
import { initialLayoutState, LayoutContext } from './contexts/layoutContext';
import {
  MeQuery,
  useMeQuery,
  useSetSelectedVenueMutation,
} from './generated/graphql';
import { layoutReducer } from './reducers/layoutReducer';
import { theme } from './styles/theme';
import { refreshAccessToken, useAccessToken } from './utils/accessToken';
import useResizeDimensions from './utils/customHooks/useResizeDimensions';
import { isEmpty } from './utils/helper';
import ShoppingList from './components/pages/shoppingList';
import { Chatbox } from './components/shared/chatBox';
import { useActiveTimeTracker } from './utils/customHooks/useActiveTimeTracker';
import { RecipeResultsProvider } from './components/pages/recipeResults/RecipeResultsProvider';
import Results from './components/pages/results';

const App: React.FC = () => {
  const [accessToken, loading] = useAccessToken();
  const [didAuthFail, setAuthFailed] = useState(false); // we don't know to start with
  const [state, dispatch] = useReducer(layoutReducer, initialLayoutState);
  const { setInitialActiveSeconds } = useActiveTimeTracker();

  const { data, refetch, loading: meQueryLoading } = useMeQuery({
    skip: !accessToken,
  });

  const [setSelectedVenueMutation] = useSetSelectedVenueMutation();

  const {
    offline,
    menuToggle,
    desktopMenuToggle,
    selectedVenueObject,
    showRecipeMenu,
    account,
    justLoggedOut,
    newAccount,
    toolTips: { resize },
  } = state;

  const ref = useRef<HTMLDivElement>(null);
  const { width } = useResizeDimensions(ref);

  useEffect(() => {
    dispatch({ type: 'SET_APP_WIDTH', payload: width });
    if (desktopMenuToggle === false && width > theme.mQ.laptop) {
      if (accessToken) {
        dispatch({ type: 'OPEN_MENU' });
      }
    } else if (menuToggle === false) {
      dispatch({ type: 'CLOSE_MENU' });
    }
  }, [width, desktopMenuToggle, menuToggle, showRecipeMenu, accessToken]);

  useEffect(() => {
    if (loading) {
      (async () => {
        const data = await refreshAccessToken();

        if (data) {
          dispatch({
            type: 'SET_ACCOUNT',
            payload: {
              type: data.user.accountType,
              billingCycle: data.user.billingCycle,
              addOns: data.user.addOns,
            },
          });
          dispatch({
            type: 'SET_NEW_ACCOUNT',
            payload: {
              ...newAccount,
              type: data.user.accountType,
              billingCycle: data.user.billingCycle,
              addOns: data.user.addOns,
            },
          });
          dispatch({
            type: 'SET_USER_DETAILS',
            payload: {
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              email: data.user.email,
              isEmailConfirmed: data.user.isEmailConfirmed,
              phone: data.user.internationalPhone,
              position: data.user.position,
              address:
                data.user.address?.addressLookup || data.user.billingAddress,
            },
          });
          if (desktopMenuToggle === false && width > theme.mQ.laptop) {
            dispatch({ type: 'OPEN_MENU' });
          }
          setAuthFailed(false);
        } else {
          setAuthFailed(true);
        }
      })();
    }
  }, [loading, desktopMenuToggle, width, menuToggle, refetch]);

  useAccountContextSync(data, account, newAccount, dispatch);

  // If the auth token changes, refetch MeQuery
  useEffect(() => {
    if (accessToken && !loading && data?.me?.successful === false) {
      setAuthFailed(false);
      refetch();
    }
  }, [accessToken, refetch, loading, data?.me?.successful]);

  useEffect(() => {
    if (!selectedVenueObject?.id) {
      const venues = data?.me?.user?.venuesSharedWithYou;
      const venue = venues?.find((venue) => venue.selected);

      if (!isEmpty(venue)) {
        const venueData = {
          id: venue!.venue.id,
          displayName: venue!.venue.displayName,
          address: venue!.venue.address,
          email: venue!.venue.email,
          totalActiveSeconds: venue!.totalActiveSeconds,
          recipeProfitIncreasePerYear: venue!.venue.recipeProfitIncreasePerYear,
        };
        dispatch({
          type: 'SELECT_VENUE_OBJECT',
          payload: venueData,
        });
        setInitialActiveSeconds(venue!.totalActiveSeconds || 0);
      } else if (!isEmpty(venues)) {
        dispatch({
          type: 'SELECT_VENUE_OBJECT',
          payload: {
            id: venues![0].venue.id,
            displayName: venues![0].venue.displayName,
            address: venues![0].venue.address,
            email: venues![0].venue.email,
            totalActiveSeconds: venues![0].totalActiveSeconds,
            recipeProfitIncreasePerYear: venues![0].venue
              .recipeProfitIncreasePerYear,
          },
        });
        setInitialActiveSeconds(venues![0].totalActiveSeconds || 0);
        setSelectedVenueMutation({
          variables: {
            input: {
              venueId: venues![0].venue.id,
              previousVenueId: selectedVenueObject.id,
            },
          },
        });
      }
    }
  }, [selectedVenueObject?.id, data, setSelectedVenueMutation, dispatch]);

  useEffect(() => {
    if (resize) {
      dispatch({ type: 'SET__TOOL_TIP_RESIZE', payload: false });
    }
  }, [resize]);

  /**
   * Ensure 'didAuthFail' is set to true when the user logs out.
   * Without this there is a bug where the loading indicator
   * is shown indefinitely when the user logs out.
   */
  useEffect(() => {
    if (justLoggedOut) {
      setAuthFailed(true);
      dispatch({ type: 'RESET_STORE', payload: { justLoggedOut: false } });
    }
  }, [justLoggedOut, setAuthFailed, dispatch]);

  // Display a loading indicator unless (a) the user data loaded successfully,
  // (b) the user is not logged in, or (c) auth failed for some other reason.
  if (
    !offline &&
    !didAuthFail &&
    (loading || meQueryLoading || !data?.me?.successful)
  ) {
    return (
      <div ref={ref}>
        <LogoLoading size={60} />
      </div>
    );
  }

  hotjar.initialize(2235202, 6);

  return (
    <StylesProvider injectFirst>
      <LayoutContext.Provider value={{ ...state, dispatch }}>
        <LayoutGlobal
          ref={ref}
          noScroll={
            state.sliderToggle ||
            state.topSliderToggle ||
            state.extraSliderToggle
          }
        >
          <MenuMain width={width} />
          <LayoutContent width={width}>
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/sign-up" component={GetStarted} />
              <Route path="/select-account/:step?" component={SelectAccount} />
              <Route exact path="/pricing" component={SelectAccount} />
              <Route exact path="/payment" component={Payment} />
              <Route exact path="/sign-in" component={Login} />
              <Route exact path="/loading" component={LogoLoading} />
              <Route exact path="/build-no" component={BuildNo} />
              {!offline && (
                <Route exact path="/payments-test" component={PaymentWrapper} />
              )}
              <Route
                exact
                path="/reset-password"
                component={ResetPasswordWithToken}
              />
              <Route
                exact
                path="/confirm-email"
                component={ConfirmEmailWithToken}
              />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <AuthRoute exact path="/add-recipe" component={EditRecipe} />
              <AuthRoute
                exact
                path="/recipe-results"
                component={RecipeResultsProvider}
              />
              <AuthRoute exact path="/account" component={Account} />
              <AuthRoute exact path="/recipes" component={Recipes} />
              <AuthRoute exact path="/results" component={Results} />
              <AuthRoute exact path="/ingredients" component={Ingredients} />
              <AuthRoute exact path="/stocktakes" component={Stocktakes} />
              <AuthRoute exact path="/inventory" component={Inventory} />
              <AuthRoute exact path="/shopping-list" component={ShoppingList} />
              <AuthRoute
                exact
                path="/add-stocktake"
                component={EditStocktake}
              />
              <AuthRoute
                exact
                path="/edit-stocktake/:stocktakeId?"
                component={EditStocktake}
              />

              <AuthRoute exact path="/dashboard" component={Dashboard} admin />
              <AuthRoute exact path="/users" component={Users} admin />

              <Route exact path="/form" component={FormExample} />
              <Route exact path="*" component={NotFound} />

              {/* <Route exact path="/menu-items" component={MenuPage} /> */}
              {/* <Route exact path="/slider" component={Slider} /> */}
              {/* <Route exact path="/brand" component={Brand} /> */}
              {/* <Route exact path="/form" component={FormExample} /> */}
            </Switch>
          </LayoutContent>
          <MenuOverlay data={data} />
          <MenuToggle width={width} />
          <ToolTip />
          <LayoutSlider />
          <ExtraLayoutSlider />
          <TopLayoutSlider />
          <LayoutOverlay />
          <Chatbox />
          <Popup />
        </LayoutGlobal>
      </LayoutContext.Provider>
    </StylesProvider>
  );
};

export default App;
function useAccountContextSync(
  data: MeQuery | undefined,
  account: any,
  newAccount: any,
  dispatch: React.Dispatch<{ type: string; payload?: any }>
) {
  const accountTypeFromQuery = data?.me?.user?.accountType;
  const billingCycleFromQuery = data?.me?.user?.billingCycle;
  const addOnsFromQuery = data?.me?.user?.addOns;
  const isSuccessFulLogin = Boolean(data?.me?.successful);

  useEffect(() => {
    if (accountTypeFromQuery && account?.type !== accountTypeFromQuery) {
      dispatch({
        type: 'SET_ACCOUNT',
        payload: {
          type: accountTypeFromQuery,
          billingCycle: billingCycleFromQuery,
          addOns: addOnsFromQuery,
        },
      });
      if (!newAccount.awaitingPayment) {
        dispatch({
          type: 'SET_NEW_ACCOUNT',
          payload: {
            type: accountTypeFromQuery,
            billingCycle: billingCycleFromQuery,
            addOns: addOnsFromQuery,
          },
        });
      }
    } else {
      if (!isEmpty(account) && !isSuccessFulLogin) {
        dispatch({
          type: 'SET_ACCOUNT',
          payload: undefined,
        });
        dispatch({
          type: 'SET_NEW_ACCOUNT',
          payload: undefined,
        });
      }
    }
  }, [accountTypeFromQuery, account, isSuccessFulLogin]);
}
