import { useEffect, useState } from 'react';
import { RefreshTokenResponse } from '../refreshToken.types';
import { API_URL } from './constants';

export type AccessTokenState = {
  isLoading: boolean;
  token: string;
};

export type AccessTokenSubscriber = (
  oldState: AccessTokenState,
  newState: AccessTokenState
) => any;

/**
 * Holds the access token
 */
let accessTokenState: AccessTokenState = {
  isLoading: true,
  token: '',
};

/**
 * Callbacks from things that are subscribed to access token
 */
const accessTokenSubscribers = new Set<AccessTokenSubscriber>();

/**
 * Get current access token state
 */
export function getAccessTokenState() {
  return accessTokenState;
}

/**
 * Hook to subscribe to access token changes
 */
export function useAccessToken() {
  const [state, setState] = useState(accessTokenState);

  // subscribe to changes in access token state
  useEffect(() => {
    const callback: AccessTokenSubscriber = (_, newState) => {
      setState(newState);
    };

    const subscription = subscribe(callback);

    return () => subscription.unsubscribe();
  }, []);

  return [state.token, state.isLoading] as const;
}

/**
 * Update access token and notify subscribers
 */
export function setAccessToken(value: string) {
  updateAccessTokenState({ isLoading: false, token: value });
}

/**
 * Update access token and notify subscribers
 */
export function setAccessTokenIsLoading() {
  updateAccessTokenState({ isLoading: true });
}

/**
 * Refresh access token
 */
export async function refreshAccessToken() {
  setAccessTokenIsLoading();

  try {
    const response = await fetch(API_URL + '/refresh_token', {
      method: 'POST',
      credentials: 'include',
    });

    if (response.ok) {
      const data: RefreshTokenResponse = await response.json();
      setAccessToken(data.accessToken);
      return data;
    } else {
      updateAccessTokenState({ isLoading: false, token: '' });
    }
  } catch (e) {
    updateAccessTokenState({ isLoading: false, token: '' });
    console.error(e);
    return undefined;
  }

  return undefined;
}

/**
 * Notifies all our subscribers that access token state has changed
 */
function updateAccessTokenState(newStatePartial: Partial<AccessTokenState>) {
  const newState = { ...accessTokenState, ...newStatePartial };
  const oldState = accessTokenState;

  // update access token if new value is different
  if (
    newState.isLoading !== oldState.isLoading ||
    newState.token !== oldState.token
  ) {
    accessTokenState = newState;
    accessTokenSubscribers.forEach((subscriber) => {
      subscriber(oldState, newState);
    });
  }
}

/**
 * Function to create an access token subscription
 * WARNING: Always unsubscribe when done, otherwise we'll have memory leaks
 */
function subscribe(subscriber: AccessTokenSubscriber) {
  accessTokenSubscribers.add(subscriber);

  const unsubscribe = () => {
    accessTokenSubscribers.delete(subscriber);
  };

  return { unsubscribe };
}
