import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Bakery: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3668 5104 c-286 -51 -531 -242 -654 -509 -29 -63 -169 -567 -425
-1532 l-32 -122 -51 46 c-113 102 -263 183 -421 228 -112 32 -300 48 -509 43
-177 -4 -196 -11 -196 -78 0 -66 5 -67 290 -73 228 -5 265 -8 351 -30 213 -55
411 -191 530 -363 47 -69 107 -202 130 -289 30 -116 37 -306 15 -415 -19 -92
-65 -210 -118 -296 -29 -48 -42 -60 -103 -89 -38 -18 -89 -45 -112 -60 l-43
-26 0 31 c0 24 13 46 55 92 66 74 138 210 167 315 27 105 29 288 4 388 -51
196 -181 373 -350 474 -79 47 -206 89 -311 101 -114 13 -752 13 -865 0 -420
-48 -705 -413 -668 -856 12 -151 84 -313 188 -427 l50 -55 0 -574 0 -575 23
-44 c13 -24 43 -57 66 -74 l43 -30 229 -3 229 -3 16 -32 c9 -18 32 -51 51 -74
l34 -43 -395 0 c-383 0 -397 1 -416 20 -20 20 -20 33 -20 699 l0 678 -22 31
c-116 158 -162 243 -199 367 -30 101 -37 312 -14 426 35 175 116 328 244 459
158 162 346 249 581 270 118 11 140 24 140 85 0 53 -26 68 -117 66 -147 -3
-300 -43 -443 -117 -262 -135 -450 -367 -536 -659 -37 -128 -45 -357 -16 -495
33 -161 97 -302 193 -429 l39 -51 0 -667 c0 -656 0 -667 21 -710 25 -51 76
-96 128 -112 28 -8 351 -11 1168 -11 1128 0 1130 0 1157 21 36 28 36 80 0 108
-26 20 -38 21 -356 21 l-330 0 5 363 c5 389 8 416 64 555 45 113 93 184 187
277 73 73 108 99 176 133 114 55 201 75 315 70 117 -6 182 -35 259 -118 74
-81 76 -90 27 -146 -23 -26 -52 -70 -66 -99 -56 -114 -57 -125 -62 -609 -3
-246 -2 -468 2 -492 3 -26 15 -53 28 -64 20 -20 38 -20 878 -20 580 0 874 4
909 11 105 22 203 120 240 238 25 79 27 217 4 305 -72 277 -330 476 -619 476
l-82 0 -59 76 c-97 128 -247 241 -401 303 -123 50 -207 66 -343 66 -122 -1
-157 -7 -250 -49 -11 -4 -21 5 -35 31 -30 55 -125 145 -192 181 -79 42 -161
62 -251 62 -67 0 -73 2 -67 18 32 72 74 190 75 205 0 13 5 17 18 13 9 -3 80
-22 157 -41 127 -33 145 -35 194 -26 163 33 259 151 249 309 -6 113 -72 209
-172 252 -22 10 -139 44 -258 76 l-218 57 -24 53 c-13 29 -40 80 -60 114 -35
60 -43 93 -28 117 6 9 88 -9 327 -73 286 -77 327 -86 385 -82 108 8 191 63
239 160 60 118 37 251 -60 346 -54 53 -78 62 -436 157 -228 61 -293 82 -293
94 1 21 56 220 63 227 3 4 153 -33 333 -81 358 -96 373 -97 473 -54 62 26 132
99 155 161 26 66 19 176 -14 242 -34 67 -98 120 -173 145 -34 11 -183 52 -332
92 -148 39 -274 75 -279 80 -15 13 44 207 92 304 60 121 187 250 307 312 131
68 210 87 350 88 169 0 284 -33 416 -121 248 -164 384 -485 325 -764 -8 -37
-154 -588 -325 -1226 -171 -638 -311 -1171 -311 -1185 0 -13 7 -35 16 -47 20
-28 83 -31 108 -4 18 21 647 2355 667 2477 22 140 -3 325 -66 476 -64 154
-225 339 -366 422 -186 109 -410 149 -621 111z m-325 -1075 c166 -45 315 -88
330 -96 84 -43 90 -180 11 -242 -52 -41 -73 -38 -403 50 -171 45 -315 85 -320
88 -11 7 59 281 71 281 5 0 144 -37 311 -81z m-204 -829 c167 -45 314 -88 326
-96 29 -19 55 -72 55 -113 0 -80 -63 -151 -132 -151 -30 0 -639 154 -656 166
-2 1 14 65 35 143 30 109 41 140 53 136 8 -2 152 -40 319 -85z m-1176 -423
c213 -62 370 -226 429 -451 20 -79 24 -216 7 -298 -19 -92 -81 -207 -156 -288
l-68 -73 -3 -139 -4 -139 -46 -68 c-26 -37 -58 -91 -71 -119 -16 -34 -32 -54
-45 -57 -261 -54 -491 -143 -613 -236 -108 -84 -194 -234 -215 -377 l-12 -82
-196 0 c-107 0 -202 5 -210 10 -13 8 -16 90 -20 608 l-5 599 -68 73 c-75 81
-137 196 -156 288 -28 135 -4 306 61 440 44 89 169 218 253 259 136 68 173 72
670 69 346 -2 419 -5 468 -19z m1090 -436 c184 -50 210 -63 233 -118 19 -45
17 -80 -7 -131 -17 -34 -32 -49 -66 -64 -28 -12 -55 -17 -71 -14 -77 18 -268
70 -273 75 -12 11 -16 301 -5 301 6 -1 91 -22 189 -49z m630 -1025 c136 -26
258 -85 375 -179 55 -45 132 -130 132 -146 0 -3 -20 -18 -44 -34 -105 -66
-179 -186 -196 -318 -5 -40 -10 -167 -10 -281 l0 -208 -401 0 -401 0 4 433 c4
422 4 433 27 490 80 202 267 290 514 243z m-1717 -353 c-16 -51 -26 -240 -26
-511 l0 -303 -202 3 c-198 3 -204 4 -253 30 -214 113 -219 406 -11 597 56 50
188 115 326 159 153 48 174 51 166 25z m2589 -98 c164 -43 308 -182 350 -338
12 -46 16 -91 14 -159 -4 -90 -6 -99 -35 -135 -16 -21 -50 -48 -74 -61 -43
-22 -51 -22 -383 -22 l-339 0 4 248 c5 267 11 299 64 369 30 39 87 79 141 99
51 18 186 17 258 -1z"
        />
      </g>
    </IconStyled>
  );
};

export default Bakery;
