import styled from 'styled-components';
import { Card } from '../../shared/card';
import { Button } from '../../shared/button';

export const RecipeCard = styled(Card)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const CalculateButton = styled(Button)`
  margin: 35px auto;
`;
