import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const FoodCount: React.FC<IIconProps> = ({ color, size, faded, className }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
      className={className}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M1195 5082 c-96 -51 -128 -74 -217 -160 l-77 -75 -83 2 c-285 7 -558
-159 -683 -413 -25 -50 -53 -120 -62 -156 -22 -81 -24 -272 -4 -350 88 -345
384 -580 729 -580 53 0 121 5 152 12 l56 11 75 -74 c134 -134 258 -188 449
-196 139 -6 205 8 330 69 163 80 298 249 345 433 20 78 20 242 0 320 -39 154
-144 303 -273 388 -118 79 -201 106 -351 115 l-105 7 -30 52 c-67 113 -190
227 -308 284 -27 13 -48 27 -48 31 0 14 112 96 177 129 35 18 63 35 63 38 0
14 -53 151 -59 151 -3 0 -38 -17 -76 -38z m-410 -469 c-13 -38 -25 -81 -27
-97 -3 -29 -2 -30 62 -42 98 -19 95 -20 109 39 16 70 47 137 64 137 40 0 147
-69 218 -139 151 -151 209 -349 160 -543 -42 -165 -128 -281 -274 -371 -118
-72 -290 -100 -427 -68 -165 39 -315 159 -388 312 -86 181 -85 349 6 529 86
172 290 307 468 309 l52 1 -23 -67z m910 -368 c99 -28 99 -22 -21 -184 -103
-136 -108 -142 -119 -119 -8 16 -10 45 -5 91 4 38 4 101 -1 140 -4 40 -8 77
-9 83 0 14 86 8 155 -11z m276 -203 c32 -46 86 -179 76 -189 -2 -2 -75 -2
-161 -1 l-157 3 92 123 c50 67 97 121 103 119 6 -2 27 -27 47 -55z m-557 -533
c-25 -112 -46 -205 -48 -206 -13 -13 -186 114 -186 136 0 4 24 24 54 45 62 43
150 132 191 194 15 23 30 41 31 39 2 -2 -17 -95 -42 -208z m629 139 c-8 -43
-56 -146 -82 -178 l-21 -25 -23 27 c-12 14 -57 67 -100 117 l-78 91 155 0 156
0 -7 -32z m-323 -202 c89 -103 93 -109 74 -123 -28 -20 -140 -53 -207 -60 -57
-6 -58 -6 -52 18 18 79 65 292 65 297 0 8 4 3 120 -132z"
        />
        <path
          d="M526 4453 c-28 -4 -28 -6 -22 -49 14 -102 99 -219 191 -264 136 -66
294 -44 409 56 44 38 45 38 -36 106 l-49 41 -22 -21 c-56 -53 -158 -67 -227
-32 -44 23 -89 82 -99 132 -7 32 -8 33 -61 34 -30 1 -68 -1 -84 -3z"
        />
        <path
          d="M4110 5029 c-112 -15 -141 -57 -142 -212 0 -75 4 -95 27 -142 14 -30
37 -63 51 -74 13 -10 24 -21 24 -24 0 -3 -22 -31 -49 -62 -27 -31 -59 -71 -71
-89 l-23 -32 -42 41 c-59 59 -124 80 -244 78 -76 -1 -99 -5 -120 -21 -37 -27
-53 -83 -54 -192 -2 -86 1 -101 27 -153 36 -75 133 -147 198 -147 27 0 32 -23
15 -77 -27 -83 -48 -204 -54 -311 l-6 -103 84 3 84 3 7 95 c5 84 31 236 41
247 2 2 21 -4 43 -13 60 -26 159 -24 217 5 60 29 150 119 178 179 l22 44 -20
42 c-28 57 -107 142 -162 173 -25 15 -48 28 -50 30 -6 4 74 105 123 153 25 24
46 39 46 32 0 -7 18 -32 40 -56 86 -93 233 -120 339 -61 93 51 190 179 176
233 -7 29 -61 106 -94 136 -12 11 -21 22 -21 26 0 18 240 70 326 70 l44 0 0
86 0 87 -82 -8 c-157 -13 -314 -54 -466 -120 -34 -15 -64 -26 -65 -24 -49 73
-64 92 -91 112 -58 45 -148 61 -256 46z m165 -190 c42 -43 39 -60 -15 -102
-53 -41 -73 -42 -104 -8 -20 22 -31 80 -22 117 7 25 9 26 62 22 41 -4 61 -11
79 -29z m320 -200 l40 -40 -43 -41 c-40 -37 -47 -40 -88 -35 -55 6 -92 29
-100 64 -9 33 3 51 46 75 58 31 100 24 145 -23z m-833 -316 c43 -39 60 -102
38 -144 -24 -43 -139 -9 -160 47 -6 14 -10 48 -10 76 l0 51 56 -6 c33 -3 64
-13 76 -24z m334 -215 l39 -40 -44 -39 c-29 -26 -53 -39 -73 -39 -29 0 -98 24
-98 35 0 3 13 32 30 65 21 42 36 60 52 62 37 6 54 -2 94 -44z"
        />
        <path
          d="M2244 4660 c-124 -19 -204 -37 -204 -46 0 -14 34 -140 39 -146 4 -4
52 1 106 11 55 10 124 22 153 25 l52 7 0 -791 0 -790 -895 0 c-492 0 -895 3
-895 6 0 14 41 164 60 218 11 33 20 64 20 70 0 14 -141 69 -151 59 -13 -14
-66 -195 -93 -315 -29 -128 -27 -158 11 -188 25 -20 44 -20 1040 -20 l1015 0
29 29 29 29 0 897 c0 923 0 922 -39 952 -23 17 -134 14 -277 -7z"
        />
        <path
          d="M2775 4658 c-46 -26 -44 19 -45 -1196 l0 -1153 25 -24 24 -25 945 0
945 0 28 24 28 24 3 174 c7 357 -62 668 -218 984 -53 108 -171 304 -206 343
-4 4 -59 -33 -108 -72 l-29 -24 50 -74 c160 -236 256 -465 312 -744 19 -92 41
-323 41 -422 l0 -43 -840 0 -840 0 0 1023 c0 563 2 1026 6 1029 8 8 175 -29
280 -63 50 -16 96 -29 101 -29 8 0 63 127 63 145 0 17 -288 96 -434 120 -108
18 -105 18 -131 3z"
        />
        <path d="M3150 3430 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
        <path d="M3812 3348 l3 -83 83 -3 82 -3 0 86 0 85 -85 0 -86 0 3 -82z" />
        <path d="M3480 3265 l0 -85 85 0 86 0 -3 83 -3 82 -82 3 -83 3 0 -86z" />
        <path d="M3060 3095 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path
          d="M3817 3093 c-4 -3 -7 -42 -7 -85 l0 -78 85 0 85 0 0 85 0 85 -78 0
c-43 0 -82 -3 -85 -7z"
        />
        <path
          d="M4237 3093 c-4 -3 -7 -42 -7 -85 l0 -78 85 0 85 0 0 85 0 85 -78 0
c-43 0 -82 -3 -85 -7z"
        />
        <path d="M3480 2925 l0 -86 83 3 82 3 3 83 3 82 -86 0 -85 0 0 -85z" />
        <path d="M3150 2760 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
        <path d="M3812 2678 l3 -83 83 -3 82 -3 0 86 0 85 -85 0 -86 0 3 -82z" />
        <path
          d="M412 2567 c-20 -21 -22 -34 -22 -121 0 -53 5 -140 12 -192 9 -78 14
-94 27 -90 9 3 41 8 72 11 31 4 61 10 66 13 7 5 8 28 2 66 -4 33 -9 84 -10
115 l-3 56 917 3 917 2 0 -961 0 -960 -57 6 c-92 11 -293 52 -355 74 -32 11
-59 17 -61 13 -17 -29 -47 -145 -41 -152 11 -10 123 -43 224 -65 157 -35 373
-55 415 -39 46 18 45 -4 45 1119 0 1123 1 1101 -45 1119 -9 3 -481 6 -1049 6
l-1033 0 -21 -23z"
        />
        <path
          d="M755 2152 c-69 -24 -112 -52 -167 -109 -58 -61 -96 -133 -110 -214
-7 -35 -16 -67 -21 -70 -5 -4 -30 -10 -56 -13 -60 -8 -139 -54 -185 -107 -72
-85 -98 -219 -61 -328 l17 -52 -37 -40 c-56 -63 -78 -121 -79 -214 0 -66 4
-90 26 -136 33 -72 94 -134 167 -168 47 -22 70 -26 141 -26 77 1 91 4 148 35
35 18 79 52 98 74 18 21 36 38 38 35 2 -2 33 -62 69 -134 l65 -130 37 -225
c20 -124 41 -241 46 -261 18 -70 15 -70 425 -67 l366 3 19 24 c18 21 19 42 19
278 0 250 1 259 40 496 52 314 44 287 81 287 43 0 145 27 192 52 59 30 133
103 162 161 72 146 -5 324 -175 404 l-55 26 0 101 c0 88 -3 107 -25 150 -57
113 -158 178 -282 184 -98 5 -163 -16 -228 -75 l-46 -41 -55 19 c-33 12 -77
18 -114 17 -52 -1 -72 4 -139 38 -73 35 -86 39 -175 41 -72 2 -109 -2 -146
-15z m235 -168 c19 -9 48 -27 63 -42 27 -26 33 -27 131 -27 99 0 103 -1 141
-31 22 -17 50 -34 61 -38 33 -10 73 10 92 46 28 53 75 95 120 107 150 41 268
-139 173 -261 -11 -14 -23 -36 -26 -50 -9 -34 19 -86 48 -92 12 -3 46 -10 75
-16 178 -35 247 -174 130 -263 -55 -42 -111 -57 -213 -57 -128 0 -136 -7 -146
-131 -4 -52 -23 -189 -43 -306 -34 -199 -36 -225 -36 -432 l0 -221 -260 0
c-143 0 -260 3 -260 6 0 3 -16 101 -35 217 l-35 212 -127 259 c-71 142 -139
269 -152 282 -30 30 -88 32 -122 5 -22 -18 -24 -27 -24 -114 0 -106 -10 -130
-76 -175 -28 -20 -44 -23 -92 -20 -90 7 -146 60 -154 145 -6 62 24 127 70 151
86 46 109 104 62 160 -67 79 -69 165 -8 233 45 50 91 64 162 51 99 -18 131 21
131 155 0 65 5 88 26 132 59 118 198 167 324 115z"
        />
        <path
          d="M1413 1480 c-29 -10 -65 -27 -81 -39 -43 -30 -110 -106 -129 -145
l-16 -35 -41 33 c-81 66 -231 126 -314 126 l-33 0 3 -82 3 -83 49 -7 c113 -16
251 -119 275 -204 6 -22 11 -114 11 -206 l0 -168 85 0 85 0 0 213 c0 225 8
278 51 352 27 45 100 92 155 101 l44 6 0 79 0 79 -47 0 c-27 -1 -72 -9 -100
-20z"
        />
        <path
          d="M2755 2065 l-25 -24 0 -819 0 -819 27 -28 c26 -25 32 -27 83 -21 79
9 224 38 233 46 8 7 -20 138 -33 153 -3 4 -35 1 -71 -8 -35 -8 -67 -15 -71
-15 -5 0 -8 313 -8 695 l0 695 795 0 795 0 -10 -25 c-5 -13 -6 -26 -2 -28 29
-17 145 -48 152 -41 12 13 50 156 50 188 0 15 -10 37 -25 51 l-24 25 -921 0
-921 0 -24 -25z"
        />
        <path
          d="M4360 1754 c-65 -11 -159 -39 -195 -57 -136 -68 -254 -213 -294 -363
-6 -21 -20 -131 -31 -244 -12 -112 -22 -205 -23 -205 -1 -1 -38 -23 -82 -49
-63 -38 -82 -46 -95 -37 -49 36 -83 43 -155 32 -119 -20 -195 -81 -236 -191
-51 -135 14 -292 148 -359 23 -12 51 -21 62 -21 15 0 23 -13 37 -55 46 -147
208 -234 356 -191 113 33 190 124 209 250 10 65 3 99 -32 147 -10 13 -4 30 36
95 26 44 48 80 49 81 1 2 103 12 227 23 274 25 315 33 406 76 113 54 207 144
262 252 30 59 61 184 61 247 0 250 -171 478 -410 549 -53 16 -250 29 -300 20z
m238 -179 c69 -16 150 -64 198 -116 191 -208 106 -546 -160 -640 -34 -12 -137
-26 -295 -41 l-242 -22 -56 56 -57 57 18 183 c10 101 22 202 27 226 31 144
148 263 294 298 70 17 198 16 273 -1z m-675 -883 l37 -37 -65 -108 c-78 -129
-81 -155 -30 -214 40 -47 41 -53 20 -94 -32 -61 -117 -87 -174 -53 -40 24 -54
51 -62 123 -9 83 -27 101 -110 110 -35 4 -74 13 -85 21 -33 21 -54 64 -54 110
0 34 6 48 33 75 52 52 78 54 129 11 60 -52 84 -49 209 28 57 35 106 64 109 65
3 0 23 -16 43 -37z"
        />
        <path
          d="M4347 1406 c-93 -35 -155 -100 -184 -195 -24 -81 -24 -81 71 -81 l84
0 6 31 c3 17 19 44 36 61 28 27 35 29 83 26 28 -2 59 -8 67 -11 12 -6 22 3 38
30 12 21 31 52 42 70 l21 32 -43 22 c-54 28 -166 36 -221 15z"
        />
      </g>
    </IconStyled>
  );
};

export default FoodCount;
