import { isEmpty } from './objects';

export const sortByNestedObject = function (prop: string, array?: any[]) {
  if (!isEmpty(array)) {
    const nested = prop.split('.');
    let len = nested.length;
    array!.sort((a, b) => {
      let i = 0;
      while (i < len) {
        a = a[nested[i]];
        b = b[nested[i]];
        i++;
      }
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  } else {
    return undefined;
  }
};
