import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Arrow: React.FC<IIconProps> = ({
  color,
  size,
  faded,
  rotate,
  className,
}) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
      rotate={rotate}
      className={className}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2370 5114 c-19 -2 -78 -9 -130 -15 -791 -90 -1522 -586 -1924 -1305
-146 -262 -252 -587 -297 -914 -20 -144 -18 -527 4 -670 49 -312 121 -539 262
-822 l57 -113 -170 -635 c-94 -349 -170 -636 -169 -637 1 -1 288 75 637 168
l635 171 145 -71 c164 -79 230 -107 345 -144 740 -240 1547 -134 2190 287 586
384 995 994 1119 1670 156 845 -103 1682 -705 2285 -399 398 -879 639 -1449
726 -100 15 -472 28 -550 19z m395 -304 c785 -72 1480 -553 1825 -1264 106
-217 166 -409 206 -661 25 -154 25 -496 0 -650 -40 -252 -100 -444 -206 -661
-223 -458 -585 -820 -1045 -1045 -216 -105 -409 -165 -660 -205 -138 -23 -486
-26 -620 -6 -313 47 -603 150 -886 314 l-56 32 -442 -118 c-244 -65 -445 -115
-448 -113 -2 3 49 204 113 447 l119 442 -33 57 c-69 119 -173 341 -210 451
-299 877 -27 1859 678 2453 464 390 1069 582 1665 527z"
        />
        <path
          d="M2375 3810 c-237 -62 -420 -224 -512 -450 -26 -65 -53 -190 -53 -246
l0 -34 148 0 148 0 11 71 c35 223 217 379 443 379 131 0 237 -44 324 -136 135
-141 163 -353 69 -525 -14 -27 -142 -178 -284 -336 l-258 -288 -1 -182 0 -183
150 0 150 0 0 124 0 124 213 237 c240 268 266 302 313 400 53 110 68 183 68
320 0 141 -16 212 -74 330 -98 199 -272 340 -485 395 -103 26 -271 27 -370 0z"
        />
        <path d="M2410 1435 l0 -155 150 0 150 0 0 155 0 155 -150 0 -150 0 0 -155z" />
      </g>
    </IconStyled>
  );
};

export default Arrow;
