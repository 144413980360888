import React, { useContext } from 'react';
import { INavbarHeadingsProps } from './navbarHeadings.types';
import {
  NavbarContainerLeft,
  HeadingsContainer,
  HeadingStyled,
  SubheadingStyled,
  Icon,
} from './NavbarHeadings.styles';
import { Arrow } from '../../icons';
import { LayoutContext } from '../../../../contexts/layoutContext';

const NavbarHeadingGroup: React.FC<INavbarHeadingsProps> = ({
  heading,
  subHeading,
  backButton,
  backButtonCTA,
}) => {
  const { dispatch } = useContext(LayoutContext);

  const handleClick = () => {
    if (backButtonCTA) {
      backButtonCTA();
    } else {
      dispatch({ type: 'TOGGLE_MENU' });
    }
  };

  return (
    <NavbarContainerLeft backButton={backButton}>
      {backButton && (
        <Icon onClick={handleClick}>
          <Arrow size="small" rotate={180} faded />
        </Icon>
      )}
      <HeadingsContainer backButton={backButton}>
        <HeadingStyled>{heading}</HeadingStyled>
        {subHeading && <SubheadingStyled>{subHeading}</SubheadingStyled>}
      </HeadingsContainer>
    </NavbarContainerLeft>
  );
};

export default NavbarHeadingGroup;
