import React from 'react';
import { getWidthCutoffs } from '../../../../../utils/helper';
import { LayoutColumn } from '../../../../shared/layout';
import { Label } from '../../../../shared/typefaces/Typefaces.styles';
import { Col, HeaderRow } from './IngredientList.styles';

const TableHeader = ({ appWidth }) => {
  const {
    isSmallerThanPhoneLarge,
    isSmallerThanPhoneMed,
    isSmallerThanTablet,
  } = getWidthCutoffs(appWidth);

  if (isSmallerThanPhoneMed) {
    return (
      <HeaderRow>
        <Col>
          <Label>Ingredient</Label>
        </Col>
        <Col>
          <LayoutColumn>
            <Label>Avg Cost:</Label>
            <Label color="grey">100g</Label>
          </LayoutColumn>
        </Col>
      </HeaderRow>
    );
  } else if (isSmallerThanPhoneLarge) {
    return (
      <HeaderRow>
        <Col>
          <Label>Ingredient Name</Label>
        </Col>
        <Col>
          <LayoutColumn>
            <Label>Avg Cost:</Label>
            <Label color="grey">100g</Label>
          </LayoutColumn>
        </Col>
      </HeaderRow>
    );
  } else if (isSmallerThanTablet) {
    return (
      <HeaderRow>
        <Col>
          <Label>Ingredient Name</Label>
        </Col>
        <Col>
          <Label>Recipe Count</Label>
        </Col>
        <Col>
          <LayoutColumn>
            <Label>Avg Cost:</Label>
            <Label color="grey" fontSize="small">
              100g
            </Label>
          </LayoutColumn>
        </Col>
      </HeaderRow>
    );
  } else {
    return (
      <HeaderRow>
        <Col>
          <Label>Ingredient Name</Label>
        </Col>
        <Col>
          <Label>Recipe Count</Label>
        </Col>
        <Col>
          <Label>Brand</Label>
        </Col>
        <Col>
          <LayoutColumn>
            <Label>Avg Cost:</Label>
            <Label color="grey">100g</Label>
          </LayoutColumn>
        </Col>
      </HeaderRow>
    );
  }
};

export default TableHeader;
