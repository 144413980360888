import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Apple: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3929 4674 c-27 -29 -27 -45 -9 -344 15 -232 17 -216 -27 -224 -61
-9 -155 -60 -203 -108 -36 -36 -142 -224 -535 -951 l-490 -907 -50 0 c-49 0
-163 -14 -214 -27 -21 -5 -18 1 20 30 78 61 128 154 143 267 6 39 12 48 42 65
51 27 122 108 152 173 24 50 27 69 27 157 -1 88 -4 107 -28 156 -41 85 -97
143 -179 184 -39 19 -77 35 -84 35 -8 0 -16 12 -19 28 -35 173 -76 253 -185
363 -70 72 -100 93 -170 127 -107 50 -170 65 -284 65 -104 0 -191 -20 -278
-64 l-59 -30 -63 31 c-134 63 -300 81 -436 46 -178 -45 -341 -175 -416 -333
l-39 -80 -68 -17 c-327 -85 -532 -418 -462 -751 18 -82 68 -191 118 -257 l36
-47 -37 -72 c-36 -69 -37 -75 -37 -178 0 -95 3 -112 27 -162 38 -76 109 -146
188 -183 58 -28 75 -31 160 -31 83 0 103 4 154 28 l59 27 56 -27 c49 -24 68
-27 156 -28 94 0 105 2 167 33 45 22 83 51 116 88 l49 55 72 -111 c40 -62 71
-113 69 -114 -2 -1 -30 -17 -63 -36 -111 -61 -203 -131 -287 -218 -97 -100
-147 -178 -190 -294 -40 -107 -50 -274 -23 -377 66 -256 289 -454 600 -537
179 -47 451 -44 634 6 63 18 66 18 100 0 168 -84 402 -127 606 -109 246 21
462 113 652 278 l80 69 31 -43 c75 -106 194 -167 332 -169 98 -1 164 18 240
69 l49 33 38 -28 c73 -54 138 -73 243 -73 74 0 107 5 150 21 107 43 199 132
234 227 50 137 280 893 302 992 32 147 29 215 -14 307 -37 79 -101 145 -179
185 -50 25 -62 37 -87 85 -49 97 -175 186 -261 186 -14 0 -25 4 -25 9 0 14
107 96 165 127 113 61 147 103 147 184 0 96 -62 169 -152 178 -92 9 -285 -69
-422 -170 -112 -83 -221 -213 -271 -324 l-19 -41 -51 19 c-72 28 -174 27 -252
-3 -68 -25 -149 -100 -179 -164 -20 -41 -32 -55 -53 -55 -7 0 230 340 527 755
601 842 576 799 568 955 -3 74 -9 97 -40 163 l-37 76 221 272 c121 150 224
285 230 300 12 36 -11 82 -49 97 -44 17 -331 42 -359 32 -13 -5 -54 -48 -91
-96 -66 -84 -68 -85 -74 -57 -4 15 -7 42 -8 59 -1 41 -21 57 -178 148 -149 86
-183 93 -224 50z m202 -237 l34 -23 3 -162 c1 -99 -1 -162 -7 -162 -5 0 -21 4
-34 9 -24 10 -25 12 -32 168 -3 87 -9 166 -11 176 -7 23 6 22 47 -6z m516 -45
c8 -5 -37 -68 -139 -195 -84 -102 -152 -186 -153 -184 -1 1 -5 21 -8 45 -6 47
-12 38 160 260 57 72 68 82 96 82 18 0 37 -4 44 -8z m-492 -479 c110 -56 177
-145 196 -262 21 -130 53 -76 -539 -906 -294 -412 -540 -756 -547 -763 -9 -10
-32 -3 -116 37 -57 28 -148 63 -201 78 l-98 27 49 90 c132 249 165 306 174
306 5 0 49 -25 97 -55 101 -63 129 -68 171 -26 58 59 31 104 -115 194 l-78 47
25 43 c13 23 65 119 115 212 51 94 96 176 100 183 7 10 27 2 89 -37 44 -28 92
-51 106 -51 41 0 81 41 81 83 0 45 -18 65 -105 118 -38 23 -72 45 -74 50 -7
10 280 545 309 577 84 94 238 117 361 55z m-2862 -340 c32 -9 85 -33 118 -55
33 -21 72 -38 86 -38 14 0 47 13 72 29 110 71 219 97 339 80 222 -31 402 -239
402 -465 0 -74 20 -95 100 -105 34 -5 81 -19 104 -32 123 -68 142 -245 36
-341 -21 -19 -55 -39 -76 -45 -20 -6 -47 -20 -60 -32 -21 -20 -24 -31 -24
-104 0 -66 -4 -90 -23 -126 -61 -115 -213 -145 -320 -64 -53 40 -101 46 -138
16 -20 -16 -24 -28 -23 -68 1 -37 45 -196 81 -288 2 -6 -27 -37 -64 -69 -38
-32 -90 -84 -117 -115 -48 -54 -52 -57 -140 -78 -50 -12 -96 -22 -102 -23 -6
0 -42 48 -79 108 -150 235 -234 328 -380 420 -103 64 -282 132 -349 132 -67 0
-108 -94 -60 -139 10 -10 59 -31 108 -46 76 -24 275 -130 310 -165 13 -13 -10
-62 -47 -98 -85 -82 -196 -82 -306 2 -43 33 -69 33 -112 1 -57 -42 -112 -65
-157 -65 -115 0 -212 95 -212 210 0 67 20 113 75 166 37 37 45 52 45 81 0 30
-10 46 -65 104 -153 163 -186 354 -93 546 68 141 208 238 370 258 29 4 61 13
71 20 10 7 31 47 46 87 69 184 204 294 396 321 43 6 121 -2 188 -20z m3377
-1086 c0 -2 -39 -28 -86 -57 -111 -67 -221 -172 -290 -276 l-55 -82 -42 5
c-23 3 -44 7 -46 9 -8 7 65 117 112 170 80 90 214 175 337 215 57 19 70 21 70
16z m-2498 -448 c-46 -20 -62 -18 -62 7 0 10 13 14 53 13 l52 0 -43 -20z
m1713 -60 c3 -3 -11 -30 -31 -60 l-36 -55 -52 26 c-29 15 -63 29 -75 33 -29 7
-21 21 27 47 28 16 52 20 99 17 34 -2 65 -5 68 -8z m698 -2 c18 -8 38 -21 45
-29 11 -14 7 -17 -25 -23 -20 -4 -49 -13 -64 -20 -25 -14 -27 -13 -58 30 -17
24 -31 46 -31 49 0 12 101 6 133 -7z m-1703 -37 c82 -20 279 -109 329 -148 23
-19 23 -20 8 -68 -27 -81 -40 -183 -34 -261 l5 -73 -42 58 c-137 185 -382 280
-616 238 -342 -61 -568 -398 -495 -740 47 -221 235 -415 458 -473 75 -19 211
-21 287 -4 241 56 434 261 476 506 9 50 17 91 18 92 3 3 136 -483 136 -497 0
-22 -83 -112 -158 -171 -177 -142 -374 -210 -604 -210 -171 0 -287 27 -447
103 l-83 40 -87 -26 c-129 -39 -219 -49 -376 -43 -112 4 -158 10 -230 32 -205
60 -351 172 -422 321 -33 70 -38 89 -41 174 -4 83 -1 105 23 175 85 254 372
477 695 540 149 29 156 32 189 78 52 70 162 176 233 223 116 78 254 130 404
154 89 14 276 4 374 -20z m1407 -52 c53 -24 79 -61 94 -127 19 -90 94 -118
158 -58 117 110 315 76 385 -65 31 -63 32 -81 5 -206 -23 -108 -280 -938 -306
-991 -38 -75 -150 -130 -241 -117 -61 8 -138 53 -170 98 -54 75 -109 73 -176
-8 -67 -80 -160 -109 -260 -80 -66 20 -125 68 -149 123 -9 21 -80 270 -158
553 -151 551 -153 558 -112 637 27 53 91 99 158 114 107 24 216 -23 271 -116
41 -71 96 -84 145 -36 25 26 29 37 29 88 1 64 19 124 47 155 59 66 183 82 280
36z m-1556 -368 c80 -15 157 -57 225 -122 108 -103 156 -243 134 -385 -48
-304 -372 -474 -646 -338 -85 42 -169 126 -208 209 -158 334 131 704 495 636z"
        />
        <path
          d="M1587 2899 c-38 -22 -88 -164 -116 -329 l-16 -97 -80 63 c-43 35
-113 82 -155 103 -86 45 -244 101 -284 101 -38 0 -76 -41 -76 -82 0 -54 21
-73 117 -103 48 -15 118 -42 154 -60 138 -70 343 -263 368 -346 19 -64 48
-117 70 -129 33 -17 76 -12 98 13 33 36 36 62 13 114 -71 159 -67 369 10 594
28 80 30 95 19 120 -6 17 -20 34 -30 39 -23 13 -69 12 -92 -1z"
        />
      </g>
    </IconStyled>
  );
};

export default Apple;
