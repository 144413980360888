import styled from 'styled-components';
import { Card } from '../../shared/card';

export const Container = styled(Card)`
  max-width: 500px;
  margin: 30px auto;
`;

export const Header = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 14px 14px 0 0;
  padding: 5px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const Form = styled.form`
  padding: 15px;
`;

export const Info = styled.div`
  margin-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const Group = styled.div`
  margin-top: 30px;

  .groupHeading {
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.grey.default};
    padding-bottom: 10px;

    &.noPaddingBottom {
      padding-bottom: 0;
    }
  }
`;

export const CTAButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
