import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { H2, Span } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div`
  @media ${device.tablet} {
    .sideContent {
      width: 1500px;
      max-width: 1200px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${H2} {
    font-size: ${({ theme }) => theme.fontSize.largeHeading};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.white};
    margin-bottom: 30px;
  }

  ${Span} {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: ${({ theme }) => theme.lineHeight.heading};
    color: ${({ theme }) => theme.colors.text.white};

    &.breakline {
      margin-bottom: 15px;
    }
  }
`;

export const CTA = styled.div`
  display: flex;
  flex-direction: column;

  .ctaButton {
    margin: 30px 30px 0px 0px;
    min-width: 250px;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`;
