import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { Button } from '../../../../shared/button';
import { Card, Header } from '../../../../shared/card';
import { Dollar, Share } from '../../../../shared/icons';
import { Container, Update } from './Invoices.styles';

const Invoices: FC = () => {
  const { appWidth, dispatch } = useContext(LayoutContext);

  const seeInvoices = () => {
    dispatch({ type: 'SLIDER_PAGE', payload: 'invoices' });
  };

  return (
    <Container>
      <Card withCardLink>
        <Header
          icon={<Dollar size="small" />}
          heading="Invoices"
          subHeading="Click to show invoices"
          button={
            appWidth !== 0 && appWidth < theme.mQ.tablet ? (
              <Button onClick={null} color="primary" asCircle>
                <Share color="white" size="small" />
              </Button>
            ) : (
              <Update color="default" inversed onClick={seeInvoices}>
                Invoices
              </Update>
            )
          }
        />
      </Card>
    </Container>
  );
};

export default Invoices;
