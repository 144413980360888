import React, { useContext, useState, memo, FC } from 'react';
import { ScaleIcon } from '../../../../shared/icons';
import { Card, Header } from '../../../../shared/card';
import { useFormContext } from 'react-hook-form';
import { Content } from './Duplicate.styles';
import { LayoutColumn } from '../../../../shared/layout';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  useRecipeQuery,
  useSetSelectedRecipeVersionMutation,
  RecipeDocument,
} from '../../../../../generated/graphql';
import { IDuplicateProps } from './duplicate.types';
import { SelectList } from '../../../formElements';

const Duplicate: FC<IDuplicateProps> = ({ handleDuplicateRecipe }) => {
  const { errors, control } = useFormContext();
  const {
    selectedRecipe,
    selectedVenueObject,
    selectedRecipeVersion,
    dispatch,
  } = useContext(LayoutContext);
  const [
    setSelectedRecipeVersionMutation,
  ] = useSetSelectedRecipeVersionMutation();
  const recipe = useRecipeQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
        recipeId: selectedRecipe!,
      },
    },
  });
  const [duplicateRecipeVersionId, setDuplicateRecipeVersionId] = useState('');

  const allVersions = recipe.data?.recipe.recipe?.recipeVersions || [];

  const selectedVersion = allVersions.find((version) =>
    duplicateRecipeVersionId &&
    duplicateRecipeVersionId !== selectedRecipeVersion
      ? version.id === duplicateRecipeVersionId
      : selectedRecipeVersion
      ? version.id === selectedRecipeVersion
      : version.selected
  );

  const defaultSelectLabel = {
    label: selectedVersion?.displayName || '',
    value: selectedVersion?.id || '',
  };

  const activeVersions = allVersions
    .filter((version) => version.active)
    .map(({ displayName, id }) => ({ label: displayName, value: id }));

  const selectVersion = (version) => {
    handleDuplicateRecipe(version.value.toString());
    setDuplicateRecipeVersionId(version.value);

    (async () => {
      try {
        const response = await setSelectedRecipeVersionMutation({
          variables: {
            input: {
              recipeId: selectedRecipe!,
              recipeVersionId: version.value,
            },
          },
          refetchQueries: [
            {
              query: RecipeDocument,
              variables: {
                input: {
                  venueId: selectedVenueObject?.id!,
                  recipeId: selectedRecipe!,
                },
              },
            },
          ],
        });

        if (response.data?.setSelectedRecipeVersion.successful) {
          dispatch({
            type: 'SELECT_RECIPE_VERSION',
            payload: response.data.setSelectedRecipeVersion.recipeVersion?.id,
          });
        }
      } catch (err) {
        console.log('err ###', err);
      }
    })();
  };

  return (
    <Card withCardLink>
      <Header
        icon={<ScaleIcon size="small" />}
        heading="Select Recipe To Duplicate"
        subHeading="Select recipe version to duplicate recipe, then make change to compare profitability"
      />
      <Content fullWidth>
        <LayoutColumn>
          <SelectList
            autoComplete
            control={control}
            name="version"
            label="Recipe Version"
            errorText={errors?.value?.message}
            options={activeVersions}
            defaultValue={defaultSelectLabel}
            preventAddOption
            handleChange={selectVersion}
          />
        </LayoutColumn>
      </Content>
    </Card>
  );
};

export default memo(Duplicate);
