import styled from 'styled-components/macro';

interface IContainerProps {
  addMarginTop?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  padding: 0 10px 0;
  margin-top: ${({ addMarginTop }) => (addMarginTop ? 50 : 0)}px;
`;
