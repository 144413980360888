import React, { FC, useContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  RecipeDocument,
  useRecipeQuery,
  useSetSelectedRecipeVersionMutation,
} from '../../../../../generated/graphql';
import { Card, Header } from '../../../../shared/card';
import { Input, SelectList } from '../../../../shared/formElements';
import { ScaleIcon } from '../../../../shared/icons';
import { LayoutColumn } from '../../../../shared/layout';
import { Content, Serves, StyledInput } from './Scale.styles';
import { IScaleProps } from './scale.types';

const Scale: FC<IScaleProps> = ({ handleScaleRecipe }) => {
  const { errors, control } = useFormContext();

  const {
    selectedRecipe,
    selectedVenueObject,
    selectedRecipeVersion,
    dispatch,
  } = useContext(LayoutContext);

  const [
    setSelectedRecipeVersionMutation,
  ] = useSetSelectedRecipeVersionMutation();

  const recipe = useRecipeQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
        recipeId: selectedRecipe!,
      },
    },
  });

  const [scaleRecipeVersionId, setScaleRecipeVersionId] = useState('');

  const allVersions = recipe.data?.recipe.recipe?.recipeVersions || [];

  const selectedVersion = allVersions.find((version) =>
    scaleRecipeVersionId && scaleRecipeVersionId !== selectedRecipeVersion
      ? version.id === scaleRecipeVersionId
      : selectedRecipeVersion
      ? version.id === selectedRecipeVersion
      : version.selected
  );

  const defaultSelectLabel = {
    label: selectedVersion?.displayName || '',
    value: selectedVersion?.id || '',
  };

  const activeVersions = allVersions
    .filter((version) => version.active)
    .map(({ displayName, id }) => ({ label: displayName, value: id }));

  const selectVersion = (version) => {
    handleScaleRecipe(version.value);
    setScaleRecipeVersionId(version.value);

    (async () => {
      try {
        const response = await setSelectedRecipeVersionMutation({
          variables: {
            input: {
              recipeId: selectedRecipe!,
              recipeVersionId: version.value,
            },
          },
          refetchQueries: [
            {
              query: RecipeDocument,
              variables: {
                input: {
                  venueId: selectedVenueObject?.id!,
                  recipeId: selectedRecipe!,
                },
              },
            },
          ],
        });

        if (response.data?.setSelectedRecipeVersion.successful) {
          dispatch({
            type: 'SELECT_RECIPE_VERSION',
            payload: response.data.setSelectedRecipeVersion.recipeVersion?.id,
          });
        }
      } catch (err) {
        console.log('err ###', err);
      }
    })();
  };

  return (
    <Card withCardLink>
      <Header
        icon={<ScaleIcon size="small" />}
        heading="Select Recipe & Serving Amount"
        subHeading="Change the serving size, to calulate ingredient quantity based on the original recipe verison"
      />
      <Content fullWidth>
        <LayoutColumn>
          <SelectList
            autoComplete
            control={control}
            name="version"
            label="Recipe Version"
            errorText={errors?.value?.message}
            options={activeVersions}
            defaultValue={defaultSelectLabel}
            preventAddOption
            handleChange={selectVersion}
          />
          <Serves>
            <Controller
              as={<StyledInput />}
              type="number"
              label="Scaled Serve Amount"
              name="serves"
              control={control}
              errorText={errors.serves && errors.serves.message}
              defaultValue={selectedVersion?.serves}
            />
          </Serves>
          <Controller
            as={<Input />}
            control={control}
            className="hiddenInput"
            type="hidden"
            name={`displayName`}
          />
          <Controller
            as={<Input />}
            control={control}
            className="hiddenInput"
            type="hidden"
            name={`salesPricePerServe`}
          />
          <Controller
            as={<Input />}
            control={control}
            className="hiddenInput"
            type="hidden"
            name={`weeklySalesPerServe`}
          />
        </LayoutColumn>
      </Content>
    </Card>
  );
};

export default Scale;
