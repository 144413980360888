import React, { FC } from 'react';
import { convertCostCleanly, roundTo } from '../../../../../utils/helper';
import { H2, Span, Label } from '../../../../shared/typefaces/Typefaces.styles';
import { IRecipeListProps } from '../../recipes.types';
import {
  Container,
  HeaderContainer,
  Headings,
  HeadingsItem,
} from './Header.styles';

const Header: FC<IRecipeListProps> = ({ data }) => {
  const recipesCount = data.data?.venueRecipes.recipes.length;

  let sumFoodCostPercentage = 0;
  let sumFoodCostRecipesCounted = 0;
  let sumRecipeProfit = 0;
  let sumRecipeProfitsCounted = 0;

  data.data?.venueRecipes.recipes.forEach((recipe) => {
    if (recipe.foodCostPercentage) {
      sumFoodCostPercentage = sumFoodCostPercentage + recipe.foodCostPercentage;
      sumFoodCostRecipesCounted++;
    }
    if (recipe.recipeProfit) {
      sumRecipeProfit = sumRecipeProfit + recipe.recipeProfit;
      sumRecipeProfitsCounted++;
    }
  });

  const averageFoodCostPercentage =
    sumFoodCostPercentage && sumFoodCostRecipesCounted
      ? sumFoodCostPercentage / sumFoodCostRecipesCounted
      : 0;
  const averageRecipeProfit =
    sumRecipeProfit && sumRecipeProfitsCounted
      ? sumRecipeProfit / sumRecipeProfitsCounted
      : 0;

  return (
    <Container>
      <HeaderContainer>
        <Headings>
          <HeadingsItem>
            <Label className="headerLabel" color="faded" fontSize="small">
              Total Recipes
            </Label>
            <H2>{recipesCount}</H2>
          </HeadingsItem>
          <HeadingsItem>
            <Label className="headerLabel" color="faded" fontSize="small">
              Average Recipe Profit
            </Label>
            <Span fontSize="heading">
              {convertCostCleanly(averageRecipeProfit)}
            </Span>
          </HeadingsItem>
          <HeadingsItem>
            <Label className=" headerLabel" color="faded" fontSize="small">
              Average Food Cost
            </Label>
            <Span fontSize="heading">
              {averageFoodCostPercentage
                ? roundTo(averageFoodCostPercentage) + '%'
                : '-'}
            </Span>
          </HeadingsItem>
        </Headings>
      </HeaderContainer>
    </Container>
  );
};

export default Header;
