import styled from 'styled-components/macro';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  .heading {
    margin: 0 20px;
  }
`;

export const BreakLine = styled.div`
  height: 1px;
  width: 60%;
  background-color: ${({ theme }) => theme.colors.primary.default};
  margin: 20px 0;
`;
