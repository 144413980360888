import styled from 'styled-components/macro';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../../../styles/device';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { IColProps } from './detail.types';
import { Col } from './brandItem/BrandItem.styles';

export const Table = styled.section`
  margin-top: 30px;
`;

export const Column = styled.li<IColProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
  padding: 15px 5px;

  &:first-child {
    justify-content: flex-start;
    margin-left: 15px;
    padding-left: 0;
  }

  &.icons {
    padding: 0;
    align-items: ${({ toggle }) => (toggle ? 'flex-start' : 'center')};
  }

  @media ${device.tablet} {
    &:first-child {
      border: 0;
      margin-left: 70px;
    }
  }
`;

export const Row = styled.ul`
  min-height: 70px;
  display: grid;
  grid-template-columns: auto;

  &:first-child {
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 40px;
    ${Col} {
      border: 0;
    }
    ${Column} {
      border: 0;
    }
  }
  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: auto;
  }
`;

export const Form = styled.div`
  margin: 30px 15px 10px;

  .colTwo {
    margin-top: 15px;
  }

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    margin: 15px 15px 15px 70px;

    .colOne {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      .costInput {
        margin-right: 15px;
        max-width: 120px;
      }

      .gramsInput {
        max-width: 150px;
      }
    }

    .colTwo {
      max-width: 120px;
      margin-top: 0;
    }
  }
`;

export const Icon = styled.div`
  height: 40px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const MenuItems = styled(Label)`
  display: block;
  margin-top: 5px;
`;

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: 15px;
  padding: 0;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 15px;
    grid-gap: 0;
  }
`;

export const BrandRow = styled.section`
  display: flex;
  flex-direction: column;

  .title {
    padding-bottom: 15px;
  }
`;

export const BrandDetails = styled.section`
  ${Span} {
    display: block;
    padding: 5px 0;
  }
`;
