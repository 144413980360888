import styled from 'styled-components/macro';
import { IContainerProps, IDeleteRowProps } from './items.types';
import { device } from '../../../../../styles/device';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Button } from '../../../../shared/button';

export const Container = styled.div<IContainerProps>`
  .tableHeader {
    border-radius: ${({ roundCorners }) =>
      roundCorners ? '14px 14px 0 0' : '0'};

    li {
      span {
        font-size: ${({ theme }) => theme.fontSize.button};
      }

      &:nth-child(2) {
        span {
          font-size: ${({ theme }) => theme.fontSize.default};
        }
      }

      &:nth-child(6),
      &:nth-child(7) {
        justify-content: end;
      }
    }
  }

  .itemsTable {
    ul {
      align-items: flex-start;
      &:first-child {
        li {
          &:nth-child(1) {
            display: flex;
          }
          @media ${device.laptop} {
            display: flex;
          }
        }
      }
    }

    li {
      .rowInput {
        padding-top: 10px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        .rowInput {
          padding-top: 0;
        }
      }

      &:nth-child(5) {
        padding-right: 5px;
      }
      &:nth-child(6) {
        padding-right: 0;
      }

      @media ${device.tablet} {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          .rowInput {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .dotsMenu {
    width: 300px;
  }
`;

export const DotsMenuWrapper = styled.div`
  position: relative;
`;

export const AddItem = styled(Button)`
  min-width: 180px;
`;

export const RowIcon = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 26px;
  padding-bottom: 15px;

  .icon {
    fill: ${({ theme }) => theme.colors.grey.faded};
  }

  &:hover {
    .icon {
      fill: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;

export const HeaderIcon = styled.div<IDeleteRowProps>`
  position: relative;
  padding: 0px 0px 2px 0px;
  border-radius: 85px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: ${({ showBackground, theme }) =>
    showBackground ? theme.colors.default.faded : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.default.faded};

    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const RowTotal = styled(Span)`
  padding-left: 5px;
  padding-right: 15px;

  @media ${device.tablet} {
    padding-right: 0;
    padding-top: 26px;
  }
`;
