import React, { FC } from 'react';
import { Image, Container, Name, Title, Comment } from './Quote.styles';
import { IQuoteProps } from './quote.types';
import QuoteMark from '../../icons/components/QuoteMark';

const Quote: FC<IQuoteProps> = ({ image, quote, name, venue, title }) => {
  return (
    <Container>
      <QuoteMark size="xlarge" />
      <Title color="grey">
        <Name color="primary">{name}</Name>
        {title} - {venue}
      </Title>
      <Comment>{quote}</Comment>
      {image && <Image src={image} />}
    </Container>
  );
};

export default Quote;
