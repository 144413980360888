import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { theme } from '../../../../styles/theme';
import { formatDateFromTimestamp } from '../../../../utils/helper/time';
import { AnchorButton } from '../../../shared/button/Button.styles';
import { Share } from '../../../shared/icons';
import { Col, Row } from '../Invoices.styles';

interface IProps {
  invoice: {
    id: string;
    invoiceUrl?: string | null;
    createdUnixSec: number;
  };
}

const InvoiceItem: FC<IProps> = ({ invoice }) => {
  const { appWidth } = useContext(LayoutContext);

  const date = formatDateFromTimestamp(
    (invoice.createdUnixSec * 1000).toString()
  );

  if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
    return (
      <Row>
        <Col>{date}</Col>
        <Col>
          <AnchorButton
            color="primary"
            asCircle
            disabled={!invoice.invoiceUrl}
            href={invoice.invoiceUrl ?? '#'}
            target="_blank"
          >
            <Share color="white" size="small" />
          </AnchorButton>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col />
        <Col>{date}</Col>
        <Col>
          <AnchorButton
            color="default"
            inversed
            disabled={!invoice.invoiceUrl}
            href={invoice.invoiceUrl ?? '#'}
            target="_blank"
            asCircle
          >
            <Share color="grey" faded size="small" />
          </AnchorButton>
        </Col>
      </Row>
    );
  }
};

export default InvoiceItem;
