import React, { FC, useContext, memo, useRef } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { Edit, RecipeBook } from '../../../../shared/icons';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { HeaderRow, Col, Row, Icon, Table } from './InventoryList.styles';
import { Card, Content, Header } from '../../../../shared/card';
import {
  Ingredient,
  IngredientProduct,
  Maybe,
  Section,
  Size,
  StocktakeIngredient,
  StocktakeSection,
  Supplier,
} from '../../../../../generated/graphql';
import {
  convertCostCleanly,
  convertGramsCleanly,
} from '../../../../../utils/helper/numbers';
import useResizeDimensions from '../../../../../utils/customHooks/useResizeDimensions';
import { LayoutColumn } from '../../../../shared/layout';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { DotsLoading } from '../../../../shared/loading';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

type StocktakeIngredientProps = Pick<
  StocktakeIngredient,
  'id' | 'quantity' | 'order'
> & {
  ingredient: {
    __typename?: 'Ingredient' | undefined;
  } & Pick<Ingredient, 'id' | 'displayName'> & {
      ingredientProducts: ({
        __typename?: 'IngredientProduct' | undefined;
      } & Pick<IngredientProduct, 'id'> & {
          venueId: string;
          required: {
            id: string;
            unitAmount: number;
            active: boolean;
            quantity: number;
          }[];
          size?:
            | Maybe<
                {
                  __typename?: 'Size' | undefined;
                } & Pick<
                  Size,
                  | 'id'
                  | 'productCost'
                  | 'productGrams'
                  | 'unitAmount'
                  | 'unitType'
                >
              >
            | undefined;
          supplier?:
            | Maybe<
                {
                  __typename?: 'Supplier' | undefined;
                } & Pick<Supplier, 'id' | 'displayName' | 'email'>
              >
            | undefined;
        })[];
    };
};

interface IInventorySectionProps {
  stocktakeSection:
    | ({
        __typename?: 'StocktakeSection' | undefined;
      } & Pick<StocktakeSection, 'id' | 'stocktakeId' | 'totalItems'> & {
          section: {
            __typename?: 'Section' | undefined;
          } & Pick<Section, 'id' | 'displayName' | 'totalRequiredItems'>;
          stocktakeIngredients: {
            __typename?: 'StocktakeIngredient' | undefined;
          } & StocktakeIngredientProps[];
        })
    | undefined;
  isLastSection: boolean;
}

export const InventorySection: FC<IInventorySectionProps> = ({
  stocktakeSection,
  isLastSection,
}) => {
  const {
    appWidth,
    toolTips,
    account,
    user,
    selectedVenueObject,
    dispatch,
  } = useContext(LayoutContext);
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeDimensions(pageWidthRef);
  const supplierRef = useRef<HTMLLIElement>(null);
  const supplierDimensions = useResizeDimensions(supplierRef);
  const totalItemsRef = useRef<HTMLLIElement>(null);
  const totalItemsDimensions = useResizeDimensions(totalItemsRef);
  const ingredientPurchaseCostRef = useRef<HTMLLIElement>(null);
  const ingredientPurchaseCostDimensions = useResizeDimensions(
    ingredientPurchaseCostRef
  );

  const {
    totalItems,
    ingredientPurchaseCost,
    ingredientSupplier,
  } = toolTips.stocktake;
  const subHeading = 'Ordered by most recently completed stocktake at the top';

  const onSupplierClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { supplier: !ingredientSupplier },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Ingredient Supplier',
        content: `The name of the ingredient supplier`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: supplierDimensions.top,
        xAxis: supplierDimensions.width,
      },
    });
  };

  const onTotalItemsClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { totalItems: !totalItems },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Total Items',
        content: `The total ingredient count in this section`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: totalItemsDimensions.top,
        xAxis: totalItemsDimensions.width,
      },
    });
  };

  const onPurchaseCostClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { ingredientPurchaseCost: !ingredientPurchaseCost },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Ingredient Purchase Cost',
        content: `What the total cost of the ingredient is when purchased`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: ingredientPurchaseCostDimensions.top,
        xAxis: ingredientPurchaseCostDimensions.width,
      },
    });
  };

  const handleEditIngredient = (
    ingredientId: string,
    ingredientProductId: string
  ) => {
    dispatch({
      type: 'SELECT_INGREDIENT',
      payload: ingredientId,
    });
    dispatch({
      type: 'SET_INGREDIENT_USER_EVENT',
      payload: 'edit',
    });
    dispatch({
      type: 'SELECT_INGREDIENT_PRODUCT',
      payload: ingredientProductId,
    });
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    }
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow $loading={false}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col>
            <Label>Total</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow $loading={false}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Total</Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Label className="hasToolTipIcon">Purchase Cost</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow $loading={false}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Total</Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow $loading={false}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col onClick={onSupplierClick} ref={supplierRef}>
            <Label className="hasToolTipIcon">Supplier</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Total</Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow $loading={false}>
          <Col />
          <Col>
            <Label>Name</Label>
          </Col>
          <Col onClick={onSupplierClick} ref={supplierRef}>
            <Label className="hasToolTipIcon">Supplier</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Total</Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const getTableRows = (col: StocktakeIngredientProps, idx) => {
    const ingredientProduct = col.ingredient.ingredientProducts.find(
      (ip) => ip.venueId === selectedVenueObject?.id
    );
    if (!ingredientProduct) {
      return;
    }
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <Row
          key={idx}
          onClick={() =>
            handleEditIngredient(col.ingredient.id, ingredientProduct.id)
          }
        >
          <Col>
            <Span color="grey">{col.ingredient.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.quantity}</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row
          key={idx}
          onClick={() =>
            handleEditIngredient(col.ingredient.id, ingredientProduct.id)
          }
        >
          <Col>
            <Span color="grey">{col.ingredient.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.quantity}</Span>
          </Col>
          <Col>
            <Span color="grey">
              {convertCostCleanly(ingredientProduct.size?.productCost)}
            </Span>
          </Col>
          <Col
            onClick={() =>
              handleEditIngredient(col.ingredient.id, ingredientProduct.id)
            }
          >
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <Row
          key={idx}
          onClick={() =>
            handleEditIngredient(col.ingredient.id, ingredientProduct.id)
          }
        >
          <Col>
            <Span color="grey">{col.ingredient.displayName}</Span>
          </Col>
          <Col>
            <Span>{ingredientProduct.supplier?.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.quantity}</Span>
          </Col>
          <Col>
            <LayoutColumn
              className="layoutColumn"
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Span color="grey">
                {convertCostCleanly(ingredientProduct.size?.productCost)}
              </Span>
              <Span>|</Span>
              <Span color="grey" className="last">
                {convertGramsCleanly(ingredientProduct.size?.productGrams)}
              </Span>
            </LayoutColumn>
          </Col>
          <Col
            onClick={() =>
              handleEditIngredient(col.ingredient.id, ingredientProduct.id)
            }
          >
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row
          key={idx}
          onClick={() =>
            handleEditIngredient(col.ingredient.id, ingredientProduct.id)
          }
        >
          <Col />
          <Col>
            <Span color="grey">{col.ingredient.displayName}</Span>
          </Col>
          <Col>
            <Span>{ingredientProduct.supplier?.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.quantity}</Span>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
              className="layoutColumn"
            >
              <Span color="grey">
                {convertCostCleanly(ingredientProduct.size?.productCost)}
              </Span>
              <Span>|</Span>
              <Span color="grey" className="last">
                {convertGramsCleanly(ingredientProduct.size?.productGrams)}
              </Span>
            </LayoutColumn>
          </Col>
          <Col
            onClick={() =>
              handleEditIngredient(col.ingredient.id, ingredientProduct.id)
            }
          >
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  };

  const sectionDisplayName = stocktakeSection?.section.displayName || 'section';

  return (
    <Card ref={pageWidthRef} withCardLink={isLastSection ? false : true}>
      <Header
        icon={<RecipeBook size="small" />}
        heading={sectionDisplayName}
        subHeading={subHeading}
        toolTip={{
          type: 'SET_STOCKTAKE_TOOL_TIPS',
          heading: `${sectionDisplayName} Inventory`,
          content:
            'This is a list of all the ingredients you have included in your Stocktake Section',
        }}
      />
      <Content fullWidth>
        <Table>
          <DotsLoading
            isLoading={false}
            size="large"
            lineHeight={10}
            color="default"
          />
          {getTableHeader()}
          {stocktakeSection?.stocktakeIngredients
            ? stocktakeSection?.stocktakeIngredients?.map((col, idx) =>
                getTableRows(col, idx)
              )
            : null}
        </Table>
      </Content>
    </Card>
  );
};

export default memo(InventorySection);
