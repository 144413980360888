import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { useResetPasswordWithTokenMutation } from '../../../generated/graphql';
import { Input } from '../../shared/formElements';
import { DotsLoading } from '../../shared/loading';
import {
  Container,
  Error,
  Form,
  Heading,
  ResetPasswordButton,
} from './ResetPasswordWithToken.styles';
import qs from 'qs';
import { decode } from 'jsonwebtoken';
import { yupResolver } from '@hookform/resolvers/yup';

const ResetPasswordWithToken: FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const [
    resetPasswordWithToken,
    { loading, error },
  ] = useResetPasswordWithTokenMutation();
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const token = qs.parse(location.search, { ignoreQueryPrefix: true }).token as
    | string
    | undefined;

  const tokenPayload =
    token &&
    (decode(token) as {
      userId?: string;
      tokenVersion?: number;
      iat?: number;
      exp?: number;
    } | null);

  const tokenError = (() => {
    if (!(tokenPayload && tokenPayload.userId))
      return 'The password reset request is invalid. Please request another password reset from the login page.';

    const now = new Date().getTime() / 1000; // Unix time in seconds
    if (now > (tokenPayload.exp || 0))
      return "It's been too long since the reset password email was sent. For security reasons, please request another password reset from the login page.";

    return null;
  })();

  const onSubmit = async (formData) => {
    const { newPassword } = formData;

    if (!loading && typeof token === 'string') {
      try {
        const response = await resetPasswordWithToken({
          variables: {
            input: {
              passwordResetToken: token,
              newPassword,
            },
          },
        });

        if (response?.data?.resetPasswordWithToken.successful) {
          history.push('/sign-in');
        }
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  const responseErrors = error && (
    <Error fontSize="small">{error.message}</Error>
  );

  const tokenErrorDisplay = tokenError && (
    <Error fontSize="small">{tokenError}</Error>
  );

  const resetPasswordForm = (
    <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Input
        ref={register}
        label="New Password"
        name="newPassword"
        type="password"
        errorText={errors.newPassword?.message}
      />
      <Input
        ref={register}
        label="Confirm New Password"
        name="newPasswordConfirm"
        type="password"
        errorText={errors.newPasswordConfirm?.message}
      />
      <ResetPasswordButton
        type="submit"
        onClick={handleSubmit(onSubmit)}
        color="primary"
        disabled={loading}
      >
        <DotsLoading
          text={(loading) => `${loading ? 'Resetting' : 'Reset'} Password`}
          isLoading={loading}
          size="small"
          lineHeight={10}
          noMargin
        />
      </ResetPasswordButton>
    </Form>
  );

  return (
    <Container>
      <Heading>Reset Password</Heading>
      {responseErrors}
      {tokenErrorDisplay || resetPasswordForm}
    </Container>
  );
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required('New password is required'),
  newPasswordConfirm: Yup.string().oneOf(
    [Yup.ref('newPassword'), undefined],
    'Passwords must match'
  ),
});

export default ResetPasswordWithToken;
