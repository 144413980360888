import React, { FC, useContext } from 'react';
import { Container, TotalText } from './Summary.styles';
import {
  ListGroup,
  ListGroupRow,
  Label,
} from '../../../../shared/typefaces/Typefaces.styles';
import { useFormContext } from 'react-hook-form';
import {
  convertTimeMinutesCleanly,
  convertGramsCleanly,
} from '../../../../../utils/helper';
import LayoutColumn from '../../../../shared/layout/layoutColumn';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { ISummaryProps } from './summary.types';

const Summary: FC<ISummaryProps> = ({
  totalStaffTime,
  totalProcessTime,
  totalTime,
  totalGrams,
  serves,
  liquidRecipe,
}) => {
  const { appWidth } = useContext(LayoutContext);
  const { register } = useFormContext();

  const liquid = liquidRecipe === 'liquid' ? true : false;

  return (
    <Container>
      <input
        ref={register}
        type="hidden"
        name="totalGrams"
        value={Number(totalGrams)}
      />
      <input
        ref={register}
        type="hidden"
        name="totalStaffTime"
        value={Number(totalStaffTime)}
      />
      <input
        ref={register}
        type="hidden"
        name="totalTime"
        value={Number(totalTime)}
      />
      <LayoutColumn
        colOneWidth="auto"
        colTwoWidth={appWidth < theme.mQ.mobileM ? '240px' : '270px'}
        padding="none"
      >
        <section />
        <ListGroup noBorder noPadding>
          <ListGroupRow colOneWidth="auto" colTwoWidth="100px">
            <Label color="grey">Staff Time</Label>
            <TotalText color="grey">
              {convertTimeMinutesCleanly(totalStaffTime)}
            </TotalText>
          </ListGroupRow>
          <ListGroupRow colOneWidth="auto" colTwoWidth="100px">
            <Label color="grey">Process Time</Label>
            <TotalText color="grey">
              {convertTimeMinutesCleanly(totalProcessTime)}
            </TotalText>
          </ListGroupRow>
          <ListGroupRow colOneWidth="auto" colTwoWidth="100px">
            <Label color="black">Total Time</Label>
            <TotalText color="black">
              {convertTimeMinutesCleanly(totalTime)}
            </TotalText>
          </ListGroupRow>
          <ListGroupRow colOneWidth="auto" colTwoWidth="100px">
            <Label color="black">
              {liquid ? 'mLs Per Serve' : 'Grams Per Serve'}
            </Label>
            <TotalText color="black">
              {totalGrams && serves
                ? convertGramsCleanly(totalGrams / serves, liquid)
                : convertGramsCleanly(totalGrams, liquid)}
            </TotalText>
          </ListGroupRow>
          <ListGroupRow colOneWidth="auto" colTwoWidth="100px">
            <Label color="black">{liquid ? 'Total mLs' : 'Total Grams'}</Label>
            <TotalText color="black">
              {convertGramsCleanly(totalGrams, liquid)}
            </TotalText>
          </ListGroupRow>
        </ListGroup>
      </LayoutColumn>
    </Container>
  );
};

export default Summary;
