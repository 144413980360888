import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  AccountType,
  AddOnApp,
  RecipeDocument,
  useSetOriginalRecipeVersionMutation,
  useSetSelectedRecipeVersionMutation,
} from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import {
  convertCostCleanly,
  getCompareRecipeData,
  isEmpty,
  sortByNestedObject,
  validEndDate,
} from '../../../../../utils/helper';
import {
  blurContent,
  convertPercentageCleanly,
  getPercentageDifference,
} from '../../../../../utils/helper/numbers';
import { Button } from '../../../../shared/button';
import { Card, Content, Header } from '../../../../shared/card';
import { LayoutColumn } from '../../../../shared/layout';
import {
  ICompareProps,
  ICompareStateProps,
} from '../../../../shared/recipe/recipe.types';
import {
  ColTitle,
  H3,
  Label,
  Span,
} from '../../../../shared/typefaces/Typefaces.styles';
import { Copy, Edit, Share, Tick } from './../../../../shared/icons';
import {
  Col,
  CTAButton,
  HeaderRow,
  Icon,
  Row,
  Table,
  ToolTipIcon,
} from './Compare.styles';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import useResizeDimensions from '../../../../../utils/customHooks/useResizeDimensions';
import {
  checkIfAccountIsNotComplete,
  useRestrictionCheck,
} from '../../../../../utils/helper/account';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';

const Compare: FC<ICompareProps> = ({
  data,
  venue,
  updateRecipeOnSelect,
  updateOriginalRecipeFromTemplate,
}) => {
  const {
    appWidth,
    account,
    user,
    selectedRecipe,
    selectedRecipeVersion,
    selectedVenueObject,
    toolTips,
    newRecipe,
    dispatch,
  } = useContext(LayoutContext);

  const [state, setState] = useState<ICompareStateProps>({
    originalVersionId: '',
    originalVersionServeProfit: undefined,
  });
  const [
    setSelectedRecipeVersionMutation,
    { loading },
  ] = useSetSelectedRecipeVersionMutation();
  const [
    setOrignalRecipeVersionMutation,
  ] = useSetOriginalRecipeVersionMutation();
  const [newSelectedRecipeVersion, setNewSelectedRecipeVersion] = useState('');
  const { originalVersionId, originalVersionServeProfit } = state;
  const sortedRecipeVersions = sortByNestedObject(
    'displayName',
    data?.data?.recipe?.recipe?.recipeVersions
  );
  const showRestrictionPopup = useRestrictionPopup();
  const checkRecipeProfitRestrictions = useRestrictionCheck([
    'CALCULATE_RECIPE_PROFIT',
  ]);
  const checkFoodCostRestrictions = useRestrictionCheck(['FOOD_COST']);
  const checkDuplicateRecipeRestrictions = useRestrictionCheck([
    'MAX_RECIPE_VERSIONS',
  ]);
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const pageDimensions = useResizeDimensions(pageWidthRef);
  const headingsRef = useRef<HTMLUListElement>(null);
  const headingsDimensions = useResizeDimensions(headingsRef, toolTips.resize);

  const { watch } = useFormContext();

  useEffect(() => {
    if (loading && selectedRecipeVersion !== newSelectedRecipeVersion) {
      dispatch({
        type: 'SELECT_RECIPE_VERSION',
        payload: newSelectedRecipeVersion,
      });
    }
  }, [loading, newSelectedRecipeVersion, selectedRecipeVersion, dispatch]);

  useEffect(() => {
    if (
      data?.data?.recipe.successful &&
      data?.data?.recipe?.recipe?.recipeVersions.length !== 0 &&
      data?.data?.recipe?.recipe?.recipeVersions.length !== 1
    ) {
      const originalVersion = data?.data?.recipe?.recipe?.recipeVersions.find(
        (version) => version.original
      );

      const setOriginRecipe = async (newOriginalVerisionId: string) => {
        try {
          const response = await setOrignalRecipeVersionMutation({
            variables: {
              input: {
                recipeId: selectedRecipe!,
                recipeVersionId: newOriginalVerisionId,
              },
            },
            refetchQueries: [
              {
                query: RecipeDocument,
                variables: {
                  input: {
                    venueId: selectedVenueObject?.id!,
                    recipeId: selectedRecipe!,
                  },
                },
              },
            ],
            awaitRefetchQueries: true,
          });
          if (response.data?.setOriginalRecipeVersion.successful) {
            setState({ ...state, originalVersionId: originalVersionId });
          }
        } catch (err) {
          console.log('err ###', err);
        }
      };

      if (!originalVersion) {
        const recipeVersions = data?.data?.recipe?.recipe?.recipeVersions;
        if (recipeVersions) {
          const createdAtTimestamps = recipeVersions?.map((version) =>
            Number(version.createdAt)
          );

          const originalCreatedAtTimestamp = Math.min(
            ...createdAtTimestamps
          ).toString();

          const newOriginalVerision = recipeVersions?.find(
            (version) => version.createdAt === originalCreatedAtTimestamp
          );

          const originalVersionId = newOriginalVerision
            ? newOriginalVerision.id
            : recipeVersions[0].id;
          setOriginRecipe(originalVersionId);
        }
      } else {
        if (
          isEmpty(originalVersionId) &&
          !isEmpty(sortedRecipeVersions && !newRecipe)
        ) {
          setState({ ...state, originalVersionId: originalVersion.id });
        }
      }
    }
  }, [
    data,
    selectedRecipe,
    selectedVenueObject?.id,
    state,
    newRecipe,
    originalVersionId,
    sortedRecipeVersions,
    setOrignalRecipeVersionMutation,
  ]);

  const restrictionCheck = checkRecipeProfitRestrictions({
    data: account?.type === AccountType.BusinessStarter,
  });
  const restrictionFoodCostCheck = checkFoodCostRestrictions({
    data: account?.type !== AccountType.Registered,
  });

  const calculatorAddOn = account?.addOns.find(
    (a) => a.app === AddOnApp.Calculator
  );
  const { endDateIsValid } = validEndDate(calculatorAddOn?.endDate);
  const showProfitContent = endDateIsValid || restrictionCheck.isPass;
  const showFoodCostContent = endDateIsValid || restrictionFoodCostCheck.isPass;

  if (!data) {
    return <H3>Loading..</H3>;
  }

  const handleRecipeRevenueUpgrade = () => {
    showRestrictionPopup(
      restrictionPopupMessages.cannotDisplayRecipeVersionProfit
    );
  };

  const handleEditRecipe = (recipeVersionId) => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountEditRecipe,
      });
    }
    dispatch({ type: 'SLIDER_PAGE', payload: 'editRecipe' });
    dispatch({ type: 'SELECT_RECIPE_VERSION', payload: recipeVersionId });
    dispatch({ type: 'DUPLICATE_RECIPE', payload: false });
    dispatch({ type: 'SCALE_RECIPE', payload: false });
    if (toolTips.editRecipe) {
      dispatch({
        type: 'SET_TOOL_TIPS',
        payload: { editRecipe: false, recipeIngredients: false },
      });
    }
  };

  const handleDuplicateRecipe = () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      const noAccountMessage = sortedRecipeVersions
        ? sortedRecipeVersions?.length > 1
          ? overlayConstants.noAccountDuplicateRecipe
          : overlayConstants.noAccountEditRecipe
        : overlayConstants.noAccountEditRecipe;
      dispatch({
        type: 'SET_OVERLAY',
        payload: noAccountMessage,
      });
    }
    const currentRecipeVersions = sortedRecipeVersions?.filter((v) => v.active);
    const totalRecipeVersions = currentRecipeVersions?.length || 0;

    const restrictionCheck = checkDuplicateRecipeRestrictions({
      // Adding one recipe version
      numRecipeVersions: totalRecipeVersions + 1,
    });

    if (restrictionCheck.isPass) {
      updateOriginalRecipeFromTemplate();
      dispatch({ type: 'DUPLICATE_RECIPE', payload: true });
      dispatch({ type: 'SCALE_RECIPE', payload: false });
      dispatch({ type: 'SLIDER_PAGE', payload: 'editDuplicateRecipe' });
    } else {
      showRestrictionPopup(
        restrictionPopupMessages.cannotSelectRecipeVersionDueToMaxRecipeVersions
      );
    }
  };

  const handleSelectRecipe = async (versionId: string) => {
    try {
      setNewSelectedRecipeVersion(versionId);
      const response = await setSelectedRecipeVersionMutation({
        variables: {
          input: {
            recipeId: selectedRecipe!,
            recipeVersionId: versionId,
          },
        },
        refetchQueries: [
          {
            query: RecipeDocument,
            variables: {
              input: {
                venueId: selectedVenueObject?.id!,
                recipeId: selectedRecipe!,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      if (response.data?.setSelectedRecipeVersion.successful) {
        if (selectedRecipeVersion !== versionId) {
          updateRecipeOnSelect(versionId);
        }
      }
    } catch (err) {
      console.log('err ###', err);
    }
  };

  const onClickHasOnlyProcessTimeIcon = (versionName: string) => {
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: `${versionName} Profit Difference`,
        content:
          'You are not seeing any profit difference here because this recipe version only has a process time items & you have not entered in your Venue Costs. Scroll to the bottom of this page & click on links to update Venue Costs.',
        buttonText: 'Got it',
        pageWidth: pageDimensions.width,
        yAxis: headingsDimensions.top,
        xAxis: headingsDimensions.width,
      },
    });
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow>
          <Col>
            <Label>Recipe Version</Label>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Label>Serve Profit</Label>
              {toolTips.editRecipe ? <Span /> : <Span>*</Span>}
              <Label className="last">
                {toolTips.editRecipe ? 'Edit' : 'Serves'}
              </Label>
            </LayoutColumn>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow>
          <Col>
            <Label>Recipe Version</Label>
          </Col>
          <Col>
            <Label>%</Label>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Label>Serve Profit</Label>
              {toolTips.editRecipe ? <Span /> : <Span>*</Span>}
              <Label className="last">
                {toolTips.editRecipe ? 'Edit' : 'Serves'}
              </Label>
            </LayoutColumn>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow>
          <Col>
            <Label>Version Name</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Label>Serve Profit</Label>
              {toolTips.editRecipe ? <Span /> : <Span>*</Span>}
              <Label className="last">
                {toolTips.editRecipe ? 'Edit' : 'Serves'}
              </Label>
            </LayoutColumn>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow>
          <Col>
            <Label>Version Name</Label>
          </Col>
          <Col>
            <Label>Profit Difference</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Label>Serve Profit</Label>
              {toolTips.editRecipe ? <Span /> : <Span>*</Span>}
              <Label className="last">
                {toolTips.editRecipe ? 'Edit' : 'Serves'}
              </Label>
            </LayoutColumn>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow>
          <Col />
          <Col>
            <Label>Recipe Version Name</Label>
          </Col>
          <Col>
            <Label>Profit Difference</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Label>Serve Profit</Label>
              {toolTips.editRecipe ? <Span /> : <Span>*</Span>}
              <Label className="last">
                {toolTips.editRecipe ? 'Edit' : 'Serves'}
              </Label>
            </LayoutColumn>
          </Col>
        </HeaderRow>
      );
    }
  };

  const getTableRows = (col, idx) => {
    if (col.active) {
      const recipeData = getCompareRecipeData({
        recipe: data,
        venue,
        selectedVersion: col,
        salesPricePerServeFormData: watch('salesPricePerServe'),
      });

      if (
        recipeData.original &&
        originalVersionServeProfit !== 0 &&
        recipeData.salesPricePerServe &&
        recipeData.weeklySalesPerServe &&
        originalVersionServeProfit !== recipeData.profitPerServe
      ) {
        setState({
          ...state,
          originalVersionServeProfit: recipeData.profitPerServe || 0,
        });
      }

      const difference = getPercentageDifference(
        originalVersionServeProfit,
        recipeData.profitPerServe
      );

      const differenceIsPositive =
        !!difference && difference !== 0 && Math.sign(difference) === 1;

      const isOriginal = recipeData.versionId === originalVersionId;
      const editRecipe =
        process.env.REACT_APP_EDIT_ORIGINAL_RECIPE === 'true' || !isOriginal;

      const getDifferencePercentage = () => {
        if (
          recipeData.versionHasOnlyProcessTime &&
          recipeData.totalTimeCost === 0
        ) {
          return (
            <ToolTipIcon
              onClick={() =>
                onClickHasOnlyProcessTimeIcon(recipeData.versionName)
              }
            />
          );
        }

        return (
          <Span
            className={`${
              !showProfitContent && !isOriginal && 'blur hasSeeIcon'
            }`}
            color={differenceIsPositive && !isOriginal ? 'secondary' : 'faded'}
            fontWeight={
              differenceIsPositive && !isOriginal ? 'medium' : 'regular'
            }
            onClick={() => !showProfitContent && handleRecipeRevenueUpgrade()}
          >
            {isOriginal
              ? 'original'
              : showProfitContent
              ? convertPercentageCleanly(difference || 0)
              : blurContent(convertPercentageCleanly(difference || 0))}
          </Span>
        );
      };

      const differencePercentage = getDifferencePercentage();
      const foodCostPercentage =
        recipeData.profitPerServe !== 0 && showFoodCostContent
          ? convertPercentageCleanly(recipeData.foodCostPercentage)
          : blurContent(
              convertPercentageCleanly(recipeData.foodCostPercentage)
            );

      if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
        return (
          <Row
            key={col.id}
            onClick={() => handleSelectRecipe(col.id)}
            ref={headingsRef}
          >
            <Col>
              <ColTitle
                selected={recipeData.versionId === selectedRecipeVersion}
              >
                {recipeData.versionName}{' '}
                {recipeData.versionId === selectedRecipeVersion && (
                  <Tick size="smaller" color="secondary" />
                )}
              </ColTitle>
            </Col>
            <Col>
              <LayoutColumn
                colOneWidth="65px"
                colTwoWidth="5px"
                colThreeWidth="42px"
              >
                <Span
                  color="grey"
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {convertCostCleanly(recipeData.profitPerServe)}
                </Span>
                <Span>*</Span>
                <Span color="grey">{recipeData.serves}</Span>
              </LayoutColumn>
            </Col>
            <Col onClick={() => handleEditRecipe(recipeData.versionId)}>
              <Icon>
                <Edit size="small" color="grey" faded />
              </Icon>
            </Col>
          </Row>
        );
      } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
        return (
          <Row
            key={idx}
            onClick={() => handleSelectRecipe(recipeData.versionId)}
            ref={headingsRef}
          >
            <Col>
              <ColTitle
                selected={recipeData.versionId === selectedRecipeVersion}
              >
                {recipeData.versionName}{' '}
                {recipeData.versionId === selectedRecipeVersion && (
                  <Tick size="smaller" color="secondary" />
                )}
              </ColTitle>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showFoodCostContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showFoodCostContent && handleRecipeRevenueUpgrade()
                }
              >
                {foodCostPercentage}
              </Span>
            </Col>
            <Col>
              <LayoutColumn
                colOneWidth="50px"
                colTwoWidth="1px"
                colThreeWidth="50px"
              >
                <Span
                  color="grey"
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(recipeData.profitPerServe)
                    : blurContent(
                        convertCostCleanly(recipeData.profitPerServe)
                      )}
                </Span>

                <Span>*</Span>
                <Span color="grey">{recipeData.serves}</Span>
              </LayoutColumn>
            </Col>
            <Col onClick={() => handleEditRecipe(recipeData.versionId)}>
              <Icon>
                <Edit size="small" color="grey" faded />
              </Icon>
            </Col>
          </Row>
        );
      } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
        return (
          <Row
            key={idx}
            onClick={() => handleSelectRecipe(recipeData.versionId)}
            ref={headingsRef}
          >
            <Col>
              <ColTitle
                selected={recipeData.versionId === selectedRecipeVersion}
              >
                {recipeData.versionName}{' '}
                {recipeData.versionId === selectedRecipeVersion && (
                  <Tick size="smaller" color="secondary" />
                )}
              </ColTitle>
            </Col>
            <Col>
              <Span>{differencePercentage}</Span>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showFoodCostContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showFoodCostContent && handleRecipeRevenueUpgrade()
                }
              >
                {foodCostPercentage}
              </Span>
            </Col>
            <Col>
              <LayoutColumn
                colOneWidth="50px"
                colTwoWidth="1px"
                colThreeWidth="50px"
              >
                <Span
                  color="grey"
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(recipeData.profitPerServe)
                    : blurContent(
                        convertCostCleanly(recipeData.profitPerServe)
                      )}
                </Span>
                <Span>*</Span>
                <Span color="grey">{recipeData.serves}</Span>
              </LayoutColumn>
            </Col>
            <Col onClick={() => handleEditRecipe(recipeData.versionId)}>
              <Icon>
                <Edit size="small" color="grey" faded />
              </Icon>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row
            key={idx}
            onClick={() => handleSelectRecipe(recipeData.versionId)}
            ref={headingsRef}
          >
            <Col>
              {recipeData.versionId === selectedRecipeVersion && (
                <Tick size="smaller" color="secondary" />
              )}
            </Col>
            <Col>
              <Span color="grey" fontSize="default">
                {recipeData.versionName}
              </Span>
            </Col>
            <Col>
              <Span>{differencePercentage}</Span>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showFoodCostContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showFoodCostContent && handleRecipeRevenueUpgrade()
                }
              >
                {foodCostPercentage}
              </Span>
            </Col>
            <Col>
              <LayoutColumn
                colOneWidth="50px"
                colTwoWidth="1px"
                colThreeWidth="50px"
              >
                <Span
                  color="grey"
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(recipeData.profitPerServe)
                    : blurContent(
                        convertCostCleanly(recipeData.profitPerServe)
                      )}
                </Span>
                <Span>*</Span>
                <Span color="grey">{recipeData.serves}</Span>
              </LayoutColumn>
            </Col>
            <Col onClick={() => handleEditRecipe(recipeData.versionId)}>
              {editRecipe && (
                <Icon>
                  <Edit size="small" color="grey" faded />
                </Icon>
              )}
            </Col>
          </Row>
        );
      }
    }
  };

  return (
    <Card ref={pageWidthRef}>
      <Header
        toolTip={{
          type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
          heading: 'Recipe Versions',
          content:
            'There are many ways to make a single recipe. Recipe Versions helps you to tinker with each recipe version to quickly discover the optimal balance between profit & flavour accurately',
        }}
        icon={<Copy size="small" />}
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? 'Compare Versions'
            : 'Compare Recipe Versions'
        }
        subHeading="Duplicate recipe versions to compare recipe revenue, return on investment & optimise costs"
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={handleDuplicateRecipe}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <CTAButton color="primary" onClick={handleDuplicateRecipe}>
              {sortedRecipeVersions?.length === 1
                ? 'Edit Recipe'
                : 'Duplicate Recipe'}
            </CTAButton>
          )
        }
      />
      <Content fullWidth>
        <Table>
          {getTableHeader()}
          {sortedRecipeVersions?.map((col, idx) => getTableRows(col, idx))}
        </Table>
      </Content>
    </Card>
  );
};

export default memo(Compare);
