import styled from 'styled-components/macro';
import { Card } from '../../../../shared/card';

export const Container = styled(Card)`
  max-width: 500px;
  margin: 100px auto;
`;

export const Header = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 14px 14px 0 0;
  padding: 5px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const Content = styled.div``;

export const Table = styled.div`
  padding: 10px 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;

  .label {
    margin: 0;
  }

  .status {
    margin: 0 10px;
  }
`;

export const Right = styled.div``;

export const Loading = styled.div`
  margin: 100px auto;
`;
