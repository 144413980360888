import React from 'react';
import { FC, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { LogoLoading, DotsLoading } from '../../../loading';
import { Span } from '../../../typefaces/Typefaces.styles';
import {
  Highlight,
  Image,
  ImageContainer,
  Item,
  Items,
} from '../selector/Selector.styles';
import fruitDish from '../../../../../images/CTA5.png';
import { IItemProps } from '../selector';

interface IOptionsProps {
  options?: IItemProps[];
  loadSuccessful?: boolean;
  signUpIsLoading?: boolean;
  selectedRecipeTemplateId: string;
  onSelect: (recipeId: string) => void;
}

export const Options: FC<IOptionsProps> = ({
  options,
  loadSuccessful,
  signUpIsLoading,
  selectedRecipeTemplateId,
  onSelect,
}) => {
  const { account } = useContext(LayoutContext);

  const CTARecipeOption = (
    <>
      <ImageContainer>
        <Highlight>{!account ? 'GET RESULTS' : 'QUICK START'} </Highlight>
        <Image src={fruitDish} />
      </ImageContainer>
      <Span className="text">Select any recipe {`>>`}</Span>
    </>
  );

  if (!options) {
    if (loadSuccessful) {
      return (
        <Items>
          <Item key="recipesComingSoon">
            <ImageContainer>
              <LogoLoading size={100} />
            </ImageContainer>
            <Span className="text">Recipes Coming Soon</Span>
          </Item>
        </Items>
        // Add option here for user to create one.
      );
    }
    return <Items>{getLoadingItems(9)}</Items>;
  }
  return (
    <Items>
      <Item
        key="click"
        onClick={() =>
          !signUpIsLoading && !signUpIsLoading && onSelect(options[0].id)
        }
      >
        {CTARecipeOption}
      </Item>

      {options.map(({ id, recipeName, imageUrl }) => {
        return imageUrl ? (
          <Item key={id} onClick={() => !signUpIsLoading && onSelect(id)}>
            <ImageContainer>
              <Image src={imageUrl} />
            </ImageContainer>
            <Span className="text">
              <DotsLoading
                isLoading={selectedRecipeTemplateId === id && signUpIsLoading}
                text={() => recipeName}
                color="primary"
                className="loadingDots"
                noMargin
              />
            </Span>
          </Item>
        ) : null;
      })}
    </Items>
  );
};

const getLoadingItems = (amount: number) => {
  let loadingItems: JSX.Element[] = [];

  for (let i = 0; i < amount; i++) {
    loadingItems.push(
      <Item key={i.toString()}>
        <ImageContainer isLoading />
        <Span className="text">-</Span>
      </Item>
    );
  }

  return loadingItems;
};
