import React, { FC, useContext } from 'react';
import { ArrowContainer, ArrowButton } from './MenuToggle.styles';
import { Arrow } from '../../icons';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { theme } from '../../../../styles/theme';
import { IMenuToggleProps } from './menuToggle.types';
import { useLocation } from 'react-router-dom';

const MenuToggle: FC<IMenuToggleProps> = ({ width }) => {
  const { pathname } = useLocation();
  const { isOpen, desktopMenuToggle, menuToggle, dispatch } = useContext(
    LayoutContext
  );
  if (pathname === '/') return null;

  const handleArrowClick = () => {
    if (width > theme.mQ.laptop) {
      if (desktopMenuToggle) {
        dispatch({ type: 'TOGGLE_DESKTOP_MENU' });
        dispatch({ type: 'OPEN_MENU' });
      } else {
        dispatch({ type: 'TOGGLE_DESKTOP_MENU' });
        dispatch({ type: 'CLOSE_MENU' });
        if (menuToggle) {
          dispatch({ type: 'TOGGLE_MENU' });
        }
      }
    } else {
      if (menuToggle) {
        dispatch({ type: 'TOGGLE_MENU' });
        dispatch({ type: 'CLOSE_MENU' });
      } else {
        dispatch({ type: 'TOGGLE_MENU' });
        dispatch({ type: 'OPEN_MENU' });
        if (desktopMenuToggle) {
          dispatch({ type: 'TOGGLE_DESKTOP_MENU' });
        }
      }
    }
  };

  return pathname !== '/' ? (
    <ArrowContainer isOpen={isOpen} onClick={handleArrowClick}>
      <ArrowButton>
        <Arrow size="tiny" color="grey" rotate={isOpen ? 180 : 0} />
      </ArrowButton>
    </ArrowContainer>
  ) : null;
};

export default MenuToggle;
