export const validateNumberValues = (
  value: string | number | null | undefined
) => {
  if (!value || value === null) {
    return true;
  }
  if (isNaN(Number(value))) {
    return false;
  }
  return true;
};

export const validatePositiveNumberValues = (
  value: string | number | null | undefined
) => {
  if (!value || value === null) {
    return true;
  }
  if (Number(value) <= 0) {
    return false;
  }
  return true;
};
