import React from 'react';

/**
 * Used to verify the build version deployed to certain stages.
 * Increment when pushing a new version.
 * I'm sure there is a better way to do this but let's start somewhere...
 */
export default function BuildNo() {
  const buildNumber = 1;

  return <div>Build number: {buildNumber}</div>;
}
