import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { Card, Header, Content } from '../../../../shared/card';
import { Email, Share } from '../../../../shared/icons';
import { theme } from '../../../../../styles/theme';
import { Button } from '../../../../shared/button';
import {
  Container,
  Row,
  Col,
  Table,
  Description,
  Update,
} from './Notification.styles';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { mockData } from '../../../../../utils/mockData';

const Notification: FC = () => {
  const { appWidth, dispatch } = useContext(LayoutContext);

  const updateNotifications = () => {
    dispatch({ type: 'SLIDER_PAGE', payload: 'updateNotifications' });
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row>
          <Col>
            <Label>Notification Type</Label>
          </Col>
          <Col>
            <Label>Status</Label>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col />
          <Col>
            <Label>Notification Type</Label>
          </Col>
          <Col>
            <Label>Status</Label>
          </Col>
        </Row>
      );
    }
  };

  const getTableRows = (col, idx) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row key={idx}>
          <Col>
            <div>
              <Span>{col.type}</Span>
              <Description color="faded" fontSize="small" fontWeight="semibold">
                {col.description}
              </Description>
            </div>
          </Col>
          <Col>
            <Span color={col.subscribed ? 'secondary' : 'faded'}>
              {col.subscribed ? 'subscribed' : 'unsubscribed'}
            </Span>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row key={idx}>
          <Col />
          <Col>
            <div>
              <Span>{col.type}</Span>
              <Description color="faded" fontSize="small" fontWeight="semibold">
                {col.description}
              </Description>
            </div>
          </Col>
          <Col>
            <Span color={col.subscribed ? 'secondary' : 'faded'}>
              {col.subscribed ? 'subscribed' : 'unsubscribed'}
            </Span>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Container>
      <Card>
        <Header
          icon={<Email size="small" />}
          heading={
            appWidth !== 0 && appWidth < theme.mQ.tablet
              ? 'Update Email Notifications'
              : 'Email Notifications'
          }
          subHeading="Actions speak louder than words! We will only email you with information that is valuable to you"
          button={
            appWidth !== 0 && appWidth < theme.mQ.tablet ? (
              <Button onClick={null} color="primary" asCircle>
                <Share color="white" size="small" />
              </Button>
            ) : (
              <Update color="default" inversed onClick={updateNotifications}>
                Update Notifications
              </Update>
            )
          }
        />
        <Content fullWidth>
          <Table>
            {getTableHeader()}
            {mockData.notifications.map((col, idx) => getTableRows(col, idx))}
          </Table>
        </Content>
      </Card>
    </Container>
  );
};

export default Notification;
