import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const ChartUp: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512 512"
      color={color}
      faded={faded}
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3546 3780 c-42 -13 -95 -68 -107 -112 -18 -68 19 -151 81 -183 24
-12 81 -15 302 -15 l273 0 -650 -650 c-504 -505 -655 -650 -674 -650 -19 0
-99 74 -340 314 -364 361 -350 351 -516 351 -164 0 -121 34 -813 -658 -649
-649 -631 -628 -612 -712 21 -93 96 -139 197 -120 34 6 104 72 623 591 454
453 591 584 610 584 19 0 96 -72 330 -304 360 -358 365 -361 520 -361 167 0
112 -45 878 719 l672 671 0 -268 c0 -300 3 -315 66 -363 31 -24 47 -29 94 -29
46 0 63 5 92 27 69 53 68 42 68 569 0 458 -1 474 -21 515 -12 26 -36 53 -57
66 l-37 23 -475 2 c-261 1 -488 -2 -504 -7z"
        />
      </g>
    </IconStyled>
  );
};

export default ChartUp;
