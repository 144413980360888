import React, { Fragment, useContext, useEffect } from 'react';
import { Container } from '../../LayoutSlider.styles';
import { LayoutContext } from '../../../../../../contexts/layoutContext';
import Brand from '../../../../../pages/brand/Brand';
import { isEmpty } from '../../../../../../utils/helper';
import Supplier from '../../../../../pages/supplier';
import Ingredient from '../../../../../pages/ingredient';
import IngredientDetails from '../../../../../pages/ingredientDetails';
import { EditBilling } from '../../../../../pages/editBilling/EditBilling';

const ExtraLayoutSlider = () => {
  const { isOpen, extraSliderToggle, extraSliderPage, dispatch } = useContext(
    LayoutContext
  );

  useEffect(() => {
    if (!isEmpty(extraSliderPage)) {
      dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
    }
  }, [extraSliderPage, dispatch]);

  const getExtraSliderPage = () => {
    switch (extraSliderPage) {
      case 'brand':
        return <Brand />;
      case 'supplier':
        return <Supplier />;
      case 'ingredient':
        return <Ingredient />;
      case 'editIngredient':
        return <IngredientDetails />;
      case 'editBilling':
        return <EditBilling />;
      default:
        return <Fragment />;
    }
  };

  return (
    <Container showSlider={extraSliderToggle} isOpen={isOpen} showAbove>
      {getExtraSliderPage()}
    </Container>
  );
};

export default ExtraLayoutSlider;
