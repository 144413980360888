import React from 'react';

import { LayoutColumnContainer } from './LayoutColumn.styles';
import { ILayoutColumnProps } from './layoutColumn.types';

const LayoutColumn: React.FC<ILayoutColumnProps> = ({
  children,
  noMargin,
  padding,
  colOneWidth,
  colTwoWidth,
  colThreeWidth,
  colFourWidth,
  className,
}) => {
  return (
    <LayoutColumnContainer
      className={className}
      noMargin={noMargin}
      padding={padding}
      colOneWidth={colOneWidth}
      colTwoWidth={colTwoWidth}
      colThreeWidth={colThreeWidth}
      colFourWidth={colFourWidth}
    >
      {children}
    </LayoutColumnContainer>
  );
};

export default LayoutColumn;
