import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export interface menuProps {
  vertical?: boolean;
  showAll?: boolean;
}
export interface menuItemProps {
  mobileHide?: boolean;
}
export interface menuLinkProps {
  highlight?: boolean;
  selected?: boolean;
  mobileHide?: boolean;
}

export const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 10px;
  z-index: ${({ theme }) => theme.zIndex.toggle};
  background-color: ${({ theme }) => theme.colors.white.default};

  .menuIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
  }

  .question {
    margin-right: 2px;
  }

  @media ${device.tablet} {
    .menuIcon {
      display: none;
    }
  }
`;

export const Logo = styled.img`
  height: 40px;
  margin-right: 5px;
`;

export const MenuItem = styled.li<menuItemProps>`
  display: ${({ mobileHide }) => (mobileHide ? 'none' : 'flex')};
  align-items: center;
  padding: 0 2px;
  font-size: ${({ theme }) => theme.fontSize.button};

  @media ${device.mobileM} {
    display: flex;
  }

  @media ${device.tablet} {
    display: flex;
    font-size: ${({ theme }) => theme.fontSize.default};
  }
`;

export const Menu = styled.ul<menuProps>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  ${MenuItem} {
    &:not(:first-child) {
      display: ${({ vertical, showAll }) =>
        vertical || showAll ? 'flex' : 'none'};
    }
  }

  @media ${device.tablet} {
    ${MenuItem} {
      &:not(:first-child) {
        display: flex;
      }
    }
  }
`;

export const MenuLink = styled.a<menuLinkProps>`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme, highlight, selected }) =>
    highlight
      ? theme.colors.primary.default
      : selected
      ? theme.colors.grey.lightest
      : 'transparent'};
  border-radius: ${({ theme }) => theme.layout.radiusTiny};
  text-decoration: none;
  color: ${({ theme, highlight }) =>
    highlight ? theme.colors.text.white : theme.colors.text.default};

  &:hover {
    background-color: ${({ theme, highlight }) =>
      highlight ? theme.colors.primary.faded : theme.colors.grey.lightest};
  }
`;

export const AuthLink = styled.button<menuLinkProps>`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme, highlight, selected }) =>
    highlight
      ? theme.colors.primary.default
      : selected
      ? theme.colors.grey.lightest
      : 'transparent'};
  border-radius: ${({ theme }) => theme.layout.radiusTiny};
  text-decoration: none;
  color: ${({ theme, highlight }) =>
    highlight ? theme.colors.text.white : theme.colors.text.default};
  border: 0;
  font-size: ${({ theme }) => theme.fontSize.default};
  font-family: ${({ theme }) => theme.fontFamily} !important;

  &:hover {
    background-color: ${({ theme, highlight }) =>
      highlight ? theme.colors.primary.faded : theme.colors.grey.lightest};
    border: 0;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Chat = styled.div`
  cursor: pointer;
  margin-right: 5px;
  @media ${device.mobileM} {
    margin-right: 0;
  }
`;

export const SideMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white.default};
  width: 300px;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.menu};
  padding: 15px;

  .menuHeader {
    margin-bottom: 20px;
  }

  .menuHeading {
    font-size: ${({ theme }) => theme.fontSize.default};
  }

  ${Logo} {
    height: 30px;
  }

  ${MenuItem} {
    font-size: 16px;
  }

  @media ${device.tablet} {
    display: none;
    padding: 0 20px;

    .menuIcon {
      display: none;
    }

    ${MenuItem} {
      &:last-child {
        display: none;
      }
    }
  }
`;
