import React, { useState, useCallback, FC } from 'react';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { Container, Controls } from './ImageCropper.styles';
import { Button } from '../../button';
import { getCroppedImg } from '../../../../utils/customHooks/cropImage';

interface IImageCropperProps {
  image: string;
  setCroppedImageFor: (
    crop: Point,
    croppedImageUrl: string,
    aspect?: number,
    zoom?: number
  ) => void;
}

const ImageCropper: FC<IImageCropperProps> = ({
  image,
  setCroppedImageFor,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const onCrop = async () => {
    const croppedImageUrl = await getCroppedImg(image, croppedAreaPixels);
    setCroppedImageFor(crop, croppedImageUrl, zoom, 1 / 1);
  };

  return (
    <Container>
      <Controls>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => setZoom(Number(zoom))}
          classes={{ root: 'slider' }}
        />
        <Button onClick={() => onCrop()} color="secondary">
          Crop
        </Button>
      </Controls>
      <div className="cropper">
        <div className="crop-container">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      </div>
    </Container>
  );
};

export default ImageCropper;
