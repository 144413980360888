import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IEditAccount, LayoutContext } from '../../../contexts/layoutContext';
import { theme } from '../../../styles/theme';
import { Button } from '../../shared/button';
import { CardField } from '../../shared/formElements/cardField';
import { Arrow } from '../../shared/icons';
import { Alert, LayoutPage } from '../../shared/layout';
import { ButtonGroup } from '../../shared/layout/Layout.styles';
import { DotsLoading } from '../../shared/loading';
import { Toast } from '../../shared/toast';
import * as Yup from 'yup';
import {
  Label,
  ListGroup,
  ListGroupRow,
  Span,
} from '../../shared/typefaces/Typefaces.styles';

import { SmallButton } from '../ingredient/Ingredient.styles';
import {
  AddressForm,
  CardForm,
  Container,
  CTA,
  CurrentCard,
  Divider,
  Form,
  FormSection,
  Header,
  Heading,
  Left,
  Right,
} from './EditBillingForm.styles';
import { useBilling } from '../../../utils/customHooks/useBilling';
import { capitalizeFirstLetterPerWord } from '../../../utils/helper';
import { Address } from '../../shared/formElements';
import { useAddress } from '../../../utils/customHooks/useAddress';
import { useMeQuery } from '../../../generated/graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { IAddressFormProps } from '../../shared/formElements/address/Address';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

export const EditBillingForm = () => {
  const {
    loading,
    data,
    error,
    userData,
    isRemovingCard,
    isSaving,
    errorMessage,
    clearPaymentMethod,
    saveBillingAddress,
    savePaymentMethod,
    setSaving,
    removeCard,
  } = useBilling();
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const me = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const { getAddressErrors } = useAddress();
  const { appWidth, editAccount, dispatch } = useContext(LayoutContext);

  const handleCancel = () => {
    if (editAccount?.shouldBillingRedirectToEditAccountWhenDone) {
      dispatch({
        type: 'SET_EDIT_ACCOUNT',
        payload: {
          selectedAccount: editAccount?.selectedAccount,
        } as IEditAccount,
      });
      dispatch({ type: 'SLIDER_PAGE', payload: 'editAccount' });
    }

    setSaving(false);
    dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
  };

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const showCurrentCard = !!userData?.stripePaymentMethodId && !isRemovingCard;
  const heading = !!userData?.stripePaymentMethodId
    ? 'Edit Billing'
    : 'Add Billing';
  const subHeading = !!userData?.stripePaymentMethodId
    ? 'Edit Billing'
    : 'Add Billing';

  let loadingMessage: JSX.Element | undefined = undefined;
  if (loading || error || !data) {
    if (loading) {
      loadingMessage = (
        <DotsLoading color="primary" isLoading={loading} size="large" />
      );
    }
    if (error) {
      loadingMessage = <p>Error: {error}</p>;
    }

    if (!loadingMessage) {
      loadingMessage = <p>Unknown error</p>;
    }
  }

  const onAddressOnlySubmit = async (formData: IAddressFormProps) => {
    const totalActiveSeconds = getTotalActiveSeconds();
    const result = await saveBillingAddress(
      data?.me?.user?.stripePaymentMethodId,
      formData,
      totalActiveSeconds
    );
    if (result?.success) {
      const formValues = methods.getValues();
      methods.reset({
        ...formValues,
        ...result?.response?.data?.updateBillingAddress?.billingAddress,
      });
    }
  };

  const onSubmit = async (formData: IAddressFormProps) => {
    const totalActiveSeconds = getTotalActiveSeconds();
    const result = await savePaymentMethod(formData, totalActiveSeconds);
    if (result?.success) {
      const formValues = methods.getValues();
      methods.reset({
        ...formValues,
        ...result?.response?.data?.savePaymentMethod?.billingAddress,
      });
    }
  };

  return (
    <LayoutPage
      backButton
      backButtonCTA={handleCancel}
      align="center"
      withLine
      heading={heading}
      subHeading={
        !!userData?.stripePaymentMethodId
          ? subHeading + ' details or review your payment method'
          : subHeading +
            ' details to upgrade your account to get more accurate calculations'
      }
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={handleCancel}>
                x
              </Button>,
              <SmallButton
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isSaving}
              >
                <DotsLoading
                  text={(loading) => (loading ? 'Saving' : 'Save')}
                  isLoading={isSaving}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </SmallButton>,
            ]
          : [
              <Button color="default" inversed onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isSaving}
              >
                <DotsLoading
                  text={(loading) => `${loading ? 'Saving' : 'Save'} Card`}
                  isLoading={isSaving}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <Container>
        <Header>Billing Details</Header>
        {loading || error || !data ? (
          loadingMessage
        ) : (
          <FormProvider {...methods}>
            {errorMessage && (
              <Toast
                type="error"
                heading="Billing Error"
                message={errorMessage || 'Please contact the billing team'}
                addMargin
              />
            )}
            <Form>
              <AddressForm>
                <Heading color="grey" fontWeight="semibold" hideMarginBottom>
                  Billing Address
                </Heading>
                <Address
                  label="Billing Address"
                  control={methods.control}
                  errors={getAddressErrors(methods.errors)}
                  getValues={methods.getValues}
                  reset={methods.reset}
                  initialAddressValues={
                    me.data?.me?.user?.billingAddress ||
                    me.data?.me?.user?.address
                  }
                />
                <ButtonGroup noMarginTop align={'right'}>
                  <Button
                    color="secondary"
                    onClick={methods.handleSubmit(onAddressOnlySubmit)}
                    disabled={isSaving || clearPaymentMethod.loading}
                    size="default"
                  >
                    <DotsLoading
                      text={(loading) =>
                        `${loading ? 'Saving' : 'Save'} Address Only`
                      }
                      isLoading={isSaving}
                      size="small"
                      lineHeight={10}
                      noMargin
                    />
                  </Button>
                </ButtonGroup>
              </AddressForm>
              <CardForm hasPaymentMethod={showCurrentCard}>
                {showCurrentCard && (
                  <Left>
                    <Heading color="grey" fontWeight="semibold">
                      Current Billing Details
                    </Heading>
                    <CurrentCard>
                      <ListGroup noBorder className="listGroup">
                        <ListGroupRow
                          colOneWidth={
                            appWidth !== 0 && appWidth < theme.mQ.tablet
                              ? '1fr'
                              : '130px'
                          }
                          colTwoWidth={
                            appWidth !== 0 && appWidth < theme.mQ.tablet
                              ? '1fr'
                              : '150px'
                          }
                        >
                          <Label color="faded">Card Number</Label>
                          <Span>
                            **** **** **** {userData?.stripePaymentLast4}
                          </Span>
                        </ListGroupRow>
                        {userData?.stripePaymentExpMonth && (
                          <ListGroupRow
                            colOneWidth={
                              appWidth !== 0 && appWidth < theme.mQ.tablet
                                ? '1fr'
                                : '130px'
                            }
                            colTwoWidth={
                              appWidth !== 0 && appWidth < theme.mQ.tablet
                                ? '1fr'
                                : '150px'
                            }
                          >
                            <Label color="faded">Card Expiry</Label>
                            <Span>
                              {userData?.stripePaymentExpMonth >= 10
                                ? userData?.stripePaymentExpMonth
                                : `0${userData?.stripePaymentExpMonth}`}{' '}
                              / {userData?.stripePaymentExpYear}
                            </Span>
                          </ListGroupRow>
                        )}
                        <ListGroupRow
                          colOneWidth={
                            appWidth !== 0 && appWidth < theme.mQ.tablet
                              ? '1fr'
                              : '130px'
                          }
                          colTwoWidth={
                            appWidth !== 0 && appWidth < theme.mQ.tablet
                              ? '1fr'
                              : '150px'
                          }
                        >
                          <Label color="faded">Subscription</Label>
                          <Span>
                            {capitalizeFirstLetterPerWord(
                              userData?.billingCycle
                            )}
                          </Span>
                        </ListGroupRow>
                      </ListGroup>
                    </CurrentCard>
                  </Left>
                )}
                {showCurrentCard && (
                  <Divider>
                    <Arrow size="small" color="grey" className="arrowIcon" />
                  </Divider>
                )}
                <Right>
                  <Heading color="grey" fontWeight="semibold">
                    Add New Card
                  </Heading>
                  <FormSection>
                    <Alert
                      icon="valid"
                      content="The card details entered will be used for RecipeRevenue.com purchases"
                      color="secondary"
                    />
                    <CardField />
                  </FormSection>
                </Right>
              </CardForm>
            </Form>
            <CTA>
              <ButtonGroup
                noMarginTop
                addMarginBottom
                align={showCurrentCard ? 'spaceBetween' : 'center'}
              >
                {showCurrentCard && (
                  <Button color="default" inversed onClick={removeCard}>
                    <DotsLoading
                      text={(loading) =>
                        `${loading ? 'Removing' : 'Remove'} Card`
                      }
                      isLoading={clearPaymentMethod.loading}
                      size="small"
                      lineHeight={10}
                      noMargin
                      color="default"
                    />
                  </Button>
                )}
                <Button
                  color="secondary"
                  onClick={methods.handleSubmit(onSubmit)}
                  disabled={isSaving || clearPaymentMethod.loading}
                >
                  <DotsLoading
                    text={(loading) => `${loading ? 'Saving' : 'Save'} Card`}
                    isLoading={isSaving}
                    size="small"
                    lineHeight={10}
                    noMargin
                  />
                </Button>
              </ButtonGroup>
            </CTA>
          </FormProvider>
        )}
      </Container>
    </LayoutPage>
  );
};

const validationSchema = Yup.object().shape({
  addressLine1: Yup.string().required('Address Line 1 is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required'),
  postcode: Yup.string().required('Postcode is required'),
});
