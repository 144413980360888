import { RecipeFormItem } from '../../components/shared/recipe/recipe.types';
import { SummaryStats } from '../../components/shared/recipe/sections/items/items.types';
import { IngredientsQuery, RecipesQuery } from '../../generated/graphql';
import { getSubRecipeGrams } from './getSubRecipeGrams';
import { isEmpty } from './objects';
import { getTotalTimeSeconds, getUnitTotal } from './units';

export const getRecipeSummaryStats = (
  fields: RecipeFormItem[],
  ingredients:
    | Exclude<IngredientsQuery['ingredients']['ingredients'], null>
    | undefined,
  recipes: Exclude<RecipesQuery['venueRecipes']['recipes'], null> | undefined
) => {
  const summaryStats: SummaryStats = {
    totalStaffTime: 0,
    totalProcessTime: 0,
    totalTime: 0,
    totalGrams: 0,
  };

  for (let index = 0; index < fields.length; index++) {
    const item = fields[index];
    if (item.__typename !== 'RecipeItemDeleted') {
      if (item.__typename === 'RecipeIngredient') {
        let total = 0;

        if (item.type === 'INGREDIENT') {
          const ingredient = ingredients?.find(
            (ingredient) => ingredient.id === item.ingredient?.value
          );

          const metrics =
            ingredient?.metrics.reduce((obj, item) => {
              return Object.assign(obj, { [item.type]: item.grams });
            }, {} as Record<string, number>) ?? {};

          total =
            item.unit && !isEmpty(item.quantity)
              ? getUnitTotal(item.unit, Number(item.quantity), metrics)
              : 0;
        }

        if (item.type === 'RECIPE') {
          const recipe = recipes?.find(
            (recipe) => recipe.id === item.ingredient?.value
          );

          const recipeVersion = recipe?.recipeVersions.find(
            (version) => version.selected
          );

          if (item.unit && !isEmpty(item.quantity)) {
            total = getSubRecipeGrams({
              unit: item.unit,
              quantity: Number(item.quantity),
              totalGrams: recipeVersion?.totalGrams ?? 0,
              recipeServes: recipeVersion?.serves ?? 0,
            });
          }
        }
        summaryStats.totalGrams = summaryStats.totalGrams + total;
      }

      if (item.__typename === 'RecipeTimeItem') {
        const total =
          item.__typename === 'RecipeTimeItem'
            ? getTotalTimeSeconds(item.unit, Number(item.quantity))
            : 0;
        summaryStats.totalTime = summaryStats.totalTime + total;
        if (item.type === 'PROCESS_TIME') {
          summaryStats.totalProcessTime = summaryStats.totalProcessTime + total;
        }
        if (item.type === 'STAFF_TIME') {
          summaryStats.totalStaffTime = summaryStats.totalStaffTime + total;
        }
      }
    }
  }

  return summaryStats;
};
