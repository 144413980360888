import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  IEditAccount,
  INewAccountAddOnProps,
  LayoutContext,
} from '../../../contexts/layoutContext';
import {
  AccountType,
  AddOnApp,
  BillingCycle,
  useMeQuery,
  useSubscriptionDataQuery,
  useUpdateSubscriptionMutation,
} from '../../../generated/graphql';
import logo from '../../../images/iconLogo-min.png';
import { theme } from '../../../styles/theme';
import { useRestrictionPopup } from '../../../utils/customHooks/useRestrictionPopup';
import { useRestrictionCheck } from '../../../utils/helper/account';
import { useHighestProfitIncrease } from '../../../utils/helper/useHighestProfitIncrease';
import { restrictionPopupMessages } from '../../../utils/restrictionPopupMessages';
import { Button } from '../../shared/button';
import { AnchorButton } from '../../shared/button/Button.styles';
import { AngleBox, LayoutPage, Modal } from '../../shared/layout';
import { FAQ } from '../../shared/layout/faq';
import { DotsLoading } from '../../shared/loading';
import { H2, H3, Span } from '../../shared/typefaces/Typefaces.styles';
import { Pricing, Extras } from '../selectedAccount/sections';
import { PlanOptions } from '../selectedAccount/sections/planOptions';
import { Callout } from '../selectedAccount/SelectAccount.styles';
import {
  Container,
  Content,
  Header,
  Heading,
  Left,
  Logo,
  Main,
} from './EditAccount.styles';
import {
  FAQs,
  capitalizeFirstLetter,
  getPlan,
} from '../../../utils/helper/strings';
import { isPaidAccount } from '../../../utils/helper/subscription';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

interface IEditAccountProps {}

export interface IInfoModalProps {
  show: boolean;
  heading: string;
  content: string;
}

export const EditAccount: FC<IEditAccountProps> = () => {
  const {
    appWidth,
    overlay,
    editAccount,
    account,
    newAccount,
    selectedVenueObject,
    dispatch,
  } = useContext(LayoutContext);
  const { getTotalActiveSeconds } = useActiveTimeTracker();

  const initialInfoState = {
    show: false,
    heading: '',
    content: '',
  };

  const [infoModal, setInfoModal] = useState<IInfoModalProps>(initialInfoState);
  const [cancelledBillingModal, setCancelledBillingModal] = useState(false);
  const checkRestrictions = useRestrictionCheck(['MAX_VENUES']);
  const highestProfitIncrease = useHighestProfitIncrease();

  const { loading, error, data } = useSubscriptionDataQuery();
  const [updateSubscriptionMutation] = useUpdateSubscriptionMutation();
  const subscriptionData = data?.me?.user;

  const me = useMeQuery({
    fetchPolicy: 'network-only',
  });

  // preview calculator variables
  const [accountTypePreview, setAccountTypePreview] = useState<
    AccountType | undefined
  >();
  const closeEditAccountOverlay = () => {
    dispatch({ type: 'TOGGLE_SLIDER' });
    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: account,
    });
    dispatch({ type: 'SET_EDIT_ACCOUNT', payload: undefined });
  };

  const showRestrictionPopup = useRestrictionPopup();

  const onClickSelectAccountType = async (accountType: AccountType) => {
    setAccountTypePreview(accountType);
    setCancelledBillingModal(false);

    const check = checkRestrictions(
      {
        numVenues: me.data?.me?.user?.venuesSharedWithYou.length || 0,
        totalProfitIncrease: highestProfitIncrease(),
        recipes: me.data?.me?.user?.venuesSharedWithYou
          .flatMap((venue) => venue.venue.recipes)
          .filter((recipe) => recipe.numScaledRecipeVersions)
          .map((recipe) => ({
            numScaledRecipeVersions: recipe.numScaledRecipeVersions!,
          })),
      },
      accountType
    );

    if (!check.isPass) {
      showRestrictionPopup(
        restrictionPopupMessages.cannotDowngradeAccount(check.userMessages)
      );
      return;
    }

    // Show billing overlay if user has no billing thing
    const paidAccount = isPaidAccount(accountType);

    if (
      paidAccount &&
      (!subscriptionData?.stripeCustomerId ||
        !subscriptionData?.stripePaymentMethodId)
    ) {
      showBillingRequiredModal(accountType, true);
    } else {
      // Ask user to confirm new account type
      dispatch({
        type: 'SET_EDIT_ACCOUNT',
        payload: {
          selectedAccount: accountType,
        } as IEditAccount,
      });
    }
  };

  const closeInfoModal = () => {
    setInfoModal(initialInfoState);
  };

  const showBillingRequiredModal = useCallback(
    async (selectedAccount: undefined | AccountType, force?: boolean) => {
      if (
        !editAccount?.shouldBillingRedirectToEditAccountWhenDone &&
        !subscriptionData?.stripePaymentMethodId &&
        (force || !cancelledBillingModal)
      ) {
        dispatch({
          type: 'SET_OVERLAY',
          payload: {
            heading: 'Billing Details Required',
            subHeading:
              'To upgrade your account, complete your Billing Details',
            buttonText: 'Update Billing Details',
            linkText: 'cancel',
            buttonCTA: () => {
              dispatch({
                type: 'SET_EDIT_ACCOUNT',
                payload: {
                  selectedAccount: selectedAccount,
                  shouldBillingRedirectToEditAccountWhenDone: true,
                } as IEditAccount,
              });
              dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'editBilling' });
              dispatch({ type: 'CLOSE_OVERLAY' });
            },
            linkCTA: () => {
              dispatch({
                type: 'SET_EDIT_ACCOUNT',
                payload: {} as IEditAccount,
              });
              setCancelledBillingModal(true);
              setAccountTypePreview(undefined);
              dispatch({ type: 'CLOSE_OVERLAY' });
            },
          },
        });
      }
    },
    [editAccount, cancelledBillingModal, subscriptionData, dispatch]
  );

  const showConfirmSelectionModal = useCallback(
    (
      accountType: AccountType,
      billingCycle: BillingCycle,
      addOns?: INewAccountAddOnProps[]
    ) => {
      const { heading, text } = getPlan(accountType)!;
      const addOnsFormatted: AddOnApp[] | undefined = addOns
        ?.filter((a) => a.updateAddOn)
        .map((a) => {
          return a.app;
        });

      dispatch({
        type: 'SET_OVERLAY',
        payload: {
          heading: `Confirm ${heading} Subscription`,
          subHeading: text,
          buttonText: 'Confirm Subscription',
          linkText: 'cancel',
          buttonCTA: async () => {
            const result = await updateSubscriptionMutation({
              variables: {
                input: {
                  accountType,
                  billingCycle,
                  addOns: addOnsFormatted,
                  venueId: selectedVenueObject?.id!,
                  totalActiveSeconds: getTotalActiveSeconds(),
                },
              },
              refetchQueries: ['SubscriptionData'],
              awaitRefetchQueries: true,
            });

            if (result.data?.updateSubscription.error) {
              return {
                errors: [result.data?.updateSubscription.error],
              };
            }

            dispatch({
              type: 'SET_POPUP',
              payload: {
                type: 'success',
                heading: 'Account Updated',
                message: `Successfully updated to the ${heading} account on a ${capitalizeFirstLetter(
                  billingCycle
                )} Billing Cycle`,
              },
            });

            dispatch({
              type: 'SET_ACCOUNT',
              payload: {
                type: accountType,
                billingCycle,
                addOns: result.data?.updateSubscription.user?.addOns,
              },
            });

            dispatch({
              type: 'SET_EDIT_ACCOUNT',
              payload: {} as IEditAccount,
            });
          },
          linkCTA: () => {
            dispatch({
              type: 'SET_EDIT_ACCOUNT',
              payload: {} as IEditAccount,
            });
            dispatch({ type: 'CLOSE_OVERLAY' });
            setAccountTypePreview(undefined);
          },
        },
      });
    },
    [dispatch, updateSubscriptionMutation]
  );

  // open confirmation modal
  useEffect(() => {
    if (editAccount?.selectedAccount && !overlay?.heading) {
      const paidAccount = isPaidAccount(editAccount?.selectedAccount);

      if (paidAccount) {
        if (!subscriptionData?.stripePaymentMethodId) {
          return;
        }
      }

      showConfirmSelectionModal(
        editAccount?.selectedAccount,
        newAccount!.billingCycle,
        newAccount!.addOns
      );
    }
  }, [
    subscriptionData,
    editAccount,
    overlay?.heading,
    newAccount?.billingCycle,
    newAccount?.addOns,
    showConfirmSelectionModal,
  ]);

  if (loading || error || !subscriptionData) {
    const message = (() => {
      if (loading) return <DotsLoading />;
      if (error) return `Error: ${error}`;
      return 'Unknown error';
    })();

    return <Container>{message}</Container>;
  }

  return (
    <LayoutPage
      backButton
      backButtonCTA={closeEditAccountOverlay}
      withLine
      heading={'Update Account'}
      subHeading={'Update account to connect your team & refine your recipes'}
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button
                color="default"
                asCircle
                inversed
                onClick={closeEditAccountOverlay}
              >
                x
              </Button>,
            ]
          : [
              <Button
                color="default"
                inversed
                onClick={closeEditAccountOverlay}
              >
                Cancel
              </Button>,
              <AnchorButton href="#features" color="secondary" disabled={false}>
                <DotsLoading
                  text={(loading) => `${loading ? 'Saving' : 'See Plans'}`}
                  isLoading={false}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </AnchorButton>,
            ]
      }
    >
      <Container>
        <AngleBox color="default" marginTop="huge" />
        <Main marginTop="huge">
          <Content>
            <Header>
              <Left>
                <Heading>Update Account</Heading>
                <H2 className="subHeading">
                  <Span className="subHeadingTop">
                    Accurately Create Delicious,
                  </Span>
                  <Span className="subHeadingBottom">
                    Profitable Recipes In Minutes
                  </Span>
                </H2>
              </Left>
              <Logo className="logo" src={logo} />
            </Header>
            <PlanOptions
              onSubmit={onClickSelectAccountType}
              accountTypePreview={accountTypePreview}
              isLoading={false}
              me={me}
            />
            <Extras setInfoModal={setInfoModal} />
            <Callout>
              <H3 className="callout">
                For creative Chef’s who run the kitchen like a business, who
                know the key to consistent quality is knowing the numbers
              </H3>
            </Callout>
          </Content>
          <Pricing
            onSubmit={onClickSelectAccountType}
            isLoading={false}
            setInfoModal={setInfoModal}
          />
          <H3 className="questionsHeader">Further Questions</H3>
          <FAQ questions={FAQs} />
          <Modal
            showModal={infoModal.show}
            handleClose={closeInfoModal}
            heading={infoModal.heading}
            content={infoModal.content}
          />
        </Main>
      </Container>
    </LayoutPage>
  );
};
