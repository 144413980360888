import React, { memo, Fragment } from 'react';
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';
import { Button } from '../../shared/button';
import { Input } from '../../shared/formElements';
import { getFieldArrayErrorMessage } from '../../../utils/helper';

const FormTwo = () => {
  const { errors, control, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  return (
    <Fragment>
      <div>
        <ul>
          {fields.map((item, index) => {
            const quantity = watch(`items[${index}].quantity`);
            return (
              <li key={item.id} className="items">
                {/* <SelectList
                autoComplete
                control={control}
                name={`items[${index}].ingredient`}
                label="Ingredient Name"
                errorText={getFieldArrayAutoCompleteErrorMessage(
                  'ingredient',
                  index,
                  errors.items
                )}
                ref={register}
                options={ingredients}
              />
              <SelectList
                control={control}
                name={`items[${index}].unit`}
                label="Units"
                errorText={getFieldArrayErrorMessage(
                  'unit',
                  index,
                  errors.items
                )}
                ref={register}
                options={ingredients}
              /> */}

                <Controller
                  as={<Input />}
                  type="number"
                  name={`items[${index}].quantity`}
                  label="quantity"
                  control={control}
                  defaultValue={item.quantity || ''}
                  errorText={getFieldArrayErrorMessage(
                    'quantity',
                    index,
                    errors.items
                  )}
                />
                <span>{quantity}</span>

                <Button onClick={() => remove(index)}>Delete</Button>
              </li>
            );
          })}
        </ul>
      </div>
      <section>
        <Button
          type="button"
          onClick={() =>
            append({ ingredient: 'ingredient', quantity: 'quantity' })
          }
        >
          append
        </Button>
      </section>
    </Fragment>
  );
};

export default memo(FormTwo);
