import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const CafeHat: React.FC<IIconProps> = ({ color, size, faded, className }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 469.333 469.333"
      color={color}
      faded={faded}
      className={className}
    >
      <path
        d="M128,224c0-5.896-4.771-10.667-10.667-10.667c-5.896,0-10.667,4.771-10.667,10.667c0,28.01,30.896,66.469,34.417,70.771
				c2.115,2.563,5.177,3.896,8.26,3.896c2.375,0,4.771-0.792,6.76-2.417c4.552-3.74,5.208-10.458,1.479-15.021
				C146.146,267.292,128,239.437,128,224z"
      />
      <path
        d="M352,213.333c-5.896,0-10.667,4.771-10.667,10.667c0,15.385-18.146,43.271-29.583,57.24
				c-3.729,4.552-3.063,11.271,1.49,15.01c1.979,1.625,4.375,2.417,6.76,2.417c3.083,0,6.135-1.333,8.25-3.896
				c3.521-4.302,34.417-42.76,34.417-70.771C362.667,218.104,357.896,213.333,352,213.333z"
      />
      <path
        d="M234.667,213.333c-5.896,0-10.667,4.771-10.667,10.667v64c0,5.896,4.771,10.667,10.667,10.667
				s10.667-4.771,10.667-10.667v-64C245.333,218.104,240.562,213.333,234.667,213.333z"
      />
      <path
        d="M352,42.667c-6.464,0-13.219,0.855-20.234,2.158C308.273,17.439,273.496,0,234.667,0s-73.607,17.439-97.099,44.824
				c-7.016-1.302-13.771-2.158-20.234-2.158C52.635,42.667,0,95.302,0,160c0,44.333,25,84.635,64.25,104.562L64.042,425.76
				c-0.01,11.635,4.51,22.573,12.729,30.802c8.229,8.24,19.156,12.771,30.792,12.771h254.25c24,0,43.521-19.521,43.521-43.521
				V264.406c39.104-19.979,64-60.219,64-104.406C469.333,95.302,416.698,42.667,352,42.667z M384,425.813
				c0,12.229-9.958,22.188-22.188,22.188h-254.25c-5.927,0-11.5-2.313-15.698-6.51c-4.188-4.198-6.5-9.771-6.49-15.708L85.43,384
				H384V425.813z M390.604,247.802c-0.563,0.229-1.094,0.5-1.594,0.823c-1.344,0.833-2.448,1.938-3.271,3.208
				c-0.833,1.26-1.396,2.719-1.625,4.281c-0.094,0.594-0.125,1.188-0.115,1.792v104.76H85.457l0.137-104.615
				c0.01-0.583-0.021-1.177-0.104-1.75c-0.229-1.615-0.823-3.115-1.688-4.417c-0.813-1.219-1.875-2.281-3.167-3.094
				c-0.531-0.333-1.094-0.625-1.677-0.865C43.948,232.615,21.333,198.135,21.333,160c0-52.938,43.063-96,96-96
				c2.069,0,4.253,0.25,6.396,0.419c-10.792,18.756-17.063,40.43-17.063,63.581c0,5.896,4.771,10.667,10.667,10.667
				c5.896,0,10.667-4.771,10.667-10.667c0-58.813,47.854-106.667,106.667-106.667S341.333,69.187,341.333,128
				c0,5.896,4.771,10.667,10.667,10.667c5.896,0,10.667-4.771,10.667-10.667c0-23.151-6.271-44.824-17.063-63.581
				C347.747,64.25,349.931,64,352,64c52.938,0,96,43.063,96,96C448,198,425.479,232.437,390.604,247.802z"
      />
    </IconStyled>
  );
};

export default CafeHat;
