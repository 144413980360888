import styled from 'styled-components/macro';
import { IHeadingsProps } from './stats.types';
import { device } from '../../../../../styles/device';

export const Container = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: auto;
  margin: 15px 15px 30px;
  border-radius: 15px;

  @media ${device.tablet} {
    grid-template-columns: 1fr auto;
    grid-gap: 15px;
  }

  @media ${device.laptopL} {
    margin: 30px 0px;
  }
`;

export const HeadingsItem = styled.li`
  display: grid;
  grid-template-rows: 25px auto;
  grid-gap: 5px;

  h2 {
    @media ${device.tablet} {
      padding-right: 15px;
    }
  }
`;

export const Headings = styled.ul<IHeadingsProps>`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: ${({ align }) =>
    align === 'right' ? 'auto auto auto' : 'auto'};

  @media ${device.tablet} {
    grid-template-columns: ${({ align }) =>
      align === 'right' ? '102px 112px 145px' : 'auto'};
  }

  ${HeadingsItem} {
    &:first-child {
      text-align: left;
      @media (min-width: 900px) {
        ${({ align }) => align === 'right' && 'text-align: right'};
      }
    }
    &:nth-child(2) {
      text-align: center;
      @media (min-width: 900px) {
        ${({ align }) => align === 'right' && 'text-align: right'};
      }
    }

    &:last-child {
      ${({ align }) => align === 'right' && 'text-align: right'};
    }
  }
`;
