import { useEffect } from 'react';

interface IProps {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  handleClick: () => void;
}

export const useClickedOutsideAlert = ({ ref, handleClick }: IProps) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
