import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useConfirmEmailWithTokenMutation } from '../../../generated/graphql';
import { DotsLoading } from '../../shared/loading';
import {
  Container,
  Message,
  Error,
  Heading,
} from './ConfirmEmailWithToken.styles';
import qs from 'qs';
import { decode } from 'jsonwebtoken';

const SHOW_SUCCESS_MESSAGE_DURATION = 3000; // millis

const ConfirmEmailWithToken: FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const [
    confirmEmailWithToken,
    { loading, error },
  ] = useConfirmEmailWithTokenMutation();

  const token = qs.parse(location.search, { ignoreQueryPrefix: true }).token as
    | string
    | undefined;

  const tokenPayload =
    token &&
    (decode(token) as {
      userId?: string;
      iat?: number;
      exp?: number;
    } | null);

  const tokenError = (() => {
    if (!(tokenPayload && tokenPayload.userId))
      return 'The confirm email request is invalid. Please log in and request another email confirmation.';

    const now = new Date().getTime() / 1000; // Unix time in seconds
    if (now > (tokenPayload.exp || 0))
      return "It's been too long since the email confirmation was sent. For security reasons, please log in and request another email confirmation.";

    return null;
  })();

  useEffect(() => {
    const submitConfirmEmail = async () => {
      let timeoutRef;

      if (typeof token === 'string') {
        try {
          const response = await confirmEmailWithToken({
            variables: {
              input: {
                confirmEmailToken: token,
              },
            },
          });

          if (response?.data?.confirmEmailWithToken.successful) {
            timeoutRef = setTimeout(
              () => history.push('/sign-in'),
              SHOW_SUCCESS_MESSAGE_DURATION
            );
          }
        } catch (err) {
          console.log('err', err);
        }
      }

      return () => {
        if (timeoutRef) clearTimeout(timeoutRef);
      };
    };

    submitConfirmEmail();
  }, [token, history, confirmEmailWithToken]);

  const responseErrors = error && (
    <Error fontSize="small">{error.message}</Error>
  );

  const tokenErrorDisplay = tokenError && (
    <Error fontSize="small">{tokenError}</Error>
  );

  const dotsLoading = (
    <DotsLoading
      isLoading={true}
      size="large"
      lineHeight={10}
      color="default"
    />
  );

  const message =
    responseErrors ||
    tokenErrorDisplay ||
    (loading ? (
      dotsLoading
    ) : (
      <Message>
        Email was confirmed successfully! Signing you in {dotsLoading}
      </Message>
    ));

  return (
    <Container>
      <Heading>Confirm Email</Heading>
      {message}
    </Container>
  );
};

export default ConfirmEmailWithToken;
