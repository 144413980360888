import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Play: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled size={size} viewBox="0 0 512 512" color={color} faded={faded}>
      <g>
        <path d="M 43.5,-0.5 C 48.5,-0.5 53.5,-0.5 58.5,-0.5C 196.313,77.1034 334.313,154.603 472.5,232C 486.886,242.042 490.052,254.876 482,270.5C 479.131,273.704 475.965,276.537 472.5,279C 334.313,356.397 196.313,433.897 58.5,511.5C 53.5,511.5 48.5,511.5 43.5,511.5C 33.3079,508.136 27.1413,501.136 25,490.5C 24.3333,333.833 24.3333,177.167 25,20.5C 27.1413,9.86383 33.3079,2.86383 43.5,-0.5 Z" />
      </g>
    </IconStyled>
  );
};

export default Play;
