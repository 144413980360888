import React, { useContext } from 'react';
import { LayoutPage } from '../../shared/layout';
import { LayoutContext } from '../../../contexts/layoutContext';
import {
  useCreateStocktakeMutation,
  useStocktakesQuery,
} from '../../../generated/graphql';
import { overlayConstants } from '../../shared/layout/layoutOverlay/constants';
import { getTodaysDate } from '../../../utils/helper';
import Header from './sections/header';
import StocktakeList from './sections/stocktakeList';
import { AnchorButton } from '../../shared/button/Button.styles';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

const Stocktakes = ({ history }) => {
  const {
    selectedVenueObject,
    account,
    user,
    dispatch,
    toolTip: { show },
  } = useContext(LayoutContext);
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const [
    createStocktakeMutation,
    createStocktake,
  ] = useCreateStocktakeMutation();

  const stocktakes = useStocktakesQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleAddStocktake = async () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    } else {
      const stocktakeDate = getTodaysDate();
      const duplicateStocktakeNameCount = stocktakes.data?.stocktakes.stocktakes?.filter(
        (s) => s.displayName.includes(stocktakeDate)
      ).length;
      const stocktakeName =
        duplicateStocktakeNameCount && duplicateStocktakeNameCount > 0
          ? `${stocktakeDate}-v${duplicateStocktakeNameCount}`
          : stocktakeDate;

      try {
        const response = await createStocktakeMutation({
          variables: {
            input: {
              displayName: stocktakeName,
              venueId: selectedVenueObject?.id!,
              totalActiveSeconds: getTotalActiveSeconds(),
            },
          },
          refetchQueries: ['useStocktakesQuery'],
        });

        if (
          response.data?.createStocktake.successful &&
          !!response.data.createStocktake.stocktake?.id
        ) {
          dispatch({
            type: 'SET_SELECTED_STOCKTAKE',
            payload: response.data.createStocktake.stocktake.id,
          });
          history.push(
            `/edit-stocktake/${response.data.createStocktake.stocktake.id}`
          );
        } else {
          console.log('Stocktake error', response);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const isLoading = createStocktake.loading;

  return (
    <LayoutPage
      align={show ? 'left' : 'center'}
      withLine
      heading="Stocktake History"
      subHeading={
        selectedVenueObject?.displayName
          ? `For ${selectedVenueObject?.displayName} Venue`
          : 'For Venue'
      }
      actionArray={
        (stocktakes && [
          <AnchorButton href="/inventory" color="default" inversed>
            Inventory
          </AnchorButton>,
          <AnchorButton href="/shopping-list" color="secondary">
            Shopping List
          </AnchorButton>,
        ]) ||
        undefined
      }
    >
      <Header stocktakes={stocktakes} />
      {stocktakes && (
        <StocktakeList
          stocktakes={stocktakes}
          handleAddStocktake={handleAddStocktake}
          isLoading={isLoading}
        />
      )}
    </LayoutPage>
  );
};

export default Stocktakes;
