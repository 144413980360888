import React, { useContext, useState } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  CreateBulkIngredientInput,
  useBulkCreateIngredientsMutation,
} from '../../../../../generated/graphql';
import { parseCsvFile } from '../../../../../utils/helper';
import { useRestrictionCheck } from '../../../../../utils/helper/account';
import { Card, Content, Header } from '../../../../shared/card';
import FileUploader from '../../../../shared/formElements/fileUploadButton/FileUploadButton';
import { Salad } from '../../../../shared/icons';
import { DotsLoading } from '../../../../shared/loading';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import {
  BtnLink,
  HighlightsItem,
  HighlightsRow,
} from './BulkAddIngredients.styles';
import { useActiveTimeTracker } from '../../../../../utils/customHooks/useActiveTimeTracker';

const initialUploadState = {
  successful: false,
  created: 0,
  updated: 0,
  skipped: 0,
};

export const BulkAddIngredients = () => {
  const { selectedVenueObject } = useContext(LayoutContext);
  const uploader = useBulkUploader({
    venue: selectedVenueObject?.id,
  });

  const canBulkUpload = useRestrictionCheck(['BULK_UPLOAD_INGREDIENTS']);
  if (!canBulkUpload({}).isPass) {
    return null;
  }

  const uploadStatsContent = [
    {
      name: 'Created',
      value: uploader.stats.created,
    },
    {
      name: 'Updated',
      value: uploader.stats.updated,
    },
    {
      name: 'Skipped',
      value: uploader.stats.skipped,
    },
  ];

  return (
    <Card withCardLink>
      <Header
        icon={<Salad size="small" />}
        heading="Bulk Add Ingredients"
        subHeading="Add ingredients with csv file"
        button={[
          <BtnLink
            key="link"
            href={`//docs.google.com/spreadsheets/d/1-bIlUUAMQ2j0QpGJlN79mwz0xiIErRsBbaINJmMbI-k/edit?usp=sharing`}
            target="_blank"
            rel="noopener noreferrer"
          >
            CSV File
          </BtnLink>,
          <FileUploader
            key="upload"
            handleFileUpload={uploader.handleUpload}
            buttonText="Upload Csv"
            accept=".csv"
            inversed={true}
            color={'default'}
            onClick={uploader.reset}
          />,
        ]}
      />
      <Content>
        <DotsLoading
          isLoading={uploader.isLoading}
          size="large"
          lineHeight={10}
          color="default"
        />
        {uploader.errorMessage ? (
          <Span color="primary" fontSize="small">
            {uploader.errorMessage}
          </Span>
        ) : null}

        {uploader.stats.successful ? (
          <HighlightsRow>
            {uploadStatsContent.map(({ name, value }) => (
              <HighlightsItem key={name}>
                <Span>{value || '-'}</Span>
                <Span>{name}</Span>
              </HighlightsItem>
            ))}
          </HighlightsRow>
        ) : null}
      </Content>
    </Card>
  );
};

const useBulkUploader = ({ venue }: { venue: string | undefined }) => {
  const [
    bulkCreateIngredientsMutation,
    bulkCreateIngredients,
  ] = useBulkCreateIngredientsMutation();
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const [stats, setUploadStats] = useState(initialUploadState);

  const reset = () => {
    setUploadStats(initialUploadState);
  };

  const handleUpload = async (file: File) => {
    const result = await parseCsvFile(file);

    if (!venue) {
      console.log('VenueID is not set, the bulk upload will fail');
      return;
    }

    const inputData = {
      venueId: venue!,
      ingredients: result.data as CreateBulkIngredientInput[],
      totalActiveSeconds: getTotalActiveSeconds(),
    };
    try {
      const response = await bulkCreateIngredientsMutation({
        variables: {
          input: inputData,
        },
      });

      if (response.data?.bulkCreateIngredients.successful) {
        const { data, successful } = response.data?.bulkCreateIngredients;

        setUploadStats({
          successful: successful,
          created: data!.created!.length,
          updated: data!.updated!.length,
          skipped: data!.skipped!.length,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return {
    handleUpload,
    stats,
    errorMessage: bulkCreateIngredients.error?.message,
    isLoading: bulkCreateIngredients.loading,
    reset,
  };
};

export default BulkAddIngredients;
