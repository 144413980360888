import React, { FC, useContext } from 'react';
import * as S from './Stocktake.styles';
import { useConfirmStocktakeMutation } from '../../../generated/graphql';
import { Button } from '../button';
import { isEmpty } from '../../../utils/helper';
import { LayoutContext } from '../../../contexts/layoutContext';
import { LayoutPage } from '../layout';
import { theme } from '../../../styles/theme';
import { DotsLoading } from '../loading';
import { Header, SectionList } from './sections';
import { IStocktakeProps } from './stocktake.types';
import { useHistory } from 'react-router-dom';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

export const Stocktake: FC<IStocktakeProps> = ({ selectedStocktake }) => {
  const {
    appWidth,
    selectedVenueObject,
    toolTip: { show },
  } = useContext(LayoutContext);
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const { push } = useHistory();
  const [
    confirmStocktakeMutation,
    confirmStocktake,
  ] = useConfirmStocktakeMutation();

  const handleCancel = () => {
    push('/stocktakes');
  };

  const handleConfirmStocktake = async () => {
    try {
      const response = await confirmStocktakeMutation({
        variables: {
          input: {
            stocktakeId: selectedStocktake.data?.stocktake.stocktake!.id!,
            venueId: selectedVenueObject?.id!,
            totalActiveSeconds: getTotalActiveSeconds(),
          },
        },
        refetchQueries: ['useStocktakesQuery'],
      });

      if (response.data?.confirmStocktake.successful) {
        push('/stocktakes');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const subHeading = selectedVenueObject?.displayName
    ? `Add sections to ${selectedVenueObject?.displayName} Venue to record stocktake`
    : `Add sections to record stocktake`;
  const disableConfirm =
    confirmStocktake.loading ||
    selectedStocktake.data?.stocktake.stocktake?.stocktakeSections.length === 0;

  return (
    <S.Container>
      <LayoutPage
        backButton={true}
        backButtonCTA={handleCancel}
        align={show ? 'left' : 'center'}
        withLine
        heading={
          !isEmpty(selectedStocktake) ? 'Edit Stocktake' : 'Add Stocktake'
        }
        subHeading={subHeading}
        slider
        actionArray={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? [
                <Button
                  color="default"
                  asCircle
                  inversed
                  onClick={handleCancel}
                >
                  x
                </Button>,
                <Button
                  small
                  color="secondary"
                  onClick={handleConfirmStocktake}
                  disabled={disableConfirm}
                >
                  <DotsLoading
                    text={(loading) => (loading ? 'Saving' : 'Save')}
                    isLoading={confirmStocktake.loading}
                    size="small"
                    lineHeight={10}
                    noMargin
                  />
                </Button>,
              ]
            : [
                <Button color="default" inversed onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  color="secondary"
                  onClick={handleConfirmStocktake}
                  className="Thebutton"
                  disabled={disableConfirm}
                >
                  <DotsLoading
                    text={(loading) =>
                      `${loading ? 'Confirming' : 'Confirm'} Stocktake`
                    }
                    isLoading={confirmStocktake.loading}
                    size="small"
                    lineHeight={10}
                    noMargin
                  />
                </Button>,
              ]
        }
      >
        <Header selectedStocktake={selectedStocktake} />
        {selectedStocktake && (
          <SectionList selectedStocktake={selectedStocktake} />
        )}
      </LayoutPage>
    </S.Container>
  );
};
