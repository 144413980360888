import { roundTo } from './numbers';

export const calculateQuantity = (
  quantity: number,
  originalServes: number,
  serves?: number
) => {
  if (serves && serves >= 1) {
    const singleServeQuantity = quantity / originalServes;
    return serves * singleServeQuantity;
  }

  return quantity;
};

export const calculateScaleQuantity = ({
  currentQuantity,
  prevServes,
  newServes,
}: {
  currentQuantity: number;
  prevServes: number;
  newServes: number;
}) => {
  if (newServes) {
    const singleServeQuantity = currentQuantity / prevServes;
    return roundTo(newServes * singleServeQuantity, 1);
  }

  // return quantity;
  return currentQuantity;
};
