import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.section`
  width: 100%;
`;

export const Header = styled.section`
  padding: 30px 15px 10px;
`;

export const CostLink = styled(Span)`
  cursor: pointer;
`;

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-gap: 15px;

  @media ${device.mobileL} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
`;
