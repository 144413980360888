import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useForm } from 'react-hook-form';
import { LayoutPage, LayoutColumn } from '../../shared/layout';
import { theme } from '../../../styles/theme';
import { SmallButton } from '../ingredient/Ingredient.styles';
import { Button } from '../../shared/button';
import { Header, Form, Container } from './Brand.styles';
import { Input, SelectList } from '../../shared/formElements';
import { ButtonGroup, SideLabel } from '../../shared/layout/Layout.styles';
import {
  useBrandQuery,
  useUpdateBrandMutation,
  useIngredientQuery,
  useBrandsQuery,
  useCreateBrandMutation,
  useUpdateIngredientProductMutation,
  IngredientDocument,
  useMeQuery,
} from '../../../generated/graphql';
import { LogoLoading, DotsLoading } from '../../shared/loading';
import {
  capitalizeFirstLetter,
  cleanURL,
  isEmpty,
} from '../../../utils/helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { IInitialSelectListOptionProps } from '../../shared/formElements/selectList/selectList.types';
import { overlayConstants } from '../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

const Brand = () => {
  const {
    appWidth,
    selectedBrand,
    selectedIngredient,
    selectedIngredientProduct,
    selectedVenueObject,
    selectedRecipe,
    account,
    user,
    topSliderToggle,
    dispatch,
  } = useContext(LayoutContext);
  const [state, setState] = useState({
    editBrand: selectedBrand,
    showDisplayNamePrompt: false,
  });
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const { editBrand, showDisplayNamePrompt } = state;

  const brands = useBrandsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const brand = useBrandQuery({
    variables: {
      input: { brandId: selectedBrand || editBrand! },
    },
  });
  const ingredient = useIngredientQuery({
    variables: {
      input: {
        ingredientId: selectedIngredient!,
        venueId: selectedVenueObject?.id!,
      },
    },
  });
  const me = useMeQuery({
    fetchPolicy: 'cache-only',
  });

  const [updateBrandMutation, updateBrand] = useUpdateBrandMutation();
  const [createBrandMutation, createBrand] = useCreateBrandMutation();
  const [
    updateIngredientProductMutation,
    updateIngredientProduct,
  ] = useUpdateIngredientProductMutation();

  let availableBrands: IInitialSelectListOptionProps[] = [
    {
      label: '',
      value: '',
    },
  ];

  if (!isEmpty(brands.data)) {
    availableBrands = brands!.data!.brands!.brands!.map((brand) => {
      return {
        label: brand.displayName,
        value: brand.id,
      };
    });
  }

  const { register, handleSubmit, errors, control, reset, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      selectedBrand ? editValidationSchema : createValidationSchema
    ),
    defaultValues: {
      displayName: selectedBrand ? selectedBrand : { label: '', value: '' },
      email: '',
      website: '',
      phone: '',
    },
  });

  const displayName = watch('displayName');

  const waitToUpdateBrand = useCallback(
    async (brandId: string) => {
      dispatch({
        type: 'SELECT_BRAND',
        payload: brandId,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!brand.loading && !brands.loading && brand.data?.brand.successful) {
      const { displayName, email, website, phone } = brand!.data!.brand!.brand!;
      reset({
        displayName:
          selectedBrand || editBrand ? displayName : { label: '', value: '' },
        email: email || '',
        website: website || '',
        phone: phone || '',
      });
    }
  }, [brand, brands, selectedBrand, state, editBrand, reset]);

  useEffect(() => {
    if (
      displayName &&
      typeof displayName === 'object' &&
      displayName.label.toLowerCase() !== displayName.value.toLowerCase()
    ) {
      if (selectedIngredientProduct) {
        const updateIngredientProduct = async () => {
          try {
            dispatch({ type: 'SELECT_BRAND', payload: displayName.value });
            const response = await updateIngredientProductMutation({
              variables: {
                input: {
                  id: selectedIngredientProduct!,
                  ingredientId: selectedIngredient!,
                  venueId: selectedVenueObject?.id!,
                  brandId: displayName.value,
                  totalActiveSeconds: getTotalActiveSeconds(),
                },
              },
            });

            if (response.data?.updateIngredientProduct.successful) {
              await waitToUpdateBrand(displayName.value);
              if (topSliderToggle && isEmpty(selectedBrand)) {
                dispatch({ type: 'TOGGLE_TOP_SLIDER' });
              }
            }
          } catch (error) {
            console.log('err ###', error);
          }
        };
        updateIngredientProduct();
      } else {
        dispatch({ type: 'SELECT_BRAND', payload: displayName.value });
      }
    }

    if (
      typeof displayName === 'string' &&
      showDisplayNamePrompt &&
      !isEmpty(brand.data) &&
      displayName !== brand.data!.brand!.brand!.displayName &&
      me.data?.me?.user?.id !== brand.data!.brand!.brand!.creatorId
    ) {
      setState({ ...state, showDisplayNamePrompt: true });
    }
  }, [
    displayName,
    selectedRecipe,
    selectedIngredient,
    selectedIngredientProduct,
    selectedVenueObject?.id,
    selectedBrand,
    state,
    me,
    showDisplayNamePrompt,
    brand.data,
    topSliderToggle,
    waitToUpdateBrand,
    setState,
    dispatch,
    updateIngredientProductMutation,
  ]);

  const handleCancel = async () => {
    dispatch({ type: 'SET_BRAND_USER_EVENT', payload: undefined });
    await waitToUpdateBrand('');
    if (topSliderToggle) {
      dispatch({ type: 'TOGGLE_TOP_SLIDER' });
    }
  };

  const onSubmit = async (formData) => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveBrand,
      });
      return;
    }

    if (selectedBrand) {
      try {
        const inputData = {
          ...formData,
          displayName: capitalizeFirstLetter(formData.displayName),
          website: cleanURL(formData.website),
          ingredientProductId: selectedIngredientProduct
            ? selectedIngredientProduct
            : '',
          brandId: selectedBrand,
        };
        const response = await updateBrandMutation({
          variables: {
            input: inputData,
          },
        });
        if (response.data?.updateBrand.successful) {
          if (topSliderToggle) {
            dispatch({ type: 'TOGGLE_TOP_SLIDER' });
          }
        }
      } catch (error) {
        console.log('err ###', error);
      }
    } else {
      if (
        formData.displayName.label.toLowerCase() ===
        formData.displayName.value.toLowerCase()
      ) {
        const inputData = {
          ...formData,
          website: cleanURL(formData.website),
          ingredientProductId: selectedIngredientProduct
            ? selectedIngredientProduct
            : '',
          displayName: formData.displayName.label,
        };
        try {
          const response = await createBrandMutation({
            variables: {
              input: inputData,
            },
            refetchQueries: [
              {
                query: IngredientDocument,
                variables: {
                  input: {
                    ingredientId: selectedIngredient,
                    venueId: selectedVenueObject?.id!,
                  },
                },
              },
            ],
            awaitRefetchQueries: true,
          });
          if (response.data?.createBrand.successful) {
            await waitToUpdateBrand(response!.data!.createBrand!.brand!.id);
            if (topSliderToggle) {
              dispatch({ type: 'TOGGLE_TOP_SLIDER' });
            }
          }
        } catch (error) {
          console.log('err ###', error);
        }
      } else {
        try {
          await updateIngredientProductMutation({
            variables: {
              input: {
                id: selectedIngredientProduct!,
                ingredientId: selectedIngredient!,
                venueId: selectedVenueObject?.id!,
                brandId: formData.displayName.value,
              },
            },
          });
          await waitToUpdateBrand(formData.displayName.value);
        } catch (error) {
          console.log('err ###', error);
        }
      }
    }
  };

  const sideLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  if (brand.loading || updateIngredientProduct.loading) {
    return <LogoLoading size={60} />;
  }

  const saveOrAdd = selectedBrand ? 'Save' : 'Add';
  const savingOrAdding = selectedBrand ? 'Saving' : 'Adding';
  const editOrAdd = selectedBrand ? 'Edit' : 'Add';

  const isLoading =
    createBrand.loading ||
    updateBrand.loading ||
    updateIngredientProduct.loading;

  return (
    <LayoutPage
      backButton
      backButtonCTA={() => handleCancel()}
      align="center"
      withLine
      heading={`${editOrAdd} Brand`}
      subHeading={
        selectedBrand || editBrand
          ? `Update ${displayName} details`
          : selectedIngredient
          ? `Find or Add a new brand for ${ingredient.data?.ingredient.ingredient?.displayName}`
          : 'Find or Add a new Brand'
      }
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={handleCancel}>
                x
              </Button>,
              <SmallButton
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) => (loading ? savingOrAdding : saveOrAdd)}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </SmallButton>,
            ]
          : [
              <Button color="default" inversed onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) =>
                    `${loading ? savingOrAdding : saveOrAdd} Brand`
                  }
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <Container>
        <Header>
          {selectedBrand && typeof displayName === 'string' && displayName}{' '}
          Brand Details
        </Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <LayoutColumn>
            {sideLabel('Brand Name *')}
            {selectedBrand || editBrand ? (
              <Input
                ref={register}
                label={fieldLabel('Brand Name *')}
                name="displayName"
                errorText={errors.displayName?.message}
                helperText={
                  showDisplayNamePrompt
                    ? 'Thank you for helping to fix up spelling errors, please note your changes will go through a review process prior to being updated'
                    : undefined
                }
              />
            ) : (
              <SelectList
                autoComplete
                freeSolo
                control={control}
                name="displayName"
                label={fieldLabel('Brand Name *')}
                // @ts-expect-error
                errorText={errors.displayName?.value?.message}
                options={availableBrands!}
              />
            )}
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Website *')}
            <Input
              ref={register}
              label={fieldLabel('Website *')}
              name="website"
              errorText={errors.website?.message}
            />
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Email')}
            <Input
              ref={register}
              label={fieldLabel('Email')}
              name="email"
              errorText={errors.email?.message}
            />
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Phone')}
            <Input
              ref={register}
              label={fieldLabel('Phone')}
              name="phone"
              errorText={errors.phone?.message}
            />
          </LayoutColumn>
        </Form>
      </Container>
      <ButtonGroup>
        <Button color="default" inversed onClick={handleCancel}>
          Cancel
        </Button>

        <Button
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <DotsLoading
            text={(loading) => `${loading ? savingOrAdding : saveOrAdd} Brand`}
            isLoading={isLoading}
            size="small"
            lineHeight={10}
            noMargin
          />
        </Button>
      </ButtonGroup>
    </LayoutPage>
  );
};

const displayNameObject = {
  value: Yup.string().required('Brand name is required'),
};

const editValidationSchema = Yup.object().shape({
  displayName: Yup.string().required('Brand name is required'),
  website: Yup.string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-zA-Z0-9]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      'Please check website url format'
    )
    .required('Website is required'),
});

const createValidationSchema = Yup.object().shape({
  displayName: Yup.object()
    .shape(displayNameObject)
    .required('Brand name is required')
    .typeError('Brand name is required'),
  website: Yup.string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-zA-Z0-9]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      'Please check website url format'
    )
    .required('Website is required'),
});

export default Brand;
