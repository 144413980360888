import styled from 'styled-components/macro';
import { device } from '../../../styles/device';
import { Card } from '../../shared/card';
import { LayoutColumnContainer } from '../../shared/layout/layoutColumn/LayoutColumn.styles';
import { Span } from '../../shared/typefaces/Typefaces.styles';

interface IFormProps {
  hasPaymentMethod?: boolean;
}

interface IHeadingProps {
  hideMarginBottom?: boolean;
}

export const Container = styled(Card)`
  margin: 30px 15px;

  @media ${device.tablet} {
    margin: 30px;
  }
`;

export const Header = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 14px 14px 0 0;
  padding: 5px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const Form = styled.form<IFormProps>`
  display: flex;
  flex-direction: column;
`;

export const AddressForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 20px 10px;
  padding-bottom: 20px;
`;

export const CardForm = styled.div<IFormProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasPaymentMethod }) =>
    hasPaymentMethod ? 'space-between' : 'center'};
  align-items: flex-start;
  padding: 20px;

  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto;
  }

  @media ${device.tablet} {
    ${LayoutColumnContainer} {
      grid-template-columns: 160px auto;
    }
  }

  @media ${device.laptopL} {
    flex-direction: row;
  }
`;

export const FormSection = styled.div``;

export const Heading = styled(Span)<IHeadingProps>`
  display: block;
  margin-top: 0 !important;
  margin-bottom: ${({ hideMarginBottom }) =>
    hideMarginBottom ? 0 : '20px'}!important;
`;

export const CurrentCard = styled.div`
  border: 1px solid gray;
  border-radius: 10px;
  padding: 0 15px;
  min-width: 300px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 15px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 15px;
    grid-gap: 30px;
  }
`;

export const Left = styled.div``;
export const Divider = styled.div`
  display: none;
  align-items: center;
  flex-shrink: 0;
  padding: 20px;

  @media ${device.laptopL} {
    display: flex;
    height: 200px;
  }
`;
export const Right = styled.div``;

export const CTA = styled.div`
  padding: 0 15px;
`;
