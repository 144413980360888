import styled from 'styled-components/macro';
import { Span, H5 } from '../../typefaces/Typefaces.styles';
import { device } from '../../../../styles/device';

export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 60px 15px;
  display: flex;
  flex-direction: column;
  position: relative;

  svg {
    fill: ${({ theme }) => theme.colors.background};
  }
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  margin: 0 auto;
  padding-bottom: 30px;
  position: absolute;
  top: 400px;
  left: 50%;
  margin-left: -50px;

  @media ${device.mobileL} {
    top: 350px;
  }
`;

export const Comment = styled(H5)`
  font-size: ${({ theme }) => theme.fontSize.large};
  margin: 30px 0;
`;

export const Name = styled(H5)`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 5px;
  /* display: inline; */
  padding-right: 15px;
`;

export const Title = styled(Span)`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const QuoteMark = styled(Span)`
  margin-right: 5px;
  font-size: ${({ theme }) => theme.fontSize.large};
`;
