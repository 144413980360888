import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Sweet: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2176 5104 c-120 -29 -250 -124 -310 -227 -103 -176 -95 -412 20
-562 l34 -45 -68 0 c-109 -1 -199 -18 -275 -53 -255 -119 -366 -421 -247 -674
16 -35 41 -79 56 -98 l26 -35 -149 0 c-159 0 -214 -10 -304 -55 -189 -96 -304
-321 -270 -527 13 -78 53 -176 96 -233 l27 -34 -29 -6 c-15 -3 -56 -15 -91
-26 -263 -81 -413 -379 -323 -641 29 -85 65 -141 134 -205 l62 -58 264 -755
c145 -415 272 -776 283 -801 12 -28 29 -51 44 -58 36 -16 2772 -16 2808 0 15
7 32 30 44 58 11 25 138 385 282 800 l264 753 58 55 c69 67 111 129 138 209
60 172 19 367 -105 503 -82 90 -170 137 -308 166 l-28 6 31 45 c132 191 128
419 -9 605 -53 72 -135 133 -229 170 -49 20 -79 24 -225 27 l-168 4 24 31
c161 212 134 513 -64 691 -71 64 -174 113 -270 127 l-76 11 -16 68 c-58 243
-251 438 -493 500 -53 14 -111 20 -183 20 -127 0 -168 10 -206 50 -23 24 -29
41 -32 87 -3 32 -9 68 -15 79 -22 45 -92 55 -202 28z m59 -204 c7 -31 55 -103
89 -133 12 -10 46 -30 76 -44 49 -24 71 -27 210 -33 121 -5 166 -11 207 -27
168 -66 287 -199 327 -365 l6 -28 -457 0 c-458 0 -458 0 -515 24 -107 44 -176
126 -204 239 -14 57 -15 79 -6 129 24 127 100 221 217 264 37 14 42 11 50 -26z
m1151 -811 c115 -21 201 -89 253 -198 37 -80 37 -192 0 -272 -52 -109 -137
-176 -254 -199 -43 -8 -290 -10 -865 -8 l-805 3 -56 26 c-79 37 -133 90 -171
167 -29 58 -33 76 -33 147 0 66 5 90 26 136 50 106 138 176 249 198 68 13
1583 13 1656 0z m659 -870 c32 -12 77 -41 108 -69 198 -180 122 -500 -135
-575 -49 -14 -206 -15 -1484 -13 l-1429 3 -47 23 c-75 38 -134 96 -169 168
-64 130 -36 283 70 388 28 28 71 57 107 71 l59 25 1432 0 c1424 0 1433 0 1488
-21z m315 -845 c100 -30 172 -91 214 -184 38 -83 43 -153 17 -239 -12 -39 -24
-71 -28 -71 -3 0 -24 16 -46 36 -137 124 -373 139 -535 34 l-58 -37 -44 40
c-25 22 -74 54 -110 71 -62 29 -71 31 -185 31 -114 0 -123 -2 -186 -31 -37
-18 -84 -48 -106 -68 -22 -20 -44 -36 -48 -36 -4 0 -26 16 -48 36 -23 19 -71
49 -107 67 -55 26 -80 32 -157 35 -136 6 -233 -24 -330 -105 l-43 -34 -43 34
c-97 81 -194 111 -330 105 -77 -3 -102 -9 -157 -35 -36 -18 -84 -48 -107 -67
-22 -20 -43 -36 -46 -36 -3 0 -25 16 -49 37 -135 114 -333 138 -493 59 -38
-19 -85 -51 -104 -70 l-34 -35 -31 24 c-88 67 -219 100 -331 84 -99 -14 -161
-43 -272 -131 -21 -17 -57 122 -49 190 13 111 83 219 171 264 92 49 15 47
1871 47 1569 1 1756 -1 1804 -15z m-2705 -508 c28 -13 70 -44 95 -70 l44 -46
37 -737 c21 -406 38 -762 38 -790 l1 -53 -173 0 -173 0 -119 795 -119 795 39
40 c61 63 125 90 209 90 54 0 83 -6 121 -24z m680 0 c28 -13 69 -40 93 -61
l42 -37 0 -799 0 -799 -215 0 -215 0 -1 63 c0 34 -17 392 -37 796 l-37 733 52
48 c63 58 116 78 203 79 49 1 78 -5 115 -23z m669 8 c26 -9 70 -37 99 -64 l52
-48 -37 -733 c-20 -404 -37 -762 -37 -796 l-1 -63 -215 0 -215 0 0 799 0 799
43 37 c92 81 203 106 311 69z m702 -9 c27 -13 67 -43 89 -65 l39 -40 -119
-795 -119 -795 -173 0 -173 0 1 53 c0 28 17 384 38 790 l37 737 44 46 c64 66
128 94 216 94 55 0 81 -5 120 -25z m-2690 -40 c104 -52 87 15 213 -835 62
-415 115 -770 118 -787 l5 -33 -48 0 -49 0 -279 797 c-200 572 -276 801 -269
812 5 9 33 28 63 44 74 37 173 38 246 2z m3334 -2 c30 -16 58 -35 63 -44 7
-11 -69 -240 -269 -812 l-279 -797 -49 0 c-48 0 -48 1 -43 28 2 15 55 369 118
787 107 721 114 761 138 788 73 80 218 102 321 50z"
        />
        <path d="M2650 4525 l0 -85 85 0 86 0 -3 83 -3 82 -82 3 -83 3 0 -86z" />
        <path
          d="M1964 3917 c-3 -8 -4 -47 -2 -88 l3 -74 83 -3 82 -3 0 91 0 90 -80 0
c-58 0 -82 -4 -86 -13z"
        />
        <path d="M3160 3840 l0 -91 83 3 82 3 0 85 0 85 -82 3 -83 3 0 -91z" />
        <path d="M2390 3665 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M1620 2985 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M2650 2985 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M3240 2985 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M1192 2818 l3 -83 88 -3 87 -3 0 86 0 85 -90 0 -91 0 3 -82z" />
        <path d="M2130 2815 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
        <path d="M3670 2815 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z" />
      </g>
    </IconStyled>
  );
};

export default Sweet;
