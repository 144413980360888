import React, { FC, useContext, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { Card, Header, Content } from '../../../../shared/card';
import { Dollar, Share } from '../../../../shared/icons';
import { theme } from '../../../../../styles/theme';
import { Button } from '../../../../shared/button';
import { Row, Table, Form, Column } from './Detail.styles';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Input, SelectList } from '../../../../shared/formElements';
import { IDetailProps } from './detail.types';
import { currency } from '../../../../../utils/helper';
import { constants } from '../../../../../utils/constants';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

const Detail: FC<IDetailProps> = ({
  ingredient,
  selectableSizes,
  selectedCostPer100g,
  setIngredientData,
}) => {
  const {
    appWidth,
    account,
    user,
    selectedIngredientProduct,
    dispatch,
  } = useContext(LayoutContext);

  const { errors, control, reset, watch } = useFormContext();

  useEffect(() => {
    const selectedBrand = watch('brand');
    const selectedSupplier = watch('supplier');

    const ingredientProduct = ingredient?.data?.ingredient.ingredient?.ingredientProducts.find(
      (product) => product.id === selectedIngredientProduct
    );

    if (
      ingredientProduct &&
      ((ingredientProduct.brandId &&
        ingredientProduct.brandId !== selectedBrand) ||
        (ingredientProduct?.supplierId &&
          ingredientProduct.supplierId !== selectedSupplier))
    ) {
      const supplierBrands = ingredient?.data?.ingredient.ingredient?.ingredientProducts.filter(
        (product) => {
          return product.supplierId === selectedSupplier;
        }
      );
      const brands = [];
      if (supplierBrands) {
        for (const product of supplierBrands) {
          if (product.brandId && !brands[product.brandId]) {
            brands[product.brandId] = { ...product, brands: [] };
          }
        }
      }
    }
  }, [selectedIngredientProduct, ingredient, reset, watch]);

  if (ingredient?.loading) {
    return null;
  }

  const handleEditIngredient = () => {
    setIngredientData();
    dispatch({ type: 'TOP_SLIDER_PAGE', payload: 'editIngredient' });
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountEditIngredient,
      });
    }
  };

  const ingredientData = ingredient?.data?.ingredient?.ingredient;

  return (
    <Card withCardLink>
      <Header
        icon={<Dollar size="small" />}
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? `Edit ${ingredientData?.displayName} Details`
            : `${ingredientData?.displayName} Details`
        }
        subHeading={`Select brand & supplier to refine ${ingredientData?.displayName} costs`}
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button asCircle onClick={handleEditIngredient}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button color="default" inversed onClick={handleEditIngredient}>
              Edit Ingredient
            </Button>
          )
        }
      />
      <Content fullWidth>
        <Table>
          <Row>
            <Column>
              <Label>
                {ingredientData?.displayName} Cost:{' '}
                <Span color="faded" font-size="small">
                  {selectedCostPer100g &&
                    `$${currency(selectedCostPer100g)} per 100${
                      ingredientData?.liquid
                        ? constants.metric.ml.s
                        : constants.metric.gram.s
                    }`}
                </Span>
              </Label>
            </Column>
          </Row>
          <Row>
            <Form>
              <div className="colOne">
                <Controller
                  as={<Input />}
                  type="number"
                  name="cost"
                  label="Cost per"
                  control={control}
                  errorText={errors.cost && errors.cost.message}
                  defaultValue=""
                  className="costInput"
                />
                <SelectList
                  className="gramsInput"
                  autoComplete
                  type="number"
                  control={control}
                  name="grams"
                  label={
                    ingredientData?.liquid
                      ? constants.metric.ml.p
                      : constants.metric.gram.p
                  }
                  errorText={
                    errors.grams &&
                    (errors.grams.message || errors.grams.value.message)
                  }
                  options={selectableSizes}
                  addValue={ingredientData?.liquid ? 'mL' : 'g'}
                />
              </div>
              <div className="colTwo">
                <Controller
                  as={<Input />}
                  type="number"
                  label="Waste %"
                  name="wastage"
                  control={control}
                  errorText={errors.waste && errors.waste.message}
                  defaultValue=""
                />
              </div>
            </Form>
          </Row>
        </Table>
      </Content>
    </Card>
  );
};

export default Detail;
