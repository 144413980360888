import styled from 'styled-components/macro';
import { H4, Span } from '../../typefaces/Typefaces.styles';

export const Container = styled.div`
  padding: 20px;

  ${H4} {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.medium};
    min-height: 40px;
    padding-bottom: 15px;
  }

  ${Span} {
    line-height: 26px;
  }
`;

export const Icon = styled.div`
  margin: -50px 0 15px;
  /* width: 100px; */
  /* height: 100px; */
`;
