import React, { FC } from 'react';
import { Container, Content } from './Content.styles';
import { ContentProps } from './Content.types';

const CardHeader: FC<ContentProps> = ({ children, fullWidth, className }) => (
  <Container fullWidth={fullWidth} className={className}>
    <Content>{children}</Content>
  </Container>
);

export default CardHeader;
