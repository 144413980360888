import React, { useContext, FC } from 'react';
import { LayoutColumn } from '../../../shared/layout';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, SelectList } from '../../../shared/formElements';
import { mockData } from '../../../../utils/mockData';
import { NestedColumns, SideLabel, Section } from '../Venue.styles';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { theme } from '../../../../styles/theme';
import { IVenueDataProps } from '../../account/sections/venues/venues.types';
import { AccountType, VenueType } from '../../../../generated/graphql';
import { capitalizeFirstLetter } from '../../../../utils/helper';

const Specs: FC<IVenueDataProps> = ({ data }) => {
  const { account, addVenue, appWidth } = useContext(LayoutContext);
  const { errors, control } = useFormContext();

  const sideLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  const venue = addVenue ? undefined : data?.data?.venue?.userVenue?.venue;

  const average = { label: 'Use Ingredient Averages', value: 'average' };
  const zero = { label: 'Zero Waste', value: 'zero' };
  const wastage = venue?.avgWastagePercentage
    ? {
        label: `${venue?.avgWastagePercentage}%`,
        value: `${venue?.avgWastagePercentage}`,
      }
    : undefined;

  const selectableWaste = wastage ? [average, zero, wastage] : [average, zero];

  const wasteDefaultValue = venue?.avgWastagePercentage
    ? venue?.avgWastagePercentage === 0
      ? zero
      : wastage
    : average;

  const venueTypeOptions = Object.keys(VenueType)
    .filter((type) => {
      if (
        (VenueType[type].toString() === VenueType.RecipeRevenue &&
          account?.type !== AccountType.RecipeRevenue) ||
        (VenueType[type].toString() === 'PERSONAL' && !venue?.personal)
      ) {
        return undefined;
      }
      return type;
    })
    .map((type) => {
      return {
        label: capitalizeFirstLetter(VenueType[type].toString(), true),
        value: VenueType[type].toString(),
      };
    });

  return (
    <Section>
      <LayoutColumn>
        {sideLabel('Venue Name')}
        <Controller
          as={<Input disabled={venue?.personal || false} />}
          name="displayName"
          label={fieldLabel('Venue Name')}
          control={control}
          errorText={errors?.displayName?.message ?? ''}
          defaultValue={venue?.displayName ?? ''}
          disabled={venue?.personal || false}
          capitalizeFirstLetterEachWord
        />
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Venue Type')}
        <SelectList
          control={control}
          name="type"
          placeholder="Select Venue Type"
          label={fieldLabel('Venue Type')}
          errorText={errors?.type?.message ?? ''}
          options={venueTypeOptions}
          defaultValue={venue?.type}
          disabled={venue?.personal || false}
        />
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Weeks Open')}
        <NestedColumns>
          <Controller
            as={<Input />}
            name="weeksOpen"
            defaultValue={addVenue ? 52 : venue?.weeksOpen}
            label={fieldLabel('Weeks Open')}
            control={control}
            errorText={errors.weeksOpen && errors.weeksOpen.message}
          />
          <SelectList
            control={control}
            noBorder
            name="weeksOpenUnit"
            defaultValue="year"
            errorText={errors.type && errors.type.message}
            options={mockData.weeksOpenUnits}
          />
        </NestedColumns>
      </LayoutColumn>
      {/* Confused users, need to test more */}
      {/* <LayoutColumn>
        {sideLabel('Prep Time')}
        <NestedColumns>
          <Controller
            as={<Input />}
            type="number"
            name="prepTime"
            label={fieldLabel('Prep Time')}
            control={control}
            errorText={errors.prepTime && errors.prepTime.message}
            defaultValue={venue?.prepTime}
          />

          <SelectList
            control={control}
            noBorder
            name="prepTimeUnit"
            defaultValue={venue?.prepTimeUnit}
            errorText={errors.prepTimeUnit && errors.prepTimeUnit.message}
            options={mockData.prepTimeUnits}
          />
        </NestedColumns>
      </LayoutColumn> */}
      <LayoutColumn>
        {sideLabel('Average Waste %')}
        <SelectList
          autoComplete
          type="number"
          control={control}
          name="avgWastagePercentage"
          label={fieldLabel('Average Wastage %')}
          options={selectableWaste}
          defaultValue={addVenue ? selectableWaste[0] : wasteDefaultValue}
          addValue="%"
        />
      </LayoutColumn>
    </Section>
  );
};

export default Specs;
