import styled from 'styled-components/macro';
import { IIconProps } from './icon.types';

export const IconStyled = styled.svg<IIconProps>`
  height: ${({ size, theme }) =>
    size ? theme.size[size] : theme.size.default};
  width: ${({ size, theme }) => (size ? theme.size[size] : theme.size.default)};
  fill: ${({ color, faded, theme }) =>
    color
      ? faded
        ? theme.colors[color].faded
        : theme.colors[color].default
      : faded
      ? theme.colors.text.faded
      : theme.colors.black};
  -webkit-transform: ${({ rotate }) =>
    rotate ? `rotate(${rotate}deg)` : 'rotate(0deg)'};
  -moz-transform: ${({ rotate }) =>
    rotate ? `rotate(${rotate}deg)` : 'rotate(0deg)'};
  -ms-transform: ${({ rotate }) =>
    rotate ? `rotate(${rotate}deg)` : 'rotate(0deg)'};
  -o-transform: ${({ rotate }) =>
    rotate ? `rotate(${rotate}deg)` : 'rotate(0deg)'};
  transform: ${({ rotate }) =>
    rotate ? `rotate(${rotate}deg)` : 'rotate(0deg)'};
`;
