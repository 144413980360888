import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useSendPasswordResetMutation } from '../../../generated/graphql';
import { TextButton } from '../../shared/button/TextButton.styles';
import { Input } from '../../shared/formElements';
import { AngleBox } from '../../shared/layout';
import { DotsLoading } from '../../shared/loading';
import {
  ErrorMessage,
  H3,
  Span,
} from '../../shared/typefaces/Typefaces.styles';
import {
  Box,
  CenteredText,
  Container,
  Content,
  Form,
  Heading,
  Left,
  Main,
  Right,
  SubmitButton,
} from './Login.styles';

interface Props {
  hideForgotPasswordForm: () => void;
}

const ForgotPasswordForm: FC<Props> = ({ hideForgotPasswordForm }) => {
  const [sendPasswordReset, { data, loading }] = useSendPasswordResetMutation();
  const [isSuccessful, setSuccessful] = useState(false);
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formData) => {
    const { email } = formData;
    if (!loading && !isSuccessful) {
      try {
        const response = await sendPasswordReset({
          variables: {
            input: {
              email,
            },
          },
        });

        if (response?.data?.sendPasswordReset.successful) {
          setSuccessful(true);
        }
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  const responseErrors = data?.sendPasswordReset.error && (
    <ErrorMessage fontSize="small">
      {data?.sendPasswordReset.error}
    </ErrorMessage>
  );

  const backToLoginButton = (
    <TextButton
      type="submit"
      onClick={hideForgotPasswordForm}
      disabled={loading}
    >
      Back to login
    </TextButton>
  );

  const innerForm = (
    <Fragment>
      <Span className="quote">
        Add your account email below to send yourself a password link to reset
        your password
      </Span>
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Input
          ref={register}
          label="Email"
          name="email"
          errorText={errors.email?.message}
        />
      </Form>
      <SubmitButton
        type="submit"
        onClick={handleSubmit(onSubmit)}
        color="primary"
        disabled={loading}
      >
        <DotsLoading
          text={(loading) => (loading ? 'Submitting' : 'Submit')}
          isLoading={loading}
          size="small"
          lineHeight={10}
          noMargin
        />
      </SubmitButton>
      {backToLoginButton}
    </Fragment>
  );

  return (
    <Container>
      <AngleBox color="primary" marginTop="large" />
      <Main>
        <Content>
          <Left>
            <div>
              <Heading>Reset Password</Heading>
            </div>
          </Left>
          <Right>
            <Box>
              <AngleBox color="secondary" marginTop="small" height="tiny" />
              <H3 className="heading">Email Reset Link</H3>
              <div className="content">
                {responseErrors}
                {isSuccessful ? (
                  <>
                    <CenteredText>
                      Password reset sent, please check your email.
                    </CenteredText>
                    {backToLoginButton}
                  </>
                ) : (
                  innerForm
                )}
              </div>
            </Box>
          </Right>
        </Content>
      </Main>
    </Container>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Please check email format'),
});
export default ForgotPasswordForm;
