import { MetricType } from '../../generated/graphql';
import { isEmpty } from './objects';

export const getRecipeIngredientGrams = (
  selectedMetric: string,
  quantity: number,
  ingredientMetrics: Array<{ type: string; grams?: number }> | undefined
): number | undefined => {
  if (!isEmpty(ingredientMetrics)) {
    const ingredientMetric = ingredientMetrics?.find((metric) => {
      return metric.type.toLowerCase() === selectedMetric.toLowerCase();
    });

    switch (selectedMetric.toUpperCase()) {
      case MetricType.Cup:
        return quantity * ingredientMetric?.grams!;
      case 'GRAM':
        return quantity;
      case 'ML':
        return quantity;
      case MetricType.Tablespoon:
        return (ingredientMetric?.grams! / 4) * quantity;
      case MetricType.Teaspoon:
        return (ingredientMetric?.grams! / 16) * quantity;
      case MetricType.Whole:
        return ingredientMetric?.grams! * quantity;
      case MetricType.Slice:
        return ingredientMetric?.grams! * quantity;
      default:
        return undefined;
    }
  } else {
    return undefined;
  }
};
