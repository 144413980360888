import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Dots: React.FC<IIconProps> = ({ color, size, faded, rotate }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 426.667 426.667"
      color={color}
      faded={faded}
      rotate={rotate}
    >
      <g>
        <g>
          <circle cx="42.667" cy="213.333" r="42.667" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="213.333" cy="213.333" r="42.667" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="384" cy="213.333" r="42.667" />
        </g>
      </g>
    </IconStyled>
  );
};

export default Dots;
