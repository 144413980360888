import styled from 'styled-components/macro';

export const Container = styled.fieldset`
  &.cardField {
    border: 2px solid ${({ theme }) => theme.colors.default.default};
    border-radius: 5px;
    padding: 12.5px 14px;
    margin-top: 20px;

    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.default.default};
    }
  }
`;
