import { useContext } from 'react';
import { IOverlayProps } from '../../components/shared/layout/layoutOverlay/layoutOverlay.types';
import { IEditAccount, LayoutContext } from '../../contexts/layoutContext';

export const useRestrictionPopup = () => {
  const { dispatch } = useContext(LayoutContext);

  return (args: {
    heading: string;
    subHeading?: string;
    buttonText: string;
  }) => {
    dispatch({
      type: 'SET_OVERLAY',
      payload: {
        ...args,
        linkText: 'Cancel',
        buttonCTA: () => {
          dispatch({
            type: 'SET_EDIT_ACCOUNT',
            payload: {} as IEditAccount,
          });
          dispatch({ type: 'SLIDER_PAGE', payload: 'editAccount' });
        },
        linkCTA: () => {
          dispatch({ type: 'CLOSE_OVERLAY' });
        },
      } as IOverlayProps,
    });
  };
};
