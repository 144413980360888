import React from 'react';
import {
  currency,
  getCostPer100g,
  getWidthCutoffs,
  isEmpty,
} from '../../../../../utils/helper';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Edit } from './../../../../shared/icons';
import { Col, Icon, Item, Row, StyledLabel } from './IngredientList.styles';

const IngredientListRow = ({ appWidth, col, handleEditIngredient }) => {
  const {
    isSmallerThanPhoneLarge,
    isSmallerThanPhoneMed,
    isSmallerThanTablet,
  } = getWidthCutoffs(appWidth);

  const { ingredient, ingredientId, supplier, brand, size, id } = col;
  const ingredientCostPer100g = size
    ? getCostPer100g(size.productCost, size.productGrams)
    : null;

  const uniqueRecipeCount = col.recipeCount.reduce((unique, item) => {
    return unique.includes(item) ? unique : [...unique, item];
  }, []);

  if (isEmpty(supplier)) {
    if (isSmallerThanPhoneMed) {
      return (
        <Row
          onClick={() => handleEditIngredient(ingredientId, id)}
          updateIngredient
        >
          <Col>
            <Item>
              <Span className="heading">{ingredient.displayName}</Span>
            </Item>
          </Col>
          <Col>
            <Span color="primary">Finish Adding</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row
          onClick={() => handleEditIngredient(ingredientId, id)}
          updateIngredient
        >
          <Col>
            <Item>
              <Span className="heading">{ingredient.displayName}</Span>
            </Item>
          </Col>
          <Col>
            <Span color="primary">Finish Adding Ingredient</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  } else {
    if (isSmallerThanPhoneLarge) {
      return (
        <Row onClick={() => handleEditIngredient(ingredientId, id)}>
          <Col>
            <Item>
              <Span className="heading">{ingredient.displayName}</Span>
              <StyledLabel color="faded" fontSize="small" fontWeight="bold">
                Supplier:{' '}
                {supplier && (
                  <Span color="faded" fontSize="small">
                    {supplier.displayName}
                  </Span>
                )}
              </StyledLabel>
            </Item>
          </Col>
          <Col>
            <Span color="faded">${col.purchaseCost}</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (isSmallerThanTablet) {
      return (
        <Row onClick={() => handleEditIngredient(ingredientId, id)}>
          <Col>
            <Item>
              <Span className="heading">{ingredient.displayName}</Span>
              <StyledLabel color="faded" fontSize="small" fontWeight="bold">
                Supplier:{' '}
                {supplier && (
                  <Span color="faded" fontSize="small">
                    {supplier.displayName}
                  </Span>
                )}
              </StyledLabel>
            </Item>
          </Col>
          <Col>
            <Span color="faded">
              {col.recipeCount.length > 0 ? uniqueRecipeCount.length : '-'}
            </Span>
          </Col>
          <Col>
            <Span color="faded">${col.purchaseCost}</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row onClick={() => handleEditIngredient(ingredientId, id)}>
          <Col>
            <Item>
              <Span className="heading">{ingredient.displayName}</Span>
              <StyledLabel color="faded" fontSize="small" fontWeight="bold">
                Supplier:{' '}
                {supplier && (
                  <Span color="faded" fontSize="small">
                    {supplier.displayName}
                  </Span>
                )}
              </StyledLabel>
            </Item>
          </Col>
          <Col>
            <Span color="faded">{uniqueRecipeCount.length || '-'}</Span>
          </Col>
          <Col>{brand && <Span color="faded">{brand.displayName}</Span>}</Col>
          <Col>
            {ingredientCostPer100g && (
              <Span color="faded">${currency(ingredientCostPer100g)}</Span>
            )}
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  }
};

export default IngredientListRow;
