import React, { FC, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ISelectedVenueProps,
  LayoutContext,
} from '../../../../contexts/layoutContext';
import {
  AccountType,
  MeQuery,
  useMeQuery,
  useSetSelectedVenueMutation,
} from '../../../../generated/graphql';
import logo from '../../../../images/iconLogo-min.png';
import { useRestrictionPopup } from '../../../../utils/customHooks/useRestrictionPopup';
import { useRestrictionCheck } from '../../../../utils/helper/account';
import { restrictionPopupMessages } from '../../../../utils/restrictionPopupMessages';
import { Button } from '../../button';
import { Tick } from '../../icons';
import { H3, H4, Span } from '../../typefaces/Typefaces.styles';
import {
  Container,
  Header,
  MenuItem,
  SubMenu,
  VenueItem,
  Venues,
  VenuesList,
} from './MenuOverlay.styles';
import { useActiveTimeTracker } from '../../../../utils/customHooks/useActiveTimeTracker';

export interface IMenuOverlayProps {
  data?: MeQuery;
}

export const MenuOverlay: FC<IMenuOverlayProps> = () => {
  const {
    showMenuOverlay,
    selectedVenueObject,
    account,
    dispatch,
  } = useContext(LayoutContext);
  const {
    getTotalActiveSeconds,
    setInitialActiveSeconds,
  } = useActiveTimeTracker();
  const checkVenueRestrictions = useRestrictionCheck(['MAX_VENUES']);
  const [setSelectedVenueMutation] = useSetSelectedVenueMutation();
  const { data, refetch } = useMeQuery();
  const { push } = useHistory();
  const location = useLocation();
  const showRestrictionPopup = useRestrictionPopup();

  const user = data?.me?.user;
  const venues = user?.venuesSharedWithYou;

  useEffect(() => {
    const selected = venues?.find((v) => v.venueId === selectedVenueObject?.id);
    if (!venues || !selected) {
      refetch();
    }
  }, [selectedVenueObject?.id, venues, refetch]);

  if (!showMenuOverlay || !data?.me?.successful) {
    return null;
  }

  const handleSelectVenue = async (venueData: ISelectedVenueProps) => {
    dispatch({ type: 'SELECT_VENUE_OBJECT', payload: venueData });
    dispatch({ type: 'SHOW_MENU_OVERLAY', payload: false });
    dispatch({ type: 'SELECT_RECIPE', payload: '' });
    dispatch({ type: 'SELECT_RECIPE_VERSION', payload: '' });
    if (location.pathname === '/recipe-results') {
      push('/recipes');
    }
    if (venues?.length !== 1) {
      try {
        const response = await setSelectedVenueMutation({
          variables: {
            input: {
              venueId: venueData.id!,
              previousVenueId: selectedVenueObject?.id!,
              totalActiveSeconds: getTotalActiveSeconds(),
            },
          },
          refetchQueries: ['useVenueQuery'],
        });

        if (
          response.data?.setSelectedVenue.successful &&
          response.data?.setSelectedVenue.userVenue
        ) {
          const uV = response.data?.setSelectedVenue.userVenue;
          const venue: ISelectedVenueProps = {
            id: uV.venueId,
            displayName: uV.venue.displayName,
            address: uV.venue.address,
            email: uV.venue.email,
            totalActiveSeconds: uV.totalActiveSeconds,
            recipeProfitIncreasePerYear: uV.venue.recipeProfitIncreasePerYear,
          };

          dispatch({ type: 'SELECT_VENUE_OBJECT', payload: venue });
          setInitialActiveSeconds(venue.totalActiveSeconds || 0);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleAddVenue = () => {
    if (!venues) return;

    const restrictionCheck = checkVenueRestrictions({
      // Adding one venue
      numVenues: venues.length + 1,
    });

    if (restrictionCheck.isPass) {
      dispatch({ type: 'SHOW_MENU_OVERLAY', payload: false });
      dispatch({ type: 'SLIDER_PAGE', payload: 'venue' });
      dispatch({ type: 'ADD_VENUE', payload: true });
      dispatch({ type: 'SHOW_MENU_OVERLAY', payload: false });
      push('/account');
    } else {
      dispatch({ type: 'SHOW_MENU_OVERLAY', payload: false });
      showRestrictionPopup(
        restrictionPopupMessages.cannotAddVenueOnMainMenuDueToMaxVenuesLimit
      );
    }
  };

  const handleClose = () => {
    dispatch({ type: 'SHOW_MENU_OVERLAY', payload: false });
  };

  const handleLinkPush = (link: string) => {
    dispatch({ type: 'SHOW_MENU_OVERLAY', payload: false });
    push(link);
  };

  const venuesList = venues
    ?.sort((a, b) => {
      var nameA = a.venue.displayName.toLowerCase(),
        nameB = b.venue.displayName.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
    .map((venue) => {
      const selected = venue.venue.id === selectedVenueObject?.id;
      const venueData = {
        id: venue!.venue.id,
        displayName: venue!.venue.displayName,
        address: venue!.venue.address,
        email: venue!.venue.email,
        totalActiveSeconds: venue!.totalActiveSeconds,
        recipeProfitIncreasePerYear: venue!.venue.recipeProfitIncreasePerYear,
      };
      return (
        <VenueItem
          key={venue.id}
          selected={selected}
          onClick={() => handleSelectVenue(venueData)}
        >
          <H4 className="text">{venue.venue.displayName}</H4>
          {selected && <Tick size="small" color="secondary" />}
        </VenueItem>
      );
    });

  return (
    <Container>
      <Header>
        <img className="logo" src={logo} alt="Logo" />
        <div className="header">
          <H3 className="text">Account Details</H3>
          <Span className="cross" onClick={handleClose}>
            +
          </Span>
        </div>
      </Header>
      <Span className="venuesHeading">Venues</Span>
      <Venues>
        <VenuesList>{venuesList}</VenuesList>
        {account?.type !== 'GUEST' && (
          <VenueItem
            selected={false}
            onClick={handleAddVenue}
            className="addVenue"
          >
            <H4 className="text">+ add a new venue</H4>
          </VenueItem>
        )}
      </Venues>
      <Span className="user">
        {account?.type === AccountType.Guest
          ? `You're not signed in`
          : `You’re signed with `}
        {account?.type !== 'GUEST' && (
          <Span className="highlight">{user?.email || '-'}</Span>
        )}
      </Span>
      <SubMenu>
        {account?.type === AccountType.Guest ? (
          <Button color="primary" onClick={() => handleLinkPush('/sign-up')}>
            Create Account
          </Button>
        ) : (
          <MenuItem
            onClick={() => handleLinkPush('/account')}
            className="addVenue"
          >
            <H4 className="text">Settings</H4>
          </MenuItem>
        )}
      </SubMenu>
    </Container>
  );
};
