import React, { useEffect, useState } from 'react';
import {
  AddressContainer,
  AddressHeader,
  AddressLoading,
  ManualAddress,
  SwapAddress,
} from './Address.styles';
import { DotsLoading } from '../../loading';
import { useLoadScript } from '@react-google-maps/api';
import { IAddressProps } from '../selectList/selectList.types';
import AddressInput from './AddressInput';
import Input from '../input/Input';
import { Controller } from 'react-hook-form';
import { isEmpty } from '../../../../utils/helper';

export interface IAddressFormProps extends IManualAddressProps {
  addressLookup: '';
  location: string;
  unit: string;
  streetNumber: string;
  road: string;
  shire: string;
  countryShort: string;
  lat: number;
  lng: number;
}

export interface IManualAddressProps {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
}

export const defaultAddressValues = {
  addressId: '',
  addressLookup: '',
  location: '',
  unit: '',
  streetNumber: '',
  road: '',
  city: '',
  shire: '',
  countryShort: '',
  addressLine1: '',
  addressLine2: '',
  state: '',
  country: '',
  postcode: '',
};

const Address = ({ initialAddressValues, ...props }: IAddressProps) => {
  const [enterManually, setEnterManually] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: ['places'],
  });
  const hideInputClassName = enterManually ? '' : 'hiddenInput';
  const hideAddressLookupClassName = enterManually ? 'hiddenInput' : '';

  const handleLookupAddress = () => {
    setEnterManually(false);
  };

  const handleEnterManually = () => {
    setEnterManually(true);
  };

  useEffect(() => {
    if (isLoaded) {
      const initialFormValues = props.getValues();
      if (initialAddressValues) {
        props.reset({
          ...initialFormValues,
          ...initialAddressValues,
          addressId: initialAddressValues.id,
        });
      }
    }
  }, [isLoaded, initialAddressValues]);

  if (!isLoaded) {
    return (
      <AddressLoading>
        <DotsLoading
          color="primary"
          size="small"
          isLoading
          noMargin
          text={() => 'Loading Address Field'}
        />
      </AddressLoading>
    );
  }

  const errors = props.errors;
  const addressLookupError =
    !enterManually &&
    !isEmpty(errors) &&
    (props.errors.addressLine1 ||
      props.errors.city ||
      props.errors.state ||
      props.errors.country ||
      props.errors.postcode)
      ? 'Address is required'
      : '';

  return (
    <AddressContainer>
      <AddressHeader>
        <SwapAddress
          onClick={(e) => {
            e.preventDefault();
            enterManually ? handleLookupAddress() : handleEnterManually();
          }}
        >
          {enterManually ? 'Lookup Address' : 'Enter Manually'}
        </SwapAddress>
      </AddressHeader>

      <ManualAddress>
        <Controller
          control={props.control}
          name="addressLine1"
          render={(props) => (
            <Input
              {...props}
              label="Address Line 1"
              className={hideInputClassName}
              defaultValue={initialAddressValues?.addressLine1}
              errorText={errors.addressLine1}
            />
          )}
        />
        <Controller
          control={props.control}
          name="addressLine2"
          render={(props) => (
            <Input
              {...props}
              label="Address Line 2"
              className={hideInputClassName}
              defaultValue={initialAddressValues?.addressLine2}
            />
          )}
        />
        <Controller
          control={props.control}
          name="city"
          render={(props) => (
            <Input
              {...props}
              label="City"
              className={hideInputClassName}
              defaultValue={initialAddressValues?.city}
              errorText={errors.city}
            />
          )}
        />
        <Controller
          control={props.control}
          name="state"
          render={(props) => (
            <Input
              {...props}
              label="State"
              className={hideInputClassName}
              defaultValue={initialAddressValues?.state}
              errorText={errors.state}
            />
          )}
        />
        <Controller
          control={props.control}
          name="country"
          render={(props) => (
            <Input
              {...props}
              label="Country"
              className={hideInputClassName}
              defaultValue={initialAddressValues?.country}
              errorText={errors.country}
            />
          )}
        />
        <Controller
          control={props.control}
          name="postcode"
          render={(props) => (
            <Input
              {...props}
              label="Postcode"
              className={hideInputClassName}
              defaultValue={initialAddressValues?.postcode}
              errorText={errors.postcode}
            />
          )}
        />
      </ManualAddress>
      <AddressInput
        {...props}
        className={hideAddressLookupClassName}
        name="addressLookup"
        control={props.control}
        onEnterManually={() => {
          handleEnterManually();
        }}
        defaultValue={initialAddressValues?.addressLookup || ''}
        errorText={addressLookupError}
      />

      {/* Hidden Fields  */}
      <Controller
        control={props.control}
        name="addressId"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.id}
          />
        )}
      />
      <Controller
        control={props.control}
        name="location"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.location}
          />
        )}
      />
      <Controller
        control={props.control}
        name="unit"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.unit}
          />
        )}
      />
      <Controller
        control={props.control}
        name="streetNumber"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.streetNumber}
          />
        )}
      />
      <Controller
        control={props.control}
        name="road"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.road}
          />
        )}
      />
      <Controller
        control={props.control}
        name="shire"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.shire}
          />
        )}
      />
      <Controller
        control={props.control}
        name="countryShort"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.countryShort}
          />
        )}
      />
      <Controller
        control={props.control}
        name="lat"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.lat}
          />
        )}
      />
      <Controller
        control={props.control}
        name="lng"
        render={(props) => (
          <Input
            {...props}
            className="hiddenInput"
            defaultValue={initialAddressValues?.lng}
          />
        )}
      />
    </AddressContainer>
  );
};

export default Address;
