import styled from 'styled-components/macro';
import { H2, ListGroup } from '../../../../shared/typefaces/Typefaces.styles';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../../../styles/device';
import { Input } from '../../../../shared/formElements';
import { IStyledInputProps } from './profitProjection.types';

export const Container = styled.section`
  width: 100%;
`;

export const Column = styled.div`
  &:first-child {
    .profitHeaderSpan {
      white-space: nowrap;
    }
    .serve {
      padding-top: 28px;
    }
  }
  &:last-child {
    &.cardHeaderItemRight {
      display: grid;
      grid-template-columns: auto;
    }
    .inlineHeaderTitle,
    .profitHeaderSpan {
      text-align: right;
    }
    .serves {
      padding-bottom: 4px;
      padding-left: 30px;

      @media ${device.laptopL} {
        padding-bottom: 0;
        padding-left: 0;
      }
    }
    .weeklySales {
      padding-top: 28px;
    }
  }
`;

export const Heading = styled(H2)`
  display: flex;
  align-items: flex-start;
`;

export const Header = styled(LayoutColumnContainer)`
  padding-bottom: 0;
  grid-gap: 0;
`;

export const HeadingColumns = styled(LayoutColumnContainer)`
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-gap: 15px;
  padding: 30px;

  @media ${device.mobileM} {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
  }

  @media ${device.laptopL} {
    padding: 30px 15px;
  }
`;

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-gap: 30px;

  @media ${device.mobileM} {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    padding-top: 15px;
    grid-gap: 15px;
  }
  @media ${device.mobileL} {
    grid-gap: 30px;
  }
`;

export const ListEnd = styled(ListGroup)`
  padding-bottom: 0 !important;
`;

export const ColumnRight = styled.div`
  display: grid;
  grid-template-columns: auto 70px;
  /* align-items: baseline; */

  &.rightHeading {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

export const SubHeading = styled.section`
  line-height: 24px;
`;

export const StyledInput = styled(Input)<IStyledInputProps>`
  width: ${({ $small }) => ($small ? '65px' : '120px')};
  .MuiTextField-root {
  }

  input {
    color: ${({ theme }) => theme.colors.text.black} !important;
    font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
    font-size: ${({ theme }) => theme.fontSize.heading} !important;
    font-family: ${({ theme }) => theme.fontFamily} !important;
    padding-right: 0;
    padding-left: ${({ $noPadding }) => ($noPadding ? 0 : 'inherit')};

    &::placeholder {
      font-weight: ${({ theme }) => theme.fontWeight.regular} !important;
    }
  }
`;

export const StyledRightInput = styled(Input)`
  margin-left: 10px;
  width: 65px;
  .MuiTextField-root {
  }

  input {
    color: ${({ theme }) => theme.colors.text.black} !important;
    font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
    font-size: ${({ theme }) => theme.fontSize.heading} !important;
    font-family: ${({ theme }) => theme.fontFamily} !important;
    padding-left: 0;
    padding-right: 0;
    text-align: right;

    &::placeholder {
      font-weight: ${({ theme }) => theme.fontWeight.regular} !important;
    }
  }
`;

export const Dollar = styled.section`
  height: 48px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.black} !important;
  font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
  font-size: ${({ theme }) => theme.fontSize.heading} !important;
  font-family: ${({ theme }) => theme.fontFamily} !important;
`;
