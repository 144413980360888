import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Valid: React.FC<IIconProps> = ({
  color,
  size,
  faded,
  className,
  rotate,
}) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
      className={className}
      rotate={rotate}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M448 4347 c-160 -55 -279 -183 -324 -348 -21 -80 -21 -2157 1 -2238
44 -167 171 -301 332 -351 63 -19 92 -20 884 -20 581 0 825 3 843 11 60 28 71
122 20 170 l-26 24 -826 5 -827 5 -55 31 c-30 17 -70 50 -87 73 -65 84 -63 58
-63 978 l0 833 2025 0 2025 0 0 -375 c0 -403 2 -425 51 -459 30 -21 88 -21
118 0 50 36 51 42 51 636 0 315 -5 583 -10 623 -28 191 -178 362 -364 411 -42
11 -364 14 -1876 14 l-1825 -1 -67 -22z m3732 -203 c39 -12 66 -29 105 -67 67
-68 85 -119 85 -249 l0 -98 -2025 0 -2025 0 0 88 c0 178 61 279 199 328 29 11
388 13 1822 14 1600 0 1791 -2 1839 -16z"
        />
        <path
          d="M593 2654 c-29 -15 -63 -65 -63 -94 0 -30 34 -80 65 -94 46 -22 458
-23 509 -2 43 18 66 51 66 96 0 45 -23 78 -66 96 -51 21 -467 20 -511 -2z"
        />
        <path
          d="M3635 2435 c-169 -31 -366 -116 -495 -214 -84 -63 -214 -199 -269
-280 -64 -94 -137 -253 -167 -366 -35 -132 -44 -355 -21 -490 29 -162 98 -334
190 -470 53 -78 185 -214 267 -276 81 -61 230 -138 333 -173 227 -75 507 -75
732 0 172 57 312 142 443 268 145 139 240 288 303 474 78 231 80 508 3 737
-35 105 -112 254 -173 335 -62 82 -198 214 -276 267 -134 90 -308 161 -463
188 -102 18 -310 18 -407 0z m368 -210 c372 -65 666 -337 769 -714 31 -110 31
-352 0 -462 -98 -357 -362 -617 -718 -706 -105 -26 -334 -24 -441 4 -241 64
-448 211 -577 410 -201 311 -206 711 -14 1023 206 335 597 512 981 445z"
        />
        <path
          d="M4215 1479 c-11 -6 -100 -91 -197 -187 l-177 -176 -133 131 c-95 92
-143 132 -165 137 -71 16 -143 -56 -127 -127 5 -24 52 -78 177 -204 93 -95
185 -179 204 -188 65 -31 79 -21 321 219 123 121 230 233 238 248 39 74 -6
158 -83 158 -21 0 -47 -6 -58 -11z"
        />
        <path
          d="M577 2110 c-38 -30 -52 -80 -33 -123 28 -69 22 -68 535 -65 l459 3
26 24 c51 48 40 142 -20 170 -17 8 -167 11 -483 11 -444 0 -459 -1 -484 -20z"
        />
      </g>
    </IconStyled>
  );
};

export default Valid;
