import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Apple: React.FC<IIconProps> = ({ color, size, faded, className }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 511.999 511.999"
      color={color}
      faded={faded}
      className={className}
    >
      <g>
        <g>
          <path d="M356.184,166.003c-5.52,0-10,4.48-10,10s4.48,10,10,10s10-4.48,10-10S361.704,166.003,356.184,166.003z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M397.662,191.085c-3.511-4.263-9.812-4.874-14.076-1.361c-4.263,3.511-4.873,9.813-1.361,14.076
			c14.77,17.933,23.588,45.325,23.588,73.271c0,5.522,4.478,10,10,10s10-4.478,10-10
			C425.813,244.632,415.29,212.487,397.662,191.085z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M434.842,173.957c-21.637-30.847-51.301-47.834-83.528-47.834c-30.974,0-52.242,24.966-65.129,32.915v-32.465
			c0-28.46,11.085-55.224,31.21-75.358c11.725-11.723,11.728-30.696,0.001-42.421c-11.721-11.723-30.696-11.727-42.422-0.001
			c-23.296,23.295-38.832,52.277-45.333,83.812C207.366,52.9,164.861,26.004,116.186,26.004h-20c-0.06,0-0.118,0.008-0.178,0.009
			c-5.546,0.096-9.826,4.664-9.822,9.996v19.995c0,30.386,10.326,59.111,29.32,82.255c-18.838,10.613-35.043,27.776-47.374,50.343
			c-14.152,25.897-21.946,57.317-21.946,88.47c0,44.688,16.179,103.119,41.218,148.862c30.38,55.501,69.09,86.065,109.001,86.065
			c7.342,0,30.524-8.69,37.967-13.619c10.689-7.068,20.107-4.683,22.753-4.985c7.136-0.813,14.413,0.959,20.489,4.982
			c7.447,4.932,30.636,13.622,37.981,13.622c39.911,0,78.621-30.565,109.001-86.065c25.039-45.743,41.218-104.175,41.218-148.862
			C465.813,239.6,454.525,202.015,434.842,173.957z M289.116,22.934c3.905-3.905,10.227-3.909,14.138,0.001
			c3.899,3.901,3.917,10.218-0.003,14.139c-23.902,23.915-37.066,55.699-37.066,89.499v39.378c-6.62,1.089-13.366,1.11-20,0.063
			c-0.001-11.902-0.001-27.526-0.001-39.441C246.185,87.425,261.431,50.618,289.116,22.934z M106.273,60.233l42.841,42.841
			c3.907,3.905,10.236,3.904,14.143,0c3.905-3.905,3.905-10.237,0-14.143l-42.839-42.839c58.7,2.233,105.767,50.674,105.767,109.91
			v3.273c-18.624-11.127-41.121-44.433-91.382-29.268C117.252,110.764,107.251,86.325,106.273,60.233z M407.05,416.332
			c-15.439,28.207-47.798,75.488-91.21,75.667c-4.299-0.549-21.95-6.829-27.186-10.297c-9.672-6.404-21.232-9.33-32.662-8.294
			c-11.43-1.029-22.979,1.891-32.661,8.294c-5.236,3.468-22.881,9.748-27.176,10.297c-43.413-0.179-75.77-47.462-91.21-75.667
			c-23.547-43.018-38.762-97.68-38.762-139.26c0.002-57.719,29.181-110.803,69.382-126.222c0.001,0,0.001-0.001,0.002-0.001
			c8.082-3.102,16.543-4.691,25.149-4.726c0.053,0,0.104,0,0.156,0c24.405,0,42.18,23.992,57.661,31.761
			c23.567,11.81,51.141,11.949,74.922-0.002c15.462-7.752,33.354-31.759,57.857-31.759c25.521,0,49.371,13.964,67.154,39.318
			c17.378,24.774,27.345,58.173,27.345,91.631C445.812,318.651,430.597,373.314,407.05,416.332z"
          />
        </g>
      </g>
    </IconStyled>
  );
};

export default Apple;
