import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';

export const Container = styled.div``;

export const Form = styled.form`
  padding: 15px;
  padding-top: 60px;
  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: 1fr;

    @media ${device.tablet} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const Dollar = styled.section`
  height: 48px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.grey} !important;
  font-weight: ${({ theme }) => theme.fontWeight.medium} !important;
  font-size: ${({ theme }) => theme.fontSize.default} !important;
  font-family: ${({ theme }) => theme.fontFamily} !important;
  padding-left: 5px;
`;
