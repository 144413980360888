import styled from 'styled-components/macro';
import { device } from '../../../styles/device';

export interface IIconProps {
  currentStage?: boolean;
}

interface IStageProps {
  disabled?: boolean;
  onClick?: () => void;
}

interface ISwapProps {
  swap: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 350px;
  margin: 30px auto;
  position: relative;

  .text {
    text-align: center;
    color: ${({ theme }) => theme.colors.white.default};
  }

  &:before {
    content: '';
    display: block;
    border-bottom: 2px solid ${({ theme }) => theme.colors.white.default};
    position: absolute;
    top: 30px;
    width: 220px;
    left: 50%;
    margin-left: -120px;
  }

  @media ${device.mobileL} {
    width: 460px;

    &:before {
      margin-left: -150px;
      width: 300px;
    }
  }
`;

export const Swap = styled.div<ISwapProps>`
  display: flex;
  flex-direction: ${({ swap }) => (swap ? 'row-reverse' : 'row')};
  width: 285px;
  justify-content: space-between;
`;

export const Stage = styled.div<IStageProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${({ onClick, disabled }) =>
    onClick && !disabled ? 'pointer' : 'default'};
`;

export const Icon = styled.div<IIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border: 2px solid ${({ theme }) => theme.colors.white.default};
  border-radius: 30px;
  background-color: ${({ theme, currentStage }) =>
    currentStage
      ? theme.colors.secondary.default
      : theme.colors.primary.default};
  z-index: ${({ theme }) => theme.zIndex.above};
  margin-bottom: 15px;
`;
