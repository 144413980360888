import React, { memo, useState, useEffect, useRef, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import SaladMix from '../../../../shared/icons/components/SaladMix';
import {
  H3,
  ListGroup,
  ListGroupRow,
} from '../../../../shared/typefaces/Typefaces.styles';
import {
  Container,
  SectionNameButton,
  ButtonContainer,
  RecipeError,
  ErrorContainer,
  StyledInput,
  HeaderInputs,
  Section,
  InputWrapper,
  TotalText,
} from './Header.styles';
import { capitalizeFirstLetterPerWord } from '../../../../../utils/helper';
import { IHeaderProps } from './header.types';
import { StocktakeSectionFormState } from '../../../../shared/stocktake/stocktake.types';

const Header: FC<IHeaderProps> = ({ showUniqueError, onSubmit }) => {
  const {
    register,
    errors,
    formState,
    watch,
    getValues,
    handleSubmit,
  } = useFormContext();
  const [hideButton, setHideButton] = useState(false);
  const displayName = watch('displayName');
  const displayNameRef = useRef<HTMLInputElement | null>();

  const { dirtyFields } = formState;

  useEffect(() => {
    if ((hideButton || !!errors.displayName) && displayNameRef.current) {
      displayNameRef!.current.focus();
    }
    if (hideButton && displayNameRef.current) {
      displayNameRef!.current.focus();
    }
  }, [displayNameRef, hideButton, errors]);

  const showNameInput = () => {
    setHideButton(true);
  };

  const getRemainingItems = () => {
    const formValues = getValues() as StocktakeSectionFormState;

    let totalItems = 0;

    const remainingItems = formValues.items?.filter((i) => {
      if (i.quantity && i.unit) {
        totalItems = totalItems + Number(i.quantity) * Number(i.unit);
      }
      if (
        i.ingredient?.value &&
        i.quantity &&
        i.required &&
        i.unitType &&
        i.unit
      ) {
        return false;
      }
      return true;
    });

    return {
      remainingItems: remainingItems?.length || 0,
      allComplete: formValues.items?.length > 0 && remainingItems?.length === 0,
      totalItems,
    };
  };

  const { remainingItems, allComplete, totalItems } = getRemainingItems();
  const remainingItemsMessage =
    remainingItems === 0
      ? allComplete
        ? 'Congratulations All Complete!'
        : 'Add Items To Section'
      : remainingItems === 1
      ? 'Show last remaining item'
      : `Show last ${remainingItems} remaining items`;

  const uniqueError = showUniqueError
    ? `You have already created a ${capitalizeFirstLetterPerWord(
        displayName
      )} Section. Please create a unique Section Name`
    : undefined;

  return (
    <Container>
      <Section>
        <InputWrapper
          showInput={
            !!displayName ||
            hideButton ||
            dirtyFields['displayName'] ||
            !!errors.displayName
          }
        >
          <StyledInput
            ref={(e: HTMLInputElement) => {
              register(e);
              displayNameRef.current = e; // you can still assign to your own ref
            }}
            label="Section Name"
            name="displayName"
            errorText={errors.displayName?.message || uniqueError}
            border="noBorder"
            placeholder="Add Section Name"
          />
        </InputWrapper>

        <ButtonContainer
          hideButton={
            !!displayName ||
            hideButton ||
            dirtyFields['displayName'] ||
            !!errors.displayName
          }
        >
          <SectionNameButton color="default" inversed onClick={showNameInput}>
            <SaladMix size="large" />
            <H3 color="black" fontWeight="regular">
              Add Section Name +
            </H3>
          </SectionNameButton>
        </ButtonContainer>
        {errors?.items?.message && (
          <ErrorContainer>
            <RecipeError>{errors.items.message}</RecipeError>
          </ErrorContainer>
        )}
      </Section>
      <HeaderInputs>
        <ListGroup noBorder noPadding>
          <ListGroupRow
            colOneWidth="auto"
            onClick={handleSubmit(onSubmit)}
            className="clickable"
          >
            <TotalText color={allComplete ? 'secondary' : 'grey'}>
              {remainingItemsMessage}
            </TotalText>
          </ListGroupRow>
          <ListGroupRow colOneWidth="auto">
            <TotalText color="grey">{totalItems} Total Items</TotalText>
          </ListGroupRow>
        </ListGroup>
      </HeaderInputs>
    </Container>
  );
};

export default memo(Header);
