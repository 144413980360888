import React, { FC } from 'react';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Container, Heading, SubHeading } from './Header.styles';
import { currency, isEmpty } from '../../../../../utils/helper';
import { constants } from '../../../../../utils/constants';
import { IHeaderProps } from './header.types';

const Header: FC<IHeaderProps> = ({ ingredient }) => {
  if (ingredient?.loading) {
    return null;
  }

  const ingredientData = ingredient?.data?.ingredient.ingredient;

  return (
    <Container>
      <Heading>{ingredientData?.displayName}</Heading>
      {!isEmpty(ingredientData?.averageCost100g) && (
        <SubHeading color="faded" fontWeight="semibold" fontSize="default">
          Avg Market Price:
          <br />
          <Span color="faded" fontSize="small">
            {`$${currency(ingredientData!.averageCost100g)} per 100${
              ingredientData?.liquid
                ? constants.metric.ml.s
                : constants.metric.gram.s
            }
            `}
          </Span>
        </SubHeading>
      )}
    </Container>
  );
};

export default Header;
