import React, { forwardRef } from 'react';
import { Container, StyledInput } from './Radio.styles';
import { IInputProps } from './radio.types';

const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    { className, type, errorText, helperText, touched, onChange, ...props },
    ref
  ) => {
    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const inputValue = !value ? '' : Number(value);
      onChange(inputValue);
    };

    return (
      <Container className={className}>
        <StyledInput
          {...props}
          type="radio"
          ref={ref}
          // onKeyDown={(e) => type === 'number' && checkNumberInput(e)}
          onChange={handleNumberChange}
        />
        <div>
          <span>{!!errorText ? errorText : helperText}</span>
        </div>
      </Container>
    );
  }
);

Input.defaultProps = {
  height: 'default',
  type: 'text',
  align: 'left',
};

export default Input;
