import styled from 'styled-components/macro';

export interface IVenueItemProps {
  selected: boolean;
}
export interface IContainerProps {
  venueCount?: number;
}

export const Container = styled.div<IContainerProps>`
  position: fixed;
  top: 15px;
  left: 10px;
  width: 350px;
  background-color: ${({ theme }) => theme.colors.white.default};
  z-index: ${({ theme }) => theme.zIndex.top};
  z-index: 20;
  box-shadow: ${({ theme }) => theme.shadow.box};
  border-radius: ${({ theme }) => theme.layout.radiusSmall};
  padding: 10px 15px 15px;

  .venuesHeading {
    display: block;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSize.button};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.black};
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .user {
    display: block;
    font-size: ${({ theme }) => theme.fontSize.small};
    margin-bottom: 15px;

    .highlight {
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
      font-size: ${({ theme }) => theme.fontSize.small};
    }
  }

  .itemIcon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  .logo {
    height: 30px;
    width: 30px;
    margin-right: 15px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .text {
    font-size: ${({ theme }) => theme.fontSize.default};
    color: ${({ theme }) => theme.colors.text.faded};
  }

  .cross {
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center;
    transform: rotate(45deg);
    font-size: ${({ theme }) => theme.fontSize.heading};
    color: ${({ theme }) => theme.colors.text.faded};
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: ${({ theme }) => theme.layout.radiusLarge};

    &:hover {
      background-color: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.text.grey};
    }
  }
`;

export const Venues = styled.section`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  padding-bottom: 5px !important;
  margin-bottom: 15px !important;
`;
export const VenuesList = styled.ul`
  overflow-y: scroll;
  max-height: 75vh;
`;

export const VenueItem = styled.li<IVenueItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 2px !important;
  border-radius: ${({ theme }) => theme.layout.radiusSmall};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.background : 'transparent'};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
  }

  &.addVenue {
    justify-content: flex-start;

    .text {
      color: ${({ theme }) => theme.colors.secondary.default};
      text-shadow: ${({ theme }) => theme.shadow.text};
      text-decoration: underline;
    }
  }

  .text {
    font-size: ${({ theme }) => theme.fontSize.default};
  }
`;

export const SubMenu = styled.ul``;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 2px !important;
  border-radius: ${({ theme }) => theme.layout.radiusSmall};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    .text {
      color: ${({ theme }) => theme.colors.grey.default};
    }
  }

  .text {
    color: ${({ theme }) => theme.colors.grey.faded};
    text-shadow: ${({ theme }) => theme.shadow.text};
    font-size: ${({ theme }) => theme.fontSize.default};
  }
`;
