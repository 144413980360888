import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { AccountType } from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import { IRecipeDataResult } from '../../../../../utils/clientServerShared/getRecipeData.types';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';
import {
  checkIfAccountIsNotComplete,
  useRestrictionCheck,
} from '../../../../../utils/helper/account';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';
import { Button } from '../../../../shared/button';
import { Card, Header } from '../../../../shared/card';
import { ScaleIcon, Share } from '../../../../shared/icons';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';

export const Scale: FC<{
  recipeData: IRecipeDataResult;
  updateOriginalRecipeFromTemplate: () => void;
}> = ({ recipeData: { serves }, updateOriginalRecipeFromTemplate }) => {
  const { appWidth, account, user, dispatch } = useContext(LayoutContext);
  const checkScaleRecipeRestrictions = useRestrictionCheck(['SCALE_RECIPES']);
  const showRestrictionPopup = useRestrictionPopup();

  const handleScale = () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountScaleRecipe,
      });
    }
    const restrictionCheck = checkScaleRecipeRestrictions({
      data: account?.type === AccountType.Registered,
    });

    if (restrictionCheck.isPass) {
      updateOriginalRecipeFromTemplate();
      dispatch({ type: 'SLIDER_PAGE', payload: 'editScaleRecipe' });
      dispatch({ type: 'SCALE_RECIPE', payload: true });
    } else {
      showRestrictionPopup(
        restrictionPopupMessages.cannotScaleRecipeOnRecipeScreenDueToMaxScaledVersions
      );
    }
  };

  return (
    <Card withCardLink>
      <Header
        icon={<ScaleIcon size="small" />}
        heading="Scale Recipe"
        subHeading={`Scale your recipe from the current ‘${serves}’ serves to larger serving amounts, to quickly understand recipe ingredient & time requirements`}
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={handleScale}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button color="default" inversed onClick={handleScale}>
              Scale Recipe
            </Button>
          )
        }
        toolTip={{
          type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
          heading: 'Scale Recipe',
          content:
            'Let us scale every recipe version to quickly know if scaling your recipe batch serves will increase profit, while ensuring accurate flavour balance',
        }}
      />
    </Card>
  );
};

export default Scale;
