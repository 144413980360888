import styled from 'styled-components/macro';
import { INavbarHeadingsProps } from './navbarHeadings.types';
import { device } from '../../../../styles/device';

export const NavbarContainerLeft = styled.div<INavbarHeadingsProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme, backButton }) =>
    backButton ? '0 15px 0 0' : theme.layout.pagePadding};
  width: 100%;
`;

export const HeadingsContainer = styled.div<INavbarHeadingsProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const HeadingStyled = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin: 0;
  text-align: center;
  width: 100%;

  @media ${device.mobileL} {
    text-align: left;
  }
`;

export const SubheadingStyled = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.colors.grey.faded};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 13px;
  text-align: center;
  width: 100%;

  @media ${device.mobileL} {
    text-align: left;
  }
`;

export const Icon = styled.section`
  display: flex;
  align-self: center;
  cursor: pointer;
  padding: 15px;

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;
