import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { LayoutContext } from '../../../contexts/layoutContext';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountType,
  RecipeDocument,
  RecipesDocument,
  useCreateRecipeMutation,
  useDeleteRecipeVersionMutation,
  useIngredientsQuery,
  useMeQuery,
  useRecipesQuery,
  useUpdateRecipeMutation,
  useVenueQuery,
} from '../../../generated/graphql';
import { theme } from '../../../styles/theme';
import {
  generateDuplicateRecipeName,
  getInitialRecipeFormItems,
  isEmpty,
} from '../../../utils/helper';

import {
  makeCreateRecipeInput,
  makeUpdateRecipeInput,
} from '../../../utils/helper/formatRecipeFormData';
import { Button } from '../../shared/button';
import Content from '../../shared/card/content';
import { LayoutPage } from '../../shared/layout';
import { DotsLoading } from '../loading';
import { CalculateButton, RecipeCard } from './Recipe.styles';
import {
  IRecipeFormProps,
  RecipeFormState,
  RecipeVersionFromQuery,
  RECIPE_FORM_SCHEMA,
  RecipeFormItem,
} from './recipe.types';
import { Duplicate, Header, Items, Scale } from './sections';
import { overlayConstants } from '../layout/layoutOverlay/constants';
import { getRecipeSummaryStats } from '../../../utils/helper/getRecipeSummaryStats';
import useScaleRecipe from './hooks/useScaleRecipe';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';
import { useRecipeActiveTimeTracker } from '../../../utils/customHooks/useRecipeActiveTimeTracker';

type HandleRecipeChangeFunc = (
  selectedVersionId: string,
  isDuplicating: boolean
) => void;

interface IState {
  changedNum: boolean;
  initialLoad: boolean;
  calculateRecipe: boolean;
  showUniqueError: boolean;
}

const Recipe: FC<IRecipeFormProps> = ({
  recipe,
  recipeVersion,
  formOnly,
  calculateRecipe,
}) => {
  const history = useHistory();
  const location = useLocation();
  const user = useMeQuery();

  const {
    selectedRecipe,
    selectedSubRecipe,
    selectedVenueObject,
    selectedRecipeVersion,
    duplicateRecipe,
    scaleRecipe,
    account,
    appWidth,
    dispatch,
  } = useContext(LayoutContext);

  const [UpdateRecipeMutation, updateRecipe] = useUpdateRecipeMutation();
  const [CreateRecipeMutation, createRecipe] = useCreateRecipeMutation();
  const [
    DeleteRecipeVersionMutation,
    deleteRecipe,
  ] = useDeleteRecipeVersionMutation();

  const { data: venueQueryResult } = useVenueQuery({
    variables: {
      input: { venueId: selectedVenueObject?.id! },
    },
    fetchPolicy: 'cache-and-network',
  });
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const { getTotalRecipeActiveSeconds } = useRecipeActiveTimeTracker();

  const ingredients = useIngredientsQuery();
  const recipes = useRecipesQuery({
    variables: {
      input: { venueId: selectedVenueObject?.id || '' },
    },
  });

  const [state, setState] = useState<IState>({
    changedNum: false,
    initialLoad: true,
    calculateRecipe: true,
    showUniqueError: false,
  });

  const initialItems = getInitialRecipeFormItems(
    recipeVersion,
    Number(recipeVersion?.serves)
  );

  const methods = useForm<RecipeFormState, {}>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(RECIPE_FORM_SCHEMA),
    defaultValues: {
      displayName: recipeVersion?.displayName ?? '',
      serves: recipeVersion?.serves ?? '',
      recipeWastage: recipeVersion?.recipeWastage ?? '',
      items: initialItems,
    },
  });

  // Scale Recipe
  useScaleRecipe(methods, recipeVersion);

  const handleRecipeChange: HandleRecipeChangeFunc = useCallback(
    (selectedVersionId, isDuplicating) => {
      const recipeVersions = recipe?.recipeVersions || [];

      const selectedVersion = recipeVersions.find(
        (version) => version.id === selectedVersionId
      );
      const otherVersions = recipeVersions.filter(
        (version) => version.id !== selectedVersionId
      );

      const otherVersionNames = new Set(
        otherVersions.map((version) => version.displayName)
      );

      const displayName = isDuplicating
        ? generateDuplicateRecipeName(
            selectedVersion?.displayName || '',
            otherVersionNames
          )
        : selectedVersion?.displayName || '';

      const itemsArray = getInitialRecipeFormItems(
        selectedVersion,
        Number(selectedVersion?.serves)
      );

      methods.reset({
        displayName,
        serves: selectedVersion?.serves || '',
        recipeWastage: selectedVersion?.recipeWastage || '',
        items: itemsArray || [],
      });
    },
    [recipe?.recipeVersions, methods.reset]
  );

  const handleScaleRecipe = (selectedVersionId: string) => {
    handleRecipeChange(selectedVersionId, false);
  };

  const handleDuplicateRecipe = useCallback(
    (selectedVersionId: string) => {
      handleRecipeChange(selectedVersionId, true);
    },
    [handleRecipeChange]
  );

  const handleDeleteRecipeVersion = async () => {
    try {
      const response = await DeleteRecipeVersionMutation({
        variables: {
          input: {
            recipeId: selectedRecipe!,
            recipeVersionId: selectedRecipeVersion!,
            venueId: selectedVenueObject?.id!,
          },
        },
        refetchQueries: [
          {
            query: RecipeDocument,
            variables: {
              input: {
                venueId: selectedVenueObject?.id!,
                recipeId: selectedRecipe!,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
        fetchPolicy: 'no-cache',
      });

      if (response.data?.deleteRecipeVersion.successful) {
        if (response.data?.deleteRecipeVersion?.selectedRecipeVersion) {
          dispatch({
            type: 'SELECT_RECIPE_VERSION',
            payload: response.data?.deleteRecipeVersion?.selectedRecipeVersion,
          });
          dispatch({ type: 'TOGGLE_SLIDER' });
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = async (formData: RecipeFormState) => {
    const venue = venueQueryResult?.venue?.userVenue?.venue || undefined;
    if (createRecipe.loading || updateRecipe.loading || !venue) {
      return;
    }

    if (
      user?.data?.me?.user?.accountType === AccountType.Guest ||
      account?.type === AccountType.Guest
    ) {
      if (location.pathname === '/') {
        history.push('recipe-results');
        dispatch({
          type: 'SET_OVERLAY',
          payload: {
            ...overlayConstants.addSalesDataRecipeResults,
            subHeading: `To calculate recipe revenue for ${formData.displayName}`,
            link: account?.type === AccountType.Guest ? '/' : '/recipes',
          },
        });
      } else {
        dispatch({
          type: 'SET_OVERLAY',
          payload: overlayConstants.noAccountSaveRecipe,
        });
      }
      return;
    }

    const fields: RecipeFormItem[] = methods.watch('items');

    const summaryStats = getRecipeSummaryStats(
      fields,
      ingredients.data?.ingredients.ingredients || undefined,
      recipes.data?.venueRecipes.recipes || undefined
    );

    if (recipe && recipeVersion) {
      const { input, recipeData } = makeUpdateRecipeInput({
        recipe,
        recipeVersion,
        formData,
        venue,
        duplicateRecipe,
        scaleRecipe,
        totalActiveSeconds: getTotalActiveSeconds(),
        recipeActiveSeconds: getTotalRecipeActiveSeconds(),
      });

      if (!recipeData) {
        return;
      }

      recipeData.totalGrams = summaryStats.totalGrams;

      try {
        const response = await UpdateRecipeMutation({
          variables: {
            input: input,
          },
          refetchQueries: [
            {
              query: RecipeDocument,
              variables: {
                input: {
                  venueId: selectedVenueObject?.id,
                  recipeId: selectedRecipe!,
                },
              },
            },
            {
              query: RecipesDocument,
              variables: {
                input: {
                  venueId: selectedVenueObject?.id,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        let selectedVersion: RecipeVersionFromQuery | undefined;
        if (response.data?.updateRecipe.successful) {
          if (duplicateRecipe || scaleRecipe) {
            selectedVersion = response.data?.updateRecipe?.recipe?.recipeVersions.find(
              (version) => version.selected
            );

            if (selectedVersion) {
              dispatch({ type: 'DUPLICATE_RECIPE', payload: false });
              dispatch({ type: 'SCALE_RECIPE', payload: false });
              dispatch({
                type: 'SELECT_RECIPE_VERSION',
                payload: selectedVersion.id,
              });
            }

            if (selectedSubRecipe) {
              dispatch({ type: 'SELECT_SUB_RECIPE', payload: '' });
            }
          }
          dispatch({ type: 'TOGGLE_SLIDER' });
        }
      } catch (err) {
        console.log('err ###', err);
      }
    } else {
      try {
        const { input, recipeData } = makeCreateRecipeInput({
          formData,
          totalActiveSeconds: getTotalActiveSeconds(),
          recipeActiveSeconds: getTotalRecipeActiveSeconds(),
          venue,
        });

        if (!recipeData) {
          return;
        }

        recipeData.totalGrams = summaryStats.totalGrams;

        const response = await CreateRecipeMutation({
          variables: {
            input: input,
          },
          refetchQueries: [
            {
              query: RecipeDocument,
              variables: {
                input: {
                  venueId: selectedVenueObject?.id!,
                  recipeId: selectedRecipe!,
                },
              },
            },
            {
              query: RecipesDocument,
              variables: {
                input: {
                  venueId: selectedVenueObject?.id!,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        if (response.data?.createRecipe.successful) {
          const { recipe } = response.data?.createRecipe;
          dispatch({
            type: 'SET_NEW_RECIPE',
            payload: true,
          });
          dispatch({
            type: 'SELECT_RECIPE',
            payload: recipe?.id,
          });

          if (response.data?.createRecipe.successful) {
            const { recipe } = response.data?.createRecipe;
            dispatch({
              type: 'SET_NEW_RECIPE',
              payload: true,
            });
            dispatch({
              type: 'SELECT_RECIPE',
              payload: recipe?.id,
            });

            history.push('recipe-results');
          }
        } else {
          if (response.data?.createRecipe.error?.includes('Already Exists')) {
            setState({ ...state, showUniqueError: true });
          }
        }
      } catch (err) {
        console.log('err ###', err);
      }
    }
  };

  useEffect(() => {
    if (calculateRecipe && state.calculateRecipe) {
      methods.handleSubmit(onSubmit)();
      setState({ ...state, calculateRecipe: false });
    }
    if (!calculateRecipe && !state.calculateRecipe) {
      setState({ ...state, calculateRecipe: true });
    }
  }, [calculateRecipe, state, methods, onSubmit]);

  // Set initial name of duplicated version
  useEffect(() => {
    if (duplicateRecipe && recipeVersion) {
      handleDuplicateRecipe(recipeVersion.id);
    }
  }, [duplicateRecipe, recipeVersion, handleDuplicateRecipe]);

  useEffect(() => {
    setState((s) => ({ ...s, showUniqueError: false }));
  }, [methods.getValues().displayName]);

  const handleCancel = async () => {
    if (!isEmpty(selectedRecipe)) {
      dispatch({ type: 'TOGGLE_SLIDER' });
    } else {
      history.push('recipes');
    }
    if (selectedSubRecipe) {
      dispatch({ type: 'SELECT_SUB_RECIPE', payload: '' });
    }
    dispatch({ type: 'DUPLICATE_RECIPE', payload: false });
    dispatch({ type: 'SCALE_RECIPE', payload: false });
  };

  const isLoading =
    createRecipe.loading || updateRecipe.loading || deleteRecipe.loading;

  const recipeForm = (
    <FormProvider {...methods}>
      {!checkIfAccountIsNotComplete(
        user.data?.me?.user?.email,
        account?.type
      ) && (
        <Prompt
          when={true}
          message="You have unsaved recipe changes, are you sure you want to leave?"
        />
      )}
      <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        {duplicateRecipe && (
          <Duplicate handleDuplicateRecipe={handleDuplicateRecipe} />
        )}
        {scaleRecipe && <Scale handleScaleRecipe={handleScaleRecipe} />}
        <RecipeCard withShadow>
          <Content fullWidth>
            {!scaleRecipe && <Header showUniqueError={state.showUniqueError} />}
            <Items
              ingredients={ingredients}
              recipes={recipes}
              disableIngredient={scaleRecipe}
              roundCorners={scaleRecipe}
              original={recipeVersion?.original}
              initialItems={initialItems}
              handleDeleteRecipeVersion={handleDeleteRecipeVersion}
            />
          </Content>
          <CalculateButton
            size="large"
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            color="secondary"
            disabled={isLoading}
          >
            <DotsLoading
              text={(loading) =>
                `${loading ? 'Calculating' : 'Calculate'} Recipe Revenue`
              }
              isLoading={isLoading}
              size="small"
              lineHeight={10}
              noMargin
            />
          </CalculateButton>
        </RecipeCard>
      </form>
    </FormProvider>
  );

  if (formOnly) {
    return recipeForm;
  }

  return (
    <LayoutPage
      backButton={selectedRecipe ? true : false}
      backButtonCTA={handleCancel}
      align="center"
      withLine
      heading={
        duplicateRecipe
          ? 'Duplicate Recipe'
          : scaleRecipe
          ? 'Scale Recipe'
          : !isEmpty(selectedRecipe)
          ? 'Edit Recipe'
          : 'Add Recipe'
      }
      subHeading={
        duplicateRecipe
          ? 'To create a new recipe from the selected version'
          : scaleRecipe
          ? 'To calculate ingredient quantity, based on the original recipe version'
          : !isEmpty(selectedRecipe)
          ? 'Refine recipe to optimise profitability'
          : 'Add core recipe components to refine recipe revenue later'
      }
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={handleCancel}>
                x
              </Button>,
              <Button
                small
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) => (loading ? 'Saving' : 'Save')}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
          : [
              <Button color="default" inversed onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                className="Thebutton"
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) => `${loading ? 'Saving' : 'Save'} Recipe`}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      {recipeForm}
    </LayoutPage>
  );
};

export default Recipe;
