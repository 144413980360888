import * as Yup from 'yup';
import {
  validateNumberValues,
  validatePositiveNumberValues,
} from '../../../utils/helper/validation';

const STOCKTAKE_SECTION_ITEM_FORM_SCHEMA = Yup.object({
  type: Yup.mixed().oneOf(['INGREDIENT', 'DELETE_ITEM']),

  __typename: Yup.mixed().oneOf([
    'StocktakeSectionIngredient',
    'StocktakeSectionItemDeleted',
  ]),

  quantity: Yup.string().when('type', {
    is: 'DELETE_ITEM',
    then: (s: Yup.StringSchema) => s.notRequired(),
    otherwise: (s: Yup.StringSchema) =>
      s
        .required('Quantity is required')
        .typeError('Quantity is required')
        .test('numberTest', 'Quantity is not a number', (value) =>
          validateNumberValues(value)
        )
        .test(
          'positiveNumberTest',
          'Number must be greater than zero',
          (value) => validatePositiveNumberValues(value)
        ),
  }),

  unit: Yup.string().when('type', {
    is: 'DELETE_ITEM',
    then: (s: Yup.StringSchema) => s.notRequired(),
    otherwise: (s: Yup.StringSchema) =>
      s
        .required('Unit Amount is required')
        .typeError('Unit Amount is required')
        .test('numberTest', 'Unit Amount is not a number', (value) =>
          validateNumberValues(value)
        )
        .test(
          'positiveNumberTest',
          'Number must be greater than zero',
          (value) => validatePositiveNumberValues(value)
        ),
  }),

  required: Yup.string().when('type', {
    is: 'DELETE_ITEM',
    then: (s: Yup.StringSchema) => s.notRequired(),
    otherwise: (s: Yup.StringSchema) =>
      s
        .required('Required is required')
        .typeError('Required is required')
        .test('numberTest', 'Required is not a number', (value) =>
          validateNumberValues(value)
        )
        .test(
          'positiveNumberTest',
          'Number must be greater than zero',
          (value) => validatePositiveNumberValues(value)
        ),
  }),

  unitType: Yup.string().when('type', {
    is: 'DELETE_ITEM',
    then: (s: Yup.StringSchema) => s.notRequired(),
    otherwise: (s: Yup.StringSchema) => s.required('Is required'),
  }),
});

export const STOCKTAKE_SECTION_FORM_SCHEMA = Yup.object().shape({
  displayName: Yup.string().required('Section Name is required'),

  items: Yup.array()
    .of(STOCKTAKE_SECTION_ITEM_FORM_SCHEMA)
    .required(
      'Stocktake Section must have at least one item, try adding an ingredient to this section'
    )
    .min(
      1,
      'Stocktake Section must have at least one item, try adding an ingredient to this section'
    ),
});
