import React, { FC, useState } from 'react';
import {
  RecipeCategory,
  useGetRecipeCategoryRecipesQuery,
} from '../../../generated/graphql';
import { Header, Selector } from './sections';
import { Container } from './TemplateSelector.styles';

interface ITemplateSelectorProps {
  showHeading?: boolean;
  preMadeRecipeRef?: React.MutableRefObject<HTMLDivElement | null>;
  addMarginTop?: boolean;
}

export const TemplateSelector: FC<ITemplateSelectorProps> = ({
  preMadeRecipeRef,
  showHeading,
  addMarginTop,
}) => {
  const [selectedRecipeCategory, setSelectedRecipeCategory] = useState<
    RecipeCategory
  >(RecipeCategory.Cafe);

  const { data: recipes, loading } = useGetRecipeCategoryRecipesQuery({
    variables: {
      input: {
        category: selectedRecipeCategory,
      },
    },
  });

  const changeRecipeCategory = (category: RecipeCategory) => {
    setSelectedRecipeCategory(category);
  };

  return (
    <Container
      id="pre-made-recipes"
      ref={preMadeRecipeRef && preMadeRecipeRef}
      addMarginTop={addMarginTop}
    >
      <Header
        selectedRecipeCategory={selectedRecipeCategory}
        showHeading={showHeading}
        changeRecipeCategory={changeRecipeCategory}
      />
      <Selector isLoading={loading} recipesData={recipes} />
    </Container>
  );
};
