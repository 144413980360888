import { MetricType } from '../../generated/graphql';

export const getUnitTotal = (
  unit: string,
  quantity: number,
  volumeConversionRates?: Record<
    MetricType, // metric name
    number // number of grams per unit of this metric
  >
) => {
  switch (unit.toUpperCase()) {
    case MetricType.Cup:
      if (!volumeConversionRates?.CUP) return 0;
      return volumeConversionRates.CUP * quantity;
    case 'ML':
      return quantity;
    case 'GRAM':
      return quantity;
    case 'SERVES':
      return quantity;
    case MetricType.Tablespoon:
      if (!volumeConversionRates?.CUP) return 0;
      return (volumeConversionRates.CUP / 4) * quantity;
    case MetricType.Teaspoon:
      if (!volumeConversionRates?.CUP) return 0;
      return (volumeConversionRates.CUP / 16) * quantity;
    case MetricType.Whole:
      if (!volumeConversionRates?.WHOLE) return 0;
      return volumeConversionRates.WHOLE * quantity;
    case MetricType.Slice:
      if (!volumeConversionRates?.SLICE) return 0;
      return volumeConversionRates.SLICE * quantity;
    case 'SECOND':
      return quantity;
    case 'MINUTE':
      return quantity / 60;
    case 'HOUR':
      return quantity / 60 / 60;
    case 'DAY':
      return quantity / 60 / 60 / 24;
    default:
      return quantity;
  }
};

export const getTotalTimeSeconds = (
  unit: string | undefined,
  quantity: number
) => {
  switch (unit?.toUpperCase()) {
    case 'SECOND':
      return quantity;
    case 'MINUTE':
      return quantity * 60;
    case 'HOUR':
      return quantity * 60 * 60;
    case 'DAY':
      return quantity * 60 * 60 * 24;
    default:
      return quantity;
  }
};

/**
 * Usage examples:
 * isTimeItem('cup') -> true
 * isTimeItem('half') -> false
 */

export const isIngredientItem = [
  MetricType.Cup,
  'ML',
  'GRAM',
  MetricType.Tablespoon,
  MetricType.Teaspoon,
  MetricType.Whole,
].includes;

/**
 * Usage examples:
 * isTimeItem('second') -> true
 * isTimeItem('weekend') -> false
 */

export const isTimeItem = ['SECOND', 'MINUTE', 'HOUR', 'DAY'].includes;

export const getVenueUnitCost = (quantity?: number, unit?: string) => {
  if (!quantity) {
    return 0;
  }

  const hour = quantity * 60 * 60;
  const day = hour * 24;
  const year = day * 365;

  switch (unit?.toUpperCase()) {
    case 'HOUR':
      return hour;
    case 'DAY':
      return day;
    case 'MONTH':
      return year / 12;
    case 'QUARTER':
      return year / 4;
    case 'YEAR':
      return year;
    default:
      return 0;
  }
};
