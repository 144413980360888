import styled from 'styled-components/macro';
import { IMenuMainItemProps } from '../../menuMain/menuMain.types';
import { device } from '../../../../styles/device';

export const ArrowButton = styled.section`
  background-color: ${({ theme }) => theme.colors.white.default};
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
    rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowContainer = styled.section<IMenuMainItemProps>`
  left: 0;
  position: fixed;
  top: 55px;
  width: 36px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.toggle};

  &:hover {
    ${ArrowButton} {
      background-color: ${({ theme }) => theme.colors.secondary.default};
      svg {
        fill: ${({ theme }) => theme.colors.white.default};
      }
    }
  }

  ${({ isOpen }) =>
    isOpen
      ? `left: 188px;`
      : `
      @media ${device.mobileL} {
        left: 38px;
      }
    `};

  @media ${device.mobileL} {
    top: 70px;
  }
`;
