import styled from 'styled-components/macro';
import { device } from '../../../styles/device';
import { Button } from '../../shared/button';
import { H2, Span } from '../../shared/typefaces/Typefaces.styles';

interface IHeadingProps {
  hideMarginBottom?: boolean;
}

export const Container = styled.section`
  position: relative;
`;

export const Main = styled.section`
  position: absolute;
  top: 400px;
  width: 100%;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  padding: 15px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin: 80px auto;
  }

  @media ${device.laptopL} {
    width: 900px;
  }
`;

export const Left = styled.div`
  margin: 0 auto 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.tablet} {
    justify-content: flex-start;
    padding-right: 30px;
    margin: 0 0 60px;
  }
`;

export const Right = styled.div`
  margin: 0 auto;

  @media ${device.laptop} {
    width: 500px;
  }
`;

export const Box = styled.div`
  position: relative;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white.default};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box};
  overflow: hidden;
  padding-bottom: 30px;
  max-width: 500px;

  .content {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 10px 15px;

    .errorToast {
      margin: 0;
      margin-bottom: 40px;
    }
  }

  .heading {
    position: absolute;
    top: 5px;
    left: 15px;
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .quote {
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: ${({ theme }) => theme.lineHeight.large};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-bottom: 30px;
  }

  @media ${device.tablet} {
    margin: 0;
  }
`;

export const FormSection = styled.div``;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const AddressForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const Heading = styled(Span)<IHeadingProps>`
  display: block;
  margin-top: 0 !important;
  margin-bottom: ${({ hideMarginBottom }) =>
    hideMarginBottom ? 0 : '20px'}!important;
`;

export const PageHeading = styled(H2)`
  text-align: center;
  margin: 30px 30px !important;
  color: ${({ theme }) => theme.colors.white.default};
  font-size: ${({ theme }) => theme.fontSize.largeHeading};
  text-shadow: ${({ theme }) => theme.shadow.text};

  @media ${device.tablet} {
    margin: 130px 30px !important;
    font-size: ${({ theme }) => theme.fontSize.title};
    text-align: left;
  }
`;

export const RegisterButton = styled(Button)`
  margin: 30px auto 20px;
  font-size: ${({ theme }) => theme.fontSize.default};
`;
