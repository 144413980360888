import { SupplierQuery } from '../../generated/graphql';
import { capitalizeFirstLetterPerWord, cleanURL } from './strings';

export const formatSupplierFormData = (
  data: SupplierQuery | undefined,
  {
    displayName,
    supplierGroupDisplayName,
    supplierGroupId,
    website,
    email,
    phone,
    internationalPhone,
    addressId,
    location,
    unit,
    streetNumber,
    road,
    shire,
    city,
    state,
    country,
    postcode,
    addressLine1,
    addressLine2,
    addressLookup,
    lat,
    lng,
  }: any,
  selectedIngredientProduct?: string
) => {
  return {
    displayName: capitalizeFirstLetterPerWord(
      typeof displayName === 'string' ? displayName : displayName.label
    ),
    supplierGroupName: capitalizeFirstLetterPerWord(
      typeof supplierGroupDisplayName === 'string'
        ? supplierGroupDisplayName
        : supplierGroupDisplayName.label
    ),
    supplierGroupId: supplierGroupId || '',
    website: cleanURL(website),
    email,
    phone,
    internationalPhone,
    ingredientProductId: selectedIngredientProduct || null,
    supplierAddress: {
      addressId,
      location,
      unit,
      streetNumber,
      road,
      city,
      shire,
      state,
      country,
      postcode,
      addressLine1,
      addressLine2,
      addressLookup,
      lat,
      lng,
    },
  };
};
