import styled from 'styled-components/macro';
import { IMenuMainItemProps } from '../menuMain.types';
import { Span } from '../../typefaces/Typefaces.styles';
import { Button } from '../../button';

export const Text = styled(Span)`
  width: 122px;
  text-align: center;
  white-space: normal;
`;

export const LeftIcon = styled.div`
  margin-left: 8px;
  width: 30px;
`;

export const RightIcon = styled.div`
  position: absolute !important;
  right: 10px;
`;

export const VenueBtn = styled(Button)<IMenuMainItemProps>`
  position: absolute;
  top: 10px;
  left: 4px;
  display: none;
  width: 190px;
  margin-bottom: 15px;
  min-width: 40px;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white.default};
  border: 0;
  border-radius: 50px;
  height: 50px;
  font-size: 12px;
  align-items: center;
  justify-content: left;
  border: 1px solid ${({ theme }) => theme.colors.default.default};

  ${({ isOpen, theme }) =>
    isOpen
      ? `
        display: flex;
        `
      : `
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;

        ${LeftIcon} {
          width: 25px;
          margin-left: 0;
        }
        ${Text} {
          display: none;
        }
        ${RightIcon} {
          display: none;
        }
      `};

  &:hover,
  &:focus,
  &:visited {
    background-color: ${({ theme }) => theme.colors.white.default};
  }
`;
