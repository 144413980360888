import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: ${({ theme }) => theme.colors.secondary.default}; */
  background: ${({ theme }) =>
    `linear-gradient(269.34deg, ${theme.colors.secondary.default} 8.92%, #23ca85 65.27%)`};
  padding: 20px 20px 0 20px;
  border-radius: 10px 10px 0 0;

  .heading {
    width: 100%;
    margin: 30px 0;
    font-size: ${({ theme }) => theme.fontSize.largeHeading};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .subHeading {
      font-size: ${({ theme }) => theme.fontSize.medium};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      text-align: center;
      margin-bottom: 15px;
    }
  }

  @media ${device.laptop} {
    align-items: center;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;

  .category {
    color: ${({ theme }) => theme.colors.text.white};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;

  ${Item}:first-child {
    margin-left: 0;

    @media ${device.laptop} {
      margin-left: 20px;
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: ${({ theme }) => theme.colors.white.light}; */
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-bottom: 15px;
`;

export const Selected = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.lightest};
  width: 10px;
  height: 10px;
  rotate: 45deg;
  margin-top: 20px;
  margin-bottom: -5px;
  border-radius: 5px 0;
`;

export const OptionsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: flex-start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.laptop} {
    justify-content: center;
  }
`;
