import React, { FC } from 'react';
import { H2, H4 } from '../../../../shared/typefaces/Typefaces.styles';
import { Container, Content, VideoImage, Image, CTA } from './About.styles';
import about from '../../../../../images/about-min.png';
import { AnchorButton } from '../../../../shared/button/Button.styles';

interface IAboutProps {
  aboutRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const About: FC<IAboutProps> = ({ aboutRef }) => {
  return (
    <Container id="about" ref={aboutRef}>
      {/* <Header>
        <H3 className="subHeading">
          Feeling Over-worked, Underpaid & Disconnected? 
          Profitable Recipes Create
        </H3>
        <H3>Remarkable Food Experiences!</H3>
      </Header> */}
      <Content>
        <VideoImage
          href="https://www.youtube.com/channel/UC2gSk3gXuiWbWKwj5f6M0tw/featured"
          target="_blank"
        >
          <Image src={about} />
        </VideoImage>
        <CTA>
          <H2>
            How Recipe Revenue <br />
            Works In 2 Minutes
          </H2>
          <H4>
            Have a remarkable recipe?
            <br />
            Make it profitable today!
          </H4>
          <AnchorButton color="primary" href="/select-account" className="cta">
            Get Started
          </AnchorButton>
        </CTA>
      </Content>
    </Container>
  );
};
