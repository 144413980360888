import styled from 'styled-components/macro';
import { device } from '../../../../styles/device';
import { ISlideProps, IDotProps } from './slides.types';

export const Container = styled.div`
  display: block;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 550px;

  @media ${device.mobileL} {
    height: 500px;
  }
`;

export const Slide = styled.div<ISlideProps>`
  position: absolute;
  top: 0;
  height: 100%;
  transition: opacity 0.5s;
  z-index: 1;
  opacity: ${({ currentSlide, count }) => (currentSlide === count ? 1 : 0)};
`;

export const Dots = styled.div`
  display: flex;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.priority};
  position: absolute;
  bottom: 30px;
`;

export const Dot = styled.div<IDotProps>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ theme, faded }) =>
    faded ? theme.colors.primary.faded : theme.colors.primary.default};
  display: inline-block;
  margin: 2px;

  &:hover {
    cursor: pointer;
  }
`;
