import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export const Container = styled.div`
  position: relative;
  display: flex;
`;

export const Header = styled.div`
  display: none;
  position: absolute;
  top: 0;
  height: 50px;
  width: 100%;
  z-index: -1;

  @media ${device.laptopL} {
    display: block;
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin: 0 auto;

  @media ${device.laptopL} {
    flex-direction: row;
    padding: 0 30px;
  }
`;
