import React, { FC } from 'react';
import StripeWrapper from '../../shared/formElements/stripeWrapper';
import PaymentForm from './PaymentForm';
import { RouteComponentProps } from 'react-router-dom';

const Payment: FC<RouteComponentProps> = (props) => {
  return (
    <StripeWrapper>
      <PaymentForm {...props} />
    </StripeWrapper>
  );
};

export default Payment;
