import styled from 'styled-components';
import { IStyledInputProps } from './radio.types';

export const Container = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.form.input.marginTop};
  position: relative;
  min-height: 55px;
`;

export const StyledInput = styled.input<IStyledInputProps>`
  width: 100%;

  .Mui-error {
    color: red;
  }
`;
