import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const ScaleIcon: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled size={size} viewBox="0 0 512 512" color={color} faded={faded}>
      <path d="m453.332031 512h-394.664062c-32.363281 0-58.667969-26.304688-58.667969-58.667969v-394.664062c0-32.363281 26.304688-58.667969 58.667969-58.667969h394.664062c32.363281 0 58.667969 26.304688 58.667969 58.667969v394.664062c0 32.363281-26.304688 58.667969-58.667969 58.667969zm-394.664062-480c-14.699219 0-26.667969 11.96875-26.667969 26.667969v394.664062c0 14.699219 11.96875 26.667969 26.667969 26.667969h394.664062c14.699219 0 26.667969-11.96875 26.667969-26.667969v-394.664062c0-14.699219-11.96875-26.667969-26.667969-26.667969zm0 0" />
      <path d="m410.667969 256c-8.832031 0-16-7.167969-16-16v-122.667969h-122.667969c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h138.667969c8.832031 0 16 7.167969 16 16v138.667969c0 8.832031-7.167969 16-16 16zm0 0" />
      <path d="m229.332031 298.667969c-4.09375 0-8.191406-1.558594-11.304687-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l181.332031-181.335938c6.25-6.25 16.382813-6.25 22.636719 0 6.25 6.253906 6.25 16.386719 0 22.636719l-181.335938 181.332031c-3.136718 3.136719-7.230468 4.695313-11.328125 4.695313zm0 0" />
      <path d="m176 512c-8.832031 0-16-7.167969-16-16v-144h-144c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h160c8.832031 0 16 7.167969 16 16v160c0 8.832031-7.167969 16-16 16zm0 0" />{' '}
    </IconStyled>
  );
};

export default ScaleIcon;
