import React, { FC, memo, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import {
  getWidthCutoffs,
  isEmpty,
  sortByNestedObject,
} from '../../../../../utils/helper';
import { Button } from '../../../../shared/button';
import { Card, Content, Header } from '../../../../shared/card';
import { DotsLoading } from '../../../../shared/loading';
import { IIngredientListProps } from '../../ingredients.types';
import { Share } from './../../../../shared/icons';
import { Table } from './IngredientList.styles';
import IngredientListRow from './IngredientListRow';
import TableHeader from './TableHeader';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

export const IngredientList: FC<IIngredientListProps> = ({ data }) => {
  const { appWidth, account, user, dispatch } = useContext(LayoutContext);

  const handleAddIngredient = () => {
    dispatch({ type: 'TOP_SLIDER_PAGE', payload: 'editIngredient' });
    dispatch({ type: 'SELECT_INGREDIENT_PRODUCT', payload: undefined });
    dispatch({ type: 'SELECT_INGREDIENT', payload: undefined });
    dispatch({
      type: 'SET_INGREDIENT_USER_EVENT',
      payload: 'add',
    });
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    }
  };

  const handleEditIngredient = (
    ingredientId: string,
    ingredientProductId: string
  ) => {
    dispatch({
      type: 'SELECT_INGREDIENT',
      payload: ingredientId,
    });
    dispatch({
      type: 'SET_INGREDIENT_USER_EVENT',
      payload: 'edit',
    });
    dispatch({
      type: 'SELECT_INGREDIENT_PRODUCT',
      payload: ingredientProductId,
    });
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    }
  };

  const { isSmallerThanTablet } = getWidthCutoffs(appWidth);
  const venueData = data?.data?.venue?.userVenue?.venue;
  const sortedIngredients = sortByNestedObject(
    'ingredient.displayName',
    venueData?.ingredientProducts
  );

  return (
    <Card>
      <Header
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? 'Add Ingredient'
            : !isEmpty(venueData?.displayName)
            ? `
                ${venueData?.displayName} Ingredients`
            : 'Ingredients'
        }
        subHeading="Compare ingredients to refine costs"
        button={
          isSmallerThanTablet ? (
            <Button onClick={handleAddIngredient} color="primary" asCircle>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button onClick={handleAddIngredient} color="primary">
              Add Ingredient
            </Button>
          )
        }
      />
      <Content fullWidth>
        <Table>
          <DotsLoading
            isLoading={data.loading}
            size="large"
            lineHeight={10}
            color="default"
          />
          {!data.loading && !isEmpty(venueData?.ingredientProducts) && (
            <TableHeader appWidth={appWidth} />
          )}
          {!data.loading &&
            !isEmpty(sortedIngredients) &&
            sortedIngredients!.map((col, idx) => (
              <IngredientListRow
                key={idx}
                col={col}
                appWidth={appWidth}
                handleEditIngredient={handleEditIngredient}
              />
            ))}
        </Table>
      </Content>
    </Card>
  );
};

export default memo(IngredientList);
