import { createContext } from 'react';
import { IOverlayProps } from '../components/shared/layout/layoutOverlay/layoutOverlay.types';
import { ISelectListOption } from '../components/shared/formElements/selectList/selectList.types';
import {
  AccountType,
  AddOn,
  Address,
  BillingCycle,
  Maybe,
} from '../generated/graphql';
import { IToolTipObject } from '../components/shared/layout/Layout.types';

export type IEditAccount = {
  showingRemovingCardModal?: boolean;
  shouldBillingRedirectToEditAccountWhenDone?: boolean;
  selectedAccount?: undefined | AccountType;
};

export interface ISelectedVenueProps {
  id: string;
  displayName: string;
  address:
    | Maybe<
        {
          __typename?: 'Address';
        } & Pick<
          Address,
          | 'id'
          | 'location'
          | 'unit'
          | 'streetNumber'
          | 'road'
          | 'city'
          | 'shire'
          | 'state'
          | 'country'
          | 'postcode'
          | 'addressLine1'
          | 'addressLine2'
          | 'addressLookup'
          | 'lat'
          | 'lng'
        >
      >
    | undefined;

  email: string;
  totalActiveSeconds: number;
  recipeProfitIncreasePerYear: number;
}

export interface ICurrentUserProps {
  firstName: string;
  email: string;
  isEmailConfirmed: boolean;
  lastName?: string;
  phone?: string;
  internationalPhone?: string;
  position?: string;
  address?: string;
}

export type IPaidAddOnProps = Pick<AddOn, 'app' | 'endDate'>;

export interface INewAccountAddOnProps extends IPaidAddOnProps {
  updateAddOn?: boolean;
}

export interface ILayoutContext {
  offline: boolean;
  user: ICurrentUserProps;
  appWidth: number;
  isOpen: boolean;
  menuToggle: boolean;
  showMenuOverlay: boolean;
  desktopMenuToggle: boolean;
  duplicateRecipe?: boolean;
  editRecipe?: boolean;
  sliderToggle: boolean;
  closeSlider: boolean;
  sliderPage: string;
  extraSliderToggle: boolean;
  closeExtraSlider: boolean;
  extraSliderPage: string;
  topSliderToggle: boolean;
  closeTopSlider: boolean;
  topSliderPage: string;
  selectedVenueObject?: ISelectedVenueProps;
  addVenue: boolean;
  selectedRecipe?: string;
  selectedRecipeVersion?: string;
  selectedSubRecipe?: string;
  newIngredientName?: string;
  newIngredientId?: string;
  selectedIngredient?: string;
  selectedBrand?: string;
  selectedSupplier?: string;
  selectedIngredientProduct?: string;
  ingredientCost?: number;
  ingredientGrams: ISelectListOption;
  ingredientWastage?: number;
  scaleRecipe?: boolean;
  showRecipeMenu?: boolean;
  newRecipe?: boolean;
  newRecipeFromTemplate?: boolean;
  recipeUpdated?: boolean;
  totalRecipes?: number;
  newRecipeFromRecipeTemplate?: boolean;
  scaleServes?: number;
  overlay?: IOverlayProps;
  overlayToggle: boolean;
  toolTip: {
    show: boolean;
    heading?: string;
    content?: string;
    buttonText?: string;
    pageWidth: number;
    yAxis: number;
    xAxis: number;
  };
  toolTips: IToolTipObject;
  account?: {
    type: AccountType;
    billingCycle: BillingCycle;
    addOns: IPaidAddOnProps[];
  };
  newAccount?: {
    type: AccountType;
    billingCycle: BillingCycle;
    addOns?: INewAccountAddOnProps[];
    awaitingPayment?: boolean;
  };
  justLoggedOut: boolean;
  userEvent: {
    ingredient?: 'add' | 'edit' | 'editDetails';
    brand?: 'add' | 'edit';
    supplier?: 'add' | 'edit';
  };
  help: {
    showHelpModal: boolean;
    showFBChatbox: boolean;
  };
  editAccount?: IEditAccount;
  popup?: {
    heading: string;
    message: string;
    type: 'success' | 'error';
  };
  swapOnboardingProcessBar: boolean;
  selectedStocktake: string | null;
  selectedStocktakeSection: string | null;
  dispatch: (type: any) => void;
}

export const initialLayoutState: ILayoutContext = {
  account: undefined,
  newAccount: {
    type: AccountType.Guest,
    billingCycle: BillingCycle.Yearly,
    addOns: undefined,
    awaitingPayment: false,
  },
  user: {
    firstName: '',
    email: '',
    isEmailConfirmed: false,
    lastName: undefined,
    phone: undefined,
    internationalPhone: undefined,
  },
  addVenue: false,
  appWidth: 0,
  closeExtraSlider: false,
  closeSlider: false,
  closeTopSlider: false,
  desktopMenuToggle: false,
  duplicateRecipe: false,
  editAccount: undefined,
  editRecipe: false,
  extraSliderPage: '',
  extraSliderToggle: false,
  help: {
    showFBChatbox: false,
    showHelpModal: false,
  },
  ingredientCost: undefined,
  ingredientGrams: {
    label: '',
    value: '',
  },
  ingredientWastage: undefined,
  isOpen: false,
  justLoggedOut: false,
  menuToggle: false,
  newIngredientId: '',
  newIngredientName: '',
  newRecipe: false,
  totalRecipes: undefined,
  newRecipeFromRecipeTemplate: false,
  offline: true,
  overlay: {
    buttonLink: '',
    buttonText: '',
    extraData: undefined,
    heading: '',
    link: '',
    linkText: '',
    subHeading: '',
    hideCTA: false,
    content: null,
  },
  overlayToggle: false,
  popup: undefined,
  recipeUpdated: true,
  scaleRecipe: false,
  scaleServes: undefined,
  selectedBrand: '',
  selectedIngredient: '',
  selectedIngredientProduct: '',
  selectedRecipe: '',
  selectedRecipeVersion: undefined,
  selectedSubRecipe: '',
  selectedSupplier: '',
  selectedVenueObject: undefined,
  showMenuOverlay: false,
  showRecipeMenu: false,
  sliderPage: '',
  sliderToggle: false,
  swapOnboardingProcessBar: false,
  toolTip: {
    buttonText: undefined,
    content: undefined,
    heading: undefined,
    pageWidth: 100,
    show: false,
    xAxis: 100,
    yAxis: 100,
  },
  toolTips: {
    editRecipe: false,
    recipeIngredients: false,
    journeyGoal: false,
    recipeResults: {
      foodCostPercentage: false,
      grossProfit: false,
      ingredientContribution: false,
      ingredientPurchaseCost: false,
      ingredientRecipeCost: false,
      ingredientWastage: false,
      netRevenue: false,
      profitDifference: false,
      profitPerMinute: false,
      profitPerServe: false,
      recipeMargin: false,
      recipeMarkup: false,
      recipeVersion: false,
      recipeWastage: false,
      recommendedPrice: false,
      venueWastage: false,
      weeklyRemakes: false,
    },
    resize: false,
    salesPrice: false,
    weeklySales: false,
    stocktake: {
      stocktakes: false,
      stocktake: false,
      stocktakeDifference: false,
      sectionDifference: false,
      totalItems: false,
      totalRequiredItems: false,
      quantity: false,
      unitAmount: false,
      total: false,
      required: false,
      unitType: false,
      ingredientSupplier: false,
      ingredientPurchaseCost: false,
    },
  },
  topSliderPage: '',
  topSliderToggle: false,
  selectedStocktake: null,
  selectedStocktakeSection: null,
  userEvent: {
    brand: undefined,
    ingredient: undefined,
    supplier: undefined,
  },
  dispatch: () => {},
};

export const LayoutContext = createContext<ILayoutContext>(initialLayoutState);
