import React, { FC, useContext, useRef, useState } from 'react';
import { Span, Label } from '../../../../shared/typefaces/Typefaces.styles';
import {
  Container,
  Headings,
  HeadingsItem,
  TopSection,
  StyledInput,
  HeaderNumber,
} from './Header.styles';
import { Button } from '../../../../shared/button';
import { theme } from '../../../../../styles/theme';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { Controller, useFormContext } from 'react-hook-form';
import {
  blurContent,
  convertCostCleanly,
  convertPercentageCleanly,
  validEndDate,
} from '../../../../../utils/helper';
import useResizeDimensions from '../../../../../utils/customHooks/useResizeDimensions';
import { IRecipeDataResult } from '../../../../../utils/clientServerShared/getRecipeData.types';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';
import { useRestrictionCheck } from '../../../../../utils/helper/account';
import { AccountType, AddOnApp } from '../../../../../generated/graphql';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';

const Header: FC<{ recipeData: IRecipeDataResult }> = ({ recipeData }) => {
  const {
    appWidth,
    account,
    toolTips: {
      recipeResults: { foodCostPercentage, profitPerMinute },
    },
    dispatch,
  } = useContext(LayoutContext);
  const { errors, control } = useFormContext();
  const [editName, setEditName] = useState(false);
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeDimensions(pageWidthRef);
  const foodCostPercentageRef = useRef<HTMLLIElement>(null);
  const foodCostPercentageDimensions = useResizeDimensions(
    foodCostPercentageRef
  );
  const profitPerMinuteRef = useRef<HTMLLIElement>(null);
  const profitPerMinuteDimensions = useResizeDimensions(profitPerMinuteRef);
  const showRestrictionPopup = useRestrictionPopup();
  const checkRecipeProfitRestrictions = useRestrictionCheck([
    'CALCULATE_RECIPE_PROFIT',
  ]);
  const restrictionCheck = checkRecipeProfitRestrictions({
    data: account?.type === AccountType.Registered,
  });
  const checkFoodCostRestrictions = useRestrictionCheck(['FOOD_COST']);

  const restrictionFoodCostCheck = checkFoodCostRestrictions({
    data: account?.type !== AccountType.Registered,
  });

  const calculatorAddOn = account?.addOns.find(
    (a) => a.app === AddOnApp.Calculator
  );
  const { endDateIsValid } = validEndDate(calculatorAddOn?.endDate);
  const showProfitContent = endDateIsValid || restrictionCheck.isPass;
  const showFoodCostContent = endDateIsValid || restrictionFoodCostCheck.isPass;

  const handleRecipeRevenueUpgrade = () => {
    showRestrictionPopup(
      restrictionPopupMessages.cannotDisplayRecipeVersionProfit
    );
  };

  const onFoodCostPercentageClick = () => {
    dispatch({
      type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
      payload: { foodCostPercentage: !foodCostPercentage },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Food Cost Percentage',
        content:
          'Food cost percentage is calculated by Food Cost / Recipe Revenue * 100',
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: foodCostPercentageDimensions.top,
        xAxis: foodCostPercentageDimensions.width,
      },
    });
  };

  const onProfitPerMinuteClick = () => {
    dispatch({
      type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
      payload: { profitPerMinute: !profitPerMinute },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Profit Per Minute',
        content:
          'We tend to only value the profit of our menu. If two different recipe versions make the same profit, however one recipe is 30 seconds faster to make.. Which one is more profitable? This is what Profit Per Minute is helping you to understand',
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: profitPerMinuteDimensions.top,
        xAxis: profitPerMinuteDimensions.width,
      },
    });
  };

  return (
    <Container ref={pageWidthRef}>
      <Headings>
        <HeadingsItem className="title">
          <TopSection>
            <Label
              color="faded"
              fontSize="small"
              fontWeight="semibold"
              className="headerLabel"
            >
              <div>
                Created:{' '}
                <Span color="faded" fontSize="small">
                  {recipeData.recipeCreatedAt}
                </Span>
              </div>
            </Label>
            {appWidth !== 0 && appWidth < theme.mQ.tablet && (
              <Button onClick={null} type="submit" color="secondary">
                Save Recipe!!
              </Button>
            )}
          </TopSection>{' '}
          <Controller
            as={<StyledInput />}
            type="text"
            name="displayName"
            border={editName || errors.displayName ? undefined : 'noBorder'}
            control={control}
            onBlur={() => setEditName(false)}
            errorText={errors.displayName && errors.displayName.message}
            defaultValue=""
          />
        </HeadingsItem>
      </Headings>
      <Headings align="right">
        <HeadingsItem>
          <Label
            className="headerLabel hasToolTip hasToolTipIcon"
            color="faded"
            fontSize="small"
            ref={foodCostPercentageRef}
            onClick={onFoodCostPercentageClick}
          >
            Food Cost Percentage
          </Label>
          <HeaderNumber
            fontSize="heading"
            className={`${!showFoodCostContent && 'largeBlur hasSeeIcon'}`}
            onClick={() => !showFoodCostContent && handleRecipeRevenueUpgrade()}
          >
            {showFoodCostContent
              ? convertPercentageCleanly(recipeData.foodCostPercentage)
              : blurContent(
                  convertPercentageCleanly(recipeData.foodCostPercentage)
                )}
          </HeaderNumber>
        </HeadingsItem>
        <HeadingsItem>
          <Label
            className="headerLabel hasToolTip hasToolTipIcon"
            color="faded"
            fontSize="small"
            onClick={onProfitPerMinuteClick}
            ref={profitPerMinuteRef}
          >
            Profit Per Minute
          </Label>
          <HeaderNumber
            fontSize="heading"
            className={`${!showProfitContent && 'largeBlur hasSeeIcon'}`}
            onClick={() => !showProfitContent && handleRecipeRevenueUpgrade()}
          >
            {showProfitContent
              ? convertCostCleanly(recipeData.profitPerMin)
              : blurContent(convertCostCleanly(recipeData.profitPerMin))}
          </HeaderNumber>
        </HeadingsItem>
        <HeadingsItem>
          <Label className="headerLabel" color="faded" fontSize="small">
            Profit Per Serve
          </Label>
          <HeaderNumber
            fontSize="heading"
            className={`${!showProfitContent && 'largeBlur hasSeeIcon'}`}
            onClick={() => !showProfitContent && handleRecipeRevenueUpgrade()}
          >
            {showProfitContent
              ? convertCostCleanly(recipeData.profitPerServe)
              : blurContent(convertCostCleanly(recipeData.profitPerServe))}
          </HeaderNumber>
        </HeadingsItem>
      </Headings>
    </Container>
  );
};

export default Header;
