import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const CafeSign: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 128.000000 128.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M216 1272 c-3 -4 -53 -286 -112 -625 -88 -505 -105 -619 -94 -632 10
-12 36 -15 124 -15 61 0 117 4 125 9 9 6 19 40 26 83 l11 73 34 0 34 0 11 -73
c7 -43 17 -77 26 -83 8 -5 69 -9 135 -9 149 0 151 1 137 100 l-9 70 162 0 162
0 7 -62 c12 -105 16 -108 149 -108 88 0 116 3 126 15 10 12 -4 134 -70 617
-49 353 -89 612 -97 626 l-13 22 -435 0 c-238 0 -436 -4 -439 -8z m858 -59 c5
-24 152 -1089 159 -1148 2 -19 -5 -20 -90 -23 -103 -3 -96 -9 -108 88 -4 30
-12 61 -17 68 -16 19 -371 17 -388 -3 -11 -13 -10 -43 6 -127 l6 -28 -107 0
c-75 0 -107 4 -110 13 -4 10 -165 1166 -165 1181 0 3 182 6 404 6 l404 0 6
-27z m-780 -538 c30 -220 56 -413 58 -430 3 -28 1 -30 -37 -33 -22 -2 -44 -7
-49 -12 -5 -5 -14 -42 -20 -82 l-11 -73 -95 0 c-79 0 -95 3 -93 15 9 64 175
1018 180 1030 8 20 4 45 67 -415z"
        />
        <path
          d="M556 1128 c-20 -28 -20 -58 -2 -83 7 -10 11 -26 9 -36 -6 -22 14 -34
32 -19 20 16 19 49 -2 72 -14 15 -14 22 -3 42 22 42 -7 63 -34 24z"
        />
        <path
          d="M634 1118 c-15 -29 -15 -34 -1 -62 9 -17 17 -40 19 -51 2 -11 10 -20
18 -20 21 0 26 62 6 84 -10 11 -12 21 -6 31 15 23 12 50 -5 50 -8 0 -22 -14
-31 -32z"
        />
        <path
          d="M726 1128 c-20 -28 -20 -58 -2 -83 7 -10 11 -26 9 -36 -6 -22 14 -34
32 -19 20 16 19 50 -1 78 -12 18 -13 26 -4 37 13 16 7 45 -9 45 -5 0 -17 -10
-25 -22z"
        />
        <path
          d="M533 934 c-17 -7 -16 -55 2 -151 18 -95 18 -93 1 -93 -26 0 -76 -50
-76 -77 0 -67 120 -116 285 -117 164 -1 265 38 265 104 0 33 -41 72 -95 90
-33 11 -35 14 -35 56 0 40 2 44 25 44 32 0 65 33 65 64 0 14 -11 38 -24 53
l-24 28 -189 2 c-103 1 -194 0 -200 -3z m280 -48 c3 -8 11 -50 18 -94 14 -94
5 -128 -40 -155 -37 -22 -66 -21 -117 2 -52 24 -79 68 -99 160 -23 110 -32
101 109 101 97 0 125 -3 129 -14z m98 -7 c16 -16 18 -23 8 -35 -6 -8 -15 -14
-20 -14 -15 0 -39 31 -39 51 0 25 25 24 51 -2z m-315 -240 c68 -76 209 -79
257 -5 15 22 19 23 52 13 40 -12 70 -40 60 -56 -24 -40 -164 -65 -289 -51 -79
9 -166 43 -174 67 -3 11 52 53 69 53 3 0 14 -9 25 -21z"
        />
        <path
          d="M521 437 c-6 -8 -8 -17 -4 -20 8 -9 538 -9 546 0 4 3 2 12 -4 20 -17
19 -521 19 -538 0z"
        />
        <path
          d="M660 340 c0 -20 5 -20 142 -18 127 3 143 5 146 21 3 16 -9 17 -142
17 -140 0 -146 -1 -146 -20z"
        />
      </g>
    </IconStyled>
  );
};

export default CafeSign;
