import React, { useContext } from 'react';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { theme } from '../../../../styles/theme';
import { Col, HeaderRow } from '../Invoices.styles';
import { Label } from '../../../shared/typefaces/Typefaces.styles';

const TableHeader = () => {
  const { appWidth } = useContext(LayoutContext);

  if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
    return (
      <HeaderRow>
        <Col>
          <Label>Date</Label>
        </Col>
      </HeaderRow>
    );
  } else {
    return (
      <HeaderRow>
        <Col />
        <Col>
          <Label>Date</Label>
        </Col>
      </HeaderRow>
    );
  }
};

export default TableHeader;
