import { useContext, useRef, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { RecipeFormState, RecipeVersionFromQuery } from '../recipe.types';
import { calculateScaleQuantity } from '../../../../utils/helper';
import { isDefinedAndNotNullFilter } from '../../../../utils/helper/arrays';

function useScaleRecipe(
  methods: UseFormMethods<RecipeFormState>,
  recipeVersion: RecipeVersionFromQuery | undefined
) {
  const { scaleRecipe } = useContext(LayoutContext);

  const servesRaw = methods.watch('serves');
  const watchItems = methods.watch('items');

  // when we clear the number of serves, we need access to the previous number of serves so that we
  // we can scale the time items accordingly
  const previousServes = useRef<number>(Number(servesRaw));
  const serves = useRef<number>(Number(servesRaw));

  useEffect(() => {
    const newServes = Number(servesRaw);
    if (serves.current !== newServes && newServes) {
      previousServes.current = serves.current;
      serves.current = newServes;
    }
  }, [servesRaw]);

  useEffect(() => {
    if (!scaleRecipe || !recipeVersion || !servesRaw || methods.errors.serves) {
      return;
    }

    const newQuantities = scaleItemQuantities({
      watchItems,
      recipeVersion,
      serves: serves.current,
      prevServes: previousServes.current,
    });

    for (const { quantity, index } of newQuantities) {
      methods.setValue(`items[${index}].quantity`, quantity);
    }
  }, [JSON.stringify(recipeVersion), scaleRecipe, servesRaw]);
}

function scaleItemQuantities({
  watchItems,
  recipeVersion,
  serves,
  prevServes,
}: {
  watchItems: RecipeFormState['items'];
  recipeVersion: RecipeVersionFromQuery;
  serves: number;
  prevServes: number;
}) {
  const mappedItems = watchItems.map((item, i) => {
    if (item.__typename === 'RecipeIngredient') {
      const originalIngredientItemQuantity = recipeVersion?.recipeIngredients?.find(
        (recipeItem) => {
          return recipeItem.ingredient.id === item.ingredient?.value;
        }
      )?.quantity;

      const originalRecipeItemQuantity = recipeVersion?.recipeAsIngredients?.find(
        (recipeItem) => {
          return recipeItem.recipe.id === item.ingredient?.value;
        }
      )?.quantity;

      const originalQuantity =
        originalIngredientItemQuantity || originalRecipeItemQuantity;

      if (originalQuantity && recipeVersion.serves) {
        return {
          index: i,
          rowKey: item.rowKey,
          quantity: calculateScaleQuantity({
            currentQuantity: originalQuantity,
            prevServes: recipeVersion.serves,
            newServes: serves,
          }),
        } as const;
      }
    }

    return {
      index: i,
      rowKey: item.rowKey,
      quantity: calculateScaleQuantity({
        currentQuantity: Number(item.quantity),
        prevServes: prevServes,
        newServes: serves,
      }),
    } as const;
  });

  return mappedItems.filter(isDefinedAndNotNullFilter);
}

export default useScaleRecipe;
