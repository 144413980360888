import React, { FC, useContext, useRef, useState } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { IRecipeIngredientDataResultItem } from '../../../../../utils/clientServerShared/getRecipeIngredientData.types';
import useResizeDimensions from '../../../../../utils/customHooks/useResizeDimensions';
import {
  convertCostCleanly,
  convertGramsCleanly,
  getRecipeIngredientGrams,
  getWidthCutoffs,
  isEmpty,
  roundTo,
  sortArrayOfObjects,
} from '../../../../../utils/helper';
import { getSubRecipeGrams } from '../../../../../utils/helper/getSubRecipeGrams';
import { Button } from '../../../../shared/button';
import { Card, Content, Header } from '../../../../shared/card';
import { Highlight } from '../../../../shared/formElements/selectList/SelectList.styles';
import { Apple, Edit, Share } from '../../../../shared/icons';
import { LayoutColumn } from '../../../../shared/layout';
import { DotsLoading } from '../../../../shared/loading';
import { H3, Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { IIngredientResultsProps } from './ingredientResult.types';
import {
  Col,
  CTAButton,
  HeaderRow,
  Icon,
  Row,
  Table,
} from './IngredientResults.styles';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

export const IngredientResults: FC<IIngredientResultsProps> = ({
  recipe,
  venue,
}) => {
  const {
    appWidth,
    selectedRecipeVersion,
    toolTips,
    account,
    user,
    dispatch,
  } = useContext(LayoutContext);
  const [showIngredientList, setShowIngredientList] = useState(
    toolTips.recipeIngredients // Original Tool tip for ingredientResults button
  );
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeDimensions(pageWidthRef);
  const ingredientRecipeCostRef = useRef<HTMLLIElement>(null);
  const ingredientRecipeCostDimensions = useResizeDimensions(
    ingredientRecipeCostRef
  );
  const ingredientPurchaseCostRef = useRef<HTMLLIElement>(null);
  const ingredientPurchaseCostDimensions = useResizeDimensions(
    ingredientPurchaseCostRef
  );
  const ingredientContributionRef = useRef<HTMLLIElement>(null);
  const ingredientContributionDimensions = useResizeDimensions(
    ingredientContributionRef
  );
  const {
    ingredientRecipeCost,
    ingredientPurchaseCost,
    ingredientContribution,
  } = toolTips.recipeResults;

  if (!recipe) {
    return <H3>Loading..</H3>;
  }

  const handleEditIngredient = async (
    id: string,
    ingredientProductId: string,
    displayName?: string
  ) => {
    dispatch({ type: 'SELECT_INGREDIENT', payload: id });
    dispatch({
      type: 'SELECT_INGREDIENT_PRODUCT',
      payload: ingredientProductId,
    });
    if (displayName) {
      dispatch({ type: 'SET_NEW_INGREDIENT_NAME', payload: displayName });
    }
    dispatch({
      type: 'SET_INGREDIENT_USER_EVENT',
      payload: 'edit',
    });
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });

    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountEditIngredient,
      });
    }
  };

  const handleEditRecipe = (recipeVersionId: string, subRecipe?: boolean) => {
    if (subRecipe) {
      dispatch({ type: 'SELECT_SUB_RECIPE', payload: recipeVersionId });
      dispatch({ type: 'SLIDER_PAGE', payload: 'editSubRecipe' });
    } else {
      dispatch({ type: 'SELECT_RECIPE_VERSION', payload: recipeVersionId });
      dispatch({ type: 'SLIDER_PAGE', payload: 'editRecipe' });
    }

    dispatch({ type: 'DUPLICATE_RECIPE', payload: false });
    dispatch({ type: 'SCALE_RECIPE', payload: false });

    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountEditRecipe,
      });
    }
  };

  const handleEdit = (col) => {
    const { id, ingredientProductId, displayName, subRecipe } = col;
    subRecipe
      ? handleEditRecipe(id, subRecipe)
      : handleEditIngredient(id, ingredientProductId, displayName);
  };

  const onRecipeCostClick = () => {
    dispatch({
      type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
      payload: { ingredientRecipeCost: !ingredientRecipeCost },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Ingredient Recipe Cost',
        content: `What the cost of the ingredient is for the amount used in this recipe`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: ingredientRecipeCostDimensions.top,
        xAxis: ingredientRecipeCostDimensions.width,
      },
    });
  };

  const onPurchaseCostClick = () => {
    dispatch({
      type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
      payload: { ingredientPurchaseCost: !ingredientPurchaseCost },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Ingredient Purchase Cost',
        content: `What the total cost of the ingredient is when purchased`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: ingredientPurchaseCostDimensions.top,
        xAxis: ingredientPurchaseCostDimensions.width,
      },
    });
  };

  const onContributionClick = () => {
    dispatch({
      type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
      payload: { ingredientContribution: !ingredientContribution },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Ingredient Contribution',
        content: `How this ingredient contributes to the recipe based on weight`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: ingredientContributionDimensions.top,
        xAxis: ingredientContributionDimensions.width,
      },
    });
  };

  const {
    isSmallerThanPhoneLarge,
    isSmallerThanPhoneMed,
    isSmallerThanTablet,
    isSmallerThanLaptop,
  } = getWidthCutoffs(appWidth);

  const getTableHeader = () => {
    if (isSmallerThanPhoneMed) {
      return (
        <HeaderRow>
          <Col>
            <Label>Ingredient</Label>
          </Col>
          <Col>
            <Label>Cost</Label>
          </Col>
          <Col />
        </HeaderRow>
      );
    } else if (isSmallerThanPhoneLarge) {
      return (
        <HeaderRow>
          <Col>
            <Label>Ingredient Name</Label>
          </Col>
          <Col>
            <Label>Recipe Cost</Label>
          </Col>
          <Col>
            <Label>Contribution</Label>
          </Col>
        </HeaderRow>
      );
    } else if (isSmallerThanLaptop) {
      return (
        <HeaderRow>
          <Col>
            <Label>Ingredient Name</Label>
          </Col>
          <Col>
            <Label>
              <Span className="firstItem" color="black">
                Recipe Cost
              </Span>
            </Label>
          </Col>
          <Col>
            <Span className="firstItem" color="black">
              Purchase Cost
            </Span>
          </Col>
          <Col>
            <Label>Contribution</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow>
          <Col />
          <Col>
            <Label>Ingredient Results</Label>
          </Col>
          <Col onClick={onRecipeCostClick} ref={ingredientRecipeCostRef}>
            <Label>
              <Span className="firstItem hasToolTipIcon" color="black">
                Recipe Cost
              </Span>
            </Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Span className="firstItem hasToolTipIcon" color="black">
              Purchase Cost
            </Span>
          </Col>
          <Col onClick={onContributionClick} ref={ingredientContributionRef}>
            <Label className="hasToolTipIcon">Contribution</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const getTableRows = (col, idx) => {
    if (!col.purchaseCost) {
      if (isSmallerThanPhoneMed) {
        return (
          <Row
            key={col.id + col.order + idx}
            updateIngredient
            onClick={() => handleEdit(col)}
          >
            <Col>
              <Span color="grey">
                {col.displayName}
                {col.subRecipe && <Highlight> - sub-recipe</Highlight>}
              </Span>
            </Col>
            <Col>
              <LayoutColumn>
                <Span color="primary">Finish Adding</Span>
              </LayoutColumn>
            </Col>
            <Col>
              <Icon>
                <Edit size="small" color="grey" faded />
              </Icon>
            </Col>
          </Row>
        );
      } else if (isSmallerThanLaptop) {
        return (
          <Row
            key={col.id + col.order + idx}
            updateIngredient
            onClick={() => handleEdit(col)}
          >
            <Col>
              <Span color="grey">
                {col.displayName}
                {col.subRecipe && <Highlight> - sub-recipe</Highlight>}
              </Span>
            </Col>
            <Col>
              <LayoutColumn>
                <Span color="primary">Finish Adding Ingredient</Span>
              </LayoutColumn>
            </Col>
            <Col>
              <Icon>
                <Edit size="small" color="grey" faded />
              </Icon>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row
            key={col.id + col.order + idx}
            updateIngredient
            onClick={() => handleEdit(col)}
          >
            <Col />
            <Col>
              <Span color="grey">
                {col.displayName}
                {col.subRecipe && <Highlight> - sub-recipe</Highlight>}
              </Span>
            </Col>
            <Col>
              <LayoutColumn>
                <Span color="primary">Finish Adding Ingredient</Span>
              </LayoutColumn>
            </Col>
            <Col>
              <Icon>
                <Edit size="small" color="grey" faded />
              </Icon>
            </Col>
          </Row>
        );
      }
    } else if (isSmallerThanPhoneMed) {
      return (
        <Row key={col.id + col.order + idx} onClick={() => handleEdit(col)}>
          <Col>
            <Span color="grey">
              {col.displayName}
              {col.subRecipe && <Highlight> - sub-recipe</Highlight>}
            </Span>
          </Col>
          <Col>
            <LayoutColumn colOneWidth="50px">
              <Span color="grey">{col.recipeCost}</Span>
            </LayoutColumn>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (isSmallerThanPhoneLarge) {
      return (
        <Row key={col.id + col.order + idx} onClick={() => handleEdit(col)}>
          <Col>
            <Span color="grey">
              {col.displayName}
              {col.subRecipe && <Highlight> - sub-recipe</Highlight>}
            </Span>
          </Col>
          <Col>
            <Span color="grey">{col.recipeCost}</Span>
          </Col>
          <Col>
            <Span color="grey">{col.contribution || 0}%</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (isSmallerThanTablet) {
      return (
        <Row key={col.id + col.order + idx} onClick={() => handleEdit(col)}>
          <Col>
            <Span color="grey">
              {col.displayName}
              {col.subRecipe && <Highlight> - sub-recipe</Highlight>}
            </Span>
          </Col>
          <Col>
            <Span color="grey">{col.recipeCost}</Span>
          </Col>
          <Col>
            <Span color="grey">{col.purchaseCost}</Span>
          </Col>
          <Col>
            <Span color="grey">{col.contribution || 0}%</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (isSmallerThanLaptop) {
      return (
        <Row key={col.id + col.order + idx} onClick={() => handleEdit(col)}>
          <Col>
            <Span color="grey">
              {col.displayName}
              {col.subRecipe && <Highlight> - sub-recipe</Highlight>}
            </Span>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Span color="grey">{col.recipeCost}</Span>
              <Span>|</Span>
              <Span color="grey" className="last">
                {col.recipeGrams}
              </Span>
            </LayoutColumn>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Span color="grey">{col.purchaseCost}</Span>
              <Span>|</Span>
              <Span color="grey" className="last">
                {col.purchaseGrams}
              </Span>
            </LayoutColumn>
          </Col>
          <Col>
            <Span color="grey">{col.contribution || 0}%</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row key={col.id + col.order + idx} onClick={() => handleEdit(col)}>
          <Col />
          <Col>
            <Span color="grey">
              {col.displayName}
              {col.subRecipe && <Highlight> - sub-recipe</Highlight>}
            </Span>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Span color="grey">{col.recipeCost}</Span>
              <Span>|</Span>
              <Span color="grey" className="last">
                {col.recipeGrams}
              </Span>
            </LayoutColumn>
          </Col>
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Span color="grey">{col.purchaseCost}</Span>
              <Span>|</Span>
              <Span color="grey" className="last">
                {col.purchaseGrams}
              </Span>
            </LayoutColumn>
          </Col>
          <Col>
            <Span color="grey">{col.contribution || 0}%</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  };

  const selectedVersion = recipe.data?.recipe?.recipe?.recipeVersions.find(
    (version) => version.id === selectedRecipeVersion
  );

  const ingredientsArray = [] as any;
  const venueIngredients =
    venue?.data?.venue?.userVenue?.venue.ingredientProducts;

  if (selectedVersion) {
    if (!isEmpty(selectedVersion.recipeIngredients)) {
      selectedVersion.recipeIngredients?.forEach((recipeIngredient) => {
        const ingredientCost = venueIngredients?.find((ingredient) => {
          return ingredient.ingredientId === recipeIngredient.ingredient.id;
        });

        const ingredientResult: IRecipeIngredientDataResultItem = {
          id: recipeIngredient.ingredient.id,
          ingredientProductId: ingredientCost?.id,
          displayName: recipeIngredient.ingredient.displayName,
          recipeCost: undefined,
          recipeGrams: undefined,
          purchaseCost: undefined,
          purchaseGrams: undefined,
          contribution: undefined,
          order: recipeIngredient.order,
        };

        if (ingredientCost && !isEmpty(ingredientCost.size)) {
          const ingredientGrams = getRecipeIngredientGrams(
            recipeIngredient.unit.toLowerCase(),
            recipeIngredient.quantity,
            ingredientCost.ingredient.metrics
          );

          ingredientResult.recipeCost = !isEmpty(ingredientCost.size)
            ? convertCostCleanly(
                (ingredientCost.size!.productCost /
                  ingredientCost.size!.productGrams) *
                  ingredientGrams!
              )
            : '';
          ingredientResult.recipeGrams = convertGramsCleanly(ingredientGrams);
          ingredientResult.purchaseCost = !isEmpty(ingredientCost.size)
            ? convertCostCleanly(ingredientCost.size!.productCost)
            : '';
          ingredientResult.purchaseGrams = !isEmpty(ingredientCost.size)
            ? convertGramsCleanly(ingredientCost.size!.productGrams)
            : '';

          ingredientResult.contribution = roundTo(
            (ingredientGrams! / selectedVersion.totalGrams) * 100
          );
          ingredientsArray.push(ingredientResult);
        } else {
          const ingredientGrams = getRecipeIngredientGrams(
            recipeIngredient.unit.toLowerCase(),
            recipeIngredient.quantity,
            recipeIngredient.ingredient.metrics
          );

          ingredientResult.recipeCost = !isEmpty(
            recipeIngredient.ingredient.averageCost100g
          )
            ? convertCostCleanly(
                (recipeIngredient.ingredient.averageCost100g / 100) *
                  ingredientGrams!
              )
            : '';

          ingredientResult.recipeGrams = convertGramsCleanly(ingredientGrams);
          ingredientResult.purchaseCost = !isEmpty(
            recipeIngredient.ingredient.averageCost100g
          )
            ? convertCostCleanly(recipeIngredient.ingredient.averageCost100g)
            : '';
          ingredientResult.purchaseGrams = !isEmpty(
            recipeIngredient.ingredient.averageCost100g
          )
            ? convertGramsCleanly(100)
            : '';

          ingredientResult.contribution = roundTo(
            (ingredientGrams! / selectedVersion.totalGrams) * 100
          );

          if (!ingredientResult.purchaseCost && !showIngredientList) {
            setShowIngredientList(true);
          }

          ingredientsArray.push(ingredientResult);
        }
      });
    }

    if (selectedVersion.recipeAsIngredients) {
      selectedVersion.recipeAsIngredients?.forEach((subRecipe) => {
        const {
          unit,
          quantity,
          order,
          recipe: {
            id,
            displayName,
            recipeProfit,
            recipeRevenue,
            serves,
            totalGrams,
          },
        } = subRecipe;

        const subRecipeGrams = getSubRecipeGrams({
          unit,
          quantity,
          recipeServes: serves,
          totalGrams,
        })!;

        const recipeCost = recipeRevenue - recipeProfit;

        const subRecipeResult: IRecipeIngredientDataResultItem = {
          id: id,
          ingredientProductId: undefined,
          displayName: displayName,
          recipeCost: convertCostCleanly(
            subRecipeGrams * (recipeCost / totalGrams)
          ),
          recipeGrams: convertGramsCleanly(subRecipeGrams),
          purchaseCost: convertCostCleanly(recipeCost),
          purchaseGrams: convertGramsCleanly(totalGrams),
          contribution: roundTo(
            (subRecipeGrams / selectedVersion.totalGrams) * 100
          ),
          order,
          subRecipe: true,
        };
        ingredientsArray.push(subRecipeResult);
      });
    }
    ingredientsArray.sort(sortArrayOfObjects('displayName'));
  }

  const isLoading = venue?.loading || recipe.loading;

  const toggleIngredientsList = () => {
    dispatch({ type: 'SET__TOOL_TIP_RESIZE', payload: true });
    setShowIngredientList((show) => !show);
    if (showIngredientList) {
      dispatch({ type: 'HIDE_TOOL_TIP' });
    }
  };

  const ctaButtons =
    toolTips.recipeIngredients ||
    (isEmpty(ingredientsArray) && selectedRecipeVersion) ? (
      isSmallerThanTablet ? (
        <Button
          color="primary"
          asCircle
          onClick={() => handleEditRecipe(selectedRecipeVersion!)}
          disabled={isLoading}
        >
          <Share color="white" size="small" />
        </Button>
      ) : (
        <CTAButton
          color="primary"
          onClick={() => handleEditRecipe(selectedRecipeVersion!)}
          disabled={isLoading}
        >
          Add Ingredients
        </CTAButton>
      )
    ) : isSmallerThanTablet ? (
      <Button
        color="primary"
        asCircle
        onClick={toggleIngredientsList}
        disabled={isLoading}
      >
        <Share color="white" size="small" />
      </Button>
    ) : (
      <Button
        color="default"
        inversed
        onClick={toggleIngredientsList}
        disabled={isLoading}
      >
        {`${showIngredientList ? 'Hide' : 'See'} Results`}
      </Button>
    );

  const isShowingIngredientsList =
    !venue?.loading &&
    !recipe.loading &&
    selectedVersion &&
    showIngredientList &&
    !isEmpty(ingredientsArray);

  return (
    <Card withCardLink ref={pageWidthRef}>
      <Header
        icon={<Apple size="small" />}
        heading={
          appWidth !== 0 &&
          appWidth < theme.mQ.tablet &&
          isEmpty(ingredientsArray)
            ? 'Add Ingredients'
            : 'Ingredient Results'
        }
        subHeading={
          selectedVersion && !isEmpty(selectedVersion.recipeIngredients)
            ? 'Review ingredients to refine costs & optimise recipe profit'
            : 'Add ingredients to see the ingredient results'
        }
        button={ctaButtons}
        toolTip={{
          type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
          heading: 'Ingredient Results',
          content:
            'Use ingredient contribution to ensure you have the correct balance of ingredients. Then refine recipe costs to optimise profit.',
        }}
      />
      <DotsLoading
        isLoading={isLoading}
        size="large"
        lineHeight={10}
        color="default"
      />
      {isShowingIngredientsList && (
        <Content fullWidth>
          <Table>
            {getTableHeader()}
            {ingredientsArray.map((col, idx) => getTableRows(col, idx))}
          </Table>
        </Content>
      )}
    </Card>
  );
};

export default IngredientResults;
