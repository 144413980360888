import React from 'react';

import {
  LayoutPageStyles,
  LayoutContentStyles,
  LayoutContentCenterStyles,
} from './LayoutPage.styles';
import { ILayoutPageProps } from './layoutPage.types';
import { Navbar } from '../../navbar';

const LayoutPage: React.FC<ILayoutPageProps> = ({
  children,
  heading,
  align,
  slider,
  noBottomPadding,
  ...otherProps
}) => {
  return (
    <LayoutPageStyles noBottomPadding={noBottomPadding}>
      {heading && <Navbar slider={slider} heading={heading} {...otherProps} />}
      <LayoutContentStyles>
        <LayoutContentCenterStyles align={align}>
          {children}
        </LayoutContentCenterStyles>
      </LayoutContentStyles>
    </LayoutPageStyles>
  );
};

export default LayoutPage;
