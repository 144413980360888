import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  INewAccountAddOnProps,
  LayoutContext,
} from '../../../contexts/layoutContext';
import {
  AccountType,
  BillingCycle,
  useMeQuery,
  useSignupMutation,
  useUpdateUserMutation,
} from '../../../generated/graphql';
import { setAccessToken } from '../../../utils/accessToken';
import { plansArray } from '../../../utils/helper/strings';
import { mockData } from '../../../utils/mockData';
import { Input, SelectList } from '../../shared/formElements';
import { AngleBox } from '../../shared/layout';
import { SelectedPlan } from '../../shared/layout/plans';
import { DotsLoading } from '../../shared/loading';
import { ProgressBar } from '../../shared/onboardingProgressBar';
import {
  ErrorMessage,
  H3,
  Span,
} from '../../shared/typefaces/Typefaces.styles';
import {
  Box,
  Container,
  Content,
  Form,
  Heading,
  Left,
  Main,
  RegisterButton,
  Right,
} from './GetStarted.styles';
import { isPaidAccount } from '../../../utils/helper/subscription';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

interface INewAccountProps {
  type: AccountType;
  billingCycle: BillingCycle;
  addOns?: INewAccountAddOnProps[] | undefined;
}

const GetStarted = () => {
  const { newAccount, selectedVenueObject, dispatch } = useContext(
    LayoutContext
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { push } = useHistory();
  const [signup, signUpMutation] = useSignupMutation();
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const [updateUserMutation, updateUser] = useUpdateUserMutation();
  const { errors, register, handleSubmit, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { data, loading: meQueryLoading } = useMeQuery();

  useEffect(() => {
    if (!newAccount?.type || newAccount?.type === AccountType.Guest) {
      push('/select-account');
    }
  }, [newAccount?.type]);

  useEffect(() => {
    dispatch({ type: 'CLOSE_MENU' });
  }, [dispatch]);

  const setAccountType = async (user) => {
    dispatch({
      type: 'SET_ACCOUNT',
      payload: { ...newAccount, type: user?.accountType },
    });
  };

  const setNewAccount = (newAccount: INewAccountProps) => {
    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: { ...newAccount, awaitingPayment: true },
    });
  };

  const onSubmit = async (formData) => {
    const newAccountPaymentCheck =
      newAccount?.type && isPaidAccount(newAccount.type);

    if (data?.me?.successful) {
      // On sign up even if the user selected paid account, set account to free as they have not adding billing details
      const updatedAccountType = newAccountPaymentCheck
        ? AccountType.Registered
        : newAccount?.type || AccountType.Registered;

      try {
        const response = await updateUserMutation({
          variables: {
            input: {
              ...formData,
              accountType: updatedAccountType,
              venueId: selectedVenueObject?.id!,
              totalActiveSeconds: getTotalActiveSeconds(),
            },
          },
          refetchQueries: ['useMeQuery'],
          awaitRefetchQueries: true,
        });

        if (response.data?.updateUser.successful) {
          const { user } = response.data.updateUser;
          await setAccountType(user);
          if (newAccount?.type) {
            if (newAccountPaymentCheck) {
              setNewAccount(newAccount);
              push('/payment');
            } else {
              push('/recipe-results');
            }
          } else {
            push('/select-account');
          }
        } else {
          if (response?.data?.updateUser?.error) {
            setErrorMessage(response.data.updateUser.error);
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const updatedAccountType = newAccountPaymentCheck
          ? AccountType.Registered
          : newAccount?.type || AccountType.Registered;

        const response = await signup({
          variables: {
            input: {
              email: formData.email,
              password: formData.newPassword,
              firstName: formData.firstName,
              position: formData.position,
              accountType: updatedAccountType,
            },
          },
        });

        if (response?.data?.signup.successful) {
          const { accessToken, user } = response.data.signup;
          await setAccountType(user?.accountType);
          if (accessToken) {
            setAccessToken(accessToken);
          }
          if (newAccount?.type) {
            if (newAccountPaymentCheck) {
              setNewAccount(newAccount);
              push('/payment');
            } else {
              push('/recipes');
            }
          }
        } else {
          if (response?.data?.signup?.error) {
            setErrorMessage(response.data.signup.error);
          }
        }
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  const isLoading =
    signUpMutation.loading || updateUser.loading || meQueryLoading;

  const selectedPlan = plansArray.find((sp) => {
    return sp.type === newAccount?.type;
  });

  return (
    <Container>
      <AngleBox color="primary" marginTop="large" />
      <Main>
        <ProgressBar currentStage="personalDetails" />
        <Content>
          <Left>
            <div>
              <Heading>Create Account</Heading>
            </div>
          </Left>
          <Right>
            <Box>
              <AngleBox color="secondary" marginTop="small" height="tiny" />
              <H3 className="heading">Personal Details</H3>
              <div className="content">
                <Span className="quote">
                  Please fill in your details below to create account
                </Span>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    ref={register}
                    label="First Name"
                    name="firstName"
                    errorText={errors.firstName?.message}
                  />
                  <SelectList
                    control={control}
                    name="position"
                    label="Industry Position"
                    options={mockData.jobPositions}
                    errorText={errors.position?.message}
                    defaultValue={''}
                  />
                  <Input
                    ref={register}
                    label="Email"
                    name="email"
                    errorText={errors.email?.message}
                  />
                  <Input
                    ref={register}
                    label="Password"
                    name="newPassword"
                    type="password"
                    errorText={errors.newPassword?.message}
                  />
                </Form>
                <RegisterButton
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  color="primary"
                  disabled={isLoading}
                >
                  <DotsLoading
                    text={(loading) => (loading ? 'Loading' : 'Create Account')}
                    isLoading={isLoading}
                    size="small"
                    lineHeight={10}
                    color="default"
                    noMargin
                  />
                </RegisterButton>
              </div>
            </Box>
          </Right>
        </Content>
        {selectedPlan && (
          <Content>
            <SelectedPlan {...selectedPlan} />
          </Content>
        )}
      </Main>
    </Container>
  );
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  position: Yup.string().required('Industry Position is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Please check email format'),
  newPassword: Yup.string().required('Password is required'),
});

export default GetStarted;
