import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripe =
  process.env.REACT_APP_TEST_STRIPE === 'true'
    ? process.env.REACT_APP_TEST_STRIPE_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const stripePromise = loadStripe(stripe!);

export default function CardFieldWrapper({ children }) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}
