import React, { FC, useState } from 'react';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Card, Content, Header } from '../../../../shared/card';
import { Apple } from '../../../../shared/icons';
import { HighlightsItem, HighlightsRow } from './Ingredients.styles';
import { Button } from '../../../../shared/button';
import { IStatsProps } from '../dashboard.types';

export const Ingredients: FC<IStatsProps> = ({ stats }) => {
  const [showIngredientList, setShowIngredientList] = useState(false);

  const ctaButtons = (
    <Button
      color="default"
      inversed
      onClick={() => setShowIngredientList(!showIngredientList)}
    >
      Monthly Stats
    </Button>
  );

  return (
    <Card>
      <Header
        icon={<Apple size="small" />}
        heading="Ingredients"
        subHeading={'Total Ingredients: ' + stats?.totalIngredients}
        button={ctaButtons}
      />
      <Content fullWidth>
        <HighlightsRow>
          <HighlightsItem>
            <Span>{stats?.totalBrands}</Span>
            <Span>Brands</Span>
          </HighlightsItem>
          <HighlightsItem>
            <Span>{stats?.totalSuppliers}</Span>
            <Span>Suppliers</Span>
          </HighlightsItem>
          <HighlightsItem>
            <Span>{stats?.totalNewIngredients}</Span>
            <Span>Recently Added</Span>
          </HighlightsItem>
        </HighlightsRow>
      </Content>
    </Card>
  );
};

export default Ingredients;
