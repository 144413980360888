import Papa from 'papaparse';
import { getTodaysDate } from '.';

export const parseCsvFile: (file: File) => Promise<any> = (file: File) =>
  new Promise((resolve) =>
    Papa.parse(file, {
      header: true,
      complete: resolve,
    })
  );

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatFilename = (filename) => {
  const todayDateTime = getTodaysDate('-', true);
  const randomString = Math.random().toString(36).substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
  const newFilename = `images/${todayDateTime}-${randomString}-${cleanFileName}`;
  const name = newFilename.substring(0, 60);
  return name;
};

export const formatS3ImageFilename = (url: string, createKey = false) => {
  const formatName = url.split('/images/')[1];
  const fileKey = `images/${formatName}`;
  return createKey ? fileKey : `/${fileKey}.jpg`;
};
