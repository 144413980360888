import styled from 'styled-components/macro';
import { Button } from '../../shared/button';
import { H2, Span } from '../../shared/typefaces/Typefaces.styles';

export const Container = styled.section`
  padding-top: 200px;
`;

export const Heading = styled(H2)`
  text-align: center;
  margin-bottom: 30px !important;
`;

export const Form = styled.form`
  width: 300px;
  margin: auto;
`;

export const ResetPasswordButton = styled(Button)`
  float: right;
  margin-top: 30px;
`;

export const Error = styled(Span)`
  text-align: center;
  display: block;
  margin-bottom: 30px;
  color: red;
`;
