import styled from 'styled-components/macro';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white.default};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.smallBox};
  width: 100%;
  max-width: 1050px;
  margin: 0 auto 20px;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.backgroundFaded};
  padding: 20px;
  cursor: pointer;

  .question {
    font-size: ${({ theme }) => theme.fontSize.default};
  }

  .arrowIcon {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    margin-left: 20px;
  }

  .arrow {
    flex-shrink: 0;
  }
`;

export const Content = styled.div`
  padding: 20px;
`;
