import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import {
  AccountType,
  SubscriptionStatus,
} from '../../../../../generated/graphql';
import { getAccountTypeColor } from '../../../selectedAccount/sections/planOption/PlanOption.styles';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

export interface ITypeProps {
  type: AccountType;
}

export interface IStatusProps {
  status: SubscriptionStatus;
}

export const getAccountStatusColor = (
  theme: any,
  status?: SubscriptionStatus
) => {
  switch (status) {
    case SubscriptionStatus.SubscriptionActive:
      return theme.colors.secondary.default;
    case SubscriptionStatus.SubscriptionPending:
      return theme.colors.default.light;
    default:
      return theme.colors.default.light;
  }
};

export const Container = styled.section`
  margin-bottom: 30px;

  .loadingDots {
    margin-top: 50px;
  }
`;

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 15px;

  .listGroup {
    padding: 0 !important;
  }

  .listGroupRow {
    grid-template-columns: 130px auto;
    height: auto;
    min-height: 40px;

    span {
      line-height: ${({ theme }) => theme.lineHeight.medium};
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 15px;
    grid-gap: 30px;
    margin-left: 60px;
  }
`;

export const StyledSpan = styled(Span)<ITypeProps>`
  color: ${({ type, theme }) => getAccountTypeColor(theme, type)};
`;

export const StatusSpan = styled(Span)<IStatusProps>`
  background-color: ${({ status, theme }) =>
    getAccountStatusColor(theme, status)};
  width: 150px;
  height: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${({ status, theme }) =>
    status === SubscriptionStatus.SubscriptionActive
      ? theme.colors.white.default
      : theme.colors.text.default};
  border-radius: 30px;
`;
