import React, { useEffect, useContext } from 'react';
import { Container, Logo, Heading } from './NotFound.styles';
import { Button } from '../../shared/button';
import { useHistory } from 'react-router-dom';
import logo from '../../../images/logo-min.png';
import {
  setAccessToken,
  getAccessTokenState,
} from '../../../utils/accessToken';
import { useLogoutMutation } from '../../../generated/graphql';
import { LayoutContext } from '../../../contexts/layoutContext';

const NotFound = () => {
  const accessToken = getAccessTokenState().token;
  const { dispatch } = useContext(LayoutContext);
  const history = useHistory();
  const [logout, { client }] = useLogoutMutation();

  useEffect(() => {
    if (accessToken) {
      try {
        const logoutUser = async () => {
          const response = await logout();
          if (response.data?.logout.successful) {
            setAccessToken('');
            window.localStorage.clear();
            await client?.resetStore();
            dispatch({
              type: 'RESET_STORE',
              payload: {
                justLoggedOut: true,
              },
            });
          }
        };

        logoutUser();
      } catch (err) {
        console.log(err);
      }
    }
  }, [accessToken, client, dispatch, logout]);

  return (
    <Container>
      <Logo src={logo} alt="logo" />
      <Heading>Page Not Found</Heading>
      <Button color="secondary" onClick={() => history.push('/')}>
        Homepage
      </Button>
    </Container>
  );
};

export default NotFound;
