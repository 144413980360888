import React, { FC, useContext, useRef } from 'react';
import { Container, Inner, Recommendation } from './Recommendations.styles';
import { Card, Content, Header } from '../../../../shared/card';
import { RecipeBook } from '../../../../shared/icons';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  RecipesQuery,
  RecipesQueryVariables,
  useVenueProfitTrendQuery,
} from '../../../../../generated/graphql';
import { DotsLoading } from '../../../../shared/loading';
import { H4, Span } from '../../../../shared/typefaces/Typefaces.styles';
import * as ApolloClient from '@apollo/client';
import {
  getRecipesThatNeedMoreSales,
  getRecipesTheNeedMoreProfit,
  getRecipesToDrop,
} from '../../utils';
import { convertCostCleanly, isEmpty } from '../../../../../utils/helper';
import { useHistory } from 'react-router-dom';

interface RecommendationsProps {
  data: ApolloClient.QueryResult<RecipesQuery, RecipesQueryVariables>;
  withCardLink?: boolean;
}

interface IHandleEditRecipeProps {
  id: string;
  salesPricePerServe: number;
  weeklySalesPerServe: number;
}

export const Recommendations: FC<RecommendationsProps> = ({
  data,
  withCardLink = false,
}) => {
  const { newRecipe, selectedVenueObject, dispatch } = useContext(
    LayoutContext
  );
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const venueProfitTrend = useVenueProfitTrendQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const isLoading = venueProfitTrend.loading || data.loading;
  const madeProgress = data.data?.venueRecipes.recipes.length !== 0;

  if (!madeProgress) {
    return null;
  }

  const recipesThatNeedMoreSales = getRecipesThatNeedMoreSales({
    data,
  });
  const recipesTheNeedMoreProfit = getRecipesTheNeedMoreProfit({
    data,
  });
  const recipesToDrop = getRecipesToDrop({ data });

  const handleEditRecipe = ({
    id,
    salesPricePerServe,
    weeklySalesPerServe,
  }: IHandleEditRecipeProps) => {
    dispatch({ type: 'SELECT_RECIPE', payload: id });
    dispatch({ type: 'SELECT_RECIPE_VERSION', payload: '' });
    if (newRecipe) {
      dispatch({
        type: 'SET_NEW_RECIPE',
        payload: false,
      });
    }
    if (salesPricePerServe === 0 && weeklySalesPerServe === 0) {
      dispatch({
        type: 'SET_NEW_RECIPE',
        payload: true,
      });
    }

    if (
      !isEmpty(data.data?.venueRecipes.recipes) &&
      data.data?.venueRecipes.recipes!.length === 1
    ) {
      dispatch({
        type: 'RESET_ALL_TOOL_TIPS',
      });
    }
    history.push('/recipe-results');
  };

  return (
    <Container>
      <Card
        ref={pageWidthRef}
        className="journeyCard"
        withCardLink={withCardLink}
      >
        <Header
          icon={<RecipeBook size="small" />}
          heading={'Recommendations'}
          subHeading="Quick overview on what to focus on next.."
        />
        <Content fullWidth>
          <DotsLoading
            isLoading={isLoading}
            size="large"
            lineHeight={10}
            color="default"
          />
          <Inner>
            <Recommendation>
              <H4>
                <Span fontWeight="bold">Highest Sales: </Span>
                <Span fontWeight="regular" color="grey">
                  Recipes that need more profit
                </Span>
              </H4>
              {recipesTheNeedMoreProfit.map((r, idx) => (
                <Span className="recipeTitle">
                  <Span color="faded">{idx + 1}. </Span>
                  <Span
                    className="recipeLink"
                    onClick={() =>
                      handleEditRecipe({
                        id: r.id,
                        weeklySalesPerServe: r.weeklySalesPerServe,
                        salesPricePerServe: r.salesPricePerServe,
                      })
                    }
                  >
                    {r.displayName}:
                  </Span>
                  <Span color="faded" fontSize="small">
                    {' '}
                    {convertCostCleanly(r.recipeProfit / r.serves)} profit per
                    serve
                  </Span>
                </Span>
              ))}
            </Recommendation>
            <Recommendation>
              <H4>
                <Span fontWeight="bold">Most Profitable: </Span>
                <Span fontWeight="regular" color="grey">
                  Recipes that need more sales
                </Span>
              </H4>
              {recipesThatNeedMoreSales.map((r, idx) => (
                <Span className="recipeTitle">
                  <Span color="faded">{idx + 1}. </Span>
                  <Span
                    className="recipeLink"
                    onClick={() =>
                      handleEditRecipe({
                        id: r.id,
                        weeklySalesPerServe: r.weeklySalesPerServe,
                        salesPricePerServe: r.salesPricePerServe,
                      })
                    }
                  >
                    {r.displayName}:
                  </Span>
                  <Span color="faded" fontSize="small">
                    {' '}
                    {convertCostCleanly(r.recipeProfit / r.serves)} profit per
                    serve
                  </Span>
                </Span>
              ))}
            </Recommendation>

            <Recommendation>
              <H4>
                <Span fontWeight="bold">Less Helpful: </Span>
                <Span fontWeight="regular" color="grey">
                  Recipes that have the least profit & sales
                </Span>
              </H4>
              {recipesToDrop.map((r, idx) => (
                <Span className="recipeTitle">
                  <Span color="faded">{idx + 1}. </Span>
                  <Span
                    className="recipeLink"
                    onClick={() =>
                      handleEditRecipe({
                        id: r.id,
                        weeklySalesPerServe: r.weeklySalesPerServe,
                        salesPricePerServe: r.salesPricePerServe,
                      })
                    }
                  >
                    {r.displayName}:
                  </Span>
                  <Span color="faded" fontSize="small">
                    {' '}
                    {convertCostCleanly(r.recipeProfit / r.serves)} profit per
                    serve
                  </Span>
                </Span>
              ))}
            </Recommendation>
          </Inner>
        </Content>
      </Card>
    </Container>
  );
};
