import React, { FC } from 'react';
import { Bin } from '../../icons';
import { PreviewContainer, ImageDeleteButton } from './ImageUpload.styles';

interface IImagePreviewProps {
  src: string;
  name: string;
  onDelete: (e: any) => void;
}

export const ImagePreview: FC<IImagePreviewProps> = ({
  src,
  name,
  onDelete,
}) => {
  return (
    <PreviewContainer className="thumb position-relative" key={name}>
      <img src={src} alt={name} />
      <ImageDeleteButton onClick={(e) => onDelete(e)} asCircle color="primary">
        <Bin size="small" color="white" />
      </ImageDeleteButton>
    </PreviewContainer>
  );
};
