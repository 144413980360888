import React, { FC } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import { Container } from './RecipesProfitPopularityGraph.styles';
import { theme } from '../../../../../styles/theme';
import {
  RecipesQuery,
  RecipesQueryVariables,
} from '../../../../../generated/graphql';
import { convertCostCleanly } from '../../../../../utils/helper';
import * as ApolloClient from '@apollo/client';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { roundToWholeNumber } from '../../../../../utils/helper/numbers';

interface IRecipesProfitPopularityGraphProps {
  data: ApolloClient.QueryResult<RecipesQuery, RecipesQueryVariables>;
}

interface IVenueRecipeTrendProps {
  totalActiveSeconds: number;
  recipeProfitIncreasePerYear?: number;
  createdAt?: string;
}

const venueRecipeTrend: IVenueRecipeTrendProps[] = [
  {
    totalActiveSeconds: 3600,
    recipeProfitIncreasePerYear: 100,
    createdAt: '1716411037901',
  },
  {
    totalActiveSeconds: 7200,
    recipeProfitIncreasePerYear: 300,
    createdAt: '1716411037901',
  },
];

let totalSeconds = 0;
for (let index = 0; index < 5; index++) {
  const element = venueRecipeTrend[index];
  if (element) {
    totalSeconds = element.totalActiveSeconds;
  } else {
    totalSeconds = totalSeconds + 3600;
    venueRecipeTrend.push({
      totalActiveSeconds: totalSeconds,
    });
  }
}

// Custom tooltip component
const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: theme.colors.white.default,
          border: `1px solid ${theme.colors.default.default}`,
          padding: '10px',
        }}
      >
        <Span fontSize="small" fontWeight="medium" color="grey">
          Name: {payload[0].payload.name}
        </Span>
        <br />
        <Span fontSize="small" fontWeight="medium">
          Total sales: {roundToWholeNumber(payload[0].value || 0)}
        </Span>
        <br />
        <Span className="intro" fontWeight="medium">
          {convertCostCleanly(payload[1].value || 0)}
          <Span className="label" color="faded">{` ${payload[1].name}`}</Span>
        </Span>
      </div>
    );
  }

  return null;
};

export const RecipesProfitPopularityGraph: FC<IRecipesProfitPopularityGraphProps> = ({
  data,
}) => {
  const recipesData = data.data?.venueRecipes.recipes.map((r) => {
    const profitPerServe = r.recipeProfit / r.serves;
    return {
      name: r.displayName,
      profit: profitPerServe,
      weeklyProfit: profitPerServe * r.weeklySalesPerServe,
      weeklySales: r.weeklySalesPerServe,
    };
  });

  return (
    <Container>
      <ResponsiveContainer width="100%" aspect={16 / 5}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <YAxis
            type="number"
            dataKey="weeklyProfit"
            name="Profit"
            tickFormatter={(value) => `$${value}`}
          />
          <XAxis type="number" dataKey="weeklySales" name="Sales" />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<CustomTooltip />}
          />
          <Scatter
            name="Popularity vs Profitability"
            data={recipesData}
            fill={theme.colors.secondary.dark}
            style={{
              border: '1px solid grey',
            }}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Container>
  );
};
