import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

interface IContainerProps {
  addTableRows?: number;
  addMobileTableRows?: number;
}
interface IItemProps {
  noWrap?: boolean;
}

interface ILabelProps {
  isLabel: boolean;
}

export const Container = styled.div<IContainerProps>`
  margin-top: ${({ addMobileTableRows }) =>
    addMobileTableRows ? 60 + 30 * addMobileTableRows : 60}px;

  @media ${device.laptopL} {
    margin-bottom: ${({ addTableRows }) =>
      addTableRows ? 30 * addTableRows : 0}px;
  }
`;

export const Heading = styled.div`
  height: 20px;
  margin-bottom: 10px;

  .heading {
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    /* margin-bottom: 5px; */
  }
`;

export const Item = styled.div<IItemProps>`
  width: ${({ noWrap }) => (noWrap ? '275px' : '110px')};
  text-align: ${({ noWrap }) => (noWrap ? 'left' : 'center')};
  line-height: ${({ theme }) => theme.lineHeight.heading};
  color: ${({ theme, noWrap }) =>
    noWrap ? theme.colors.text.grey : theme.colors.text.default};

  @media ${device.laptopL} {
    width: ${({ noWrap }) => (noWrap ? '250px' : '150px')};
  }
`;

export const Label = styled.div<ILabelProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isLabel }) => (isLabel ? 'space-between' : 'center')};
  align-items: center;
  height: 30px;
  cursor: ${({ isLabel }) => (isLabel ? 'pointer' : 'default')};

  .label {
    font-size: ${({ isLabel, theme }) =>
      isLabel ? theme.fontSize.default : theme.fontSize.button};
  }

  .icon {
    cursor: pointer;
  }

  .content {
    height: 30px;
  }

  @media ${device.laptopL} {
    .label {
      font-size: ${({ theme }) => theme.fontSize.default};
    }
  }
`;
