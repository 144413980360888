import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { theme } from '../../../../../styles/theme';
import {
  getFieldArrayErrorMessage,
  isEmpty,
  roundTo,
  sortArrayObjects,
} from '../../../../../utils/helper';
import { RowIcon, RowTotal } from './Items.styles';
import { StocktakeSectionRowArgs } from './items.types';
import { IInitialSelectListOptionProps } from '../../../../shared/formElements/selectList/selectList.types';
import { Input, SelectList } from '../../../../shared/formElements';
import { Apple } from '../../../../shared/icons';
import { RowMenu } from '../rowMenu';

export function getFormRow({
  item,
  index,
  ingredients,
  appWidth,
  errors,
  disableIngredient,
  control,
  handleDelete,
  handleIngredientChange,
}: StocktakeSectionRowArgs) {
  const quantity = Number(item.quantity);
  const unit = Number(item.unit);
  const label = 'Ingredient name';
  const total = quantity * unit || 0;
  let ingredientOptions: IInitialSelectListOptionProps[] = [];
  let mergedOptions: IInitialSelectListOptionProps[] = [];

  if (ingredients) {
    ingredientOptions =
      ingredients?.map((ingredient) => {
        return {
          label: ingredient.displayName,
          value: ingredient.id,
          extra: 'ingredient',
        };
      }) || [];

    mergedOptions = sortArrayObjects([...ingredientOptions], 'label');
  }

  const handleItemDelete = () => {
    handleDelete(item, index);
  };

  switch (item.__typename) {
    case 'StocktakeSectionIngredient':
      return {
        id: item.rowKey,
        hiddenType: (
          <Fragment>
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].rowKey`}
              defaultValue={item.rowKey}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].type`}
              defaultValue={'INGREDIENT'}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].stocktakeSectionItemId`}
              defaultValue={item.stocktakeSectionItemId ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].ingredientProductId`}
              defaultValue={item.ingredientProductId ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].order`}
              defaultValue={item.order ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].__typename`}
              defaultValue={item.__typename}
            />
          </Fragment>
        ),
        icon:
          appWidth < theme.mQ.laptop ? (
            <Fragment />
          ) : (
            <RowIcon>
              <Apple className="icon" faded={disableIngredient} size="small" />
            </RowIcon>
          ),
        name: (
          <SelectList
            className="rowInput"
            autoComplete
            control={control}
            name={`items[${index}].ingredient`}
            label={label}
            handleChange={handleIngredientChange}
            errorText={getFieldArrayErrorMessage(
              'ingredient',
              index,
              errors.items
            )}
            options={mergedOptions}
            defaultValue={item.ingredient || null}
            disabled={disableIngredient}
          />
        ),
        item1: (
          // Ingredient quantity input
          <Controller
            as={<Input />}
            type="number"
            className="rowInput"
            label={appWidth < theme.mQ.laptop ? 'Quanity' : ''}
            align={appWidth < theme.mQ.laptop ? 'left' : 'right'}
            name={`items[${index}].quantity`}
            control={control}
            placeholder="Amount"
            defaultValue={
              isEmpty(item.quantity)
                ? ''
                : `${roundTo(Number(item.quantity), 3)}`
            }
            errorText={getFieldArrayErrorMessage(
              'quantity',
              index,
              errors.items
            )}
            disabled={disableIngredient}
          />
        ),
        item2: (
          <Controller
            as={<Input />}
            type="number"
            className="rowInput"
            label={appWidth < theme.mQ.laptop ? 'Unit' : ''}
            align={appWidth < theme.mQ.laptop ? 'left' : 'right'}
            name={`items[${index}].unit`}
            control={control}
            placeholder="Unit Amount"
            defaultValue={
              isEmpty(item.unit) ? '1' : `${roundTo(Number(item.unit), 3)}`
            }
            errorText={getFieldArrayErrorMessage('unit', index, errors.items)}
            disabled={disableIngredient}
          />
        ),
        item3: <RowTotal>{total === 0 ? '-' : total}</RowTotal>,
        item4: (
          <Controller
            as={<Input />}
            type="number"
            className="rowInput"
            label={appWidth < theme.mQ.laptop ? 'Required' : ''}
            align={appWidth < theme.mQ.laptop ? 'right' : 'right'}
            name={`items[${index}].required`}
            control={control}
            placeholder="Required"
            defaultValue={
              isEmpty(item.required)
                ? ''
                : `${roundTo(Number(item.required), 3)}`
            }
            errorText={getFieldArrayErrorMessage(
              'required',
              index,
              errors.items
            )}
            disabled={disableIngredient}
          />
        ),
        item5: (
          <Controller
            as={<Input />}
            className="rowInput"
            label={appWidth < theme.mQ.laptop ? 'Unit Type' : ''}
            align={appWidth < theme.mQ.laptop ? 'right' : 'right'}
            name={`items[${index}].unitType`}
            control={control}
            placeholder="Kg"
            defaultValue={isEmpty(item.unitType) ? '' : item.unitType}
            errorText={getFieldArrayErrorMessage(
              'unitType',
              index,
              errors.items
            )}
            disabled={disableIngredient}
          />
        ),
        item6: (
          <RowMenu
            ingredientId={item.ingredient?.value}
            ingredientProductId={item.ingredientProductId}
            disabled={disableIngredient}
            handleItemDelete={handleItemDelete}
          />
        ),
      };

    case 'StocktakeSectionItemDeleted':
      return {
        id: item.rowKey,
        hiddenType: (
          <Fragment>
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].rowKey`}
              defaultValue={item.rowKey}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].type`}
              defaultValue={'DELETE_ITEM'}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].stocktakeSectionItemId`}
              defaultValue={item.stocktakeSectionItemId ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].ingredientProductId`}
              defaultValue={item.ingredientProductId ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].order`}
              defaultValue={item.order ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].__typename`}
              defaultValue={item.__typename}
            />
          </Fragment>
        ),
        icon: <Fragment />,
        name: <Fragment />,
        item1: (
          <Controller
            as={<Input />}
            control={control}
            className="hiddenInput"
            type="hidden"
            name={`items[${index}].quantity`}
            defaultValue={item.quantity}
          />
        ),
        item2: (
          <Controller
            as={<Input />}
            control={control}
            className="hiddenInput"
            type="hidden"
            name={`items[${index}].unit`}
            defaultValue={item.unit}
          />
        ),
        item3: <Fragment />,
        item4: <Fragment />,
        item5: <Fragment />,
        item6: <Fragment />,
        delete: true,
      };

    default:
      return {
        icon: <Fragment />,
        name: <Fragment />,
        item1: <Fragment />,
        item2: <Fragment />,
        item3: <Fragment />,
        item4: <Fragment />,
        item5: <Fragment />,
        item6: <Fragment />,
      };
  }
}
