import React, { memo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Input } from '../../shared/formElements';

const FormOne = () => {
  const { errors, control } = useFormContext();

  return (
    <div>
      <Controller
        as={<Input />}
        type="number"
        name="serves"
        label="Serves"
        control={control}
        errorText={errors.number && errors.number.message}
        defaultValue=""
      />
      {/* <SelectList
        autoComplete
        control={control}
        name="find"
        label="Find Ingredient Name"
        errorText={errors?.value?.message}
        ref={register}
        options={ingredients}
      />
      <Input
        ref={register}
        label="Recipe Name"
        name="name"
        errorText={errors.name && errors.name.message}
      /> */}
    </div>
  );
};

export default memo(FormOne);
