import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { Label } from '../../../../shared/typefaces/Typefaces.styles';

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};

  &:first-child {
    justify-content: flex-start;
    margin-left: 15px;
    padding-left: 0;
  }

  @media ${device.tablet} {
    &:first-child {
      justify-content: center;
      border: 0;
      margin: 0;
    }
    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 0;
    }
  }
`;

export const Row = styled.ul`
  min-height: 70px;
  display: grid;
  grid-template-columns: auto 130px 50px;
  cursor: pointer;

  &:first-child {
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 40px;
    ${Col} {
      border: 0;
    }
    cursor: default;
  }
  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundFaded};
    &:last-child {
      border-radius: 0 0 15px 15px;
    }
  }

  @media ${device.mobileL} {
    grid-template-columns: auto 180px 130px 50px;
  }
  @media ${device.tablet} {
    grid-template-columns: 70px auto 180px 130px 50px;
  }
`;

export const Icon = styled.div`
  height: 50px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const MenuItems = styled(Label)`
  display: block;
  margin-top: 5px;
`;
