import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const SaladMix: React.FC<IIconProps> = ({ size, faded }) => {
  return (
    <IconStyled
      size={size}
      faded={faded}
      version="1.1"
      id="Capa_1"
      viewBox="0 0 512 512"
    >
      <path
        style={{ fill: '#E5E5E5' }}
        d="M338.441,507.68H182.237c-95.857,0-173.559-77.703-173.559-173.559H512
	C512,429.978,434.298,507.68,338.441,507.68z"
      />
      <path
        style={{ fill: '#88B337' }}
        d="M406.389,91.138h-7.203l22.806-22.806c13.911-13.46,15.447-35.241,3.558-50.514
	c-12.904-15.724-36.118-17.998-51.833-5.094c-0.929,0.764-1.822,1.571-2.673,2.421L347.119,39.07v-7.203
	c0.312-14.223-10.466-26.26-24.637-27.509c-14.353-0.772-26.624,10.24-27.396,24.602c-0.026,0.477-0.035,0.955-0.035,1.432v43.39
	c0.017-14.379-11.62-26.051-25.999-26.069c-0.477,0-0.955,0.009-1.432,0.035c-14.171,1.258-24.949,13.286-24.637,27.509v41.915
	c0.017-14.379-11.62-26.051-25.999-26.069c-0.477,0-0.955,0.009-1.432,0.035c-14.171,1.25-24.949,13.286-24.637,27.509v128.694
	h147.525c14.379,0,26.034-11.655,26.034-26.034l0,0c0-14.379-11.655-26.034-26.034-26.034h41.915
	c14.223,0.312,26.26-10.466,27.509-24.637c0.772-14.353-10.24-26.624-24.602-27.396c-0.477-0.026-0.955-0.035-1.432-0.035h26.034
	c14.379,0.017,26.051-11.62,26.069-25.999c0-0.477-0.009-0.955-0.035-1.432C432.649,101.603,420.612,90.825,406.389,91.138z"
      />
      <g>
        <polygon
          style={{ fill: '#6B962A' }}
          points="387.966,62.561 375.695,50.291 338.441,87.545 338.441,65.104 321.085,65.104
		321.085,104.901 286.373,139.613 286.373,108.494 269.017,108.494 269.017,156.969 234.305,191.681 234.305,151.884
		216.949,151.884 216.949,209.037 132.712,293.274 144.983,305.544 229.22,221.307 286.373,221.307 286.373,203.951
		246.576,203.951 281.288,169.239 329.763,169.239 329.763,151.884 298.644,151.884 333.355,117.172 373.153,117.172
		373.153,99.816 350.711,99.816 	"
        />
        <path
          style={{ fill: '#6B962A' }}
          d="M101.671,143.206c-23.951-0.026-43.364-19.439-43.39-43.39v-4.339l-7.602-5.701
		C33.046,76.55,29.47,51.523,42.696,33.889s38.252-21.209,55.886-7.984l3.089,2.317l3.081-2.317
		c17.634-13.234,42.661-9.659,55.886,7.975c0.443,0.59,0.876,1.198,1.284,1.814l0,0c11.75,17.634,7.689,41.368-9.268,54.081
		l-7.593,5.701v4.339C145.035,123.767,125.622,143.18,101.671,143.206z M74.709,35.269c-1.302,0-2.595,0.113-3.879,0.338
		c-6.127,1.015-11.55,4.539-14.969,9.719c-6.647,9.954-4.365,23.37,5.207,30.564l11.064,8.305c2.187,1.64,3.471,4.209,3.471,6.942
		v8.678c0,14.379,11.655,26.034,26.034,26.034s26.034-11.655,26.034-26.034v-8.678c0-2.734,1.284-5.302,3.471-6.942l11.064-8.305
		c9.971-7.48,11.993-21.617,4.513-31.588s-21.617-11.993-31.588-4.513l-8.287,6.222c-3.089,2.317-7.324,2.317-10.414,0l-8.296-6.222
		C84.272,36.866,79.56,35.278,74.709,35.269z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#DE4C3C' }}
          d="M468.61,117.172V99.816c9.589,0,17.356-7.767,17.356-17.356s-7.767-17.356-17.356-17.356V47.748
		c19.17,0,34.712,15.542,34.712,34.712S487.78,117.172,468.61,117.172z"
        />
        <rect
          x="190.915"
          y="4.358"
          style={{ fill: '#DE4C3C' }}
          width="17.356"
          height="17.356"
        />
        <rect
          x="242.983"
          y="282.053"
          style={{ fill: '#DE4C3C' }}
          width="17.356"
          height="17.356"
        />
        <rect
          x="17.356"
          y="125.85"
          style={{ fill: '#DE4C3C' }}
          width="17.356"
          height="17.356"
        />
        <rect
          y="91.138"
          style={{ fill: '#DE4C3C' }}
          width="17.356"
          height="17.356"
        />
      </g>
      <path
        style={{ fill: '#FDB62F' }}
        d="M373.153,308.087h-17.356c0-9.589-7.767-17.356-17.356-17.356s-17.356,7.767-17.356,17.356h-17.356
	c0-19.17,15.542-34.712,34.712-34.712S373.153,288.917,373.153,308.087z"
      />
      <path
        style={{ fill: '#88B337' }}
        d="M104.136,203.951h-8.678c0-14.379-11.655-26.034-26.034-26.034S43.39,189.572,43.39,203.951h-8.678
	c-14.379,0-26.034,11.655-26.034,26.034c0,14.379,11.655,26.034,26.034,26.034h69.424c14.379,0,26.034-11.654,26.034-26.034
	C130.169,215.606,118.515,203.951,104.136,203.951z"
      />
      <rect
        x="52.068"
        y="256.019"
        style={{ fill: '#638724' }}
        width="34.712"
        height="34.712"
      />
      <path
        style={{ fill: '#E5E5E5' }}
        d="M459.932,195.273c-28.759,0-52.068,23.309-52.068,52.068s23.309,52.068,52.068,52.068
	c28.759,0,52.068-23.309,52.068-52.068C512,218.582,488.691,195.273,459.932,195.273z M459.932,273.375
	c-14.379,0-26.034-11.655-26.034-26.034c0-14.379,11.654-26.034,26.034-26.034c14.379,0,26.034,11.655,26.034,26.034
	C485.966,261.721,474.312,273.375,459.932,273.375z"
      />
      <path
        style={{ fill: '#DEDEDE' }}
        d="M392.018,334.121c-68.556,90.581-169.316,141.746-246.741,169.524
	c12.14,2.664,24.533,4.018,36.959,4.035h156.203C434.297,507.68,512,429.978,512,334.121H392.018z"
      />
    </IconStyled>
  );
};

export default SaladMix;
