import styled from 'styled-components/macro';
import { IContainerProps, IHeadingsProps, IIconProps } from './header.types';
import { H4 } from '../../typefaces/Typefaces.styles';
import { ButtonStyled } from '../../button/Button.styles';

const info =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20px' height='20px' viewBox='0 0 60 60'  style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath style='opacity:0.984' fill='%2310b561' d='M 25.5,2.5 C 51.9379,2.43538 63.1046,15.4354 59,41.5C 49.913,59.3667 35.913,64.7001 17,57.5C 5.16667,61.6667 1.33333,57.8333 5.5,46C -1.67997,24.871 4.98669,10.371 25.5,2.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23f7fcf9' d='M 28.5,15.5 C 35.4883,14.6478 37.8216,17.4811 35.5,24C 26.8377,26.5009 24.5043,23.6675 28.5,15.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23fbfdfc' d='M 29.5,28.5 C 31.9363,28.317 34.103,28.9837 36,30.5C 36.6667,35.8333 36.6667,41.1667 36,46.5C 33,49.1667 30,49.1667 27,46.5C 26.3333,41.1667 26.3333,35.8333 27,30.5C 27.9947,29.9341 28.828,29.2674 29.5,28.5 Z'/%3E%3C/g%3E%3C/svg%3E";

export const Container = styled.section<IContainerProps>`
  display: grid;
  grid-template-columns: ${({ icon }) =>
    icon ? 'minmax(50px, 75px) auto 1fr' : 'auto 1fr'};
  padding-top: ${({ theme }) => theme.layout.padding};
  padding-right: ${({ theme }) => theme.layout.padding};
  padding-left: ${({ icon, theme }) => (icon ? 0 : theme.layout.padding)};
  padding-bottom: ${({ theme, addPaddingBottom }) =>
    addPaddingBottom ? theme.layout.padding : 0};
`;

export const Icon = styled.div<IIconProps>`
  border: 1px solid
    ${({ error, theme }) => (error ? theme.colors.error.default : '#e0e0e0')};
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Headings = styled.div<IHeadingsProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .CardHeaderHeading {
    line-height: 40px;
  }

  .error {
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.text.error};
  }

  &:hover {
    &.hasToolTip {
      cursor: pointer;
      position: relative;

      .heading {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          background-color: ${({ theme, icon = true }) =>
            icon ? 'transparent' : theme.colors.secondary.default};
          background-image: ${({ theme, icon = true }) =>
            icon
              ? `url(${`"${info}"`})`
              : `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
          width: ${({ icon = true }) => (icon ? '20px' : '8px')};
          height: ${({ icon = true }) => (icon ? '20px' : '8px')};
          border-radius: ${({ icon = true }) => (icon ? '0' : '8px')};
          top: ${({ icon = true }) => (icon ? '-5px' : '-5px')};
          right: -10px;
        }
      }
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  ${ButtonStyled} {
    margin-left: 15px;
  }
`;

export const Heading = styled(H4)`
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 15px;
`;
