import React, { FC, useContext } from 'react';
import { Card, Header } from '../../../../shared/card';
import { Salad, Share } from '../../../../shared/icons';
import { ITargetFoodMarginExceededProps } from './TargetFoodMarginExceeded.types';
import * as S from './TargetFoodMarginExceeded.styles';
import { convertPercentageCleanly } from '../../../../../utils/helper';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { Button } from '../../../../shared/button';
import { theme } from '../../../../../styles/theme';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

export const TargetFoodMarginExceeded: FC<ITargetFoodMarginExceededProps> = ({
  recipeData,
  venue,
}) => {
  const { appWidth, account, user, dispatch } = useContext(LayoutContext);
  const targetFoodMargin = venue.data?.venue?.userVenue?.venue.targetFoodMargin;

  if (targetFoodMargin === null || targetFoodMargin === undefined) {
    return null;
  }

  if (recipeData.foodCostPercentage <= targetFoodMargin) {
    return null;
  }

  const updateVenue = () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountUpdateVenueCosts,
      });
    } else {
      dispatch({ type: 'SLIDER_PAGE', payload: 'venue' });
    }
  };

  return (
    <S.Container>
      <Card withAlert withCardLink className="card">
        <Header
          icon={<Salad size="small" />}
          heading="Exceeding Recipe Target Margin"
          subHeading={`Your current recipe version "${
            recipeData.versionName
          }" Food Cost is ${convertPercentageCleanly(
            recipeData.foodCostPercentage,
            0
          )}. It is exceeding your target margin of ${convertPercentageCleanly(
            targetFoodMargin,
            0
          )}`}
          button={
            appWidth !== 0 && appWidth < theme.mQ.tablet ? (
              <Button color="primary" asCircle onClick={updateVenue}>
                <Share color="white" size="small" />
              </Button>
            ) : (
              <Button color="default" inversed onClick={updateVenue}>
                Edit Venue
              </Button>
            )
          }
        />
      </Card>
    </S.Container>
  );
};

export default TargetFoodMarginExceeded;
