import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  margin-bottom: 30px;
  padding: ${({ theme }) => theme.layout.pagePadding};

  @media ${device.mobileM} {
    grid-template-columns: auto;
  }

  .headerLabel {
    margin-top: 10px;
    @media ${device.tablet} {
      margin-top: 15px;
    }
  }

  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const HeadingsItem = styled.li`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 15px;

  h2 {
    @media ${device.tablet} {
      padding-right: 15px;
    }
  }
`;

export const Headings = styled.ul`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto auto;

  ${HeadingsItem} {
    &:first-child {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
      @media ${device.mobileM} {
        text-align: right;
      }
    }

    &:last-child {
      text-align: right;
    }
  }

  @media ${device.mobileM} {
    grid-template-columns: 1fr auto auto;
  }
`;
