import { RecipeFormItem } from '../../components/shared/recipe/recipe.types';
import {
  CreateItemInput,
  IngredientMetricUnit,
  ItemType,
  RecipeMetricUnit,
  TimeUnitType,
} from '../../generated/graphql';
import { isDefinedAndNotNullFilter } from './arrays';
import { capitalizeFirstLetter, toTitleCase } from './strings';
import { getTotalTimeSeconds } from './units';

/**
 * Converts the RecipeFormState.items shape into a list of items
 */
export function convertFormItemsToInput(
  items: RecipeFormItem[]
): CreateItemInput[] {
  return items.map(convertFormItemToInput).filter(isDefinedAndNotNullFilter);
}

/**
 * converts a recipe form item into createItemInput
 */
function convertFormItemToInput(
  item: RecipeFormItem
): CreateItemInput | undefined {
  // if it's a newly added item, the recipeItemId must be undefined, not an empty string
  // otherwise the server will try to update it
  const recipeItemId = item.recipeItemId ? item.recipeItemId : undefined;

  switch (item.type) {
    case 'INGREDIENT':
      const isNewIngredient = !item?.ingredient?.extra;

      return {
        itemType: ItemType.Ingredient,
        content: {
          recipeItemId: recipeItemId,
          newIngredientName: isNewIngredient
            ? toTitleCase(item.ingredient?.label || '')
            : undefined,
          itemId: item.ingredient?.value,
          ingredientUnit:
            IngredientMetricUnit[capitalizeFirstLetter(item.unit)],
          quantity: Number(item.quantity),
        },
      };

    case 'RECIPE':
      return {
        itemType: ItemType.Recipe,
        content: {
          recipeItemId: recipeItemId,
          itemId: item.ingredient?.value,
          recipeUnit: RecipeMetricUnit[capitalizeFirstLetter(item.unit)],
          quantity: Number(item.quantity),
        },
      };

    case 'PROCESS_TIME':
      return {
        itemType: ItemType.Time,
        content: {
          recipeItemId: recipeItemId,
          description: item.description,
          timeUnit: TimeUnitType[capitalizeFirstLetter(item.unit)],
          quantity: getTotalTimeSeconds(item.unit, Number(item.quantity)),
        },
      };

    case 'STAFF_TIME':
      return {
        itemType: ItemType.Time,
        content: {
          recipeItemId: recipeItemId,
          description: item.description,
          timeUnit: TimeUnitType[capitalizeFirstLetter(item.unit)],
          quantity: getTotalTimeSeconds(item.unit, Number(item.quantity)),
          staffTime: true,
        },
      };

    case 'DELETE_ITEM':
      return {
        itemType: ItemType.Delete,
        content: {
          recipeItemId: recipeItemId,
          deleteType: item.deleteType,
        },
      };

    default:
      return undefined;
  }
}
