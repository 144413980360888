export const isEmpty = (value: any) => {
  return (
    value === false ||
    value === undefined ||
    value === 'undefined' ||
    value === null ||
    value === 0 ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (typeof value === 'number' && isNaN(value))
  );
};

type EmptyObjWithKeys<KEYS extends string | symbol | number> = Partial<
  Record<KEYS, undefined>
>;

type AllKeys<T extends { [key: string]: any }> = T extends Record<
  infer KEYS,
  any
>
  ? KEYS
  : never;

type AddKeysToObj<
  OBJ extends {},
  KEYS extends string | symbol | number
> = OBJ extends infer U
  ? U & EmptyObjWithKeys<Exclude<KEYS, keyof OBJ>>
  : never;

export type ObjUnion<T extends {}> = AddKeysToObj<T, AllKeys<T>>;
