import styled from 'styled-components/macro';
import { IDeleteRowProps, IContainerProps } from './items.types';
import { device } from '../../../../../styles/device';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Button } from '../../../../shared/button';

export const Container = styled.div<IContainerProps>`
  .tableHeader {
    border-radius: ${({ roundCorners }) =>
      roundCorners ? '14px 14px 0 0' : '0'};
  }

  .itemsTable {
    ul {
      min-height: 80px;
      align-items: flex-start;
      &:first-child {
        min-height: 40px;
        li {
          &:nth-child(1) {
            display: flex;
          }
          @media ${device.tablet} {
            display: flex;
          }
        }
      }
    }

    li {
      .rowInput {
        padding-top: 10px;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        .rowInput {
          padding-top: 0px;
        }
      }
      @media ${device.tablet} {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          .rowInput {
            padding-top: 10px;
          }
        }
      }
    }
  }
`;

export const DotsMenuWrapper = styled.div`
  position: relative;
`;

export const AddItem = styled(Button)`
  min-width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 0;

  @media ${device.mobileM} {
    min-width: 136px;
    height: ${({ theme }) => theme.layout.buttonHeight};

    &:first-child,
    &:last-child {
      min-width: 130px;
    }
  }

  @media ${device.mobileL} {
    min-width: 150px;
    &:first-child,
    &:last-child {
      min-width: 150px;
    }
  }

  @media ${device.laptop} {
    min-width: 180px;
    &:first-child,
    &:last-child {
      min-width: 180px;
    }
  }
`;

export const RowIcon = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 26px;
  padding-bottom: 15px;

  .icon {
    fill: ${({ theme }) => theme.colors.grey.faded};
  }

  &:hover {
    .icon {
      fill: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;

export const DeleteRow = styled.div<IDeleteRowProps>`
  padding-left: 5px;
  padding-top: 26px;
  ${({ disabled }) =>
    !disabled &&
    `
  cursor: pointer;
  `}
`;

export const HeaderIcon = styled.div<IDeleteRowProps>`
  position: relative;
  padding: 0px 0px 2px 0px;
  border-radius: 85px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: ${({ showBackground, theme }) =>
    showBackground ? theme.colors.default.faded : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.default.faded};

    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const RowTotal = styled(Span)`
  padding-left: 5px;
  padding-right: 15px;

  @media ${device.tablet} {
    padding-right: 0;
    padding-top: 26px;
  }
`;
