import React, { FC } from 'react';
import { IButtonOnclickProps } from './button.types';
import { ButtonStyled } from './Button.styles';

const Button: FC<IButtonOnclickProps> = ({ children, onClick, ...props }) => {
  const handleClick = (e) => {
    onClick && onClick(e);
  };

  return (
    <ButtonStyled onClick={handleClick} {...props}>
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  onClick: null,
  type: 'button',
};

export default Button;
