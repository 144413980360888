import { useCallback, useRef } from 'react';

export function useBoxedFn<FN extends (...args: any[]) => any>(
  fn: FN
): (...args: Parameters<FN>) => ReturnType<FN> {
  const fnRef = useRef<FN>(fn);
  fnRef.current = fn;

  return useCallback((...args: Parameters<FN>): ReturnType<FN> => {
    return fnRef.current(...args);
  }, []);
}
