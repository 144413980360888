import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { Button } from '../../button';
import { Label } from '../../typefaces/Typefaces.styles';
import {
  ImageContainer,
  ImageInput,
  ImageLabel,
  ImageSize,
  Preview,
} from './ImageUpload.styles';
import {
  formatBytes,
  formatFilename,
  formatS3ImageFilename,
} from '../../../../utils/helper/file';
import { ImageCropper } from '.';
import { Point } from 'react-easy-crop/types';
import { convertToImageFile } from '../../../../utils/customHooks/cropImage';
import Compressor from 'compressorjs';
import { ImagePreview } from './ImagePreview';

// interface IImageUploadProps {
//   name: string;
//   control: Control<Record<string, any>>;
//   onSubmit: (formData: any) => Promise<void>;
//   multiple?: boolean;
//   rest?: {
//     [x: string]: any;
//   };
// }

interface ICroppedImageProps {
  crop: Point;
  croppedImageUrl: string;
  aspect?: number;
  zoom?: number;
}

const ImageUpload = (props) => {
  const {
    control,
    label,
    name,
    rules,
    multiple = false,
    maxFiles,
    setValue,
    accept,
    maxSize,
    setError,
    clearErrors,
    formGroupClassName,
    watch,
    defaultValue,
    onDelete,
  } = props;
  const [image, setImage] = useState(watch(name));
  const [croppedImage, setCroppedImage] = useState<ICroppedImageProps>();
  const [showCropper, setShowCropper] = useState(false);
  const [showDefaultValue, setShowDefaultValue] = useState(true);

  useEffect(() => {
    if (
      watch(name) !== '' &&
      typeof watch(name) === 'string' &&
      watch(name).startsWith('/')
    ) {
      const uploadedName = watch(name)
        .substr(watch(name).lastIndexOf('/') + 1)
        .substr(0, watch(name).lastIndexOf('/'));

      const formattedName = formatFilename(uploadedName);

      setImage([
        {
          preview: window.location.origin + watch(name),
          text: formattedName,
        },
      ]);
    }
  }, [watch, name]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        setValue(name, []);
        setImage([]);
        setError(name, {
          type: 'manual',
          message: rejectedFiles && rejectedFiles[0].errors[0].message,
        });
      } else {
        setImage(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        clearErrors(name);
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onabort = () => console.log('File reading was aborted');
          reader.onerror = () => console.log('File reading has failed');
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setValue(name, [file], { shouldValidate: true });
          };
          setShowCropper(true);
        });
      }
    },
    [name, setValue, setError, clearErrors]
  );

  const deleteImage = (e) => {
    e.preventDefault();
    setImage([]);
    setValue(name, null);
    onDelete();
    setShowDefaultValue(false);
  };

  const setCroppedImageFor = async (
    crop: Point,
    croppedImageUrl: string,
    aspect?: number,
    zoom?: number
  ) => {
    const newCroppedImage = {
      croppedImageUrl,
      crop,
      zoom,
      aspect,
    };

    const newImageList = [...image];
    const currentImage = image[0];
    const newImage = {
      lastModified: currentImage.lastModified,
      name: currentImage.name,
      path: currentImage.path,
      preview: croppedImageUrl,
      size: currentImage.size,
      type: currentImage.type,
      webkitRelativePath: currentImage.webkitRelativePath,
    };

    const blob = await convertToImageFile(newImage.preview, newImage.name);

    new Compressor(blob, {
      quality: 0.8,
      width: 300,
      height: 300,
      resize: 'cover',
      success(result) {
        newImageList[0] = result;
        setImage(newImageList);
        setCroppedImage(newCroppedImage);
        setShowCropper(false);
        setValue(name, newImageList, { shouldValidate: true });
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const thumbs =
    image && image !== null && image.length > 0 ? (
      image.map((image) => {
        const ext =
          image.name && image.name.substr(image.name.lastIndexOf('.') + 1);
        if (
          ext === 'png' ||
          ext === 'jpg' ||
          ext === 'jpeg' ||
          ext === 'PNG' ||
          ext === 'JPG' ||
          ext === 'JPEG'
        ) {
          return (
            <ImagePreview
              name={image.name}
              src={
                croppedImage?.croppedImageUrl
                  ? croppedImage.croppedImageUrl
                  : image.preview
                  ? image.preview
                  : image
              }
              onDelete={deleteImage}
            />
          );
        }
        return <div>Cannot upload {image.name}</div>;
      })
    ) : showDefaultValue && defaultValue ? (
      <ImagePreview
        name={defaultValue}
        src={defaultValue}
        onDelete={deleteImage}
      />
    ) : null;

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: multiple ? maxFiles : 0,
    accept,
    onDrop,
    minSize: 0,
    maxSize,
    multiple,
  });
  return (
    <ImageContainer className={formGroupClassName}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={(controllerProps) => (
          <div
            {...getRootProps({
              className: 'dropzone',
            })}
            {...controllerProps}
          >
            <ImageInput
              addVerticalPadding={
                (image && image.length > 0) ||
                (showDefaultValue && defaultValue)
              }
            >
              <ImageLabel>
                <Label
                  fontSize={
                    (showDefaultValue && defaultValue) ||
                    (image && image.length > 0)
                      ? 'small'
                      : 'default'
                  }
                  wrap
                >
                  {image && image.length > 0
                    ? image[0].name
                    : showDefaultValue && defaultValue
                    ? formatS3ImageFilename(defaultValue)
                    : label || `Upload Image..`}
                </Label>
                {image && image.length > 0 ? (
                  <ImageSize fontSize="small" color="faded">
                    {formatBytes(image[0].size)}
                  </ImageSize>
                ) : (
                  showDefaultValue &&
                  defaultValue && (
                    <ImageSize fontSize="small" color="faded">
                      Optimised in database
                    </ImageSize>
                  )
                )}
              </ImageLabel>
              <Button color="primary" onClick={null}>
                Select Image
              </Button>
            </ImageInput>
            <input {...getInputProps()} />
          </div>
        )}
      />
      {showCropper ? (
        image &&
        image !== null &&
        image.length > 0 && (
          <ImageCropper
            image={image[0].preview}
            setCroppedImageFor={setCroppedImageFor}
          />
        )
      ) : (
        <Preview className="thumbs-container">{thumbs}</Preview>
      )}
    </ImageContainer>
  );
};

export default ImageUpload;
