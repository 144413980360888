import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from '../../../contexts/layoutContext';
import { CafeMenu, ChefHat, Tick } from '../icons';
import { Span } from '../typefaces/Typefaces.styles';
import { Container, Icon, Stage, Swap } from './ProgressBar.styles';
import { IProgressBarProps } from './ProgressBar.types';

export const ProgressBar: FC<IProgressBarProps> = ({ currentStage, step }) => {
  const { newAccount } = useContext(LayoutContext);
  const { push } = useHistory();

  const handleCLick = (link: 'select-account' | 'sign-up') => {
    if (newAccount?.type) push(link);
  };

  return (
    <Container>
      <Swap swap={false}>
        <Stage onClick={() => handleCLick(`select-account`)}>
          <Icon currentStage={currentStage === 'selectAccount'}>
            <Tick size="medium" color="white" />
          </Icon>
          <Span className="text">Select Account</Span>
        </Stage>
        <Stage
          onClick={() => handleCLick('sign-up')}
          disabled={!newAccount?.type}
        >
          <Icon currentStage={currentStage === 'personalDetails'}>
            <ChefHat size="medium" color="white" />
          </Icon>
          <Span className="text">Personal Details</Span>
        </Stage>
      </Swap>
      <Stage>
        <Icon>
          <CafeMenu size="medium" color="white" />
        </Icon>
        <Span className="text">Calculate Recipes</Span>
      </Stage>
    </Container>
  );
};
