import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { Button } from '../../../../shared/button';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { Label, H2 } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.section`
  margin-bottom: 30px;

  .listGroup {
    padding: 0 !important;
  }

  .loadingDots {
    margin-top: 50px;
  }
`;

export const Description = styled(Label)`
  display: block;
  margin-top: 5px;
`;

export const Update = styled(Button)`
  min-width: 190px;
`;

export const StyledButton = styled(Button)`
  margin-top: 10px;
`;

export const Heading = styled(H2)`
  display: block;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  font-size: 24px;
`;

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 15px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 15px;
    grid-gap: 30px;
    margin-left: 60px;
  }
`;
