import React, { FC } from 'react';
import { Tick } from '../../../../shared/icons';
import Info from '../../../../shared/icons/components/Info';
import { H4, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { IInfoModalProps } from '../../SelectAccount';
import { IDetailsProps } from '../pricing/Pricing';
import { Container, Heading, Item, Label } from './PlanTable.Styles';

interface IPlanTableProps {
  heading?: string;
  id: string;
  items: IDetailsProps[];
  addTableRows?: number;
  addMobileTableRows?: number;
  noWrap?: boolean;
  setInfoModal?: React.Dispatch<React.SetStateAction<IInfoModalProps>>;
}

export const PlanTable: FC<IPlanTableProps> = ({
  heading,
  id,
  items,
  addTableRows,
  addMobileTableRows,
  noWrap,
  setInfoModal,
}) => {
  const handleInfoClick = (heading: string, idx: number) => {
    const content = items[idx];

    if (setInfoModal) {
      setInfoModal({ show: true, heading, content: content.info });
    }
  };

  return (
    <Container
      addTableRows={addTableRows}
      addMobileTableRows={addMobileTableRows}
    >
      <Heading>
        <H4 color="faded" className="heading">
          {heading}
        </H4>
      </Heading>

      {items
        .map((u) => {
          return u[id];
        })
        .map((v: string | boolean, idx) => {
          return (
            <Item noWrap={noWrap} key={idx}>
              {typeof v === 'boolean' ? (
                v ? (
                  <Tick color="secondary" size="smaller" />
                ) : (
                  '-'
                )
              ) : (
                <Label
                  isLabel={id === 'label'}
                  onClick={() => handleInfoClick(v, idx)}
                >
                  <Span className="label">{v}</Span>
                  {id === 'label' && (
                    <Info size="small" color="grey" faded className="icon" />
                  )}
                </Label>
              )}
            </Item>
          );
        })}
    </Container>
  );
};
