import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const CategoryIcon: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled size={size} viewBox="0 0 512 512" color={color} faded={faded}>
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M706 4709 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111
l-32 -67 0 -695 0 -695 32 -67 c44 -93 100 -151 191 -196 l76 -37 696 0 696 0
76 37 c91 45 147 103 191 196 l32 67 0 696 0 695 -37 76 c-45 91 -103 147
-196 191 l-67 32 -675 2 c-387 1 -692 -3 -714 -8z m1368 -161 c55 -16 138 -99
154 -154 17 -59 17 -1289 0 -1348 -16 -55 -99 -138 -154 -154 -31 -9 -206 -12
-674 -12 -468 0 -643 3 -674 12 -55 16 -138 99 -154 154 -17 59 -17 1289 0
1348 15 51 99 137 148 153 52 16 1298 17 1354 1z"
        />
        <path
          d="M3026 4709 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111
l-32 -67 0 -695 0 -695 32 -67 c44 -93 100 -151 191 -196 l76 -37 696 0 696 0
76 37 c91 45 147 103 191 196 l32 67 0 696 0 695 -37 76 c-45 91 -103 147
-196 191 l-67 32 -675 2 c-387 1 -692 -3 -714 -8z m1368 -161 c55 -16 138 -99
154 -154 17 -59 17 -1289 0 -1348 -16 -55 -99 -138 -154 -154 -31 -9 -206 -12
-674 -12 -468 0 -643 3 -674 12 -55 16 -138 99 -154 154 -17 59 -17 1289 0
1348 15 51 99 137 148 153 52 16 1298 17 1354 1z"
        />
        <path
          d="M706 2389 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111
l-32 -67 0 -695 0 -695 32 -67 c44 -93 100 -151 191 -196 l76 -37 696 0 696 0
76 37 c91 45 147 103 191 196 l32 67 0 696 0 695 -37 76 c-45 91 -103 147
-196 191 l-67 32 -675 2 c-387 1 -692 -3 -714 -8z m1368 -161 c55 -16 138 -99
154 -154 17 -59 17 -1289 0 -1348 -16 -55 -99 -138 -154 -154 -59 -17 -1289
-17 -1348 0 -55 16 -138 99 -154 154 -17 59 -17 1289 0 1348 15 51 99 137 148
153 52 16 1298 17 1354 1z"
        />
        <path
          d="M3026 2389 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111
l-32 -67 0 -695 0 -695 32 -67 c44 -93 100 -151 191 -196 l76 -37 696 0 696 0
76 37 c91 45 147 103 191 196 l32 67 0 696 0 695 -37 76 c-45 91 -103 147
-196 191 l-67 32 -675 2 c-387 1 -692 -3 -714 -8z m1368 -161 c55 -16 138 -99
154 -154 17 -59 17 -1289 0 -1348 -16 -55 -99 -138 -154 -154 -59 -17 -1289
-17 -1348 0 -55 16 -138 99 -154 154 -17 59 -17 1289 0 1348 15 51 99 137 148
153 52 16 1298 17 1354 1z"
        />
        <path
          d="M3665 1975 l-25 -24 0 -236 0 -235 -235 0 -236 0 -24 -25 c-33 -32
-33 -78 0 -110 l24 -25 236 0 235 0 0 -235 0 -236 25 -24 c32 -33 78 -33 110
0 l25 24 0 236 0 235 235 0 236 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55
l-24 25 -236 0 -235 0 0 235 0 236 -25 24 c-15 16 -36 25 -55 25 -19 0 -40 -9
-55 -25z"
        />
      </g>
    </IconStyled>
  );
};

export default CategoryIcon;
