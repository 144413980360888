import React, { FC, memo, useContext } from 'react';
import { Col, Container, HeaderRow, Table } from './InventoryList.styles';
import { QueryResult } from '@apollo/client';
import {
  Exact,
  InventoryStocktakeQuery,
  GetInventoryStocktakeInput,
} from '../../../../../generated/graphql';
import InventorySection from './InventorySection';
import { Card, Content, Header } from '../../../../shared/card';
import { RecipeBook } from '../../../../shared/icons';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { DotsLoading } from '../../../../shared/loading';
import { Label } from '../../../../shared/typefaces/Typefaces.styles';
import { Button } from '../../../../shared/button';
import { useHistory } from 'react-router-dom';
import { isEmpty } from '../../../../../utils/helper';

interface IInventoryListProps {
  stocktake: QueryResult<
    InventoryStocktakeQuery,
    Exact<{
      input: GetInventoryStocktakeInput;
    }>
  >;
}

export const InventoryList: FC<IInventoryListProps> = ({ stocktake }) => {
  const { appWidth } = useContext(LayoutContext);
  const history = useHistory();
  const isLoading = stocktake.loading || !stocktake.data;
  const stocktakeSectionsLength =
    stocktake.data?.inventoryStocktake.stocktake?.stocktakeSections.length || 0;
  const subHeading =
    'Each inventory section will contain a list of ingredients & details associated';

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow $loading={isLoading}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col>
            <Label>Total</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow $loading={isLoading}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Purchase Cost</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow $loading={isLoading}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow $loading={isLoading}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Supplier</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow $loading={isLoading}>
          <Col />
          <Col>
            <Label>Name</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Supplier</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const noData = (
    <Card className="noDataCard">
      <Header
        icon={<RecipeBook size="small" />}
        heading={'Add Stocktake to see Inventory List'}
        subHeading="Add a stocktake to see your Inventory on this page grouped by Section"
        button={
          <Button onClick={() => history.push('/stocktakes')} color="primary">
            Add Stocktake
          </Button>
        }
      />
    </Card>
  );

  return (
    <Container>
      {isLoading && (
        <Card>
          <Header
            icon={<RecipeBook size="small" />}
            heading={'Inventory Sections Loading'}
            subHeading={subHeading}
          />
          <Content fullWidth>
            <Table>
              <DotsLoading
                isLoading={true}
                size="large"
                lineHeight={10}
                color="default"
              />
              {getTableHeader()}
            </Table>
          </Content>
        </Card>
      )}

      {!isLoading &&
        isEmpty(stocktake.data?.inventoryStocktake.stocktake) &&
        noData}

      {stocktake.data?.inventoryStocktake.stocktake?.stocktakeSections.map(
        (s, idx) => {
          const isLastSection = stocktakeSectionsLength === idx + 1;
          return (
            <InventorySection
              key={s.id}
              stocktakeSection={s}
              isLastSection={isLastSection}
            />
          );
        }
      )}
    </Container>
  );
};

export default memo(InventoryList);
