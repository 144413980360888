import {
  IngredientMetricUnit,
  RecipeMetricUnit,
} from '../../generated/graphql';

export function getSubRecipeGrams({
  unit,
  quantity,
  recipeServes,
  totalGrams,
}: {
  unit: string;
  quantity: number;
  recipeServes: number;
  totalGrams: number;
}) {
  switch (unit.toUpperCase()) {
    case RecipeMetricUnit.Serve:
      const gramsPerServe = totalGrams / recipeServes;
      return quantity * gramsPerServe;
    case IngredientMetricUnit.Gram:
      return quantity;

    default:
      return 0;
  }
}
