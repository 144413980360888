import React, { FC } from 'react';
import RecipeResults from './RecipeResults';
import { RouteComponentProps } from 'react-router-dom';
import { RecipeActiveTimeProvider } from '../../../utils/customHooks/useRecipeActiveTimeTracker';

export const RecipeResultsProvider: FC<RouteComponentProps> = (props) => {
  return (
    <RecipeActiveTimeProvider>
      <RecipeResults {...props} />
    </RecipeActiveTimeProvider>
  );
};
