import React from 'react';
import { AngleBox } from '../../shared/layout';
import { H3, Span } from '../../shared/typefaces/Typefaces.styles';
import {
  Box,
  Container,
  Content,
  Heading,
  Left,
  Main,
  Right,
} from './PrivacyPolicy.styles';

const PrivacyPolicy = () => {
  return (
    <Container>
      <AngleBox color="primary" marginTop="large" />
      <Main>
        <Content>
          <Left>
            <div>
              <Heading>Privacy Policy</Heading>
            </div>
          </Left>
          <Right>
            <Box>
              <AngleBox color="secondary" marginTop="small" height="tiny" />
              <div className="content">
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">1.</span> Introduction
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">1.1</span>
                    <span className="text">
                      Recipe Revenue Trading Pty Ltd (ABN 73 496 212 784) will
                      be known as <b>(Recipe Revenue, we, us)</b> for the
                      remainder of this document. The Recipe Revenue Privacy
                      Policy <b>(Privacy Policy)</b> is available to help you
                      understand your rights and obligations when interacting
                      and browsing our <b>website</b> that is hosted at
                      https://reciperevenue.com.au & https://reciperevenue.com
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">1.2</span>
                    <span className="text">
                      This policy sets out:
                      <ul>
                        <li>What is considered personal information;</li>
                        <li>What personal information we collect and hold;</li>
                        <li>
                          How we collect, hold, use or disclose personal
                          information;
                        </li>
                        <li>
                          The purposes for which we collect personal
                          information;{' '}
                        </li>
                        <li>
                          What happens if we are not able to collect personal
                          information;
                        </li>
                        <li>
                          How to seek access to and correct your personal
                          information;
                        </li>
                        <li>
                          Whether we disclose personal information outside
                          Australia; and how to
                        </li>
                        <li>Contact us.</li>
                      </ul>
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">1.3</span>
                    <span className="text">
                      Recipe Revenue respects the rights and privacy of all
                      individuals and is committed to complying with the Privacy
                      Act 1988 (Cth) <b>(the Act)</b> and the Australian Privacy
                      Principles and protecting the personal information we
                      hold.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">1.4</span>
                    <span className="text">
                      We may, from time to time, review and update this policy,
                      including taking account of new or amended laws, new
                      technology and/or changes to our operations. All personal
                      information held by us will be governed by the most
                      recently updated policy and we will give you notice of our
                      revised policy by posting to our Website.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">2.</span>
                    <span>
                      Definition of personal information for our privacy policy?
                    </span>
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">2.1</span>
                    <span className="text">
                      When used in this policy, the term “personal information”
                      has the meaning given to it in the Act. In general terms,
                      it is any information that can be used to personally
                      identify you. This may include (but is not limited to)
                      your name, age, gender, postcode and contact details
                      (including phone numbers and email addresses) and possibly
                      financial information, including your credit card or
                      direct debit account information. If the information we
                      collect personally identifies you, or you are reasonably
                      identifiable from it, the information will be considered
                      personal information.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">3.</span> What personal
                    information does recipe revenue collect and hold
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">3.1</span>
                    <span className="text">
                      We collect the type of personal information required to
                      assist with providing you with access to the Website and
                      its associated functionality.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">3.2</span>
                    <span className="text">
                      This may include personal information such as:
                      <ul>
                        <li>Personal or Company name; </li>
                        <li>Mailing or street address;</li>
                        <li>Email address;</li>
                        <li>Telephone number;</li>
                        <li>Age or birth date;</li>
                        <li>Occupation or Job Position;</li>
                        <li>Costs associated with venue operations;</li>
                        <li>Details about your recipes process;</li>
                        <li>
                          Information about your staff / labour allocation;
                        </li>
                        <li>
                          any additional information relating to you that you
                          provide to us directly through our website, by phone,
                          email, surveys, or in person, or information you have
                          provided indirectly through use of our Website or
                          online presence through our representatives or
                          otherwise;
                        </li>
                      </ul>
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">4.</span> How and why does
                    recipe revenue collect personal information?
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">4.1</span>
                    <span className="text">
                      We collect your personal information directly from you
                      unless it is unreasonable or impractical to do so. We do
                      this in ways including:
                      <ul>
                        <li>when you register as a user on our Website;</li>
                        <li>via your access and use of our Website; and</li>
                        <li>
                          during conversations between you and us via phone or
                          email (if any).;
                        </li>
                      </ul>
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">4.2</span>
                    <span className="text">
                      We may also collect personal information from third
                      parties including third party companies such as law
                      enforcement agencies and other government entities,
                      e-commerce platforms, data suppliers, advertisers, mailing
                      lists and contractors and business partners.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">4.3</span>
                    <span className="text">
                      We may also provide your information to third parties
                      engaged by Recipe Revenue to perform functions on its
                      behalf, such as processing credit card information, as
                      well as third parties authorised by you to receive
                      information held by Recipe Revenue.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">4.4</span>
                    <span className="text">
                      We may collect and disclose personal information to third
                      parties for the purposes described in this policy. These
                      purposes include but are not limited to:
                      <ul>
                        <li>
                          Efficient communications between you and Recipe
                          Revenue.
                        </li>
                        <li>
                          Secure storage and management of your information to
                          allow Recipe Revenue to provide you proper access to
                          the Website.
                        </li>
                      </ul>
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">4.5</span>
                    <span className="text">
                      The primary purpose for which we collect information about
                      you is to enable us to perform our business activities and
                      functions and to provide the best customer experience.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">4.6</span>
                    <span className="text">
                      We generally collect personal information as part of
                      providing you with access to Website, for you to optimise
                      your recipe revenue as accurately as possible, complying
                      with our contractual and other legal obligations, running
                      promotions and other marketing activities or administering
                      our relationship with you by responding to your enquiries
                      and providing you with information about Recipe Revenue’s
                      activities that may be of interest to you.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">4.7</span>
                    <span className="text">
                      We may use your personal information for those purposes,
                      in developing, maintaining or updating the system accessed
                      by You through the Website or in any other way if we ask
                      for your consent first.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">4.8</span>
                    <span className="text">
                      Your personal information will not be shared, sold, rented
                      or disclosed other than as described in this Privacy
                      Policy.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">4.9</span>
                    <span className="text">
                      We may disclose your personal information to:
                      <ul>
                        <li>
                          our employees, contractors, licensees or external
                          service providers for the operation of our website or
                          our business, fulfilling requests by you, including
                          without limitation IT systems administrators or
                          payment processors;
                        </li>
                        <li>
                          specific third parties authorised by you to receive
                          information held by us (e.g. a software application
                          api that you have asked us to connect to)
                        </li>
                        <li>
                          the police, any relevant authority or enforcement
                          body, or your Internet Service Provider or network
                          administrator, for example, if we have reason to
                          suspect that you have committed a breach of any of our
                          terms and conditions, or have otherwise been engaged
                          in any unlawful activity, and we reasonably believe
                          that disclosure is necessary;
                        </li>
                        <li>
                          as required or permitted by any law (including the
                          Privacy Act).
                        </li>
                      </ul>
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">5.</span>What happens if we
                    can’t collect your personal information?
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">5.1</span>
                    <span className="text">
                      Where practical, you may choose not to identify yourself.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">5.2</span>
                    <span className="text">
                      In some instances, if you do not provide us with required
                      personal information described in this policy, we may not
                      be able to provide you with access to the Website, either
                      to the same standard as if you had provided the required
                      personal information, or at all.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">6.</span> Use of financial
                    information
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">6.1</span>
                    <span className="text">
                      If you use our Website to make purchases or other
                      financial transactions (such as payment of invoices
                      through the Website for products or services), we may
                      collect information about the purchase or transaction.
                      This includes payment information, such as your credit
                      card or debit card number, billing details and other
                      account and contact information{' '}
                      <b>(Financial Information)</b>.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">6.2</span>
                    <span className="text">
                      We will only collect Financial Information from you with
                      your prior knowledge and consent. You can access and
                      browse our Website without disclosing Financial
                      Information.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">6.3</span>
                    <span className="text">
                      We use your Financial Information solely to process
                      payments for products or services you request or purchase
                      through the use of our Website. We only use and retain
                      your Financial Information to complete payments you
                      initiate, any Financial Information that is collected is
                      solely for the purpose of transaction approval and the
                      transfer of funds.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">6.4</span>
                    <span className="text">
                      We provide data encryption throughout the payment process
                      and only share your Financial Information with your credit
                      card provider, third party payment processor or financial
                      institution to process payments. The Financial Information
                      we collect from you is strictly confidential and held on
                      secured servers in controlled facilities.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">6.5</span>
                    <span className="text">
                      We do not retain your Financial Information after the
                      transaction is complete, unless you check a box through
                      which you ask us to save your Financial Information for
                      future product purchases or payments. If you do check that
                      box, we will retain your Financial Information until you
                      contact us and ask that we remove it from our databases.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">6.6</span>
                    <span className="text">
                      We may use third party agents to manage online payment
                      processing. These agents are not permitted to store,
                      retain, or use your Financial Information or other
                      personally identifiable information, except for the sole
                      purpose of payment processing on our behalf. Any third
                      party agent used by us is not authorized to use your
                      Financial Information in any way other than to process
                      payments and is required to keep any Financial Information
                      it uses or collects confidential.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">7.</span>Direct marketing
                    materials
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">7.1</span>
                    <span className="text">
                      We may send you direct marketing communications and
                      information about services that we consider may be of
                      interest to you. These communications may be sent in
                      various forms, including mail, SMS or email, in accordance
                      with applicable marketing laws, such as the Spam Act 2004
                      (Cth). If you indicate a preference for a method of
                      communication, we will endeavour to use that method
                      whenever practical to do so.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">7.2</span>
                    <span className="text">
                      In addition, at any time, you may opt-out of receiving
                      marketing communications from us by contacting us (details
                      below) or by using the opt-out facilities provided (e.g.
                      an unsubscribe link). We will then ensure that your name
                      is removed from our mailing list. We do not provide your
                      personal information to other organisations for the
                      purposes of direct marketing unless expressly authorised
                      by you.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">7.3</span>
                    <span className="text">
                      Even if you do opt out of receiving marketing
                      communications from us, you agree that we may still send
                      you information relevant to the supply of any services
                      arranged by us or goods or services purchased by you
                      through our Website.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">7.4</span>
                    <span className="text">
                      If you receive communications from us that you believe
                      have been sent to you other than in accordance with this
                      policy, or in breach of any law, please contact us using
                      the details provided below.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">8.</span>Third party hosting
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">8.1</span>
                    <span className="text">
                      Our Website is hosted by third party service providers.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">8.2</span>
                    <span className="text">
                      In order for Recipe Revenue to allow you access to the
                      Website, we at times may allow access to personal
                      information to third party providers.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">8.3</span>
                    <span className="text">
                      In order for Recipe Revenue to allow you access to the
                      Website, we at times may allow access to personal
                      information to third party providers.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">9.</span> Will your information
                    be disclosed overseas?
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">9.1</span>
                    <span className="text">
                      We will not disclose your personal information to any
                      person or entity outside Australia. If this needs to
                      change, we will inform you prior to any overseas
                      disclosure and will provide the relevant details.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">9.2</span>
                    <span className="text">
                      If we are required to disclose personal information to
                      other overseas persons or entities, we will take
                      reasonable steps to ensure that the overseas recipients of
                      your personal information do not breach the privacy
                      obligations relating to your personal information.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">10.</span> How can you access
                    and correct your personal information
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">10.1</span>
                    <span className="text">
                      You may request access to any personal information we hold
                      about you at any time by contacting us at
                      team@reciperevenue.com
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">10.2</span>
                    <span className="text">
                      Where we hold information that you are entitled to access,
                      we will try to provide you with suitable means of
                      accessing it (for example, by mailing or emailing it to
                      you). We will not charge for simply making a request and
                      will not charge for making any corrections to your
                      personal information. If you make an access request, we
                      will ask you to verify your identity. There may be
                      instances where we cannot grant you access to the personal
                      information we hold. For example, we may need to refuse
                      access if granting access would interfere with the privacy
                      of others, or if it would result in a breach of
                      confidentiality. If that happens, we will give you written
                      reasons for any refusal.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">10.3</span>
                    <span className="text">
                      If you believe that personal information we hold about you
                      is incorrect, incomplete or inaccurate, then you may
                      request us to amend it. We will consider if the
                      information requires amendment. If we do not agree that
                      there are grounds for amendment, then we will add a note
                      to the personal information stating that you disagree with
                      it.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">10.4</span>
                    <span className="text">
                      We request that you keep your information as current as
                      possible so that we may continue to improve our service to
                      you.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">11.</span> How will recipe
                    revenue make sure your personal information is secure?
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">11.1</span>
                    <span className="text">
                      We will take all reasonable steps to protect the personal
                      information that we hold from misuse, loss, or
                      unauthorised access, including by means of firewalls,
                      password access, secure servers and encryption of credit
                      card transactions.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">11.2</span>
                    <span className="text">
                      If you suspect any misuse or loss of, or unauthorised
                      access to, your personal information, please let us know
                      immediately.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">11.3</span>
                    <span className="text">
                      If we suspect any misuse or loss of, or unauthorised
                      access to, your personal information we may inform you of
                      that suspicion and take immediate steps to limit any
                      further access to, or distribution of, your personal
                      information. If we determine that the breach is likely to
                      result in serious harm to you and we are unable to prevent
                      the likely risk of serious harm with remedial action, we
                      will take action in accordance with the Privacy Act 1988
                      (Cth).
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">11.4</span>
                    <span className="text">
                      If we receive unsolicited personal information that we are
                      not permitted to collect under this privacy policy, or
                      within the confines of the law, we will destroy or
                      de-identify the unsolicited personal information as soon
                      as practicable if it is lawful and reasonable to do so. We
                      will destroy or de-identify your personal information if
                      we no longer require it to deliver our services as soon as
                      practicable if it is lawful and reasonable to do so.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">12.</span> Does recipe revenue
                    use “cookies”
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">12.1</span>
                    <span className="text">
                      When you use our Website, Recipe Revenue or our service
                      providers may obtain information using technologies such
                      as cookies, tags, web beacons, and navigational data
                      collection (log files, server logs, and clickstream data)
                      to better understand your user experience. For example,
                      Recipe Revenue or our service providers may collect
                      information like the date, time and duration of visits and
                      which web pages are accessed.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">12.2</span>
                    <span className="text">
                      When you access our Website, we may send a “cookie” (which
                      is a small summary file containing a unique ID number) to
                      your computer or mobile device. This enables us to
                      recognise your computer or device and greet you each time
                      you visit our Website, without bothering you with a
                      request to register or log-in. It also helps us keep track
                      of products or services you view, so that we can send you
                      news about those products or services.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">12.3</span>
                    <span className="text">
                      We also use cookies to measure traffic patterns, to
                      determine which areas of our websites have been visited,
                      and to measure transaction patterns in the aggregate. We
                      use this to research our users’ habits so that we can
                      improve our online services. If you do not wish to receive
                      cookies, you can set your browser so that your computer
                      does not accept them.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">12.4</span>
                    <span className="text">
                      We may also log IP addresses (the electronic addresses of
                      computers connected to the internet) to analyse trends,
                      administer the website, track user movements, and gather
                      broad demographic information.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">12.5</span>
                    <span className="text">
                      This information is generally not linked to your identity,
                      except where it is accessed via links in Recipe Revenue
                      emails or where you have identified yourself. We may also
                      collect anonymous data (which is not personal information)
                      relating to your activity on our website (including IP
                      addresses) via cookies. We generally use this information
                      to report statistics, analyse trends, administer our
                      services, diagnose problems and target and improve the
                      quality of our services. To the extent this information
                      does not constitute personal information because it does
                      not identify you or anyone else, the Australian Privacy
                      Principles do not apply and we may use this information
                      for any purpose and by any means whatsoever.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">12.6</span>
                    <span className="text">
                      We use Hotjar.com in order to better understand our users’
                      needs and to optimize this service and experience. Hotjar
                      is a technology service that helps us better understand
                      our users’ experience (e.g. how much time they spend on
                      which pages, which links they choose to click, what users
                      do and don’t like, etc.) and this enables us to build and
                      maintain our service with user feedback. Hotjar uses
                      cookies and other technologies to collect data on our
                      users’ behavior and their devices. This includes a
                      device's IP address (processed during your session and
                      stored in a de-identified form), device screen size,
                      device type (unique device identifiers), browser
                      information, geographic location (country only), and the
                      preferred language used to display our website. Hotjar
                      stores this information on our behalf in a pseudonymized
                      user profile. Hotjar is contractually forbidden to sell
                      any of the data collected on our behalf.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">13.</span> How will recipe
                    revenue make sure your personal information is secure?
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">13.1</span>
                    <span className="text">
                      If you believe your privacy has been breached by us, have
                      any questions or concerns about our Privacy Policy please,
                      contact us using the email team@reciperevenue.com and
                      provide details of the incident so that we can investigate
                      it.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">13.2</span>
                    <span className="text">
                      We are genuinely committed to the rules, ethos and intent
                      detailed in this Privacy Policy and we aim to ensure that
                      your requests or complaints are treated confidentially.
                      Our representative will contact you within a reasonable
                      time after receipt of your complaint to discuss your
                      concerns and outline options regarding how they may be
                      resolved. We will aim to ensure that your complaint is
                      resolved in a timely and appropriate manner.
                    </span>
                  </Span>
                  <Span className="sectionContent">
                    <span className="sectionId">13.3</span>
                    <span className="text">
                      If you are not satisfied with the outcome of our
                      investigation, then you may request that an independent
                      person (usually the Commonwealth Privacy Officer)
                      investigate your complaint.
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">14.</span> Who can you contact
                    about your personal information?
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">14.1</span>
                    <span className="text">
                      To contact Recipe Revenue about your personal information,
                      concerns or complaints, email at team@reciperevenue.com or
                      alternatively, write to Recipe Revenue 11/39 Pakington St
                      Kew 3101
                    </span>
                  </Span>
                </section>
                <section>
                  <H3 className="sectionHeading">
                    <span className="sectionId">15.</span> How will recipe
                    revenue notify you of privacy policy changes?
                  </H3>
                  <Span className="sectionContent">
                    <span className="sectionId">15.1</span>
                    <span className="text">
                      We will contact you via email associated with your
                      account.
                    </span>
                  </Span>
                </section>
              </div>
            </Box>
          </Right>
        </Content>
      </Main>
    </Container>
  );
};

export default PrivacyPolicy;
