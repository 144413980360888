import styled from 'styled-components/macro';
import { device } from '../../../../styles/device';
import { IContentProps } from './sideContent.types';

export const Image = styled.div`
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 30px;
  overflow: hidden;
  height: fit-content;
  position: relative;

  .text {
    position: absolute;
    top: 160px;
    color: white;
    font-weight: bold;
    font-size: 35px;
    width: 100%;
    text-align: center;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
  }
`;

export const Content = styled.div<IContentProps>`
  display: flex;
  align-items: center;
  padding-bottom: 90px;

  @media ${device.tablet} {
    padding-bottom: 30px;
    padding-left: ${({ imageSide }) => (imageSide === 'left' ? '90px' : '0')};
    padding-right: ${({ imageSide }) => (imageSide === 'right' ? '90px' : '0')};
  }
`;

export const Container = styled.div<IContentProps>`
  display: flex;
  justify-content: space-between;
  margin: 30px auto 60px;
  padding: 15px;
  flex-direction: column-reverse;
  max-width: 500px;

  @media ${device.tablet} {
    max-width: 900px;
    flex-direction: ${({ imageSide }) =>
      imageSide === 'left' ? 'row' : 'row-reverse'};
  }

  @media ${device.desktopS} {
    max-width: 1200px;
  }
`;
