import React, { FC } from 'react';
import { AccountType } from '../../../../../generated/graphql';
import { Arrow } from '../../../../shared/icons';
import { H2 } from '../../../../shared/typefaces/Typefaces.styles';
import { PlanOptions } from '../planOptions';
import * as S from './SelectPlan.styles';

interface ISelectPlanProps {
  isLoading: boolean;
  onSubmit: (accountType: AccountType) => void;
}

export const SelectPlan: FC<ISelectPlanProps> = ({ isLoading, onSubmit }) => {
  return (
    <S.Container>
      <S.Header>
        <H2 className="heading">Select A Plan To Start With</H2>
        <S.BreakLine />
        <Arrow color="grey" size="small" rotate={90} />
      </S.Header>
      <PlanOptions isLoading={isLoading} onSubmit={onSubmit} />
    </S.Container>
  );
};
