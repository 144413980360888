import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Info: React.FC<IIconProps> = ({
  color,
  size,
  faded,
  rotate,
  className,
  onClick,
}) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 64 64"
      color={color}
      faded={faded}
      rotate={rotate}
      className={className}
      onClick={onClick}
    >
      <g
        transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M223 622 c-109 -39 -178 -112 -210 -221 -29 -102 4 -228 82 -306 122
-121 328 -121 450 0 91 92 118 241 64 356 -69 146 -241 223 -386 171z m197
-63 c210 -95 212 -377 5 -475 -166 -79 -364 50 -365 236 0 182 197 313 360
239z"
        />
        <path
          d="M289 484 c-17 -21 0 -49 31 -49 31 0 48 28 31 49 -8 9 -21 16 -31 16
-10 0 -23 -7 -31 -16z"
        />
        <path
          d="M302 358 c-13 -13 -17 -173 -6 -202 8 -21 40 -21 48 0 3 9 6 55 6
103 0 79 -9 111 -30 111 -3 0 -11 -5 -18 -12z"
        />
      </g>
    </IconStyled>
  );
};

export default Info;
