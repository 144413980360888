import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Apple: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3734 5110 c-472 -39 -836 -178 -1142 -434 -511 -429 -693 -1139
-506 -1976 15 -63 37 -152 50 -198 l24 -83 -72 -126 c-68 -121 -161 -316 -187
-395 -7 -21 -14 -38 -16 -38 -2 0 -20 51 -41 113 -268 807 -718 1645 -1200
2233 -112 137 -171 163 -290 129 -55 -15 -67 -25 -188 -148 -70 -73 -135 -145
-143 -160 -21 -41 -27 -118 -13 -163 7 -22 54 -95 105 -164 659 -890 1240
-2273 1390 -3315 15 -99 34 -199 45 -221 40 -92 153 -164 255 -164 94 0 208
68 250 150 8 16 34 101 56 188 110 429 274 949 404 1277 70 178 192 438 243
516 l30 46 103 7 c404 27 797 143 1054 310 107 70 257 214 325 312 65 94 138
248 185 389 20 61 86 286 146 500 61 215 130 442 155 505 76 191 169 347 278
467 85 93 103 153 71 230 -31 74 -62 94 -197 122 -393 84 -837 118 -1174 91z
m527 -170 c73 -6 201 -20 284 -31 143 -19 395 -66 403 -74 2 -2 -24 -37 -58
-77 -126 -149 -234 -332 -305 -518 -19 -47 -75 -233 -125 -415 -149 -537 -192
-671 -256 -797 -142 -281 -410 -476 -800 -583 -196 -54 -467 -95 -624 -95 -75
0 -101 -21 -171 -136 -196 -325 -445 -1003 -638 -1739 -34 -127 -66 -241 -73
-253 -14 -27 -57 -52 -88 -52 -32 0 -83 27 -99 51 -7 12 -23 94 -36 183 -153
1061 -744 2478 -1414 3385 -50 68 -91 130 -91 138 0 21 197 229 230 243 17 7
35 8 44 3 46 -25 327 -406 484 -657 364 -576 712 -1361 842 -1898 39 -160 72
-194 150 -153 23 12 33 28 46 73 100 357 198 574 397 872 441 662 1006 1231
1675 1688 67 46 127 92 132 102 30 56 -10 124 -73 124 -25 0 -64 -21 -163 -89
-610 -418 -1134 -925 -1569 -1518 l-79 -109 -13 39 c-21 59 -71 316 -84 432
-17 156 -6 479 21 606 68 319 200 566 420 785 209 210 436 335 738 409 107 26
298 56 412 64 107 8 356 6 481 -3z"
        />
      </g>
    </IconStyled>
  );
};

export default Apple;
