import React, { useState, useRef, useCallback, useEffect } from 'react';
import { roundTo } from '../../../../../utils/helper';
import { Tick, Dots } from '../../../../shared/icons';
import {
  Col,
  Item,
  Row,
  MetricNames,
  MetricValues,
} from './VerifyIngredients.styles';
import { DotsMenu } from '../../../../shared/dotsMenu';
import { MenuItem } from '../../../../shared/dotsMenu/DotsMenu.styles';
import {
  DotsMenuWrapper,
  HeaderIcon,
} from '../../../../shared/recipe/sections/items/Items.styles';
import { LinkSpanStyled } from '../../../../shared/navbar/navbarActions/NavbarActions.styles';
import { MetricType } from '../../../../../generated/graphql';

const VerifyIngredientsRow = ({
  ingredient,
  handleEditIngredient,
  handleIgnoreIngredient,
  handleToggleSelectIngredient,
  isSelected,
}) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const recipeMenuRef = useRef(null);
  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setMenuOpen(false);
    }
  }, []);
  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (
        recipeMenuRef.current &&
        (recipeMenuRef.current! as any).contains &&
        !(recipeMenuRef.current! as any).contains(e.target)
      ) {
        setMenuOpen(false);
      }
    },
    [recipeMenuRef]
  );

  const recipeMenuIcon = (
    <HeaderIcon
      onClick={() => setMenuOpen((x) => !x)}
      showBackground={isMenuOpen}
      ref={recipeMenuRef}
    >
      <Dots color="grey" size="smaller" />
    </HeaderIcon>
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [escapeListener, clickListener]);

  const {
    displayName,
    averageCost100g,
    metrics,
    ingredientProducts,
  } = ingredient;

  const whole = metrics.find(({ type }) => type === MetricType.Whole);
  const cup = metrics.find(({ type }) => type === MetricType.Cup);

  const ingredientProduct =
    ingredientProducts.length > 0 ? ingredientProducts[0] : {};
  const { brand, supplier } = ingredientProduct;

  return (
    <Row>
      <Col onClick={handleToggleSelectIngredient} pointer>
        <Item>
          {isSelected ? <Tick size="small" color="secondary" /> : null}
        </Item>
      </Col>
      <Col onClick={handleToggleSelectIngredient} pointer>
        <Item>{displayName}</Item>
        <Item light>
          <strong>Supplier:</strong> {supplier?.displayName || '--'}
        </Item>
      </Col>
      <Col>
        <Item light horizontal>
          <MetricNames>
            <div>Whole:</div>
            <div>Cup:</div>
          </MetricNames>
          <MetricValues>
            <div>{whole?.grams}g</div>
            <div>{cup?.grams}g</div>
          </MetricValues>
        </Item>
      </Col>
      <Col>
        <Item light>{brand?.displayName || '--'}</Item>
      </Col>
      <Col>
        <Item light>${roundTo(averageCost100g, 2)}</Item>
      </Col>
      <Col>
        <Item onClick={() => setMenuOpen(true)}>{recipeMenuIcon}</Item>
        {isMenuOpen ? (
          <>
            <DotsMenuWrapper>
              <DotsMenu top={10} right={-2}>
                <MenuItem>
                  <LinkSpanStyled
                    onClick={() =>
                      handleEditIngredient(ingredient.id, ingredientProduct.id)
                    }
                  >
                    Edit
                  </LinkSpanStyled>
                </MenuItem>
                <MenuItem onClick={() => handleIgnoreIngredient(ingredient.id)}>
                  <LinkSpanStyled color="faded">Ignore</LinkSpanStyled>
                </MenuItem>
              </DotsMenu>
            </DotsMenuWrapper>
          </>
        ) : null}
      </Col>
    </Row>
  );
};

export default VerifyIngredientsRow;
