import React, { FC, useContext } from 'react';
import {
  Span,
  Label,
  H2,
  H3,
  ListGroupRow,
  ListGroup,
} from '../../../../shared/typefaces/Typefaces.styles';
import { Container, CostLink, Header, ListColumns } from './Cost.styles';
import {
  capitalizeFirstLetter,
  convertCostCleanly,
  convertPercentageCleanly,
  isEmpty,
  blurContent,
  convertNumberCleanly,
  validEndDate,
} from '../../../../../utils/helper';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { ICostProps } from './cost.types';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';
import {
  checkIfAccountIsNotComplete,
  useRestrictionCheck,
} from '../../../../../utils/helper/account';
import { AccountType, AddOnApp } from '../../../../../generated/graphql';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';

const Cost: FC<ICostProps> = ({
  recipeData: {
    recipeCost,
    costPerServe,
    foodCost,
    foodCostPercentage,
    totalStaffTimeCost,
    totalVenueTimeCost,
    rentCost,
    powerCost,
    waterCost,
    insuranceCost,
    councilCost,
    wastageCost,
    wastageType,
    weeklyRecipeRemakes,
  },
  venue,
}) => {
  const { selectedRecipeVersion, account, user, dispatch } = useContext(
    LayoutContext
  );
  const showRestrictionPopup = useRestrictionPopup();
  const checkRecipeProfitRestrictions = useRestrictionCheck([
    'CALCULATE_RECIPE_PROFIT',
  ]);

  const handleRecipeRevenueUpgrade = () => {
    showRestrictionPopup(
      restrictionPopupMessages.cannotDisplayRecipeVersionProfit
    );
  };

  const handleEditRecipe = (recipeVersionId: string | undefined) => {
    dispatch({ type: 'SLIDER_PAGE', payload: 'editRecipe' });
    dispatch({ type: 'SELECT_RECIPE_VERSION', payload: recipeVersionId });
    dispatch({ type: 'DUPLICATE_RECIPE', payload: false });
    dispatch({ type: 'SCALE_RECIPE', payload: false });
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountEditRecipe,
      });
    }
  };

  const updateVenueCosts = () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountUpdateVenueCosts,
      });
    } else {
      dispatch({ type: 'SLIDER_PAGE', payload: 'venue' });
    }
  };

  const venueData = venue?.data?.venue?.userVenue?.venue;
  const calculatorAddOn = account?.addOns.find(
    (a) => a.app === AddOnApp.Calculator
  );
  const { endDateIsValid } = validEndDate(calculatorAddOn?.endDate);
  const restrictionCheck = checkRecipeProfitRestrictions({
    data: account?.type === AccountType.BusinessStarter,
  });
  const showProfitContent = endDateIsValid || restrictionCheck.isPass;

  return (
    <Container>
      <Header>
        <H2 fontSize="large">Cost Breakdown</H2>
        <Span fontSize="small" color="faded">
          per recipe batch
        </Span>
      </Header>
      <ListColumns colOneWidth="1fr" colTwoWidth="1fr" padding="small">
        <div>
          <H3 fontWeight="semibold" color="black">
            Overview
          </H3>
          <ListGroup>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Food Cost</Label>
              {!isEmpty(recipeCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(foodCostPercentage)
                    : blurContent(convertCostCleanly(foodCostPercentage))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => handleEditRecipe(selectedRecipeVersion)}
                >
                  Add Ingredients
                </CostLink>
              )}
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Total Recipe Cost</Label>
              <Span
                className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showProfitContent && handleRecipeRevenueUpgrade()
                }
              >
                {showProfitContent
                  ? convertCostCleanly(recipeCost)
                  : blurContent(convertCostCleanly(recipeCost))}
              </Span>
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Cost Per Serve</Label>
              <Span
                className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showProfitContent && handleRecipeRevenueUpgrade()
                }
              >
                {showProfitContent
                  ? convertCostCleanly(costPerServe)
                  : blurContent(convertCostCleanly(costPerServe))}
              </Span>
            </ListGroupRow>
          </ListGroup>
          <ListGroup>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Ingredient Cost</Label>
              {!isEmpty(foodCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(foodCost)
                    : blurContent(convertCostCleanly(foodCost))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => handleEditRecipe(selectedRecipeVersion)}
                >
                  Add Ingredients
                </CostLink>
              )}
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Staff Cost</Label>
              {!isEmpty(totalStaffTimeCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(totalStaffTimeCost)
                    : blurContent(convertCostCleanly(totalStaffTimeCost))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => handleEditRecipe(selectedRecipeVersion)}
                >
                  Add Staff Time
                </CostLink>
              )}
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Venue Cost</Label>
              {!isEmpty(totalVenueTimeCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(totalVenueTimeCost)
                    : blurContent(convertCostCleanly(totalVenueTimeCost))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => updateVenueCosts()}
                >
                  Update venue costs
                </CostLink>
              )}
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Weekly Remakes</Label>
              <Span>
                {convertNumberCleanly(Number(weeklyRecipeRemakes?.toFixed()))}
              </Span>
            </ListGroupRow>
          </ListGroup>
        </div>
        <div>
          <H3 fontWeight="semibold" color="black">
            Venue Costs
          </H3>
          <ListGroup>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Rent</Label>
              {!isEmpty(rentCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(rentCost)
                    : blurContent(convertCostCleanly(rentCost))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => updateVenueCosts()}
                >
                  Update rent cost
                </CostLink>
              )}
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Power</Label>
              {!isEmpty(powerCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(powerCost)
                    : blurContent(convertCostCleanly(powerCost))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => updateVenueCosts()}
                >
                  Update power cost
                </CostLink>
              )}
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Water</Label>
              {!isEmpty(waterCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(waterCost)
                    : blurContent(convertCostCleanly(waterCost))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => updateVenueCosts()}
                >
                  Update water cost
                </CostLink>
              )}
            </ListGroupRow>
          </ListGroup>
          <ListGroup noBorder>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Insurance</Label>
              {!isEmpty(insuranceCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(insuranceCost)
                    : blurContent(convertCostCleanly(insuranceCost))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => updateVenueCosts()}
                >
                  Update insurance cost
                </CostLink>
              )}
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Council</Label>
              {!isEmpty(councilCost) ? (
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(councilCost)
                    : blurContent(convertCostCleanly(councilCost))}
                </Span>
              ) : (
                <CostLink
                  color="primary"
                  fontSize="small"
                  onClick={() => updateVenueCosts()}
                >
                  Update council cost
                </CostLink>
              )}
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">Avg Venue Wastage</Label>
              <Span>
                {convertPercentageCleanly(venueData?.avgWastagePercentage || 0)}{' '}
              </Span>
            </ListGroupRow>
            <ListGroupRow colOneWidth="180px" colTwoWidth="auto">
              <Label color="grey">
                {wastageType && capitalizeFirstLetter(wastageType)} Wastage
              </Label>
              <Span>{convertCostCleanly(wastageCost)}</Span>
            </ListGroupRow>
          </ListGroup>
        </div>
      </ListColumns>
    </Container>
  );
};

export default Cost;
