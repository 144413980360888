import React, { FC, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAccessToken } from '../../../../utils/accessToken';
import { IAuthRouteProps } from './authRoute.types';

const AuthRoute: FC<IAuthRouteProps> = ({ exact, path, component }) => {
  const history = useHistory();

  const [accessToken, isLoading] = useAccessToken();

  useEffect(() => {
    if (!isLoading && !accessToken) {
      history.push('/sign-in');
    }
  }, [accessToken, isLoading]);

  return <Route exact={exact} path={path} component={component} />;
};
export default AuthRoute;
