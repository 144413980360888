import styled from 'styled-components/macro';
import { Span } from '../../typefaces/Typefaces.styles';
import { device } from '../../../../styles/device';
import { IContainerProps, IItemProps } from './boxGroup.types';

export const Container = styled.ul<IContainerProps>`
  max-width: 1200px;
  margin: 0 auto !important;
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'spacebetween')};
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndex.priority};
  position: relative;

  @media ${device.mobileL} {
    flex-direction: row;
  }
`;

export const Item = styled.li<IItemProps>`
  background-color: ${({ theme, noBackground }) =>
    noBackground ? 'trasparent' : theme.colors.white.default};
  box-shadow: ${({ theme, noBackground }) =>
    noBackground ? 'none' : theme.shadow.smallBox};
  display: block;
  height: auto;
  border-radius: 15px;
  margin: ${({ noMargin }) =>
    noMargin ? '0 0 60px !important' : '0 10px 60px !important'};

  @media ${device.mobileL} {
    width: 33.33%;
  }
`;

export const BoxWrapper = styled.div`
  max-width: 280px;
  overflow: hidden;
  margin: 0 auto;

  img {
    /* border-radius: ${({ theme }) => theme.layout.radiusSmall}; */
    /* box-shadow: ${({ theme }) => theme.shadow.smallBox}; */
    border-radius: ${({ theme }) => theme.layout.radiusTiny};
    box-shadow: ${({ theme }) => theme.shadow.smallBox};
  }

  ${Span} {
    padding: 15px 0;
  }
`;
