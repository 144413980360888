import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { Button } from '../../../../shared/button';
import { Label } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.section`
  margin-bottom: 30px;
`;

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};

  &:first-child {
    justify-content: flex-start;
    margin-left: 15px;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 15px;
  }

  @media ${device.tablet} {
    &:first-child {
      justify-content: center;
      border: 0;
      margin: 0;
    }
    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 0;
    }
  }
`;

export const Row = styled.ul`
  min-height: 70px;
  display: grid;
  grid-template-columns: auto 150px;

  &:first-child {
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 40px;
    ${Col} {
      border: 0;
    }
  }
  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 70px auto 150px;
  }
`;

export const Description = styled(Label)`
  display: block;
  margin-top: 5px;
`;

export const Update = styled(Button)`
  min-width: 190px;
`;
