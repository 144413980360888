import styled from 'styled-components';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../../../styles/device';
import { Input } from '../../../../shared/formElements';
import { Container } from '../../../../shared/card/content/Content.styles';

export const Content = styled(Container)`
  @media ${device.tablet} {
    padding-left: 15px;
  }

  ${LayoutColumnContainer} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-gap: 15px;

    @media ${device.tablet} {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      padding-left: 0;
    }
  }
`;

export const Serves = styled.section`
  display: flex;
  justify-content: flex-end;
`;

export const StyledInput = styled(Input)`
  @media ${device.tablet} {
    max-width: 200px;
  }
`;
