import styled from 'styled-components/macro';
import { device } from '../../../../styles/device';
import { ButtonStyled } from '../../button/Button.styles';
import { H3, Span } from '../../typefaces/Typefaces.styles';

export interface IContainerProps {
  showModal: boolean;
  isOpen: boolean;
  sliderToggle: boolean;
  fullWidth: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: ${({ showModal }) => (showModal ? 'flex' : 'none')};
  background-color: ${({ theme }) => theme.colors.white.faded};
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.overlay};
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(100% - 200px)')};

  ${ButtonStyled} {
    width: fit-content;
    margin: 0 auto;
  }

  @media ${device.mobileL} {
    width: ${({ sliderToggle, fullWidth }) =>
      fullWidth
        ? '100%'
        : sliderToggle
        ? 'calc(100% - 101px)'
        : 'calc(100% - 50px)'};
  }

  @media ${device.tablet} {
    width: ${({ isOpen, sliderToggle, fullWidth }) =>
      fullWidth
        ? '100%'
        : isOpen
        ? sliderToggle
          ? 'calc(100% - 200px - 101px)'
          : 'calc(100% - 200px)'
        : sliderToggle
        ? 'calc(100% - 50px - 101px)'
        : 'calc(100% - 50px)'};
  }
`;

export const CardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  max-width: 500px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.default.default};
  background-color: ${({ theme }) => theme.colors.white.default};
  margin: 30px auto;
  border-radius: 30px;
  box-shadow: ${({ theme }) => theme.shadow.box};

  .close {
    cursor: pointer;
    margin: 20px;
    fill: ${({ theme }) => theme.colors.default.faded};
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 15px 60px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled(H3)`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.large};
  padding-bottom: 10px;
`;

export const SubHeading = styled(Span)`
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeight.medium};
`;
