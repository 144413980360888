import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51Kh6z6F5PoNDRraQTxwtSLXWz2A5yhuquXdSxnzkAkq1i6drE3AJpD4RbAgrJED8bNSSC8M7RcjoAH3jHnTexSD200AnmnKDAz'
  // 'pk_test_51HnyB6D77KGaRUooM6WfctJF9zGGjcuPoFn5fUvhVOWG3vQU1FEDQQJm2TZGlE6taKPnu9pSpNv9BylLQI2jNncK00KLK81rQu'
);

export default function PaymentWrapper() {
  return <Elements stripe={stripePromise}>[[payment form goes here]]</Elements>;
}
