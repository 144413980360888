import React, { FC } from 'react';
import { Button } from '../../button';
import { IFileUploadButtonProps } from './fileUploadButton.types';

const FileUploader: FC<IFileUploadButtonProps> = ({
  buttonText,
  accept,
  color,
  inversed,
  onClick,
  handleFileUpload,
}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<any>(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    if (onClick) onClick();
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files && event.target.files[0];
    if (fileUploaded) handleFileUpload(fileUploaded);
  };

  return (
    <>
      <Button onClick={handleClick} color={color} inversed={inversed}>
        {buttonText}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
      />
    </>
  );
};

export default FileUploader;
