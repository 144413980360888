import styled from 'styled-components/macro';
import { H3, Span } from '../../typefaces/Typefaces.styles';
import { ButtonStyled } from '../../button/Button.styles';
import { device } from '../../../../styles/device';
import { IBoxProps, IContainerProps, IContentProps } from './angleBox.types';

const angle = 6;
const lines = {
  primary:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='744' height='744' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23f98d0f' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23f99b27'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E",
  // Light Green
  default:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='906' height='906' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2323CD86' stroke-width='1.7'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2325D38B'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E",
  secondary:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='744' height='744' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%231bab3a' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2329ab45'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E",
};
export const Container = styled.div<IContainerProps>`
  display: block;
  overflow: hidden;
  margin-top: ${({ marginTop }) =>
    marginTop === 'small'
      ? '-150px'
      : marginTop === 'medium'
      ? '-215px'
      : marginTop === 'large'
      ? '-400px'
      : marginTop === 'xlarge'
      ? '-500px'
      : marginTop === 'huge'
      ? '-650px'
      : 0};
  padding: ${({ height }) =>
    height === 'tiny' ? '50px 0' : height === 'small' ? '60px 0' : '300px 0'};
  z-index: ${({ theme }) => theme.colors.white.default};
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom && '-100px'};

  @media ${device.mobileM} {
    padding: ${({ height }) =>
      height === 'tiny' ? '50px 0' : height === 'small' ? '39px 0' : '250px 0'};
  }
`;

export const Box = styled.div<IBoxProps>`
  background-color: ${({ color, faded, theme }) =>
    color
      ? faded
        ? theme.colors[color].faded
        : theme.colors[color].default
      : theme.colors.background};

  /* background-image: ${({ color, theme }) =>
    color
      ? `linear-gradient(to right top, ${theme.colors[color].dark}, ${theme.colors[color].default}, ${theme.colors[color].faded})`
      : 'none'}; */

  min-height: ${({ height }) =>
    height === 'tiny' ? '145px' : height === 'small' ? '400px' : '700px'};
  -webkit-transform: rotate(-${angle}deg);
  -ms-transform: rotate(-${angle}deg);
  transform: rotate(-${angle}deg);
  width: 150%;
  margin-left: 50%;
  padding-top: 60px;
  position: relative;
  left: -75%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-image: url(${({ color }) => color && `"${lines[color]}"`}),
    ${({ color, theme }) =>
      color
        ? color === 'default'
          ? `linear-gradient(269.34deg, #1fc580 8.92%, #23ca85 95.27%)`
          : `linear-gradient(to right top, ${theme.colors[color].dark}, ${theme.colors[color].default}, ${theme.colors[color].faded})`
        : 'none'};
`;

export const Content = styled.div<IContentProps>`
  -webkit-transform: rotate(${angle}deg);
  -ms-transform: rotate(${angle}deg);
  transform: rotate(${angle}deg);
  /* This is to fix the angle box width when it is not full width */
  width: ${({ fullWidth }) => (fullWidth ? '90vw' : '100%')};
  padding: 0 23px 0 15px;

  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.largeHeading};
    font-weight: ${({ theme }) => theme.fontWeight.default};
    padding-bottom: 30px;
    line-height: 50px;
  }

  ${Span} {
    font-size: ${({ theme }) => theme.fontSize.default};
    display: block;
    line-height: 24px;
  }

  ${ButtonStyled} {
    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.white.default};
    }
  }
`;
