import React, { useContext, useState } from 'react';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useForm } from 'react-hook-form';
import { LayoutPage } from '../../shared/layout';
import { theme } from '../../../styles/theme';
import { SmallButton } from '../ingredient/Ingredient.styles';
import { Button } from '../../shared/button';
import { Header, Form, Container } from '../../pages/profile/Profile.styles';
import { SelectList } from '../../shared/formElements';
import {
  RecipeCategory,
  RecipeDocument,
  useRecipeQuery,
  useSignS3Mutation,
  useUpdateRecipeCategoryMutation,
} from '../../../generated/graphql';
import { DotsLoading, LogoLoading } from '../../shared/loading';
import axios from 'axios';
import {
  capitalizeFirstLetterPerWord,
  formatS3ImageFilename,
  getTodaysDate,
} from '../../../utils/helper';
import { ImageUpload } from '../../shared/formElements/imageUpload';

type IRecipeCategoryProps = keyof typeof RecipeCategory | string;

interface IRecipeCategoriesProps {
  label: IRecipeCategoryProps;
  value: IRecipeCategoryProps;
}

const EditRecipeTemplate = () => {
  const [imageDeleted, setImageDeleted] = useState(false);
  const {
    selectedRecipe,
    selectedVenueObject,
    appWidth,
    dispatch,
  } = useContext(LayoutContext);
  const { data, loading } = useRecipeQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
        recipeId: selectedRecipe!,
      },
    },
  });
  const [
    UpdateRecipeCategoryMutation,
    updateRecipeCategory,
  ] = useUpdateRecipeCategoryMutation();
  const [SignS3Mutation, signS3] = useSignS3Mutation();

  const {
    errors,
    control,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const recipeData = data?.recipe.recipe;

  const handleCancel = () => {
    dispatch({ type: 'TOGGLE_SLIDER' });
  };

  const uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    try {
      const response = await axios.put(signedRequest, file, options);
      return response;
    } catch (error) {
      return error;
    }
  };

  const formatFilename = (filename) => {
    const todayDateTime = getTodaysDate('-', true);
    const randomString = Math.random().toString(36).substring(2, 7);
    const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
    const newFilename = `images/${todayDateTime}-${randomString}-${cleanFileName}`;
    const name = newFilename.substring(0, 60);
    return name;
  };

  const onSubmit = async (formData) => {
    const { category, uploadedImages } = formData;

    try {
      let imageUrl = '';
      if (uploadedImages && uploadedImages[0]) {
        const imageToUpload = uploadedImages[0];
        const response = await SignS3Mutation({
          variables: {
            input: {
              fileName: formatFilename(imageToUpload.name),
              fileType: imageToUpload.type,
              currentImageKey:
                recipeData?.imageUrl &&
                formatS3ImageFilename(recipeData?.imageUrl, true),
            },
          },
        });
        if (response.data) {
          const { signedRequest, url } = response.data.signS3;
          imageUrl = url;
          await uploadToS3(imageToUpload, signedRequest);
        }
      }

      // Check if image was deleted ot just not updated
      const image = imageUrl
        ? imageUrl
        : imageDeleted
        ? null
        : recipeData?.imageUrl;

      const input = {
        id: selectedRecipe!,
        category: category || null,
        imageUrl: image,
      };

      await UpdateRecipeCategoryMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: RecipeDocument,
            variables: {
              input: {
                venueId: selectedVenueObject?.id!,
                recipeId: selectedRecipe!,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      dispatch({ type: 'TOGGLE_SLIDER' });
    } catch (err) {
      console.log(err);
    }
  };

  if (loading || !data?.recipe?.recipe?.id) {
    return <LogoLoading size={60} />;
  }

  const recipeCategories: IRecipeCategoriesProps[] = (Object.keys(
    RecipeCategory
  ) as Array<keyof typeof RecipeCategory>).map((category) => {
    return {
      label: capitalizeFirstLetterPerWord(RecipeCategory[category], true),
      value: RecipeCategory[category],
    };
  });

  recipeCategories.unshift({ label: 'None', value: '' });

  const isLoading = signS3.loading || updateRecipeCategory.loading;

  return (
    <LayoutPage
      backButton
      backButtonCTA={handleCancel}
      align="center"
      withLine
      heading="Edit Recipe Template"
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={handleCancel}>
                x
              </Button>,
              <SmallButton
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) => (loading ? 'Saving' : 'Save')}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </SmallButton>,
            ]
          : [
              <Button color="default" inversed onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) => `${loading ? 'Saving' : 'Save'} Template`}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <Container>
        <Header>{data.recipe.recipe.displayName} Template</Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <SelectList
            control={control}
            name="category"
            label="Recipe Category"
            options={recipeCategories}
            defaultValue={recipeData?.category}
            errorText={errors.position && errors.position.message}
          />
          <ImageUpload
            control={control}
            name="uploadedImages"
            watch={watch}
            clearErrors={clearErrors}
            setError={setError}
            setValue={setValue}
            error={errors}
            label="Upload Image"
            onSubmit={onSubmit}
            defaultValue={recipeData?.imageUrl}
            onDelete={() => setImageDeleted(true)}
          />
        </Form>
      </Container>
    </LayoutPage>
  );
};

export default EditRecipeTemplate;
