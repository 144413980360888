import styled from 'styled-components';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { device } from '../../../../../styles/device';

export const RowLabel = styled(Label)`
  display: block;
  margin: 10px 0;

  @media ${device.mobileL} {
    display: flex;
    align-items: center;
    height: 40px;
  }
`;

export const Description = styled(Span)`
  display: block;
  padding-left: 0px;

  @media ${device.mobileL} {
    padding-left: 4px;
  }
`;
