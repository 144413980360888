import styled from 'styled-components/macro';
import { IContainerProps } from '../Layout.types';

export const LayoutContainer = styled.section<IContainerProps>`
  width: inherit;
  overflow-y: auto;
  opacity: ${({ isActive }) => (!isActive ? '0.5' : '1')};
  -webkit-transition: opacity 0.7s ease-out;
  -moz-transition: opacity 0.7s ease-out;
  -o-transition: opacity 0.7s ease-out;
  transition: opacity 0.7s ease-out;
`;
