import styled from 'styled-components/macro';
import { LayoutColumnContainer } from '../../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../../../../styles/device';
import {
  Label,
  Span,
  A,
} from '../../../../../shared/typefaces/Typefaces.styles';
import { IColProps } from '../detail.types';

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li<IColProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
  padding: 15px 5px;

  &:first-child {
    justify-content: center;
    border: 0;
    margin: 0;
  }
  &:nth-child(2) {
    justify-content: flex-start;
    padding-left: 0;
  }

  &.icons {
    padding: 0;
    align-items: ${({ toggle }) => (toggle ? 'flex-start' : 'center')};
  }

  @media ${device.tablet} {
    &:first-child {
      justify-content: center;
      border: 0;
      margin: 0;
    }
    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 0;
    }
  }
`;

export const Row = styled.ul`
  min-height: 70px;
  display: grid;
  grid-template-columns: 50px auto 50px;

  @media ${device.tablet} {
    grid-template-columns: 70px auto 50px;
  }
`;

export const Icon = styled.i`
  height: 40px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const MenuItems = styled(Label)`
  display: block;
  margin-top: 5px;
`;

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: 15px;
  padding: 0;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 15px;
    grid-gap: 0;
  }
`;

export const BrandRow = styled.section`
  display: flex;
  flex-direction: column;

  .title {
    padding-bottom: 15px;
  }
`;

export const BrandDetails = styled.section`
  ${Span}, ${A} {
    display: block;
    padding: 5px 0;
    font-size: ${({ theme }) => theme.fontSize.small};

    @media ${device.tablet} {
      font-size: inherit;
    }
  }
`;

export const Radio = styled.input`
  + ${Icon} {
    svg {
      fill: ${({ theme }) => theme.colors.default.default};
      transform: scale(0.5);
    }

    &:hover {
      cursor: pointer;
      svg {
        fill: ${({ theme }) => theme.colors.secondary.default};
        transform: scale(0.8);
      }
    }
  }
  &:checked + i {
    display: flex;
    svg {
      fill: ${({ theme }) => theme.colors.secondary.default};
      transform: scale(1);
    }

    &:hover {
      svg {
        transform: scale(1);
      }
    }
  }
`;

export const LabelWrap = styled.label`
  &:last-child {
    ${Col} {
      border: 0;
    }
  }
  cursor: pointer;
`;
