import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export const Container = styled.div`
  .contentContainer {
    margin-bottom: 50px;
  }

  .content {
    background: ${({ theme }) => theme.colors.backgroundFaded};
    border-radius: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading {
    font-size: 30px;
    text-align: center;
  }

  .subHeading {
    font-size: ${({ theme }) => theme.fontSize.medium};
    color: ${({ theme }) => theme.colors.text.faded};
    text-align: center;
    max-width: 500px;
    margin-bottom: 50px;
  }

  .CTA {
    padding: 0 40px;
    margin-bottom: 50px;
  }

  @media ${device.mobileL} {
    .content {
      padding: 50px;
    }

    .CTA {
      padding: 0 40px;
      margin-bottom: 10px;
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  .partyIcon {
    width: 60px;
    height: 60px;
    margin-left: 20px;
  }
`;
