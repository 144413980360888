import {
  convertCostCleanly,
  convertGramsCleanly,
  getRecipeIngredientGrams,
  isEmpty,
} from '../helper';
import {
  IRecipeIngredientDataArgs,
  IRecipeIngredientDataResult,
  IRecipeIngredientDataResultItem,
} from './getRecipeIngredientData.types';
import { IRecipeIngredient, IVenueData } from './sharedTypes';

export function getRecipeIngredientsData({
  recipeIngredients,
  venueData,
  recipeWastage,
}: IRecipeIngredientDataArgs): IRecipeIngredientDataResult {
  const ingredientsDataArray: IRecipeIngredientDataResultItem[] = [];
  let wastageType: 'recipe' | 'ingredient' | 'venue' | undefined = undefined;

  const costs: {
    foodCost: number;
    wastageCost: number;
  }[] = recipeIngredients.map((recipeIngredient) => {
    const ingredientProduct = venueData?.ingredientProducts?.find(
      (ingredient) =>
        ingredient.ingredient.id === recipeIngredient.ingredient.id
    );

    const ingredientResult: IRecipeIngredientDataResultItem = {
      id: recipeIngredient.ingredient.id,
      displayName: recipeIngredient.ingredient.displayName,
      recipeCost: undefined,
      recipeGrams: undefined,
      purchaseCost: undefined,
      purchaseGrams: undefined,
      contribution: undefined,
      order: recipeIngredient.order,
    };

    // ingredient exists in the venue
    if (ingredientProduct) {
      const ingredientGrams = getRecipeIngredientGrams(
        recipeIngredient.unit,
        recipeIngredient.quantity,
        ingredientProduct.ingredient.metrics
      )!;

      let rCost = 0;
      let wCost = 0;
      if (!isEmpty(ingredientProduct.size) && ingredientProduct.size) {
        if (
          venueData.avgWastagePercentage &&
          (isEmpty(ingredientProduct.ingredient.averageWastagePercentage) ||
            !ingredientProduct.ingredient.averageWastagePercentage) &&
          (isEmpty(recipeWastage) || !recipeWastage)
        ) {
          wCost =
            ingredientProduct.size!.productCost *
            (venueData.avgWastagePercentage / 100);

          rCost =
            ((ingredientProduct.size.productCost + wCost) /
              ingredientProduct.size.productGrams) *
            ingredientGrams;

          if (wastageType !== 'ingredient') {
            wastageType = 'venue';
          }
        } else {
          if (isEmpty(recipeWastage) || !recipeWastage) {
            wCost =
              ingredientProduct.size!.productCost *
              (ingredientProduct.ingredient.averageWastagePercentage / 100);
            wastageType = 'ingredient';
          } else {
            wCost = ingredientProduct.size!.productCost * (recipeWastage / 100);
            wastageType = 'recipe';
          }

          rCost =
            ((ingredientProduct.size.productCost + wCost) /
              ingredientProduct.size.productGrams) *
            (ingredientGrams || 0);
        }
      } else {
        if (isEmpty(recipeWastage) || !recipeWastage) {
          wCost =
            ingredientProduct.ingredient.averageCost100g *
            ((venueData.avgWastagePercentage || 0) / 100);
          if (wastageType !== 'ingredient') {
            wastageType = 'venue';
          }
        } else {
          // Recipe wastage is then calcualted in getRecipeData
          wastageType = 'recipe';
        }

        if (ingredientGrams) {
          rCost =
            ((ingredientProduct.ingredient.averageCost100g + wCost) / 100) *
            ingredientGrams;
        }
      }

      ingredientResult.recipeCost = convertCostCleanly(rCost);
      ingredientResult.recipeGrams = convertGramsCleanly(ingredientGrams);
      ingredientResult.purchaseCost =
        ingredientProduct.size && !isEmpty(ingredientProduct.size)
          ? convertCostCleanly(ingredientProduct.size.productCost)
          : '';
      ingredientResult.purchaseGrams = !isEmpty(ingredientProduct.size)
        ? convertGramsCleanly(ingredientProduct.size!.productGrams)
        : '';

      ingredientsDataArray.push(ingredientResult);

      return {
        foodCost: rCost,
        wastageCost: wCost,
      };

      // Ingredient not yet created
    } else {
      const { wCost, rCost, ingredientGrams } = calculateCosts(
        recipeIngredient,
        venueData
      );

      ingredientResult.recipeCost = convertCostCleanly(rCost);
      ingredientResult.recipeGrams = convertGramsCleanly(ingredientGrams);
      ingredientResult.purchaseCost = !isEmpty(
        recipeIngredient.ingredient.averageCost100g
      )
        ? convertCostCleanly(recipeIngredient.ingredient.averageCost100g)
        : '';
      ingredientResult.purchaseGrams = !isEmpty(
        recipeIngredient.ingredient.averageCost100g
      )
        ? convertGramsCleanly(100)
        : '';

      ingredientsDataArray.push(ingredientResult);

      return {
        foodCost: rCost,
        wastageCost: wCost,
      };
    }
  });

  const { foodCost, wastageCost } = costs.reduce(
    (acc, curr) => ({
      foodCost: acc.foodCost + curr.foodCost,
      wastageCost: acc.wastageCost + curr.wastageCost,
    }),
    { foodCost: 0, wastageCost: 0 }
  );

  ingredientsDataArray.sort((step) => step.order);

  return {
    ingredientsDataArray,
    foodCost,
    wastageCost,
    wastageType,
  };
}

function calculateCosts(
  recipeIngredient: IRecipeIngredient,
  venueData: IVenueData
) {
  const ingredientGrams = getRecipeIngredientGrams(
    recipeIngredient.unit,
    recipeIngredient.quantity,
    recipeIngredient.ingredient.metrics
  )!;

  if (
    isEmpty(recipeIngredient.ingredient.averageCost100g) ||
    !recipeIngredient.ingredient.averageCost100g
  ) {
    return { wCost: 0, rCost: 0, ingredientGrams };
  }

  const wastagePercentage =
    venueData.avgWastagePercentage ||
    recipeIngredient.ingredient.averageWastagePercentage;

  const wCost =
    recipeIngredient.ingredient.averageCost100g * (wastagePercentage / 100);

  const rCost =
    ((recipeIngredient.ingredient.averageCost100g + wCost) / 100) *
    ingredientGrams;

  return { rCost, wCost, ingredientGrams };
}
