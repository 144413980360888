import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export interface IArrowProps {
  rotate?: boolean;
}

interface IImageContainerProps {
  isLoading?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.grey.lightest};
  padding: 25px 25px 20px;
  border-radius: 0 0 20px 20px;
  margin-bottom: 20px;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  .heading {
    margin-bottom: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 25px;
  max-width: 215px;
  cursor: pointer;

  .text {
    color: ${({ theme }) => theme.colors.text.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    display: flex;
    align-items: center;

    .loadingDots {
      margin-left: 10px;
    }
  }
`;

export const Items = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;

  ${Item}:last-child {
    padding-right: 20px;
  }
`;

export const ImageContainer = styled.div<IImageContainerProps>`
  width: 215px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  outline: 1px solid rgba(255, 255, 255, 0.4);
  outline-offset: -1px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.colors.white.default};
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  @keyframes gradient-animation {
    from {
      left: -100%;
    }
    to {
      left: 100%;
    }
  }

  ${({ isLoading }) =>
    isLoading &&
    `
    &::after {
      content: "";
      position: absolute;
      left: 0%;
      top: 0;
      height: 100%;
      width: 200px;
      background: linear-gradient(to right, #F8F8F8 25%, #eee 50%, #F8F8F8 100%);
      filter: blur(25px);
      animation: gradient-animation 2s ease infinite;
    }
  `}
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 5px;
`;

export const ArrowContainer = styled.div<IArrowProps>`
  position: absolute;
  right: 2px;
  bottom: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white.default};
  width: 20px;
  height: 20px;
  border-radius: 30px;
  box-shadow: ${({ theme }) => theme.shadow.smallBox};

  &.left {
    left: 2px;
  }

  @media ${device.tablet} {
    right: 15px;
    width: 30px;
    height: 30px;

    &.left {
      left: 15px;
    }
  }
`;

export const Highlight = styled.div`
  position: absolute;
  top: 10px;
  background-color: ${({ theme }) => theme.colors.primary.default};
  padding: 5px 10px;
  border-radius: 10px;
  left: 10px;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
  color: white;
`;
