import styled from 'styled-components/macro';
import { AccountType } from '../../../../../generated/graphql';
import { device } from '../../../../../styles/device';
import { H3, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { getAccountTypeColor } from '../planOption/PlanOption.styles';

interface IContainerProps {
  noMaxWidth: boolean;
  desktopOnly: boolean;
}

interface IHeadingProps {
  alignLeft: boolean;
  bold: boolean;
  type?: AccountType;
}

export const Container = styled.div<IContainerProps>`
  display: ${({ desktopOnly }) => (desktopOnly ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  max-width: auto;
  margin-bottom: 120px;
  border: 1px solid ${({ theme }) => theme.colors.default.faded};
  padding: 20px;
  border-radius: ${({ theme }) => theme.layout.radiusSmallest};

  .CTA {
    margin: 0 10px;
  }

  @media ${device.laptopL} {
    display: flex;
    max-width: ${({ noMaxWidth }) => (noMaxWidth ? 'auto' : '180px')};
    border: none;
    padding: 5px;
  }
`;

export const Heading = styled(H3)<IHeadingProps>`
  display: flex;
  flex-shrink: 0;
  justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'center')};
  align-items: center;
  font-size: ${({ theme, bold }) =>
    bold ? theme.fontSize.large : theme.fontSize.medium};
  color: ${({ type, theme }) => getAccountTypeColor(theme, type)};
  height: 50px;
  width: 100%;
  font-weight: ${({ theme, bold }) =>
    bold ? theme.fontWeight.bold : theme.fontWeight.regular};
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeight.heading}; ;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;

  @media ${device.laptopL} {
    flex-direction: row;
  }
`;

export const Labels = styled.div`
  @media ${device.laptopL} {
    display: none;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.colors.secondary.default};
  padding: 40px 0 0;
  width: 100%;
`;

export const Price = styled(Span)`
  font-size: ${({ theme }) => theme.fontSize.largeHeading};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: ${({ theme }) => theme.lineHeight.largeHeading};
  color: ${({ theme }) => theme.colors.text.grey};
  padding: 20px 5px;
`;

export const SubHeading = styled(Span)`
  font-size: ${({ theme }) => theme.fontSize.default};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.colors.text.grey};
`;

export const Text = styled(Span)`
  font-size: ${({ theme }) => theme.fontSize.default};
  font-weight: ${({ theme }) => theme.fontWeight.default};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  color: ${({ theme }) => theme.colors.text.grey};
  flex-shrink: 0;
  text-align: center;
  padding: 60px 10px 0;
  max-width: 500px;
  height: 150px;

  @media ${device.laptopL} {
    width: 100%;
    max-width: auto;
    height: 190px;
  }
`;

export const Footer = styled.div`
  margin-top: 60px;
`;
