import React, { FC, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { useClickedOutsideAlert } from '../../../../utils/customHooks/useClickedOutsideAlert';
import { Button } from '../../button';
import { ErrorIcon } from '../../icons';
import { Span } from '../../typefaces/Typefaces.styles';
import * as S from './Modal.styles';

interface ICTAProps {
  label: string;
  CTA: () => void;
}

interface IModalProps {
  showModal: boolean;
  heading: string;
  content: string;
  handleClose: () => void;
  CTA?: ICTAProps;
  cancelCTA?: ICTAProps;
  component?: React.ReactNode;
}

export const Modal: FC<IModalProps> = ({
  showModal,
  heading,
  content,
  CTA,
  cancelCTA,
  component,
  handleClose,
}) => {
  const { isOpen, sliderToggle } = useContext(LayoutContext);
  const ref = useRef(null);
  const location = useLocation();

  useClickedOutsideAlert({ ref, handleClick: handleClose });

  return (
    <S.Container
      showModal={showModal}
      sliderToggle={sliderToggle}
      isOpen={isOpen}
      fullWidth={location.pathname === '/'}
    >
      <S.CardStyled ref={ref}>
        <ErrorIcon size={'small'} className="close" onClick={handleClose} />
        <S.Content>
          <S.Heading>{heading}</S.Heading>
          <S.SubHeading color="faded">{content}</S.SubHeading>
          {CTA && (
            <Button onClick={CTA.CTA} color="primary">
              {CTA.label}
            </Button>
          )}
          {cancelCTA && <Span onClick={cancelCTA?.CTA}>{cancelCTA.label}</Span>}
          {component}
        </S.Content>
      </S.CardStyled>
    </S.Container>
  );
};
