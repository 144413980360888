import React, { useContext } from 'react';
import { LayoutPage } from '../../shared/layout';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useInventoryStocktakeQuery } from '../../../generated/graphql';
import Header from './sections/header';
import { AnchorButton } from '../../shared/button/Button.styles';
import IngredientList from './sections/ingredientList';

const ShoppingList = () => {
  const {
    selectedVenueObject,
    toolTip: { show },
  } = useContext(LayoutContext);

  const stocktake = useInventoryStocktakeQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id || '',
      },
    },
    fetchPolicy: 'network-only',
  });

  const stocktakeData = stocktake.data?.inventoryStocktake.stocktake;
  const stocktakeUrl = stocktakeData
    ? `/stocktake/${stocktakeData?.id}`
    : `/stocktakes`;

  return (
    <LayoutPage
      align={show ? 'left' : 'center'}
      withLine
      heading="Shopping List"
      subHeading={
        selectedVenueObject?.displayName
          ? `For ${selectedVenueObject.displayName} Venue`
          : 'For Venue'
      }
      actionArray={
        (stocktakeData && [
          <AnchorButton href="/inventory" color="secondary">
            Inventory
          </AnchorButton>,
          <AnchorButton href={stocktakeUrl} color="primary">
            See Stocktake
          </AnchorButton>,
        ]) ||
        undefined
      }
    >
      <Header stocktake={stocktake} />
      {stocktake && <IngredientList stocktake={stocktake} />}
    </LayoutPage>
  );
};

export default ShoppingList;
