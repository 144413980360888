import styled from 'styled-components/macro';
import { IHeadingsProps, IRecipeNameProps } from './header.types';
import { device } from '../../../../../styles/device';
import { Input } from '../../../../shared/formElements';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 30px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  .headerLabel {
    margin-top: 10px;
    @media ${device.tablet} {
      margin-top: 15px;
    }
  }
`;

export const TopSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const HeadingsItem = styled.li`
  display: grid;
  grid-template-rows: 40px auto;
  grid-gap: 10px;
  padding: ${({ theme }) => theme.layout.pagePadding};

  &.title {
    ${TopSection} {
      display: flex;
      align-items: center;
      .headerLabel {
        margin-top: 0;
        padding-right: 10px;
      }
    }
    @media ${device.tablet} {
      ${TopSection} {
        margin-top: 15px;
        align-items: flex-start;
      }
    }
  }

  h2 {
    @media ${device.tablet} {
      padding-right: 17px;
    }
  }

  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const Headings = styled.ul<IHeadingsProps>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${({ align }) =>
    align === 'right' ? 'auto auto auto' : 'auto'};

  ${HeadingsItem} {
    &:first-child {
      text-align: left;
      justify-content: flex-start;
      @media (min-width: 900px) {
        ${({ align }) => align === 'right' && 'text-align: right'};
      }
    }
    &:nth-child(2) {
      text-align: center;
      justify-content: center;
      @media (min-width: 900px) {
        ${({ align }) => align === 'right' && 'text-align: right'};
      }
    }

    &:last-child {
      justify-content: flex-end;
      ${({ align }) => align === 'right' && 'text-align: right'};
    }
  }
`;

export const StyledInput = styled(Input)`
  .MuiTextField-root {
  }

  margin: 0;
  min-height: auto;

  .MuiOutlinedInput-root {
    height: 37px;

    .MuiOutlinedInput-input {
      padding: 0;
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }

    &:hover,
    &:focus {
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }

    input {
      letter-spacing: normal;
    }
  }

  input {
    color: ${({ theme }) => theme.colors.text.black} !important;
    font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
    font-size: ${({ theme }) => theme.fontSize.heading} !important;
    font-family: ${({ theme }) => theme.fontFamily} !important;
    padding-right: 0;

    &::placeholder {
      font-weight: ${({ theme }) => theme.fontWeight.regular} !important;
    }
  }
`;

export const HeaderNumber = styled(Span)`
  padding-top: 2px;
`;

export const RecipeName = styled.div<IRecipeNameProps>`
  border: 2px solid
    ${({ showBorder, theme }) =>
      showBorder ? theme.colors.default.default : theme.colors.white.default};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.default.default};
  }

  .hide {
    display: none;
  }
`;
