import styled from 'styled-components/macro';
import { Card } from '../../shared/card';
import { LayoutColumnContainer } from '../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../styles/device';

export const Container = styled(Card)`
  max-width: 500px;
  margin: 30px auto;
`;

export const Header = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 14px 14px 0 0;
  padding: 5px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const Form = styled.form`
  padding: 15px;
  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto;

    @media ${device.tablet} {
      grid-template-columns: 160px auto;
    }
  }
`;

export const FormTop = styled.div`
  margin-bottom: 15px;
`;
