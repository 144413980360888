import React, { FC } from 'react';
import * as S from './FAQ.styles';
import { Question } from './sections';
import { IQuestionProps } from './sections/question/Question';

interface IFAQProps {
  questions: IQuestionProps[];
}

export const FAQ: FC<IFAQProps> = ({ questions }) => {
  return (
    <S.Container>
      {questions.map(({ question, answer }, idx) => {
        return question && answer ? (
          <Question question={question} answer={answer} key={idx} />
        ) : null;
      })}
    </S.Container>
  );
};
