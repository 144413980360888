import styled from 'styled-components/macro';
import { Button } from '../../../../shared/button';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Input } from '../../../../shared/formElements';
import { device } from '../../../../../styles/device';
import { IButtonContainerProps, IInputWrapperProps } from './header.types';

export const Container = styled.section`
  padding: 15px;
  padding-bottom: 30px;
  display: block;

  @media ${device.mobileM} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const HeaderInputs = styled.section`
  .clickable:hover {
    cursor: pointer;
  }

  @media ${device.mobileM} {
    max-width: 170px;
  }
  @media ${device.tablet} {
    max-width: 255px;
  }
`;

export const SectionNameButton = styled(Button)`
  display: block;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-width: 100%;
  height: 174px;
  border-radius: 10px;

  @media ${device.mobileM} {
    width: 240px;
    margin-top: ${({ theme }) => theme.form.input.marginTop};
  }

  @media ${device.mobileL} {
    display: flex;
    margin-top: ${({ theme }) => theme.form.input.marginTop};
  }
`;

export const ButtonContainer = styled.div<IButtonContainerProps>`
  display: ${({ hideButton }) => (hideButton ? 'none' : 'block')};

  margin-bottom: 15px;
  @media ${device.mobileM} {
    width: 240px;
  }

  @media ${device.mobileL} {
    width: 300px;
  }
`;

export const ErrorContainer = styled.div`
  border-left: 4px solid red;
  padding: 5px 10px;
  margin-bottom: 30px;

  @media ${device.mobileM} {
    width: 240px;
  }

  @media ${device.mobileL} {
    width: 300px;
  }
`;

export const RecipeError = styled(Span)`
  color: red;
  font-size: ${({ theme }) => theme.fontSize.button};
`;

export const InputWrapper = styled.div<IInputWrapperProps>`
  display: ${({ showInput }) => (showInput ? 'block' : 'none')};
`;

export const StyledInput = styled(Input)`
  input {
    color: ${({ theme }) => theme.colors.text.black} !important;
    font-weight: ${({ theme }) => theme.fontWeight.medium} !important;
    font-size: ${({ theme }) => theme.fontSize.large} !important;

    &::placeholder {
      font-weight: ${({ theme }) => theme.fontWeight.regular} !important;
    }
  }
`;

export const Section = styled.section`
  width: 100%;
  max-width: 405px;
  margin-right: 15px;
`;

export const Steps = styled.ul`
  margin-top: 15px !important;
  margin-left: 15px !important;
`;

export const Step = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px !important;
  align-items: center;

  .step {
    font-size: 14px;
    margin-left: 15px;
    line-height: 20px;
  }
`;
export const Icon = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.default};
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .number {
    color: ${({ theme }) => theme.colors.text.white};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const TotalText = styled(Span)`
  text-align: right;
`;
