import styled from 'styled-components/macro';

export interface IContainerProps {
  show: boolean;
  yAxis: number;
  xAxis: number;
  pageWidth: number;
  isOpen: boolean;
  displayType: 'desktop' | 'popout';
  desktopToolTipMaxWidth: number;
  sliderPageIsOpen: boolean;
}

export const ToolTipStyled = styled.div`
  position: relative;
  border-left: 1px solid ${({ theme }) => theme.colors.default.default};

  &:after {
    content: ' ';
    position: absolute;
    top: 15px;
    left: -8px;
    height: 15px;
    width: 15px;
    background: ${({ theme }) => theme.colors.white.default};
    transform: rotate(-45deg);
    border-left: 1px solid ${({ theme }) => theme.colors.default.default};
    border-top: 1px solid ${({ theme }) => theme.colors.default.default};
    border-radius: 2px 0 0 0;
  }

  .heading {
    font-size: 18px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: 10px;
  }

  .content {
    font-size: ${({ theme }) => theme.fontSize.button};
    margin-bottom: 10px;
  }

  .button {
    margin-top: 20px;
  }
`;

export const Container = styled.div<IContainerProps>`
  z-index: ${({ theme }) => theme.zIndex.top};
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: ${({ yAxis }) => `${yAxis}px`};
  right: ${({ displayType }) => (displayType === 'popout' ? '30px' : '15px')};
  left: ${({ displayType, pageWidth, sliderPageIsOpen }) =>
    displayType === 'desktop'
      ? `${pageWidth + 200 + 50 + (sliderPageIsOpen ? 100 : 0)}px`
      : 'initial'};
  width: ${({ desktopToolTipMaxWidth, sliderPageIsOpen }) =>
    desktopToolTipMaxWidth >= 300 && desktopToolTipMaxWidth <= 500
      ? `${
          sliderPageIsOpen
            ? desktopToolTipMaxWidth - 150
            : desktopToolTipMaxWidth - 50
        }px`
      : desktopToolTipMaxWidth >= 500
      ? sliderPageIsOpen
        ? '350px'
        : '450px'
      : sliderPageIsOpen
      ? '350px'
      : '450px'};
  background-color: ${({ theme }) => theme.colors.white.default};
  box-shadow: ${({ theme, displayType }) =>
    displayType === 'popout' ? theme.shadow.smallBox : 'none'};

  ${ToolTipStyled} {
    padding: ${({ displayType }) =>
      displayType === 'popout' ? '15px' : '0 15px 5px'};

    &:after {
      display: ${({ displayType }) =>
        displayType === 'popout' ? 'none' : 'block'};
    }
  }
`;
