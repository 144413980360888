import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { Button } from '../../../../shared/button';
import { IRowProps } from './verifyIngredients.types';

export const BtnLink = styled.a`
  color: ${({ theme }) => theme.colors.text.faded};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 40px;

  &:hover {
    color: ${({ theme }) => theme.colors.text.default};
  }
`;

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li<{ pointer?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 5px;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};

  &:first-child {
    align-items: center;
  }
  &:nth-child(2) {
    align-items: flex-start;
    padding-left: 0;
  }
  &:last-child {
    padding-right: 15px;
  }

  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
`;

export const Row = styled.ul<IRowProps>`
  min-height: 55px;
  display: grid;
  grid-template-columns: ${({ updateIngredient }) =>
    updateIngredient ? 'auto 130px 50px' : ' auto 100px 50px'};

  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto;
    grid-gap: 15px;
    .last {
      text-align: right;
    }
  }

  @media ${device.mobileM} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 210px 50px' : 'auto 100px 80px 50px'};
  }
  @media ${device.mobileL} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 300px 50px' : 'auto 60px 125px 80px 50px'};
    ${LayoutColumnContainer} {
      padding: 0;
      grid-template-columns: auto 2px 50px;
      grid-gap: 15px;
      .last {
        text-align: right;
      }
    }
  }
  @media ${device.tablet} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 300px 50px' : 'auto 150px 160px 80px 50px'};
  }
  @media ${device.laptop} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient
        ? '70px auto 300px 90px'
        : '70px auto 170px 180px 100px 70px'};
  }
`;

export const HeaderRow = styled.ul`
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-columns: auto 90px 50px;
  ${Col} {
    border: 0;
  }

  @media ${device.mobileM} {
    grid-template-columns: auto 105px 130px;
  }
  @media ${device.mobileL} {
    grid-template-columns: auto 105px 125px 130px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto 150px 160px 130px;
  }
  @media ${device.laptop} {
    grid-template-columns: 70px auto 170px 180px 170px;
  }
`;

export const CTAButton = styled(Button)`
  min-width: 182px;
`;

export const Icon = styled.div`
  height: 50px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const Item = styled.div<{ light?: boolean; horizontal?: boolean }>`
  color: ${(props) => (props.light ? '#666' : '#000')};
  display: ${(props) => (props.horizontal ? 'flex' : 'block')};
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
`;

export const MetricNames = styled.div`
  margin-right: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80px;
`;

export const MetricValues = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20px;
`;
