import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { useSubscriptionDataQuery } from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import { Button } from '../../../../shared/button';
import { Card, Content, Header } from '../../../../shared/card';
import { Dollar, Share } from '../../../../shared/icons';
import { DotsLoading } from '../../../../shared/loading';
import {
  Label,
  ListGroup,
  ListGroupRow,
  Span,
} from '../../../../shared/typefaces/Typefaces.styles';
import { Container, ListColumns } from './Billing.styles';
import { capitalizeFirstLetterPerWord } from '../../../../../utils/helper';

const Billing: FC = () => {
  const { appWidth, account, dispatch } = useContext(LayoutContext);
  const { loading, error, data } = useSubscriptionDataQuery();
  const subscriptionData = data?.me?.user;

  if (loading || error || !subscriptionData) {
    const message = (() => {
      if (loading)
        return (
          <DotsLoading
            color="primary"
            size="large"
            isLoading
            className="loadingDots"
          />
        );
      if (error) return `Error: ${error}`;
      return 'Unknown error';
    })();

    return (
      <Container>
        <Card withCardLink>{message}</Card>
      </Container>
    );
  }

  const {
    stripePaymentLast4,
    stripePaymentMethodId,
    stripePaymentExpMonth,
    stripePaymentExpYear,
    billingCycle,
  } = subscriptionData;

  const updateBilling = () => {
    dispatch({ type: 'SET_EDIT_ACCOUNT', payload: undefined });
    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: account,
    });
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'editBilling' });
  };

  return (
    <Container>
      <Card withCardLink>
        <Header
          icon={<Dollar size="small" />}
          heading="Billing Details"
          subHeading="Information about your billing & payment details "
          button={
            appWidth !== 0 && appWidth < theme.mQ.tablet ? (
              <Button color="primary" asCircle onClick={updateBilling}>
                <Share color="white" size="small" />
              </Button>
            ) : (
              <Button
                color={stripePaymentMethodId ? 'default' : 'secondary'}
                inversed={!!stripePaymentMethodId}
                onClick={updateBilling}
              >
                {stripePaymentMethodId
                  ? 'Update Billing'
                  : 'Add Payment Method'}
              </Button>
            )
          }
        />
        <Content fullWidth>
          <DotsLoading isLoading={!stripePaymentMethodId} />
          {stripePaymentMethodId && (
            <ListColumns colOneWidth="1fr" padding="small">
              <ListGroup noBorder className="listGroup">
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : '130px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">Card Number</Label>
                  <Span>**** **** **** {stripePaymentLast4}</Span>
                </ListGroupRow>
                {stripePaymentExpMonth && (
                  <ListGroupRow
                    colOneWidth={
                      appWidth !== 0 && appWidth < theme.mQ.tablet
                        ? '1fr'
                        : '130px'
                    }
                    colTwoWidth={
                      appWidth !== 0 && appWidth < theme.mQ.tablet
                        ? '1fr'
                        : 'auto'
                    }
                  >
                    <Label color="faded">Card Expiry</Label>
                    <Span>
                      {stripePaymentExpMonth >= 10
                        ? stripePaymentExpMonth
                        : `0${stripePaymentExpMonth}`}{' '}
                      / {stripePaymentExpYear}
                    </Span>
                  </ListGroupRow>
                )}
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : '130px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">Subscription</Label>
                  <Span>{capitalizeFirstLetterPerWord(billingCycle)}</Span>
                </ListGroupRow>
              </ListGroup>
            </ListColumns>
          )}
        </Content>
      </Card>
    </Container>
  );
};

export default Billing;
