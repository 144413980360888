import styled from 'styled-components';
import { Card } from '../../shared/card';
import { Label } from '../../shared/typefaces/Typefaces.styles';
import { LayoutColumnContainer } from '../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../styles/device';

interface ISideLabelProps {
  marginTop?: boolean;
}

export const Container = styled(Card)`
  max-width: 530px;
  margin: 30px auto;
`;

export const Header = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 14px 14px 0 0;
  padding: 5px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const Form = styled.form`
  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto;

    @media ${device.tablet} {
      grid-template-columns: 160px auto;
    }
  }
`;

export const SideLabel = styled(Label)<ISideLabelProps>`
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? '25px' : 0)};
`;

export const NestedColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

export const Section = styled.section`
  margin: 30px 15px;
`;

export const DVContainer = styled(Card)`
  max-width: 530px;
  margin: 30px auto;
  border-color: ${({ theme }) => theme.colors.error.default};
`;

export const DVHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.error.faded};
  border-bottom: 1px solid ${({ theme }) => theme.colors.error.default};
  padding: 5px 15px;
  border-radius: 14px 14px 0 0;
  min-height: 50px;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
  background: repeating-linear-gradient(
    45deg,
    rgba(205, 0, 0, 0.7),
    rgba(205, 0, 0, 0.7) 10px,
    rgba(205, 0, 0, 0.8) 10px,
    rgba(205, 0, 0, 0.8) 20px
  );
`;

export const DVContent = styled.div`
  padding: 15px;

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;

    @media ${device.mobileM} {
      flex-direction: row;
    }
  }

  .CTA {
    margin-top: 15px;
    background-color: ${({ theme }) => theme.colors.error.faded};

    &:hover {
      background-color: ${({ theme }) => theme.colors.error.default};
    }

    @media ${device.mobileM} {
      margin-top: 12px;
      margin-left: 10px;
    }
  }
`;
