import React, { FC, useState, useContext } from 'react';
import {
  Col,
  SupplierRow,
  SupplierDetails,
  Icon,
  Row,
  Radio,
  LabelWrap,
  Header,
} from './SupplierItem.styles';
import { Span } from '../../../../../shared/typefaces/Typefaces.styles';
import { Edit, Tick, Arrow, Dot } from '../../../../../shared/icons';
import { useFormContext } from 'react-hook-form';
import { LayoutColumn } from '../../../../../shared/layout';
import { LayoutContext } from '../../../../../../contexts/layoutContext';
import { theme } from '../../../../../../styles/theme';
import { isEmpty, convertCostCleanly } from '../../../../../../utils/helper';
import { ISupplierItemTypes } from './supplierItem.types';

const SupplierItem: FC<ISupplierItemTypes> = ({
  col,
  selectedBrandSuppliers,
}) => {
  const [toggle, setToggle] = useState(false);
  const { register, watch } = useFormContext();
  const { appWidth, dispatch } = useContext(LayoutContext);

  const toggleChecked = watch('supplier');

  const editSupplier = (supplierId: string) => {
    dispatch({ type: 'SELECT_SUPPLIER', payload: supplierId });
    dispatch({ type: 'SET_SUPPLIER_USER_EVENT', payload: 'edit' });
    dispatch({ type: 'TOP_SLIDER_PAGE', payload: 'supplier' });
  };

  const getRowHeader = (selected: boolean) => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <Header>
          <Span
            color={
              selected || toggleChecked === col.supplierId ? 'black' : 'faded'
            }
            className={toggle ? 'title' : ''}
          >
            {col.supplier.displayName}
          </Span>
          <LayoutColumn>
            <Span
              color={
                selected || toggleChecked === col.supplierId ? 'grey' : 'faded'
              }
              className="last"
            >
              {convertCostCleanly(col.size.productCost)}
            </Span>
          </LayoutColumn>
        </Header>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <Header>
          <Span
            color={
              selected || toggleChecked === col.supplierId ? 'black' : 'faded'
            }
            className={toggle ? 'title' : ''}
          >
            {col.supplier.displayName}
          </Span>
          <LayoutColumn>
            <Span
              color={
                selected || toggleChecked === col.supplierId ? 'grey' : 'faded'
              }
              className="last"
            >
              {convertCostCleanly(col.size.productCost)}
            </Span>
          </LayoutColumn>
        </Header>
      );
    } else {
      return (
        <Header>
          <Span
            color={
              selected || toggleChecked === col.supplierId ? 'black' : 'faded'
            }
            className={toggle ? 'title' : ''}
          >
            {col.supplier.displayName}
          </Span>
          <LayoutColumn>
            {col.size && (
              <Span
                color={
                  selected || toggleChecked === col.supplierId
                    ? 'grey'
                    : 'faded'
                }
                className="last"
              >
                {convertCostCleanly(col.size.productCost)}
              </Span>
            )}
          </LayoutColumn>
        </Header>
      );
    }
  };

  let selected = true;
  if (!isEmpty(selectedBrandSuppliers)) {
    selected = selectedBrandSuppliers.includes(col.supplier.id);
  }

  return (
    <LabelWrap>
      <Row>
        <Col>
          <Radio
            type="radio"
            name="supplier"
            value={col.supplier.id}
            ref={register}
            hidden
          />
          <Icon>
            {toggleChecked === col.supplier.id ? (
              <Tick size="small" color="secondary" />
            ) : (
              <Dot size="smaller" color="default" />
            )}
          </Icon>
        </Col>
        <Col>
          <SupplierRow>
            {getRowHeader(selected)}
            {toggle && (
              <SupplierDetails>
                {col.supplier.website && (
                  <a
                    href={`//${col.supplier.website}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {col.supplier.website}
                  </a>
                )}
                <Span>{col.supplier.email}</Span>
                <Span>{col.supplier.phone}</Span>
              </SupplierDetails>
            )}
          </SupplierRow>
        </Col>
        <Col className="icons" toggle={toggle}>
          <SupplierRow>
            <Icon onClick={() => setToggle(!toggle)}>
              <Arrow
                size="smaller"
                color="grey"
                faded
                rotate={toggle ? 270 : 90}
              />
            </Icon>
            {toggle && (
              <SupplierDetails>
                <Icon onClick={() => editSupplier(col.supplierId)}>
                  <Edit size="small" color="grey" faded />
                </Icon>
              </SupplierDetails>
            )}
          </SupplierRow>
        </Col>
      </Row>
    </LabelWrap>
  );
};

export default SupplierItem;
