import React, { FC } from 'react';
import { H2, Span, Label } from '../../../../shared/typefaces/Typefaces.styles';
import {
  Container,
  HeaderContainer,
  Headings,
  HeadingsItem,
} from './Header.styles';
import { QueryResult } from '@apollo/client';
import {
  StocktakesQuery,
  Exact,
  GetStocktakesInput,
  StocktakeStatus,
} from '../../../../../generated/graphql';
import { convertNumberCleanly } from '../../../../../utils/helper/numbers';

interface IHeaderProps {
  stocktakes: QueryResult<
    StocktakesQuery,
    Exact<{
      input: GetStocktakesInput;
    }>
  >;
}

const Header: FC<IHeaderProps> = ({ stocktakes }) => {
  const stocktakesCount = stocktakes.data?.stocktakes.stocktakes?.length;
  const confirmedStocktakes = stocktakes.data?.stocktakes.stocktakes?.filter(
    (s) => s.status === StocktakeStatus.Confirmed
  );

  const lastConfirmedStocktake = confirmedStocktakes && confirmedStocktakes[0];

  return (
    <Container>
      <HeaderContainer>
        <Headings>
          <HeadingsItem>
            <Label className="headerLabel" color="faded" fontSize="small">
              Total Stocktakes
            </Label>
            <H2>{convertNumberCleanly(stocktakesCount || 0)}</H2>
          </HeadingsItem>
          <HeadingsItem>
            <Label className=" headerLabel" color="faded" fontSize="small">
              Last Confirmed Stocktake
            </Label>
            <Span fontSize="heading">
              {lastConfirmedStocktake?.displayName || '-'}
            </Span>
          </HeadingsItem>
        </Headings>
      </HeaderContainer>
    </Container>
  );
};

export default Header;
