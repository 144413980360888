import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};

  &:first-child {
    justify-content: flex-start;
    margin-left: 15px;
    padding-left: 0;
  }

  @media ${device.mobileM} {
    &:nth-child(3) {
      justify-content: flex-start;
      padding-left: 25px;
    }
  }

  @media ${device.tablet} {
    &:nth-child(4) {
      justify-content: flex-start;
      padding-left: 25px;
    }
  }

  @media ${device.laptop} {
    &:first-child {
      justify-content: center;
      border: 0;
      margin: 0;
    }
    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 0;
    }
    &:nth-child(5) {
      justify-content: flex-start;
      padding-left: 25px;
    }
  }
`;

export const Row = styled.ul`
  min-height: 55px;
  display: grid;
  grid-template-columns: auto 115px 50px;
  cursor: pointer;

  &:first-child {
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 40px;
    ${Col} {
      border: 0;
    }
    cursor: default;
  }

  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundFaded};
    &:last-child {
      border-radius: 0 0 15px 15px;
    }
  }

  @media ${device.mobileM} {
    grid-template-columns: auto 60px 115px 50px;
  }
  @media ${device.mobileL} {
    grid-template-columns: auto 60px 115px 50px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto 100px 135px 115px 50px;
  }
  @media ${device.laptop} {
    grid-template-columns: 70px auto 100px 135px 115px 50px;
  }
`;

export const HeaderRow = styled.ul`
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-columns: auto 165px;
  padding-right: 15px;

  &:first-child {
    ${Col} {
      border: 0;
      &:last-child {
        padding-right: 15px;
      }
    }
  }

  @media ${device.mobileM} {
    grid-template-columns: auto 110px 165px;
  }
  @media ${device.mobileL} {
    grid-template-columns: auto 110px 165px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto 135px 135px 165px;
  }
  @media ${device.laptop} {
    grid-template-columns: 70px auto 210px 135px 165px;
  }
`;

export const Icon = styled.div`
  height: 50px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;
