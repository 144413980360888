import React, { useContext } from 'react';
import { DVContainer, DVContent, DVHeader } from '../Venue.styles';
import { Span } from '../../../shared/typefaces/Typefaces.styles';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { Input } from '../../../shared/formElements';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../shared/button';
import {
  MeDocument,
  useDeleteVenueMutation,
} from '../../../../generated/graphql';
import { DotsLoading } from '../../../shared/loading';
import { useActiveTimeTracker } from '../../../../utils/customHooks/useActiveTimeTracker';

export const DeleteVenue = () => {
  const { selectedVenueObject, dispatch } = useContext(LayoutContext);
  const {
    totalActiveSeconds,
    setInitialActiveSeconds,
  } = useActiveTimeTracker();
  const [deleteVenueMutation, deleteVenue] = useDeleteVenueMutation();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema(selectedVenueObject?.displayName)),
  });

  const onSubmit = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete this your '${selectedVenueObject?.displayName}' venue?`
      )
    ) {
      try {
        const response = await deleteVenueMutation({
          variables: {
            input: {
              venueId: selectedVenueObject?.id!,
            },
          },
          refetchQueries: [
            {
              query: MeDocument,
            },
          ],
          awaitRefetchQueries: true,
          fetchPolicy: 'no-cache',
        });

        if (response.data?.deleteVenue.successful) {
          const venue = response.data.deleteVenue.venue;
          const venueData = {
            id: venue!.id,
            displayName: venue!.displayName,
            address: venue!.address,
            email: venue!.email,
            totalActiveSeconds: totalActiveSeconds,
            recipeProfitIncreasePerYear: venue!.recipeProfitIncreasePerYear,
          };

          dispatch({ type: 'SELECT_VENUE_OBJECT', payload: venueData });
          setInitialActiveSeconds(venueData.totalActiveSeconds || 0);
          dispatch({ type: 'TOGGLE_SLIDER' });
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };
  const isLoading = deleteVenue.loading;

  return (
    <DVContainer>
      <DVHeader>
        <Span fontWeight="semibold" color="white">
          Delete Venue
        </Span>
      </DVHeader>
      <DVContent>
        <Span>
          When deleting a venue, all remaining recipes will be automatically
          added to your personal venue. All stocktake information will be delete
          immediately.
        </Span>
        <br />
        <br />
        <Span>
          To delete your venue please type the venue name{' '}
          <Span fontWeight="semibold">{selectedVenueObject?.displayName}</Span>{' '}
          into the form below
        </Span>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="form">
            <Input
              ref={methods.register}
              label="Venue name"
              name="displayName"
              errorText={methods.errors.displayName?.message}
            />
            <Button
              className="CTA"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              <DotsLoading
                text={(loading) => (loading ? 'Deleting' : 'Delete')}
                size="small"
                lineHeight={10}
                color="default"
                noMargin
                isLoading={isLoading}
              />
            </Button>
          </form>
        </FormProvider>
      </DVContent>
    </DVContainer>
  );
};

const validateDisplayName = (
  selectedVenueName: string,
  displayName?: string | null
) => {
  return selectedVenueName === displayName;
};

const validationSchema = (selectedVenueName) =>
  Yup.object().shape({
    displayName: Yup.string()
      .required('Venue Name is required')
      .typeError('Venue Name is required')
      .test(
        'deleteVenue',
        'Please ensure the Venue name matches identically to delete the venue',
        (value) => validateDisplayName(selectedVenueName, value)
      ),
  });
