import React, { useContext, useEffect, FC } from 'react';
import { LayoutPage } from '../../shared/layout';
import { Button } from '../../shared/button';
import { GoalJourney, Header, RecipeList } from './sections';
import { useRecipesQuery, useVenueQuery } from '../../../generated/graphql';
import { LayoutContext } from '../../../contexts/layoutContext';
import { RouteComponentProps } from 'react-router-dom';
import { overlayConstants } from '../../shared/layout/layoutOverlay/constants';
import { TemplateSelector } from '../../shared/templateSelector';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

const Recipes: FC<RouteComponentProps> = ({ history }) => {
  const {
    account,
    user,
    selectedVenueObject,
    selectedIngredient,
    selectedIngredientProduct,
    selectedRecipe,
    selectedRecipeVersion,
    newRecipeFromTemplate,
    dispatch,
  } = useContext(LayoutContext);
  const { setInitialActiveSeconds } = useActiveTimeTracker();

  const venue = useVenueQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id,
      },
    },
  });

  const recipes = useRecipesQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
  });

  useEffect(() => {
    if (selectedIngredient || selectedIngredientProduct) {
      dispatch({ type: 'SELECT_INGREDIENT', payload: '' });
      dispatch({ type: 'SELECT_INGREDIENT_PRODUCT', payload: '' });
      dispatch({ type: 'SET_NEW_INGREDIENT_NAME', payload: '' });
    }
    if (!selectedVenueObject?.id) {
      const venueData = venue.data?.venue?.userVenue?.venue;
      dispatch({
        type: 'SELECT_VENUE_OBJECT',
        payload: {
          id: venueData?.id,
          displayName: venueData?.displayName,
          address: venueData?.address,
          email: venueData?.email,
          totalActiveSeconds: venue.data?.venue?.userVenue?.totalActiveSeconds,
          recipeProfitIncreasePerYear: venueData?.recipeProfitIncreasePerYear,
        },
      });
      setInitialActiveSeconds(
        venue.data?.venue?.userVenue?.totalActiveSeconds || 0
      );
    }
    if (!newRecipeFromTemplate) {
      if (selectedRecipe) {
        dispatch({ type: 'SELECT_RECIPE', payload: '' });
      }
      if (selectedRecipeVersion) {
        dispatch({ type: 'SELECT_RECIPE_VERSION', payload: '' });
      }
    }
  }, [
    selectedVenueObject?.id,
    venue,
    selectedIngredient,
    selectedIngredientProduct,
    selectedRecipe,
    selectedRecipeVersion,
    newRecipeFromTemplate,
    dispatch,
  ]);

  useEffect(() => {
    dispatch({
      type: 'SET_TOTAL_RECIPES',
      payload: recipes.data?.venueRecipes.recipes.length,
    });
  }, [recipes.data?.venueRecipes.recipes]);

  const handleAddRecipe = () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountAddRecipe,
      });
    }

    dispatch({ type: 'SELECT_RECIPE', payload: '' });
    dispatch({ type: 'SELECT_RECIPE_VERSION', payload: '' });
    history.push('/add-recipe');
  };

  return (
    <LayoutPage
      withLine
      heading="Recipes"
      subHeading={
        selectedVenueObject?.displayName &&
        `${selectedVenueObject.displayName} Recipes`
      }
      actionArray={[
        <Button color="primary" onClick={() => handleAddRecipe()}>
          Add Recipe
        </Button>,
      ]}
    >
      <TemplateSelector />
      <GoalJourney data={recipes} />
      <Header data={recipes} />
      <RecipeList data={recipes} />
    </LayoutPage>
  );
};

export default Recipes;
