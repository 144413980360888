import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 600px;

  .cropper {
    top: 88px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -80px;
  }

  .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }

  .slider {
    padding: 22px 0px;
    width: 50%;
  }

  .MuiSlider-root {
    color: ${({ theme }) => theme.colors.secondary.default};
  }
  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: ${({ theme }) =>
      `0px 0px 0px 14px ${theme.colors.default.transparent}`};
  }
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: ${({ theme }) =>
      ` 0px 0px 0px 8px ${theme.colors.default.transparent}`};
  }
`;

export const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: 'row';
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 10px;
`;
