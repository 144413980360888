import React, { FC, useContext } from 'react';
import {
  ISelectedVenueProps,
  LayoutContext,
} from '../../../../../contexts/layoutContext';
import { useSetSelectedVenueMutation } from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';
import {
  convertCostCleanly,
  convertPercentageCleanly,
  isEmpty,
  stringsMatch,
} from '../../../../../utils/helper';
import {
  getVenueStats,
  useRestrictionCheck,
} from '../../../../../utils/helper/account';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';
import { Button } from '../../../../shared/button';
import { Card, Content, Header } from '../../../../shared/card';
import { Edit, Location, Tick } from '../../../../shared/icons';
import { DotsLoading } from '../../../../shared/loading';
import {
  ColTitle,
  Label,
  Span,
} from '../../../../shared/typefaces/Typefaces.styles';
import { Col, Icon, MenuItems, Row, Table } from './Venues.styles';
import { IVenuesProps } from './venues.types';
import { useActiveTimeTracker } from '../../../../../utils/customHooks/useActiveTimeTracker';

const Venues: FC<IVenuesProps> = ({ data }) => {
  const { appWidth, selectedVenueObject, dispatch } = useContext(LayoutContext);
  const checkVenueRestrictions = useRestrictionCheck(['MAX_VENUES']);
  const [setSelectedVenueMutation] = useSetSelectedVenueMutation();
  const {
    getTotalActiveSeconds,
    setInitialActiveSeconds,
  } = useActiveTimeTracker();
  const venues = data?.data?.me?.user?.venuesSharedWithYou;
  const showRestrictionPopup = useRestrictionPopup();

  const handleAddVenue = () => {
    if (!venues) return;

    const restrictionCheck = checkVenueRestrictions({
      // Adding one venue
      numVenues: venues.length + 1,
    });

    if (restrictionCheck.isPass) {
      dispatch({ type: 'SLIDER_PAGE', payload: 'venue' });
      dispatch({ type: 'ADD_VENUE', payload: true });
    } else {
      showRestrictionPopup({
        ...restrictionPopupMessages.cannotAddVenueOnAccountScreenDueToMaxVenuesLimit,
        subHeading: restrictionCheck.failures.MAX_VENUES?.userMessage,
      });
    }
  };

  const handleEditVenue = (venueData: ISelectedVenueProps) => {
    dispatch({
      type: 'SELECT_VENUE_OBJECT',
      payload: venueData,
    });
    dispatch({ type: 'SLIDER_PAGE', payload: 'venue' });
    dispatch({ type: 'SELECT_VENUE_OBJECT', payload: venueData });
    dispatch({ type: 'ADD_VENUE', payload: false });
  };

  const handleSelectVenue = async (venueData: ISelectedVenueProps) => {
    dispatch({ type: 'SELECT_VENUE_OBJECT', payload: venueData });
    if (venues?.length !== 1) {
      try {
        const response = await setSelectedVenueMutation({
          variables: {
            input: {
              venueId: venueData?.id!,
              totalActiveSeconds: getTotalActiveSeconds(),
              previousVenueId: selectedVenueObject?.id!,
            },
          },
          refetchQueries: ['useVenueQuery'],
        });

        if (response.data?.setSelectedVenue.successful) {
          dispatch({ type: 'SELECT_VENUE_OBJECT', payload: venueData });
          setInitialActiveSeconds(venueData.totalActiveSeconds);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <Row>
          <Col>
            <Label>Venue Name</Label>
          </Col>
          <Col>
            <Label>Avg Food Cost</Label>
          </Col>
          <Col />
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row>
          <Col>
            <Label>Venue Name</Label>
          </Col>
          <Col>
            <Label>Recipe Revenue P / y</Label>
          </Col>
          <Col>
            <Label>Avg Food Cost</Label>
          </Col>
          <Col />
        </Row>
      );
    } else {
      return (
        <Row>
          <Col />
          <Col>
            <Label>Venue Name</Label>
          </Col>
          <Col>
            <Label>Recipe Revenue /YR</Label>
          </Col>
          <Col>
            <Label>Avg Food Cost</Label>
          </Col>
          <Col />
        </Row>
      );
    }
  };

  const getTableRows = ({ venue }) => {
    const {
      totalRevenuePerYear,
      avgFoodCostPercentage,
      totalRecipes,
    } = getVenueStats(venue);

    const venueData = {
      id: venue!.id,
      displayName: venue!.displayName,
      address: venue!.address,
      email: venue!.email,
      totalActiveSeconds: venue!.totalActiveSeconds,
      recipeProfitIncreasePerYear: venue!.recipeProfitIncreasePerYear,
    };

    if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <Row key={venueData.id} onClick={() => handleSelectVenue(venueData)}>
          <Col>
            <div>
              <ColTitle
                selected={stringsMatch(venueData.id, selectedVenueObject?.id)}
              >
                {venueData.displayName}
              </ColTitle>
              {stringsMatch(venueData.id, selectedVenueObject?.id) && (
                <Tick size="smaller" color="secondary" />
              )}
              <MenuItems color="faded" fontSize="small" fontWeight="semibold">
                Total Recipes:{' '}
                <Span color="faded" fontSize="small">
                  {totalRecipes}
                </Span>
              </MenuItems>
            </div>
          </Col>
          <Col>
            <Span>{convertPercentageCleanly(avgFoodCostPercentage)}</Span>
          </Col>
          <Col>
            <Icon onClick={() => handleEditVenue(venueData)}>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row key={venueData.id} onClick={() => handleSelectVenue(venueData)}>
          <Col>
            <div>
              <ColTitle
                selected={stringsMatch(venueData.id, selectedVenueObject?.id)}
              >
                {venueData.displayName}
              </ColTitle>
              {stringsMatch(venueData.id, selectedVenueObject?.id) && (
                <Tick size="smaller" color="secondary" />
              )}
              <MenuItems color="faded" fontSize="small" fontWeight="semibold">
                Total Recipes:{' '}
                <Span color="faded" fontSize="small">
                  {totalRecipes}
                </Span>
              </MenuItems>
            </div>
          </Col>
          <Col>
            <Span>{convertCostCleanly(totalRevenuePerYear)}</Span>
          </Col>
          <Col>
            <Span>{convertPercentageCleanly(avgFoodCostPercentage)}</Span>
          </Col>
          <Col>
            <Icon onClick={() => handleEditVenue(venueData)}>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row key={venueData.id} onClick={() => handleSelectVenue(venueData)}>
          <Col>
            {stringsMatch(venueData.id, selectedVenueObject?.id) && (
              <Tick size="small" color="secondary" />
            )}
          </Col>
          <Col>
            <div>
              <ColTitle
                selected={stringsMatch(venueData.id, selectedVenueObject?.id)}
              >
                {venueData.displayName}
              </ColTitle>
              <MenuItems color="faded" fontSize="small" fontWeight="semibold">
                Total Recipes:{' '}
                <Span color="faded" fontSize="small">
                  {totalRecipes}
                </Span>
              </MenuItems>
            </div>
          </Col>
          <Col>
            <Span>{convertCostCleanly(totalRevenuePerYear)}</Span>
          </Col>
          <Col>
            <Span>{convertPercentageCleanly(avgFoodCostPercentage)}</Span>
          </Col>
          <Col>
            <Icon onClick={() => handleEditVenue(venueData)}>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  };

  // Right now add venue isn't working..
  // Until it works I added edit venue labels
  return (
    <Card withCardLink>
      <Header
        icon={<Location size="small" />}
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? 'Edit Venue' : 'Venues'
        }
        subHeading="Quick overview of venue performances"
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={handleAddVenue}>
              Add
            </Button>
          ) : (
            <Button color="primary" onClick={handleAddVenue}>
              Add Venue
            </Button>
          )
        }
      />
      <Content fullWidth>
        <DotsLoading
          isLoading={data?.loading}
          size="large"
          lineHeight={10}
          color="default"
        />
        {!data?.loading && !isEmpty(venues) && (
          <Table>
            {getTableHeader()}
            {venues!
              .sort((a, b) => {
                var nameA = a.venue.displayName.toLowerCase(),
                  nameB = b.venue.displayName.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((col) => getTableRows(col))}
          </Table>
        )}
      </Content>
    </Card>
  );
};

export default Venues;
