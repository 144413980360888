import { roundNumber, roundTo, roundToWholeNumber } from './numbers';
import { isTimeItem, getTotalTimeSeconds } from './units';

export const getTodaysDate = (formatSpacing?: string, addTime?: boolean) => {
  const dateSpacer = formatSpacing || '/';
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  const hh = today.getHours();
  const min = today.getMinutes();
  const sec = today.getSeconds();
  const date = dd + dateSpacer + mm + dateSpacer + yyyy;
  const time = hh + dateSpacer + min + dateSpacer + sec;

  return addTime ? date + dateSpacer + time : date;
};

export const getTimeTotal = (type: string, formValues) => {
  return formValues.items
    ? formValues.items.map((item) => {
        const time =
          item.type === type &&
          item.quantity &&
          item.quantity > 0 &&
          isTimeItem(item.unit)
            ? getTotalTimeSeconds(item.unit, item.quantity)
            : 0;
        return time;
      })
    : null;
};

export const formatDateFromTimestamp = (
  timestamp: string,
  formatSpacing?: string,
  addTime?: boolean
) => {
  const dateSpacer = formatSpacing || '/';
  var date = new Date(Number(timestamp));

  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  const hh = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();
  const dateFormatted = dd + dateSpacer + mm + dateSpacer + yyyy;
  const time = hh + dateSpacer + min + dateSpacer + sec;

  return addTime ? dateFormatted + dateSpacer + time : dateFormatted;
};

export const convertTimeMinutesCleanly = (time) => {
  let timeText = '-';

  if (Number(time) === 0 || time === undefined) {
    return timeText;
  }
  const minTime = time / 60;
  if (minTime <= 60) {
    timeText = minTime === 1 ? 'min' : 'mins';
    return `${roundTo(minTime)} ${timeText}`;
  } else if (minTime > 60) {
    const hoursTime = minTime / 60;
    timeText = hoursTime === 1 ? 'hour' : 'hours';
    return `${roundTo(hoursTime)} ${timeText}`;
  } else {
    return timeText;
  }
};

export const convertTimeFromSecondsCleanly = (seconds?: number) => {
  let timeValue = seconds || 0;
  let timeText = 'sec';

  if (Number(seconds) === 0 || seconds === undefined) {
    return {
      timeValue,
      timeText,
    };
  }

  const minTime = seconds / 60;
  const hoursTime = minTime / 60;
  const daysTime = hoursTime / 24;

  if (seconds < 60) {
    return {
      timeValue: roundNumber(seconds, 0),
      timeText: seconds === 1 ? 'sec' : 'secs',
    };
  }

  if (minTime < 60) {
    return {
      timeValue: roundNumber(minTime, 0),
      timeText: roundNumber(minTime, 0) === 1 ? 'min' : 'mins',
    };
  }

  if (hoursTime < 24) {
    return {
      timeValue: roundNumber(hoursTime, 0),
      timeText: roundNumber(hoursTime, 0) === 1 ? 'hour' : 'hours',
    };
  }

  return {
    timeValue: roundNumber(daysTime, 0),
    timeText: roundNumber(daysTime, 0) === 1 ? 'day' : 'days',
  };
};

export const validEndDate = (
  endDate?: string
): { endDateIsValid: boolean; remainingDays: number } => {
  if (!endDate) {
    return {
      endDateIsValid: false,
      remainingDays: 0,
    };
  }

  const today = new Date();
  const currentTimestamp = new Date(today).setDate(today.getDate());
  const isValid = currentTimestamp <= Number(endDate);

  //calculate time difference
  var timeDifference = Number(endDate) - currentTimestamp;

  //calculate days difference by dividing total milliseconds in a day
  var daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return {
    endDateIsValid: isValid,
    remainingDays: roundToWholeNumber(daysDifference, 'down'),
  };
};
