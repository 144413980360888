import React, { FC } from 'react';
import fvp from '../../../../../images/fvp3.jpg';
import dish from '../../../../../images/dish-min.png';
import SideContent from '../../../../shared/layout/sideContent';
import { H2, H4 } from '../../../../shared/typefaces/Typefaces.styles';
import { Container, Content } from './SideImageInfo.styles';
import { AnchorButton } from '../../../../shared/button/Button.styles';

interface ISideImageInfoProps {}

export const SideImageInfo: FC<ISideImageInfoProps> = () => {
  return (
    <Container>
      {/* <Header>
        <H3 className="heading">
          Inspire your team today through
          <br />
          Clarity, Connection, Creativity & Culture.
        </H3>
      </Header> */}
      <SideContent imageSide="right" image={fvp} text="Flavour vs. Profit">
        <Content>
          <H2 className="title">
            Quickly Compare & Balance
            <br />
            Your Recipes
          </H2>
          <H4>
            You love food & we love results!
            <br />
            Use our tools to accurately calculate your recipe costs.
          </H4>
          <AnchorButton
            href="#pre-made-recipes"
            color="primary"
            className="CTA"
          >
            Start with profitable recipes
          </AnchorButton>
        </Content>
      </SideContent>
      <SideContent imageSide="left" image={dish}>
        <Content>
          <H2 className="title">
            Great ingredients Create
            <br />
            Great Recipes
          </H2>
          <H4>
            Clear recipe costs help you to improve your ingredient quality
          </H4>
          <AnchorButton href="/select-account" color="primary" className="CTA">
            Create Free Account
          </AnchorButton>
        </Content>
      </SideContent>
    </Container>
  );
};
