import styled from 'styled-components/macro';
import { Checkbox } from '@material-ui/core';

export const commonStyles = {
  defaultHeight: '48px',
  largeHeight: '60px',
};

export const CheckboxStyled = styled(Checkbox)`
  width: 20px;
  color: ${({ theme }) => theme.colors.default.faded};

  &:hover {
    background-color: transparent;
  }

  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.colors.secondary.default};

    &:hover {
      background-color: transparent;
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }
`;
