import { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete';
import { cleanURL, convertPhoneNumberCleanly } from '../helper';

export interface IIAddressErrorsProps {
  addressLookup: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
}

export const useAddress = () => {
  const getAddressValue = (
    address: google.maps.GeocoderResult[],
    value: string,
    selectShortName?: boolean
  ) => {
    if (selectShortName) {
      return address[0].address_components.find((a) => a.types.includes(value))
        ?.short_name;
    }
    return address[0].address_components.find((a) => a.types.includes(value))
      ?.long_name;
  };

  const getAddressFromInputValue = async (address: string) => {
    try {
      const results = await getGeocode({
        address,
      });

      const detailsSearchProps = {
        // Use the "place_id" of suggestion from the dropdown (object), here just taking the first suggestion for brevity
        placeId: results[0].place_id,
        // Specify the return data that you want (optional)
        fields: [
          'formatted_phone_number',
          'international_phone_number',
          'website',
          'name',
        ],
      };

      const placeDetails = (await getDetails(
        detailsSearchProps
      )) as google.maps.places.PlaceResult;

      const { lat, lng } = getLatLng(results[0]);
      const formattedAddress = results[0].formatted_address;
      const addressCommaIndex = address.indexOf(',');
      const initialAddress =
        addressCommaIndex !== -1 ? address.substring(0, addressCommaIndex) : '';

      const initialFormattedAddressCommaIndex = results[0].formatted_address.indexOf(
        ','
      );
      const initialformattedAddress =
        initialFormattedAddressCommaIndex !== -1
          ? formattedAddress.substring(0, initialFormattedAddressCommaIndex)
          : '';

      let location =
        getAddressValue(results, 'establishment') ||
        getAddressValue(results, 'neighborhood') ||
        '';

      if (location && initialAddress !== initialformattedAddress) {
        location = initialAddress;
      }

      if (!location && initialAddress) {
        location = initialAddress;
      }

      const unit = getAddressValue(results, 'subpremise') || '';
      const streetNumber = getAddressValue(results, 'street_number') || '';
      const road = getAddressValue(results, 'route') || '';
      const city = getAddressValue(results, 'locality') || '';
      const shire =
        getAddressValue(results, 'administrative_area_level_2') || '';
      const state =
        getAddressValue(results, 'administrative_area_level_1') || '';
      const country = getAddressValue(results, 'country') || '';
      const countryShort = getAddressValue(results, 'country', true) || '';
      const postcode = getAddressValue(results, 'postal_code') || '';
      let addressLine1 = `${location}${
        location && ','
      } ${unit} ${streetNumber} ${road}`;
      addressLine1 = addressLine1.trim();
      if (addressLine1.endsWith(',')) {
        addressLine1 = addressLine1.slice(0, -1);
      }
      const addressLine2 = shire;

      return {
        location,
        unit,
        streetNumber,
        road,
        city,
        shire,
        state,
        country,
        countryShort,
        postcode,
        addressLine1,
        addressLine2,
        lat,
        lng,
        phone: convertPhoneNumberCleanly(placeDetails.formatted_phone_number),
        internationalPhone: placeDetails.international_phone_number,
        website: placeDetails.website ? cleanURL(placeDetails.website) : '',
      };
    } catch (error) {
      return {
        location: '',
        unit: '',
        streetNumber: '',
        road: '',
        city: '',
        shire: '',
        state: '',
        country: '',
        countryShort: '',
        postcode: '',
        addressLine1: address,
        addressLine2: '',
        lat: null,
        lng: null,
        phone: '',
        internationalPhone: '',
        website: '',
      };
    }
  };

  const getAddressErrors = (errors: any): IIAddressErrorsProps => {
    return {
      addressLookup: errors?.addressLookup?.message || '',
      addressLine1: errors?.addressLine1?.message || '',
      addressLine2: errors?.addressLine2?.message || '',
      city: errors?.city?.message || '',
      state: errors?.state?.message || '',
      country: errors?.country?.message || '',
      postcode: errors?.postcode?.message || '',
    };
  };

  return {
    getAddressFromInputValue,
    getAddressErrors,
  };
};
