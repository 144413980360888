import React, { FC } from 'react';
import { isEmpty } from '../../../../../utils/helper';
import { H2, Span, Label } from '../../../../shared/typefaces/Typefaces.styles';
import { IIngredientListProps } from '../../ingredients.types';
import { Container, Headings, HeadingsItem } from './Header.styles';

const Header: FC<IIngredientListProps> = ({ data }) => {
  const ingredientsCount =
    data.data?.venue?.userVenue?.venue.ingredientProducts.length;

  const supplierIds = data.data?.venue?.userVenue?.venue.ingredientProducts.map(
    (product) => product.supplierId
  );
  const brandIds = data.data?.venue?.userVenue?.venue.ingredientProducts.map(
    (product) => product.brandId
  );

  const supplierList = supplierIds?.filter((item, pos) => {
    return supplierIds?.indexOf(item) === pos && !isEmpty(item);
  });
  const brandList = brandIds?.filter((item, pos) => {
    return brandIds?.indexOf(item) === pos && !isEmpty(item);
  });

  return (
    <Container>
      <Headings>
        <HeadingsItem>
          <Label color="faded" fontSize="small" className="headerLabel">
            Total Ingredients
          </Label>
          <H2>{ingredientsCount}</H2>
        </HeadingsItem>
        <HeadingsItem>
          <Label className="headerLabel" color="faded" fontSize="small">
            Brands
          </Label>
          <Span className="" fontSize="heading">
            {brandList?.length ? brandList?.length : '-'}
          </Span>
        </HeadingsItem>
        <HeadingsItem>
          <Label className="headerLabel" color="faded" fontSize="small">
            Suppliers
          </Label>
          <Span className="" fontSize="heading">
            {supplierList?.length ? supplierList?.length : '-'}
          </Span>
        </HeadingsItem>
      </Headings>
    </Container>
  );
};

export default Header;
