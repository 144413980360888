import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { useMeQuery } from '../../../../generated/graphql';
import logo from '../../../../images/iconLogo-min.png';
import { Arrow } from '../../icons';
import { LeftIcon, RightIcon, Text, VenueBtn } from './VenueButton.styes';
import { IVenueButtonProps } from './venueButton.types';

const VenueButton: FC<IVenueButtonProps> = ({ accessToken, onClick }) => {
  const { isOpen, selectedVenueObject, account, dispatch } = useContext(
    LayoutContext
  );
  const [venueName, setVenueName] = useState('Recipe Revenue');
  const { data, loading, refetch } = useMeQuery();
  const { push } = useHistory();

  const venues = data?.me?.user?.venuesSharedWithYou;

  useEffect(() => {
    if (accessToken) {
      const venue = venues?.find(
        (venue) => venue.venue.id === selectedVenueObject?.id
      );
      if (!loading) {
        if (!venue) {
          refetch();
        } else {
          setVenueName(venue?.venue.displayName);
        }
      }
    }
  }, [venues, selectedVenueObject?.id, loading, accessToken, refetch]);

  useEffect(() => {
    if (!venues) {
      refetch();
    }
  }, [selectedVenueObject?.id, venues, refetch]);

  const handleClick = () => {
    if (account) {
      dispatch({ type: 'SHOW_MENU_OVERLAY', payload: true });
      dispatch({ type: 'TOGGLE_MENU' });
    } else {
      push('/');
    }
    onClick && onClick();
  };

  return (
    <VenueBtn isOpen={isOpen} onClick={handleClick}>
      <LeftIcon>
        <img src={logo} alt="Logo" />
      </LeftIcon>
      <Text fontSize="small">{venueName}</Text>
      <RightIcon>
        <Arrow color="grey" size="tiny" />
      </RightIcon>
    </VenueBtn>
  );
};

export default VenueButton;
