import React, { FC } from 'react';
import { Loading } from './LogoLoading.styles';
import { ILoadingProps } from './logoLoading.types';

const LogoLoading: FC<ILoadingProps> = ({ size }) => {
  return (
    <Loading size={size}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 500 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="animateLogo">
          <path
            id="leaf"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M264.131 265.004C260.497 260.844 273.608 277.25 273.608 277.25C273.608 277.25 249.202 311.779 250.447 314.451C307.957 437.874 206.961 492.322 164.695 495.632C57.8523 504.002 6.84357 444.456 6.84357 444.456C6.84357 444.456 79.4273 431.574 90.3429 362.454C103.932 276.401 163.272 271.822 239.004 301.437C239.233 301.527 233.741 310.377 233.401 310.424C95.4311 329.112 213.201 407.954 105.684 439.39C223.95 455.946 251.288 396.372 246.654 338.66C246.364 335.045 243.902 329.014 243.709 329.559C240.955 337.346 238.509 344.629 234.593 354.559C229.862 366.556 220.085 388.046 187.452 399.785C180.56 402.264 205.926 385.725 213.534 378.472C221.971 370.427 227.021 360.636 224.925 361.147C209.237 364.978 205.607 352.726 205.607 352.726C205.607 352.726 215.479 363.569 227.654 350.095C233.149 344.014 264.585 265.865 264.131 265.004V265.004Z"
            fill="url(#paintLeaf)"
          />
          <path
            id="bottom"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M478.56 4.53079C524.208 20.7339 453.495 173.275 452.388 173.466C343.316 413.231 254.786 301.373 254.786 301.373C254.786 301.373 131.568 218.023 334.012 68.9127C334.012 68.9127 434.183 -11.2205 478.56 4.53079V4.53079Z"
            fill="url(#paintBottom)"
          />
          <path
            id="top"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.28 12.2698C483.965 33.1504 411.605 180.179 410.657 180.371C310.372 382.105 255.458 301.899 255.458 301.899C253.962 300.496 138.658 226.675 312.213 85.6442C334.273 67.7179 420.263 2.70231 440.28 12.2698V12.2698Z"
            fill="#FD8B2D"
          />
        </g>
        <defs>
          <linearGradient
            id="paintBottom"
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            gradientTransform="rotate(230)"
          >
            <stop offset="20%" stopColor="#FD8B2D">
              <animate
                attributeName="stopColor"
                values="#FD8B2D; #FA7820; #FD8B2D;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="100%" stopColor="#FA7820">
              <animate
                attributeName="stopColor"
                values="#FA7820; #FD8B2D; #FA7820;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
          </linearGradient>
          <linearGradient
            id="paintLeaf"
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            gradientTransform="rotate(0)"
          >
            <stop offset="20%" stopColor="#137B29">
              <animate
                attributeName="stopColor"
                values="#137B29; #1AB07D; #137B29;"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="100%" stopColor="#1AB07D">
              <animate
                attributeName="stopColor"
                values="#1AB07D; #137B29; #1AB07D;"
                dur="1.5s"
                repeatCount="indefinite"
              ></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </Loading>
  );
};

export default LogoLoading;
