import styled from 'styled-components/macro';
import { Span } from '../../typefaces/Typefaces.styles';
import { Button } from '../../button';
import { Link } from 'react-router-dom';

export const NavbarContainerRight = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
`;

export const AuthMenu = styled.ul`
  position: absolute;
  right: 15px;
  top: 70px;
  background-color: #fff;
  width: 250px;
  height: auto;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadow.box};
  z-index: ${({ theme }) => theme.zIndex.nav};

  &:before {
    content: '';
    position: absolute;
    right: 13px;
    top: -12px;
    width: 0;
    border-width: 6px;
    border-radius: 10px;
    border-color: transparent transparent
      ${({ theme }) => theme.colors.white.default} transparent;
    border-style: solid;
  }
`;

export const AuthMenuItem = styled.li`
    display:flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    border-top: 1px solid ${({ theme }) => theme.colors.background};

    &:first-child {
      border-top: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.background};

      &:first-child {
        border-radius: 10px 10px 0px 0px;
      }
      &:last-child {
        border-radius: 0px 0px 10px 10px;
      }
    }
}
`;

export const LinkStyled = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.grey};
  &:hover {
    color: ${({ theme }) => theme.colors.text.black};
  }
`;

export const LinkSpanStyled = styled(Span)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.colors.text.black};
  }
`;

export const AuthButton = styled(Button)`
  margin-left: 5px;
`;
