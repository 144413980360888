import React, { FC, useState } from 'react';
import { Arrow } from '../../../../icons';
import { H4 } from '../../../../typefaces/Typefaces.styles';
import * as S from './Question.styles';

export interface IQuestionProps {
  question: string;
  answer: string;
}

export const Question: FC<IQuestionProps> = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <S.Container>
      <S.Header onClick={() => setShowAnswer(!showAnswer)}>
        <H4 className="question">{question}</H4>
        <div className="arrowIcon">
          <Arrow
            color="grey"
            size={'smaller'}
            rotate={showAnswer ? 270 : 90}
            className="arrow"
          />
        </div>
      </S.Header>
      {showAnswer && <S.Content>{answer}</S.Content>}
    </S.Container>
  );
};
