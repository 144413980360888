import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useRecipeQuery } from '../../../generated/graphql';
import { isEmpty } from '../../../utils/helper';
import {
  validateNumberValues,
  validatePositiveNumberValues,
} from '../../../utils/helper/validation';
import { Button } from '../../shared/button';
import LayoutPage from '../../shared/layout/layoutPage';
import { IRecipeFormProps } from '../../shared/recipe/recipe.types';
import { Container } from './FormExample.styles';
import FormOne from './FormOne';
import FormTwo from './FormTwo';

const FormExample = () => {
  const {
    selectedRecipe,
    selectedVenueObject,
    selectedRecipeVersion,
  } = useContext(LayoutContext);
  const { data, loading, error } = useRecipeQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
        recipeId: selectedRecipe!,
      },
    },
  });

  if (loading || error || !data) {
    return <div>Loading...</div>;
  }

  const selectedVersion = data?.recipe?.recipe?.recipeVersions.filter(
    (version) => version.id === selectedRecipeVersion
  );
  if (
    !isEmpty(data?.recipe.recipe) &&
    !isEmpty(selectedVersion && selectedVersion[0])
  ) {
    return (
      <Form recipe={data?.recipe.recipe!} recipeVersion={selectedVersion![0]} />
    );
  }
  return <Form />;
};

const Form: FC<IRecipeFormProps> = ({ recipe, recipeVersion }) => {
  const [serveValue, setServeValue] = useState(20);

  const onSubmit = () => {
    // Form Example Submit
  };

  const itemsArray = [] as any;
  if (!isEmpty(recipeVersion)) {
    recipeVersion!.recipeIngredients?.map((recipeIngredient) => {
      const ingredient = {
        ...recipeIngredient,
        unit: recipeIngredient.unit.toLowerCase(),
      };
      return itemsArray.push(ingredient);
    });
    recipeVersion!.recipeAsIngredients?.map((recipeAsIngredient) => {
      const recipe = {
        ...recipeAsIngredient,
        unit: recipeAsIngredient.unit.toLowerCase(),
      };
      return itemsArray.push(recipe);
    });
    recipeVersion!.recipeTimeItems?.map((recipeTimeItem) => {
      const timeItem = {
        ...recipeTimeItem,
        unit: recipeTimeItem.unit.toLowerCase(),
      };

      return itemsArray.push(timeItem);
    });
  }

  itemsArray.sort((step) => step.order);

  const defaultValues = {
    serves: serveValue,
    items: [{ quantity: 50 }, { quantity: 250 }, { quantity: 250 }],
  };
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { watch } = methods;

  const serves = watch('serves');
  const items = watch('items');

  useEffect(() => {
    if (serves !== serveValue) {
      setServeValue(serves);
      const updatedItems = items.map((i) => {
        return { quantity: i.quantity * Number(serves) };
      });

      methods.reset({
        serves: serves,
        items: updatedItems,
      });
    }
  }, [serveValue, serves, items, watch]);

  // useEffect(() => {
  //   const serves = methods.watch('serves');
  //   const watchItems = methods.watch('items');

  //   if (recipeVersion && Number(serves) > 1 && serves !== state.serves) {
  //     setState({ ...state, serves: serves });

  //     const itemsArray = [] as any;
  //     watchItems.map((item) => {
  //       const q = {
  //         quantity: calculateQuantity(
  //           item.quantity,
  //           recipeVersion.serves,
  //           Number(serves)
  //         ),
  //       };
  //       return itemsArray.push(q);
  //     });

  //     methods.reset({
  //       items: itemsArray,
  //     });
  //   }
  // }, [methods, state, recipeVersion]);

  return (
    <LayoutPage>
      <Container>
        <FormProvider {...methods}>
          <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
            <FormOne />
            <FormTwo />
            <div style={{ marginTop: '30px' }} />
            <Button onClick={onSubmit} type="submit">
              Submit
            </Button>
            <Button onClick={() => methods.reset(defaultValues)} type="submit">
              Reset
            </Button>
          </form>
        </FormProvider>
      </Container>
    </LayoutPage>
  );
};

const autocompleteSchema = {
  value: yup.string().required('form.required_message'),
};

const autocompleteIngredientSchema = {
  value: yup.string().required('Ingredient is required'),
};

const formSchema = {
  ingredient: yup.object().shape(autocompleteIngredientSchema),
  quantity: yup
    .string()
    .required('quantity is required')
    .typeError('type error')
    .test('numberTest', 'Serves is not a number', (value) =>
      validateNumberValues(value)
    )
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),
  units: yup.string().required('Is required'),
};

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  find: yup.object().shape(autocompleteSchema),
  number: yup
    .string()
    .required('Number is required')
    .typeError('Number is required')
    .test('numberTest', 'Serves is not a number', (value) =>
      validateNumberValues(value)
    )
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),
  items: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required('Must have fields')
    .min(1, 'Minimum of 1 field'),
});

export default FormExample;
