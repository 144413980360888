import {
  RecipeFormState,
  RecipeProcessTimeFormState,
  RecipeStaffTimeFormState,
  RecipeVersionFromQuery,
} from '../../components/shared/recipe/recipe.types';
import { calculateQuantity } from './calculateQuantity';
import { isEmpty, ObjUnion } from './objects';
import { sortArrayOfObjects } from './sort';
import { getUnitTotal } from './units';

export const getInitialRecipeFormItems = (
  recipeVersion: RecipeVersionFromQuery | undefined,
  serves: number | undefined
): RecipeFormState['items'] => {
  if (isEmpty(recipeVersion)) return [];

  const itemsArray: RecipeFormState['items'] = [];

  for (const recipeIngredient of recipeVersion?.recipeIngredients ?? []) {
    itemsArray.push({
      __typename: 'RecipeIngredient',
      type: 'INGREDIENT',

      rowKey: recipeIngredient.id,
      recipeItemId: recipeIngredient.id,

      ingredient: {
        label: recipeIngredient.ingredient.displayName,
        value: recipeIngredient.ingredient.id,
        extra: 'ingredient',
      },

      quantity: calculateQuantity(
        Number(recipeIngredient.quantity),
        recipeVersion?.serves ?? 0,
        serves
      ).toString(),

      unit: recipeIngredient.unit,
      metrics: recipeIngredient.ingredient.metrics,
      order: recipeIngredient.order,
    });
  }

  for (const recipeAsIngredient of recipeVersion?.recipeAsIngredients ?? []) {
    itemsArray.push({
      __typename: 'RecipeIngredient',
      type: 'RECIPE',

      rowKey: recipeAsIngredient.id,
      recipeItemId: recipeAsIngredient.id,

      ingredient: {
        label: recipeAsIngredient.recipe.displayName,
        value: recipeAsIngredient.recipe.id,
        extra: 'recipe',
      },

      quantity: calculateQuantity(
        recipeAsIngredient.quantity,
        recipeVersion?.serves ?? 0,
        serves
      ).toString(),

      unit: recipeAsIngredient.unit,
      order: recipeAsIngredient.order,
    });
  }

  for (const recipeTimeItem of recipeVersion?.recipeTimeItems ?? []) {
    const unit = recipeTimeItem.unit.toLowerCase();

    const item: ObjUnion<
      RecipeProcessTimeFormState | RecipeStaffTimeFormState
    > = {
      __typename: 'RecipeTimeItem',
      type: recipeTimeItem.staffTime ? 'STAFF_TIME' : 'PROCESS_TIME',
      staffTime: recipeTimeItem.staffTime,
      order: recipeTimeItem.order,
      rowKey: recipeTimeItem.id,
      recipeItemId: recipeTimeItem.id,
      description: recipeTimeItem.description || '',

      quantity: getUnitTotal(
        unit,
        calculateQuantity(
          recipeTimeItem.quantity,
          recipeVersion?.serves ?? 0,
          serves
        )
      ).toString(),

      unit: unit,
    };

    itemsArray.push(item);
  }

  const t = itemsArray.sort(sortArrayOfObjects('order'));
  return t;
};
