import React, { FC, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Arrow } from '../../../icons';
import { ArrowContainer, Container } from './Selector.styles';
import {
  GetRecipeCategoryRecipesQuery,
  MeDocument,
  RecipesDocument,
  useCreateRecipeFromTemplateMutation,
  useSignupWithRecipeTemplateMutation,
} from '../../../../../generated/graphql';
import { setAccessToken } from '../../../../../utils/accessToken';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { Options } from '../options';
import { useActiveTimeTracker } from '../../../../../utils/customHooks/useActiveTimeTracker';

interface ISelectorProps {
  recipesData?: GetRecipeCategoryRecipesQuery;
  isLoading: boolean;
}
interface IPointerProps {
  goBackwards?: boolean;
  className?: string;
  onClick?: () => void;
}

export interface IItemProps {
  id: string;
  recipeName: string;
  imageUrl?: string;
}

export const Selector: FC<ISelectorProps> = ({ isLoading, recipesData }) => {
  const recipes = recipesData?.recipeCategoryRecipes.recipes;
  const { account, selectedVenueObject, totalRecipes, dispatch } = useContext(
    LayoutContext
  );
  const {
    getTotalActiveSeconds,
    setInitialActiveSeconds,
  } = useActiveTimeTracker();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(
    !!recipes && recipes?.length > 2
  );
  const [selectedRecipeTemplateId, setSelectedRecipeTemplateId] = useState('');
  const containerWidthRef = useRef<HTMLDivElement>(null);
  const [
    signupWithRecipeTemplateMutation,
    signupWithRecipeTemplate,
  ] = useSignupWithRecipeTemplateMutation();
  const [
    createRecipeFromTemplateMutation,
    createRecipeFromTemplate,
  ] = useCreateRecipeFromTemplateMutation();
  const history = useHistory();

  const onSelect = async (recipeId: string) => {
    setSelectedRecipeTemplateId(recipeId);
    try {
      if (!account) {
        const response = await signupWithRecipeTemplateMutation({
          variables: {
            input: {
              recipeId,
            },
          },
          refetchQueries: [
            {
              query: MeDocument,
            },
          ],
          awaitRefetchQueries: true,
        });

        if (response.data?.signupWithRecipeTemplate.successful) {
          const {
            accessToken,
            recipe,
            user,
          } = response.data.signupWithRecipeTemplate;

          if (user && accessToken) {
            setAccessToken(accessToken);
            const venueData = {
              id: user?.venuesSharedWithYou[0].venue.id,
              displayName: user?.venuesSharedWithYou[0].venue.displayName,
              address: user?.venuesSharedWithYou[0].venue.address,
              email: user?.venuesSharedWithYou[0].venue.email,
              totalActiveSeconds:
                user?.venuesSharedWithYou[0].totalActiveSeconds,
              recipeProfitIncreasePerYear:
                user?.venuesSharedWithYou[0].venue.recipeProfitIncreasePerYear,
            };
            dispatch({
              type: 'SELECT_VENUE_OBJECT',
              payload: venueData,
            });
            setInitialActiveSeconds(venueData.totalActiveSeconds || 0);
            dispatch({
              type: 'RESET_ALL_TOOL_TIPS',
            });
          }

          if (recipe?.id) {
            dispatch({
              type: 'SET_NEW_RECIPE_FROM_TEMPLATE',
              payload: true,
            });

            dispatch({
              type: 'SELECT_RECIPE',
              payload: recipe?.id,
            });

            dispatch({
              type: 'SELECT_RECIPE_VERSION',
              payload: recipe?.recipeVersions[0].id,
            });

            dispatch({
              type: 'SET_TOTAL_RECIPES',
              payload: 1,
            });

            history.push('recipe-results');
          }
        }
      } else {
        const response = await createRecipeFromTemplateMutation({
          variables: {
            input: {
              recipeId,
              venueId: selectedVenueObject?.id!,
              totalActiveSeconds: getTotalActiveSeconds(),
            },
          },
          refetchQueries: [
            {
              query: MeDocument,
            },
            {
              query: RecipesDocument,
              variables: {
                input: {
                  venueId: selectedVenueObject?.id!,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        if (response.data?.createRecipeFromTemplate.successful) {
          const { recipe } = response.data.createRecipeFromTemplate;

          if (recipe?.id) {
            dispatch({
              type: 'SET_NEW_RECIPE_FROM_TEMPLATE',
              payload: true,
            });

            dispatch({
              type: 'SELECT_RECIPE',
              payload: recipe?.id,
            });

            dispatch({
              type: 'SELECT_RECIPE_VERSION',
              payload: recipe?.recipeVersions[0].id,
            });

            dispatch({
              type: 'SET_TOTAL_RECIPES',
              payload: totalRecipes ? totalRecipes + 1 : undefined,
            });

            history.push('recipe-results');
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const options: IItemProps[] | undefined =
    !isLoading && recipes && recipes.length > 0
      ? recipes.map((recipe) => ({
          id: recipe.id,
          recipeName: recipe.displayName,
          imageUrl: recipe.imageUrl || undefined,
        }))
      : undefined;

  const checkArrow = (type: 'left' | 'right') => {
    if (containerWidthRef.current !== null) {
      const {
        scrollLeft,
        scrollWidth,
        clientWidth,
      } = containerWidthRef.current;

      if (type === 'left') {
        if (showLeftArrow) {
          if (scrollLeft === 0) {
            setShowLeftArrow(false);
          }
        } else {
          if (scrollLeft !== 0) {
            setShowLeftArrow(true);
          }
        }
      }
      if (type === 'right') {
        if (showRightArrow) {
          if (scrollLeft + clientWidth === scrollWidth) {
            setShowRightArrow(false);
          }
        } else {
          if (scrollLeft + clientWidth !== scrollWidth) {
            setShowRightArrow(true);
          }
        }
      }
    }
  };

  const scroll = (goBackwards?: boolean) => {
    if (containerWidthRef.current !== null) {
      const { clientWidth } = containerWidthRef.current;
      if (goBackwards) {
        containerWidthRef.current.scrollLeft -= clientWidth;
      } else {
        containerWidthRef.current.scrollLeft += clientWidth;
      }
    }
  };

  const onScroll = () => {
    checkArrow('left');
    checkArrow('right');
  };

  return (
    <Container ref={containerWidthRef} onScroll={() => onScroll()}>
      <Options
        options={options}
        loadSuccessful={recipesData?.recipeCategoryRecipes.successful}
        onSelect={onSelect}
        signUpIsLoading={
          signupWithRecipeTemplate.loading || createRecipeFromTemplate.loading
        }
        selectedRecipeTemplateId={selectedRecipeTemplateId}
      />
      {showLeftArrow && (
        <Pointer className="left" onClick={() => scroll(true)} goBackwards />
      )}
      {showRightArrow && <Pointer onClick={() => scroll()} />}
    </Container>
  );
};

const Pointer: FC<IPointerProps> = ({ goBackwards, className, onClick }) => {
  return (
    <ArrowContainer className={className} onClick={onClick}>
      <Arrow rotate={goBackwards ? 180 : 0} size="tiny" />
    </ArrowContainer>
  );
};
