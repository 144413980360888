import styled from 'styled-components/macro';
import { ILoadingProps } from './dotsLoading.types';

export const Container = styled.div<ILoadingProps>`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '12px')};
  align-items: flex-end;
  justify-content: center;
  margin: ${({ noMargin }) => (noMargin ? 0 : '15px')};
`;

export const Dots = styled.div<ILoadingProps>`
  width: ${({ size }) => (size === 'small' ? '4px' : '10px')};
  height: ${({ size }) => (size === 'small' ? `4px` : '10px')};
  position: relative;
  border-radius: 5px;
  background-color: ${({ color, theme }) =>
    color ? theme.colors[color].default : theme.colors.white.default};
  color: ${({ color, theme }) =>
    color ? theme.colors[color].default : theme.colors.white.default};
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-left: ${({ size }) => (size === 'small' ? '6px' : '12px')};

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: ${({ size }) => (size === 'small' ? '-5px' : '-15px')};
    width: ${({ size }) => (size === 'small' ? '4px' : '10px')};
    height: ${({ size }) => (size === 'small' ? `4px` : '10px')};
    border-radius: 5px;
    background-color: ${({ color, theme }) =>
      color ? theme.colors[color].default : theme.colors.white.default};
    color: ${({ color, theme }) =>
      color ? theme.colors[color].default : theme.colors.white.default};
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &:after {
    left: ${({ size }) => (size === 'small' ? '5px' : '15px')};
    width: ${({ size }) => (size === 'small' ? '4px' : '10px')};
    height: ${({ size }) => (size === 'small' ? `4px` : '10px')};
    border-radius: 5px;
    background-color: ${({ color, theme }) =>
      color ? theme.colors[color].default : theme.colors.white.default};
    color: ${({ color, theme }) =>
      color ? theme.colors[color].default : theme.colors.white.default};
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: ${({ color, theme }) =>
        color ? theme.colors[color].default : theme.colors.white.default};
    }
    50%,
    100% {
      background-color: ${({ theme }) => theme.colors.white.light};
    }
  }
`;
