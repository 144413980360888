import React, { useContext } from 'react';
import { IMenuItemProps } from './menuItem.types';
import { Container, ItemText, LinkStyled } from './MenuItem.styles';
import { LayoutContext } from '../../../../contexts/layoutContext';

const MenuMainItem: React.FC<IMenuItemProps> = ({
  itemName,
  link,
  icon,
  highlight,
  onClick,
}: IMenuItemProps) => {
  const { isOpen } = useContext(LayoutContext);

  return (
    <LinkStyled to={link} onClick={onClick}>
      <Container isOpen={isOpen} highlight={highlight}>
        {icon && icon}
        <ItemText isOpen={isOpen} showIcon={!!icon} highlight={highlight}>
          {itemName}
        </ItemText>
      </Container>
    </LinkStyled>
  );
};

export default MenuMainItem;
