import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import { theme } from './styles/theme';
import { client } from './utils/apolloClient';
import ScrollToTop from './utils/customHooks/ScollToTop';
import { ActiveTimeProvider } from './utils/customHooks/useActiveTimeTracker';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GA4!);

ReactGA.send({
  hitType: 'pageview',
  page: '/',
  title: 'Landed on Recipe Revenue',
});

ReactDOM.render(
  // @ts-expect-error
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <ActiveTimeProvider>
          <App />
        </ActiveTimeProvider>
      </ThemeProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);
