import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  max-width: 950px;
  margin: 0 auto;

  .toolTipHeading {
    margin-bottom: 5px;
  }
`;
