import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  AccountType,
  IngredientRequest,
  IngredientRequestStatus,
  Metric,
  MetricType,
  useIngredientRequestQuery,
} from '../../../../../generated/graphql';
import { capitalizeFirstLetter, isEmpty } from '../../../../../utils/helper';
import { getAverage } from '../../../../../utils/helper/arrays';
import { roundToWholeNumber } from '../../../../../utils/helper/numbers';
import { DotsLoading } from '../../../../shared/loading';
import { H5, Span } from '../../../../shared/typefaces/Typefaces.styles';
import * as S from './Requests.styles';

interface IRequestsProps {
  metrics:
    | ({
        __typename?: 'Metric' | undefined;
      } & Pick<Metric, 'grams' | 'id' | 'type'>)[]
    | undefined;
}

interface IDisplayAdminRowProps {
  requests:
    | ({
        __typename?: 'IngredientRequest' | undefined;
      } & Pick<
        IngredientRequest,
        | 'type'
        | 'id'
        | 'grams'
        | 'creatorId'
        | 'ingredientId'
        | 'venueId'
        | 'metricId'
        | 'newMetric'
        | 'createdAt'
        | 'updatedAt'
      >)[]
    | undefined;
  metrics:
    | ({
        __typename?: 'Metric' | undefined;
      } & Pick<Metric, 'grams' | 'id' | 'type'>)[]
    | undefined;
}

const DislayAdminRow: FC<IDisplayAdminRowProps> = ({ requests, metrics }) => {
  if (isEmpty(requests)) {
    return null;
  }

  const metric = metrics?.find((m) => m.id === requests![0].metricId);
  const gramsArray = requests?.map((r) => r.grams);
  const gramsArrayPlusCurrentMetricAmount = [...gramsArray!, metric!.grams];
  const gramAverage = roundToWholeNumber(
    getAverage(gramsArrayPlusCurrentMetricAmount!)
  );

  return (
    <S.Row>
      <S.Left>
        <Span fontWeight="semibold">
          {capitalizeFirstLetter(requests![0].type)}
        </Span>
        <Span
          className="status"
          fontWeight="semibold"
          color="faded"
          fontSize="small"
        >
          Average: {gramAverage}g
        </Span>
      </S.Left>
      <S.Right>
        {requests?.map((g, idx) => (
          <Span key={idx}>
            {idx !== 0 && ','} {g.grams}g
          </Span>
        ))}
      </S.Right>
    </S.Row>
  );
};

export const Requests: FC<IRequestsProps> = ({ metrics }) => {
  const { selectedIngredient, selectedVenueObject, account } = useContext(
    LayoutContext
  );

  const requests = useIngredientRequestQuery({
    variables: {
      input: {
        ingredientId: selectedIngredient!,
        venueId: selectedVenueObject?.id!,
      },
    },
    skip: !selectedIngredient, // don't make query if ingredient is new (i.e. no info to load)
    fetchPolicy: 'network-only',
  });

  const ingredientRequests = requests.data?.ingredientRequest.ingredientRequest;

  if (requests.loading) {
    return (
      <S.Loading>
        <DotsLoading isLoading={requests.loading} color="primary" />
      </S.Loading>
    );
  }

  if (!requests.loading && isEmpty(ingredientRequests)) {
    return null;
  }

  const getStatus = (status: IngredientRequestStatus, metricGrams?: number) => {
    switch (status) {
      case IngredientRequestStatus.Denied:
        return (
          <Span
            className="status"
            fontWeight="medium"
            color="primary"
            fontSize="small"
          >
            {`Reviewed: Please create an alternative ingredient to use these metric grams. Eg: Wholemeal Loaf -> Large Wholemeal Loaf`}
          </Span>
        );

      case IngredientRequestStatus.New:
        return (
          <Span
            className="status"
            fontWeight="medium"
            color="secondary"
            fontSize="small"
          >
            {`Auto Updated: Thank you for contribution! Your change is currently being verified`}
          </Span>
        );

      case IngredientRequestStatus.Updated:
        return (
          <Span
            className="status"
            fontWeight="medium"
            color="secondary"
            fontSize="small"
          >
            {`Updated: Thank you, based on your request & others we have updated the metric grams to ${
              metricGrams && `${metricGrams}g`
            }`}
          </Span>
        );

      case IngredientRequestStatus.Verified:
        return (
          <Span
            className="status"
            fontWeight="medium"
            color="secondary"
            fontSize="small"
          >
            Verified Successfully
          </Span>
        );

      default:
        return (
          <Span
            className="status"
            fontWeight="semibold"
            color="faded"
            fontSize="small"
          >
            Pending Review
          </Span>
        );
    }
  };

  const getAdminTable = () => {
    const cupRequests = ingredientRequests?.filter((r) => {
      return r.type.toUpperCase() === MetricType.Cup;
    });
    const wholeRequests = ingredientRequests?.filter(
      (r) => r.type === MetricType.Whole
    );
    const sliceRequests = ingredientRequests?.filter(
      (r) => r.type === MetricType.Slice
    );

    return (
      <S.Table>
        <DislayAdminRow requests={cupRequests} metrics={metrics} />
        <DislayAdminRow requests={wholeRequests} metrics={metrics} />
        <DislayAdminRow requests={sliceRequests} metrics={metrics} />
      </S.Table>
    );
  };

  const getDefaultTable = () => {
    return (
      <S.Table>
        {ingredientRequests?.map((r) => {
          const metric = metrics?.find((m) => m.id === r.metricId);
          return (
            <S.Row key={r.id}>
              <S.Left>
                <H5 fontWeight="semibold" className="label">
                  {capitalizeFirstLetter(r.type)}
                </H5>
                {getStatus(r.status, metric?.grams)}
              </S.Left>
              <Span>{r.grams}g</Span>
            </S.Row>
          );
        })}
      </S.Table>
    );
  };

  const getTable = () => {
    if (account?.type === AccountType.RecipeRevenue) {
      return getAdminTable();
    }
    return getDefaultTable();
  };

  return (
    <S.Container>
      <S.Header>Change Requests</S.Header>
      <S.Content>{getTable()}</S.Content>
    </S.Container>
  );
};
