import styled from 'styled-components';
import { ContainerProps, RowProps } from './table.types';
import { device } from '../../../styles/device';
import { DeleteRow } from '../../shared/recipe/sections/items/Items.styles';

const info =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20px' height='20px' viewBox='0 0 60 60'  style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath style='opacity:0.984' fill='%2310b561' d='M 25.5,2.5 C 51.9379,2.43538 63.1046,15.4354 59,41.5C 49.913,59.3667 35.913,64.7001 17,57.5C 5.16667,61.6667 1.33333,57.8333 5.5,46C -1.67997,24.871 4.98669,10.371 25.5,2.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23f7fcf9' d='M 28.5,15.5 C 35.4883,14.6478 37.8216,17.4811 35.5,24C 26.8377,26.5009 24.5043,23.6675 28.5,15.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23fbfdfc' d='M 29.5,28.5 C 31.9363,28.317 34.103,28.9837 36,30.5C 36.6667,35.8333 36.6667,41.1667 36,46.5C 33,49.1667 30,49.1667 27,46.5C 26.3333,41.1667 26.3333,35.8333 27,30.5C 27.9947,29.9341 28.828,29.2674 29.5,28.5 Z'/%3E%3C/g%3E%3C/svg%3E";

interface IColProps {
  icon?: boolean;
}

export const Container = styled.section<ContainerProps>`
  width: 100%;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : '30px')};
`;

export const Header = styled.ul<RowProps>`
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-gap: 5px;
  grid-template-columns: ${({
    iconWidth,
    nameWidth,
    itemOneWidth,
    itemTwoWidth,
    itemThreeWidth,
    itemFourWidth,
    itemFifthWidth,
    itemSixthWidth,
  }) => {
    const colIconWidth = iconWidth ? `${iconWidth}px` : '1fr';
    const colNameWidth = nameWidth ? `${nameWidth}px` : '1fr';
    const colOneWidth = itemOneWidth ? `${itemOneWidth}px` : '1fr';
    const colTwoWidth = itemTwoWidth ? `${itemTwoWidth}px` : '1fr';
    const colThreeWidth = itemThreeWidth ? `${itemThreeWidth}px` : '1fr';
    const colFourWidth = itemFourWidth ? itemFourWidth : 0;
    const colFifthWidth = itemFifthWidth ? itemFifthWidth : 0;
    const colSixthWidth = itemSixthWidth ? itemSixthWidth : 0;
    return `${colIconWidth} ${colNameWidth} ${colOneWidth} ${colTwoWidth} ${colThreeWidth} ${colFourWidth}px ${colFifthWidth}px ${colSixthWidth}px`;
  }};

  ${DeleteRow} {
    padding-top: 0 !important;
  }

  grid-template-areas: ${({ gridArea }) =>
    gridArea ? gridArea : "'name name name name name name name itemFour'"};

  @media ${device.tablet} {
    grid-template-areas: ${({ gridArea }) =>
      gridArea
        ? gridArea
        : "'iconOne name itemOne itemTwo itemThree itemFour itemFive itemSix'"};
  }
`;

export const Rows = styled.div`
  overflow: hidden;
`;

export const HeaderItem = styled.li<IColProps>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;

  &:hover {
    .hasToolTipIcon {
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        background-color: ${({ theme, icon = true }) =>
          icon ? 'transparent' : theme.colors.secondary.default};
        background-image: ${({ theme, icon = true }) =>
          icon
            ? `url(${`"${info}"`})`
            : `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
        width: ${({ icon = true }) => (icon ? '20px' : '8px')};
        height: ${({ icon = true }) => (icon ? '20px' : '8px')};
        border-radius: ${({ icon = true }) => (icon ? '0' : '8px')};
        margin-left: -4px;
        left: 50%;
        top: ${({ icon = true }) => (icon ? '-24px' : '-14px')};
      }
    }
  }

  &:nth-child(1) {
    grid-area: iconOne;
    justify-content: center;
  }

  &:nth-child(2) {
    grid-area: name;
    justify-content: flex-start;
    padding-left: 15px;
    @media ${device.tablet} {
      padding-left: 0;
    }
  }
  &:nth-child(3) {
    grid-area: itemOne;
    text-align: right;
    justify-content: flex-end;
  }
  &:nth-child(4) {
    grid-area: itemTwo;
    text-align: right;
    justify-content: flex-end;
  }
  &:nth-child(5) {
    grid-area: itemThree;
    text-align: right;
    justify-content: flex-end;
  }
  &:nth-child(6) {
    grid-area: itemFour;
    align-items: center;
    justify-content: flex-end;
    padding-right: ${({ theme }) => theme.layout.padding};
    @media ${device.tablet} {
      justify-content: center;
      padding-right: 0;
    }
  }

  .firstItem {
    padding-right: 10px;
  }

  .secondItem {
    width: 60px;
    display: inline-block;
  }
`;

export const Row = styled.ul<RowProps>`
  display: grid;
  position: relative;
  grid-gap: 5px;
  padding-bottom: 10px !important;
  background-color: ${({ isDragging, theme }) =>
    isDragging ? theme.colors.default.lightest : theme.colors.background.white};
  border-radius: ${({ isDragging, theme }) =>
    isDragging && theme.layout.radiusSmallest};

  &:after {
    position: absolute;
    display: block;
    width: 100%;
    background: #808080;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }) => theme.colors.default.default};
    border-bottom-width: ${({ isDragging }) => (isDragging ? 0 : '1px')};

    left: ${({ iconWidth }) => {
      const iconColWidth = iconWidth ? iconWidth + 5 : 45;
      return `${iconColWidth}px`;
    }};
    bottom: 0;
    content: '';
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  grid-template-columns: ${({
    iconWidth,
    nameWidth,
    itemOneWidth,
    itemTwoWidth,
    itemThreeWidth,
    itemFourWidth,
    itemFifthWidth,
    itemSixthWidth,
  }) => {
    const colIconWidth = iconWidth ? `${iconWidth}px` : '1fr';
    const colNameWidth = nameWidth ? `${nameWidth}px` : '1fr';
    const colOneWidth = itemOneWidth ? `${itemOneWidth}px` : '1fr';
    const colTwoWidth = itemTwoWidth ? `${itemTwoWidth}px` : '1fr';
    const colThreeWidth = itemThreeWidth ? `${itemThreeWidth}px` : '1fr';
    const colFourWidth = itemFourWidth ? itemFourWidth : 0;
    const colFifthWidth = itemFifthWidth ? itemFifthWidth : 0;
    const colSixthWidth = itemSixthWidth ? itemSixthWidth : 0;
    return `${colIconWidth} ${colNameWidth} ${colOneWidth} ${colTwoWidth} ${colThreeWidth} ${colFourWidth}px ${colFifthWidth}px ${colSixthWidth}px`;
  }};
  grid-template-areas: ${({ gridArea }) =>
    gridArea
      ? gridArea
      : "'iconOne name itemOne itemTwo itemThree itemFour itemFive itemSix'"};

  &.hideItem {
    display: none;
  }

  &:last-child {
    li {
      border-bottom: 0;
    }
  }
`;

export const RowItem = styled.li`
  min-height: 60px;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    grid-area: iconOne;
    justify-content: center;
  }
  &:nth-child(2) {
    grid-area: name;
    justify-content: flex-start;
  }
  &:nth-child(3) {
    grid-area: itemOne;
    text-align: right;
    justify-content: flex-end;
  }
  &:nth-child(4) {
    grid-area: itemTwo;
    text-align: right;
    justify-content: flex-end;
  }
  &:nth-child(5) {
    grid-area: itemThree;
    text-align: right;
    justify-content: flex-end;
  }
  &:nth-child(6) {
    grid-area: itemFour;
    justify-content: flex-end;
    padding-right: ${({ theme }) => theme.layout.padding};
  }
  &:nth-child(7) {
    grid-area: itemFive;
    justify-content: flex-end;
  }
  &:nth-child(8) {
    grid-area: itemSix;
    justify-content: flex-end;
    padding-right: ${({ theme }) => theme.layout.padding};
  }
  &:last-child {
    padding-right: ${({ theme }) => theme.layout.padding};
  }

  .firstItem {
    padding-right: 10px;
  }

  .secondItem {
    width: 60px;
    display: inline-block;
  }
`;
