import { useContext } from 'react';
import { LayoutContext } from '../../contexts/layoutContext';
import { useLocation, useParams } from 'react-router-dom';
import {
  Exact,
  GetStocktakeInput,
  StocktakeQuery,
  useStocktakeQuery,
} from '../../generated/graphql';
import { QueryResult } from '@apollo/client';

export const useStocktake = (): {
  stocktake: QueryResult<
    StocktakeQuery,
    Exact<{
      input: GetStocktakeInput;
    }>
  >;
  stocktakeId?: string;
  addStocktake: boolean;
} => {
  const { account, selectedStocktake } = useContext(LayoutContext);
  const { pathname } = useLocation();
  const params: { stocktakeId?: string } = useParams();
  const addStocktake = pathname.includes('/add-stocktake');
  const stocktakeId = params.stocktakeId;
  const skipQuery = selectedStocktake ? false : !stocktakeId || !account;

  const stocktake = useStocktakeQuery({
    variables: {
      input: {
        stocktakeId: selectedStocktake || params.stocktakeId!,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: skipQuery,
  });

  return {
    stocktake,
    stocktakeId,
    addStocktake,
  };
};
