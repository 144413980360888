import React, { useContext } from 'react';
import * as S from './GuestHeader.styles';
import { AnchorButton } from '../../../../shared/button/Button.styles';
import { H3, H4 } from '../../../../shared/typefaces/Typefaces.styles';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { AccountType } from '../../../../../generated/graphql';
import { Card } from '../../../../shared/card';
import partyIcon from '../../../../../images/partyIcon.png';

const GuestHeader = () => {
  const { totalRecipes, account } = useContext(LayoutContext);
  const { push } = useHistory();

  if (account?.type !== AccountType.Guest) {
    return null;
  }

  return (
    <S.Container>
      <Card className="content" containerClassName="contentContainer">
        <S.Heading>
          <H3 className="heading">
            {totalRecipes === 1
              ? 'Congratulations On First Recipe!'
              : 'Want to see more recipes?'}
          </H3>
          {totalRecipes === 1 && (
            <img src={partyIcon} alt="party icon" className="partyIcon" />
          )}
        </S.Heading>
        <H4 className="subHeading">
          Click here to see more recipes, or navigate to the recipes page in the
          top left menu
        </H4>
        <AnchorButton
          color="secondary"
          onClick={() => push('/recipes')}
          size="large"
          className="CTA"
        >
          See More Recipes
        </AnchorButton>
      </Card>
    </S.Container>
  );
};

export default GuestHeader;
