import React, { FC, Fragment } from 'react';
import {
  Container,
  Header,
  Row,
  RowItem,
  HeaderItem,
  Rows,
} from './Table.styles';
import { TableProps } from './table.types';
import { Draggable } from 'react-beautiful-dnd';

const Table: FC<TableProps> = ({
  noMargin,
  headings,
  rows,
  iconWidth,
  nameWidth,
  itemOneWidth,
  itemTwoWidth,
  itemThreeWidth,
  itemFourWidth,
  itemFifthWidth,
  itemSixthWidth,
  gridArea,
  className,
  provided,
  snapshot,
}) => (
  <Container
    noMargin={noMargin}
    className={className}
    {...provided?.droppableProps}
    ref={provided?.innerRef}
  >
    <Header
      iconWidth={iconWidth}
      itemOneWidth={itemOneWidth}
      itemTwoWidth={itemTwoWidth}
      itemThreeWidth={itemThreeWidth}
      itemFourWidth={itemFourWidth}
      itemFifthWidth={itemFifthWidth}
      itemSixthWidth={itemSixthWidth}
      gridArea={gridArea}
      className="tableHeader"
    >
      {headings.map((col, idx) => (
        <Fragment key={idx}>
          {col.icon && <HeaderItem>{col.icon}</HeaderItem>}
          <HeaderItem>{col.name}</HeaderItem>
          {col.item1 && (
            <HeaderItem {...col.item1ToolTip}>{col.item1}</HeaderItem>
          )}
          {col.item2 && (
            <HeaderItem {...col.item2ToolTip}>{col.item2}</HeaderItem>
          )}
          {col.item3 && (
            <HeaderItem {...col.item3ToolTip}>{col.item3}</HeaderItem>
          )}
          {col.item4 && (
            <HeaderItem {...col.item4ToolTip}>{col.item4}</HeaderItem>
          )}
          {col.item5 && (
            <HeaderItem {...col.item5ToolTip}>{col.item5}</HeaderItem>
          )}
          {col.item6 && <HeaderItem>{col.item6}</HeaderItem>}
        </Fragment>
      ))}
    </Header>
    <Rows>
      {rows.map((col, idx) => {
        return provided ? (
          <Draggable key={col.id} draggableId={col.id} index={idx}>
            {(provided, snapshot) => (
              <Row
                id={col.id && `row${idx}`}
                key={`${idx}_${col.id}`}
                iconWidth={iconWidth}
                nameWidth={nameWidth}
                itemOneWidth={itemOneWidth}
                itemTwoWidth={itemTwoWidth}
                itemThreeWidth={itemThreeWidth}
                itemFourWidth={itemFourWidth}
                itemFifthWidth={itemFifthWidth}
                itemSixthWidth={itemSixthWidth}
                gridArea={gridArea}
                className={col.delete ? 'hideItem' : ''}
                isDragging={snapshot.isDragging}
                ref={provided.innerRef}
                {...provided.draggableProps}
              >
                <Fragment>
                  {col.icon && (
                    <RowItem {...provided.dragHandleProps}>
                      {col.icon}
                      {col.hiddenType && col.hiddenType}
                    </RowItem>
                  )}
                  {col.name && <RowItem>{col.name}</RowItem>}
                  {col.item1 && <RowItem>{col.item1}</RowItem>}
                  {col.item2 && <RowItem>{col.item2}</RowItem>}
                  {col.item3 && <RowItem>{col.item3}</RowItem>}
                  {col.item4 && <RowItem>{col.item4}</RowItem>}
                  {col.item5 && <RowItem>{col.item5}</RowItem>}
                  {col.item6 && <RowItem>{col.item6}</RowItem>}
                </Fragment>
              </Row>
            )}
          </Draggable>
        ) : (
          <Row
            id={col.id && `row${idx}`}
            key={`${idx}_${col.id}`}
            iconWidth={iconWidth}
            nameWidth={nameWidth}
            itemOneWidth={itemOneWidth}
            itemTwoWidth={itemTwoWidth}
            itemThreeWidth={itemThreeWidth}
            itemFourWidth={itemFourWidth}
            itemFifthWidth={itemFifthWidth}
            itemSixthWidth={itemSixthWidth}
            gridArea={gridArea}
            className={col.delete ? 'hideItem' : ''}
          >
            <Fragment>
              {col.icon && (
                <RowItem>
                  {col.icon}
                  {col.hiddenType && col.hiddenType}
                </RowItem>
              )}
              {col.name && <RowItem>{col.name}</RowItem>}
              {col.item1 && <RowItem>{col.item1}</RowItem>}
              {col.item2 && <RowItem>{col.item2}</RowItem>}
              {col.item3 && <RowItem>{col.item3}</RowItem>}
              {col.item4 && <RowItem>{col.item4}</RowItem>}
              {col.item5 && <RowItem>{col.item5}</RowItem>}
              {col.item6 && <RowItem>{col.item6}</RowItem>}
            </Fragment>
          </Row>
        );
      })}
      {provided && provided.placeholder}
    </Rows>
  </Container>
);

export default Table;
