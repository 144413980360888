import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { device } from '../../../styles/device';
import { Button } from '../button';
import {
  IContainerProps,
  IMenuMainItemProps,
  ISubMenuProps,
} from './menuMain.types';
import { Container, ItemText } from './menuItem/MenuItem.styles';
import { LayoutContainer } from '../layout/layoutContent/LayoutContent.styles';
import { ArrowContainer } from '../layout/menuToggle/MenuToggle.styles';
import {
  VenueBtn,
  Text,
  RightIcon,
  LeftIcon,
} from './venueButton/VenueButton.styes';
import { Anchor, Span } from '../typefaces/Typefaces.styles';

export const HelpButton = styled(Button)<IMenuMainItemProps>`
  display: none;
  position: fixed;
  bottom: 15px;

  min-width: 40px;
  padding: 0;

  ${({ isOpen, theme }) =>
    isOpen
      ? `
      display: block;
      left: 20px;
      min-width: ${theme.layout.buttonWidth};

      &::after {
        content: 'Need Help?';
        min-width: ${theme.layout.buttonWidth};
      }
      `
      : `
      left: 4px;

      @media ${device.mobileL} {
        display: block;
      }

      @media ${device.laptop} {
        min-width: ${({ theme }) => theme.layout.buttonWidth};
      }

      &::after {
        content: '?';
      }
    `};
`;

export const Items = styled.div<IMenuMainItemProps>`
  position: fixed;
  overflow-y: scroll;
  padding: 10px;
  width: auto;
  display: none;
  padding-top: 65px;

  scrollbar-width: none;
  -ms-overflow-style: none;

  -webkit-scrollbar {
    display: none;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    width: auto;
    display: block;
  `};

  @media ${device.mobileL} {
    display: block;
  }
`;

export const Border = styled.div<IMenuMainItemProps>`
  position: fixed;
  width: 3px;
  left: 12px;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.2) 0px,
    rgba(0, 0, 0, 0.2) 1px,
    rgba(0, 0, 0, 0.1) 1px,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;

  ${({ isOpen }) =>
    isOpen &&
    `
    left: 197px;
    `}

  @media ${device.mobileL} {
    left: ${({ isOpen }) => (isOpen ? '197px' : '47px')};
  }
`;

export const MenuDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.default.default};
  padding-bottom: 15px;
`;

export const SubMenu = styled.div<ISubMenuProps>`
  border-top: 1px solid ${({ theme }) => theme.colors.default.default};
  padding-top: 15px;

  ${({ isOpen }) =>
    isOpen
      ? `
        display: block;
      `
      : `display: none`};

  ${Container} {
    padding-bottom: 5px;

    svg {
      display: none;
    }

    ${ItemText} {
      padding-left: 0;
      font-size: ${({ theme }) => theme.fontSize.button};
    }
  }

  ${Anchor} {
    display: block;
    color: ${({ theme }) => theme.colors.text.grey}; /* Or 918E8B */
    font-weight: ${({ theme }) => theme.fontWeight.label};
    font-size: ${({ theme }) => theme.fontSize.button};
    line-height: 20px;
    text-decoration: none;
    margin-bottom: 5px;
    line-height: ${({ theme }) => theme.lineHeight.large};
    cursor: pointer;

    &:hover {
      span {
        color: ${({ theme }) => theme.colors.text.default};
      }
    }
  }
`;

export const MenuMainContainer = styled.div<IContainerProps>`
  display: flex;
  flex-direction: row;
  width: 15px;
  /* position: relative; */
  background: ${({ theme }) => theme.colors.background};
  z-index: ${({ theme }) => theme.zIndex.menu};
  min-height: 100vh;
  flex-shrink: 0;

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 200px;
    position: fixed;
    + ${LayoutContainer} {
      margin-left: 15px;
    }

    ${Items} {
      padding-top: 80px;
    }

    @media ${device.mobileL} {
      + ${LayoutContainer} {
          margin-left: 53px;
      }
    }

    @media ${device.tablet} {
      position: relative;
      + ${LayoutContainer} {
        margin-left: 0;
      }
    }
  `};

  @media ${device.mobileXS} {
    &:hover {
      width: 200px;
      position: fixed;
      ${Items} {
        display: block;
        padding-top: 80px;
      }
      ${Border} {
        margin-left: 0;
      }
      ${Container} {
        width: 180px;
      }
      ${ItemText} {
        display: block;
      }
      ${VenueBtn} {
        width: 190px;
        justify-content: left;
        height: 50px;

        ${LeftIcon} {
          margin-left: 8px;
        }
        ${Text} {
          display: block;
        }
        ${RightIcon} {
          display: block;
        }
      }

      ${SubMenu} {
        display: block;
      }

      ${HelpButton} {
        min-width: ${({ theme }) => theme.layout.buttonWidth};
        left: 20px;

        &::after {
          content: 'Need Help?';
        }
      }

      + ${LayoutContainer} {
        margin-left: 15px;
      }

      + ${LayoutContainer} + ${ArrowContainer} {
        left: 188px;
      }

      ${Border} {
        left: 197px;
      }
    }
  }
  @media ${device.mobileL} {
    width: ${({ isOpen }) => (isOpen ? '200px' : '50px')};

    &:hover {
      + ${LayoutContainer} {
        margin-left: 53px;
      }
    }
  }
  @media ${device.tablet} {
    &:hover {
      position: relative;
      + ${LayoutContainer} {
        margin-left: 0;
      }
    }
  }
`;

export const LinkStyled = styled(Link)`
  display: block;
  color: #666;
  font-size: 14px;
  line-height: 20px;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-bottom: 5px;
  line-height: 24px;

  &:hover {
    color: ${({ theme }) => theme.colors.text.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

export const SpanLinkStyled = styled(Span)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: #666;
  font-size: 14px;
  line-height: 20px;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-bottom: 5px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.text.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;
