import React, { FC, useContext } from 'react';
import { Button } from '../../../../shared/button';
import { Card, Header } from '../../../../shared/card';
import { CategoryIcon, Share } from '../../../../shared/icons';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
import { IRecipeDataResult } from '../../../../../utils/clientServerShared/getRecipeData.types';

export const RecipeTemplate: FC<{ recipeData: IRecipeDataResult }> = ({
  recipeData,
}) => {
  const { appWidth, dispatch } = useContext(LayoutContext);

  const handleRecipeTemplate = () => {
    dispatch({ type: 'SLIDER_PAGE', payload: 'editTemplateRecipe' });
  };

  return (
    <Card withCardLink>
      <Header
        icon={<CategoryIcon size="small" />}
        heading={
          recipeData.category
            ? `Category: ${capitalizeFirstLetter(recipeData.category, true)}`
            : 'Recipe Template'
        }
        subHeading={`Turn your recipe into a template for other chefs to start fast by providing an image & recipe category`}
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={handleRecipeTemplate}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button color="default" inversed onClick={handleRecipeTemplate}>
              {recipeData.category
                ? 'Edit Recipe Template'
                : 'Create Recipe Template'}
            </Button>
          )
        }
      />
    </Card>
  );
};

export default RecipeTemplate;
