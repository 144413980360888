import React, { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { theme } from '../../../../styles/theme';
import { Input } from '../../../shared/formElements';
import { LayoutColumn } from '../../../shared/layout';
import { IVenueDataProps } from '../../account/sections/venues/venues.types';
import { Section, SideLabel } from '../Venue.styles';

const TargetFoodMargin: FC<IVenueDataProps> = ({ data }) => {
  const { appWidth, addVenue } = useContext(LayoutContext);
  const { errors, control } = useFormContext();

  const sideLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  const venue = data?.data?.venue?.userVenue?.venue;

  return (
    <Section>
      <LayoutColumn>
        {sideLabel('Target Food Margin')}
        <Controller
          as={<Input />}
          control={control}
          label={fieldLabel('Target Food Margin')}
          name="targetFoodMargin"
          errorText={errors.targetFoodMargin && errors.targetFoodMargin.message}
          defaultValue={
            addVenue || venue?.targetFoodMargin === null
              ? ''
              : venue?.targetFoodMargin
          }
        />
      </LayoutColumn>
    </Section>
  );
};

export default TargetFoodMargin;
