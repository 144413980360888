import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const RecipeBook: React.FC<IIconProps> = ({ color, size, faded, rotate }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 511.997 511.997"
      color={color}
      faded={faded}
      rotate={rotate}
    >
      <g>
        <g>
          <path
            d="M479.996,458.777V10.664C479.996,4.773,475.23,0,469.34,0H74.673C51.11,0,32,19.102,32,42.664c0,0,0,0.008,0,0.016
			v437.317c0,17.672,14.344,32,32,32h405.34c5.891,0,10.656-4.781,10.656-10.672s-4.766-10.672-10.656-10.672v-21.219
			C475.23,469.434,479.996,464.668,479.996,458.777z M64.001,490.652c-5.875,0-10.656-4.781-10.656-10.656
			c0-5.375,4-9.844,9.188-10.562H446.56c-5.219,0.719-9.219,5.156-9.219,10.562c0,5.891,4.781,10.656,10.656,10.656H64.001z
			 M458.669,448.106H64.001v-0.109c-0.859,0-1.719,0.031-2.578,0.109h-8.078v-10.547V42.664c0-11.758,9.562-21.328,21.328-21.328
			h383.997V448.106z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M322.156,107.999c-7.344,0-14.469,1.023-21.219,2.93c-12.469-9.375-27.984-14.93-44.781-14.93
			c-16.812,0-32.327,5.555-44.812,14.93c-6.75-1.906-13.859-2.93-21.219-2.93c-43.094,0-78.016,34.93-78.016,78.023
			c0,32.453,19.828,60.273,48.016,72.031v115.289H352.17V258.053c28.188-11.758,48.015-39.578,48.015-72.031
			C400.185,142.929,365.248,107.999,322.156,107.999z M330.842,351.998h-0.032c0-5.891-4.781-10.656-10.656-10.656
			c-5.906,0-10.688,4.766-10.688,10.656H298.81c0-5.891-4.781-10.656-10.656-10.656c-5.906,0-10.688,4.766-10.688,10.656H266.81
			c0-5.891-4.781-10.656-10.656-10.656c-5.906,0-10.688,4.766-10.688,10.656h-10.656c0-5.891-4.78-10.656-10.655-10.656
			c-5.906,0-10.688,4.766-10.688,10.656h-10.656c0-5.891-4.781-10.656-10.656-10.656c-5.906,0-10.688,4.766-10.688,10.656v-10.656
			h10.688h32h31.999h32h32h10.688V351.998z M369.094,217.809c-6.234,9.188-14.938,16.296-25.141,20.554l-13.109,5.469v14.219v61.947
			H181.469v-61.945v-14.219l-13.125-5.469c-21.203-8.835-34.906-29.382-34.906-52.343c-0.001-31.258,25.437-56.687,56.687-56.687
			c1.25,0,2.5,0.047,3.75,0.125c-7.531,11.352-12.031,24.867-12.375,39.421c-0.094,0.578-0.156,1.172-0.156,1.781
			c0,5.891,4.781,10.672,10.656,10.672c5.906,0,10.672-4.781,10.672-10.672h0.141c0-29.406,23.922-53.328,53.343-53.328
			c28.938,0,52.531,23.164,53.281,51.929c-0.062,0.461-0.094,0.922-0.094,1.398c0,5.891,4.781,10.672,10.656,10.672
			c5.906,0,10.672-4.781,10.672-10.672h0.141c0-15.227-4.562-29.39-12.406-41.203c1.25-0.078,2.5-0.125,3.75-0.125
			c31.266,0,56.688,25.429,56.688,56.687C378.844,197.426,375.469,208.418,369.094,217.809z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M213.344,213.334c-5.89,0-10.672,4.774-10.672,10.664c0,0,0,0,0,0.008v53.32c0,0,0,0,0,0.016
			c0,5.875,4.781,10.656,10.672,10.656S224,283.217,224,277.342c0-0.016,0-0.016,0-0.016v-53.32c0-0.008,0-0.008,0-0.008
			C224,218.107,219.233,213.334,213.344,213.334z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M255.998,213.334c-5.875,0-10.656,4.773-10.656,10.664c0,0,0,0,0,0.008v53.32c0,0,0,0,0,0.016
			c0,5.875,4.781,10.656,10.656,10.656c5.906,0,10.672-4.781,10.672-10.656c0-0.016,0-0.016,0-0.016v-53.32c0-0.008,0-0.008,0-0.008
			C266.67,218.107,261.904,213.334,255.998,213.334z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M298.67,213.334c-5.891,0-10.672,4.774-10.672,10.664c0,0,0,0,0,0.008v53.32c0,0,0,0,0,0.016
			c0,5.875,4.781,10.656,10.672,10.656s10.672-4.781,10.672-10.656c0-0.016,0-0.016,0-0.016v-53.32c0-0.008,0-0.008,0-0.008
			C309.342,218.107,304.561,213.334,298.67,213.334z"
          />
        </g>
      </g>
    </IconStyled>
  );
};

export default RecipeBook;
