import styled from 'styled-components/macro';
import { ICardProps } from './card.types';
import { device } from '../../../../styles/device';

export const Container = styled.section`
  margin: 0 auto;
`;

export const CardContainer = styled.div<ICardProps>`
  max-width: 900px;
  margin: ${({ theme }) => theme.layout.pagePadding};
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: solid
    ${({ withShadow, withAlert, theme }) =>
      withAlert
        ? `1px ${theme.colors.error.default}`
        : withShadow
        ? '0'
        : `1px ${theme.colors.default.default}`};
  box-shadow: ${({ withShadow, theme }) =>
    withShadow ? theme.shadow.box : 'none'};
  background-color: ${({ withBackground, theme }) =>
    withBackground ? theme.colors.background : 'transparent'};
  padding-bottom: ${({ childCount }) =>
    childCount && childCount === 1 ? '30px' : 0};
  position: relative;
  min-height: 100px;

  ${({ withCardLink, theme }) =>
    withCardLink &&
    `
    margin-bottom: 30px;

    &:after {
      position: absolute;
      content: "";
      height: 30px;
      width: 8px;
      background-color: ${theme.colors.default.default};
      left: 34px;
      bottom: -31px;
      z-index: ${theme.zIndex.above};
    }
  `}

  ${({ menuIsOpen, withCardLink }) =>
    menuIsOpen
      ? `
    @media ${device.laptopL} {
      margin: 0 auto;
      ${withCardLink && `margin-bottom: 30px`}
    }
  `
      : `
    @media ${device.laptop} {
      margin: 0 auto;
      ${withCardLink && `margin-bottom: 30px`}
    }
  `}
`;
