import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Open: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2445 4997 c-105 -35 -201 -110 -250 -193 -79 -135 -77 -308 5 -438
17 -26 30 -50 29 -54 0 -4 -413 -374 -917 -822 l-916 -815 -70 -7 c-39 -5 -86
-13 -106 -19 -81 -27 -155 -94 -192 -174 l-23 -50 -3 -1005 c-2 -727 0 -1020
8 -1060 14 -64 65 -147 114 -185 19 -14 58 -35 86 -46 49 -19 104 -19 2350
-19 2246 0 2301 0 2350 19 28 11 67 32 86 46 49 38 100 121 114 185 8 40 10
333 8 1060 l-3 1005 -23 51 c-27 59 -89 123 -149 154 -24 12 -75 27 -115 33
l-73 11 -900 789 c-495 435 -915 804 -933 822 l-34 32 31 48 c44 69 63 136 63
218 0 174 -91 315 -250 390 -57 27 -80 32 -157 34 -55 2 -105 -2 -130 -10z
m178 -208 c52 -14 112 -71 133 -124 24 -65 16 -144 -22 -198 -113 -160 -366
-96 -382 98 -13 155 121 266 271 224z m12 -618 l75 15 860 -755 861 -756 -932
-3 c-512 -1 -1347 -1 -1857 0 l-926 3 848 755 849 756 73 -15 c66 -14 82 -14
149 0z m2235 -1749 c19 -19 30 -43 35 -73 3 -24 5 -474 3 -999 -3 -903 -4
-956 -21 -982 -10 -14 -32 -31 -49 -37 -44 -15 -4512 -15 -4556 0 -17 6 -39
23 -49 37 -17 26 -18 79 -21 982 -2 525 0 975 3 999 5 30 16 54 35 73 l28 28
2282 0 2282 0 28 -28z"
        />
        <path
          d="M1003 2020 c-169 -24 -321 -170 -353 -339 -16 -84 -13 -534 4 -606
64 -271 363 -404 616 -275 58 30 142 116 172 176 46 92 49 120 46 434 l-3 295
-32 67 c-83 177 -262 275 -450 248z m152 -229 c51 -23 101 -79 114 -128 6 -22
11 -143 11 -283 0 -236 -1 -247 -24 -295 -27 -59 -46 -77 -106 -104 -83 -38
-171 -19 -237 49 -54 56 -58 79 -58 365 0 250 1 262 22 302 23 43 69 84 113
102 40 16 122 12 165 -8z"
        />
        <path
          d="M1783 2019 c-17 -5 -41 -21 -52 -35 -21 -26 -21 -36 -21 -600 l0
-572 31 -31 c42 -42 95 -43 141 -2 l33 29 3 181 3 181 102 0 c56 0 130 5 165
10 186 26 334 170 364 354 38 231 -127 452 -364 486 -81 12 -363 11 -405 -1z
m469 -244 c123 -87 123 -263 0 -350 -43 -30 -46 -30 -188 -33 l-144 -3 0 211
0 211 144 -3 c142 -3 145 -3 188 -33z"
        />
        <path
          d="M2854 2023 c-12 -2 -34 -18 -50 -34 l-29 -30 -3 -532 c-2 -293 0
-552 3 -576 5 -29 16 -54 35 -73 l28 -28 255 0 255 0 31 31 c42 42 43 95 2
141 l-29 33 -181 3 -181 3 0 159 0 159 181 3 181 3 29 33 c41 46 40 99 -2 141
l-31 31 -179 0 -179 0 0 160 0 160 153 0 c170 0 217 9 247 47 45 57 14 143
-58 163 -35 9 -432 12 -478 3z"
        />
        <path
          d="M3694 2016 c-67 -30 -64 0 -64 -632 l0 -572 31 -31 c42 -42 95 -43
141 -2 l33 29 5 354 5 354 214 -368 c117 -203 223 -375 235 -383 11 -8 42 -15
68 -15 39 0 54 6 80 29 l33 29 3 554 c2 369 0 565 -8 589 -20 72 -108 95 -167
45 l-28 -24 -5 -378 -5 -377 -225 388 c-124 213 -234 393 -245 401 -33 21 -68
25 -101 10z"
        />
      </g>
    </IconStyled>
  );
};

export default Open;
