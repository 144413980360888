/**
 * Returns a name for a recipe duplicate given the original name.
 * Based off the strategy macOS uses:
 * - Normally, call the duplicate `{original} copy`
 * - If a copy already exists, call it `{original} copy 2`
 * - If multiple copies exist, call it `{original} copy {n}`
 *   where n is the next available copy number after the original's number
 *
 * Issues:
 * - This should ultimately be a server side responsibility as the client side implementation
 *   doesn't account for pagination, etc. Keep this in mind as we scale RR
 *
 * @param originalVersionName original name of the recipe
 * @param otherVersionNames other version names that are taken - so that we don't accidentally take the same name as another recipe
 */
export const generateDuplicateRecipeName: (
  originalVersionName: string,
  otherVersionNames: Set<string>
) => string = (originalVersionName, otherVersionNames) => {
  /**
   * This regex matches a space, the letter 'v' in lowercase
   * and then one or more digits, at the end of a string.
   *
   * It extracts the copy number, if present, into a capture group.
   *
   * Matches:
   * - Myrecipe v2
   * - Myrecipe v3
   * - Myrecipe v700
   *
   * Does not match:
   * - Myrecipe
   * - Myrecipe 5
   * - Myrecipev2
   * - Myrecipe va
   * - Myrecipe v4 somethingelse
   */
  const checkCopyRegex = / v([0-9]+)$/;

  const checkResult = originalVersionName.match(checkCopyRegex);

  // 1 -> original version `Myrecipe`
  // 2 -> first copy `Myrecipe v2`
  // 3 -> second copy `Myrecipe v3`
  // 4 -> third copy `Myrecipe v4` etc
  let currentIncrement = (() => {
    // Empty if not a copy
    if (!(checkResult && checkResult.length)) return 1;

    // Match with capture if copy e.g. `Myrecipe v8`
    return Number(checkResult[1]);
  })();

  const originalPureName = (() => {
    // Original is not a copy
    if (currentIncrement <= 1) return originalVersionName;

    // Original is a later copy e.g. `Myrecipe v17`
    const versionDigitCount = (checkResult && checkResult[1].length) || 2;
    const endCharsCount = ' v'.length + versionDigitCount;
    return originalVersionName.slice(0, -1 * endCharsCount);
  })();

  // We are trying to find the NEXT name for this recipe, not the current one
  currentIncrement++;

  const formatName: (pureName: string, increment: number) => string = (
    pureName,
    increment
  ) => {
    if (increment === 0) return pureName;
    return `${pureName} v${increment}`;
  };

  while (
    otherVersionNames.has(formatName(originalPureName, currentIncrement))
  ) {
    currentIncrement++;
  }

  const newName = formatName(originalPureName, currentIncrement);
  return newName;
};
