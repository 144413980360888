import React, { FC, useState } from 'react';
import { RecipeCategory } from '../../../../../generated/graphql';
import { capitalizeFirstLetterPerWord } from '../../../../../utils/helper';
import {
  Manufacturing,
  Bakery,
  FoodTruck,
  Drink,
  CafeSign,
  GlutenFree,
  Sweet,
  Open,
  Retail,
  Vegan,
  Vegetarian,
  Catering,
  CategoryIcon,
} from '../../../icons';
import { H2, Span } from '../../../typefaces/Typefaces.styles';
import {
  Container,
  Icon,
  Item,
  Items,
  OptionsContainer,
  Selected,
} from './Header.styles';

interface IHeaderProps {
  selectedRecipeCategory: RecipeCategory;
  showHeading?: boolean;
  changeRecipeCategory: (category: RecipeCategory) => void;
}

interface IOptionsProps {
  options: IItemProps[];
  selectedRecipeCategory: RecipeCategory;
  changeRecipeCategory: (category: RecipeCategory) => void;
}

interface IItemProps {
  icon: React.ReactNode;
  category: RecipeCategory;
}

export const Header: FC<IHeaderProps> = ({
  selectedRecipeCategory,
  showHeading,
  changeRecipeCategory,
}) => {
  const [showMore, setShowMore] = useState(false);

  const options: IItemProps[] = [
    {
      icon: <Open color="white" size="large" />,
      category: RecipeCategory.Restaurant,
    },
    {
      icon: <CafeSign color="white" size="large" />,
      category: RecipeCategory.Cafe,
    },
    {
      icon: <Bakery color="white" size="large" />,
      category: RecipeCategory.Bakery,
    },
    {
      icon: <Drink color="white" size="large" />,
      category: RecipeCategory.Drink,
    },
    {
      icon: <Catering color="white" size="large" />,
      category: RecipeCategory.Catering,
    },

    {
      icon: <Sweet color="white" size="large" />,
      category: RecipeCategory.Sweet,
    },
    {
      icon: <FoodTruck color="white" size="large" />,
      category: RecipeCategory.FoodTruck,
    },
  ];

  const extraOptions: IItemProps[] = [
    {
      icon: <Vegetarian color="white" size="large" />,
      category: RecipeCategory.Vegetarian,
    },
    {
      icon: <GlutenFree color="white" size="large" />,
      category: RecipeCategory.GlutenFree,
    },
    {
      icon: <Retail color="white" size="large" />,
      category: RecipeCategory.Retail,
    },
    {
      icon: <Vegan color="white" size="large" />,
      category: RecipeCategory.Vegan,
    },
    {
      icon: <Manufacturing color="white" size="large" />,
      category: RecipeCategory.Manufacturing,
    },
  ];

  return (
    <Container>
      {showHeading && (
        <H2 color="white" className="heading">
          More Flavour, More Profit.
          <br />
          <span className="subHeading">
            Compare your recipe costs in minutes.
            <br />
            No math required.
          </span>
        </H2>
      )}
      <OptionsContainer>
        <Options
          options={options}
          selectedRecipeCategory={selectedRecipeCategory}
          changeRecipeCategory={changeRecipeCategory}
        />
        {!showMore && (
          <Item onClick={() => setShowMore(true)}>
            <Icon>
              <CategoryIcon color="white" size="large" />
            </Icon>
            <Span className="category">More</Span>
          </Item>
        )}
        {showMore && (
          <Options
            options={extraOptions}
            selectedRecipeCategory={selectedRecipeCategory}
            changeRecipeCategory={changeRecipeCategory}
          />
        )}
      </OptionsContainer>
    </Container>
  );
};

const Options: FC<IOptionsProps> = ({
  options,
  selectedRecipeCategory,
  changeRecipeCategory,
}) => {
  return (
    <Items>
      {options.map(({ icon, category }, idx) => {
        const option = capitalizeFirstLetterPerWord(category, true);
        return (
          <Item onClick={() => changeRecipeCategory(category)} key={idx}>
            <Icon>{icon}</Icon>
            <Span className="category">{option}</Span>
            {selectedRecipeCategory === category && <Selected />}
          </Item>
        );
      })}
    </Items>
  );
};
