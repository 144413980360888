import React, { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { theme } from '../../../../styles/theme';
import { Address, Input } from '../../../shared/formElements';
import { LayoutColumn } from '../../../shared/layout';
import { IVenueDataProps } from '../../account/sections/venues/venues.types';
import { Section, SideLabel } from '../Venue.styles';
import { useAddress } from '../../../../utils/customHooks/useAddress';

const Details: FC<IVenueDataProps> = ({ data }) => {
  const { appWidth, addVenue } = useContext(LayoutContext);
  const { errors, control, register, getValues, reset } = useFormContext();
  const { getAddressErrors } = useAddress();

  const sideLabel = (label: string, marginTop?: boolean) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel marginTop={marginTop}>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  const venue = addVenue ? undefined : data?.data?.venue?.userVenue?.venue;

  return (
    <Section>
      <LayoutColumn>
        {sideLabel('Addess', true)}
        <Address
          control={control}
          errors={getAddressErrors(errors)}
          getValues={getValues}
          reset={reset}
          initialAddressValues={venue?.address}
        />
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Email')}
        <Controller
          as={<Input />}
          control={control}
          label={fieldLabel('Email')}
          name="email"
          errorText={errors.email && errors.email.message}
          defaultValue={addVenue ? '' : venue?.email}
        />
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Mobile')}
        <Input
          ref={register}
          label={fieldLabel('Mobile')}
          name="phone"
          type="number"
          errorText={errors.phone && errors.phone.message}
          defaultValue={addVenue ? '' : venue?.phone}
        />
      </LayoutColumn>

      <LayoutColumn>
        {sideLabel('Website')}
        <Controller
          as={<Input />}
          control={control}
          label={fieldLabel('Website')}
          name="website"
          errorText={errors.website && errors.website.message}
          defaultValue={addVenue ? '' : venue?.website}
        />
      </LayoutColumn>
      <LayoutColumn>
        <Controller
          as={<Input className="hiddenInput" />}
          control={control}
          name="internationalPhone"
          defaultValue={addVenue ? '' : venue?.internationalPhone}
        />
      </LayoutColumn>
    </Section>
  );
};

export default Details;
