import React, {
  useEffect,
  useRef,
  createContext,
  useContext,
  ReactNode,
} from 'react';

// Define the shape of the context value
interface RecipeActiveTimeContextType {
  getTotalRecipeActiveSeconds: () => number;
  setInitialRecipeActiveSeconds: (seconds: number) => void;
  resetTimer: () => void;
}

// Create the context
const RecipeActiveTimeContext = createContext<
  RecipeActiveTimeContextType | undefined
>(undefined);

export const useRecipeActiveTimeTracker = (): RecipeActiveTimeContextType => {
  const context = useContext(RecipeActiveTimeContext);
  if (!context) {
    throw new Error(
      'useRecipeActiveTimeTracker must be used within an RecipeActiveTimeProvider'
    );
  }
  return context;
};

interface RecipeActiveTimeProviderProps {
  children: ReactNode;
}

export const RecipeActiveTimeProvider: React.FC<RecipeActiveTimeProviderProps> = ({
  children,
}) => {
  const totalRecipeActiveSecondsRef = useRef<number>(0);
  const intervalRef = useRef<number | null>(null);
  const idleTimeoutRef = useRef<number | null>(null);

  const getTotalRecipeActiveSeconds = () => totalRecipeActiveSecondsRef.current;

  const startRecipeActiveTimer = () => {
    if (!intervalRef.current) {
      intervalRef.current = window.setInterval(() => {
        totalRecipeActiveSecondsRef.current += 1;
      }, 1000);
    }
  };

  const stopRecipeActiveTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const resetIdleTimeout = () => {
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
    }
    idleTimeoutRef.current = window.setTimeout(() => {
      stopRecipeActiveTimer();
    }, 2000); // 2 seconds of inactivity
  };

  const handleActivity = () => {
    startRecipeActiveTimer();
    resetIdleTimeout();
  };

  const resetTimer = () => {
    totalRecipeActiveSecondsRef.current = 0;
    handleActivity();
  };

  const setInitialRecipeActiveSeconds = (seconds: number) => {
    totalRecipeActiveSecondsRef.current = seconds;
    handleActivity();
  };

  useEffect(() => {
    const handleMouseMove = () => {
      handleActivity();
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
    };
  }, []);

  return (
    <RecipeActiveTimeContext.Provider
      value={{
        getTotalRecipeActiveSeconds,
        setInitialRecipeActiveSeconds,
        resetTimer,
      }}
    >
      {children}
    </RecipeActiveTimeContext.Provider>
  );
};
