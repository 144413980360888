import styled from 'styled-components/macro';
import { ILayoutColumnProps } from './layoutColumn.types';

export const LayoutColumnContainer = styled.section<ILayoutColumnProps>`
  padding: ${({ padding, theme }) =>
    padding === 'none'
      ? `0px ${theme.layout.padding}`
      : padding === 'small'
      ? `${theme.layout.padding}`
      : `30px ${theme.layout.padding}`};
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: ${({
    colOneWidth,
    colTwoWidth,
    colThreeWidth,
    colFourWidth,
  }) => {
    const cOneWidth = colOneWidth ? colOneWidth : '1fr';
    const cTwoWidth = colTwoWidth ? colTwoWidth : '1fr';
    const cThreeWidth = colThreeWidth ? colThreeWidth : '';
    const cFourWidth = colFourWidth ? colFourWidth : '';
    return `${cOneWidth && cOneWidth} ${cTwoWidth && cTwoWidth} ${
      cThreeWidth && cThreeWidth
    } ${cFourWidth && cFourWidth}`;
  }};
`;
