import styled from 'styled-components/macro';
import { commonStyles } from '../FormElements.styles';
import { TextField, MenuItem } from '@material-ui/core';
import { Span } from '../../typefaces/Typefaces.styles';
import { IStyledSelectListProps } from '../selectList/selectList.types';

export const AddressLoading = styled.div`
  display: flex;
  align-items: center;
  height: ${() => commonStyles.defaultHeight};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.default.faded};
  border-radius: 5px;
  padding-left: 14px;
  color: ${({ theme }) => theme.colors.grey.faded};
  font-size: ${({ theme }) => theme.fontSize.default};
`;

export const AddressContainer = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  min-height: 55px;
  margin-top: 8px;

  &.hiddenInput {
    display: none;
  }
`;

export const StyledSelectList = styled(TextField)<IStyledSelectListProps>`
  width: 100%;
  .MuiOutlinedInput-root {
    font-size: ${({ height, theme }) =>
      height === 'large' ? theme.fontSize.large : theme.fontSize.default};
    height: ${({ height }) =>
      height === 'large'
        ? commonStyles.largeHeight
        : commonStyles.defaultHeight};
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? 'transparent' : theme.colors.default.faded};
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
      }
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
      }
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: red;
      }
    }
  }
  .MuiOutlinedInput-root.noBorder {
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    ${({ alignRight }) =>
      alignRight &&
      `
      .MuiOutlinedInput-input {
        padding-right: 20px;
      }
      svg {
        right: -7px;
      }
    `}
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .noBorder {
    .MuiSelect-select:focus {
      background-color: ${({ theme }) => theme.colors.background};
      border-radius: 5px;
    }
  }

  .MuiInputLabel-outlined {
    font-size: ${({ height, theme }) =>
      height === 'large' ? theme.fontSize.large : theme.fontSize.default};
    color: ${({ theme }) => theme.colors.grey.faded};
    transform: ${({ height }) =>
        height === 'large' ? 'translate(15px, 17px)' : 'translate(15px, 15px)'}
      scale(1);
    &.MuiInputLabel-shrink {
      transform: ${({ height }) =>
        height === 'large'
          ? 'translate(15px, -9px) scale(0.5)'
          : 'translate(15px, -9px) scale(0.75)'};
    }
    &.Mui-focused {
      color: ${(props) => props.theme.colors.text.grey};
      transform: ${({ height }) =>
        height === 'large'
          ? 'translate(15px, -9px) scale(0.5)'
          : 'translate(15px, -9px) scale(0.75)'};
    }
    &.Mui-error {
      color: red;
    }
  }

  .MuiFormHelperText-contained {
    color: ${({ theme }) => theme.colors.secondary.default};
    margin: 4px 15px;
  }

  input,
  div.MuiOutlinedInput-input {
    &::-internal-autofill-selecte {
      background-color: none;
    }
    color: ${({ theme }) => theme.colors.text.grey};

    padding: 14px;

    ::placeholder {
      color: ${({ theme }) => theme.colors.grey.faded};
      opacity: 1 !important;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.grey.dark};
    }
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
  }

  .Mui-focused {
    input {
      color: ${({ theme }) => theme.colors.text.default};
    }
  }

  .Mui-error {
    color: red;
  }

  .MuiAutocomplete-endAdornment {
    display: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  }
`;

export const StyledSelectMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    color: ${({ theme }) => theme.colors.text.default};
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.background};
    }

    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors.background};
    }
  }
`;

export const StyledAutocomplete = styled(StyledSelectList)`
  margin-top: 8px;
`;

export const Highlight = styled(Span)`
  color: ${({ theme }) => theme.colors.primary.default};
  padding-left: 3px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const AddressHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ManualAddress = styled.div``;

export const SwapAddress = styled.button`
  color: ${({ theme }) => theme.colors.primary.default};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  text-align: right;
  padding: 5px 14px;
  display: flex;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-right: -10px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.default};
  }
`;
