import React, { FC } from 'react';
import { H2, Span, Label } from '../../../../shared/typefaces/Typefaces.styles';
import {
  Container,
  HeaderContainer,
  Headings,
  HeadingsItem,
} from './Header.styles';
import { QueryResult } from '@apollo/client';
import {
  Exact,
  InventoryStocktakeQuery,
  GetInventoryStocktakeInput,
} from '../../../../../generated/graphql';
import { convertNumberCleanly } from '../../../../../utils/helper/numbers';

interface IHeaderProps {
  stocktake: QueryResult<
    InventoryStocktakeQuery,
    Exact<{
      input: GetInventoryStocktakeInput;
    }>
  >;
}

const Header: FC<IHeaderProps> = ({ stocktake }) => {
  const stoketakeData = stocktake.data?.inventoryStocktake.stocktake;
  let ingredientIds: string[] = [];
  let supplierIds: string[] = [];
  stoketakeData?.stocktakeSections.forEach((s) => {
    s.stocktakeIngredients.forEach((i) => {
      const ingredientId = i.ingredient.id;
      const supplierId = i.ingredient.ingredientProducts[0].supplier?.id;
      if (ingredientId) {
        const ingredientIncluded = supplierIds.includes(ingredientId);
        if (!ingredientIncluded) {
          ingredientIds.push(ingredientId);
        }
      }
      if (supplierId) {
        const supplierIncluded = supplierIds.includes(supplierId);
        if (!supplierIncluded) {
          supplierIds.push(supplierId);
        }
      }
    });
  });

  return (
    <Container>
      <HeaderContainer>
        <Headings>
          <HeadingsItem>
            <Label className="headerLabel" color="faded" fontSize="small">
              Total Ingredients
            </Label>
            <H2>{convertNumberCleanly(ingredientIds.length)}</H2>
          </HeadingsItem>
          <HeadingsItem>
            <Label className=" headerLabel" color="faded" fontSize="small">
              Total Suppliers
            </Label>
            <Span fontSize="heading">
              {convertNumberCleanly(supplierIds.length)}
            </Span>
          </HeadingsItem>
        </Headings>
      </HeaderContainer>
    </Container>
  );
};

export default Header;
