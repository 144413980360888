import React, { useContext } from 'react';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useRecipeQuery } from '../../../generated/graphql';
import { isEmpty } from '../../../utils/helper';
import { LogoLoading } from '../../shared/loading';
import Recipe from '../../shared/recipe';
import { RecipeActiveTimeProvider } from '../../../utils/customHooks/useRecipeActiveTimeTracker';

const EditRecipe = () => {
  const {
    selectedRecipe,
    selectedVenueObject,
    selectedRecipeVersion,
  } = useContext(LayoutContext);

  const { data, loading, error } = useRecipeQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
        recipeId: selectedRecipe!,
      },
    },
  });

  let selectedVersion = data?.recipe.recipe?.recipeVersions.find((version) =>
    selectedRecipeVersion
      ? version.id === selectedRecipeVersion
      : version.selected
  );

  if (!data || loading || error) {
    return <LogoLoading size={60} />;
  }

  if (!isEmpty(data?.recipe.recipe) && !isEmpty(selectedVersion)) {
    return (
      <RecipeActiveTimeProvider>
        <Recipe recipe={data?.recipe.recipe!} recipeVersion={selectedVersion} />
      </RecipeActiveTimeProvider>
    );
  }
  return (
    <RecipeActiveTimeProvider>
      <Recipe />
    </RecipeActiveTimeProvider>
  );
};

export default EditRecipe;
