import {
  StocktakeSectionFormQuery,
  StocktakeSectionFormState,
} from '../../components/shared/stocktake/stocktake.types';
import { isEmpty } from './objects';
import { sortArrayOfObjects } from './sort';

export const getInitialStocktakeSectionFormItems = (
  stocktakeSection: StocktakeSectionFormQuery | undefined
): StocktakeSectionFormState['items'] => {
  if (isEmpty(stocktakeSection)) return [];

  const itemsArray: StocktakeSectionFormState['items'] = [];

  for (const sectionIngredient of stocktakeSection?.stocktakeIngredients ??
    []) {
    const activeRequired = sectionIngredient.ingredient.ingredientProducts[0].required.find(
      (r) => r.active
    );

    const requiredQuantity = activeRequired?.quantity || 0;

    itemsArray.push({
      __typename: 'StocktakeSectionIngredient',
      type: 'INGREDIENT',

      rowKey: sectionIngredient.id,
      stocktakeSectionItemId: sectionIngredient.id,
      ingredientProductId:
        sectionIngredient.ingredient.ingredientProducts[0].id,
      order: sectionIngredient.order,
      ingredient: {
        label: sectionIngredient.ingredient.displayName,
        value: sectionIngredient.ingredient.id,
        extra: 'ingredient',
      },

      quantity:
        sectionIngredient.quantity !== 0
          ? sectionIngredient.quantity.toString()
          : '',

      unit:
        sectionIngredient.ingredient.ingredientProducts[0].size?.unitAmount?.toString() ||
        '',
      required: requiredQuantity.toString(),
      unitType:
        sectionIngredient.ingredient.ingredientProducts[0].size?.unitType?.toString() ||
        '',
    });
  }

  return itemsArray.sort(sortArrayOfObjects('order'));
};
