import styled from 'styled-components/macro';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../../../styles/device';
import { ListGroup } from '../../../../shared/typefaces/Typefaces.styles';

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 15px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 15px;
    grid-gap: 30px;
    margin-left: 60px;
  }
`;

export const ListEnd = styled(ListGroup)`
  padding-bottom: 0 !important;
`;
