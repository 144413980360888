import { VenueQuery, VenueType } from '../../generated/graphql';
import { capitalizeFirstLetterPerWord } from './strings';

export const formatVenueFormData = (
  data: VenueQuery | undefined,
  {
    displayName,
    email,
    type,
    phone,
    internationalPhone,
    website,
    weeksOpen,
    weeksOpenUnit,
    prepTime,
    prepTimeUnit,
    avgWastagePercentage,
    wastageUnit,
    venueCostId,
    chefCost,
    chefCostUnit,
    councilCost,
    councilCostUnit,
    insuranceCost,
    insuranceCostUnit,
    rentCost,
    rentCostUnit,
    powerCost,
    powerCostUnit,
    waterCost,
    waterCostUnit,
    targetFoodMargin,
    addressId,
    location,
    unit,
    streetNumber,
    road,
    city,
    shire,
    state,
    country,
    postcode,
    addressLine1,
    addressLine2,
    addressLookup,
    lat,
    lng,
  }: any
) => {
  const avgWaste =
    avgWastagePercentage.value === 'zero' || avgWastagePercentage.value === '0'
      ? 0
      : avgWastagePercentage.value === 'average'
      ? null
      : Number(avgWastagePercentage.value);

  return {
    displayName: capitalizeFirstLetterPerWord(displayName),
    email,
    type: type as VenueType,
    phone: phone?.toString() || '',
    internationalPhone: internationalPhone?.toString() || '',
    website,
    weeksOpen: Number(weeksOpen),
    weeksOpenUnit,
    prepTime: prepTime || 0,
    prepTimeUnit,
    avgWastagePercentage: avgWaste,
    wastageUnit,
    venueCostId,
    targetFoodMargin: Number(targetFoodMargin) || null,
    venueCost: {
      id: data?.venue?.userVenue?.venue.venueCost.id,
      chefCost: Number(chefCost),
      chefCostUnit,
      councilCost: Number(councilCost),
      councilCostUnit,
      insuranceCost: Number(insuranceCost),
      insuranceCostUnit,
      rentCost: Number(rentCost),
      rentCostUnit,
      powerCost: Number(powerCost),
      powerCostUnit,
      waterCost: Number(waterCost),
      waterCostUnit,
    },
    venueAddressId: addressId,
    venueAddress: {
      addressId,
      location,
      unit,
      streetNumber,
      road,
      city,
      shire,
      state,
      country,
      postcode,
      addressLine1,
      addressLine2,
      addressLookup,
      lat,
      lng,
    },
  };
};
