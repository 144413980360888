import React, { useContext, FC } from 'react';
import { Card } from '../../../../shared/card';
import {
  Span,
  Label,
  H2,
  H3,
  ListGroupRow,
  ListGroup,
} from '../../../../shared/typefaces/Typefaces.styles';
import {
  Container,
  Column,
  Heading,
  ColumnRight,
  SubHeading,
  Header,
  ListEnd,
  HeadingColumns,
  ListColumns,
  Dollar,
  StyledInput,
  StyledRightInput,
} from './ProfitProjection.styles';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { useFormContext, Controller } from 'react-hook-form';
import {
  convertGramsCleanly,
  convertCostCleanly,
  blurContent,
  validEndDate,
  convertPercentageCleanly,
} from '../../../../../utils/helper';
import { IRecipeDataResult } from '../../../../../utils/clientServerShared/getRecipeData.types';
import { useRestrictionCheck } from '../../../../../utils/helper/account';
import { AccountType, AddOnApp } from '../../../../../generated/graphql';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';

const ProfitProjection: FC<{ recipeData: IRecipeDataResult }> = ({
  recipeData,
}) => {
  const { appWidth, toolTips, account, dispatch } = useContext(LayoutContext);
  const { errors, control } = useFormContext();
  const showRestrictionPopup = useRestrictionPopup();
  const checkRecipeProfitRestrictions = useRestrictionCheck([
    'CALCULATE_RECIPE_PROFIT',
  ]);

  const checkSalesPriceToolTip = () => {
    if (toolTips.salesPrice) {
      dispatch({
        type: 'SET_TOOL_TIPS',
        payload: { salesPrice: false },
      });
    }
  };
  const checkWeeklySalesToolTip = () => {
    if (toolTips.weeklySales) {
      dispatch({
        type: 'SET_TOOL_TIPS',
        payload: { weeklySales: false },
      });
    }
  };

  const handleRecipeRevenueUpgrade = () => {
    showRestrictionPopup(
      restrictionPopupMessages.cannotDisplayRecipeVersionProfit
    );
  };

  const calculatorAddOn = account?.addOns.find(
    (a) => a.app === AddOnApp.Calculator
  );
  const { endDateIsValid } = validEndDate(calculatorAddOn?.endDate);
  const restrictionCheck = checkRecipeProfitRestrictions({
    data: account?.type === AccountType.BusinessStarter,
  });
  const showProfitContent = endDateIsValid || restrictionCheck.isPass;

  return (
    <Container>
      <HeadingColumns colOneWidth="auto" colTwoWidth="auto">
        <Column>
          <Heading>
            <Controller
              as={<StyledInput />}
              type="number"
              name="salesPricePerServe"
              border={toolTips.salesPrice ? '' : 'noBorder'}
              control={control}
              errorText={
                errors.salesPricePerServe && errors.salesPricePerServe.message
              }
              startsymbol={<Dollar>$</Dollar>}
              defaultValue=""
              onClick={checkSalesPriceToolTip}
            />{' '}
            <Span className="profitHeaderSpan serve">Per serve</Span>
          </Heading>
          <SubHeading>
            <Label
              fontSize="small"
              className="profitHeaderSpan"
              fontWeight="semibold"
              color="faded"
            >
              Recommended Price:
            </Label>{' '}
            <Span
              fontSize="small"
              className={`profitHeaderSpan ${
                !showProfitContent && 'blur hasSeeIcon'
              }`}
              onClick={() => !showProfitContent && handleRecipeRevenueUpgrade()}
            >
              {showProfitContent
                ? convertCostCleanly(recipeData.recommendedPrice)
                : blurContent(convertCostCleanly(recipeData.recommendedPrice))}
            </Span>
          </SubHeading>
          <SubHeading>
            <Label
              fontSize="small"
              className="profitHeaderSpan"
              fontWeight="semibold"
              color="faded"
            >
              Version:
            </Label>{' '}
            <Span fontSize="small" className="profitHeaderSpan">
              {recipeData.versionName}
            </Span>
          </SubHeading>
        </Column>
        {appWidth < theme.mQ.mobileM ? (
          <Column>
            <Heading>
              <Controller
                as={<StyledInput $noPadding $small />}
                type="number"
                name="weeklySalesPerServe"
                border={toolTips.weeklySales ? '' : 'noBorder'}
                control={control}
                errorText={
                  errors.weeklySalesPerServe &&
                  errors.weeklySalesPerServe.message
                }
                defaultValue=""
                onClick={checkWeeklySalesToolTip}
              />{' '}
              <Span className="profitHeaderSpan weeklySales">Weekly Sales</Span>
            </Heading>
            <SubHeading>
              <Label
                fontSize="small"
                className="profitHeaderSpan"
                fontWeight="semibold"
                color="faded"
              >
                Grams Per Serve:
              </Label>{' '}
              <Span fontSize="small" className="profitHeaderSpan">
                {convertGramsCleanly(recipeData.gramsPerServe)}
              </Span>
            </SubHeading>
            <SubHeading>
              <Label
                fontSize="small"
                className="profitHeaderSpan"
                fontWeight="semibold"
                color="faded"
              >
                Total Recipe Grams:
              </Label>{' '}
              <Span fontSize="small" className="profitHeaderSpan">
                {convertGramsCleanly(recipeData.totalGrams)}
              </Span>
            </SubHeading>
          </Column>
        ) : (
          <Column>
            <ColumnRight className="rightHeading">
              <Span className="profitHeaderSpan weeklySales">Weekly Sales</Span>{' '}
              <Controller
                as={<StyledRightInput />}
                type="number"
                name="weeklySalesPerServe"
                border={toolTips.weeklySales ? '' : 'noBorder'}
                control={control}
                errorText={
                  errors.weeklySalesPerServe &&
                  errors.weeklySalesPerServe.message
                }
                defaultValue=""
                onClick={checkWeeklySalesToolTip}
              />
            </ColumnRight>
            <SubHeading>
              <ColumnRight>
                <Label
                  fontSize="small"
                  className="profitHeaderSpan"
                  fontWeight="semibold"
                  color="faded"
                >
                  Grams Per Serve:
                </Label>
                <Span fontSize="small" className="profitHeaderSpan">
                  {convertGramsCleanly(recipeData.gramsPerServe)}
                </Span>
              </ColumnRight>
            </SubHeading>
            <SubHeading>
              <ColumnRight>
                <Label
                  fontSize="small"
                  className="profitHeaderSpan"
                  fontWeight="semibold"
                  color="faded"
                >
                  Total Recipe Grams:
                </Label>
                <Span fontSize="small" className="profitHeaderSpan">
                  {convertGramsCleanly(recipeData.totalGrams)}
                </Span>
              </ColumnRight>
            </SubHeading>
          </Column>
        )}
      </HeadingColumns>

      <Card withBackground withCardLink>
        <Header colOneWidth="auto" colTwoWidth="auto" padding="small">
          <Column>
            <H2 fontSize="large">Profit & Projections</H2>
            {appWidth <= theme.mQ.mobileM && (
              <div>
                <Label
                  fontSize="small"
                  fontWeight="semibold"
                  color="faded"
                  className="profitHeaderSpan"
                >
                  Recipe Version Serves
                </Label>{' '}
                <Span fontSize="small" className="profitHeaderSpan">
                  {recipeData.serves}
                </Span>
              </div>
            )}
          </Column>
          {appWidth > theme.mQ.mobileM && (
            <Column className="cardHeaderItemRight">
              <H2 fontSize="large" className="inlineHeaderTitle" color="grey">
                {recipeData.serves}
              </H2>
              <Label
                fontSize="small"
                fontWeight="semibold"
                color="faded"
                className="profitHeaderSpan"
              >
                Recipe Version Serves
              </Label>
            </Column>
          )}
        </Header>

        <ListColumns colOneWidth="auto" colTwoWidth="auto" padding="small">
          <div>
            <H3 fontWeight="semibold" color="black">
              Gross Profit
            </H3>
            <ListGroup>
              <ListGroupRow colOneWidth="130px" colTwoWidth="auto">
                <Label color="grey">per Serve</Label>
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(recipeData.profitPerServe)
                    : blurContent(
                        convertCostCleanly(recipeData.recommendedPrice)
                      )}
                </Span>
              </ListGroupRow>
              <ListGroupRow colOneWidth="130px" colTwoWidth="auto">
                <Label color="grey">per Month</Label>
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(recipeData.profitPerMonth)
                    : blurContent(
                        convertCostCleanly(recipeData.profitPerMonth)
                      )}
                </Span>
              </ListGroupRow>
              <ListGroupRow colOneWidth="130px" colTwoWidth="auto">
                <Label color="grey">per Year</Label>
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(recipeData.profitPerYear)
                    : blurContent(convertCostCleanly(recipeData.profitPerYear))}
                </Span>
              </ListGroupRow>
              <ListGroupRow colOneWidth="130px" colTwoWidth="auto">
                <Label color="grey">per Minute</Label>
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(recipeData.profitPerMin)
                    : blurContent(convertCostCleanly(recipeData.profitPerMin))}
                </Span>
              </ListGroupRow>
            </ListGroup>
            <ListEnd>
              <ListGroupRow colOneWidth="130px" colTwoWidth="auto">
                <Label color="grey">Recipe Profit</Label>
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertCostCleanly(recipeData.recipeProfit)
                    : blurContent(convertCostCleanly(recipeData.recipeProfit))}
                </Span>
              </ListGroupRow>
              <ListGroupRow colOneWidth="130px" colTwoWidth="auto">
                <Label color="grey">Recipe Margin</Label>
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertPercentageCleanly(
                        recipeData.profitMarginPercentage
                      )
                    : blurContent(
                        convertPercentageCleanly(
                          recipeData.profitMarginPercentage
                        )
                      )}
                </Span>
              </ListGroupRow>
            </ListEnd>
          </div>
          <div>
            <H3 fontWeight="semibold" color="black">
              Net Revenue
            </H3>
            <ListGroup>
              <ListGroupRow colOneWidth="100px" colTwoWidth="auto">
                <Label color="grey">per Serve</Label>
                <Span>{convertCostCleanly(recipeData.salesPricePerServe)}</Span>
              </ListGroupRow>
              <ListGroupRow colOneWidth="100px" colTwoWidth="auto">
                <Label color="grey">per Month</Label>
                <Span>{convertCostCleanly(recipeData.revenuePerMonth)}</Span>
              </ListGroupRow>
              <ListGroupRow colOneWidth="100px" colTwoWidth="auto">
                <Label color="grey">per Year</Label>
                <Span>{convertCostCleanly(recipeData.revenuePerYear)}</Span>
              </ListGroupRow>
              {appWidth > theme.mQ.mobileM && <ListGroupRow />}
            </ListGroup>
            <ListEnd>
              <ListGroupRow colOneWidth="100px" colTwoWidth="auto">
                <Label color="grey">Total Recipe</Label>
                <Span>{convertCostCleanly(recipeData.recipeRevenue)}</Span>
              </ListGroupRow>
            </ListEnd>
          </div>
        </ListColumns>
        <ListColumns colOneWidth="auto" colTwoWidth="auto" padding="small">
          <div>
            <H3 fontWeight="semibold" color="black">
              Mark Up
            </H3>
            <ListGroup>
              <ListGroupRow colOneWidth="130px" colTwoWidth="auto">
                <Label color="grey">Food Markup</Label>
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertPercentageCleanly(
                        recipeData.ingredientMarkupPercentage
                      )
                    : blurContent(
                        convertPercentageCleanly(
                          recipeData.ingredientMarkupPercentage
                        )
                      )}
                </Span>
              </ListGroupRow>
              <ListGroupRow colOneWidth="130px" colTwoWidth="auto">
                <Label color="grey">Recipe Markup</Label>
                <Span
                  className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                  onClick={() =>
                    !showProfitContent && handleRecipeRevenueUpgrade()
                  }
                >
                  {showProfitContent
                    ? convertPercentageCleanly(recipeData.markupPercentage)
                    : blurContent(
                        convertPercentageCleanly(recipeData.markupPercentage)
                      )}
                </Span>
              </ListGroupRow>
            </ListGroup>
          </div>
          <div>
            <H3 fontWeight="semibold" color="black">
              Recommendations
            </H3>
            <ListGroup>
              <ListGroupRow colOneWidth="140px" colTwoWidth="auto">
                <Label color="grey">Coming soon</Label>
              </ListGroupRow>
            </ListGroup>
          </div>
        </ListColumns>
      </Card>
    </Container>
  );
};

export default ProfitProjection;
