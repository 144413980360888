import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../../../styles/device';
import { IContainerProps, IItemTextProps } from './menuItem.types';

export const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  width: 100%;
  cursor: pointer;

  span {
    color: ${({ theme, highlight }) => highlight && theme.colors.text.default};
  }
  svg {
    fill: ${({ theme, highlight }) => highlight && theme.colors.text.default};
  }

  &:hover {
    span {
      color: ${({ theme, highlight }) => theme.colors.text.default};
    }
    svg {
      fill: ${({ theme, highlight }) => theme.colors.text.default};
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    width: 180px;
  `};

  @media ${device.laptop} {
    width: 180px;

    ${({ isOpen }) =>
      isOpen
        ? `
    width: 180px;
  `
        : `
    width: 100%;
  `};
  }
`;

export const LinkStyled = styled(Link)`
  outline: 0;
`;

export const ItemText = styled.span<IItemTextProps>`
  display: none;
  color: ${({ theme, highlight }) =>
    highlight
      ? theme.colors.text.default
      : theme.colors.text.grey}; /* Or 918E8B */
  font-weight: ${({ theme, highlight }) =>
    highlight ? theme.fontWeight.medium : theme.fontWeight.label};
  font-size: ${({ theme }) => theme.fontSize.default};
  line-height: 20px;
  padding-left: 20px;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.text.default};
    /* font-weight: ${({ theme }) => theme.fontWeight.medium}; */
  }

  ${({ isOpen }) =>
    isOpen
      ? `
        display: block;
      `
      : `display: none`};
`;
