import React, { FC } from 'react';
import { ILoadingProps } from './dotsLoading.types';
import { Dots, Container } from './DotsLoading.styles';

const DotsLoading: FC<ILoadingProps> = ({
  size,
  isLoading,
  lineHeight,
  color,
  noMargin,
  className,
  text,
}) => {
  return (
    <>
      {text && text(isLoading || false)}
      <Container
        lineHeight={lineHeight}
        isLoading={isLoading}
        noMargin={noMargin}
        className={className}
      >
        <Dots size={size} color={color} />
      </Container>
    </>
  );
};

export default DotsLoading;
