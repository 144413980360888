import React, {
  useEffect,
  useRef,
  createContext,
  useContext,
  ReactNode,
} from 'react';

// Define the shape of the context value
interface ActiveTimeContextType {
  getTotalActiveSeconds: () => number;
  setInitialActiveSeconds: (seconds: number) => void;
  resetTimer: () => void;
}

// Create the context
const ActiveTimeContext = createContext<ActiveTimeContextType | undefined>(
  undefined
);

export const useActiveTimeTracker = (): ActiveTimeContextType => {
  const context = useContext(ActiveTimeContext);
  if (!context) {
    throw new Error(
      'useActiveTimeTracker must be used within an ActiveTimeProvider'
    );
  }
  return context;
};

interface ActiveTimeProviderProps {
  children: ReactNode;
}

export const ActiveTimeProvider: React.FC<ActiveTimeProviderProps> = ({
  children,
}) => {
  const totalActiveSecondsRef = useRef<number>(0);
  const intervalRef = useRef<number | null>(null);
  const idleTimeoutRef = useRef<number | null>(null);

  const getTotalActiveSeconds = () => totalActiveSecondsRef.current;

  const startActiveTimer = () => {
    if (!intervalRef.current) {
      intervalRef.current = window.setInterval(() => {
        totalActiveSecondsRef.current += 1;
      }, 1000);
    }
  };

  const stopActiveTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const resetIdleTimeout = () => {
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
    }
    idleTimeoutRef.current = window.setTimeout(() => {
      stopActiveTimer();
    }, 2000); // 2 seconds of inactivity
  };

  const handleActivity = () => {
    startActiveTimer();
    resetIdleTimeout();
  };

  const resetTimer = () => {
    totalActiveSecondsRef.current = 0;
    handleActivity();
  };

  const setInitialActiveSeconds = (seconds: number) => {
    totalActiveSecondsRef.current = seconds;
    handleActivity();
  };

  useEffect(() => {
    const handleMouseMove = () => {
      handleActivity();
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
    };
  }, []);

  return (
    <ActiveTimeContext.Provider
      value={{
        getTotalActiveSeconds,
        setInitialActiveSeconds,
        resetTimer,
      }}
    >
      {children}
    </ActiveTimeContext.Provider>
  );
};
