import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { H2, H3 } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div`
  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.default};
    line-height: ${({ theme }) => theme.lineHeight.large};
    padding-bottom: 0;
    margin-bottom: 15px;
  }

  img {
    width: 60px;
    margin: 0 auto 30px;
  }

  .ctaLaptop {
    display: none;
    @media ${device.mobileL} {
      display: block;
    }
  }

  .ctaMobile {
    display: block;
    margin-top: 15px;

    @media ${device.mobileL} {
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  .heading {
    margin: 0 !important;
  }

  .subHeading {
    color: ${({ theme }) => theme.colors.text.faded};
    text-align: center;
  }

  .semibold {
    color: ${({ theme }) => theme.colors.text.faded};
    display: inline;
    font-size: ${({ theme }) => theme.fontSize.medium};
  }

  ${H2} {
    font-size: ${({ theme }) => theme.fontSize.heading};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.default};
    margin: 0 0 30px 0;
  }
`;
