import React, { FC, memo, useContext } from 'react';
import { Button } from '../../../../shared/button';
import { Card } from '../../../../shared/card';
import { RecipeBook, Share, Edit } from './../../../../shared/icons';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Header, Content } from '../../../../shared/card';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { Table, Row, Col, HeaderRow, Icon } from './RecipeList.styles';
import { useHistory } from 'react-router-dom';
import {
  convertCostCleanly,
  isEmpty,
  roundTo,
} from '../../../../../utils/helper';
import { DotsLoading } from '../../../../shared/loading';
import { IRecipeListProps } from '../../recipes.types';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

export const RecipeList: FC<IRecipeListProps> = ({ data }) => {
  const { appWidth, account, user, newRecipe, dispatch } = useContext(
    LayoutContext
  );
  const history = useHistory();

  interface IHandleEditRecipeProps {
    id: number;
    salesPricePerServe: number;
    weeklySalesPerServe: number;
  }

  const handleEditRecipe = ({
    id,
    salesPricePerServe,
    weeklySalesPerServe,
  }: IHandleEditRecipeProps) => {
    dispatch({ type: 'SELECT_RECIPE', payload: id });
    dispatch({ type: 'SELECT_RECIPE_VERSION', payload: '' });
    if (newRecipe) {
      dispatch({
        type: 'SET_NEW_RECIPE',
        payload: false,
      });
    }
    if (salesPricePerServe === 0 && weeklySalesPerServe === 0) {
      dispatch({
        type: 'SET_NEW_RECIPE',
        payload: true,
      });
    }

    if (!isEmpty(recipes) && recipes!.length === 1) {
      dispatch({
        type: 'RESET_ALL_TOOL_TIPS',
      });
    }
    history.push('/recipe-results');
  };

  const handleAddRecipe = () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountAddRecipe,
      });
    }

    dispatch({ type: 'SELECT_RECIPE', payload: '' });
    dispatch({ type: 'SELECT_RECIPE_VERSION', payload: '' });
    if (isEmpty(recipes)) {
      dispatch({
        type: 'SET_TOOL_TIPS',
        payload: { editRecipe: true, recipeIngredients: true },
      });
    }
    history.push('/add-recipe');
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow>
          <Col>
            <Label>Recipe Name</Label>
          </Col>
          <Col>
            <Label>Recipe revenue</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow>
          <Col>
            <Label>Recipe Name</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <Label>Recipe revenue</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow>
          <Col>
            <Label>Recipe Name</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <Label>Recipe revenue</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow>
          <Col>
            <Label>Recipe Name</Label>
          </Col>
          <Col>
            <Label>ROI</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <Label>Recipe revenue</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow>
          <Col />
          <Col>
            <Label>Recipe Name</Label>
          </Col>
          <Col>
            <Label>Return on investment</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <Label>Recipe revenue</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const getTableRows = (col, idx) => {
    const {
      id,
      recipeRevenue,
      recipeProfit,
      foodCostPercentage,
      salesPricePerServe,
      weeklySalesPerServe,
    } = col;

    const recipeCost = recipeRevenue - recipeProfit;
    const roi =
      recipeProfit > 0 && recipeRevenue !== recipeProfit && recipeCost
        ? (recipeProfit - recipeCost) / recipeCost
        : 0;

    const editData = {
      id,
      salesPricePerServe,
      weeklySalesPerServe,
    };

    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <Row key={idx} onClick={() => handleEditRecipe(editData)}>
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>{convertCostCleanly(recipeRevenue)}</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row key={idx} onClick={() => handleEditRecipe(editData)}>
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>
              {foodCostPercentage ? roundTo(foodCostPercentage) + '%' : '-'}
            </Span>
          </Col>
          <Col>
            <Span>{convertCostCleanly(recipeRevenue)}</Span>
          </Col>
          <Col onClick={() => handleEditRecipe(editData)}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <Row key={idx} onClick={() => handleEditRecipe(editData)}>
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>{roi ? roundTo(roi) + '%' : '-'}</Span>
          </Col>
          <Col>
            <Span>
              {foodCostPercentage ? roundTo(foodCostPercentage) + '%' : '-'}
            </Span>
          </Col>
          <Col>
            <Span>{convertCostCleanly(recipeRevenue)}</Span>
          </Col>
          <Col onClick={() => handleEditRecipe(editData)}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row key={idx} onClick={() => handleEditRecipe(editData)}>
          <Col />
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>{roi ? roundTo(roi) + '%' : '-'}</Span>
          </Col>
          <Col>
            <Span>
              {foodCostPercentage ? roundTo(foodCostPercentage) + '%' : '-'}
            </Span>
          </Col>
          <Col>
            <Span>{convertCostCleanly(recipeRevenue)}</Span>
          </Col>
          <Col onClick={() => handleEditRecipe(editData)}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  };

  const recipes = data?.data?.venueRecipes?.recipes;

  return (
    <Card>
      <Header
        icon={<RecipeBook size="small" />}
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? 'Add Recipe'
            : 'Recipes'
        }
        subHeading="Optimise recipes & refine costs"
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={() => handleAddRecipe()}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button color="primary" onClick={() => handleAddRecipe()}>
              Add Recipe
            </Button>
          )
        }
      />
      <Content fullWidth>
        <Table>
          <DotsLoading
            isLoading={data.loading}
            size="large"
            lineHeight={10}
            color="default"
          />
          {!isEmpty(recipes) && getTableHeader()}
          {recipes
            ? recipes
                .sort((a, b) => {
                  var nameA = a.displayName.toLowerCase(),
                    nameB = b.displayName.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
                .map((col, idx) => getTableRows(col, idx))
            : null}
        </Table>
      </Content>
    </Card>
  );
};

export default memo(RecipeList);
