import React, { FC, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Container, Highlight } from './Address.styles';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { Control, Controller, UnpackNestedValue } from 'react-hook-form';
import { AutoCompleteOptions } from '../selectList/selectList.types';
import { StyledInput } from '../input/Input.styles';
import { DotsLoading } from '../../loading';
import { useAddress } from '../../../../utils/customHooks/useAddress';

interface IAddressInputProps {
  name: string;
  control: Control<Record<string, any>>;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  id?: string;
  errorText?: string;
  helperText?: string;
  className?: string;
  getFormattedFormValues?: (
    address: IAddressFormatProps,
    formValues: {
      [x: string]: any;
    }
  ) => {
    [x: string]: any;
  };
  onEnterManually: () => void;
  getValues: {
    (): {
      [x: string]: any;
    };
    <TFieldName extends string, TFieldValue extends unknown>(
      name: TFieldName
    ): TFieldName extends string ? any : TFieldValue;
    <TFieldName extends string>(names: TFieldName[]): UnpackNestedValue<
      Pick<Record<string, any>, TFieldName>
    >;
  };
  reset: (
    values?:
      | {
          [x: string]: any;
        }
      | undefined,
    omitResetState?:
      | Partial<{
          errors: boolean;
          isDirty: boolean;
          isSubmitted: boolean;
          touched: boolean;
          isValid: boolean;
          submitCount: boolean;
          dirtyFields: boolean;
        }>
      | undefined
  ) => void;
}

export interface IAddressFormatProps {
  location: string;
  unit: string;
  streetNumber: string;
  road: string;
  city: string;
  shire: string;
  state: string;
  country: string;
  countryShort: string;
  postcode: string;
  addressLine1: string;
  addressLine2: string;
  lat: number | null;
  lng: number | null;
  phone: string;
  internationalPhone: string | undefined;
  website: string;
}

const AddressInput: FC<IAddressInputProps> = ({
  name,
  control,
  defaultValue,
  label,
  placeholder,
  id,
  errorText,
  helperText,
  className,
  getFormattedFormValues,
  onEnterManually,
  getValues,
  reset,
}) => {
  const [options, setOptions] = useState<AutoCompleteOptions[]>([]);
  const [open, setOpen] = useState(false);
  const { getAddressFromInputValue } = useAddress();
  const {
    suggestions: { data, loading },
    value,
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (data && data.length > 0) {
      const formattedOptions: AutoCompleteOptions[] = data.map((o) => {
        return {
          label: o.description,
          value: o.description,
          freeSolo: true,
          extra: data.map((d) => {
            return {
              description: d.description,
              terms: d.terms,
            };
          }),
        };
      });
      setOptions(formattedOptions);
    }
  }, [data]);

  const handleInputChange = (event, newInputValue) => {
    if (newInputValue === null) {
      setValue('');
      setOpen(false);
    }

    if (event?.type === 'click' || event?.type === 'change') {
      setValue(newInputValue);
      setOpen(true);
    }
  };

  const handleSelect = async (selectedOption: AutoCompleteOptions) => {
    const formValues = getValues();
    try {
      const address = await getAddressFromInputValue(
        selectedOption.value || value
      );

      const formattedFormValues = getFormattedFormValues
        ? await Promise.resolve(getFormattedFormValues(address, formValues))
        : formValues;

      setValue(selectedOption.value);
      const updatedFormValues = {
        ...formattedFormValues,
        [name]: selectedOption.value,
        ...address,
      };
      if (updatedFormValues) {
        reset(updatedFormValues);
        setOpen(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFilter = (
    options: AutoCompleteOptions[],
    params: {
      getOptionLabel: any;
      inputValue: string;
    }
  ) => {
    const filteredOptions: AutoCompleteOptions[] = options.map((o) => {
      return {
        label: o.label,
        value: o.value,
        freeSolo: true,
        extra: o.extra,
      };
    });
    filteredOptions.push({
      inputValue: params.inputValue,
      label: params.inputValue,
      value: params.inputValue,
      freeSolo: true,
    });

    return filteredOptions;
  };

  const handleEnterManually = async (inputValue: string) => {
    const formValues = getValues();
    const address = await getAddressFromInputValue(inputValue || value);
    onEnterManually();
    reset({
      ...formValues,
      [name]: inputValue || value,
      ...address,
    });
  };

  const optionLabel = (o: string | AutoCompleteOptions) => {
    if (typeof o !== 'string') {
      return o.label;
    }
    return o;
  };

  return (
    <Container className={className} id={id}>
      <Controller
        control={control}
        name={name}
        style={{ width: '100%' }}
        defaultValue={defaultValue}
        render={(props) => (
          <Autocomplete
            {...props}
            freeSolo
            open={open}
            options={options}
            getOptionLabel={(o) => optionLabel(o)}
            loading={loading}
            inputValue={value || ''}
            filterOptions={handleFilter}
            onInputChange={handleInputChange}
            onChange={(e, newValue) => {
              e.preventDefault();
              handleSelect(newValue);
            }}
            clearText={''}
            renderOption={(option: any) => {
              return (
                <>
                  {option.inputValue ? (
                    <span
                      onClick={() => handleEnterManually(option.inputValue)}
                    >
                      <Highlight>Enter Manually:</Highlight> {option.inputValue}
                    </span>
                  ) : (
                    option.label
                  )}
                </>
              );
            }}
            renderInput={(params) => (
              <StyledInput
                {...params}
                label={label}
                onBlur={() => setOpen(false)}
                placeholder={placeholder || 'Search Address'}
                variant="outlined"
                error={!!errorText}
                helperText={!!errorText ? errorText : helperText}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'off',
                  endAdornment: (
                    <>
                      {loading ? (
                        <DotsLoading
                          size="small"
                          color="primary"
                          text={() => ''}
                          isLoading={loading}
                          noMargin
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />
    </Container>
  );
};

export default AddressInput;
