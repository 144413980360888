import React, { FC, memo, useContext, useRef } from 'react';
import { Button } from '../../../button';
import { Card } from '../../../card';
import { Share, Edit } from '../../../icons';
import { Label, Span } from '../../../typefaces/Typefaces.styles';
import { Header, Content } from '../../../card';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { Table, Row, Col, HeaderRow, Icon } from './SectionList.styles';
import { isEmpty } from '../../../../../utils/helper';
import { DotsLoading } from '../../../loading';
import { IStocktakeProps } from '../../stocktake.types';
import useResizeDimensions from '../../../../../utils/customHooks/useResizeDimensions';
import { getPercentageDifference } from '../../../../../utils/helper/numbers';

interface IHandleEditSectionProps {
  id: number;
}

export const SectionList: FC<IStocktakeProps> = ({ selectedStocktake }) => {
  const { appWidth, dispatch, toolTips } = useContext(LayoutContext);
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeDimensions(pageWidthRef);
  const differenceRef = useRef<HTMLLIElement>(null);
  const differenceDimensions = useResizeDimensions(differenceRef);
  const totalItemsRef = useRef<HTMLLIElement>(null);
  const totalItemsDimensions = useResizeDimensions(totalItemsRef);
  const totalRequiredItemsRef = useRef<HTMLLIElement>(null);
  const totalRequiredItemsDimensions = useResizeDimensions(
    totalRequiredItemsRef
  );

  const {
    totalItems,
    totalRequiredItems,
    sectionDifference,
  } = toolTips.stocktake;

  const selectedStocktakeData = selectedStocktake.data?.stocktake.stocktake;
  const stocktakeLoading = selectedStocktake.loading || !selectedStocktake.data;

  const handleEditSection = ({ id }: IHandleEditSectionProps) => {
    dispatch({ type: 'SET_SELECTED_STOCKTAKE_SECTION', payload: id });
    dispatch({
      type: 'SET_SELECTED_STOCKTAKE',
      payload: selectedStocktakeData!.id,
    });
    dispatch({ type: 'SLIDER_PAGE', payload: 'editStocktakeSection' });
  };

  const handleAddSection = () => {
    dispatch({ type: 'SET_SELECTED_STOCKTAKE_SECTION', payload: null });
    dispatch({
      type: 'SET_SELECTED_STOCKTAKE',
      payload: selectedStocktakeData!.id,
    });
    dispatch({ type: 'SLIDER_PAGE', payload: 'editStocktakeSection' });
  };

  const onDifferenceClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { sectionDifference: !sectionDifference },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Section Difference',
        content: `This gives you an insight into the difference of quanities you are ordering & the total required amount per section`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: differenceDimensions.top,
        xAxis: differenceDimensions.width,
      },
    });
  };

  const onTotalItemsClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { totalItems: !totalItems },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Total Items',
        content: `The total count of items in this section`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: totalItemsDimensions.top,
        xAxis: totalItemsDimensions.width,
      },
    });
  };

  const onTotalRequiredItemsClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { totalRequiredItems: !totalRequiredItems },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Required Items',
        content: `The total count of required items in this section`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: totalRequiredItemsDimensions.top,
        xAxis: totalRequiredItemsDimensions.width,
      },
    });
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow $loading={stocktakeLoading}>
          <Col>
            <Label>Section Name</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow $loading={stocktakeLoading}>
          <Col>
            <Label>Section Name</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Items</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow $loading={stocktakeLoading}>
          <Col>
            <Label>Section Name</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Items</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow $loading={stocktakeLoading}>
          <Col>
            <Label>Section Name</Label>
          </Col>
          <Col onClick={onDifferenceClick} ref={differenceRef}>
            <Label className="hasToolTipIcon">Difference</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Items</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow $loading={stocktakeLoading}>
          <Col />
          <Col>
            <Label>Section Name</Label>
          </Col>
          <Col onClick={onDifferenceClick} ref={differenceRef}>
            <Label className="hasToolTipIcon">Difference</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Items</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const getTableRows = (col, idx) => {
    const difference = getPercentageDifference(
      col.section.totalRequiredItems,
      col.totalItems
    );

    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <Row
          key={idx}
          onClick={() => handleEditSection({ id: col.section.id })}
        >
          <Col>
            <Span color="grey">{col.section.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.section.totalRequiredItems}</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row
          key={idx}
          onClick={() => handleEditSection({ id: col.section.id })}
        >
          <Col>
            <Span color="grey">{col.section.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.totalItems}</Span>
          </Col>
          <Col>
            <Span>{col.section.totalRequiredItems}</Span>
          </Col>
          <Col onClick={() => handleEditSection({ id: col.section.id })}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <Row
          key={idx}
          onClick={() => handleEditSection({ id: col.section.id })}
        >
          <Col>
            <Span color="grey">{col.section.displayName}</Span>
          </Col>
          <Col>
            <Span>
              {difference && difference !== 0 ? `${difference}%` : '-'}
            </Span>
          </Col>
          <Col>
            <Span>{col.totalItems}</Span>
          </Col>
          <Col>
            <Span>{col.section.totalRequiredItems}</Span>
          </Col>
          <Col onClick={() => handleEditSection({ id: col.section.id })}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row
          key={idx}
          onClick={() => handleEditSection({ id: col.section.id })}
        >
          <Col />
          <Col>
            <Span color="grey">{col.section.displayName}</Span>
          </Col>
          <Col>
            <Span>
              {difference && difference !== 0 ? `${difference}%` : '-'}
            </Span>
          </Col>
          <Col>
            <Span>{col.totalItems}</Span>
          </Col>
          <Col>
            <Span>{col.section.totalRequiredItems}</Span>
          </Col>
          <Col onClick={() => handleEditSection({ id: col.section.id })}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  };

  const stocktakeSections = selectedStocktakeData?.stocktakeSections;
  const subHeading = selectedStocktakeData?.duplicatedName
    ? `Stocktake ${selectedStocktakeData?.displayName} default sections based on ${selectedStocktakeData?.duplicatedName} Stocktake`
    : `Click 'Add Sections' to add stocktake sections to ${
        selectedStocktakeData?.displayName
          ? selectedStocktakeData?.displayName
          : ''
      } Stocktake`;

  return (
    <Card ref={pageWidthRef}>
      <Header
        toolTip={{
          type: 'SET_STOCKTAKE_TOOL_TIPS',
          heading: 'Ingredient Section List',
          content:
            "Each stocktake is made up of sections example: 'Back Fridge' or 'Dry Storage'. Create a sections to organise your Stocktake Ingredients per section",
        }}
        heading={'Ingredient Sections'}
        subHeading={subHeading}
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={() => handleAddSection()}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button color="primary" onClick={() => handleAddSection()}>
              Add Section
            </Button>
          )
        }
      />
      <Content fullWidth>
        <Table>
          <DotsLoading
            isLoading={stocktakeLoading}
            size="large"
            lineHeight={10}
            color="default"
          />
          {!isEmpty(stocktakeSections) && getTableHeader()}
          {stocktakeSections
            ? stocktakeSections
                .sort((a, b) => {
                  var nameA = a.section.displayName.toLowerCase(),
                    nameB = b.section.displayName.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
                .map((col, idx) => getTableRows(col, idx))
            : null}
        </Table>
      </Content>
    </Card>
  );
};

export default memo(SectionList);
