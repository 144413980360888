import React, { forwardRef } from 'react';
import { Container, StyledInput, Icon } from './Input.styles';
import { IInputProps } from './input.types';
import {
  capitalizeFirstLetterPerWord,
  checkNumberInput,
} from '../../../../utils/helper';

const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className,
      type,
      errorText,
      helperText,
      touched,
      handleChange,
      textarea,
      capitalizeFirstLetterEachWord,
      onChange,
      ...props
    },
    ref
  ) => {
    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLDivElement>,
      type?: string
    ) => {
      // 46
      if (type === 'number') {
        return checkNumberInput(e);
      }
      return;
    };

    const handleNumberChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = e?.target?.value;
      const inputValue = !value
        ? ''
        : !isNaN(Number(value))
        ? value
        : value.replace(/\D/g, ''); // Prevents letters from being pasted in and still allows numbers

      onChange && onChange(inputValue);
      if (handleChange) {
        handleChange(inputValue);
      }
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const inputValue = !value ? '' : Number(value);

      onChange && onChange(inputValue);
    };

    const handleInputChange = (v: any) => {
      const input = v.target.value;
      if (onChange) {
        capitalizeFirstLetterEachWord
          ? onChange(capitalizeFirstLetterPerWord(input))
          : onChange(input);
      }
      if (handleChange) {
        handleChange(input);
      }
    };

    return (
      <Container className={className}>
        {props.startsymbol && <Icon>{props.startsymbol}</Icon>}
        {textarea ? (
          <StyledInput
            {...props}
            inputProps={{
              ref,
              step: type === 'number' ? 'any' : 1,
            }}
            error={!!errorText}
            helperText={!!errorText ? errorText : helperText}
            onKeyDown={(e) => handleKeyDown(e, type)}
            label={props.label}
            multiline
            onChange={handleInputChange}
          />
        ) : (
          <StyledInput
            {...props}
            type={type || 'text'}
            inputProps={{
              ref,
              step: type === 'number' ? 'any' : 1,
            }}
            $maxRows={Infinity}
            error={!!errorText}
            helperText={!!errorText ? errorText : helperText}
            onKeyDown={(e) => handleKeyDown(e, type)}
            onChange={
              type === 'number'
                ? handleNumberChange
                : type === 'radio'
                ? handleRadioChange
                : handleInputChange
            }
          />
        )}
      </Container>
    );
  }
);

Input.defaultProps = {
  variant: 'outlined',
  height: 'default',
  type: 'text',
  align: 'left',
};

export default Input;
