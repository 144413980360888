import React, { FC, memo, useContext } from 'react';
import { Col, Container, HeaderRow, Table } from './IngredientList.styles';
import { QueryResult } from '@apollo/client';
import {
  Exact,
  InventoryStocktakeQuery,
  GetInventoryStocktakeInput,
} from '../../../../../generated/graphql';
import { Card, Content, Header } from '../../../../shared/card';
import { RecipeBook } from '../../../../shared/icons';
import {
  ICurrentUserProps,
  ISelectedVenueProps,
  LayoutContext,
} from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { DotsLoading } from '../../../../shared/loading';
import { Label } from '../../../../shared/typefaces/Typefaces.styles';
import SupplierSection from './SupplierSection';
import { roundToWholeNumber } from '../../../../../utils/helper/numbers';
import { isEmpty } from '../../../../../utils/helper';
import { Button } from '../../../../shared/button';
import { useHistory } from 'react-router-dom';

interface IIngredientListProps {
  stocktake: QueryResult<
    InventoryStocktakeQuery,
    Exact<{
      input: GetInventoryStocktakeInput;
    }>
  >;
}

export interface IIngredientProps {
  id: string;
  ingredientProductId: string;
  displayName: string;
  quantity: number;
  required: {
    id: string;
    unitAmount: number;
    active: boolean;
    quantity: number;
  };
  size: {
    id: string;
    productCost: number;
    productGrams: number;
    unitAmount: number;
    unitType: string;
  };
  stockDifference: number;
  totalUnits: number;
}

export interface ISupplierDataProps {
  supplier: {
    id: string;
    displayName: string;
    email: string;
  };
  venue: ISelectedVenueProps;
  user: ICurrentUserProps;
  ingredients: IIngredientProps[];
}

export const IngredientList: FC<IIngredientListProps> = ({ stocktake }) => {
  const { appWidth, user, selectedVenueObject } = useContext(LayoutContext);
  const history = useHistory();
  const isLoading = stocktake.loading || !stocktake.data;
  const subHeading =
    'Each inventory section will contain a list of ingredients & details associated';
  const suppliersData =
    stocktake.data?.inventoryStocktake.stocktake?.stocktakeSections?.reduce(
      (suppliers, stocktakeSection) => {
        stocktakeSection.stocktakeIngredients.forEach((s) => {
          const ingredientProduct = s.ingredient.ingredientProducts.find(
            (ip) => ip.venueId === selectedVenueObject?.id
          );
          if (!ingredientProduct) {
            return;
          }
          const required = ingredientProduct.required.find((r) => r.active);
          const supplier = {
            id: ingredientProduct.supplier?.id,
            displayName: ingredientProduct.supplier?.displayName,
            email: ingredientProduct.supplier?.email,
          };
          const total = s.quantity * ingredientProduct.size!.unitAmount!;

          const stockDifference = required!.quantity - total;

          // How many more units do we need to achieve the required quantity

          // How many units we currently have
          const totalCurrentUnits = roundToWholeNumber(
            total / ingredientProduct.size!.unitAmount!,
            'down'
          );

          // How many units we currently need
          // requiredQuantity

          const totalRequiredUnits = roundToWholeNumber(
            required!.quantity / ingredientProduct.size!.unitAmount!,
            'up'
          );

          const remainingUnits = totalRequiredUnits - totalCurrentUnits;

          const ingredient = {
            id: s.ingredient.id,
            ingredientProductId: ingredientProduct.id,
            displayName: s.ingredient.displayName,
            quantity: total,
            required,
            size: ingredientProduct.size,
            stockDifference,
            totalUnits: isNaN(remainingUnits) ? 0 : remainingUnits,
          };

          const supplierData = {
            supplier,
            venue: selectedVenueObject,
            user,
          };

          if (supplier.id) {
            if (suppliers[supplier.id]) {
              return Object.assign(suppliers, {
                [supplier.id]: {
                  ...supplierData,
                  ingredients: [
                    ...suppliers[supplier.id].ingredients,
                    ingredient,
                  ],
                },
              });
            } else {
              return Object.assign(suppliers, {
                [supplier.id]: { ...supplierData, ingredients: [ingredient] },
              });
            }
          }
        });
        return suppliers;
      },
      {} as Record<string, ISupplierDataProps>
    ) ?? {};
  const stocktakeSectionsLength = Object.keys(suppliersData).length;

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow $loading={isLoading}>
          <Col>
            <Label>Ingredients To Order</Label>
          </Col>
          <Col>
            <Label>Total Units</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow $loading={isLoading}>
          <Col>
            <Label>Ingredients To Order</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Purchase Cost</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow $loading={isLoading}>
          <Col>
            <Label>Ingredients To Order</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow $loading={isLoading}>
          <Col>
            <Label>Ingredients To Order</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total Items</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow $loading={isLoading}>
          <Col />
          <Col>
            <Label>Ingredients To Order</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total Items</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const noData = (
    <Card className="noDataCard">
      <Header
        icon={<RecipeBook size="small" />}
        heading={'Add Stocktake to see Shopping List'}
        subHeading="Add a stocktake to see your Shopping List on this page grouped by Supplier"
        button={
          <Button onClick={() => history.push('/stocktakes')} color="primary">
            Add Stocktake
          </Button>
        }
      />
    </Card>
  );

  return (
    <Container>
      {!isLoading && isEmpty(suppliersData) && noData}
      {isLoading && (
        <Card>
          <Header
            icon={<RecipeBook size="small" />}
            heading={'Shopping List'}
            subHeading={subHeading}
          />
          <Content fullWidth>
            <Table>
              <DotsLoading
                isLoading={true}
                size="large"
                lineHeight={10}
                color="default"
              />
              {getTableHeader()}
            </Table>
          </Content>
        </Card>
      )}

      {Object.keys(suppliersData)
        ?.sort((a, b) => {
          var nameA = suppliersData[a].supplier.displayName.toLowerCase(),
            nameB = suppliersData[b].supplier.displayName.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
        .map((s, idx) => {
          const isLastSection = stocktakeSectionsLength === idx + 1;
          return (
            <SupplierSection
              key={suppliersData[s].supplier.id}
              supplierData={suppliersData[s]}
              isLastSection={isLastSection}
            />
          );
        })}
    </Container>
  );
};

export default memo(IngredientList);
