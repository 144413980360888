import React, { useContext, useEffect, useState, FC } from 'react';
import { Button } from '../../../../shared/button';
import { Share, Trolley } from './../../../../shared/icons';
import { Label } from '../../../../shared/typefaces/Typefaces.styles';
import { Header, Content } from '../../../../shared/card';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import SupplierItem from './supplierItem/SupplierItem';

import { Table, Col, HeaderRow } from './Suppliers.styles';
import { LayoutColumn } from '../../../../shared/layout';
import { EndCard } from './Suppliers.styles';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from '../../../../../utils/helper';
import { ISuppliersTypes } from './suppliers.types';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

export const Suppliers: FC<ISuppliersTypes> = ({
  ingredient,
  setIngredientData,
}) => {
  const { appWidth, account, user, dispatch } = useContext(LayoutContext);
  const [selectedBrandSuppliers, setSelectedBrandSuppliers] = useState([]);
  const [prevSelectedBrand, setPrevSelectedBrand] = useState('');

  const { errors, watch } = useFormContext();

  useEffect(() => {
    const selectedBrand = watch('brand');
    if (selectedBrand && selectedBrand !== prevSelectedBrand) {
      const brandSuppliers = ingredient?.data?.ingredient.ingredient?.ingredientProducts.filter(
        (product) => {
          return product.brandId === selectedBrand;
        }
      );
      const suppliers = [];
      if (brandSuppliers) {
        for (const product of brandSuppliers) {
          if (product.supplierId && !suppliers[product.supplierId]) {
            suppliers[product.supplierId] = { ...product, suppliers: [] };
          }
        }
      }
      if (!isEmpty(suppliers)) {
        setSelectedBrandSuppliers(suppliers);
      }
      setPrevSelectedBrand(selectedBrand);
    }
  }, [ingredient, selectedBrandSuppliers, prevSelectedBrand, watch]);

  const handleAddSupplier = () => {
    setIngredientData();
    dispatch({ type: 'SELECT_SUPPLIER', payload: '' });
    dispatch({ type: 'SELECT_BRAND', payload: '' });
    dispatch({ type: 'SET_SUPPLIER_USER_EVENT', payload: 'add' });
    dispatch({ type: 'TOP_SLIDER_PAGE', payload: 'supplier' });
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveSupplier,
      });
    }
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow>
          <Col>
            <Label>Supplier</Label>
          </Col>
          <Col>
            <LayoutColumn>
              <Label>Avg Cost:</Label>
              <Label color="grey">100 Grams</Label>
            </LayoutColumn>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow>
          <Col>
            <Label>Supplier</Label>
          </Col>
          <Col>
            <LayoutColumn>
              <Label>Avg Cost:</Label>
              <Label color="grey">100 Grams</Label>
            </LayoutColumn>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow>
          <Col>
            <Label>Supplier</Label>
          </Col>
          <Col>
            <LayoutColumn>
              <Label>Avg Cost:</Label>
              <Label color="grey">100 Grams</Label>
            </LayoutColumn>
          </Col>
        </HeaderRow>
      );
    }
  };

  if (ingredient?.loading || !ingredient?.data?.ingredient?.ingredient) {
    return null;
  }

  const ingredientProductsMap: Record<string, any> = {};

  if (!isEmpty(ingredient!.data!.ingredient!.ingredient)) {
    for (const product of ingredient!.data!.ingredient!.ingredient!
      .ingredientProducts) {
      const supplierId = product.supplierId;

      if (supplierId) {
        const existingSupplier = ingredientProductsMap[supplierId];
        if (existingSupplier && !isEmpty(product.size)) {
          ingredientProductsMap[supplierId] = {
            ...existingSupplier,
            size: {
              productCost:
                (ingredientProductsMap[supplierId].size.productCost *
                  (ingredientProductsMap[supplierId].size.count +
                    (product.size!.productCost / product.size!.productGrams) *
                      100)) /
                (ingredientProductsMap[supplierId].size.count + 1),
              count: ingredientProductsMap[supplierId].size.count + 1,
            },
          };
        } else {
          ingredientProductsMap[supplierId] = {
            supplierId: product.supplierId,
            brandId: product.brandId,
            supplier: product.supplier,
            size: product.size
              ? {
                  productCost:
                    (product.size!.productCost / product.size!.productGrams) *
                    100,
                  productGrams: 100,
                  count: 1,
                }
              : undefined,
          };
        }
      }
    }
  }

  return (
    <EndCard>
      <Header
        icon={<Trolley size="small" />}
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? `Add ${ingredient?.data?.ingredient?.ingredient?.displayName}  Supplier`
            : `${ingredient?.data?.ingredient?.ingredient?.displayName} Suppliers`
        }
        subHeading="Compare average prices chefs have previously paid for suppliers"
        errorText={errors.supplier && errors.supplier.message}
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={handleAddSupplier}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button color="primary" onClick={handleAddSupplier}>
              Add Supplier
            </Button>
          )
        }
      />
      <Content fullWidth>
        <Table>
          {!isEmpty(ingredientProductsMap) && getTableHeader()}
          {Object.values(ingredientProductsMap)?.map((product, idx) => {
            return (
              <SupplierItem
                col={product}
                key={idx}
                selectedBrandSuppliers={Object.keys(selectedBrandSuppliers)}
                setIngredientData={setIngredientData}
              />
            );
          })}
        </Table>
      </Content>
    </EndCard>
  );
};

export default Suppliers;
