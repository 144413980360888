import React, { FC } from 'react';
import StripeWrapper from '../../shared/formElements/stripeWrapper';
import { EditBillingForm } from './EditBillingForm';

interface IEditBillingProps {}

export const EditBilling: FC<IEditBillingProps> = () => {
  return (
    <StripeWrapper>
      <EditBillingForm />
    </StripeWrapper>
  );
};
