import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const PieChart: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled size={size} viewBox="0 0 512 512" color={color} faded={faded}>
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2714 5036 c-17 -8 -34 -21 -38 -31 -3 -9 -6 -529 -6 -1156 l0 -1140
25 -24 c13 -14 29 -25 35 -25 6 0 403 63 883 141 479 77 982 158 1117 180 203
33 248 43 267 61 30 28 29 62 -3 205 -151 677 -606 1253 -1229 1558 -270 133
-555 213 -815 230 -47 3 -112 7 -145 10 -38 3 -72 -1 -91 -9z m293 -166 c384
-51 726 -188 1022 -408 413 -308 705 -763 815 -1273 l6 -26 -168 -27 c-92 -15
-541 -88 -998 -162 -458 -74 -837 -134 -843 -134 -8 0 -11 279 -11 1020 l0
1020 49 0 c27 0 85 -5 128 -10z"
        />
        <path
          d="M2027 4665 c-512 -62 -987 -294 -1352 -660 -191 -190 -330 -388 -449
-635 -141 -294 -209 -573 -223 -910 -24 -626 202 -1214 642 -1669 245 -253
589 -474 912 -585 395 -136 817 -165 1213 -85 268 55 550 172 790 329 93 62
117 92 104 134 -4 11 -294 427 -645 923 l-639 901 0 1115 0 1116 -26 20 c-23
18 -40 21 -123 20 -53 -1 -145 -7 -204 -14z m193 -1221 c0 -797 3 -1081 12
-1097 9 -17 577 -822 1196 -1696 l46 -64 -30 -24 c-41 -33 -312 -167 -414
-204 -185 -67 -376 -105 -586 -118 -1006 -62 -1939 593 -2204 1545 -155 561
-78 1178 208 1664 282 480 723 827 1250 984 146 44 356 81 480 85 l42 1 0
-1076z"
        />
        <path
          d="M3889 2610 c-604 -98 -1106 -183 -1118 -189 -23 -13 -36 -49 -29 -84
6 -27 1268 -1811 1309 -1849 16 -16 40 -28 54 -28 54 0 331 243 483 425 155
184 302 439 387 670 46 124 98 327 120 466 32 211 29 568 -6 701 -11 42 -42
68 -79 67 -14 0 -518 -81 -1121 -179z m1062 -61 c11 -104 10 -289 -1 -400 -47
-459 -233 -883 -542 -1230 -90 -100 -271 -263 -287 -257 -11 3 -1164 1629
-1159 1633 2 2 228 40 503 84 275 45 716 117 980 160 264 43 485 78 490 77 6
-2 13 -31 16 -67z"
        />
      </g>
    </IconStyled>
  );
};

export default PieChart;
