import React, { FC, useContext, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import { theme } from '../../../styles/theme';
import { Button } from '../../shared/button';
import { LayoutPage } from '../../shared/layout';
import { DotsLoading } from '../../shared/loading';
import { Container } from './EditStocktakeSection.styles';
import { useStocktake } from '../../../utils/customHooks/useSocktake';
import { Card, Content } from '../../shared/card';
import { Header } from './sections';
import { FormProvider, useForm } from 'react-hook-form';
import { STOCKTAKE_SECTION_FORM_SCHEMA } from './editStocktakeSection.types';
import Items from './sections/items';
import { getInitialStocktakeSectionFormItems } from '../../../utils/helper/getInitialStocktakeSectionFormItems';
import { StocktakeSectionFormState } from '../../shared/stocktake/stocktake.types';
import {
  StocktakeDocument,
  useCreateStocktakeSectionMutation,
  useDeleteStocktakeSectionMutation,
  useUpdateStocktakeSectionMutation,
} from '../../../generated/graphql';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

interface IEditStocktakeSectionProps {}

export interface IInfoModalProps {
  show: boolean;
  heading: string;
  content: string;
}

interface IEditStocktakeSectionState {
  showUniqueError: boolean;
}

export const EditStocktakeSection: FC<IEditStocktakeSectionProps> = () => {
  const {
    appWidth,
    selectedStocktakeSection,
    selectedVenueObject,
    dispatch,
    toolTip: { show },
  } = useContext(LayoutContext);
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const [
    updateStocktakeSectionMutation,
    updateStocktakeSection,
  ] = useUpdateStocktakeSectionMutation();
  const [
    createStocktakeSectionMutation,
    createStocktakeSection,
  ] = useCreateStocktakeSectionMutation();
  const [
    deleteStocktakeSectionMutation,
    deleteStocktakeSection,
  ] = useDeleteStocktakeSectionMutation();
  const { stocktake } = useStocktake();
  const stocktakeData = stocktake?.data?.stocktake.stocktake;
  const stocktakeSectionData = stocktakeData?.stocktakeSections.find(
    (s) => s.section.id === selectedStocktakeSection
  );
  const addSection = !stocktakeSectionData;
  const heading = `${addSection ? 'New' : 'Update'} Stocktake Section`;

  const [state, setState] = useState<IEditStocktakeSectionState>({
    showUniqueError: false,
  });

  const initialItems = getInitialStocktakeSectionFormItems(
    stocktakeSectionData
  );

  const methods = useForm<StocktakeSectionFormState, {}>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(STOCKTAKE_SECTION_FORM_SCHEMA),
    defaultValues: {
      displayName: stocktakeSectionData?.section.displayName ?? '',
      items: initialItems,
    },
  });

  const closeEditStocktakeSectionSlider = () => {
    dispatch({ type: 'HIDE_TOOL_TIP' });
    dispatch({ type: 'TOGGLE_SLIDER' });
    dispatch({ type: 'SET_SELECTED_STOCKTAKE_SECTION', payload: null });
  };

  const handleDeleteStocktakeSection = async () => {
    try {
      if (stocktakeSectionData?.id) {
        const stocktakeIngredientIds = stocktakeSectionData.stocktakeIngredients.map(
          (i) => i.id
        );

        const response = await deleteStocktakeSectionMutation({
          variables: {
            input: {
              stocktakeId: stocktakeData?.id!,
              stocktakeSectionId: stocktakeSectionData?.id!,
              updatedStocktakeTotalRequiredItems:
                stocktakeData?.totalRequiredItems! -
                stocktakeSectionData?.section.totalRequiredItems!,
              venueId: selectedVenueObject?.id!,
              stocktakeIngredientIds,
              totalActiveSeconds: getTotalActiveSeconds(),
            },
          },
          refetchQueries: [
            {
              query: StocktakeDocument,
              variables: {
                input: {
                  stocktakeId: stocktakeData!.id,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });
        if (response.data?.deleteStocktakeSection.successful) {
          dispatch({ type: 'TOGGLE_SLIDER' });
          dispatch({ type: 'SET_SELECTED_STOCKTAKE_SECTION', payload: null });
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = async (formData: StocktakeSectionFormState) => {
    const ingredients = formData.items.map((i) => {
      return {
        stocktakeItemId: i.stocktakeSectionItemId,
        ingredientId: i.ingredient!.value,
        quantity: Number(i.quantity),
        required: Number(i.required),
        unitAmount: Number(i.unit),
        unitType: i.unitType!,
        order: i.order,
      };
    });

    const inputData = {
      stocktakeId: stocktakeData?.id!,
      venueId: selectedVenueObject?.id!,
      section: {
        sectionId: selectedStocktakeSection,
        displayName: formData.displayName,
        ingredients,
      },
      totalActiveSeconds: getTotalActiveSeconds(),
    };

    try {
      if (stocktakeSectionData?.id) {
        const response = await updateStocktakeSectionMutation({
          variables: {
            input: {
              ...inputData,
              stocktakeSectionId: stocktakeSectionData?.id!,
            },
          },
          refetchQueries: [
            {
              query: StocktakeDocument,
              variables: {
                input: {
                  stocktakeId: stocktakeData!.id,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        if (response.data?.updateStocktakeSection.successful) {
          closeEditStocktakeSectionSlider();
        } else {
          if (
            response.data?.updateStocktakeSection.error?.includes(
              'Already Exists'
            )
          ) {
            setState({ ...state, showUniqueError: true });
          }
        }
      } else {
        const response = await createStocktakeSectionMutation({
          variables: {
            input: inputData,
          },
          refetchQueries: [
            {
              query: StocktakeDocument,
              variables: {
                input: {
                  stocktakeId: stocktakeData!.id,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        if (response.data?.createStocktakeSection.successful) {
          closeEditStocktakeSectionSlider();
        } else {
          if (
            response.data?.createStocktakeSection.error?.includes(
              'Already Exists'
            )
          ) {
            setState({ ...state, showUniqueError: true });
          }
        }
      }
    } catch (err: any) {
      console.log('err', err);
    }
  };

  //  refetchQueries:
  const isLoading =
    updateStocktakeSection.loading || createStocktakeSection.loading;

  return (
    <LayoutPage
      align={show ? 'left' : 'center'}
      backButton
      backButtonCTA={closeEditStocktakeSectionSlider}
      withLine
      heading={heading}
      subHeading={
        'Easily record your stocktake by sections, known data is prefilled automatically'
      }
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button
                color="default"
                asCircle
                inversed
                onClick={closeEditStocktakeSectionSlider}
              >
                x
              </Button>,
              <Button
                small
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) => (loading ? 'Saving' : 'Save')}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
          : [
              <Button
                color="default"
                inversed
                onClick={closeEditStocktakeSectionSlider}
              >
                Cancel
              </Button>,
              <Button
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) => (loading ? 'Saving' : 'Save Section')}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <Container>
        <Card withShadow ref={pageWidthRef}>
          <Content fullWidth>
            <FormProvider {...methods}>
              <form
                autoComplete="off"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <Header
                  showUniqueError={state.showUniqueError}
                  onSubmit={onSubmit}
                />
                <Items
                  initialItems={initialItems}
                  pageWidthRef={pageWidthRef}
                  deleteStocktakeSection={deleteStocktakeSection}
                  handleDeleteStocktakeSection={handleDeleteStocktakeSection}
                />
              </form>
            </FormProvider>
          </Content>
        </Card>
      </Container>
    </LayoutPage>
  );
};
