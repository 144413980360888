import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

const info =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20px' height='20px' viewBox='0 0 60 60'  style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath style='opacity:0.984' fill='%2310b561' d='M 25.5,2.5 C 51.9379,2.43538 63.1046,15.4354 59,41.5C 49.913,59.3667 35.913,64.7001 17,57.5C 5.16667,61.6667 1.33333,57.8333 5.5,46C -1.67997,24.871 4.98669,10.371 25.5,2.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23f7fcf9' d='M 28.5,15.5 C 35.4883,14.6478 37.8216,17.4811 35.5,24C 26.8377,26.5009 24.5043,23.6675 28.5,15.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23fbfdfc' d='M 29.5,28.5 C 31.9363,28.317 34.103,28.9837 36,30.5C 36.6667,35.8333 36.6667,41.1667 36,46.5C 33,49.1667 30,49.1667 27,46.5C 26.3333,41.1667 26.3333,35.8333 27,30.5C 27.9947,29.9341 28.828,29.2674 29.5,28.5 Z'/%3E%3C/g%3E%3C/svg%3E";

interface IColProps {
  icon?: boolean;
}

interface IHeaderRowProps {
  $loading: boolean;
}

export const Container = styled.div``;

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li<IColProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};

  &:hover {
    .hasToolTipIcon {
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        background-color: ${({ theme, icon = true }) =>
          icon ? 'transparent' : theme.colors.secondary.default};
        background-image: ${({ theme, icon = true }) =>
          icon
            ? `url(${`"${info}"`})`
            : `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
        width: ${({ icon = true }) => (icon ? '20px' : '8px')};
        height: ${({ icon = true }) => (icon ? '20px' : '8px')};
        border-radius: ${({ icon = true }) => (icon ? '0' : '8px')};
        margin-left: -4px;
        left: 50%;
        top: ${({ icon = true }) => (icon ? '-24px' : '-14px')};
      }
    }
  }

  &:first-child {
    justify-content: flex-start;
    margin-left: 15px;
    padding-left: 0;
  }

  &:nth-child(2) {
    justify-content: flex-start;
    padding-left: 25px;
  }

  @media ${device.mobileM} {
    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 25px;
    }
    &:nth-child(3) {
      justify-content: flex-start;
      padding-left: 25px;
    }
  }

  @media ${device.tablet} {
    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 25px;
    }
    &:nth-child(4) {
      justify-content: flex-start;
      padding-left: 25px;
    }
  }

  @media ${device.laptop} {
    &:first-child {
      justify-content: center;
      border: 0;
      margin: 0;
    }
    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 0;
    }
    &:nth-child(5) {
      justify-content: flex-start;
      padding-left: 25px;
    }
  }
`;

export const Row = styled.ul`
  min-height: 55px;
  display: grid;
  grid-template-columns: auto 60px 50px;
  cursor: pointer;

  .layoutColumn {
    padding: 0;
  }

  &:first-child {
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 40px;
    ${Col} {
      border: 0;
    }
    cursor: default;
  }

  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundFaded};
    &:last-child {
      border-radius: 0 0 15px 15px;
    }
  }

  @media ${device.mobileM} {
    grid-template-columns: auto 60px 105px 50px;
  }
  @media ${device.mobileL} {
    grid-template-columns: auto 60px 172px 50px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto 160px 60px 172px 50px;
  }
  @media ${device.laptop} {
    grid-template-columns: 70px auto 180px 60px 172px 50px;
  }
`;

export const HeaderRow = styled.ul<IHeaderRowProps>`
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-columns: auto 110px;
  padding-right: 15px;
  border-radius: ${({ $loading }) => ($loading ? '0 0 15px 15px' : '0')};

  &:first-child {
    ${Col} {
      border: 0;
      &:last-child {
        padding-right: 15px;
      }
    }
  }

  ${Col} {
    border: ${({ $loading }) => $loading && '0'};
  }

  @media ${device.mobileM} {
    grid-template-columns: auto 60px 155px;
    &:nth-child(2) {
      ${Col} {
        border: 0;
        &:last-child {
          padding-right: 15px;
        }
      }
    }
  }
  @media ${device.mobileL} {
    grid-template-columns: auto 60px 222px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto 160px 60px 222px;
  }
  @media ${device.laptop} {
    grid-template-columns: 70px auto 180px 60px 222px;
  }
`;

export const Icon = styled.div`
  height: 50px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;
