import styled from 'styled-components/macro';
import { Button } from '../../../../shared/button';

export const Container = styled.div`
  .card {
    border-width: 2px;
  }

  .heading {
    color: ${({ theme }) => theme.colors.error.default};
  }
`;

export const CTAButton = styled(Button)`
  min-width: 182px;
`;
