import React, { FC } from 'react';
import { Valid } from '../../icons';
import { Container, Content, Icon } from './Alert.styles';

interface IAlertProps {
  icon?: 'warning' | 'valid';
  color: 'secondary' | 'valid';
  content: string;
}

export const Alert: FC<IAlertProps> = ({ icon, content, color }) => {
  let showIcon: React.ReactNode | undefined;

  switch (icon) {
    case 'valid':
      showIcon = <Valid color="secondary" size="small" />;
      break;

    default:
      break;
  }

  return (
    <Container>
      {showIcon && <Icon>{showIcon}</Icon>}
      <Content>{content}</Content>
    </Container>
  );
};
