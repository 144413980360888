import React from 'react';
import { LayoutPage } from '../../shared/layout';
import { Button } from '../../shared/button';
import { Header, Ingredients, Recipes, Users } from './sections';
import {
  useBrandsQuery,
  useIngredientsQuery,
  useSuppliersQuery,
  useUsersQuery,
  useVenuesQuery,
} from '../../../generated/graphql';
import { getUserStats } from '../../../utils/helper';

const Dashboard = () => {
  const users = useUsersQuery();
  const venues = useVenuesQuery();
  const brands = useBrandsQuery();
  const suppliers = useSuppliersQuery();
  const ingredients = useIngredientsQuery();
  const stats = getUserStats({ users, venues, brands, suppliers, ingredients });

  console.log('stats', stats);

  return (
    <LayoutPage
      align="center"
      withLine
      heading="Dashboard"
      subHeading="Overview on business progress & activity"
      actionArray={[
        <Button color="primary" onClick={null}>
          Users
        </Button>,
      ]}
    >
      <Header stats={stats} />
      <Users stats={stats} />
      <Recipes stats={stats} />
      <Ingredients stats={stats} />
    </LayoutPage>
  );
};

export default Dashboard;
