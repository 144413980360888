import styled from 'styled-components/macro';
import { IButtonOnclickProps, IButtonProps } from './button.types';

export const ButtonStyled = styled.button<IButtonOnclickProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  height: ${({ theme, size }) =>
    size === 'small'
      ? theme.layout.smallButtonHeight
      : size === 'large'
      ? theme.layout.largeButtonHeight
      : theme.layout.buttonHeight};
  min-width: ${({ asCircle, size, small, theme }) =>
    asCircle
      ? size === 'large'
        ? theme.layout.largeButtonHeight
        : theme.layout.buttonHeight
      : small
      ? theme.layout.smallButtonWidth
      : theme.layout.buttonWidth};
  padding: 0 20px;
  ${({ asCircle, theme }) =>
    asCircle && `width: ${theme.layout.buttonHeight}; padding: 0`};
  border-radius: ${({ withCorners, size }) =>
    withCorners ? '0' : size === 'large' ? '30px' : '20px'};

  border: ${({ inversed }) => (inversed ? '1px solid' : 'none')};

  border-color: ${({ color, faded, inversed, theme, disabled }) =>
    color
      ? inversed
        ? faded || disabled
          ? theme.colors[color].faded
          : theme.colors[color].default
        : 'transparent'
      : disabled
      ? theme.colors.default.faded
      : theme.colors.default.default};
  background-color: ${({ color, faded, inversed, theme, disabled }) =>
    color
      ? inversed
        ? theme.colors.white.default
        : faded || disabled
        ? theme.colors[color].faded
        : theme.colors[color].default
      : disabled
      ? theme.colors.default.faded
      : theme.colors.default.default};
  background-image: ${({ color, inversed, theme, faded, disabled }) =>
    inversed
      ? 'none'
      : color
      ? faded || disabled
        ? theme.colors[color].faded
        : `linear-gradient(to right top, ${theme.colors[color].dark}, ${theme.colors[color].default}, ${theme.colors[color].faded})`
      : 'none'};

  color: ${({ color, faded, inversed, theme, disabled }) =>
    color
      ? inversed
        ? faded || disabled
          ? theme.colors[color].faded
          : color === 'default'
          ? theme.colors.text.grey
          : theme.colors[color].default
        : theme.colors.white.default
      : theme.colors.white.default};
  font-size: ${({ theme }) => theme.fontSize.button};
  outline: 0;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    border: ${({ inversed }) => (inversed ? '2px solid' : 'none')};
    border-color: ${({ color, faded, inversed, theme, disabled }) =>
      color
        ? inversed
          ? faded || disabled
            ? theme.colors[color].faded
            : theme.colors[color].faded
          : 'transparent'
        : disabled
        ? theme.colors.default.faded
        : theme.colors.default.faded};
    background-color: ${({ color, faded, inversed, theme, disabled }) =>
      color
        ? inversed
          ? theme.colors.white.default
          : faded || disabled
          ? theme.colors[color].faded
          : theme.colors[color].faded
        : disabled
        ? theme.colors.default.faded
        : theme.colors.default.faded};
    background-image: ${({ color, inversed, theme, faded, disabled }) =>
      inversed
        ? 'none'
        : color
        ? disabled || faded
          ? theme.colors[color].faded
          : `linear-gradient(to left bottom, ${theme.colors[color].dark}, ${theme.colors[color].default}, ${theme.colors[color].faded})`
        : 'none'};
    color: ${({ color, faded, inversed, theme, disabled }) =>
      color
        ? inversed
          ? faded || disabled
            ? theme.colors[color].faded
            : color === 'default'
            ? theme.colors.text.faded
            : theme.colors[color].faded
          : theme.colors.white.default
        : theme.colors.white.default};
  }
`;

export const AnchorButton = styled.a<IButtonProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  height: ${({ theme, size }) =>
    size === 'large'
      ? theme.layout.largeButtonHeight
      : theme.layout.buttonHeight};
  min-width: ${({ asCircle, size, small, theme }) =>
    asCircle
      ? size === 'large'
        ? theme.layout.largeButtonHeight
        : theme.layout.buttonHeight
      : small
      ? theme.layout.smallButtonWidth
      : theme.layout.buttonWidth};
  padding: 0 20px;
  ${({ asCircle, theme }) =>
    asCircle && `width: ${theme.layout.buttonHeight}; padding: 0`};
  border-radius: ${({ withCorners, size }) =>
    withCorners ? '0' : size === 'large' ? '30px' : '20px'};

  border: ${({ inversed }) => (inversed ? '1px solid' : 'none')};

  border-color: ${({ color, faded, inversed, theme, disabled }) =>
    color
      ? inversed
        ? faded || disabled
          ? theme.colors[color].faded
          : theme.colors[color].default
        : 'transparent'
      : disabled
      ? theme.colors.default.faded
      : theme.colors.default.default};
  background-color: ${({ color, faded, inversed, theme, disabled }) =>
    color
      ? inversed
        ? theme.colors.white.default
        : faded || disabled
        ? theme.colors[color].faded
        : theme.colors[color].default
      : disabled
      ? theme.colors.default.faded
      : theme.colors.default.default};
  background-image: ${({ color, inversed, theme }) =>
    inversed
      ? 'none'
      : color
      ? `linear-gradient(to right top, ${theme.colors[color].dark}, ${theme.colors[color].default}, ${theme.colors[color].faded})`
      : 'none'};

  color: ${({ color, faded, inversed, theme, disabled }) =>
    color
      ? inversed
        ? faded || disabled
          ? theme.colors[color].faded
          : color === 'default'
          ? theme.colors.text.grey
          : theme.colors[color].default
        : theme.colors.white.default
      : theme.colors.white.default};
  font-size: ${({ theme }) => theme.fontSize.button};
  outline: 0;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    border: ${({ inversed }) => (inversed ? '2px solid' : 'none')};
    border-color: ${({ color, faded, inversed, theme, disabled }) =>
      color
        ? inversed
          ? faded || disabled
            ? theme.colors[color].faded
            : theme.colors[color].faded
          : 'transparent'
        : disabled
        ? theme.colors.default.faded
        : theme.colors.default.faded};
    background-color: ${({ color, faded, inversed, theme, disabled }) =>
      color
        ? inversed
          ? theme.colors.white.default
          : faded || disabled
          ? theme.colors[color].faded
          : theme.colors[color].faded
        : disabled
        ? theme.colors.default.faded
        : theme.colors.default.faded};
    background-image: ${({ color, inversed, theme }) =>
      inversed
        ? 'none'
        : color
        ? `linear-gradient(to left bottom, ${theme.colors[color].dark}, ${theme.colors[color].default}, ${theme.colors[color].faded})`
        : 'none'};
    color: ${({ color, faded, inversed, theme, disabled }) =>
      color
        ? inversed
          ? faded || disabled
            ? theme.colors[color].faded
            : color === 'default'
            ? theme.colors.text.faded
            : theme.colors[color].faded
          : theme.colors.white.default
        : theme.colors.white.default};
  }
`;
