import React from 'react';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import { isEmpty, toTitleCase } from '../../../../utils/helper';
import {
  Container,
  Highlight,
  StyledAutocomplete,
  StyledSelectList,
  StyledSelectMenuItem,
} from './SelectList.styles';
import { IMiniSelectListProps, ISelectListOption } from './selectList.types';

const SelectList = ({
  name,
  className,
  label,
  options,
  errorText,
  defaultValue,
  autoFocus,
  type,
  isNumeric,
  control,
  autoComplete,
  variant,
  freeSolo,
  noBorder,
  disabled,
  helperText,
  handleChange,
  placeholder,
  preventAddOption,
  addValue,
  onAddNew,
  renderAddValueOptionLabel,
}: IMiniSelectListProps) => {
  const filter = createFilterOptions<ISelectListOption>();

  const handleFilter = (options, params) => {
    const filtered = filter(options, params);

    if (
      (params.inputValue !== '' &&
        !preventAddOption &&
        filtered.length !== 1) ||
      (!isEmpty(filtered) &&
        filtered[0].label.toLowerCase().replace(/\D/g, '') !==
          params.inputValue.toLowerCase())
    ) {
      const value =
        isNumeric || type === 'number'
          ? // Doesn't have decimals allowed because when it's used we want to block decimal places from being add
            params.inputValue.replace(/\D/g, '')
          : params.inputValue;

      if (!isEmpty(value) && Number(value) !== 0) {
        filtered.push({
          inputValue: value,
          value,
          label: `${toTitleCase(value)}${addValue || ''}`,
        });
      }
    }

    return filtered;
  };

  const handleChangeOuter = (onChange) => (_, value, reason) => {
    // Blur events don't count
    if (reason === 'blur') {
      return;
    }

    handleChange && handleChange(value, name);
    onChange(value);

    // onAddNew event
    if (
      onAddNew &&
      value &&
      value.inputValue &&
      value.label &&
      value.label.toLowerCase() === value.inputValue.toLowerCase()
    ) {
      onAddNew(value.inputValue);
    }
  };

  const handleSelectOnChange = (onChange) => (e) => {
    onChange && onChange(e);
    if (handleChange) {
      handleChange(e);
    }
  };

  const renderAddValueOptionLabelFinal =
    renderAddValueOptionLabel ?? ((value: string) => `Add ${value}`);

  return (
    <Container className={`${!autoComplete && 'selectList'} ${className}`}>
      {autoComplete ? (
        <Controller
          control={control}
          name={name}
          style={{ width: '100%' }}
          defaultValue={defaultValue}
          render={(props) => (
            <Autocomplete
              {...props}
              onChange={handleChangeOuter(props.onChange)}
              freeSolo={freeSolo}
              options={options}
              autoHighlight
              disabled={disabled}
              selectOnFocus
              handleHomeEndKeys
              renderOption={(option: any) => {
                return option.highlight ? (
                  <>
                    {option.label}
                    <Highlight>- {option.highlight}</Highlight>
                  </>
                ) : (
                  <>
                    {option.inputValue
                      ? renderAddValueOptionLabelFinal(option.label)
                      : option.label}
                  </>
                );
              }}
              getOptionLabel={(option: ISelectListOption) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.label;
              }}
              getOptionSelected={(option: any, value: any) => {
                return value && option && option.value === value.value;
              }}
              filterOptions={handleFilter}
              renderInput={(params) => (
                <StyledAutocomplete
                  {...params}
                  autoFocus={autoFocus}
                  label={label}
                  name={name}
                  variant="outlined"
                  error={!!errorText}
                  helperText={errorText || helperText}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                />
              )}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name={name}
          style={{ width: '100%' }}
          defaultValue={defaultValue}
          render={(props) => {
            return (
              <StyledSelectList
                {...props}
                name={name}
                onChange={handleSelectOnChange(props.onChange)}
                variant={variant}
                select
                disabled={disabled}
                label={label}
                error={!!errorText}
                helperText={errorText || helperText}
                SelectProps={{
                  className: noBorder ? 'noBorder' : '',
                  placeholder: placeholder || 'select',
                }}
              >
                {options.map((option) => (
                  <StyledSelectMenuItem key={option.value} value={option.value}>
                    {option.label}
                  </StyledSelectMenuItem>
                ))}
              </StyledSelectList>
            );
          }}
        />
      )}
    </Container>
  );
};

SelectList.defaultProps = {
  addValue: null,
  autoComplete: false,
  autoFocus: false,
  preventAddOption: false,
  isNumeric: false,
  freeSolo: false,
  variant: 'outlined',
  errorText: '',
  helperText: '',
  label: '',
  onAddNew: null,
  type: '',
  placeholder: '',
};

export default SelectList;
