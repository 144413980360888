import styled from 'styled-components/macro';
import { ButtonStyled } from '../button/Button.styles';
import { Label } from '../typefaces/Typefaces.styles';

interface ILayoutGlobalProps {
  noScroll?: boolean;
}

interface IButtonGroupProps {
  noMarginTop?: boolean;
  addMarginBottom?: boolean;
  align?: 'left' | 'right' | 'center' | 'spaceBetween';
}

interface ISideLabelProps {
  marginTop?: boolean;
}

export const LayoutGlobal = styled.div<ILayoutGlobalProps>`
  display: flex;
  top: 0;
  left: 0;
  height: ${({ noScroll }) => (noScroll ? `100vh` : `100%`)};
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: '16px';
  overflow: ${({ noScroll }) => (noScroll ? `hidden` : `visible`)};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul,
  ol {
    padding: 0;
  }

  html {
    height: 100%;
    overflow: auto;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd,
  article {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    overflow: auto;
  }

  /* Remove list styles on ul, ol elements */
  ul,
  ol {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not() {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  a {
    text-decoration: none;
  }

  .hasToolTip {
    cursor: pointer;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`;

export const LayoutGlobalStoryBook = styled(LayoutGlobal)`
  display: block;
`;

export const ButtonGroup = styled.section<IButtonGroupProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) =>
    align === 'left'
      ? 'flex-start'
      : align === 'right'
      ? 'flex-end'
      : align === 'spaceBetween'
      ? 'space-between'
      : 'center'};
  margin-top: ${({ noMarginTop }) => (noMarginTop ? 0 : '15px')};
  margin-bottom: ${({ addMarginBottom }) => (addMarginBottom ? '15px' : 0)};
  z-index: ${({ theme }) => theme.zIndex.default};

  ${ButtonStyled} {
    margin: 5px;
  }
`;
export const SideLabel = styled(Label)<ISideLabelProps>`
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? '25px' : 0)};
`;

export const NestedColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;
