import React, { useContext, useEffect, useRef, useState } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Container } from './Chatbox.styles';
import { LayoutContext } from '../../../contexts/layoutContext';

export const Chatbox = () => {
  const {
    user,
    selectedVenueObject,
    help: { showFBChatbox },
  } = useContext(LayoutContext);
  const tawkMessengerRef = useRef<any>(null);
  const [isTawkLoaded, setIsTawkLoaded] = useState(false);
  const propertyId = process.env.REACT_APP_TAWK_PROPERTY_ID;
  const widgetId = process.env.REACT_APP_TWAK_WIDGET_ID;

  useEffect(() => {
    if (
      tawkMessengerRef.current == null ||
      user == null ||
      !isTawkLoaded ||
      !propertyId ||
      !widgetId
    ) {
      return;
    }

    const userName = user.firstName
      ? `${user.firstName} ${user.lastName}`
      : '👋🏼 NEW USER';

    tawkMessengerRef.current.setAttributes(
      {
        reference: user?.email || 'newuser@reciperevenue.com',
        name: userName,
        email: user?.email || 'newuser@reciperevenue.com',
        phones: user?.phone || '',
        position: user?.position || '',
        venue: selectedVenueObject?.displayName || '',
        location: user.address || '',
      },
      function (error: unknown) {
        console.log('Chatbox Error:', error);
      }
    );
  }, [isTawkLoaded, user]);

  useEffect(() => {
    if (showFBChatbox) {
      tawkMessengerRef.current.maximize();
    }
  }, [showFBChatbox]);

  const onLoad = () => {
    setIsTawkLoaded(true);
  };

  if (!propertyId || !widgetId) {
    return null;
  }

  return (
    <Container>
      <TawkMessengerReact
        propertyId={propertyId}
        widgetId={widgetId}
        ref={tawkMessengerRef}
        onLoad={onLoad}
      />
    </Container>
  );
};
