import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import { useAuth } from '../../../utils/customHooks/useAuth';

const Login: FC<RouteComponentProps> = ({ history }) => {
  const [isShowingForgotPassword, setShowingForgotPassword] = useState(false);
  const { handleLogout } = useAuth();

  useEffect(() => {
    handleLogout({});
  }, []);

  return isShowingForgotPassword ? (
    <ForgotPasswordForm
      hideForgotPasswordForm={() => setShowingForgotPassword(false)}
    />
  ) : (
    <LoginForm
      showForgotPasswordForm={() => setShowingForgotPassword(true)}
      history={history}
    />
  );
};

export default Login;
