import { ISelectListOption } from '../../components/shared/formElements/selectList/selectList.types';
import { isEmpty } from './objects';

export const getCostPer100g = (
  cost?: number | string,
  grams?: number | string | ISelectListOption
) => {
  if (
    isEmpty(cost) ||
    isEmpty(grams) ||
    (typeof grams === 'object' && isEmpty(grams.value))
  ) {
    return undefined;
  }

  let result: number;

  if (typeof grams === 'object') {
    if (grams.inputValue) {
      result = (Number(cost)! / Number(grams!.inputValue)) * 100;
    } else {
      result = (Number(cost)! / Number(grams!.value)) * 100;
    }
  } else {
    result = (Number(cost)! / Number(grams)!) * 100;
  }

  return result;
};
