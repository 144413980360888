import React, { FC, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import { Container } from './RecipeProfitGraph.styles';
import { theme } from '../../../../../styles/theme';
import {
  RecipeQuery,
  RecipeQueryVariables,
  VenueQuery,
  VenueQueryVariables,
} from '../../../../../generated/graphql';
import { DotsLoading } from '../../../../shared/loading';
import {
  convertCostCleanly,
  convertTimeFromSecondsCleanly,
  getFiveGraphElements,
  isNegative,
} from '../../../../../utils/helper';
import * as ApolloClient from '@apollo/client';
import { Card, Content, Header } from '../../../../shared/card';
import { Apple } from '../../../../shared/icons';
import { Button } from '../../../../shared/button';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { IRecipeDataResult } from '../../../../../utils/clientServerShared/getRecipeData.types';

interface IRecipeProfitGraphProps {
  data?: ApolloClient.QueryResult<RecipeQuery, RecipeQueryVariables>;
  venue?: ApolloClient.QueryResult<VenueQuery, VenueQueryVariables>;
  recipeData: IRecipeDataResult;
}

// Custom tooltip component
const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: theme.colors.white.default,
          border: `1px solid ${theme.colors.default.default}`,
          padding: '10px',
        }}
      >
        <Span fontSize="small" fontWeight="medium">
          Total Profit Change:
        </Span>
        <br />
        <Span className="intro" fontWeight="medium">
          {convertCostCleanly(payload[0].value)}{' '}
          <Span className="label" color="faded">{`in ${label}`}</Span>
        </Span>
      </div>
    );
  }

  return null;
};

const formatYAxis = (tickItem) => {
  return `$${tickItem}`;
};

const RecipeProfitGraph: FC<IRecipeProfitGraphProps> = (props) => {
  const [showRecipeJourney, setShowRecipeJourney] = useState(true);
  const recipe = props.data?.data?.recipe.recipe;
  const weeksOpen = props.venue?.data?.venue?.userVenue?.venue.weeksOpen;

  const recipeProfitTrendNew =
    recipe?.recipeProfitTrend?.map((t) => {
      const { timeValue, timeText } = convertTimeFromSecondsCleanly(
        t.recipeActiveSeconds
      );

      // const totalYearlyProfitIncrease =
      //   t.recipeProfitIncreasePerServe * t.weeklySalesPerServe * t.serves;
      return {
        name: `${timeValue} ${timeText}`,
        totalProfit: t.recipeProfitIncreasePerServe,
        amt: t.recipeProfitIncreasePerServe,
      };
    }) || [];

  const graphData = getFiveGraphElements(recipeProfitTrendNew);

  const handleCtaButtonClick = () => {
    setShowRecipeJourney(!showRecipeJourney);
  };

  const ctaButton = (
    <Button
      color="secondary"
      inversed={showRecipeJourney}
      onClick={handleCtaButtonClick}
    >
      {showRecipeJourney ? 'Hide Journey' : 'See Journey'}
    </Button>
  );

  const currentProfit = props.recipeData.recipeProfit / props.recipeData.serves;

  const yearlyProfitIncrease =
    recipe?.recipeProfitIncreasePerServe! *
    recipe?.weeklySalesPerServe! *
    weeksOpen!;

  const increaseDescreased = isNegative(recipe?.recipeProfitIncreasePerServe!)
    ? 'decreased'
    : 'increased';

  const originalProfit =
    recipe?.originalRecipeProfit! === 0
      ? props.recipeData?.profitPerServe
      : recipe?.originalRecipeProfit! / recipe?.originalServes!;

  const subHeading = (
    <Span fontSize="small" color="faded">
      Recipe Profit Per Serve started at{' '}
      <Span fontSize="small" color="black">
        {convertCostCleanly(originalProfit)}
      </Span>
      , currently it's{' '}
      <Span fontSize="small" color="black">
        {convertCostCleanly(currentProfit)}
      </Span>
      , a total profit {increaseDescreased} of{' '}
      <Span fontSize="small" color="black">
        {convertCostCleanly(recipe?.recipeProfitIncreasePerServe)}.
      </Span>{' '}
      Based on your yearly sales you have {increaseDescreased} profitability by{' '}
      <Span fontSize="small" color="black">
        {convertCostCleanly(yearlyProfitIncrease)}
      </Span>
    </Span>
  );

  return (
    <Container>
      <Card>
        <Header
          icon={<Apple size="small" />}
          heading="Profit Changes Overtime"
          subHeading={subHeading}
          button={ctaButton}
          toolTip={{
            type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
            heading: 'Recipe Changes',
            content:
              'This graph highlights your recipe changes overtime to visualise key aspects that have achieved your recipe results',
          }}
          addPaddingBottom
        />
        <DotsLoading
          isLoading={props.data?.loading}
          size="large"
          lineHeight={10}
          color="default"
        />
        {showRecipeJourney && (
          <Content fullWidth className="content">
            <ResponsiveContainer width="100%" aspect={16 / 5}>
              <LineChart
                width={900}
                height={150}
                data={graphData}
                margin={{ top: 30, right: 55, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" dy={10} />
                <YAxis dataKey="totalProfit" tickFormatter={formatYAxis} />
                <CartesianGrid strokeDasharray="3 3" />
                <Line
                  type="monotone"
                  dataKey="totalProfit"
                  stroke={theme.colors.secondary.default}
                />
                {/* <Legend content={<CustomLegend />} /> */}
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
          </Content>
        )}
      </Card>
    </Container>
  );
};

export default RecipeProfitGraph;
