import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Chef: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512 512"
      color={color}
      faded={faded}
      preserveAspectRatio="xMidYMid meet"
      // style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M2400 5054 c-162 -20 -408 -74 -512 -114 -29 -11 -102 -34 -163 -51
-60 -17 -131 -43 -158 -57 -60 -32 -127 -103 -164 -173 -26 -50 -28 -63 -28
-164 0 -101 2 -115 29 -170 42 -85 125 -164 209 -200 l68 -29 -2 -355 c-3
-355 -3 -356 23 -461 52 -206 127 -366 251 -528 l78 -103 -35 -58 c-46 -75
-143 -181 -232 -251 l-71 -57 -119 -6 c-373 -20 -706 -182 -896 -437 -116
-155 -195 -385 -213 -620 -11 -150 38 -625 85 -828 29 -121 40 -139 103 -156
272 -77 922 -124 1847 -133 750 -7 1229 12 1658 67 153 20 371 63 403 81 53
28 139 598 139 920 0 208 -73 458 -183 627 -55 84 -191 220 -279 278 -171 114
-402 184 -655 200 l-108 6 -73 55 c-90 69 -182 168 -232 252 l-38 64 67 86
c118 151 184 278 249 481 l36 115 -1 382 -1 382 36 12 c93 29 204 129 247 222
21 45 25 67 25 157 0 95 -3 111 -28 165 -34 71 -106 146 -172 180 -26 13 -101
40 -166 60 -66 19 -170 51 -232 70 -196 60 -360 86 -572 90 -102 2 -201 1
-220 -1z m382 -174 c126 -12 289 -48 413 -90 50 -17 139 -44 199 -60 128 -34
168 -61 209 -140 77 -151 -15 -308 -197 -335 -86 -13 -86 -14 -86 -256 l0
-211 -27 7 c-31 8 -293 55 -305 55 -4 0 -8 45 -8 100 0 122 -14 162 -61 179
-26 9 -37 8 -55 -4 -37 -24 -46 -51 -51 -156 l-6 -99 -228 0 -229 0 0 94 c0
116 -9 142 -56 162 -32 13 -38 13 -60 0 -36 -24 -41 -42 -49 -163 l-7 -112
-72 -11 c-39 -7 -111 -20 -161 -30 -49 -10 -93 -16 -97 -13 -5 2 -8 98 -8 212
0 170 -3 211 -14 221 -8 6 -42 18 -76 26 -134 31 -201 95 -216 206 -15 111 72
232 183 253 21 4 112 31 203 60 155 50 282 82 410 103 76 13 323 14 452 2z
m183 -1195 c133 -18 208 -31 299 -52 l68 -15 -7 -102 c-19 -276 -96 -477 -263
-686 -66 -83 -207 -205 -283 -245 -165 -87 -272 -80 -435 26 -152 99 -300 272
-390 454 -73 149 -104 266 -111 426 -7 145 -19 128 107 153 70 14 315 50 405
60 85 9 504 -4 610 -19z m-714 -1211 c243 -154 436 -150 681 12 38 26 73 43
77 38 4 -5 34 -44 65 -87 32 -42 105 -121 163 -175 l104 -98 -65 -71 c-45 -48
-86 -81 -133 -105 l-68 -36 -227 -6 c-224 -7 -267 -13 -368 -52 l-45 -17 -260
147 c-143 82 -270 154 -283 162 -23 15 -22 16 76 112 54 53 118 127 142 165
24 37 46 67 48 67 3 0 44 -25 93 -56z m-251 -540 c199 -113 307 -180 303 -188
-4 -6 -24 -33 -44 -61 -21 -27 -51 -76 -68 -109 l-31 -58 0 -624 0 -624 -57 0
c-73 0 -465 17 -599 26 l-101 6 -7 296 c-4 185 -11 301 -18 309 -16 19 -102
17 -119 -4 -11 -12 -15 -78 -17 -301 l-4 -285 -81 7 c-101 8 -349 43 -399 57
-35 9 -37 12 -52 77 -45 201 -84 652 -71 806 16 175 96 380 198 506 163 201
470 336 770 339 l88 1 309 -176z m1710 162 c350 -53 602 -228 736 -510 67
-142 79 -212 79 -476 -1 -167 -6 -271 -18 -360 -27 -193 -58 -348 -72 -360
-14 -11 -313 -63 -431 -75 l-71 -7 -6 39 c-4 21 -7 150 -7 286 0 152 -4 256
-10 268 -9 15 -22 19 -64 19 -32 0 -58 -5 -65 -13 -7 -9 -12 -112 -13 -313
l-3 -299 -65 -7 c-36 -4 -358 -11 -716 -14 l-651 -6 -6 197 c-4 109 -7 382 -7
607 l0 409 46 72 c56 86 135 164 200 197 46 23 62 25 284 31 204 6 243 10 297
29 113 39 211 115 308 239 l48 61 56 0 c31 0 99 -7 151 -14z"
        />
        <path
          d="M2618 1535 c-31 -17 -38 -36 -40 -97 -2 -98 18 -128 86 -128 26 0 40
7 56 28 19 23 21 37 18 95 -3 50 -9 72 -23 86 -28 27 -65 33 -97 16z"
        />
        <path
          d="M2625 1108 c-35 -19 -45 -41 -45 -100 0 -99 42 -145 109 -122 57 20
69 166 17 211 -30 25 -50 28 -81 11z"
        />
        <path
          d="M2605 665 c-21 -20 -25 -34 -25 -85 0 -71 20 -117 54 -126 65 -16
101 28 101 123 0 59 -3 67 -28 89 -36 31 -71 31 -102 -1z"
        />
      </g>
    </IconStyled>
  );
};

export default Chef;
