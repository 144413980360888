import styled from 'styled-components/macro';

export const Container = styled.div`
  padding-left: 5px;
  padding-top: 26px;
  cursor: pointer;

  .rowDotsMenu {
    width: 180px;
    height: auto !important;
  }
`;
