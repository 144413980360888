import React, { FC } from 'react';
import { Container, Box, Content } from './AngleBox.styles';
import { IAngleBoxProps } from './angleBox.types';

const AngleBox: FC<IAngleBoxProps> = ({
  children,
  color,
  faded,
  marginTop,
  noMarginBottom,
  height,
  fullWidth,
  className,
}) => {
  return (
    <Container
      marginTop={marginTop}
      noMarginBottom={noMarginBottom}
      height={height}
      className={className}
    >
      <Box color={color} faded={faded} height={height}>
        <Content fullWidth={fullWidth}>{children}</Content>
      </Box>
    </Container>
  );
};

export default AngleBox;
