import React, { useContext } from 'react';
import { LayoutPage } from '../../shared/layout';
import { Button } from '../../shared/button';
import Header from './sections/header';
import IngredientList from './sections/ingredientList';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useVenueQuery } from '../../../generated/graphql';
import BulkAddIngredients from './sections/bulkAddIngredients';
import VerifyIngredients from './sections/verifyIngredients';
import { overlayConstants } from '../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';

const Ingredients = () => {
  const { selectedVenueObject, account, user, dispatch } = useContext(
    LayoutContext
  );

  const venue = useVenueQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleAddIngredient = () => {
    dispatch({ type: 'TOP_SLIDER_PAGE', payload: 'editIngredient' });
    dispatch({ type: 'SELECT_INGREDIENT_PRODUCT', payload: undefined });
    dispatch({ type: 'SELECT_INGREDIENT', payload: undefined });
    dispatch({
      type: 'SET_INGREDIENT_USER_EVENT',
      payload: 'add',
    });

    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    }
  };

  return (
    <LayoutPage
      align="center"
      withLine
      heading="Ingredients"
      subHeading={`${
        selectedVenueObject?.displayName || ''
      } Venue: All the ingredients you have added`}
      actionArray={[
        <Button onClick={handleAddIngredient} color="primary">
          Add Ingredient
        </Button>,
      ]}
    >
      <Header data={venue} />
      <BulkAddIngredients />
      <VerifyIngredients />
      <IngredientList data={venue} />
    </LayoutPage>
  );
};

export default Ingredients;
