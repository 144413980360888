import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LayoutContext } from '../../../../contexts/layoutContext';
import logo from '../../../../images/logo-min.png';
import { Button } from '../../button';
import { TextButton } from '../../button/TextButton.styles';
import { DotsLoading } from '../../loading';
import { ErrorMessage, Span } from '../../typefaces/Typefaces.styles';
import {
  Buttons,
  Cancel,
  CardStyled,
  Container,
  Content,
  Heading,
  Image,
  LinkStyled,
  SubHeading,
} from './LayoutOverlay.styles';
import { useChatbox } from '../../../../utils/customHooks/useChatbox';

const LayoutOverlay = () => {
  const {
    isOpen,
    overlayToggle,
    sliderToggle,
    extraSliderToggle,
    topSliderToggle,
    newRecipe,
    overlay,
    help: { showHelpModal },
    dispatch,
  } = useContext(LayoutContext);
  const { chatboxLoading } = useChatbox();
  const { push } = useHistory();
  const location = useLocation();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isLoadingCTA, setIsLoadingCTA] = useState(false);
  const overlayRef = useRef(null);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useClickedOutside(ref, runFunction, overlay, dispatch) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          overlay?.linkCTA && overlay?.linkCTA();
          setErrorMessages([]);
          dispatch({ type: 'CLOSE_OVERLAY' });
        }
      }
      if (runFunction) {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }
    }, [ref, runFunction, dispatch, overlay]);
  }

  useClickedOutside(overlayRef, overlayToggle, overlay, dispatch);

  const handleClose = () => {
    setErrorMessages([]);
    if (showHelpModal) {
      dispatch({
        type: 'TOGGLE_HELP_MODAL',
      });
      dispatch({ type: 'CLOSE_OVERLAY' });
    } else {
      dispatch({ type: 'CLOSE_OVERLAY' });
    }
  };

  const handleButtonClick = async () => {
    try {
      // if is modal
      setIsLoadingCTA(true);
      setErrorMessages([]);

      const { errors } = (await overlay?.buttonCTA?.()) ?? {};

      if (errors?.length) {
        setIsLoadingCTA(false);
        setErrorMessages(errors);
        return;
      }

      dispatch({ type: 'CLOSE_OVERLAY' });

      if (!overlay?.toggleSliderOnButtonCTA) {
        if (sliderToggle) {
          dispatch({ type: 'TOGGLE_SLIDER' });
        }
        if (extraSliderToggle) {
          dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
        }
        if (topSliderToggle) {
          dispatch({ type: 'TOGGLE_TOP_SLIDER' });
        }
        if (showHelpModal) {
          dispatch({
            type: 'TOGGLE_HELP_MODAL',
          });
        }
      }
      overlay?.buttonLink && push(overlay?.buttonLink);
    } catch (e) {
      console.error(e);
      setErrorMessages(['Something went wrong']);
    } finally {
      setIsLoadingCTA(false);
    }
  };

  const handleLinkClick = () => {
    overlay?.linkCTA && overlay?.linkCTA();
    overlay?.link && push(overlay.link);
    if (sliderToggle) {
      dispatch({ type: 'TOGGLE_SLIDER' });
    }
    if (extraSliderToggle) {
      dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
    }
    if (topSliderToggle) {
      dispatch({ type: 'TOGGLE_TOP_SLIDER' });
    }
    if (showHelpModal) {
      dispatch({
        type: 'TOGGLE_HELP_MODAL',
      });
    }
    dispatch({ type: 'CLOSE_OVERLAY' });
  };

  return (
    <Container
      showOverlay={overlayToggle}
      sliderToggle={sliderToggle}
      isOpen={isOpen}
      fullWidth={location.pathname === '/'}
    >
      <CardStyled ref={overlayRef}>
        <Content>
          {!newRecipe && location.pathname === '/recipe-results' && (
            <Cancel onClick={handleClose}>
              <Span className="cross">+</Span>
            </Cancel>
          )}
          <Image src={logo} onClick={handleClose} />
          {errorMessages.map((err, i) => (
            <ErrorMessage className="errorMessage" key={i}>
              {err}
            </ErrorMessage>
          ))}
          <Heading>{overlay?.heading && overlay.heading}</Heading>
          <SubHeading color="faded">
            {overlay?.subHeading && overlay.subHeading}
          </SubHeading>
          {overlay?.content}

          {!overlay?.hideCTA && (
            <Buttons>
              <Button
                color="primary"
                onClick={() => handleButtonClick()}
                disabled={chatboxLoading || isLoadingCTA}
              >
                <DotsLoading
                  text={(loading) =>
                    loading ? 'Loading' : overlay?.buttonText || ''
                  }
                  isLoading={chatboxLoading || isLoadingCTA}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>

              {overlay?.link && overlay?.linkText ? (
                <LinkStyled to={overlay.link} onClick={() => handleLinkClick()}>
                  {overlay.linkText}
                </LinkStyled>
              ) : (
                overlay?.linkText && (
                  <TextButton onClick={() => handleLinkClick()}>
                    {overlay.linkText}
                  </TextButton>
                )
              )}
            </Buttons>
          )}
        </Content>
      </CardStyled>
    </Container>
  );
};

export default LayoutOverlay;
