import styled from 'styled-components/macro';
import { H2, Span } from '../../shared/typefaces/Typefaces.styles';

export const Container = styled.section`
  padding-top: 200px;
`;

export const Heading = styled(H2)`
  text-align: center;
  margin-bottom: 30px !important;
`;

export const Message = styled(Span)`
  text-align: center;
  display: block;
  margin-bottom: 30px;
`;

export const Error = styled(Message)`
  color: red;
`;
