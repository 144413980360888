import styled from 'styled-components/macro';
import Button from './Button';

export const TextButton = styled(Button)`
  background: none;
  text-decoration: underline;
  margin: 0 auto;
  margin-top: 20px;
  color: ${(props) => props.theme.colors.black};
  font-size: 12px;

  &:hover,
  &:active,
  &:focus {
    background: none;
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }
`;
