import React, { Children, forwardRef, useContext } from 'react';
import { CardContainer, Container } from './Card.styles';
import { ICardProps } from './card.types';
import { LayoutContext } from '../../../../contexts/layoutContext';

const Card = forwardRef<HTMLDivElement, ICardProps>(
  ({ children, containerClassName, ...props }, ref) => {
    const { isOpen } = useContext(LayoutContext);

    return (
      <Container ref={ref} className={containerClassName}>
        <CardContainer
          childCount={Children.count(children)}
          menuIsOpen={isOpen}
          {...props}
        >
          {children}
        </CardContainer>
      </Container>
    );
  }
);

export default Card;
