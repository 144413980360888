import React, {
  useContext,
  Fragment,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { INavbarActionsProps } from './navbarActions.types';
import {
  NavbarContainerRight,
  AuthMenu,
  AuthMenuItem,
  LinkStyled,
  AuthButton,
  LinkSpanStyled,
} from './NavbarActions.styles';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { theme } from '../../../../styles/theme';
import { useMeQuery } from '../../../../generated/graphql';
import { useAuth } from '../../../../utils/customHooks/useAuth';

const NavbarActions: React.FC<INavbarActionsProps> = ({
  actionArray,
  slider,
}) => {
  const [authNavMenu, setAuthNavMenu] = useState(false);
  const { appWidth } = useContext(LayoutContext);
  const { data } = useMeQuery();
  const authMenuRef = useRef(null);
  const { handleLogout } = useAuth();

  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setAuthNavMenu(false);
    }
  }, []);
  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (
        !slider &&
        authMenuRef.current &&
        !(authMenuRef.current as any).contains(e.target)
      ) {
        setAuthNavMenu(false);
      }
    },
    [authMenuRef, slider]
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [escapeListener, clickListener]);

  const handleAuthNavClick = () => {
    if (authNavMenu) {
      setAuthNavMenu(false);
    } else {
      setAuthNavMenu(true);
    }
  };

  const mobileNav = (
    <div ref={authMenuRef}>
      <AuthButton asCircle onClick={handleAuthNavClick}>
        RR
      </AuthButton>
    </div>
  );

  const nav = (
    <Fragment>
      {actionArray?.map((cta, idx) => (
        <div key={idx} className="navBarButton">
          {cta}
        </div>
      ))}
      {!slider && mobileNav}
    </Fragment>
  );

  const authMenu = (
    <Fragment>
      <AuthMenuItem onClick={handleAuthNavClick}>
        <LinkStyled to="/account">Account</LinkStyled>
      </AuthMenuItem>
      <AuthMenuItem onClick={handleAuthNavClick}>
        <LinkSpanStyled
          color={'grey'}
          onClick={() => handleLogout({ redirect: true })}
        >
          Sign Out
        </LinkSpanStyled>
      </AuthMenuItem>
    </Fragment>
  );

  const signUpMenu = (
    <Fragment>
      <AuthMenuItem onClick={handleAuthNavClick}>
        <LinkStyled to="/sign-up">Get Started</LinkStyled>
      </AuthMenuItem>
      <AuthMenuItem onClick={handleAuthNavClick}>
        <LinkStyled to="/">About</LinkStyled>
      </AuthMenuItem>
      <AuthMenuItem onClick={handleAuthNavClick}>
        <LinkStyled to="/">Pricing</LinkStyled>
      </AuthMenuItem>
      <AuthMenuItem onClick={handleAuthNavClick}>
        <LinkStyled to="/sign-in">Sign in</LinkStyled>
      </AuthMenuItem>
    </Fragment>
  );

  const mobileMenu = (
    <AuthMenu>
      {data?.me?.successful && data?.me?.user?.accountType === 'GUEST'
        ? signUpMenu
        : authMenu}
    </AuthMenu>
  );

  return (
    <NavbarContainerRight>
      {appWidth <= theme.mQ.tablet ? (slider ? nav : mobileNav) : nav}
      {authNavMenu && mobileMenu}
    </NavbarContainerRight>
  );
};

export default NavbarActions;
