import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { IRowProps } from './ingredientList.types';

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};

  ${Span} {
    text-align: right;
  }

  .heading {
    text-align: left;
  }

  &:first-child {
    justify-content: flex-start;
    margin-left: 15px;
    padding-left: 0;
  }

  @media ${device.mobileM} {
    &:nth-child(3) {
      justify-content: flex-end;
      padding-left: 10px;
    }
  }

  @media ${device.tablet} {
    &:nth-child(4) {
      justify-content: flex-end;
      padding-left: 10px;
    }
  }

  @media ${device.laptop} {
    &:nth-child(5) {
      justify-content: flex-end;
      padding-left: 10px;
    }
  }
`;

export const Row = styled.ul<IRowProps>`
  min-height: 70px;
  display: grid;
  grid-template-columns: ${({ updateIngredient }) =>
    updateIngredient ? 'auto 120px 50px' : 'auto 115px 50px'};
  cursor: pointer;

  &:first-child {
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 40px;
    ${Col} {
      border: 0;
    }
  }
  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundFaded};
    &:last-child {
      border-radius: 0 0 15px 15px;
    }
  }

  @media ${device.mobileM} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 215px 50px' : 'auto 115px 50px'};
  }
  @media ${device.mobileL} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 215px 50px' : 'auto 60px 170px 50px'};
  }
  @media ${device.tablet} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 215px 50px' : 'auto 60px 170px 160px 50px'};
  }
`;

export const HeaderRow = styled.ul`
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-columns: auto 210px;
  padding-right: 15px;

  &:first-child {
    ${Col} {
      border: 0;
      &:last-child {
        padding-right: 15px;
      }
    }
  }

  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto 50px;
    grid-gap: 0px;

    &:last-child {
      span {
        padding-right: 5px;
      }
    }
  }

  @media ${device.mobileM} {
    grid-template-columns: auto 210px;
  }
  @media ${device.mobileL} {
    grid-template-columns: auto 120px 210px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto 120px 170px 210px;
  }
`;

export const Icon = styled.div`
  height: 50px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled(Label)`
  margin-top: 5px;
`;
