import styled from 'styled-components/macro';
import { IBannerProps, IPlainProps } from './homepage.types';
import smoothie from '../../../images/smoothie-min.png';
import {
  H3,
  Span,
  H5,
  H4,
  Anchor,
} from '../../shared/typefaces/Typefaces.styles';
import { CardContainer } from '../../shared/card/card/Card.styles';
import { device } from '../../../styles/device';
import { LayoutContentStyles } from '../../shared/layout/layoutPage/LayoutPage.styles';
import { AnchorButton, ButtonStyled } from '../../shared/button/Button.styles';
import { Item, BoxWrapper } from '../../shared/layout/boxGroup/BoxGroup.styles';

export const Container = styled.div`
  ${LayoutContentStyles} {
    padding-top: 0;
  }

  .testimonial {
    /* margin-top: -100px; */
  }

  .whiteButton {
    background-color: ${({ theme }) => theme.colors.white.default};
    color: ${({ theme }) => theme.colors.primary.default};
    border-color: ${({ theme }) => theme.colors.white.default};
    margin: 60px auto 0;
  }

  .seeHow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 105px;
  }
`;

export const BannerContent = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;

  ${ButtonStyled} {
    width: fit-content;
  }
`;

export const Banner = styled.div<IBannerProps>`
  background-color: #f1f1f1;
  background-image: url(${({ image }) => image});
  background-position: ${({ align }) =>
    align === 'left'
      ? 'bottom left'
      : align === 'center'
      ? 'bottom center'
      : 'bottom left'};
  background-size: cover;
  height: ${({ height }) =>
    height && height + 30 < 100 ? `${height + 10}vh` : '90vh'};
  display: flex;
  align-items: top;
  justify-content: ${({ align }) =>
    align === 'left' ? 'flex-start' : 'flex-end'};
  padding: 30px;

  ${BannerContent} {
    justify-content: ${({ align }) =>
      align === 'left' ? 'flex-start' : 'flex-end'};
  }

  ${ButtonStyled} {
    margin: ${({ align }) => (align === 'left' ? '0 auto 0 0' : '0 0 0 auto')};
  }

  .title {
    font-size: ${({ theme }) => theme.fontSize.largeHeading};
    margin: 30px 0 !important;
    text-align: ${({ align }) => align};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    max-width: 500px;
    text-shadow: ${({ theme }) => theme.shadow.textHeading};
    line-height: 55px;

    @media ${device.mobileL} {
      font-size: ${({ theme }) => theme.fontSize.title};
      margin: 60px 0 30px !important;
    }
  }

  @media ${device.mobileL} {
    height: ${({ height }) => (height ? `${height}vh` : '90vh')};
  }

  @media ${device.laptop} {
    padding: 30px 60px;
  }
`;

export const RecipeExample = styled.div`
  width: 100%;
  margin-top: -150px;
  z-index: ${({ theme }) => theme.zIndex.priority};
  position: relative;

  ${CardContainer} {
    background-color: ${({ theme }) => theme.colors.white.default};
  }

  @media ${device.mobileL} {
    margin-top: -200px;
  }
`;

export const AngleBoxReset = styled.div`
  position: absolute;
  top: -300px;
  width: 100%;
`;

export const Plain = styled.div<IPlainProps>`
  position: relative;
  padding: 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ angleBoxAbove, halfMargin, noMargin }) =>
    noMargin
      ? '0 auto 100px;'
      : angleBoxAbove
      ? '100px auto 250px;'
      : halfMargin
      ? '100px auto 80px;'
      : '200px auto;'};

  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.largeHeading};
    text-align: center;
    padding-bottom: 30px;
    line-height: 50px;
    margin-top: ${({ angleBoxAbove }) => (angleBoxAbove ? '-120px' : '0')};
  }

  ${Span} {
    display: block;
    text-align: center;
    line-height: 24px;
  }

  ${ButtonStyled} {
    width: fit-content;
    margin: 0 auto;
  }

  img {
    width: 80px;
    margin: 0 auto 60px;
  }

  .aboutText {
    margin-top: 30px;
    font-size: ${({ theme }) => theme.fontSize.button};
    color: ${({ theme }) => theme.colors.text.grey};
  }

  &.pricing,
  &.compare {
    .subheading {
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.medium};
      margin-bottom: 15px;
    }
    .followUp {
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      margin-bottom: 60px;
    }

    .statement {
      margin-bottom: 30px;
    }
    .priceButton {
      width: 180px;
      margin: 60px auto;
    }
  }

  @media ${device.mobileL} {
    padding: 30px;
  }
`;

export const PlayButton = styled.div`
  background: red;
  border-radius: 50% / 10%;
  color: #ffffff;
  font-size: 2em; /* change this to change size */
  height: 1.5em;
  width: 2em;
  margin: 20px auto;
  padding: 0;
  position: relative;
  text-align: center;
  text-indent: 0.1em;
  transition: all 150ms ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;

  &:before {
    background: inherit;
    border-radius: 8% / 50%;
    bottom: 9%;
    content: '';
    left: -5%;
    position: absolute;
    right: -7%;
    top: 7%;
  }

  &:after {
    content: ' ';
    font-size: 0.75em;
    height: 0;
    margin: -1em 0 0 -0.75em;
    top: 50%;
    position: absolute;
    width: 0;
  }
`;

export const VideoPlaceholder = styled.div`
  background-image: url(${smoothie});
  width: 100%;
  height: 500px;
  background-position: center;
  background-size: cover;
  padding-top: 130px;
  padding-right: 22px;
  margin-bottom: 120px;

  &:hover {
    cursor: pointer;

    ${PlayButton} {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media ${device.laptop} {
    padding-top: 100px;
  }

  @media ${device.laptop} {
    padding-top: 80px;
  }
`;

export const Border = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.background};
`;

export const ListGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;
  margin-bottom: 15px;
  Ready ${H3} {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    margin-bottom: 15px;
  }

  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.large};
    color: ${({ theme }) => theme.colors.text.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: 15px;
  }

  ${Span} {
    flex: 1;
    padding-left: 15px;
    line-height: 24px;
  }

  .listButton {
    margin-top: 20px;
  }

  .brand {
    display: flex;
    align-self: end;
    text-align: end;
    width: 90px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

export const Icon = styled.div`
  width: 40px;
  flex: 0;
`;

export const Creative = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;

  ${H3} {
    padding-top: 150px;

    &:before {
      content: '';
      height: 60px;
      background-color: red;
      width: 30px;
      position: absolute;
      top: --5px;
      z-index: ${({ theme }) => theme.zIndex.below};
      background-color: ${({ theme }) => theme.colors.background};
    }
  }

  .subheading {
    max-width: 510px;
    margin: 5px auto;
    background-color: ${({ theme }) => theme.colors.background};
    padding: 10px;
  }

  ${AnchorButton} {
    width: ${({ theme }) => theme.layout.buttonWidth};
    margin: 60px auto 120px;
  }
`;

export const Guide = styled.ul`
  padding-bottom: 60px !important;

  li {
    display: flex;
    max-width: 450px;
    text-align: left;
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${H4} {
    margin-bottom: 15px;
  }
`;

export const NumberIcon = styled.div`
  font-weight: bold;
  width: 60px;
  height: 60px;
  border: 2px solid ${({ theme }) => theme.colors.secondary.faded};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-right: 50px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.secondary.default};
  color: ${({ theme }) => theme.colors.white.default};
`;

export const GuideContainer = styled.div`
  position: relative;
  display: flex;
  min-height: 500px;

  @media ${device.mobileL} {
    margin-left: 70px;
  }

  @media ${device.tablet} {
    align-items: center;
  }
`;

export const GuideImage = styled.div`
  position: absolute;
  top: 0;
  left: 550px;
  width: 500px;
  border-radius: 30px;

  img {
    border-radius: 30px;
  }

  @media ${device.desktopS} {
    left: 50%;
  }
`;

export const VerticalLine = styled.div`
  position: absolute;
  top: 0;
  width: 1px;
  border-right: 1px solid ${({ theme }) => theme.colors.default.faded};
  left: 30px;
  z-index: ${({ theme }) => theme.zIndex.below};
  height: 1300px;

  @media ${device.mobileL} {
    left: 100px;
  }
`;

export const Ready = styled.div`
  margin: 100px 0;

  ${BoxWrapper} {
    padding: 0 18px;
  }

  ${ButtonStyled}, ${AnchorButton} {
    margin: 30px auto 120px;
  }

  ${AnchorButton} {
    width: ${({ theme }) => theme.layout.buttonWidth};
  }

  @media ${device.laptopL} {
    margin: 100px auto;
  }
`;

export const Details = styled.div`
  margin: -100px 0 120px;

  ${ListGroup} {
    max-width: 350px;
    margin: 0 auto;
  }

  ${Span} {
    padding: 0 0 0 15px;
  }
`;

export const Callout = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.default};
  height: 350px;
  display: flex;
  justify-content: center;

  ${Plain} {
    margin: 0 auto;
  }

  ${ButtonStyled} {
    margin: 30px auto 0;
  }
`;

export const Footer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.lightest};
  padding: 60px 0 5px;

  ${Item} {
    margin: 0 15px 60px !important;
  }

  ${ListGroup} {
    max-width: 200px;
    min-width: 80px;
    margin: 0 auto;
  }

  ${ListItem} {
    cursor: pointer;
  }

  ${H5} {
    font-size: ${({ theme }) => theme.fontSize.default};
    color: ${({ theme }) => theme.colors.text.grey};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  ${Span}, ${Anchor} {
    padding: 0;
    color: ${({ theme }) => theme.colors.text.faded};
  }
`;

export const Logo = styled.img`
  width: 80px;
  height: 80px;
`;

export const Brand = styled.div`
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .brandHeader {
    display: flex;
    flex-direction: row;
  }

  .brandSubHeading {
    font-size: ${({ theme }) => theme.fontSize.default};
    color: ${({ theme }) => theme.colors.secondary.default};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    line-height: ${({ theme }) => theme.lineHeight.default};
  }
`;
