import React, { FC } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import { Container } from './RecipesProfitGraph.styles';
import { theme } from '../../../../../styles/theme';
import {
  VenueProfitTrendQuery,
  VenueProfitTrendQueryVariables,
} from '../../../../../generated/graphql';
import {
  convertCostCleanly,
  convertTimeFromSecondsCleanly,
  getFiveGraphElements,
} from '../../../../../utils/helper';
import * as ApolloClient from '@apollo/client';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

interface IRecipesProfitGraphProps {
  data: ApolloClient.QueryResult<
    VenueProfitTrendQuery,
    VenueProfitTrendQueryVariables
  >;
}

interface IVenueRecipeTrendProps {
  totalActiveSeconds: number;
  recipeProfitIncreasePerYear?: number;
  createdAt?: string;
}

const venueRecipeTrend: IVenueRecipeTrendProps[] = [
  {
    totalActiveSeconds: 3600,
    recipeProfitIncreasePerYear: 100,
    createdAt: '1716411037901',
  },
  {
    totalActiveSeconds: 7200,
    recipeProfitIncreasePerYear: 300,
    createdAt: '1716411037901',
  },
];

let totalSeconds = 0;
for (let index = 0; index < 5; index++) {
  const element = venueRecipeTrend[index];
  if (element) {
    totalSeconds = element.totalActiveSeconds;
  } else {
    totalSeconds = totalSeconds + 3600;
    venueRecipeTrend.push({
      totalActiveSeconds: totalSeconds,
    });
  }
}

// Custom tooltip component
const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: theme.colors.white.default,
          border: `1px solid ${theme.colors.default.default}`,
          padding: '10px',
        }}
      >
        <Span fontSize="small" fontWeight="medium">
          Total Profit Change:
        </Span>
        <br />
        <Span className="intro" fontWeight="medium">
          {convertCostCleanly(payload[0].value)}{' '}
          <Span className="label" color="faded">{`in ${label}`}</Span>
        </Span>
      </div>
    );
  }

  return null;
};

const formatYAxis = (tickItem) => {
  return `$${tickItem}`;
};

export const RecipesProfitGraph: FC<IRecipesProfitGraphProps> = ({ data }) => {
  const venueRecipeTrend =
    data?.data?.venue?.userVenue?.venue.venueProfitTrends.map((t) => {
      const { timeValue, timeText } = convertTimeFromSecondsCleanly(
        t.totalActiveSeconds
      );

      return {
        name: `${timeValue} ${timeText}`,
        totalProfit: t.recipeProfitIncreasePerYear,
        amt: t.recipeProfitIncreasePerYear,
      };
    }) || [];

  const graphData = getFiveGraphElements(venueRecipeTrend);

  return (
    <Container>
      <ResponsiveContainer width="100%" aspect={16 / 5}>
        <LineChart
          width={900}
          height={150}
          data={graphData}
          margin={{ top: 30, right: 55, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="name" dy={10} />
          <YAxis dataKey="totalProfit" tickFormatter={formatYAxis} />
          <CartesianGrid strokeDasharray="3 3" />
          <Line
            type="monotone"
            dataKey="totalProfit"
            stroke={theme.colors.secondary.default}
          />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};
