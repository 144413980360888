import { nanoid } from 'nanoid';
import React, { FC, Fragment, memo, useContext, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  IngredientMetricUnit,
  RecipeMetricUnit,
  useIngredientsQuery,
} from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import { scrollItemToCenter } from '../../../../../utils/helper';
import { getFormRow } from './getFormRow';
import {
  AddItem,
  Container,
  DotsMenuWrapper,
  HeaderIcon,
} from './Items.styles';
import { IItemsProps } from './items.types';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import {
  StocktakeSectionFormItem,
  StocktakeSectionFormState,
  StocktakeSectionIngredientFormState,
} from '../../../../shared/stocktake/stocktake.types';
import { Label } from '../../../../shared/typefaces/Typefaces.styles';
import { Dots } from '../../../../shared/icons';
import Table from '../../../../shared/table';
import { DotsMenu } from '../../../../shared/dotsMenu';
import {
  LinkSpanStyled,
  MenuItem,
} from '../../../../shared/dotsMenu/DotsMenu.styles';
import { ButtonGroup } from '../../../../shared/layout/Layout.styles';
import { TableRow } from '../../../../shared/table/table.types';
import { DotsLoading } from '../../../../shared/loading';

const Items: FC<IItemsProps> = ({
  initialItems,
  deleteStocktakeSection,
  handleDeleteStocktakeSection,
}) => {
  const { appWidth, selectedStocktakeSection, toolTips, dispatch } = useContext(
    LayoutContext
  );
  const sectionMenuRef = useRef<HTMLDivElement>(null);
  const [sectionMenu, setSectionMenu] = useState(false);
  const {
    quantity,
    unitAmount,
    required,
    unitType,
    total,
  } = toolTips.stocktake;

  const { errors, control, watch, setValue } = useFormContext<
    StocktakeSectionFormState
  >();
  const { append, remove } = useFieldArray<StocktakeSectionFormItem>({
    control,
    name: 'items',
  });

  const fields: StocktakeSectionFormItem[] = watch('items');
  const disableIngredient = false;
  const ingredients = useIngredientsQuery();
  const [fieldCount, setFieldCount] = useState(0);

  const handleAppend = (type: StocktakeSectionFormItem['__typename']) => {
    const rowKey = nanoid();

    append({ rowKey, __typename: type, order: fields.length });

    setFieldCount(fields.length);

    scrollItemToCenter(fieldCount, true);
  };

  const handleDelete = (
    item: Partial<StocktakeSectionFormItem>,
    index: number
  ) => {
    if (item.type === 'DELETE_ITEM') {
      return;
    }

    // if the item is not saved, we simply remove it from the list
    if (!item.stocktakeSectionItemId) {
      remove(index);
      return;
    }

    remove(index);

    append({
      rowKey: nanoid(),
      type: 'DELETE_ITEM',
      __typename: 'StocktakeSectionItemDeleted',
      stocktakeSectionItemId: item.stocktakeSectionItemId,
    });
  };

  const handleDotsClick = () => {
    setSectionMenu(!sectionMenu);
  };

  const handleDeleteSection = async () => {
    if (
      window.confirm('Are you sure you want to delete this Stocktake Section?')
    ) {
      setSectionMenu(true);
      handleDeleteStocktakeSection();
    } else {
      setSectionMenu(false);
    }
  };

  const handleIngredientChange = (
    value: StocktakeSectionIngredientFormState['ingredient'],
    name: string
  ) => {
    const index = name.includes('recipe')
      ? name.replace('.recipe', '')
      : name.replace('.ingredient', '');
    if (value) {
      if (value.extra === 'recipe') {
        setValue(index + '.type', 'RECIPE');
        setValue(index + '.unit', RecipeMetricUnit.Serve);
      } else {
        setValue(index + '.type', 'INGREDIENT');
        setValue(index + '.unit', IngredientMetricUnit.Gram);
      }
    }
  };

  const sectionMenuIcon = (
    <HeaderIcon
      onClick={handleDotsClick}
      showBackground={sectionMenu}
      ref={sectionMenuRef}
    >
      <Dots color="grey" size="smaller" />
    </HeaderIcon>
  );

  const onQuantityClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { quantity: !quantity },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Current Ingredient Quantity',
        content: `Add the total quantity of ingredients that you currently have`,
        buttonText: 'Got it',
        pageWidth: 900,
        yAxis: 230,
        xAxis: 900,
      },
    });
  };

  const onUnitAmountClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { unitAmount: !unitAmount },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Unit Amount',
        content: `Sometimes ingredients are ordered in units. A Slab of beer typically represents 24 beers for example. In this column add the unit amount associated with this ingredient`,
        buttonText: 'Got it',
        pageWidth: 900,
        yAxis: 230,
        xAxis: 900,
      },
    });
  };

  const onTotalClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { total: !total },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Total Amount',
        content: `What the total amount of ingredients you currently have. Example: Quantity * Unit Amount = Total Amount`,
        buttonText: 'Got it',
        pageWidth: 900,
        yAxis: 230,
        xAxis: 900,
      },
    });
  };

  const onRequiredClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { required: !required },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Total Required Amount',
        content: `How much do you required of this ingredient in total. Example: Quantity * Unit Amount = Total Required Amount`,
        buttonText: 'Got it',
        pageWidth: 900,
        yAxis: 230,
        xAxis: 900,
      },
    });
  };

  const onUnitTypeClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { unitType: !unitType },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Unit Type',
        content: `What is the unit Type? Examples: Box, Bottle, Packet, Kg..`,
        buttonText: 'Got it',
        pageWidth: 900,
        yAxis: 230,
        xAxis: 900,
      },
    });
  };

  const getTableHeadings: TableRow[] =
    appWidth < theme.mQ.laptop
      ? [
          {
            icon: <Fragment />,
            name: <Label>Section Items</Label>,
            item1: <Fragment />,
            item2: <Fragment />,
            item3: <Fragment />,
            item4: <Fragment />,
            item5: <Fragment />,
            item6: sectionMenuIcon,
            item1ToolTip: {
              onClick: onQuantityClick,
            },
            item2ToolTip: { onClick: onUnitAmountClick },
            item3ToolTip: { onClick: onTotalClick },
            item4ToolTip: { onClick: onRequiredClick },
            item5ToolTip: { onClick: onUnitTypeClick },
          },
        ]
      : [
          {
            icon: <Fragment />,
            name: <Label>Section Items</Label>,
            item1: <Label className="hasToolTipIcon">Quantity</Label>,
            item2: <Label className="hasToolTipIcon">Unit Amount</Label>,
            item3: <Label className="hasToolTipIcon">Total</Label>,
            item4: <Label className="hasToolTipIcon">Required</Label>,
            item5: <Label className="hasToolTipIcon">Unit Type</Label>,
            item6: sectionMenuIcon,
            item1ToolTip: {
              onClick: onQuantityClick,
            },
            item2ToolTip: { onClick: onUnitAmountClick },
            item3ToolTip: { onClick: onTotalClick },
            item4ToolTip: { onClick: onRequiredClick },
            item5ToolTip: { onClick: onUnitTypeClick },
            // item5ToolTip: <Label>Unit Type</Label>,
          },
        ];

  const getTableItems = (provided?: any, snapshot?: any) => {
    return appWidth < theme.mQ.mobileL ? (
      <Table
        className="itemsTable"
        iconWidth={10}
        itemOneWidth={40}
        itemTwoWidth={40}
        itemThreeWidth={50}
        itemFourWidth={60}
        itemFifthWidth={60}
        itemSixthWidth={40}
        gridArea="'iconOne name name name name name itemSix itemSix'
        'iconOne itemOne itemOne itemOne itemTwo itemTwo itemThree itemThree' 
        'iconOne itemFour itemFour itemFour itemFive itemFive itemFive itemFive'"
        headings={getTableHeadings}
        provided={provided}
        snapshot={snapshot}
        rows={fields.map((item, index) =>
          getFormRow({
            item,
            index,
            ingredients: ingredients.data?.ingredients.ingredients || undefined,
            selectedStocktakeSection: selectedStocktakeSection!,
            appWidth,
            errors,
            control,
            disableIngredient,
            initialItems,
            handleIngredientChange,
            handleDelete,
            setValue,
            watch,
          })
        )}
      />
    ) : appWidth < theme.mQ.laptop ? (
      <Table
        className="itemsTable"
        iconWidth={10}
        itemOneWidth={90}
        itemTwoWidth={70}
        itemThreeWidth={60}
        itemFourWidth={60}
        itemFifthWidth={70}
        itemSixthWidth={40}
        gridArea="'iconOne name name name name name itemSix itemSix'
        'iconOne itemOne itemTwo itemThree itemFour itemFour itemFive itemFive'"
        headings={getTableHeadings}
        provided={provided}
        snapshot={snapshot}
        rows={fields.map((item, index) =>
          getFormRow({
            item,
            index,
            ingredients: ingredients.data?.ingredients.ingredients || undefined,
            selectedStocktakeSection: selectedStocktakeSection!,
            appWidth,
            errors,
            control,
            disableIngredient,
            initialItems,
            handleIngredientChange,
            handleDelete,
            setValue,
            watch,
          })
        )}
      />
    ) : (
      <Table
        className="itemsTable"
        iconWidth={50}
        itemOneWidth={75}
        itemTwoWidth={100}
        itemThreeWidth={50}
        itemFourWidth={75}
        itemFifthWidth={70}
        itemSixthWidth={40}
        headings={getTableHeadings}
        provided={provided}
        snapshot={snapshot}
        rows={fields.map((item, index) =>
          getFormRow({
            item,
            index,
            ingredients: ingredients.data?.ingredients.ingredients || undefined,
            selectedStocktakeSection: selectedStocktakeSection!,
            appWidth,
            errors,
            control,
            disableIngredient,
            initialItems,
            handleIngredientChange,
            handleDelete,
            setValue,
            watch,
          })
        )}
      />
    );
  };

  const reorder = (
    list: StocktakeSectionFormItem[],
    startIndex: number,
    endIndex: number
  ): StocktakeSectionFormItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const reOrderedItems = reorder(fields, source.index, destination.index);
    const updatedOrder = reOrderedItems.map((item, idx) => {
      return {
        ...item,
        order: idx,
      };
    });
    setValue('items', updatedOrder);
  };

  return (
    <Container>
      <DotsMenuWrapper>
        {sectionMenu && (
          <DotsMenu top={50} right={5} menuItemCount={1} className="dotsMenu">
            <MenuItem onClick={() => handleDeleteSection()}>
              <LinkSpanStyled color="faded">
                <DotsLoading
                  text={(loading) =>
                    loading
                      ? 'Deleting Section From Stocktake'
                      : 'Delete Section From Stocktake'
                  }
                  isLoading={deleteStocktakeSection.loading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </LinkSpanStyled>
            </MenuItem>
          </DotsMenu>
        )}
      </DotsMenuWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="items">
          {(provided: any, snapshot: any) => getTableItems(provided, snapshot)}
        </Droppable>
      </DragDropContext>
      <ButtonGroup>
        {!disableIngredient && (
          <AddItem
            color="primary"
            onClick={() => handleAppend('StocktakeSectionIngredient')}
          >
            Add Ingredient
          </AddItem>
        )}
      </ButtonGroup>
    </Container>
  );
};

export default memo(Items);
