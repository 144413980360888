import { useMeQuery } from '../../generated/graphql';
import { useBoxedFn } from '../customHooks/useBoxedFn';

type IProfitOverridesByRecipeId = {
  [recipeId: string]: {
    recipeProfitIncreasePerServe: number;
    weeklySalesPerServe: number;
  };
};

export function useHighestProfitIncrease() {
  const user = useMeQuery({ fetchPolicy: 'cache-only' });

  return useBoxedFn(
    (profitIncreaseOverridesByRecipeId: IProfitOverridesByRecipeId = {}) => {
      const recipeProfitIncreases =
        user.data?.me?.user?.venuesSharedWithYou
          .map((v) => v.venue)
          .flatMap((v) => v.recipes)
          .map((r) => {
            const matchingOverride = profitIncreaseOverridesByRecipeId[r.id];

            const recipeProfitIncreasePerServe =
              matchingOverride?.recipeProfitIncreasePerServe ??
              r.recipeProfitIncreasePerServe;

            const weeklySalesPerServe =
              matchingOverride?.weeklySalesPerServe ?? r.weeklySalesPerServe;

            return (
              Math.max(recipeProfitIncreasePerServe, 0) *
              Math.max(weeklySalesPerServe, 0)
            );
          }) || [];

      const totalProfitIncrease = recipeProfitIncreases.reduce(
        (sum, value) => value + sum,
        0
      );

      return totalProfitIncrease;
    }
  );
}
