import styled from 'styled-components/macro';
import { INavbarStyleProps } from './navbar.types';
import { device } from '../../../styles/device';

export const NavbarContainer = styled.nav<INavbarStyleProps>`
  position: sticky;
  top: 0;
  display: flex;
  flex: 0 0;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white.default};
  border-bottom: ${({ withLine, theme }) =>
    withLine
      ? `1px solid ${theme.colors.default.default}`
      : `1px solid ${theme.colors.default.default}`};
  min-height: 50px;
  z-index: ${({ theme }) => theme.zIndex.nav};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 5px 0;

  .navBarButton {
    margin: 5px 0px 5px 10px;
  }

  @media ${device.mobileL} {
    min-height: 60px;
  }
`;

export const MenuToggle = styled.div`
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }

  @media ${device.mobileL} {
    display: none;
  }
`;
