import styled from 'styled-components/macro';
import {
  H5,
  Span,
  Anchor,
} from '../../../../shared/typefaces/Typefaces.styles';
import { Item } from '../../../ingredients/sections/ingredientList/IngredientList.styles';
import { ListGroup, ListItem } from '../../Homepage.styles';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.text.default};
  padding: 60px 0 5px;
  margin-top: 300px;

  ${Item} {
    margin: 0 15px 60px !important;
  }

  ${ListGroup} {
    max-width: 200px;
    min-width: 80px;
    margin: 0 auto;
  }

  ${ListItem} {
    cursor: pointer;
  }

  ${H5} {
    font-size: ${({ theme }) => theme.fontSize.default};
    color: ${({ theme }) => theme.colors.white.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  ${Span}, ${Anchor} {
    padding: 0;
    color: ${({ theme }) => theme.colors.text.faded};
  }
`;

export const Logo = styled.img`
  width: 80px;
  height: 80px;
`;
