import styled from 'styled-components/macro';
import { device } from '../../../styles/device';
import { H2 } from '../../shared/typefaces/Typefaces.styles';

export const Container = styled.section`
  position: relative;
`;

export const Main = styled.section`
  position: absolute;
  top: 400px;
  width: 100%;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  padding: 15px;

  .errorMessage {
    margin-bottom: 30px;
  }

  @media ${device.tablet} {
    margin: 80px auto;
  }

  @media ${device.laptopL} {
    width: 900px;
  }
`;

export const Left = styled.div`
  margin: 0 auto 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Right = styled.div`
  margin: 0 auto;
  @media ${device.laptop} {
    width: 800px;
  }
`;

export const Box = styled.div`
  position: relative;

  background-color: ${({ theme }) => theme.colors.white.default};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box};
  overflow: hidden;
  padding-bottom: 30px;
  max-width: 800px;

  .content {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 15px 30px;

    @media ${device.laptop} {
      margin: 0 auto;
      padding: 0px 50px;
    }
  }

  .sectionHeading {
    color: ${({ theme }) => theme.colors.text.black};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: ${({ theme }) => theme.lineHeight.large};
    padding: 30px 0 20px;
    display: flex;
    flex-direction: row;
  }

  .sectionId {
    color: ${({ theme }) => theme.colors.text.black};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding-right: 25px;
  }

  .sectionContent {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    line-height: ${({ theme }) => theme.lineHeight.large};
  }

  .text {
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.grey};

    ul {
      list-style-type: disc;
      padding-left: 15px;
    }

    li {
      padding-left: 5px;
    }
  }

  @media ${device.tablet} {
    margin: 0;
  }
`;

export const Heading = styled(H2)`
  text-align: center;
  margin: 30px 30px !important;
  color: ${({ theme }) => theme.colors.white.default};
  font-size: ${({ theme }) => theme.fontSize.largeHeading};
  text-shadow: ${({ theme }) => theme.shadow.text};

  @media ${device.tablet} {
    margin: 30px 30px !important;
    font-size: ${({ theme }) => theme.fontSize.title};
    text-align: left;
  }
`;
