import styled from 'styled-components/macro';
import { H3 } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div`
  overflow: hidden;

  .cta {
    width: 180px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 90px 0;

  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.heading};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.grey};
    text-align: center;

    .subHeading {
      color: ${({ theme }) => theme.colors.text.faded};
      padding: 0 15px;
    }

    .smallSubHeading {
      font-size: ${({ theme }) => theme.fontSize.medium};
    }
  }

  .CTA {
    margin-top: 40px;
  }
`;
