import React, { FC, useContext } from 'react';
import { theme } from '../../../../styles/theme';
import { Span } from '../../../shared/typefaces/Typefaces.styles';
import {
  Row,
  Col,
  Description,
  Checkbox,
  LabelWrap,
} from '../UpdateNotifications.styles';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { useFormContext } from 'react-hook-form';

interface IProps {
  col: {
    type: string;
    name: string;
    description: string;
    subscribed: boolean;
  };
}

const NotificationItem: FC<IProps> = ({ col }) => {
  const { appWidth } = useContext(LayoutContext);
  const { register, watch } = useFormContext();

  const toggleChecked = watch(col.name);

  if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
    return (
      <LabelWrap>
        <Row>
          <Col>
            <div>
              <Span>{col.type}</Span>
              <Description color="faded" fontSize="small" fontWeight="semibold">
                {col.description}
              </Description>
            </div>
          </Col>
          <Checkbox type="checkbox" name={col.name} ref={register} hidden />
          <Col>
            <Span color={toggleChecked ? 'secondary' : 'faded'}>
              {toggleChecked ? 'subscribed' : 'unsubscribed'}
            </Span>
          </Col>
        </Row>
      </LabelWrap>
    );
  } else {
    return (
      <LabelWrap>
        <Row>
          <Col />
          <Col>
            <div>
              <Span>{col.type}</Span>
              <Description color="faded" fontSize="small" fontWeight="semibold">
                {col.description}
              </Description>
            </div>
          </Col>
          <Checkbox type="checkbox" name={col.name} ref={register} hidden />
          <Col>
            <Span color={toggleChecked ? 'secondary' : 'faded'}>
              {toggleChecked ? 'subscribed' : 'unsubscribed'}
            </Span>
          </Col>
        </Row>
      </LabelWrap>
    );
  }
};

export default NotificationItem;
