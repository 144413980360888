import styled from 'styled-components';
import { ButtonStyled } from '../../button/Button.styles';
import { Label } from '../../typefaces/Typefaces.styles';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const PreviewContainer = styled.div`
  position: relative;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;

  .dropzone {
    width: 100%;
    cursor: pointer;
    margin-bottom: 8px;
  }
`;

export const ImageInput = styled.div<{ addVerticalPadding: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: ${({ addVerticalPadding }) =>
    addVerticalPadding ? '14px' : '14px 9px'};
  border-radius: 5px;
  outline: 1px solid ${({ theme }) => theme.colors.default.faded};
  border: 1px solid transparent;
  margin-bottom: 8px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.default.default};
  }
`;

export const Preview = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;

export const ImageDeleteButton = styled(ButtonStyled)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const ImageConfirmButton = styled(ButtonStyled)`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

export const ImageLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageSize = styled(Label)`
  margin-top: 4px;
`;
