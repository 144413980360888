import { isDefinedAndNotNullFilter } from './arrays';

const RESTRICTION_CHECKERS = {
  // Max venues restriction type
  MAX_VENUES: {
    check: (
      data: {
        numVenues: number;
      },
      conf: {
        maxVenues?: number;
      },
      direction: 'UP' | 'DOWN'
    ) => {
      if (conf.maxVenues === undefined) {
        console.error('maxVenues required but not found');
        return { isPass: false };
      }

      const isPass = data.numVenues <= conf.maxVenues;

      if (isPass) {
        return { isPass };
      }

      const venuesWord = conf.maxVenues === 1 ? 'venue' : 'venues';

      return {
        isPass,
        userMessage:
          direction === 'DOWN'
            ? `You currently have more venues than the new selected account allows. Please ensure you have ${conf.maxVenues} ${venuesWord}, before downgrading.`
            : `You have reached your venue limit of ${conf.maxVenues} ${venuesWord}, upgrade your account to add another venue.`,
      };
    },
  },

  // Max recipe versions
  MAX_RECIPE_VERSIONS: {
    check: (
      data: {
        numRecipeVersions: number;
      },
      conf: {
        maxRecipeVersions: number;
      }
    ) => {
      if (conf.maxRecipeVersions === undefined) {
        console.error('maxRecipeVersions required but not found');
        return { isPass: false };
      }

      const isPass = data.numRecipeVersions <= conf.maxRecipeVersions;

      if (isPass) {
        return { isPass };
      }

      const recipeWord =
        conf.maxRecipeVersions === 1 ? 'recipe version' : 'recipe versions';

      return {
        isPass,
        userMessage: `You have reached your recipe version limit of ${conf.maxRecipeVersions} ${recipeWord}, upgrade your account to add another recipe version.`,
      };
    },
  },

  SCALE_RECIPES: {
    check: (
      data: {
        scaleRecipes: boolean;
      },
      conf: {
        scaleRecipes?: boolean;
      }
    ) => {
      if (conf.scaleRecipes === undefined) {
        console.error('allRecipes required but not found');
        return { isPass: false };
      }

      const isPass = conf.scaleRecipes;

      if (isPass) {
        return { isPass };
      }

      return {
        isPass,
        userMessage: `Upgrade your account to scale your recipes`,
      };
    },
  },

  // Calculate recipe profits
  CALCULATE_RECIPE_PROFIT: {
    check: (
      _data: {},
      conf: {
        canShowCalculatedRecipeProfit: boolean;
      }
    ) => ({
      isPass: conf.canShowCalculatedRecipeProfit,
      userMessage: `Upgrade your account to 'Recipe Revenue', to see recipe profit & costs`,
    }),
  },

  // Food Cost Percentage
  FOOD_COST: {
    check: (
      _data: {},
      conf: {
        canShowFoodCostPercentage: boolean;
      }
    ) => ({
      isPass: conf.canShowFoodCostPercentage,
      userMessage: `Upgrade your account to 'Recipe +' or higher, to see recipe profit & costs`,
    }),
  },

  // Max recipe scale versions
  MAX_SCALE_RECIPE_VERSIONS: {
    check: (
      data: {
        recipes?: {
          displayName?: string;
          numScaledRecipeVersions: number;
        }[];
      },
      conf: {
        maxRecipeVersions: number;
      }
    ) => {
      if (data.recipes === undefined) {
        console.error('allRecipes required but not found');
        return { isPass: false };
      }

      for (const recipe of data.recipes || []) {
        if (recipe.numScaledRecipeVersions <= conf.maxRecipeVersions) {
          continue;
        } else {
          const userMessage = recipe.displayName
            ? `your recipe '${recipe.displayName}' is over the maximum of $${conf.maxRecipeVersions} scaled versions`
            : `your recipe is over the maximum of $${conf.maxRecipeVersions} scaled versions`;

          return { isPass: false, userMessage };
        }
      }

      return {
        isPass: true,
      };
    },
  },

  // Max profit restriction -> Not needed anymore
  MAX_PROFIT_INCREASE: {
    check: (
      data: {
        totalProfitIncrease?: number;
      },
      conf: {
        maxProfitIncrease: number;
      }
    ) => {
      const totalProfitIncrease = data.totalProfitIncrease;
      if (totalProfitIncrease === undefined) {
        console.error('totalProfitIncrease required but not found');
        return { isPass: false };
      }

      const isPass = totalProfitIncrease < conf.maxProfitIncrease;

      if (isPass) {
        return { isPass };
      }

      return {
        isPass,
        userMessage: `your profit increase is over the maximum of ${conf.maxProfitIncrease}`,
      };
    },
  },

  BULK_UPLOAD_INGREDIENTS: {
    check: (
      _data: {},
      conf: {
        canBulkUpload: boolean;
      }
    ) => ({
      isPass: conf.canBulkUpload,
    }),
  },
};

export type RestrictionCheckers = typeof RESTRICTION_CHECKERS;

export type RestrictionCheckerName = keyof typeof RESTRICTION_CHECKERS;

export type RestrictionChecker<
  NAME extends RestrictionCheckerName = RestrictionCheckerName
> = typeof RESTRICTION_CHECKERS[NAME];

export type RestrictionCheckerFunction<
  NAME extends RestrictionCheckerName
> = RestrictionCheckers[NAME]['check'];

export type RestrictionCheckerConf<
  NAME extends RestrictionCheckerName = RestrictionCheckerName
> = Parameters<RestrictionCheckerFunction<NAME>>['1'];

export type RestrictionCheckerInput<
  NAME extends RestrictionCheckerName = RestrictionCheckerName
> = Parameters<RestrictionCheckerFunction<NAME>>['0'];

export type AccountTypeRestrictionItem<
  NAME extends RestrictionCheckerName = RestrictionCheckerName
> = NAME extends infer N
  ? N extends RestrictionCheckerName
    ? {
        type: N;
        conf: RestrictionCheckerConf<N>;
      }
    : never
  : never;

export function checkRestrictionRaw<ITEM extends AccountTypeRestrictionItem>(
  item: ITEM,
  input: RestrictionCheckerInput<ITEM['type']>,
  direction: 'UP' | 'DOWN'
): { isPass: boolean; userMessage?: string } {
  const checker = RESTRICTION_CHECKERS[item.type];

  // Type error is to be expected here
  // @ts-expect-error
  return checker.check(input, item.conf, direction);
}

export function checkRestrictionsRaw<ITEM extends AccountTypeRestrictionItem>(
  items: ITEM[],
  input: RestrictionCheckerInput,
  direction: 'UP' | 'DOWN'
) {
  const failures: {
    [NAME in AccountTypeRestrictionItem['type']]?: {
      type: NAME;
      conf: RestrictionCheckerConf<NAME>;
      userMessage?: string;
    };
  } = {};

  for (const item of items) {
    const check = checkRestrictionRaw(item, input, direction);

    if (!check.isPass) {
      failures[item.type as string] = {
        userMessage: check.userMessage,
        ...item,
      };
    }
  }

  return {
    isPass: Object.values(failures).length === 0,
    failures: failures,
    userMessages: Object.values(failures)
      .map((failure) => failure?.userMessage)
      .filter(isDefinedAndNotNullFilter),
  };
}
