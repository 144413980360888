import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Catering: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M1359 4601 c-22 -18 -24 -29 -29 -135 -7 -167 4 -160 -253 -166 -231
-6 -266 -16 -332 -93 -52 -61 -68 -127 -63 -261 5 -129 22 -172 90 -230 54
-47 120 -66 226 -66 79 0 85 -2 113 -29 26 -26 29 -36 29 -94 0 -84 23 -117
81 -117 64 0 79 24 79 123 0 65 -5 92 -24 132 -48 103 -124 144 -261 145 -145
0 -175 29 -175 166 0 149 25 164 271 164 208 0 237 7 304 75 64 63 75 100 75
249 0 103 -2 118 -20 136 -26 26 -80 26 -111 1z"
        />
        <path
          d="M1763 4222 c-36 -5 -53 -40 -53 -105 0 -88 -8 -97 -85 -97 -75 0
-109 -11 -149 -48 -46 -43 -66 -97 -66 -180 0 -112 34 -176 115 -213 22 -10
58 -19 80 -19 52 0 65 -14 65 -67 0 -56 26 -83 79 -83 55 0 81 34 81 104 -1
117 -86 206 -198 206 -21 0 -43 5 -50 12 -16 16 -16 100 0 116 7 7 35 12 62
12 98 0 167 35 203 104 25 46 25 206 0 234 -19 21 -48 29 -84 24z"
        />
        <path
          d="M1239 3301 c-45 -14 -73 -33 -138 -95 -95 -92 -112 -136 -66 -181 16
-17 35 -25 58 -25 29 0 44 10 93 61 94 98 125 105 276 69 137 -33 191 -35 303
-10 135 30 198 35 235 20 16 -7 73 -59 126 -116 117 -127 127 -134 266 -195
160 -70 182 -96 194 -228 l7 -71 -953 0 -953 0 6 75 c10 116 30 145 131 196
62 30 88 50 96 69 23 55 -14 110 -72 110 -40 0 -180 -73 -224 -116 -20 -21
-45 -56 -55 -78 -20 -46 -39 -149 -39 -213 l0 -43 -164 0 c-153 0 -165 -1
-197 -23 -57 -38 -69 -75 -69 -212 0 -137 12 -174 69 -212 25 -17 48 -23 91
-23 l58 0 4 -247 c5 -218 8 -256 27 -315 23 -73 48 -98 94 -98 35 0 77 40 77
73 0 14 -7 44 -17 68 -13 35 -17 92 -21 282 l-4 237 1157 0 1156 0 -3 -252
c-3 -253 -3 -253 -30 -304 -36 -69 -69 -106 -126 -142 -97 -63 -82 -62 -997
-62 -734 0 -836 2 -885 16 -93 28 -135 29 -159 5 -21 -21 -27 -63 -15 -96 15
-37 163 -84 267 -85 l47 0 0 -55 0 -54 -377 -3 c-432 -3 -431 -3 -484 -87 -28
-44 -29 -49 -29 -175 0 -150 9 -179 73 -230 l39 -31 2073 -3 c1979 -2 2074 -1
2095 16 31 25 32 85 2 115 l-23 22 -2044 3 -2044 2 -12 21 c-16 31 -8 167 11
179 20 13 4760 13 4780 0 19 -12 27 -148 11 -178 -11 -20 -20 -21 -240 -24
-209 -3 -231 -5 -250 -22 -28 -26 -29 -88 -1 -116 20 -20 30 -21 280 -18 l258
3 39 31 c64 51 73 80 73 230 0 126 -1 131 -29 175 -43 69 -81 83 -233 87
l-126 4 18 41 c10 23 21 65 25 94 6 46 10 52 32 55 67 8 83 112 23 143 -16 8
-45 15 -64 15 l-35 0 19 43 c10 23 21 66 25 95 6 46 10 52 30 52 26 0 65 42
65 71 0 53 -44 89 -111 89 l-28 0 19 43 c10 23 21 66 25 95 6 46 10 52 30 52
26 0 65 42 65 71 0 53 -44 89 -111 89 l-28 0 19 43 c10 23 21 66 25 95 6 46
10 52 30 52 28 0 65 43 65 75 0 12 -9 34 -21 49 l-20 26 -679 0 -679 0 -20
-26 c-12 -15 -21 -37 -21 -49 0 -35 37 -75 69 -75 27 0 28 -2 33 -63 3 -35 13
-77 21 -94 l16 -30 -47 -6 c-52 -6 -92 -41 -92 -81 0 -37 37 -76 71 -76 28 0
29 -2 29 -45 0 -25 8 -67 19 -94 l18 -49 -46 -5 c-51 -6 -91 -41 -91 -81 0
-37 37 -76 71 -76 28 0 29 -2 29 -45 0 -25 9 -67 19 -95 l19 -50 -33 0 c-64 0
-105 -35 -105 -91 0 -28 44 -69 75 -69 23 0 25 -3 25 -45 0 -25 9 -67 19 -95
l19 -50 -589 0 -589 0 0 55 0 55 43 0 c232 0 445 160 502 375 11 45 15 116 15
302 l0 243 64 0 c49 0 71 5 97 23 57 38 69 75 69 212 0 137 -12 174 -69 212
-32 22 -44 23 -197 23 l-164 0 0 48 c0 64 -24 171 -48 218 -32 62 -90 110
-187 155 -49 22 -109 50 -132 61 -26 12 -80 60 -146 131 -124 133 -168 160
-273 165 -51 3 -107 -4 -198 -23 l-126 -27 -128 26 c-125 26 -223 32 -273 17z
m1781 -1011 l0 -80 -1380 0 -1380 0 0 80 0 80 1380 0 1380 0 0 -80z m1600 -32
c0 -33 -23 -90 -47 -119 -49 -57 -60 -59 -424 -59 -286 0 -336 2 -366 16 -48
23 -84 75 -91 129 l-5 45 467 0 c366 0 466 -3 466 -12z m-5 -375 c-10 -54 -30
-88 -72 -120 l-36 -28 -344 -3 c-305 -2 -347 -1 -379 14 -49 23 -85 75 -92
129 l-5 45 468 0 467 0 -7 -37z m-1 -350 c-10 -55 -29 -88 -71 -120 l-36 -28
-343 -3 c-328 -3 -345 -2 -383 17 -51 26 -82 71 -89 127 l-5 44 467 0 467 0
-7 -37z m0 -350 c-9 -55 -48 -110 -94 -133 -37 -19 -58 -20 -371 -20 l-332 0
-44 25 c-48 29 -83 85 -83 135 l0 30 465 0 466 0 -7 -37z m-3344 -98 l0 -55
-110 0 -110 0 0 55 0 55 110 0 110 0 0 -55z m580 0 l0 -55 -210 0 -210 0 0 55
0 55 210 0 210 0 0 -55z m380 0 l0 -55 -110 0 -110 0 0 55 0 55 110 0 110 0 0
-55z"
        />
      </g>
    </IconStyled>
  );
};

export default Catering;
