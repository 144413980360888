import React, { FC } from 'react';
import { AccountType } from '../../../../../generated/graphql';
import { IInfoModalProps } from '../../SelectAccount';
import { IDetailsProps, IPlanProps } from '../pricing/Pricing';
import {
  Container,
  Content,
  Details,
  Header,
  Heading,
  Labels,
} from './PlanDetails.styles';
import { PlanTable } from './PlanTable';

interface IPlanDetailsProps {
  cta?: string;
  usage: IDetailsProps[];
  essentials: IDetailsProps[];
  calculations: IDetailsProps[];
  advanced: IDetailsProps[];
  details?: IPlanProps;
  addUsageLineBreaks?: number;
  addCalculationsLineBreaks?: number;
  showHeadings?: boolean;
  isLoading?: boolean;
  setInfoModal?: React.Dispatch<React.SetStateAction<IInfoModalProps>>;
  onSubmit?: (accountType: AccountType) => void;
}

export const PlanDetails: FC<IPlanDetailsProps> = ({
  details,
  usage,
  essentials,
  calculations,
  advanced,
  addUsageLineBreaks,
  showHeadings,
  setInfoModal,
}) => {
  return (
    <Container noMaxWidth={!details} desktopOnly={!details} id="features">
      <Header>
        <Heading alignLeft={!details} bold={!details} type={details?.type}>
          {details ? details.heading : 'Compare Features'}
        </Heading>
      </Header>
      <Content>
        <Labels>
          <PlanTable
            heading="Usage"
            id="label"
            items={usage}
            noWrap
            addMobileTableRows={0}
            setInfoModal={setInfoModal}
          />
          <PlanTable
            heading="Essentials"
            id="label"
            items={essentials}
            noWrap
            addMobileTableRows={0}
            setInfoModal={setInfoModal}
          />
          <PlanTable
            heading="Calculations"
            id="label"
            items={calculations}
            noWrap
            setInfoModal={setInfoModal}
          />
          <PlanTable heading="Advanced" id="label" items={advanced} noWrap />
        </Labels>
        <Details>
          <PlanTable
            heading={showHeadings ? 'Usage' : ''}
            id={details ? details.id : 'label'}
            items={usage}
            addTableRows={addUsageLineBreaks}
            noWrap={!details}
            setInfoModal={setInfoModal}
          />
          <PlanTable
            heading={showHeadings ? 'Essentials' : ''}
            id={details ? details.id : 'label'}
            items={essentials}
            noWrap={!details}
            setInfoModal={setInfoModal}
          />
          <PlanTable
            heading={showHeadings ? 'Calculations' : ''}
            id={details ? details.id : 'label'}
            items={calculations}
            noWrap={!details}
            setInfoModal={setInfoModal}
          />
          <PlanTable
            heading={showHeadings ? 'Advanced' : ''}
            id={details ? details.id : 'label'}
            items={advanced}
            noWrap={!details}
            setInfoModal={setInfoModal}
          />
        </Details>
      </Content>
    </Container>
  );
};
