import { IGetCompareRecipeDataProps } from '../../components/pages/recipeResults/recipeResult.types';
import { isEmpty } from './objects';
import { getUnitTotal } from './units';
import { getRecipeIngredientsData } from '../clientServerShared/getRecipeIngredientData';
import { getSubRecipeGrams } from './getSubRecipeGrams';

export const getCompareRecipeData = ({
  recipe,
  venue,
  selectedVersion,
  salesPricePerServeFormData,
}: IGetCompareRecipeDataProps) => {
  if (recipe.data?.recipe.recipe && venue?.data?.venue?.userVenue?.venue) {
    const venueData = venue.data?.venue?.userVenue?.venue;
    const { weeklySalesPerServe } = recipe.data?.recipe.recipe;

    const {
      id,
      displayName,
      serves,
      original,
      recipeWastage,
      totalStaffTime,
      totalTime,
      recipeIngredients,
    } = selectedVersion;

    const recipeData: any = {
      versionId: id,
      versionName: displayName,
      serves,
      salesPricePerServe: salesPricePerServeFormData || 0,
      weeklySalesPerServe,
      original,
      profit: 0,
      profitPerServe: 0,
      foodCost: 0,
      profitPerMin: 0,
      recipeProfit: 0,
      recipeRevenue: 0,
      totalStaffTimeCost: 0,
      totalVenueTimeCost: 0,
      foodCostPercentage: 0,
      totalTimeCost: 0,
      versionHasOnlyProcessTime: false,
    };

    if (!isEmpty(recipeIngredients) && recipeIngredients) {
      const { foodCost, wastageCost } = getRecipeIngredientsData({
        recipeIngredients,
        venueData,
        recipeWastage,
      });

      recipeData.foodCost = foodCost;
      recipeData.wastageCost = wastageCost;
    }

    for (const subRecipe of selectedVersion.recipeAsIngredients ?? []) {
      const {
        unit,
        quantity,
        recipe: { recipeProfit, recipeRevenue, serves, totalGrams },
      } = subRecipe;

      const subRecipeGrams = getSubRecipeGrams({
        unit,
        quantity,
        recipeServes: serves,
        totalGrams,
      });

      if (!subRecipeGrams) continue;

      const recipeCost = recipeRevenue - recipeProfit;
      const subRecipeCost = subRecipeGrams * (recipeCost / totalGrams);
      recipeData.foodCost += subRecipeCost;
    }

    if (totalTime && venueData) {
      const {
        chefCost,
        rentCost,
        waterCost,
        powerCost,
        councilCost,
        insuranceCost,
      } = venueData?.venueCost;

      recipeData.rentCost = totalTime * rentCost;
      recipeData.powerCost = totalTime * powerCost;
      recipeData.waterCost = totalTime * waterCost;
      recipeData.insuranceCost = totalTime * insuranceCost;
      recipeData.councilCost = totalTime * councilCost;
      recipeData.totalStaffTimeCost = totalStaffTime * chefCost;
      recipeData.totalVenueTimeCost =
        (rentCost + waterCost + powerCost + councilCost + insuranceCost) *
        totalTime;
      recipeData.totalTimeCost =
        recipeData.totalStaffTimeCost + recipeData.totalVenueTimeCost;
    }
    recipeData.recipeRevenue = recipeData.salesPricePerServe * serves;

    if (!isEmpty(recipeWastage)) {
      const foodWastageCost = recipeData.foodCost * (recipeWastage! / 100);
      recipeData.foodCost = recipeData.foodCost + foodWastageCost;
    }

    recipeData.recipeCost = recipeData.foodCost + recipeData.totalTimeCost;
    if (recipeData.venuePrepCost) {
      recipeData.recipeCost = recipeData.recipeCost + recipeData.venuePrepCost;
    }

    if (!isEmpty(recipeData.foodCost) && !isEmpty(recipeData.recipeCost)) {
      recipeData.foodCostPercentage =
        (recipeData.foodCost / recipeData.recipeRevenue) * 100;
    }

    recipeData.recipeProfit = recipeData.recipeRevenue - recipeData.recipeCost;
    recipeData.profitPerServe = recipeData.recipeProfit / serves;

    const totalStaffTimeItems =
      selectedVersion.recipeTimeItems.length > 0 &&
      selectedVersion.recipeTimeItems.filter((t) => t.staffTime);

    recipeData.versionHasOnlyProcessTime =
      totalStaffTimeItems && totalStaffTimeItems.length === 0;

    if (!isEmpty(recipeData.recipeProfit) && !isEmpty(totalTime)) {
      recipeData.profitPerMin =
        recipeData.recipeProfit / getUnitTotal('minute', totalTime);
    }
    return recipeData;
  } else {
    return undefined;
  }
};
