import React, { useContext, FC } from 'react';
import { LayoutColumn } from '../../../shared/layout';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, SelectList } from '../../../shared/formElements';
import { NestedColumns, Section, SideLabel } from '../Venue.styles';
import { theme } from '../../../../styles/theme';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { IVenueDataProps } from '../../account/sections/venues/venues.types';
import { getVenueUnitCost, currency } from '../../../../utils/helper';

const Costs: FC<IVenueDataProps> = ({ data }) => {
  const { appWidth, addVenue } = useContext(LayoutContext);
  const venueCosts = data?.data?.venue?.userVenue?.venue.venueCost;
  const { errors, control } = useFormContext();

  const sideLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  if (!venueCosts && !addVenue) {
    return null;
  }

  const chefCostUnits = [{ label: 'per Hour', value: 'hour' }];
  const costUnits = [
    { label: 'per Week', value: 'week' },
    { label: 'per Month', value: 'month' },
    { label: 'per Quarter', value: 'quarter' },
    { label: 'per Year', value: 'year' },
  ];

  let chefCost = 0;
  let chefCostUnit = 'hour';
  let councilCost = 0;
  let councilCostUnit = 'year';
  let insuranceCost = 0;
  let insuranceCostUnit = 'year';
  let rentCost = 0;
  let rentCostUnit = 'year';
  let powerCost = 0;
  let powerCostUnit = 'month';
  let waterCost = 0;
  let waterCostUnit = 'month';

  if (venueCosts) {
    chefCost = venueCosts.chefCost;
    chefCostUnit = venueCosts.chefCostUnit;
    councilCost = venueCosts.councilCost;
    councilCostUnit = venueCosts.councilCostUnit;
    insuranceCost = venueCosts.insuranceCost;
    insuranceCostUnit = venueCosts.insuranceCostUnit;
    rentCost = venueCosts.rentCost;
    rentCostUnit = venueCosts.rentCostUnit;
    powerCost = venueCosts.powerCost;
    powerCostUnit = venueCosts.powerCostUnit;
    waterCost = venueCosts.waterCost;
    waterCostUnit = venueCosts.waterCostUnit;
  }

  return (
    <Section>
      <LayoutColumn>
        {sideLabel('Chef Cost')}
        <NestedColumns>
          <Controller
            as={<Input />}
            type="number"
            name="chefCost"
            label={fieldLabel('Chef Cost')}
            control={control}
            errorText={errors.chefCost && errors.chefCost.message}
            defaultValue={currency(getVenueUnitCost(chefCost, chefCostUnit))}
          />
          <SelectList
            control={control}
            noBorder
            name="chefCostUnit"
            defaultValue={chefCostUnit}
            errorText={errors.chefCostUnit && errors.chefCostUnit.message}
            options={chefCostUnits}
          />
        </NestedColumns>
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Rent Cost')}
        <NestedColumns>
          <Controller
            as={<Input />}
            type="number"
            name="rentCost"
            label={fieldLabel('Lease Cost')}
            control={control}
            errorText={errors.rentCost && errors.rentCost.message}
            defaultValue={currency(getVenueUnitCost(rentCost, rentCostUnit))}
          />
          <SelectList
            control={control}
            noBorder
            name="rentCostUnit"
            defaultValue={rentCostUnit ? rentCostUnit : 'year'}
            errorText={errors.rentCostUnit && errors.rentCostUnit.message}
            options={costUnits}
          />
        </NestedColumns>
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Water Cost')}
        <NestedColumns>
          <Controller
            as={<Input />}
            type="number"
            name="waterCost"
            label={fieldLabel('Water Cost')}
            control={control}
            errorText={errors.waterCost && errors.waterCost.message}
            defaultValue={currency(getVenueUnitCost(waterCost, waterCostUnit))}
          />
          <SelectList
            control={control}
            noBorder
            name="waterCostUnit"
            defaultValue={waterCostUnit ? waterCostUnit : 'month'}
            errorText={errors.waterCostUnit && errors.waterCostUnit.message}
            options={costUnits}
          />
        </NestedColumns>
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Power Cost')}
        <NestedColumns>
          <Controller
            as={<Input />}
            type="number"
            name="powerCost"
            label={fieldLabel('Power Cost')}
            control={control}
            errorText={errors.powerCost && errors.powerCost.message}
            defaultValue={currency(getVenueUnitCost(powerCost, powerCostUnit))}
          />
          <SelectList
            control={control}
            noBorder
            name="powerCostUnit"
            defaultValue={powerCostUnit ? powerCostUnit : 'month'}
            errorText={errors.powerCostUnit && errors.powerCostUnit.message}
            options={costUnits}
          />
        </NestedColumns>
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Insurance Cost')}
        <NestedColumns>
          <Controller
            as={<Input />}
            type="number"
            name="insuranceCost"
            label={fieldLabel('Insurance Cost')}
            control={control}
            errorText={errors.insuranceCost && errors.insuranceCost.message}
            defaultValue={currency(
              getVenueUnitCost(insuranceCost, insuranceCostUnit)
            )}
          />
          <SelectList
            control={control}
            noBorder
            name="insuranceCostUnit"
            defaultValue={insuranceCostUnit ? insuranceCostUnit : 'year'}
            errorText={
              errors.insuranceCostUnit && errors.insuranceCostUnit.message
            }
            options={costUnits}
          />
        </NestedColumns>
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Council Cost')}
        <NestedColumns>
          <Controller
            as={<Input />}
            type="number"
            name="councilCost"
            label={fieldLabel('Council Cost')}
            control={control}
            errorText={errors.councilCost && errors.councilCost.message}
            defaultValue={currency(
              getVenueUnitCost(councilCost, councilCostUnit)
            )}
          />
          <SelectList
            control={control}
            noBorder
            name="councilCostUnit"
            defaultValue={councilCostUnit ? councilCostUnit : 'year'}
            errorText={errors.councilCostUnit && errors.councilCostUnit.message}
            options={costUnits}
          />
        </NestedColumns>
      </LayoutColumn>
    </Section>
  );
};

export default Costs;
