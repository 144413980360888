import React, { useContext } from 'react';
import { LayoutContext } from '../../../contexts/layoutContext';
import { Button } from '../button';
import { H4, Span } from '../typefaces/Typefaces.styles';
import { Container, ToolTipStyled } from './ToolTip.styles';

export const ToolTip = () => {
  const {
    appWidth,
    isOpen,
    toolTip: { show, heading, content, buttonText, pageWidth, xAxis, yAxis },
    dispatch,
    sliderToggle,
    extraSliderToggle,
    topSliderToggle,
  } = useContext(LayoutContext);

  const widthCheck = isOpen
    ? appWidth - (pageWidth + 200)
    : appWidth - (pageWidth + 50);
  const displayType = widthCheck > 300 ? 'desktop' : 'popout';
  const sliderPageIsOpen = sliderToggle || extraSliderToggle || topSliderToggle;

  return (
    <Container
      show={show}
      xAxis={xAxis}
      yAxis={yAxis}
      pageWidth={pageWidth}
      isOpen={isOpen}
      displayType={displayType}
      desktopToolTipMaxWidth={widthCheck}
      sliderPageIsOpen={sliderPageIsOpen}
    >
      <ToolTipStyled>
        <H4 className="heading">{heading}</H4>
        <Span className="content">{content}</Span>
        <Button
          onClick={() => dispatch({ type: 'HIDE_TOOL_TIP' })}
          small
          className="button"
          color="secondary"
        >
          {buttonText}
        </Button>
      </ToolTipStyled>
    </Container>
  );
};
