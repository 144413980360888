import styled from 'styled-components/macro';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div`
  margin-top: 30px;
`;

export const TotalText = styled(Span)`
  text-align: right;
`;
