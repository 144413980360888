import styled from 'styled-components';
import { ContentProps } from './Content.types';

export const Container = styled.section<ContentProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ fullWidth }) =>
    fullWidth ? 'auto' : 'minmax(50px, 75px) auto'};
  grid-template-areas: ${({ fullWidth }) =>
    fullWidth ? "'content'" : "'... content'"};
  padding-bottom: ${({ fullWidth }) => (fullWidth ? '0' : '20px')};
`;

export const Content = styled.section`
  grid-area: content;
`;
