import { useState, useEffect } from 'react';

const useResizeDimensions = (myRef, resize?: boolean) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });

  useEffect(() => {
    const getDimensions = () => {
      if (myRef.current === null || undefined) {
        return {
          width: 0,
          height: 0,
          top: 0,
          left: 0,
        };
      }
      const rect = myRef.current.getBoundingClientRect();

      return {
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight,
        top: rect.top,
        left: rect.left,
      };
    };

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (resize) {
      handleResize();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, resize]);

  return dimensions;
};

export default useResizeDimensions;
