import React, { FC } from 'react';
import { Container, Item } from './BoxGroup.styles';
import { IBoxGroupProps } from './boxGroup.types';

const AngleBox: FC<IBoxGroupProps> = ({
  items,
  center,
  noBackground,
  noMargin,
}) => {
  return (
    <Container center={center}>
      {items.map((item, idx) => (
        <Item key={idx} noBackground={noBackground} noMargin={noMargin}>
          {item}
        </Item>
      ))}
    </Container>
  );
};

export default AngleBox;
