import React, { FC } from 'react';
import salad from '../../../../../images/salad-min.png';
import { AnchorButton } from '../../../../shared/button/Button.styles';
import SideContent from '../../../../shared/layout/sideContent';
import { H2, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Container, Content, CTA } from './WhereToStart.styles';

interface IWhereToStartProps {}

export const WhereToStart: FC<IWhereToStartProps> = () => {
  return (
    <Container>
      <SideContent imageSide="right" image={salad} className="sideContent">
        <Content>
          <H2>Where To Start?</H2>
          <Span>In the kitchen of course!</Span>
          <Span className="breakline">
            We recommend starting with your favourite dish.
          </Span>
          <Span fontWeight="bold">Challenge yourself.</Span>
          <Span>
            Can you make your recipe more profiable and improve it's
            ingredients, cooking time or presentation, without increasing the
            sales price?
          </Span>
          <CTA>
            <AnchorButton
              href="/select-account"
              color="primary"
              inversed
              className="ctaButton"
            >
              Make Your Own Recipe
            </AnchorButton>
            <AnchorButton
              href="#pre-made-recipes"
              color="primary"
              className="ctaButton"
            >
              Browse Profitable Recipes
            </AnchorButton>
          </CTA>
        </Content>
      </SideContent>
    </Container>
  );
};
