import React, { FC, useContext, useRef, useState } from 'react';
import { Container } from './ProfitPopularity.styles';
import { RecipesProfitPopularityGraph } from '..';
import { Card, Content, Header } from '../../../../shared/card';
import { PieChart } from '../../../../shared/icons';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { Button } from '../../../../shared/button';
import {
  RecipesQuery,
  RecipesQueryVariables,
  useVenueProfitTrendQuery,
} from '../../../../../generated/graphql';
import { DotsLoading } from '../../../../shared/loading';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import * as ApolloClient from '@apollo/client';
import { AnchorButton } from '../../../../shared/button/Button.styles';

interface ProfitPopularityProps {
  data: ApolloClient.QueryResult<RecipesQuery, RecipesQueryVariables>;
  withCardLink?: boolean;
  showGraph?: boolean;
}

export const ProfitPopularity: FC<ProfitPopularityProps> = ({
  data,
  withCardLink = false,
  showGraph = false,
}) => {
  const { selectedVenueObject } = useContext(LayoutContext);
  const [showProfitPopularity, setShowProfitPopularity] = useState(showGraph);
  const pageWidthRef = useRef<HTMLDivElement>(null);

  const venueProfitTrend = useVenueProfitTrendQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const isLoading = venueProfitTrend.loading || data.loading;

  const handleCtaButtonClick = () => {
    setShowProfitPopularity(!showProfitPopularity);
  };

  const madeProgress = data.data?.venueRecipes.recipes.length !== 0;

  const ctaButton = madeProgress ? (
    <Button
      color="secondary"
      inversed={showProfitPopularity}
      onClick={handleCtaButtonClick}
    >
      {showProfitPopularity ? 'Hide Results' : 'See Results'}
    </Button>
  ) : (
    <AnchorButton href="add-recipe" color="primary">
      Start
    </AnchorButton>
  );

  const subHeading = (
    <Span fontSize="small" color="faded">
      {madeProgress ? 'Quickly' : 'Add recipes to quickly'} spot which recipes
      to promote and which need your attention by comparing each recipe's profit
      with how popular it is among your customers.
    </Span>
  );

  return (
    <Container>
      <Card
        ref={pageWidthRef}
        className="journeyCard"
        withCardLink={withCardLink}
      >
        <Header
          icon={<PieChart size="small" />}
          heading={'Profit vs Popularity'}
          subHeading={subHeading}
          button={ctaButton}
        />
        {madeProgress && showProfitPopularity && (
          <Content fullWidth>
            <DotsLoading
              isLoading={isLoading}
              size="large"
              lineHeight={10}
              color="default"
            />
            {!isLoading && venueProfitTrend.data && (
              <RecipesProfitPopularityGraph data={data} />
            )}
          </Content>
        )}
      </Card>
    </Container>
  );
};
