import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { theme } from '../../../../styles/theme';
import { Container } from './CardField.styles';

const CARD_OPTIONS: StripeCardElementOptions = {
  hidePostalCode: true,
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: theme.colors.secondary.default,
      color: '#000',
      fontWeight: '500',
      fontFamily: theme.fontFamily,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: theme.colors.text.faded },
      '::placeholder': { color: theme.colors.text.faded },
    },
    invalid: {
      iconColor: theme.colors.text.error,
      color: theme.colors.text.error,
    },
  },
};

export const CardField = () => {
  return (
    <Container className="FormGroup cardField">
      <div className="FormRow">
        <CardElement options={CARD_OPTIONS} />
      </div>
    </Container>
  );
};
