import React, { Fragment, useContext, useEffect } from 'react';
import { Container } from '../../LayoutSlider.styles';
import { LayoutContext } from '../../../../../../contexts/layoutContext';
import Brand from '../../../../../pages/brand/Brand';
import { isEmpty } from '../../../../../../utils/helper';
import Supplier from '../../../../../pages/supplier';
import Profile from '../../../../../pages/profile';
import Venue from '../../../../../pages/venue';
import Ingredient from '../../../../../pages/ingredient';
import UpdateNotifications from '../../../../../pages/updateNotifications';
import EditRecipe from '../../../../../pages/editRecipe';
import IngredientDetails from '../../../../../pages/ingredientDetails';
import EditSubRecipe from '../../../../../pages/editSubRecipe';
import EditRecipeTemplate from '../../../../../pages/editRecipeTemplate';
import { EditAccount } from '../../../../../pages/editAccount/EditAccount';
import { EditStocktakeSection } from '../../../../../pages/editStocktakeSection';
import Invoices from '../../../../../pages/invoices';

const LayoutSlider = () => {
  const { isOpen, sliderToggle, sliderPage, dispatch } = useContext(
    LayoutContext
  );

  useEffect(() => {
    if (!isEmpty(sliderPage)) {
      dispatch({ type: 'HIDE_TOOL_TIP' });
      dispatch({ type: 'TOGGLE_SLIDER' });
    }
  }, [sliderPage, dispatch]);

  return (
    <Container showSlider={sliderToggle} isOpen={isOpen}>
      {getSliderPage(sliderPage)}
    </Container>
  );
};

const getSliderPage = (sliderPage: string) => {
  switch (sliderPage) {
    case 'brand':
      return <Brand />;
    case 'supplier':
      return <Supplier />;
    case 'account':
      return <Profile />;
    case 'venue':
      return <Venue />;
    case 'ingredient':
      return <Ingredient />;
    case 'editIngredient':
      return <IngredientDetails />;
    case 'editRecipe':
      return <EditRecipe />;
    case 'editDuplicateRecipe':
      return <EditRecipe />;
    case 'editScaleRecipe':
      return <EditRecipe />;
    case 'editSubRecipe':
      return <EditSubRecipe />;
    case 'updateNotifications':
      return <UpdateNotifications />;
    case 'invoices':
      return <Invoices />;
    case 'editTemplateRecipe':
      return <EditRecipeTemplate />;
    case 'editAccount':
      return <EditAccount />;
    case 'editStocktakeSection':
      return <EditStocktakeSection />;
    default:
      return <Fragment />;
  }
};

export default LayoutSlider;
