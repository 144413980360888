import styled from 'styled-components/macro';
import { IHeadingsProps } from './header.types';
import { device } from '../../../../../styles/device';
import { Input } from '../../../../shared/formElements';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

const info =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20px' height='20px' viewBox='0 0 60 60'  style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath style='opacity:0.984' fill='%2310b561' d='M 25.5,2.5 C 51.9379,2.43538 63.1046,15.4354 59,41.5C 49.913,59.3667 35.913,64.7001 17,57.5C 5.16667,61.6667 1.33333,57.8333 5.5,46C -1.67997,24.871 4.98669,10.371 25.5,2.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23f7fcf9' d='M 28.5,15.5 C 35.4883,14.6478 37.8216,17.4811 35.5,24C 26.8377,26.5009 24.5043,23.6675 28.5,15.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23fbfdfc' d='M 29.5,28.5 C 31.9363,28.317 34.103,28.9837 36,30.5C 36.6667,35.8333 36.6667,41.1667 36,46.5C 33,49.1667 30,49.1667 27,46.5C 26.3333,41.1667 26.3333,35.8333 27,30.5C 27.9947,29.9341 28.828,29.2674 29.5,28.5 Z'/%3E%3C/g%3E%3C/svg%3E";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 30px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  .headerLabel {
    margin-top: 10px;
    @media ${device.tablet} {
      margin-top: 15px;
    }
  }
`;

export const TopSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const HeadingsItem = styled.li`
  display: grid;
  grid-template-rows: 40px auto;
  grid-gap: 10px;
  padding: ${({ theme }) => theme.layout.pagePadding};

  &.title {
    ${TopSection} {
      display: flex;
      align-items: center;
      .headerLabel {
        margin-top: 0;
        padding-right: 10px;
      }
    }
    @media ${device.tablet} {
      ${TopSection} {
        margin-top: 15px;
        align-items: flex-start;
      }
    }
  }

  h2 {
    @media ${device.tablet} {
      padding-right: 17px;
    }
  }

  .hasToolTipIcon {
    &:hover {
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        background-color: ${({ theme, icon = true }) =>
          icon ? 'transparent' : theme.colors.secondary.default};
        background-image: ${({ theme, icon = true }) =>
          icon
            ? `url(${`"${info}"`})`
            : `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
        width: ${({ icon = true }) => (icon ? '20px' : '8px')};
        height: ${({ icon = true }) => (icon ? '20px' : '8px')};
        border-radius: ${({ icon = true }) => (icon ? '0' : '8px')};
        margin-left: -4px;
        right: -18px;
        top: -18px;
      }
    }
  }
  @media ${device.laptopL} {
    padding: 0;
  }
`;

export const StyledInput = styled(Input)`
  .MuiTextField-root {
  }

  margin: 0;
  min-height: auto;

  .MuiOutlinedInput-root {
    height: 37px;

    .MuiOutlinedInput-input {
      padding: 0;
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
      }
    }

    &:hover,
    &:focus {
      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: ${({ border }) => (border === 'noBorder' ? '0' : '2px')};
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.default.default};
    }

    input {
      letter-spacing: normal;
    }
  }

  input {
    color: ${({ theme }) => theme.colors.text.black} !important;
    font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
    font-size: ${({ theme }) => theme.fontSize.heading} !important;
    font-family: ${({ theme }) => theme.fontFamily} !important;
    padding-right: 0;

    &::placeholder {
      font-weight: ${({ theme }) => theme.fontWeight.regular} !important;
    }
  }
`;

export const HeaderNumber = styled(Span)`
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 900px) {
    justify-content: flex-end;
  }
`;

export const Headings = styled.ul<IHeadingsProps>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${({ align }) =>
    align === 'right' ? 'auto auto auto' : 'auto'};

  ${HeadingsItem} {
    &:first-child {
      text-align: left;
      ${HeaderNumber} {
        justify-content: flex-start;
      }
      @media (min-width: 900px) {
        ${({ align }) => align === 'right' && 'text-align: right'};
        ${HeaderNumber} {
          justify-content: flex-end;
        }
      }
    }
    &:nth-child(2) {
      text-align: center;
      ${HeaderNumber} {
        justify-content: center;
      }
      @media (min-width: 900px) {
        ${({ align }) => align === 'right' && 'text-align: right'};
        ${HeaderNumber} {
          justify-content: flex-end;
        }
      }
    }

    &:last-child {
      ${HeaderNumber} {
        justify-content: flex-end;
      }
      ${({ align }) => align === 'right' && 'text-align: right'};
    }
  }
`;
