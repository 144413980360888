import { theme } from '../../styles/theme';

export const scrollItemToCenter = (
  id: number | string,
  fieldArrayRow?: boolean
) => {
  let el: HTMLElement | null = null;
  if (fieldArrayRow) {
    el = document.getElementById(`row${id}`);
  } else {
    if (typeof id === 'string') {
      el = document.getElementById(id);
    }
  }

  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const getWidthCutoffs = (appWidth: number) => ({
  isSmallerThanPhoneMed: appWidth !== 0 && appWidth < theme.mQ.mobileM,
  isSmallerThanPhoneLarge: appWidth !== 0 && appWidth < theme.mQ.mobileL,
  isSmallerThanTablet: appWidth !== 0 && appWidth < theme.mQ.tablet,
  isSmallerThanLaptop: appWidth !== 0 && appWidth < theme.mQ.laptop,
});
