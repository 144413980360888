import { isEmpty } from './objects';

export const getMetricData = (type, data) => {
  if (!isEmpty(data)) {
    const metric = data.filter((metric) => metric.type.toUpperCase() === type);
    if (!isEmpty(metric)) {
      return metric[0];
    }
  }
};
