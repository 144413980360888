import React, { FC, useContext, useRef } from 'react';
import { Container, Icon, Headings, Buttons, Heading } from './Header.styles';
import { IHeaderProps } from './header.types';
import { Span } from '../../typefaces/Typefaces.styles';
import { LayoutContext } from '../../../../contexts/layoutContext';
import useResizeDimensions from '../../../../utils/customHooks/useResizeDimensions';

const CardHeader: FC<IHeaderProps> = ({
  icon,
  heading,
  subHeading,
  error,
  errorText,
  button,
  toolTip,
  addPaddingBottom,
}) => {
  const {
    toolTips: { resize },
    dispatch,
  } = useContext(LayoutContext);
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const pageDimensions = useResizeDimensions(pageWidthRef);
  const headingsRef = useRef<HTMLDivElement>(null);
  const headingsDimensions = useResizeDimensions(headingsRef, resize);

  const onHeadingClick = () => {
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: toolTip?.heading,
        content: toolTip?.content,
        buttonText: 'Got it',
        pageWidth: pageDimensions.width,
        yAxis: headingsDimensions.top,
        xAxis: headingsDimensions.width,
      },
    });
  };

  return (
    <Container
      icon={!!icon}
      ref={pageWidthRef}
      addPaddingBottom={addPaddingBottom}
    >
      {icon && (
        <Icon
          onClick={toolTip && onHeadingClick}
          className={toolTip && 'hasToolTip'}
          error={error}
        >
          {icon}
        </Icon>
      )}
      <Headings
        ref={headingsRef}
        onClick={toolTip && onHeadingClick}
        className={toolTip && 'hasToolTip'}
      >
        <Heading color="grey" className="heading">
          {heading}
        </Heading>
        {subHeading && (
          <Span fontSize="small" color="faded">
            {subHeading}
          </Span>
        )}
        {errorText && (
          <Span fontSize="small" className="error">
            {errorText}
          </Span>
        )}
      </Headings>
      <Buttons>{button && button}</Buttons>
    </Container>
  );
};

export default CardHeader;
