import React, { FC } from 'react';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
import { H2, Span, Label } from '../../../../shared/typefaces/Typefaces.styles';
import {
  Container,
  HeaderContainer,
  Headings,
  HeadingsItem,
} from './Header.styles';
import { IStocktakeProps } from '../../stocktake.types';

const Header: FC<IStocktakeProps> = ({ selectedStocktake }) => {
  const sectionsCount =
    selectedStocktake.data?.stocktake.stocktake?.stocktakeSections.length;

  // @todo
  const reminaingItems = '-';
  const stocktakeLabel = !!selectedStocktake.data?.stocktake.stocktake?.status
    ? 'Stocktake'
    : '-';

  return (
    <Container>
      <HeaderContainer>
        <Headings>
          <HeadingsItem>
            <Label className="headerLabel" color="faded" fontSize="small">
              Status
            </Label>
            <H2>
              {`${capitalizeFirstLetter(
                selectedStocktake.data?.stocktake.stocktake?.status
              )} ${stocktakeLabel}` || '-'}
            </H2>
          </HeadingsItem>
          <HeadingsItem>
            <Label className=" headerLabel" color="faded" fontSize="small">
              Remaining Items To Count
            </Label>
            <Span fontSize="heading">{reminaingItems}</Span>
          </HeadingsItem>
          <HeadingsItem>
            <Label className=" headerLabel" color="faded" fontSize="small">
              Total Sections
            </Label>
            <Span fontSize="heading">{sectionsCount || '-'}</Span>
          </HeadingsItem>
        </Headings>
      </HeaderContainer>
    </Container>
  );
};

export default Header;
