import styled from 'styled-components/macro';
import { IContainerProps } from './layoutSlider.types';
import { device } from '../../../../styles/device';

export const Container = styled.div<IContainerProps>`
  display: ${({ showSlider }) => (showSlider ? 'block' : 'none')};
  position: fixed;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.default.default};
  background-color: ${({ theme }) => theme.colors.white.default};
  right: ${({ showSlider }) => (showSlider ? '0' : '-100%')};
  -webkit-transition: right 0.3s ease-out;
  -moz-transition: right 0.3s ease-out;
  -o-transition: right 0.3s ease-out;
  transition: right 0.3s ease-out;
  overflow-y: auto;
  width: calc(100% - 15px);
  z-index: ${({ theme, showAbove, showAsPriority }) =>
    showAbove
      ? theme.zIndex.aboveNav
      : showAsPriority
      ? theme.zIndex.priority
      : theme.zIndex.default};

  @media ${device.mobileL} {
    width: calc(100% - 100px);
  }
  @media ${device.tablet} {
    width: calc(100% - 50px - 100px);
    ${({ isOpen }) =>
      isOpen &&
      `
        width: calc(100% - 200px - 100px);
      `};
  }
`;
