import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const FoodTruck: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        // fill="#000000"
        stroke="none"
      >
        <path
          d="M1515 5105 c-27 -8 -53 -14 -56 -15 -3 0 -33 -18 -67 -39 -53 -35
-133 -119 -144 -152 -3 -8 -16 -5 -44 9 -90 46 -231 53 -326 16 -117 -46 -220
-150 -254 -258 l-17 -53 -72 -12 c-96 -16 -167 -52 -236 -121 -64 -64 -93
-113 -115 -203 -28 -108 -2 -250 62 -334 13 -17 24 -36 24 -40 0 -5 -19 -18
-43 -30 -60 -29 -127 -92 -164 -154 -67 -112 -64 -26 -61 -1600 l3 -1424 22
-41 c49 -92 144 -144 264 -144 l57 0 12 -52 c45 -210 237 -397 455 -443 325
-68 636 137 709 467 l6 28 988 0 987 0 14 -57 c62 -269 336 -470 615 -450 257
18 478 201 539 447 l14 55 119 6 c133 6 178 20 232 72 122 116 100 319 -43
403 l-45 26 0 423 c0 264 -4 443 -11 474 -6 28 -27 76 -47 107 -32 50 -68 79
-281 233 -135 97 -253 187 -263 201 -10 14 -82 230 -159 480 -151 491 -165
523 -247 584 -86 62 -111 66 -423 66 -257 0 -280 1 -285 18 -41 120 -117 219
-206 267 -29 15 -55 29 -57 31 -2 2 6 16 18 33 12 16 34 56 49 88 24 52 27 70
27 163 0 97 -2 110 -32 173 -65 136 -185 227 -332 250 -53 8 -71 14 -71 26 0
9 -13 43 -29 76 -97 204 -332 296 -541 212 l-60 -25 -29 42 c-102 148 -288
217 -456 171z m186 -170 c62 -21 115 -68 145 -127 l26 -51 -48 7 c-109 15
-245 17 -338 7 -54 -6 -102 -9 -105 -6 -12 13 46 101 87 129 77 55 153 68 233
41z m-605 -170 c27 -8 60 -23 73 -33 l23 -19 -63 -23 c-97 -36 -216 -92 -291
-138 l-68 -41 0 28 c0 64 49 151 107 191 72 49 137 60 219 35z m1200 0 c91
-27 159 -106 174 -202 8 -52 2 -53 -60 -13 -58 37 -238 122 -308 145 l-54 17
35 23 c65 45 133 54 213 30z m-415 -185 c378 -77 736 -330 878 -623 79 -160
73 -281 -18 -365 -69 -64 -237 -120 -445 -147 -360 -46 -1112 -41 -1421 11
-313 52 -445 142 -445 304 0 116 63 251 183 391 206 241 550 416 892 453 79 8
278 -4 376 -24z m-1271 -160 c10 -18 1 -30 -69 -101 -44 -44 -101 -111 -127
-149 -26 -39 -51 -70 -55 -70 -12 0 -22 86 -14 127 13 69 47 119 118 173 17
12 96 38 124 39 7 1 17 -8 23 -19z m2109 4 c99 -29 181 -135 181 -234 0 -49
-9 -90 -19 -90 -3 0 -26 30 -50 66 -25 36 -80 103 -123 147 -72 76 -88 103
-71 120 10 10 24 9 82 -9z m-2444 -786 c9 -29 27 -70 41 -90 l24 -38 0 -748
c0 -723 1 -749 20 -786 19 -39 19 -39 -1 -100 -45 -132 16 -267 144 -321 32
-13 170 -15 1121 -15 l1084 0 53 28 c119 62 172 204 119 320 -19 41 -19 43 0
85 19 40 20 73 20 790 0 740 0 748 21 776 11 15 31 56 43 91 l23 63 26 -35
c61 -79 57 26 57 -1543 l0 -1435 -770 0 -770 0 -6 28 c-30 136 -86 235 -187
329 -187 174 -464 204 -696 76 -131 -73 -249 -231 -282 -379 l-13 -56 -64 4
c-55 4 -68 8 -88 32 l-24 27 0 1392 c0 985 3 1404 11 1432 10 34 63 125 73
125 2 0 11 -24 21 -52z m3545 -249 c39 -24 56 -43 71 -79 10 -23 259 -828 259
-837 0 -2 -204 -2 -452 -1 l-453 3 -3 468 -2 467 272 0 c261 0 275 -1 308 -21z
m-3206 -45 c164 -57 334 -80 713 -96 326 -13 786 -1 993 27 127 17 278 53 358
84 l52 21 0 -665 0 -665 -1110 0 -1110 0 0 666 c0 631 1 666 18 659 9 -4 48
-18 86 -31z m3650 -1053 c4 -5 104 -79 224 -164 211 -151 263 -194 275 -228 5
-15 -11 -17 -151 -21 -137 -3 -162 -7 -198 -26 -91 -47 -143 -128 -144 -221 0
-102 43 -180 128 -233 34 -21 51 -23 207 -28 l170 -5 3 -173 2 -172 -130 0
-131 0 -47 39 c-103 86 -233 131 -375 131 -144 0 -263 -41 -375 -129 l-52 -41
-212 2 -213 3 -3 638 -2 637 509 0 c296 0 512 -4 515 -9z m-1558 -444 c31 -37
31 -81 -1 -112 l-24 -25 -1061 0 c-1042 0 -1060 0 -1080 20 -42 42 -32 111 20
137 20 10 241 12 1073 10 l1049 -2 24 -28z m2074 -222 l0 -85 -145 0 c-142 0
-147 1 -170 25 -44 43 -26 116 35 138 8 3 75 6 148 6 l132 1 0 -85z m-3708
-624 c74 -26 124 -58 181 -120 151 -162 144 -425 -15 -584 -169 -169 -429
-167 -601 4 -221 221 -134 592 163 699 74 26 197 27 272 1z m3210 -24 c231
-114 310 -402 167 -616 -148 -221 -453 -252 -648 -66 -176 167 -172 457 9 617
91 81 171 109 301 105 88 -2 103 -6 171 -40z m-732 -129 c-1 -2 -11 -39 -24
-83 l-24 -80 -131 -3 -131 -3 0 86 0 85 155 0 c85 0 155 -1 155 -2z m1370 -18
c28 -28 36 -69 19 -99 -24 -42 -55 -51 -158 -49 l-93 3 -18 65 c-9 36 -20 73
-24 83 -8 16 2 17 123 17 117 0 133 -2 151 -20z"
        />
        <path
          d="M1829 4337 c-8 -7 -115 -152 -237 -323 -186 -261 -222 -317 -222
-345 1 -41 17 -65 54 -78 56 -22 73 -5 311 329 193 270 225 320 225 351 0 46
-33 79 -81 79 -19 0 -41 -6 -50 -13z"
        />
        <path
          d="M1163 4090 c-22 -9 -283 -347 -305 -395 -27 -59 50 -131 110 -103 37
18 312 383 312 415 0 35 -16 61 -50 78 -33 17 -36 17 -67 5z"
        />
        <path
          d="M2273 4090 c-22 -9 -283 -347 -305 -395 -27 -59 50 -131 110 -103 27
13 284 341 303 387 27 66 -43 137 -108 111z"
        />
        <path
          d="M847 835 c-82 -29 -148 -113 -162 -207 -17 -113 64 -239 176 -272 65
-20 92 -20 155 -1 106 31 174 127 174 244 0 81 -25 136 -85 189 -69 60 -170
79 -258 47z m148 -180 c41 -40 33 -105 -17 -131 -58 -30 -122 7 -122 71 0 76
87 113 139 60z"
        />
        <path
          d="M3982 824 c-188 -94 -186 -356 3 -457 63 -33 164 -30 232 7 84 46
133 132 133 230 -1 183 -202 303 -368 220z m173 -169 c35 -34 33 -78 -4 -116
-18 -17 -40 -29 -56 -29 -32 0 -85 49 -85 80 0 26 25 77 42 83 34 14 80 6 103
-18z"
        />
      </g>
    </IconStyled>
  );
};

export default FoodTruck;
