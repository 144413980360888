import React, { useContext, useState } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  AccountType,
  IngredientConfirmationState,
  useIngredientsWithStateQuery,
  useBulkVerifyIngredientsMutation,
  useIgnoreIngredientMutation,
} from '../../../../../generated/graphql';
import { Button } from '../../../../shared/button';
import { Card, Content, Header } from '../../../../shared/card';
import { Apple } from '../../../../shared/icons';
import { Label } from '../../../../shared/typefaces/Typefaces.styles';
import { Col, HeaderRow, Table } from './VerifyIngredients.styles';
import VerifyIngredientsRow from './VerifyIngredientsRow';

export const VerifyIngredients = () => {
  const { account, dispatch } = useContext(LayoutContext);

  const [selectedIngredients, setSelectedIngredients] = useState<Set<string>>(
    new Set()
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [bulkVerifyIngredients] = useBulkVerifyIngredientsMutation();
  const [ignoreIngredient] = useIgnoreIngredientMutation();

  const unverifiedIngredients = useIngredientsWithStateQuery({
    variables: {
      input: {
        confirmationState: IngredientConfirmationState.Unverified,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (unverifiedIngredients.loading || !unverifiedIngredients.data) {
    return null;
  }

  if (!account || account.type !== AccountType.RecipeRevenue) {
    return null;
  }

  const handleEditIngredient = (
    ingredientId: string,
    ingredientProductId: string
  ) => {
    dispatch({
      type: 'SELECT_INGREDIENT',
      payload: ingredientId,
    });
    dispatch({
      type: 'SET_INGREDIENT_USER_EVENT',
      payload: 'edit',
    });
    dispatch({
      type: 'SELECT_INGREDIENT_PRODUCT',
      payload: ingredientProductId,
    });
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });
  };

  const handleIgnoreIngredient = async (id: string) => {
    setIsSubmitting(true);

    // Deselect ingredient if selected
    if (selectedIngredients.has(id)) {
      handleToggleSelectIngredient(id);
    }

    await ignoreIngredient({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ['IngredientsWithState'],
    });
    setIsSubmitting(false);
  };

  const handleToggleSelectIngredient = (id: string) =>
    setSelectedIngredients((prevSet) => {
      const newSet = new Set(prevSet);
      if (prevSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await bulkVerifyIngredients({
      variables: {
        input: {
          ingredientIds: Array.from(selectedIngredients),
        },
      },
      refetchQueries: ['IngredientsWithState'],
    });
    setIsSubmitting(false);

    setSelectedIngredients(new Set());
  };

  const { ingredients } = unverifiedIngredients.data.ingredientsWithState;

  const isSubmitEnabled = selectedIngredients.size > 0 && !isSubmitting;

  return (
    <Card withCardLink>
      <Header
        icon={<Apple size="small" />}
        heading="Verify Ingredients"
        subHeading="View ingredients submitted by users before adding to public list"
        button={
          <Button
            onClick={handleSubmit}
            color={isSubmitEnabled ? 'primary' : 'default'}
            disabled={!isSubmitEnabled}
          >
            {isSubmitting
              ? 'Submitting...'
              : 'Mark Selected Ingredients as Verified'}
          </Button>
        }
      />
      <Content fullWidth>
        <Table>
          <HeaderRow>
            <Col /> {/* toggle column */}
            <Col>
              <Label>Ingredient Name</Label>
            </Col>
            <Col>
              <Label>Metric Weight</Label>
            </Col>
            <Col>
              <Label>Brand</Label>
            </Col>
            <Col>
              <Label>Avg Cost 100g</Label>
            </Col>
            <Col />
            {/* menu column */}
          </HeaderRow>
          {ingredients?.map((ingredient) => (
            <VerifyIngredientsRow
              key={ingredient.id}
              ingredient={ingredient}
              handleToggleSelectIngredient={() =>
                handleToggleSelectIngredient(ingredient.id)
              }
              handleEditIngredient={handleEditIngredient}
              handleIgnoreIngredient={handleIgnoreIngredient}
              isSelected={selectedIngredients.has(ingredient.id)}
            />
          ))}
        </Table>
      </Content>
    </Card>
  );
};

export default VerifyIngredients;
