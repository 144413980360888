import styled from 'styled-components/macro';
import { ILoadingProps } from './logoLoading.types';

export const Loading = styled.div<ILoadingProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  #leaf {
    animation: leaf 2s ease-out infinite;
    transform-origin: 255px 300px;
  }

  @keyframes leaf {
    from {
      transform: scale(0.4);
    }

    to {
      transform: scale(1);
    }
  }
`;
