import { ISizeOptions } from '../../components/pages/ingredient/Ingredient.types';
import {
  IngredientQuery,
  IngredientQueryVariables,
} from '../../generated/graphql';
import * as ApolloClient from '@apollo/client';
import { isEmpty } from '.';

export interface GetSelectableSizesResponse {
  selectableSizes: ISizeOptions[];
  costs: Record<string, number>;
}

export const getSelectableSizes = (
  inputBrand: string,
  inputSupplier: string,
  ingredient: ApolloClient.QueryResult<
    IngredientQuery,
    IngredientQueryVariables
  >
): GetSelectableSizesResponse => {
  let selectableSizes: ISizeOptions[] = [];
  let costs: Record<string, number> = {};

  if (!isEmpty(inputBrand) || !isEmpty(inputSupplier)) {
    if (!isEmpty(inputBrand) && !isEmpty(inputSupplier)) {
      selectableSizes = ingredient!
        .data!.ingredient!.ingredient!.ingredientProducts.filter(
          (product) =>
            inputBrand === product.brandId &&
            inputSupplier === product.supplierId
        )
        .filter((product) => {
          const key = product.size?.productGrams;
          if (key) {
            if (costs[key]) {
              costs[key] = (costs[key] + product.size!.productCost) / 2;
              return null;
            } else {
              costs[key] = product.size!.productCost;
              return product;
            }
          }
          return null;
        })
        .map((product) => {
          return {
            label: `${product.size!.productGrams}g`,
            value: product.size!.productGrams.toString(),
          };
        });
    } else if (!isEmpty(inputBrand) && isEmpty(inputSupplier)) {
      selectableSizes = ingredient!
        .data!.ingredient!.ingredient!.ingredientProducts.filter(
          (product) => inputBrand === product.brandId
        )
        .filter((product) => {
          const key = product.size?.productGrams;
          if (key) {
            if (costs[key]) {
              costs[key] = (costs[key] + product.size!.productCost) / 2;
              return null;
            } else {
              costs[key] = product.size!.productCost;
              return product;
            }
          }
          return null;
        })
        .map((product) => {
          return {
            label: `${product.size!.productGrams}g`,
            value: product.size!.productGrams.toString(),
          };
        });
    } else if (isEmpty(inputBrand) && !isEmpty(inputSupplier)) {
      selectableSizes = ingredient!
        .data!.ingredient!.ingredient!.ingredientProducts.filter(
          (product) => inputSupplier === product.supplierId
        )
        .filter((product) => {
          const key = product.size?.productGrams;
          if (key) {
            if (costs[key]) {
              costs[key] = (costs[key] + product.size!.productCost) / 2;
              return null;
            } else {
              costs[key] = product.size!.productCost;
              return product;
            }
          }
          return null;
        })
        .map((product) => {
          return {
            label: `${product.size!.productGrams}g`,
            value: product.size!.productGrams.toString(),
          };
        });
    }
  } else {
    selectableSizes = ingredient!
      .data!.ingredient!.ingredient!.ingredientProducts.filter((product) => {
        const key = product.size?.productGrams;
        if (key) {
          if (costs[key]) {
            costs[key] = (costs[key] + product.size!.productCost) / 2;
            return null;
          } else {
            costs[key] = product.size!.productCost;
            return product;
          }
        }
        return null;
      })
      .map((product) => {
        return {
          label: `${product.size!.productGrams}g`,
          value: product.size!.productGrams.toString(),
        };
      });
  }

  if (isEmpty(selectableSizes)) {
    selectableSizes = [{ label: '1000g', value: '1000' }];
  }

  return {
    selectableSizes,
    costs,
  };
};
