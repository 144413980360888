import styled from 'styled-components/macro';
import { H4 } from '../typefaces/Typefaces.styles';

interface IContainerProps {
  type: 'error' | 'success';
  addMargin?: boolean;
}

export const Container = styled.div<IContainerProps>`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.colors.white.default};
  margin: ${({ addMargin }) => (addMargin ? '20px' : 0)};
  border: 1px solid
    ${({ theme, type }) =>
      type === 'success'
        ? theme.colors.secondary.default
        : theme.colors.error.default};
  border-radius: ${({ theme }) => theme.layout.radiusTiny};

  .error {
    fill: ${({ theme }) => theme.colors.error.default};
    margin-right: 10px;
  }

  .text {
    font-size: ${({ theme }) => theme.fontSize.default};
    color: ${({ theme }) => theme.colors.text.grey};
    line-height: ${({ theme }) => theme.lineHeight.large};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled(H4)`
  font-size: ${({ theme }) => theme.fontSize.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 5px !important;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary.default};
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
