import React from 'react';
import { LayoutPage } from '../../shared/layout';
import { Button } from '../../shared/button';

const Users = () => {
  return (
    <LayoutPage
      align="center"
      withLine
      heading="Users"
      subHeading="Overview on business progress & activity"
      actionArray={[
        <Button color="primary" onClick={null}>
          Users
        </Button>,
      ]}
    >
      <div>Users page</div>
    </LayoutPage>
  );
};

export default Users;
