import { useContext, useState } from 'react';
import { LayoutContext } from '../../contexts/layoutContext';

export const useChatbox = () => {
  const {
    help: { showFBChatbox, showHelpModal },
    dispatch,
  } = useContext(LayoutContext);
  const [chatboxLoading, setChatboxLoading] = useState(false);

  const handleShowChatbox = () => {
    if (!showFBChatbox) {
      dispatch({ type: 'TOGGLE_FB_CHATBOX' });
      setChatboxLoading(true);
      setTimeout(() => {
        setChatboxLoading(false);
        dispatch({ type: 'CLOSE_OVERLAY' });
        if (showHelpModal) {
          dispatch({
            type: 'TOGGLE_HELP_MODAL',
          });
        }
      }, 1500);
    }
  };

  return {
    chatboxLoading,
    handleShowChatbox,
    setChatboxLoading,
  };
};
