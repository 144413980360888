import * as ApolloClient from '@apollo/client';
import { ChangeEvent } from 'react';
import { ArrayField } from 'react-hook-form';
import {
  ItemDeleteType,
  RecipeQuery,
  RecipeQueryVariables,
  VenueQuery,
  VenueQueryVariables,
} from '../../../generated/graphql';
import { ObjUnion } from '../../../utils/helper/objects';
import * as Yup from 'yup';
import {
  validateNumberValues,
  validatePositiveNumberValues,
} from '../../../utils/helper/validation';

const RECIPE_ITEM_FORM_SCHEMA = Yup.object({
  type: Yup.mixed().oneOf([
    'INGREDIENT',
    'RECIPE',
    'PROCESS_TIME',
    'STAFF_TIME',
    'DELETE_ITEM',
  ]),

  __typename: Yup.mixed().oneOf([
    'RecipeIngredient',
    'RecipeTimeItem',
    'RecipeItemDeleted',
  ]),

  ingredient: Yup.object().when('__typename', {
    is: 'RecipeIngredient',
    then: (s: Yup.ObjectSchema) =>
      s.required('Ingredient is required').typeError('Ingredient is required'),
    otherwise: (s: Yup.ObjectSchema) => s.notRequired(),
  }),

  description: Yup.string().when('__typename', {
    is: 'RecipeTimeItem',
    then: (s: Yup.StringSchema) =>
      s
        .required('Description is required')
        .typeError('Description is required'),
    otherwise: (s: Yup.StringSchema) => s.notRequired(),
  }),

  quantity: Yup.string().when('type', {
    is: 'DELETE_ITEM',
    then: (s: Yup.StringSchema) => s.notRequired(),
    otherwise: (s: Yup.StringSchema) =>
      s
        .required('Quantity is required')
        .typeError('Quantity is required')
        .test('numberTest', 'Quantity is not a number', (value) =>
          validateNumberValues(value)
        )
        .test(
          'positiveNumberTest',
          'Number must be greater than zero',
          (value) => validatePositiveNumberValues(value)
        ),
  }),

  unit: Yup.string().when('type', {
    is: 'DELETE_ITEM',
    then: (s: Yup.StringSchema) => s.notRequired(),
    otherwise: (s: Yup.StringSchema) => s.required('Is required'),
  }),
});

export const RECIPE_FORM_SCHEMA = Yup.object().shape({
  displayName: Yup.string().required('Recipe Name is required'),

  serves: Yup.string()
    .required('Serves is required')
    .typeError('Serves is required')
    .test('numberTest', 'Serves is not a number', (value) =>
      validateNumberValues(value)
    )
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),

  items: Yup.array()
    .of(RECIPE_ITEM_FORM_SCHEMA)
    .required(
      'Recipe must have at least one recipe item, try adding an ingredient'
    )
    .min(
      1,
      'Recipe must have at least one recipe item, try adding an ingredient'
    ),
});

export type RecipeIngredientFormState = {
  // client-side generated uuid if it's a new item, otherwise use recipeItemId
  rowKey: string;

  type: 'INGREDIENT';
  __typename: 'RecipeIngredient';

  recipeItemId: string;
  ingredient: null | {
    label: string;
    value: string;
    extra: string;
  };
  quantity: string;
  unit: string;
  order: number;
  metrics:
    | Array<{
        __typename?: 'Metric' | undefined;
        id: string;
        type: string;
        grams: number;
      }>
    | undefined;
};

export type RecipeRecipeItemFormState = {
  // client-side generated uuid if it's a new item, otherwise use recipeItemId
  rowKey: string;
  type: 'RECIPE';
  __typename: 'RecipeIngredient';
  order: number;
  recipeItemId: string;
  ingredient: null | {
    label: string;
    value: string;
    extra: string;
  };
  quantity: string;
  unit: string;
};

export type RecipeProcessTimeFormState = {
  // client-side generated uuid if it's a new item, otherwise use recipeItemId
  rowKey: string;
  order: number;
  type: 'PROCESS_TIME';
  __typename: 'RecipeTimeItem';
  description: string;
  quantity: string;
  unit: string;
  recipeItemId: string | undefined;
  staffTime: boolean | undefined;
};

export type RecipeStaffTimeFormState = {
  // client-side generated uuid if it's a new item, otherwise use recipeItemId
  rowKey: string;
  order: number;
  type: 'STAFF_TIME';
  __typename: 'RecipeTimeItem';
  description: string;
  quantity: string;
  unit: string;
  recipeItemId: string | undefined;
  staffTime: boolean | undefined;
};

export type RecipeDeleteItemFormState = {
  // client-side generated uuid if it's a new item, otherwise use recipeItemId
  rowKey: string;
  order: number;
  type: 'DELETE_ITEM';
  __typename: 'RecipeItemDeleted';
  recipeItemId: string | undefined;
  deleteType: ItemDeleteType;
};

export type RecipeFormItem = ObjUnion<
  | RecipeIngredientFormState
  | RecipeProcessTimeFormState
  | RecipeStaffTimeFormState
  | RecipeDeleteItemFormState
  | RecipeRecipeItemFormState
>;

export type RecipeFormState = {
  /**
   * only used if we're creating a new recipe
   */
  displayName: string;
  serves: number | '';
  recipeWastage: number | '';
  items: RecipeFormItem[];
};

export type RecipeFromQuery = Exclude<
  Exclude<RecipeQuery['recipe']['recipe'], undefined>,
  null
>;

export type RecipeVersionFromQuery = RecipeFromQuery['recipeVersions'][0];

export interface IRecipeFormProps {
  recipe?: RecipeFromQuery;
  recipeVersion?: RecipeVersionFromQuery;
  formOnly?: boolean;
  calculateRecipe?: boolean;
}

export interface IIngredientState {
  id: string;
  index: string;
  name: string;
  ingredientAdded: boolean;
  currentFields: Partial<ArrayField<Record<string, any>, 'id'>>[];
  updatedItemsArray: any[];
}

export interface IHeaderProps {
  data?: ApolloClient.QueryResult<RecipeQuery, RecipeQueryVariables>;
}
export interface ICompareProps {
  data?: ApolloClient.QueryResult<RecipeQuery, RecipeQueryVariables>;
  venue?: ApolloClient.QueryResult<VenueQuery, VenueQueryVariables>;
  updateRecipeOnSelect: (recipeVersionId: string) => void;
  updateOriginalRecipeFromTemplate: () => void;
}

export interface ICompareStateProps {
  originalVersionId: string;
  originalVersionServeProfit?: number;
}

export interface IRecipeFormValues {
  name?: string;
  serves?: number;
  pricePerServe?: number;
  weeklySales?: number;
  items: IProcessItemObject[];
}

export interface IRecipeFormValuesErrors {
  name?: string;
  serves?: string;
  pricePerServe?: string;
  weeklySales?: string;
}

export interface IRecipeFormValuesTouched {
  name?: boolean;
  serves?: boolean;
  pricePerServe?: boolean;
  weeklySales?: boolean;
}

export interface OtherProps {
  title?: string;
  onChange?: (name: string, value: string) => void;
}

export interface IFormProps {
  values: IRecipeFormValues;
  onBlur?: (e: ChangeEvent) => void;
  onChange?: (e: ChangeEvent) => void;
  onFocus?: (e: ChangeEvent) => void;
  errors: IRecipeFormValuesErrors;
  touched: IRecipeFormValuesTouched;
}
export interface IProcessItemObject {
  item: string;
}

export interface IRecipeAsIngredientsProps {
  id: string;
  unit: string;
  quantity: number;
  recipe: {
    __typename: string;
    displayName: string;
    id: string;
    recipeProfit: number;
    recipeRevenue: number;
    serves: number;
    totalGrams: number;
  };
  order: number;
}

export interface IRecipeIngredientsProps {
  id: string;
  unit: string;
  quantity: number;
  ingredient: {
    id: string;
    displayName: string;
    __typename: string;
  };
  order: number;
}

export interface IRecipeTimeItemProps {
  id: string;
  unit: string;
  quantity: number;
  description: string;
  staffTime: boolean;
  order: number;
}
