import styled from 'styled-components/macro';
import TextField from '@material-ui/core/TextField';
import { IStyledInputProps } from './input.types';
import { commonStyles } from '../FormElements.styles';

export const Container = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.form.input.marginTop};
  position: relative;
  min-height: 55px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &.hiddenInput {
    display: none;
    input[type='hidden'] {
      display: none;
    }
  }
`;

export const StyledInput = styled(TextField)<IStyledInputProps>`
  width: 100%;

  .MuiOutlinedInput-root {
    font-size: ${({ height, theme }) =>
      height === 'large' ? theme.fontSize.large : theme.fontSize.default};
    height: ${({ height, multiline }) =>
      multiline
        ? 'auto'
        : height === 'large'
        ? commonStyles.largeHeight
        : commonStyles.defaultHeight};
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid
        ${({ theme, border, disabled }) =>
          border === 'noBorder' || disabled
            ? 'transparent'
            : theme.colors.default.faded};
      border-radius: 5px;
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 2px solid ${({ theme }) => theme.colors.default.default};
      }
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: 2px solid
          ${({ theme, disabled }) =>
            disabled ? 'transparent' : theme.colors.default.default};
      }
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: red;
      }
    }
    .MuiOutlinedInput-input {
      padding-left: ${({ startsymbol }) => !!startsymbol && '18px'};
    }
    &.MuiOutlinedInput-multiline {
      padding: 14.5px 14px;
    }
  }

  .MuiInputLabel-outlined {
    font-size: ${({ height, theme }) =>
      height === 'large' ? theme.fontSize.large : theme.fontSize.default};
    color: ${({ theme, textColor }) =>
      textColor === 'black'
        ? theme.colors.text.black
        : theme.colors.grey.faded};
    transform: ${({ height }) =>
        height === 'large' ? 'translate(15px, 17px)' : 'translate(15px, 15px)'}
      scale(1);
    padding: 0;

    &.MuiInputLabel-shrink {
      transform: ${({ height }) =>
        height === 'large'
          ? 'translate(15px, -9px) scale(0.5)'
          : 'translate(15px, -9px) scale(0.75)'};
    }
    &.Mui-focused {
      color: ${(props) => props.theme.colors.text.grey};
      transform: ${({ height }) =>
        height === 'large'
          ? 'translate(15px, -9px) scale(0.5)'
          : 'translate(15px, -9px) scale(0.75)'};
    }
    &.Mui-error {
      color: red;
    }
  }

  .MuiFormHelperText-contained {
    color: ${({ theme }) => theme.colors.secondary.default};
    margin: 4px 15px;
  }

  input {
    &::-internal-autofill-selecte {
      background-color: none;
    }
    color: ${({ theme }) => theme.colors.text.grey};
    text-align: ${({ align }) => align};
    ::placeholder {
      color: ${({ theme }) => theme.colors.grey.faded};
      opacity: 1 !important;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.grey.dark};
    }
  }

  .Mui-focused {
    input {
      color: ${({ theme }) => theme.colors.text.default};
    }
  }

  .Mui-error {
    color: red;
  }
`;

export const Icon = styled.section`
  position: absolute;
`;
