import React, { FC, useState } from 'react';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Card, Content, Header } from '../../../../shared/card';
import { Apple } from '../../../../shared/icons';
import { HighlightsItem, HighlightsRow } from './Users.styles';
import { Button } from '../../../../shared/button';
import { IStatsProps } from '../dashboard.types';

export const Users: FC<IStatsProps> = ({ stats }) => {
  const [showIngredientList, setShowIngredientList] = useState(false);

  const ctaButtons = (
    <Button
      color="default"
      inversed
      onClick={() => setShowIngredientList(!showIngredientList)}
    >
      Monthly Stats
    </Button>
  );

  return (
    <Card withCardLink>
      <Header
        icon={<Apple size="small" />}
        heading="Users"
        subHeading={'Total Users: ' + stats?.totalUsers}
        button={ctaButtons}
      />
      <Content fullWidth>
        <HighlightsRow>
          <HighlightsItem>
            <Span>{stats?.totalGuestUsers}</Span>
            <Span>Guest Users</Span>
          </HighlightsItem>
          <HighlightsItem>
            <Span>{stats?.totalRegisteredUsers}</Span>
            <Span>Users</Span>
          </HighlightsItem>
          <HighlightsItem>
            <Span>{stats?.totalGoalUsers}</Span>
            <Span>Goal Users</Span>
          </HighlightsItem>
        </HighlightsRow>
      </Content>
    </Card>
  );
};

export default Users;
