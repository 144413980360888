import React, { FC } from 'react';
import { H3 } from '../../typefaces/Typefaces.styles';
import { Container, Image, Content } from './SideContent.styles';
import { ISideContentProps } from './sideContent.types';

const SideContent: FC<ISideContentProps> = ({
  imageSide,
  image,
  children,
  className,
  text,
}) => {
  return (
    <Container imageSide={imageSide} className={className}>
      <Image>
        <img src={image} alt="Side Content" />
        <H3 className="text">{text}</H3>
      </Image>
      <Content imageSide={imageSide}>{children}</Content>
    </Container>
  );
};

export default SideContent;
