export const checkNumberInput = (e: any) => {
  const { keyCode, ctrlKey, metaKey } = e;

  // Ctrl+C or Cmd+C pressed?
  if ((ctrlKey || metaKey) && keyCode === 67) {
    return;
  }

  // Ctrl+V or Cmd+V pressed?
  if ((ctrlKey || metaKey) && keyCode === 86) {
    return;
  }

  // Ctrl+X or Cmd+X pressed?
  if ((ctrlKey || metaKey) && keyCode === 88) {
    return;
  }

  if (
    (keyCode >= 48 && keyCode <= 57) || // 0-9
    keyCode === 8 || // backspace
    keyCode === 46 || // backspace
    keyCode === 9 || // tab
    keyCode === 37 || // %
    keyCode === 39 || // '
    keyCode === 190 // .
  ) {
    return;
  }
  return e.preventDefault();
};
