import styled from 'styled-components/macro';

export const Container = styled.div`
  .journeyCard {
    padding-bottom: 30px;
  }
`;

export const Inner = styled.div`
  margin-top: 25px;
  margin-left: 75px;
`;

export const Recommendation = styled.div`
  margin-top: 20px;

  .recipeTitle {
    margin-top: 5px;
    display: block;
  }

  .recipeLink {
    cursor: pointer;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.text.faded};
    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.text.default};
    }
  }
`;
