import { RecipeFormState } from '../../components/shared/recipe/recipe.types';
import { CreateRecipeInput, UpdateRecipeInput } from '../../generated/graphql';
import { getRecipeData } from '../clientServerShared/getRecipeData';
import { IRecipeDataResult } from '../clientServerShared/getRecipeData.types';
import {
  IRecipeData,
  IRecipeVersionData,
  IVenueData,
} from '../clientServerShared/sharedTypes';

import { convertFormItemsToInput } from './convertFormItemsToCreateInput';
import { convertFormItemsToRecipeVersion } from './convertFormItemsToRecipeVersion';
import { capitalizeFirstLetter } from './strings';
import { getTodaysDate } from './time';

export function makeUpdateRecipeInput({
  recipe,
  recipeVersion,
  formData,
  venue,
  duplicateRecipe,
  scaleRecipe,
  totalActiveSeconds,
  recipeActiveSeconds,
}: {
  recipe: IRecipeData;
  recipeVersion: IRecipeVersionData;
  formData: RecipeFormState;
  venue: IVenueData & { id: string };
  duplicateRecipe: boolean | undefined;
  scaleRecipe: boolean | undefined;
  totalActiveSeconds: number;
  recipeActiveSeconds: number;
}): { input: UpdateRecipeInput; recipeData: IRecipeDataResult | undefined } {
  const displayName = capitalizeFirstLetter(formData.displayName);
  const wastage = Number(formData.recipeWastage);
  const serves = Number(formData.serves);
  const newUpdateVersion = duplicateRecipe || scaleRecipe;

  const updatedVersion = {
    ...recipeVersion,
    id: newUpdateVersion ? '' : recipeVersion.id,
    displayName: formData.displayName,
    serves: serves,
    recipeWastage: wastage,
    selected: true,
    ...convertFormItemsToRecipeVersion(formData.items, venue),
  };

  // Need to update recipe versions to deselect duplicate or scaled version
  const updatedRecipeVersions = newUpdateVersion
    ? recipe.recipeVersions.map((v) => {
        return {
          ...v,
          selected: false,
        };
      })
    : recipe.recipeVersions;

  const recipeData = getRecipeData({
    recipe: {
      ...recipe,
      recipeVersions: !updatedVersion.id
        ? updatedRecipeVersions.concat(updatedVersion)
        : recipe.recipeVersions.map((version) =>
            version.id === updatedVersion.id ? updatedVersion : version
          ),
    },
    venue,
    convertTimeToSeconds: false,
  });

  const input: UpdateRecipeInput = {
    id: recipe.id,
    active: true,
    venueId: venue.id,
    weeklySalesPerServe: recipeData?.weeklySalesPerServe || 0,
    salesPricePerServe: recipeData?.salesPricePerServe || 0,
    recipeRevenue: recipeData?.recipeRevenue || 0,
    recipeProfit: recipeData?.recipeProfit || 0,
    recipeProfitIncreasePerServe: recipeData?.profitIncreasePerServe || 0,
    highestRecipeProfitIncreasePerServe:
      recipeData?.highestProfitIncreasePerServe || 0,
    foodCostPercentage: recipeData?.foodCostPercentage || 0,
    ingredientCost: recipeData?.foodCost,
    staffCost: recipeData?.totalStaffTimeCost,
    totalCost: recipeData?.recipeCost,
    serves: recipeData?.serves,
    totalGrams: recipeData?.totalGrams || 0,
    totalActiveSeconds,
    recipeActiveSeconds,
    recipeVersion: {
      id: duplicateRecipe || scaleRecipe ? '' : recipeVersion.id,
      recipeId: recipe.id,
      displayName: scaleRecipe
        ? `${displayName} - scaled on ${getTodaysDate()}`
        : displayName,
      serves: serves,
      recipeWastage: wastage,
      selected: true,
      active: true,
      isScaled: scaleRecipe || false,
      totalStaffTime: recipeData?.totalStaffTime || 0,
      totalTime: recipeData?.totalTime || 0,
      totalGrams: recipeData?.totalGrams || 0,
      items: convertFormItemsToInput(formData.items),
    },
  };

  return { input, recipeData };
}

export function makeCreateRecipeInput({
  formData,
  totalActiveSeconds,
  recipeActiveSeconds,
  venue,
}: {
  formData: RecipeFormState;
  totalActiveSeconds: number;
  recipeActiveSeconds: number;
  venue: IVenueData & { id: string };
}): { input: CreateRecipeInput; recipeData: IRecipeDataResult | undefined } {
  const displayName = capitalizeFirstLetter(formData.displayName);
  const wastage = Number(formData.recipeWastage);
  const serves = Number(formData.serves);
  const recipeData = getRecipeData({
    recipe: {
      id: '',
      createdAt: 0,
      displayName: capitalizeFirstLetter(formData.displayName),
      recipeVersions: [
        {
          displayName,
          id: '',
          original: true,
          serves: serves,
          recipeWastage: wastage,
          selected: true,
          active: true,
          ...convertFormItemsToRecipeVersion(formData.items, venue),
        },
      ],
      salesPricePerServe: 0,
      weeklySalesPerServe: 0,
      originalRecipeProfit: 0,
    },
    venue,
    convertTimeToSeconds: true,
  });

  const input: CreateRecipeInput = {
    id: '',
    displayName: displayName,
    weeklySalesPerServe: 0,
    salesPricePerServe: 0,
    active: true,
    venueId: venue.id,

    recipeRevenue: 0,
    recipeProfit: 0,
    foodCostPercentage: 0,
    serves: recipeData?.serves,
    totalGrams: recipeData?.totalGrams || 0,

    totalActiveSeconds,
    recipeActiveSeconds,

    recipeVersion: {
      id: '',
      recipeId: '',
      displayName: formData.displayName,
      serves: Number(formData.serves),
      original: true,
      recipeWastage: wastage,
      selected: true,
      totalStaffTime: recipeData?.totalStaffTime || 0,
      totalTime: recipeData?.totalTime || 0,
      totalGrams: recipeData?.totalGrams || 0,
      active: true,
      items: convertFormItemsToInput(formData.items),
    },
  };

  return { input, recipeData };
}
