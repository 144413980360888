import React, { FC, useContext, useRef, useState } from 'react';
import * as S from './RowMenu.styles';
import { Dots } from '../../../../shared/icons';
import { DotsMenuWrapper } from '../items/Items.styles';
import { DotsMenu } from '../../../../shared/dotsMenu';
import {
  LinkSpanStyled,
  MenuItem,
} from '../../../../shared/dotsMenu/DotsMenu.styles';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

interface IRowMenuProps {
  ingredientId?: string;
  ingredientProductId?: string;
  disabled?: boolean;
  handleItemDelete: () => void;
}

export const RowMenu: FC<IRowMenuProps> = ({
  ingredientId,
  ingredientProductId,
  disabled,
  handleItemDelete,
}) => {
  const { account, user, dispatch } = useContext(LayoutContext);
  const rowMenuRef = useRef<HTMLDivElement>(null);
  const [rowMenu, setRowMenu] = useState(false);

  const handleDotsClick = () => {
    setRowMenu(!rowMenu);
  };

  const handleEditIngredient = () => {
    dispatch({
      type: 'SELECT_INGREDIENT',
      payload: ingredientId,
    });
    dispatch({
      type: 'SET_INGREDIENT_USER_EVENT',
      payload: 'edit',
    });
    dispatch({
      type: 'SELECT_INGREDIENT_PRODUCT',
      payload: ingredientProductId,
    });
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    }
  };

  if (disabled) {
    return null;
  }

  return (
    <S.Container onClick={handleDotsClick} ref={rowMenuRef}>
      <Dots color="grey" size="smaller" />
      <DotsMenuWrapper>
        {rowMenu && (
          <DotsMenu
            top={-72}
            right={23}
            menuItemCount={2}
            className="rowDotsMenu"
            pointerRight
          >
            {ingredientId && ingredientProductId && (
              <MenuItem onClick={() => handleEditIngredient()}>
                <LinkSpanStyled color="grey">Update Pricing</LinkSpanStyled>
              </MenuItem>
            )}
            <MenuItem onClick={handleItemDelete}>
              <LinkSpanStyled color="faded">Delete</LinkSpanStyled>
            </MenuItem>
          </DotsMenu>
        )}
      </DotsMenuWrapper>
    </S.Container>
  );
};
