import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Span } from '../typefaces/Typefaces.styles';

const menuItemHeight = 40;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  height: ${menuItemHeight}px;
  padding: 0 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.background};
  cursor: pointer;

  &:first-child {
    border-top: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};

    &:first-child {
      border-radius: 10px 10px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 10px 10px;
    }
  }
`;

export const Container = styled.ul<{
  top?: number;
  right?: number;
  menuItemCount?: number;
  pointerRight?: boolean;
}>`
  position: absolute;
  right: ${(props) => props.right || 5}px;
  top: ${(props) => props.top || 5}px;
  background-color: #ffffff;
  width: 250px;
  height: auto;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadow.box};
  z-index: ${({ theme }) => theme.zIndex.nav};

  &:before {
    content: '';
    position: absolute;
    right: ${({ pointerRight }) => (pointerRight ? '-12px' : '13px')};
    top: ${({ pointerRight, menuItemCount }) =>
      pointerRight && menuItemCount
        ? `${menuItemCount * menuItemHeight - 27}px`
        : '-12px'};
    rotate: ${({ pointerRight }) => (pointerRight ? '90deg' : '0deg')};
    width: 0;
    border-width: 6px;
    border-color: transparent transparent
      ${({ theme }) => theme.colors.white.default} transparent;
    border-style: solid;
  }

  ${MenuItem} {
    &:hover {
      border-radius: ${({ menuItemCount }) => menuItemCount === 1 && '10px'};
    }
  }
`;

export const LinkStyled = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.grey};
  &:hover {
    color: ${({ theme }) => theme.colors.text.black};
  }
`;

export const LinkSpanStyled = styled(Span)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.colors.text.black};
  }
`;
