import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components/macro';
import { device } from '../../../styles/device';
import { theme } from '../../../styles/theme';
import { CardContainer } from '../../shared/card/card/Card.styles';
import { H1, Span } from '../../shared/typefaces/Typefaces.styles';

const lines = {
  primary:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='744' height='744' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23f98d0f' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23f99b27'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E",
  secondary:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='744' height='744' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%231bab3a' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2329ab45'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E",
};

interface IMainProps {
  marginTop?: 'xlarge' | 'huge';
}

export const Container = styled.section`
  position: relative;

  .questionsHeader {
    text-align: center;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.heading};
    margin-bottom: 50px;
  }
`;

export const Main = styled.section<IMainProps>`
  position: absolute;
  top: ${({ marginTop }) => (marginTop === 'huge' ? 630 : 500)}px;
  width: 100%;
  overflow: hidden;

  @media ${device.mobileS} {
    top: ${({ marginTop }) => (marginTop === 'huge' ? 680 : 500)}px;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto 80px;

  @media ${device.laptopL} {
    width: 900px;
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 30px 10px 160px;
  color: ${({ theme }) => theme.colors.white.default};

  .subHeading {
    display: block;
    margin-bottom: 5px;
    margin-right: 10px;
    width: 100%;

    .subHeadingTop {
      color: ${({ theme }) => theme.colors.white.default};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.largeHeading};
      line-height: ${({ theme }) => theme.lineHeight.title};
      margin-right: 5px;
    }
    .subHeadingBottom {
      color: ${({ theme }) => theme.colors.white.default};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.largeHeading};
      line-height: ${({ theme }) => theme.lineHeight.title};
    }
  }

  @media ${device.mobileM} {
    margin: 0 10px 220px;
  }

  @media ${device.tablet} {
    .subHeading {
      display: flex;
      flex-direction: column;
    }
  }

  @media ${device.laptopL} {
    width: 900px;
    margin: 0 auto 260px;
  }
`;

export const Heading = styled(H1)`
  text-align: center;
  margin: 5px 0 30px !important;
  max-width: 450px;
  text-align: left;
  color: ${({ theme }) => theme.colors.white.faded};
  text-shadow: ${({ theme }) => theme.shadow.text};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img`
  display: none !important;

  @media ${device.mobileM} {
    display: block !important;
    width: 80px;
    height: 80px;
    margin-left: 30px;
  }
`;

export const AccountHeader = styled.div`
  margin: 0 auto;

  .accountHeading {
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.heading};
    text-align: center;
  }

  .accountSubHeading {
    display: block;
    width: 100%;
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    font-size: ${({ theme }) => theme.fontSize.default};
    text-align: center;
    margin-bottom: 60px;
  }

  @media ${device.mobileM} {
    .accountHeading,
    .accountSubHeading {
      text-align: left;
    }
  }

  @media ${device.laptop} {
    .accountHeading {
      margin-left: 20px;
      margin-bottom: 5px;
    }

    .accountSubHeading {
      margin-left: 20px;
    }
  }

  @media ${device.laptop} {
    margin: 0;
  }
`;

export const CurrentAccount = styled.div`
  position: relative;
`;

export const Account = styled.div`
  position: absolute;
  top: -100px;
  width: 270px;
  background-color: #fff;
  left: 50%;
  margin-left: -135px;
  display: flex;

  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.layout.radiusTiny};
  padding: 15px;
  box-shadow: ${({ theme }) => theme.shadow.box};

  .logo {
    width: 40px;
    height: 40px;
  }

  .content {
    margin-left: 15px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .heading {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: normal;
    margin-bottom: 10px;
  }

  .subHeading {
    color: ${({ theme }) => theme.colors.text.grey};
  }

  @media ${device.mobileM} {
    width: 300px;
    margin-left: -150px;
    top: -150px;
  }

  @media ${device.mobileL} {
    top: -190px;
  }
`;

export const ChefHeader = styled.div`
  .heading {
    color: ${({ theme }) => theme.colors.text.grey};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .pricing {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    @media ${device.mobileM} {
      top: 60px;
    }
  }

  .dollar {
    color: ${({ theme }) => theme.colors.text.grey};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding-right: 5px;
  }

  .price {
    color: ${({ theme }) => theme.colors.text.grey};
    font-size: ${({ theme }) => theme.fontSize.title};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    line-height: ${({ theme }) => theme.lineHeight.largeHeading};
    padding-right: 5px;
  }

  .slash {
    color: ${({ theme }) => theme.colors.text.grey};
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: ${({ theme }) => theme.lineHeight.heading};
    padding: 0 5px;
  }

  .metric {
    color: ${({ theme }) => theme.colors.text.grey};
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: ${({ theme }) => theme.lineHeight.default};
    padding: 10px 5px;
  }

  .priceQuote {
    color: ${({ theme }) => theme.colors.text.grey};
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-shadow: ${({ theme }) => theme.shadow.text};
    line-height: ${({ theme }) => theme.lineHeight.default};
  }
`;
export const BusinessHeader = styled.div`
  .heading {
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .pricing {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    @media ${device.mobileM} {
      top: 60px;
    }
  }

  .dollar {
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding-right: 5px;
  }

  .price {
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSize.title};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    line-height: ${({ theme }) => theme.lineHeight.largeHeading};
    padding-right: 5px;
  }

  .slash {
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: ${({ theme }) => theme.lineHeight.heading};
    padding: 0 5px;
  }

  .metric {
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: ${({ theme }) => theme.lineHeight.default};
    padding: 10px 5px;
  }

  .priceQuote {
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-shadow: ${({ theme }) => theme.shadow.text};
    line-height: ${({ theme }) => theme.lineHeight.default};
  }
`;

export const Boxes = styled.div`
  display: flex;
  flex-direction: row;
  width: inherit;
`;

export const Box = styled.div`
  height: 820px;
  position: relative;

  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white.default};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box};
  overflow: hidden;
  padding-bottom: 15px;
  min-width: 140px;
  flex: 1;

  .heading {
    position: absolute;
    top: 5px;
    left: 15px;
    color: ${({ theme }) => theme.colors.white.default};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .pricing {
    position: absolute;
    top: 45px;
    left: 15px;
    right: 15px;

    @media ${device.mobileM} {
      top: 60px;
    }
  }

  .priceQuote {
    position: absolute;
    top: 95px;
    left: 15px;
    right: 10px;

    @media ${device.mobileM} {
      top: 130px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 0 15px;
  }

  .bottom {
    position: absolute;
    top: 230px;
    bottom: 15px;
    left: 0;
  }

  .subHeading {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    line-height: ${({ theme }) => theme.lineHeight.large};
    margin-bottom: 25px;
  }

  .quote {
    font-size: ${({ theme }) => theme.fontSize.default};
    line-height: ${({ theme }) => theme.lineHeight.large};
    color: ${({ theme }) => theme.colors.text.grey};

    .quoteTablet {
      display: none;
    }

    @media ${device.mobileL} {
      top: 130px;

      .quoteMobile {
        display: none;
      }
    }
  }

  .listHeading {
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: ${({ theme }) => theme.lineHeight.large};
    color: ${({ theme }) => theme.colors.text.faded};
    padding: 50px 0 10px;

    @media ${device.mobileL} {
      padding: 35px 0 10px;
    }

    @media ${device.tablet} {
      padding: 30px 0 10px;
    }

    @media ${device.laptopL} {
      padding: 40px 0 10px;
    }
  }

  .listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    min-height: 50px;

    .itemText {
      padding: 0 5px 0 15px;
      font-size: ${({ theme }) => theme.fontSize.default};
      line-height: ${({ theme }) => theme.lineHeight.large};
      color: ${({ theme }) => theme.colors.text.grey};
    }
  }

  .button {
    display: flex;
    justify-content: center;
    border-top: 1px solid ${({ theme }) => theme.colors.background};
    padding-top: 30px;
    margin: 15px;

    .selectButton {
      font-size: ${({ theme }) => theme.fontSize.default};
      min-width: 120px;

      @media ${device.mobileL} {
        min-width: 160px;
      }
    }
  }

  &:first-child {
    margin-right: 5px;
    margin-right: 5px;
  }

  &:last-child {
    margin-left: 5px;
  }

  @media ${device.mobileXS} {
    height: 770px;
    min-width: 160px;
  }
  @media ${device.mobileM} {
    height: 670px;
    min-width: 200px;
  }
  @media ${device.mobileL} {
    height: 650px;
  }
  @media ${device.tablet} {
    margin: 0;
  }

  @media ${device.laptopL} {
    height: 670px;
  }
`;

export const Paragraph = styled(Span)`
  display: block;
  width: 100%;
  margin-bottom: 5px !important;
  color: ${({ theme }) => theme.colors.white.default};
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeight.large};
  max-width: 580px;
  text-shadow: ${({ theme }) => theme.shadow.text};

  @media ${device.tabletL} {
    text-align: left;
  }
`;

export const Who = styled.div`
  position: relative;

  .container {
    display: none;
    @media ${device.tabletL} {
      position: absolute;
      display: block;
      top: -400px;
      left: 15px;
    }
  }

  .title {
    color: ${({ theme }) => theme.colors.text.grey};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: 30px;
  }

  .listItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
  }

  .image {
    width: 50px;
    height: 50px;
    margin-right: 30px;

    img {
      border-radius: ${({ theme }) => theme.layout.radiusSmall};
    }
  }

  .details {
    display: flex;
    flex-direction: column;
  }

  .heading {
    color: ${({ theme }) => theme.colors.text.black};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: 5px;
  }

  .subHeading {
    color: ${({ theme }) => theme.colors.text.faded};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

export const Explainer = styled.div`
  margin-bottom: 60px;

  .explainerCard {
    border-radius: ${({ theme }) => theme.layout.radiusTiny}
      ${({ theme }) => theme.layout.radiusLarge}
      ${({ theme }) => theme.layout.radiusTiny}
      ${({ theme }) => theme.layout.radiusTiny};
    margin-top: 100px;
  }

  .heading {
    color: ${({ theme }) => theme.colors.text.black};
    font-weight: ${({ theme }) => theme.fontWeight.large};
    text-align: center;
    margin: 0 15px;
  }

  .subHeading {
    color: ${({ theme }) => theme.colors.text.faded};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    text-align: center;
    padding: 15px 5px;
    line-height: 20px;
  }

  .CTA {
    width: 250px;
  }

  ${CardContainer} {
    padding-bottom: 0;
  }

  .container {
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
      flex-direction: row;
    }
  }

  .panel {
    background-color: ${({ theme }) => theme.colors.backgroundFaded};
    position: relative;
    display: flex;
    flex-direction: row;

    @media ${device.tablet} {
      flex-direction: column;
      width: 200px;
      border-radius: ${({ theme }) => theme.layout.radiusTiny} 0 0
        ${({ theme }) => theme.layout.radiusTiny};
      overflow: hidden;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    width: 50%;

    .chef {
      ${ChefHeader} {
        .heading {
          text-align: center;
          margin: 5px 0 10px;
          font-size: ${({ theme }) => theme.fontSize.default};
          color: ${({ theme }) => theme.colors.text.grey};

          @media ${device.tablet} {
            text-align: left;
          }
        }

        .pricing {
          justify-content: center;
        }

        .slash,
        .metric {
          display: none;
        }

        .price {
          padding: 0 5px 5px 0;
        }

        .priceQuote {
          margin: 15px 0 30px;
          display: none;
        }

        @media ${device.tablet} {
          .heading {
            font-size: ${({ theme }) => theme.fontSize.medium};
            line-height: ${({ theme }) => theme.lineHeight.large};
          }

          .extra {
            display: none;
          }

          .pricing {
            justify-content: flex-start;
          }

          .slash,
          .metric {
            display: flex;
          }

          .priceQuote {
            display: block;
          }
        }
      }
    }

    .extra {
      margin: 5px 0 10px;

      @media ${device.tablet} {
        display: none;
      }
    }

    @media ${device.tablet} {
      width: 100%;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.secondary.default};
    width: 50%;
    padding: 15px 10px;
    border-radius: 0 ${({ theme }) => theme.layout.radiusLarge};
    background-image: url(${`"${lines.secondary}"`}),
      ${({ theme }) =>
        `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};

    @media ${device.tablet} {
      position: absolute;
      bottom: -10px;
      height: 53%;
      width: 100%;
      transform: skewY(-5deg);
      border-radius: 0 0 0 ${({ theme }) => theme.layout.radiusLarge};
    }
  }

  .business {
    .selectButton {
      margin: 15px auto;
    }

    ${BusinessHeader} {
      .heading {
        text-align: center;
        margin: 5px 0 10px;
        font-size: ${({ theme }) => theme.fontSize.default};
        color: ${({ theme }) => theme.colors.white.default};

        @media ${device.tablet} {
          text-align: left;
        }
      }

      .pricing {
        justify-content: center;
      }

      .slash,
      .metric {
        display: none;
      }

      .price {
        padding: 0 5px 5px 0;
      }

      .priceQuote {
        margin: 15px 0 30px;
        display: none;
      }

      @media ${device.tablet} {
        .heading {
          font-size: ${({ theme }) => theme.fontSize.medium};
          line-height: ${({ theme }) => theme.lineHeight.large};
        }

        .extra {
          display: none;
        }

        .pricing {
          justify-content: flex-start;
        }

        .slash,
        .metric {
          display: flex;
        }

        .priceQuote {
          display: block;
        }
      }
    }

    @media ${device.tablet} {
      transform: skewY(5deg);
      position: relative;
    }
  }

  .selectButton {
    background-color: ${({ theme }) => theme.colors.white.default};
    background-image: none;
    color: ${({ theme }) => theme.colors.text.grey};
    margin: 15px auto;
    box-shadow: ${({ theme }) => theme.shadow.smallBox};
    font-size: ${({ theme }) => theme.fontSize.default};
    min-width: 120px;

    @media ${device.mobileL} {
      min-width: 160px;
    }
  }

  .content {
    position: relative;
    flex: 1;
    display: flex;
  }

  .details {
    width: 100%;
    flex: 1;
    border-radius: 0 ${({ theme }) => theme.layout.radiusTiny}
      ${({ theme }) => theme.layout.radiusTiny} 0;
    padding: 15px 15px 15px 30px;
    display: block;

    .subHeading {
      display: block;
      padding: 30px 0px;
      text-align: center;
    }

    .price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .priceBox {
      width: 45%;
    }

    .heading {
      font-size: ${({ theme }) => theme.fontSize.medium};
      line-height: ${({ theme }) => theme.lineHeight.large};
      color: ${({ theme }) => theme.colors.text.faded};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      text-align: left;
      margin: 0 0 30px;
      padding-top: 15px;
    }

    .amount {
      display: block;
      font-size: ${({ theme }) => theme.fontSize.heading};
      line-height: ${({ theme }) => theme.lineHeight.medium};
      color: ${({ theme }) => theme.colors.secondary.default};
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
      position: relative;

      @media ${device.tabletL} {
        display: flex;
      }
    }

    .slashGroup {
      display: block;
      position: relative;
      top: 5px;
      left: 0;

      @media ${device.tabletL} {
        position: absolute;
        left: 85px;
        top: 0;

        &.push {
          left: 105px;
        }
      }
    }

    .dollarGroup {
      display: flex;
    }

    .dollar {
      font-size: ${({ theme }) => theme.fontSize.default};
      color: ${({ theme }) => theme.colors.secondary.default};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      padding-right: 2px;
    }

    .slash {
      padding-right: 3px;
      color: ${({ theme }) => theme.colors.text.grey};
      &:before {
        content: 'per';
      }

      @media ${device.tabletL} {
        &:before {
          content: '/';
        }
        font-size: ${({ theme }) => theme.fontSize.large};
        line-height: ${({ theme }) => theme.lineHeight.medium};
        font-weight: ${({ theme }) => theme.fontWeight.default};
      }
    }

    .metric {
      padding-top: 5px;
      color: ${({ theme }) => theme.colors.text.grey};
    }

    .total {
      display: block;
      line-height: ${({ theme }) => theme.lineHeight.medium};
      color: ${({ theme }) => theme.colors.text.grey};
      font-weight: ${({ theme }) => theme.fontWeight.default};
      padding: 25px 0 30px;
    }

    .cost {
      color: ${({ theme }) => theme.colors.text.black};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      padding-right: 5px;
    }

    .equals {
      width: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }

    .icon {
      font-size: ${({ theme }) => theme.fontSize.heading};
      line-height: ${({ theme }) => theme.lineHeight.largeHeading};
      color: ${({ theme }) => theme.colors.text.grey};
      font-weight: ${({ theme }) => theme.fontWeight.default};
    }

    .line {
      background-color: ${({ theme }) => theme.colors.white.default};
      background-image: ${({ theme }) =>
        `linear-gradient(to top, ${theme.colors.grey.faded}, ${theme.colors.white.default})`};
      width: 1px;
      flex: 1;

      &:last-child {
        background-image: ${({ theme }) =>
          `linear-gradient(to bottom, ${theme.colors.grey.faded}, ${theme.colors.white.default})`};
      }
    }

    .slider {
      margin: 30px 0 60px;
      padding: 0 30px 0 15px;

      .MuiSlider-mark {
        display: none;
      }

      .MuiSlider-markLabel {
        top: 50px;
      }
    }

    .description {
      .heading {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSize.default};
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        margin: 0 0 10px;
        padding: 0;
      }

      .text {
        color: ${({ theme }) => theme.colors.grey.default};
        font-size: ${({ theme }) => theme.fontSize.button};
      }
    }
  }
`;

export const CTA = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  .CTA {
    width: 250px;
  }
`;

export const ArrowIcon = styled.div`
  margin-top: 20px;
`;

export const Features = styled.div`
  .heading {
    color: ${({ theme }) => theme.colors.text.black};
    font-weight: ${({ theme }) => theme.fontWeight.large};
    text-align: center;
    margin: 100px 15px 15px;
  }

  .subHeading {
    display: block;
    color: ${({ theme }) => theme.colors.grey.faded};
    font-weight: ${({ theme }) => theme.fontWeight.large};
    text-align: center;
    margin: 0 15px 100px;
  }

  .comparison {
    border-radius: ${({ theme }) => theme.layout.radiusTiny}
      ${({ theme }) => theme.layout.radiusLarge}
      ${({ theme }) => theme.layout.radiusTiny}
      ${({ theme }) => theme.layout.radiusTiny};
    box-shadow: ${({ theme }) => theme.shadow.box};
    margin: 0 15px;

    @media ${device.mobileM} {
      width: 400px;
      margin: 0 auto;
    }

    @media ${device.tabletL} {
      display: flex;
      width: 750px;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .featureList {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .features {
    padding: 15px;
  }

  .switch {
    position: relative;
    display: flex;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 10px;
      width: 50%;

      .price {
        display: flex;
        flex-direction: row;
        margin: 15px 0;
        position: relative;
      }

      .dollar {
        color: ${({ theme }) => theme.colors.text.grey};
        font-size: ${({ theme }) => theme.fontSize.medium};
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        position: absolute;
        left: -15px;
        top: -5px;
      }

      .amount {
        color: ${({ theme }) => theme.colors.text.grey};
        font-size: ${({ theme }) => theme.fontSize.title};
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      }

      .heading {
        font-size: ${({ theme }) => theme.fontSize.default};
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        color: ${({ theme }) => theme.colors.text.black};
        margin: 5px 0 15px;
      }

      .extra {
        margin: 5px 0 10px;
      }

      .priceQuote {
        font-size: ${({ theme }) => theme.fontSize.default};
        line-height: ${({ theme }) => theme.lineHeight.large};
        color: ${({ theme }) => theme.colors.text.grey};
        text-align: center;
        margin: 0px 0 15px;
        display: none;

        @media ${device.tablet} {
          display: block;
        }
      }

      @media ${device.tabletL} {
        width: 100%;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      background-color: ${({ theme }) => theme.colors.secondary.default};
      width: 50%;
      padding: 15px 10px;
      border-radius: 0 ${({ theme }) => theme.layout.radiusLarge};
      background-image: url(${`"${lines.secondary}"`}),
        ${({ theme }) =>
          `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
    }

    .business {
      .selectButton {
        margin: 30px auto 15px;
      }

      ${BusinessHeader} {
        .heading {
          text-align: center;
          margin: 5px 0 10px;
          font-size: ${({ theme }) => theme.fontSize.default};
          color: ${({ theme }) => theme.colors.white.default};
        }

        .pricing {
          justify-content: center;
        }

        .slash,
        .metric {
          display: none;
        }

        .price {
          padding: 0 5px 5px 0;
        }

        .priceQuote {
          margin: 15px 0 30px;
          display: none;
        }
      }
    }

    .selectButton {
      background-color: ${({ theme }) => theme.colors.white.default};
      background-image: none;
      color: ${({ theme }) => theme.colors.text.grey};
      margin: 15px auto;
      box-shadow: ${({ theme }) => theme.shadow.smallBox};

      &.select {
        font-size: ${({ theme }) => theme.fontSize.default};
        color: ${({ theme }) => theme.colors.text.white};
      }
    }

    @media ${device.tabletL} {
      height: 210px;

      .left,
      .right {
        display: none;
      }
    }
  }

  .featureSet {
    color: ${({ theme }) => theme.colors.text.faded};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.default};
    margin: 30px 0 15px;
  }

  .feature {
    display: flex;
    line-height: ${({ theme }) => theme.lineHeight.medium};
    padding-bottom: 15px;

    &.faded {
      color: ${({ theme }) => theme.colors.text.faded};
    }

    .sideIcon {
      margin-right: 10px;
    }

    .soon {
      font-size: ${({ theme }) => theme.fontSize.button};
      white-space: nowrap;
    }

    @media ${device.tabletL} {
      display: block;

      .sideIcon,
      .soon {
        display: none;
      }
    }
  }

  .plans {
    display: none;

    @media ${device.tabletL} {
      display: flex;
      flex-direction: row;
    }

    .featureSet {
      min-height: 25px;

      &.short {
        min-height: 20px;
      }
    }

    ${Box} {
      height: auto;
      padding-bottom: 15px;
    }

    ${Box}:first-child {
      .heading {
        position: absolute;
        top: 5px;
        left: 15px;
        color: ${({ theme }) => theme.colors.text.white};
        margin: 0;
      }

      .subHeading {
        color: ${({ theme }) => theme.colors.black};
        font-size: ${({ theme }) => theme.fontSize.default};
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        margin: 15px 0 105px;
      }

      .price {
        display: flex;
        flex-direction: row;
        margin: 15px 0;
        position: relative;
      }

      .dollar {
        color: ${({ theme }) => theme.colors.text.grey};
        font-size: ${({ theme }) => theme.fontSize.medium};
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        position: absolute;
        left: -15px;
        top: -5px;
      }

      .amount {
        color: ${({ theme }) => theme.colors.text.grey};
        font-size: ${({ theme }) => theme.fontSize.title};
        font-weight: ${({ theme }) => theme.fontWeight.semibold};
      }

      .content {
        align-items: center;
      }

      .button {
        position: absolute;
        bottom: 30px;
      }
    }

    ${Box}:last-child {
      height: auto;
      padding-bottom: 30px;

      .heading {
        position: absolute;
        top: 5px;
        left: 15px;
        margin: 0;
        color: ${({ theme }) => theme.colors.text.white};
      }

      .spacing {
        padding: 50px 0;
      }

      .content {
        align-items: center;
      }
    }
  }
`;

export const SliderStyled = withStyles({
  root: {
    color: theme.colors.secondary.default,
    height: 8,
  },
  thumb: {
    height: 30,
    width: 60,
    borderRadius: 8,
    backgroundColor: theme.colors.white.default,
    boxShadow: theme.shadow.largeBox,
    '&:focus, &:hover, &$active': {
      boxShadow: theme.shadow.largeBox,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: theme.shadow.largeBox,
      },
    },
    marginTop: -12,
    marginLeft: -30,
    '& .arrowIcon': {
      width: 35,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
