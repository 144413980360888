import React, { FC, Fragment, useContext } from 'react';
import { useLocation } from 'react-router';
import { LayoutContext } from '../../../contexts/layoutContext';
import { AccountType, useMeQuery } from '../../../generated/graphql';
import { theme } from '../../../styles/theme';
import { useAccessToken } from '../../../utils/accessToken';
import {
  Apple,
  CafeMenu,
  FoodCount,
  Timer,
  RecipeBook,
  Trolley,
  PieChart,
  Chef,
} from '../icons';
import { DotsLoading } from '../loading';
import MenuMainItem from './menuItem';
import {
  Border,
  HelpButton,
  Items,
  LinkStyled,
  MenuDivider,
  MenuMainContainer,
  SpanLinkStyled,
  SubMenu,
} from './MenuMain.styles';
import { IMenuMainProps } from './menuMain.types';
import { VenueButton } from './venueButton';
import { overlayConstants } from '../layout/layoutOverlay/constants';
import { useChatbox } from '../../../utils/customHooks/useChatbox';
import { IOverlayProps } from '../layout/layoutOverlay/layoutOverlay.types';

const MenuMain: FC<IMenuMainProps> = ({ width }) => {
  const {
    isOpen,
    menuToggle,
    sliderToggle,
    extraSliderToggle,
    topSliderToggle,
    selectedIngredient,
    selectedIngredientProduct,
    overlayToggle,
    account,
    help: { showHelpModal },
    dispatch,
  } = useContext(LayoutContext);
  const { chatboxLoading, handleShowChatbox } = useChatbox();
  const { pathname } = useLocation();

  const { data } = useMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [accessToken] = useAccessToken();

  if (pathname === '/') return null;

  const handleMenuClick = () => {
    if (sliderToggle) {
      dispatch({ type: 'TOGGLE_SLIDER' });
    }
    if (extraSliderToggle) {
      dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
    }
    if (topSliderToggle) {
      dispatch({ type: 'TOGGLE_TOP_SLIDER' });
    }

    if (sliderToggle || extraSliderToggle) {
      if (selectedIngredient || selectedIngredientProduct) {
        dispatch({ type: 'SELECT_INGREDIENT', payload: '' });
        dispatch({ type: 'SELECT_INGREDIENT_PRODUCT', payload: '' });
        dispatch({ type: 'SET_NEW_INGREDIENT_NAME', payload: '' });
      }
    }

    if (overlayToggle) {
      dispatch({ type: 'CLOSE_OVERLAY' });
    }
  };

  const handleMenuItemClick = () => {
    dispatch({ type: 'HIDE_TOOL_TIP' });
    if (width < theme.mQ.laptop && menuToggle && isOpen) {
      dispatch({ type: 'TOGGLE_MENU' });
      dispatch({ type: 'CLOSE_MENU' });
    }
  };

  const handleHelpClick = () => {
    handleMenuItemClick();
    dispatch({
      type: 'TOGGLE_HELP_MODAL',
    });

    if (!showHelpModal) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: {
          ...overlayConstants.helpModal,
          buttonCTA: () => {
            handleShowChatbox();
          },
          linkCTA: () => {
            dispatch({
              type: 'TOGGLE_HELP_MODAL',
            });
            dispatch({ type: 'CLOSE_OVERLAY' });
            dispatch({ type: 'RESET_ALL_TOOL_TIPS' });
          },
        } as IOverlayProps,
      });
    }
  };

  const handleContactClick = () => {
    handleShowChatbox();
    handleMenuItemClick();
  };

  const authAccess =
    Boolean(account && account?.type !== 'GUEST') || Boolean(accessToken);

  const howWeWork = (
    <LinkStyled
      to={{ pathname: '/', state: 'about' }}
      onClick={handleMenuItemClick}
    >
      How we work?
    </LinkStyled>
  );

  const pricing = (
    <LinkStyled
      to={{ pathname: '/pricing', state: 'pricing' }}
      onClick={handleMenuItemClick}
    >
      Pricing
    </LinkStyled>
  );

  if (!authAccess) {
    return pathname !== '/' ? (
      <MenuMainContainer isOpen={isOpen} onClick={handleMenuClick}>
        <Items isOpen={isOpen}>
          <VenueButton
            onClick={handleMenuItemClick}
            accessToken={accessToken}
          />
          <MenuMainItem
            itemName="Sign In"
            link="/sign-in"
            icon={<Chef faded size="medium" />}
            onClick={handleMenuItemClick}
          />
          <MenuMainItem
            itemName="Create account"
            link="select-account"
            icon={<CafeMenu faded size="medium" />}
            onClick={handleMenuItemClick}
          />

          <SubMenu isOpen={isOpen}>
            {howWeWork}
            {pricing}
            <SpanLinkStyled onClick={handleContactClick}>
              <DotsLoading
                text={(loading) => (loading ? 'Loading' : 'Contact')}
                isLoading={chatboxLoading}
                size="small"
                color="default"
                noMargin
              />
            </SpanLinkStyled>
          </SubMenu>

          <HelpButton
            onClick={handleHelpClick}
            isOpen={isOpen}
            inversed
            color="default"
          />
        </Items>
        <Border isOpen={isOpen} />
      </MenuMainContainer>
    ) : null;
  }

  const subMenu =
    data?.me?.user?.accountType === 'GUEST' ? (
      <Fragment>
        {howWeWork}
        <MenuMainItem
          itemName="Create account"
          link="select-account"
          onClick={handleMenuItemClick}
        />
        <MenuMainItem
          itemName="Sign in"
          link="/sign-in"
          onClick={handleMenuItemClick}
        />
        {pricing}
        <SpanLinkStyled onClick={handleContactClick}>
          <DotsLoading
            text={(loading) => (loading ? 'Loading' : 'Contact')}
            isLoading={chatboxLoading}
            size="small"
            color="default"
            noMargin
          />
        </SpanLinkStyled>
      </Fragment>
    ) : (
      <Fragment>
        {howWeWork}
        <LinkStyled to="/account" onClick={handleMenuItemClick}>
          Account
        </LinkStyled>
        <SpanLinkStyled onClick={handleContactClick}>
          <DotsLoading
            text={(loading) => (loading ? 'Loading' : 'Contact')}
            isLoading={chatboxLoading}
            size="small"
            color="default"
            noMargin
          />
        </SpanLinkStyled>
      </Fragment>
    );

  const adminMenu =
    data?.me?.user?.accountType === AccountType.RecipeRevenue ? (
      <Fragment>
        <MenuMainItem
          itemName="Dashboard"
          link="/dashboard"
          highlight={pathname === '/dashboard'}
          icon={<Timer faded size="medium" />}
          onClick={handleMenuItemClick}
        />
      </Fragment>
    ) : (
      <Fragment />
    );

  return (
    <MenuMainContainer isOpen={isOpen} onClick={handleMenuClick}>
      <Items isOpen={isOpen}>
        <VenueButton onClick={handleMenuItemClick} />
        {adminMenu}
        <MenuMainItem
          itemName="Results"
          link="/results"
          highlight={pathname === '/results'}
          icon={<PieChart faded size="medium" />}
          onClick={handleMenuItemClick}
        />
        <MenuMainItem
          itemName="Recipes"
          link="/recipes"
          highlight={pathname === '/recipes'}
          icon={<CafeMenu faded size="medium" />}
          onClick={handleMenuItemClick}
        />
        <MenuMainItem
          itemName="Ingredients"
          link="/ingredients"
          highlight={pathname === '/ingredients'}
          icon={<Apple faded size="medium" />}
          onClick={handleMenuItemClick}
        />
        <MenuDivider />
        <MenuMainItem
          itemName="Stocktakes"
          link="/stocktakes"
          highlight={pathname === '/stocktakes'}
          icon={<FoodCount faded size="medium" />}
          onClick={handleMenuItemClick}
        />
        <MenuMainItem
          itemName="Inventory"
          link="/inventory"
          highlight={pathname === '/inventory'}
          icon={<RecipeBook faded size="medium" />}
          onClick={handleMenuItemClick}
        />
        <MenuMainItem
          itemName="Shopping List"
          link="/shopping-list"
          highlight={pathname === '/shopping-list'}
          icon={<Trolley faded size="medium" />}
          onClick={handleMenuItemClick}
        />
        <SubMenu isOpen={isOpen}>{subMenu}</SubMenu>
        <HelpButton
          onClick={handleHelpClick}
          isOpen={isOpen}
          inversed
          color="default"
        />
      </Items>
      <Border isOpen={isOpen} />
    </MenuMainContainer>
  );
};

export default MenuMain;
