import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import { Controller, useForm } from 'react-hook-form';
import { LayoutPage, LayoutColumn } from '../../shared/layout';
import { theme } from '../../../styles/theme';
import { SmallButton } from '../ingredient/Ingredient.styles';
import { Button } from '../../shared/button';
import { Header, Form, Container, AddSupplier } from './Supplier.styles';
import { Address, Input, SelectList } from '../../shared/formElements';
import { ButtonGroup, SideLabel } from '../../shared/layout/Layout.styles';
import {
  useSupplierQuery,
  useIngredientQuery,
  useSuppliersQuery,
  useCreateSupplierMutation,
  useUpdateSupplierMutation,
  useUpdateIngredientProductMutation,
  useMeQuery,
  IngredientDocument,
} from '../../../generated/graphql';
import { LogoLoading, DotsLoading } from '../../shared/loading';
import { formatSupplierFormData, isEmpty } from '../../../utils/helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { IInitialSelectListOptionProps } from '../../shared/formElements/selectList/selectList.types';
import { overlayConstants } from '../../shared/layout/layoutOverlay/constants';
import { useAddress } from '../../../utils/customHooks/useAddress';
import { H3 } from '../../shared/typefaces/Typefaces.styles';
import { defaultAddressValues } from '../../shared/formElements/address/Address';
import { IAddressFormatProps } from '../../shared/formElements/address/AddressInput';
import {
  capitalizeFirstLetterPerWord,
  findMatchingStrings,
} from '../../../utils/helper/strings';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

interface ISupplierStateProps {
  editSupplier?: string;
  showDisplayNamePrompt: boolean;
}

const Supplier = () => {
  const {
    appWidth,
    selectedSupplier,
    selectedIngredient,
    selectedIngredientProduct,
    selectedVenueObject,
    account,
    user,
    dispatch,
  } = useContext(LayoutContext);
  const [state, setState] = useState<ISupplierStateProps>({
    editSupplier: selectedSupplier,
    showDisplayNamePrompt: false,
  });
  const { editSupplier, showDisplayNamePrompt } = state;
  const { getAddressErrors } = useAddress();
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const suppliers = useSuppliersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const supplier = useSupplierQuery({
    variables: {
      input: { supplierId: selectedSupplier || editSupplier! },
    },
  });

  const ingredient = useIngredientQuery({
    variables: {
      input: {
        ingredientId: selectedIngredient!,
        venueId: selectedVenueObject?.id!,
      },
    },
  });
  const me = useMeQuery({
    fetchPolicy: 'cache-only',
  });

  const [createSupplierMutation, createSupplier] = useCreateSupplierMutation();
  const [updateSupplierMutation, updateSupplier] = useUpdateSupplierMutation();
  const [
    updateIngredientProductMutation,
    updateIngredientProduct,
  ] = useUpdateIngredientProductMutation();

  let availableSuppliers: IInitialSelectListOptionProps[] = [
    {
      label: '',
      value: '',
    },
  ];
  let availableSupplierGroups: IInitialSelectListOptionProps[] = [
    {
      label: '',
      value: '',
    },
  ];

  const {
    errors,
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      selectedSupplier ? editValidationSchema : createValidationSchema
    ),
    defaultValues: {
      supplierGroupId: '',
      supplierGroupDisplayName: selectedSupplier
        ? ''
        : { label: '', value: '' },
      displayName: selectedSupplier ? '' : { label: '', value: '' },
      email: '',
      website: '',
      phone: '',
    },
  });

  const displayName = watch('displayName');
  const supplierGroupId: string = watch('supplierGroupId');
  const supplierGroupData =
    (selectedSupplier || editSupplier) &&
    supplier.data?.supplier.supplier?.supplierGroup;

  if (!isEmpty(suppliers.data)) {
    availableSupplierGroups = suppliers!.data!.suppliers!.suppliers?.reduce(
      (acc: any, currentSupplier) => {
        const supplierGroupId = currentSupplier.supplierGroup?.id;
        const supplierGroupDisplayName =
          currentSupplier.supplierGroup?.displayName;

        // Check if the supplier group ID already exists in the accumulator
        const existingGroup = acc.find(
          (group: any) => group.value === supplierGroupId
        );

        // If not, add a new object representing the supplier group to the accumulator
        if (!existingGroup && supplierGroupId && supplierGroupDisplayName) {
          acc.push({
            label: supplierGroupDisplayName,
            value: supplierGroupId,
          });
        }

        return acc;
      },
      []
    );

    if (supplierGroupId) {
      availableSuppliers = suppliers!
        .data!.suppliers!.suppliers!.filter(
          (s) => s.supplierGroup?.id === supplierGroupId
        )
        .map((s) => {
          return {
            label: s.displayName,
            value: s.id,
          };
        });
    } else {
      availableSuppliers = suppliers!.data!.suppliers!.suppliers!.map(
        (supplier) => {
          return {
            label: supplier.displayName,
            value: supplier.id,
          };
        }
      );
    }
  }

  const waitToUpdateSupplier = useCallback(
    async (supplierId: string) => {
      dispatch({
        type: 'SELECT_SUPPLIER',
        payload: supplierId,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      !supplier.loading &&
      !suppliers.loading &&
      supplier.data?.supplier.successful &&
      selectedSupplier
    ) {
      const {
        supplierGroup,
        displayName,
        email,
        website,
        phone,
        address,
      } = supplier!.data!.supplier!.supplier!;

      reset({
        supplierGroupId: supplierGroup?.id || '',
        supplierGroupDisplayName:
          selectedSupplier || editSupplier
            ? supplierGroup
              ? supplierGroup.displayName
              : ''
            : { label: '', value: '' },
        displayName:
          selectedSupplier || editSupplier
            ? displayName
            : { label: '', value: '' },
        email: email || '',
        website: website || '',
        phone: phone || '',
        ...address,
      });
    }
  }, [supplier, suppliers, selectedSupplier, editSupplier, reset]);

  const checkDisplayNameChange = (input: string | number | null) => {
    if (
      typeof displayName === 'string' &&
      showDisplayNamePrompt &&
      !isEmpty(supplier.data) &&
      displayName !== supplier.data!.supplier!.supplier!.displayName &&
      me.data?.me?.user?.id !== supplier.data!.supplier!.supplier!.creatorId
    ) {
      setState({ ...state, showDisplayNamePrompt: true });
    }
  };

  const handleCancel = async () => {
    dispatch({ type: 'SET_SUPPLIER_USER_EVENT', payload: undefined });
    await waitToUpdateSupplier('');
    dispatch({ type: 'TOGGLE_TOP_SLIDER' });
  };

  const handleAddSupplier = () => {
    dispatch({ type: 'SELECT_SUPPLIER', payload: '' });
    dispatch({ type: 'SELECT_BRAND', payload: '' });
    dispatch({ type: 'SET_SUPPLIER_USER_EVENT', payload: 'add' });
    setState({
      editSupplier: undefined,
      showDisplayNamePrompt: false,
    });
    reset({
      displayName: { label: '', value: '' },
      email: '',
      website: '',
      phone: '',
      ...defaultAddressValues,
    });
  };

  const handleNewSelectedSupplier = async (newSupplier: {
    label: string;
    value: string;
    newValue?: string;
  }) => {
    if (
      newSupplier &&
      !newSupplier?.newValue &&
      newSupplier?.label.toLowerCase() !== newSupplier.value?.toLowerCase()
    ) {
      if (selectedIngredientProduct) {
        const updateIngredientProduct = async () => {
          try {
            dispatch({
              type: 'SELECT_SUPPLIER',
              payload: newSupplier.value,
            });
            const response = await updateIngredientProductMutation({
              variables: {
                input: {
                  id: selectedIngredientProduct!,
                  ingredientId: selectedIngredient!,
                  venueId: selectedVenueObject?.id!,
                  supplierId: newSupplier?.value,
                  totalActiveSeconds: getTotalActiveSeconds(),
                },
              },
            });

            if (response.data?.updateIngredientProduct.successful) {
              await waitToUpdateSupplier(newSupplier?.value);
              const s =
                response.data.updateIngredientProduct.ingredientProduct
                  ?.supplier;

              reset({
                supplierGroupId: s?.supplierGroup?.id || '',
                supplierGroupDisplayName: s?.supplierGroup?.displayName || '',
                displayName: s?.displayName || '',
                email: s?.email || '',
                website: s?.website || '',
                phone: s?.phone || '',
                ...s?.address,
              });
            }
          } catch (error) {
            console.log('err ###', error);
          }
        };
        updateIngredientProduct();
      } else {
        dispatch({
          type: 'SELECT_SUPPLIER',
          payload: newSupplier?.value,
        });
      }
    }
  };

  const handleNewSelectedSupplierGroup = async (newSupplier: {
    label: string;
    value: string;
    newValue?: string;
  }) => {
    if (
      newSupplier &&
      newSupplier?.label.toLowerCase() !== newSupplier.value?.toLowerCase()
    ) {
      const formValues = getValues();
      reset({
        ...formValues,
        supplierGroupId: newSupplier.value,
        supplierGroupDisplayName: newSupplier,
      });
    }
  };

  const formatAddressLookupValues = async (
    address: IAddressFormatProps,
    formValues: {
      [x: string]: any;
    }
  ) => {
    const supplierInDatabase = suppliers.data?.suppliers.suppliers?.find(
      (s) =>
        s.address.postcode === address.postcode &&
        s.supplierGroup?.displayName.toLowerCase() ===
          address.location.toLowerCase()
    );

    if (supplierInDatabase) {
      await handleNewSelectedSupplier({
        label: supplierInDatabase.displayName,
        value: supplierInDatabase.id,
      });
    }

    const supplierGroup = address.location.replace(address.city, '').trim();
    const matchedSupplierGroup = findMatchingStrings(
      supplierGroup,
      address.website
    );
    const displayName = matchedSupplierGroup
      ? `${matchedSupplierGroup} ${address.city}`
      : supplierGroup
      ? `${supplierGroup} ${address.city}`
      : '';

    if (formValues.displayName?.inputValue) {
      return formValues;
    }

    if (typeof formValues.supplierGroupDisplayName === 'string') {
      formValues.supplierGroupDisplayName = formValues.supplierGroupDisplayName
        ? formValues.supplierGroupDisplayName
        : matchedSupplierGroup
        ? capitalizeFirstLetterPerWord(matchedSupplierGroup, false, true)
        : capitalizeFirstLetterPerWord(supplierGroup, false, true);
    } else {
      const name = formValues?.supplierGroup?.label
        ? formValues.supplierGroupDisplayName.label
        : matchedSupplierGroup
        ? capitalizeFirstLetterPerWord(matchedSupplierGroup, false, true)
        : capitalizeFirstLetterPerWord(supplierGroup, false, true);
      formValues.supplierGroupDisplayName = {
        inputValue: name,
        value: name,
        label: name,
      };
    }

    if (typeof formValues.displayName === 'string') {
      formValues.displayName = formValues.displayName
        ? formValues.displayName
        : capitalizeFirstLetterPerWord(displayName, false, true);
    } else {
      const name = formValues.displayName.label
        ? formValues.displayName.label
        : capitalizeFirstLetterPerWord(displayName, false, true);
      formValues.displayName = { inputValue: name, value: name, label: name };
    }

    return formValues;
  };

  const onSubmit = async (formData) => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveSupplier,
      });
      return;
    }

    if (selectedSupplier) {
      try {
        const inputData = {
          ...formatSupplierFormData(
            supplier.data,
            formData,
            selectedIngredientProduct
          ),
        };

        const response = await updateSupplierMutation({
          variables: {
            input: {
              ...inputData,
              supplierId: selectedSupplier,
            },
          },
          refetchQueries: [
            {
              query: IngredientDocument,
              variables: {
                input: {
                  ingredientId: selectedIngredient,
                  venueId: selectedVenueObject!.id,
                  ingredientProductId: selectedIngredientProduct,
                },
              },
            },
          ],
        });

        if (response.data?.updateSupplier.successful) {
          dispatch({ type: 'TOGGLE_TOP_SLIDER' });
        }
      } catch (error) {
        console.log('err ###', error);
      }
    } else {
      if (
        formData.displayName.label.toLowerCase() ===
        formData.displayName.value.toLowerCase()
      ) {
        const inputData = {
          ...formatSupplierFormData(
            supplier.data,
            formData,
            selectedIngredientProduct
          ),
        };

        try {
          const response = await createSupplierMutation({
            variables: {
              input: inputData,
            },
            refetchQueries: [
              {
                query: IngredientDocument,
                variables: {
                  input: {
                    ingredientId: selectedIngredient,
                    venueId: selectedVenueObject!.id,
                    ingredientProductId: selectedIngredientProduct,
                  },
                },
              },
            ],
          });
          if (response.data?.createSupplier.successful) {
            await waitToUpdateSupplier(
              response.data.createSupplier.supplier!.id
            );
            dispatch({ type: 'TOGGLE_TOP_SLIDER' });
          }
        } catch (error) {
          console.log('err ###', error);
        }
      } else {
        try {
          await updateIngredientProductMutation({
            variables: {
              input: {
                id: selectedIngredientProduct!,
                ingredientId: selectedIngredient!,
                venueId: selectedVenueObject?.id!,
                supplierId: formData.displayName.value,
              },
            },
          });
          await waitToUpdateSupplier(formData.displayName.value);
        } catch (error) {
          console.log('err ###', error);
        }
      }
    }
  };

  const sideLabel = (label: string, marginTop?: boolean) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel marginTop={marginTop}>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  if (supplier.loading || updateIngredientProduct.loading) {
    return <LogoLoading size={60} />;
  }

  const saveOrAdd = selectedSupplier ? 'Save' : 'Add';
  const savingOrAdding = selectedSupplier ? 'Saving' : 'Adding';
  const editOrAdd = selectedSupplier ? 'Edit' : 'Add';

  const isLoading =
    createSupplier.loading ||
    updateSupplier.loading ||
    updateIngredientProduct.loading;

  return (
    <LayoutPage
      backButton
      backButtonCTA={() => handleCancel()}
      align="center"
      withLine
      heading={`${editOrAdd} Supplier`}
      subHeading={
        selectedSupplier || editSupplier
          ? `Update ${displayName} details`
          : selectedIngredient
          ? `Find or Add a new supplier for ${ingredient.data?.ingredient.ingredient?.displayName}`
          : 'Find or Add a new Supplier'
      }
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={handleCancel}>
                x
              </Button>,
              <SmallButton
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) => (loading ? savingOrAdding : saveOrAdd)}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </SmallButton>,
            ]
          : [
              <Button color="default" inversed onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) =>
                    `${loading ? savingOrAdding : saveOrAdd} Supplier`
                  }
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <Container>
        <Header>
          <H3 color="grey">
            {selectedSupplier && typeof displayName === 'string' && displayName}{' '}
            Supplier Details
          </H3>
          {selectedSupplier && (
            <AddSupplier onClick={handleAddSupplier}>
              Add New Supplier
            </AddSupplier>
          )}
        </Header>
        <Form autoComplete="off">
          <LayoutColumn>
            {sideLabel('Lookup Supplier', true)}
            <Address
              label="Name / Address"
              placeholder="Name / Address"
              control={control}
              getFormattedFormValues={formatAddressLookupValues}
              errors={getAddressErrors(errors)}
              getValues={getValues}
              reset={reset}
              initialAddressValues={
                selectedSupplier
                  ? supplier?.data?.supplier?.supplier?.address
                  : undefined
              }
            />
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Company Name *')}
            {supplierGroupData ? (
              <Input
                ref={register}
                label={fieldLabel('Company Name *')}
                name="supplierGroupDisplayName"
                errorText={
                  errors.supplierGroupDisplayName &&
                  errors.supplierGroupDisplayName.message
                }
              />
            ) : (
              <SelectList
                autoComplete
                freeSolo
                control={control}
                name="supplierGroupDisplayName"
                label={fieldLabel('Company Name *')}
                handleChange={(props) => handleNewSelectedSupplierGroup(props)}
                errorText={
                  errors.supplierGroupDisplayName &&
                  // @ts-expect-error
                  errors.supplierGroupDisplayName.value &&
                  // @ts-expect-error
                  errors.supplierGroupDisplayName.value.message
                }
                options={availableSupplierGroups!}
              />
            )}
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Location Name *')}
            {selectedSupplier || editSupplier ? (
              <Input
                ref={register}
                label={fieldLabel('Location Name *')}
                name="displayName"
                onChange={(input: string | number | null) =>
                  checkDisplayNameChange(input)
                }
                errorText={errors.displayName && errors.displayName.message}
                helperText={
                  showDisplayNamePrompt
                    ? 'Thank you for helping to fix up spelling errors, please note your changes will go through a review process prior to being updated'
                    : undefined
                }
              />
            ) : (
              <SelectList
                autoComplete
                freeSolo
                control={control}
                name="displayName"
                label={fieldLabel('Location Name *')}
                handleChange={(props) => handleNewSelectedSupplier(props)}
                errorText={
                  errors.displayName &&
                  // @ts-expect-error
                  errors.displayName.value &&
                  // @ts-expect-error
                  errors.displayName.value.message
                }
                options={availableSuppliers!}
              />
            )}
          </LayoutColumn>

          <LayoutColumn>
            {sideLabel('Website *')}
            <Input
              ref={register}
              label={fieldLabel('Website *')}
              name="website"
              errorText={errors.website && errors.website.message}
            />
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Email')}
            <Input
              ref={register}
              label={fieldLabel('Email')}
              name="email"
              errorText={errors.email && errors.email.message}
            />
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Phone')}
            <Input
              ref={register}
              label={fieldLabel('Phone')}
              name="phone"
              errorText={errors.phone && errors.phone.message}
            />
          </LayoutColumn>
          <LayoutColumn>
            <Controller
              as={<Input className="hiddenInput" />}
              control={control}
              name="supplierGroupId"
              defaultValue={
                selectedSupplier
                  ? supplier.data?.supplier.supplier?.supplierGroup?.id
                  : ''
              }
            />
          </LayoutColumn>
          <LayoutColumn>
            <Controller
              as={<Input className="hiddenInput" />}
              control={control}
              name="internationalPhone"
              defaultValue={
                selectedSupplier
                  ? supplier.data?.supplier.supplier?.internationalPhone
                  : selectedSupplier
              }
            />
          </LayoutColumn>
        </Form>
      </Container>
      <ButtonGroup>
        <Button color="default" inversed onClick={handleCancel}>
          Cancel
        </Button>

        <Button
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <DotsLoading
            text={(loading) =>
              `${loading ? savingOrAdding : saveOrAdd} Supplier`
            }
            isLoading={isLoading}
            size="small"
            lineHeight={10}
            noMargin
          />
        </Button>
      </ButtonGroup>
    </LayoutPage>
  );
};

const displayNameObject = {
  value: Yup.string().required('Supplier name is required'),
};

const editValidationSchema = Yup.object().shape({
  displayName: Yup.string().required('Supplier name is required'),
  addressLine1: Yup.string()
    .required('Address Line 1 is required')
    .typeError('Address Line 1 is required'),
  city: Yup.string().required('City is required').typeError('City is required'),
  state: Yup.string()
    .required('State is required')
    .typeError('State is required'),
  country: Yup.string()
    .required('Country is required')
    .typeError('Country is required'),
  postcode: Yup.string()
    .typeError('Postcode is required')
    .required('Postcode is required'),
  website: Yup.string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-zA-Z0-9]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      'Please check website url format'
    )
    .required('Website is required'),
});

const createValidationSchema = Yup.object().shape({
  displayName: Yup.object()
    .shape(displayNameObject)
    .required('Supplier name is required')
    .typeError('Supplier name is required'),
  addressLine1: Yup.string()
    .required('Address Line 1 is required')
    .typeError('Address Line 1 is required'),
  city: Yup.string().required('City is required').typeError('City is required'),
  state: Yup.string()
    .required('State is required')
    .typeError('State is required'),
  country: Yup.string()
    .required('Country is required')
    .typeError('Country is required'),
  postcode: Yup.string()
    .typeError('Postcode is required')
    .required('Postcode is required'),
  website: Yup.string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-zA-Z0-9]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      'Please check website url format'
    )
    .required('Website is required'),
});

export default Supplier;
