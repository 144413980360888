import React, { FC, useContext } from 'react';
import { ILayoutContentProps } from '../Layout.types';
import { LayoutContainer } from './LayoutContent.styles';
import { theme } from '../../../../styles/theme';
import { LayoutContext } from '../../../../contexts/layoutContext';

const LayoutContent: FC<ILayoutContentProps> = ({ width, children }) => {
  const {
    menuToggle,
    sliderToggle,
    extraSliderToggle,
    topSliderToggle,
    selectedIngredient,
    selectedIngredientProduct,
    showMenuOverlay,
    addVenue,
    dispatch,
  } = useContext(LayoutContext);

  const handleLayoutClick = () => {
    if (extraSliderToggle) {
      dispatch({ type: 'HIDE_TOOL_TIP' });
      dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
      dispatch({ type: 'CLOSE_EXTRA_SLIDER', payload: true });
    }

    if (topSliderToggle) {
      dispatch({ type: 'HIDE_TOOL_TIP' });
      dispatch({ type: 'TOGGLE_TOP_SLIDER' });
      dispatch({ type: 'CLOSE_TOP_SLIDER', payload: true });
    }

    if (sliderToggle) {
      dispatch({ type: 'HIDE_TOOL_TIP' });
      dispatch({ type: 'TOGGLE_SLIDER' });
      dispatch({ type: 'CLOSE_SLIDER', payload: true });
      dispatch({ type: 'DUPLICATE_RECIPE', payload: false });
      dispatch({ type: 'SCALE_RECIPE', payload: false });
    }

    if (sliderToggle || extraSliderToggle) {
      if (selectedIngredient || selectedIngredientProduct) {
        dispatch({ type: 'SELECT_INGREDIENT', payload: '' });
        dispatch({ type: 'SELECT_INGREDIENT_PRODUCT', payload: '' });
      }
    }

    if (menuToggle === true && width < theme.mQ.tablet) {
      dispatch({ type: 'TOGGLE_MENU' });
      dispatch({ type: 'CLOSE_MENU' });
    }

    if (addVenue) {
      dispatch({ type: 'ADD_VENUE', payload: false });
    }

    if (showMenuOverlay) {
      dispatch({ type: 'SHOW_MENU_OVERLAY', payload: false });
    }
  };

  return (
    <LayoutContainer isActive={!sliderToggle} onClick={handleLayoutClick}>
      {children}
    </LayoutContainer>
  );
};

export default LayoutContent;
