import React, { FC, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import { AccountType, useLoginMutation } from '../../../generated/graphql';
import { setTeamSatus } from '../../../utils/accessTeam';
import { setAccessToken } from '../../../utils/accessToken';
import { TextButton } from '../../shared/button/TextButton.styles';
import { Input } from '../../shared/formElements';
import { AngleBox } from '../../shared/layout';
import { DotsLoading } from '../../shared/loading';
import { ErrorMessage, H3 } from '../../shared/typefaces/Typefaces.styles';
import {
  Box,
  Container,
  Content,
  Form,
  Heading,
  Left,
  Main,
  SubmitButton,
  Right,
} from './Login.styles';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

interface Props {
  history: RouteComponentProps['history'];
  showForgotPasswordForm: () => void;
}

const Login: FC<Props> = ({ history, showForgotPasswordForm }) => {
  const { newAccount, dispatch } = useContext(LayoutContext);
  const { setInitialActiveSeconds } = useActiveTimeTracker();

  const [login, { data, loading, client }] = useLoginMutation();
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formData) => {
    const { email, password } = formData;
    if (!loading) {
      try {
        await client?.resetStore();
        const response = await login({
          variables: {
            input: {
              email,
              password,
            },
          },
        });

        if (response?.data?.login.successful) {
          const { accessToken, user } = response.data.login;

          if (accessToken) {
            setAccessToken(accessToken);
          }
          history.push('/recipes');

          if (user?.accountType === AccountType.RecipeRevenue) {
            setTeamSatus(true);
          }
          dispatch({
            type: 'SET_ACCOUNT',
            payload: {
              type: user?.accountType,
              billingCycle: user?.billingCycle,
              addOns: user?.addOns,
            },
          });
          dispatch({
            type: 'SET_NEW_ACCOUNT',
            payload: {
              ...newAccount,
              type: user?.accountType,
              billingCycle: user?.billingCycle,
              addOns: user?.addOns,
            },
          });

          dispatch({
            type: 'SET_USER_DETAILS',
            payload: {
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              isEmailConfirmed: user?.isEmailConfirmed,
              phone: user?.internationalPhone,
              position: user?.position,
              address: user?.address?.addressLookup || user?.billingAddress,
            },
          });

          const selectedVenue = user?.venuesSharedWithYou.find(
            (venue) => venue.selected
          );

          if (selectedVenue) {
            const venueData = {
              id: selectedVenue!.venue.id,
              displayName: selectedVenue!.venue.displayName,
              address: selectedVenue!.venue.address,
              email: selectedVenue!.venue.email,
              totalActiveSeconds: selectedVenue!.totalActiveSeconds,
              recipeProfitIncreasePerYear: selectedVenue!.venue
                .recipeProfitIncreasePerYear,
            };
            dispatch({
              type: 'SELECT_VENUE_OBJECT',
              payload: venueData,
            });
            setInitialActiveSeconds(venueData.totalActiveSeconds || 0);
          } else {
            const venueData = {
              id: user?.venuesSharedWithYou[0].venue.id,
              displayName: user?.venuesSharedWithYou[0].venue.displayName,
              address: user?.venuesSharedWithYou[0].venue.address,
              email: user?.venuesSharedWithYou[0].venue.email,
              totalActiveSeconds:
                user?.venuesSharedWithYou[0].totalActiveSeconds,
              recipeProfitIncreasePerYear:
                user?.venuesSharedWithYou[0].venue.recipeProfitIncreasePerYear,
            };
            dispatch({
              type: 'SELECT_VENUE_OBJECT',
              payload: venueData,
            });
            setInitialActiveSeconds(venueData.totalActiveSeconds || 0);
          }
        }
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  const responseErrors = data?.login.error && (
    <ErrorMessage fontSize="small">{data?.login.error}</ErrorMessage>
  );

  return (
    <Container>
      <AngleBox color="primary" marginTop="large" />
      <Main>
        <Content>
          <Left>
            <div>
              <Heading>Welcome Back</Heading>
            </div>
          </Left>
          <Right>
            <Box>
              <AngleBox color="secondary" marginTop="small" height="tiny" />
              <H3 className="heading">Sign in</H3>
              <div className="content">
                {responseErrors}
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    ref={register}
                    label="Email"
                    name="email"
                    errorText={errors.email?.message}
                  />
                  <Input
                    ref={register}
                    label="Password"
                    name="password"
                    type="password"
                    errorText={errors.password?.message}
                  />
                </Form>
                <SubmitButton
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  color="primary"
                  disabled={loading}
                >
                  <DotsLoading
                    text={(loading) => `${loading ? 'Signing' : 'Sign'} in`}
                    isLoading={loading}
                    size="small"
                    lineHeight={10}
                    noMargin
                  />
                </SubmitButton>
                <TextButton
                  type="submit"
                  onClick={showForgotPasswordForm}
                  disabled={loading}
                >
                  Forgot Password?
                </TextButton>
              </div>
            </Box>
          </Right>
        </Content>
      </Main>
    </Container>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Please check email format'),
  password: Yup.string().required('Password is required'),
});
export default Login;
