import styled from 'styled-components/macro';
import { H2, Label } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding: 0 15px;
`;

export const Heading = styled(H2)`
  text-align: center;
`;

export const SubHeading = styled(Label)`
  display: block;
  text-align: center;
  margin-top: 5px;
`;
