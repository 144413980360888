import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useForm, FormProvider } from 'react-hook-form';
import { LayoutPage } from '../../shared/layout';
import { theme } from '../../../styles/theme';
import { SmallButton } from '../ingredient/Ingredient.styles';
import { Button } from '../../shared/button';
import {
  Container,
  Table,
  Col,
  ButtonStyled,
  HeaderRow,
} from './UpdateNotifications.styles';
import { Card, Header, Content } from '../../shared/card';
import { Label } from '../../shared/typefaces/Typefaces.styles';
import { mockData } from '../../../utils/mockData';
import NotificationItem from './sections/NotificationItem';
import { Email } from '../../shared/icons';

interface IProps {}

const UpdateNotifications: FC<IProps> = () => {
  const { appWidth, dispatch } = useContext(LayoutContext);
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const onSubmit = (data) => {
    dispatch({ type: 'TOGGLE_SLIDER' });
  };

  const closeOverlay = () => {
    dispatch({ type: 'TOGGLE_SLIDER' });
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow>
          <Col>
            <Label>Notification Type</Label>
          </Col>
          <Col>
            <Label>Status</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow>
          <Col />
          <Col>
            <Label>Notification Type</Label>
          </Col>
          <Col>
            <Label>Status</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  return (
    <LayoutPage
      backButton
      align="center"
      withLine
      heading="Edit Notifications"
      subHeading="Update notifications and get notified on your terms"
      slider
      backButtonCTA={closeOverlay}
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button
                color="default"
                asCircle
                inversed
                onClick={() => dispatch({ type: 'TOGGLE_SLIDER' })}
              >
                x
              </Button>,
              <SmallButton
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
              >
                Save
              </SmallButton>,
            ]
          : [
              <Button
                color="default"
                inversed
                onClick={() => dispatch({ type: 'TOGGLE_SLIDER' })}
              >
                Cancel
              </Button>,
              <ButtonStyled
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
              >
                Save Notifications
              </ButtonStyled>,
            ]
      }
    >
      <Container>
        <Card>
          <Header
            icon={<Email size="small" />}
            heading={
              appWidth !== 0 && appWidth < theme.mQ.tablet
                ? 'Update Email Notifications'
                : 'Email Notifications'
            }
            subHeading="Actions speak louder than words! We will only email you with information that is valuable to you"
          />
          <Content fullWidth>
            <FormProvider {...methods}>
              <form
                autoComplete="off"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <Table>
                  {getTableHeader()}
                  {mockData.notifications.map((col, idx) => (
                    <NotificationItem key={idx} col={col} />
                  ))}
                </Table>
              </form>
            </FormProvider>
          </Content>
        </Card>
      </Container>
    </LayoutPage>
  );
};

export default UpdateNotifications;
