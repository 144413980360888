import React, { FC, useState, useEffect } from 'react';
import { Container, Slide, Dots, Dot } from './Slides.styles';
import { ISlidesProps } from './slides.types';

const Slides: FC<ISlidesProps> = ({
  slides,
  interval,
  className,
  hideDots,
}) => {
  const [slideCount, setSlideCount] = useState(1);

  useEffect(() => {
    const setInt = setInterval(() => {
      if (slideCount === slides.length) {
        setSlideCount(1);
      } else {
        setSlideCount(slideCount + 1);
      }
    }, interval);

    return () => clearInterval(setInt);
  });

  const handleSlideChange = (idx) => {
    setSlideCount(idx + 1);
  };

  return (
    <Container className={className}>
      {slides.map((slide, idx) => {
        return (
          <Slide key={idx} count={idx + 1} currentSlide={slideCount}>
            <div>{slide}</div>
          </Slide>
        );
      })}
      {!hideDots && (
        <Dots>
          <div>
            {slides.map((s, idx) => (
              <Dot
                faded={slideCount !== idx + 1 ? true : false}
                key={idx}
                onClick={() => handleSlideChange(idx)}
              />
            ))}
          </div>
        </Dots>
      )}
    </Container>
  );
};

export default Slides;
