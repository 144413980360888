import React, { FC } from 'react';
import { ErrorIcon, Tick } from '../icons';
import { Span } from '../typefaces/Typefaces.styles';
import { Circle, Container, Content, Heading } from './Toast.styles';

interface IToastProps {
  heading?: string;
  className?: string;
  message: string;
  type: 'error' | 'success';
  addMargin?: boolean;
}

export const Toast: FC<IToastProps> = ({
  heading,
  className,
  type,
  message,
  addMargin,
}) => {
  return (
    <Container type={type} addMargin={addMargin} className={className}>
      {type === 'success' ? (
        <Circle>
          <Tick size="tiny" color="white" />
        </Circle>
      ) : (
        <ErrorIcon className="error" size="small" />
      )}
      <Content>
        <Heading>{heading}</Heading>
        <Span className="text">{message}</Span>
      </Content>
    </Container>
  );
};
