import React from 'react';
import { LogoLoading } from '../../shared/loading';
import { Stocktake } from '../../shared/stocktake';
import { useHistory } from 'react-router-dom';
import { useStocktake } from '../../../utils/customHooks/useSocktake';

const EditStocktake = () => {
  const { stocktake, stocktakeId } = useStocktake();
  const { push } = useHistory();

  if (!stocktakeId) {
    push('/stocktakes');
  }

  if (stocktake === null) {
    return <LogoLoading size={60} />;
  }

  return <Stocktake selectedStocktake={stocktake} />;
};

export default EditStocktake;
