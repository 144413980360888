import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Drink: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2012 4981 c-216 -59 -232 -68 -232 -124 0 -22 159 -637 177 -684 4
-10 -54 -13 -270 -13 -256 0 -276 -1 -298 -19 -23 -19 -24 -25 -29 -198 l-5
-178 -59 -5 c-45 -4 -62 -11 -77 -29 -27 -33 -24 -77 6 -107 23 -23 33 -25 80
-22 l55 3 0 -47 c0 -34 -4 -48 -14 -48 -7 0 -45 -16 -83 -35 -86 -43 -144
-103 -186 -193 l-32 -67 0 -1416 0 -1415 37 -76 c45 -91 103 -147 196 -191
l67 -32 1055 0 1055 0 67 32 c93 44 151 100 196 191 l37 76 3 207 4 207 176 4
c191 5 246 18 354 81 101 59 197 182 240 305 l23 67 0 665 c0 737 3 698 -67
832 -36 69 -128 166 -193 204 -111 64 -165 77 -356 82 l-176 4 -5 88 c-9 158
-86 278 -221 345 -38 19 -76 35 -84 35 -11 0 -13 23 -11 123 l3 122 59 5 c45
4 62 11 77 29 27 33 24 77 -6 107 -23 23 -33 25 -80 22 l-55 -3 0 98 c0 91 -2
100 -25 122 l-24 25 -425 0 -425 0 -14 48 c-8 26 -58 214 -113 417 -105 395
-114 416 -171 414 -15 -1 -120 -27 -231 -58z m58 -155 c0 -2 -19 -37 -42 -77
l-42 -72 -16 59 c-9 32 -15 60 -14 62 2 4 91 29 107 31 4 1 7 -1 7 -3z m190
-243 l41 -153 -85 -146 c-61 -105 -86 -141 -90 -127 -20 68 -76 283 -76 296 0
18 163 303 167 291 1 -5 21 -78 43 -161z m144 -533 c20 -75 36 -144 36 -154 0
-21 -162 -304 -167 -290 -1 5 -21 77 -43 160 l-40 152 81 141 c45 78 85 138
89 134 4 -4 24 -69 44 -143z m-344 -267 c32 -117 61 -224 64 -238 l6 -25 -305
0 -305 0 0 49 0 48 93 7 c262 18 260 18 284 42 31 32 30 78 -3 111 -25 25 -29
25 -107 19 -45 -3 -124 -9 -174 -12 l-93 -7 0 112 0 111 242 -2 241 -3 57
-212z m1220 167 c0 -33 -4 -50 -12 -50 -7 0 -154 -9 -325 -21 -172 -12 -316
-20 -319 -16 -4 3 -15 36 -25 72 l-18 65 350 0 349 0 0 -50z m0 -320 l0 -110
-285 0 -284 0 -11 43 c-6 23 -18 63 -25 90 l-14 47 32 1 c18 0 133 9 257 18
124 10 249 19 278 20 l52 1 0 -110z m-740 -97 c0 -9 -19 -13 -60 -13 l-61 0
17 33 c9 17 28 50 42 72 l26 40 18 -60 c10 -33 18 -66 18 -72z m-5 -1520 c198
-740 365 -1356 370 -1367 6 -12 23 -27 37 -34 24 -11 50 -6 235 43 114 31 218
63 230 71 13 8 25 25 29 39 4 17 -108 451 -338 1307 l-343 1283 315 3 c226 2
328 -1 360 -10 58 -16 141 -96 158 -154 17 -60 17 -2728 0 -2788 -16 -55 -99
-138 -154 -154 -60 -17 -2008 -17 -2068 0 -55 16 -138 99 -154 154 -17 60 -17
2728 0 2788 15 51 99 137 148 153 23 7 171 11 425 12 l390 1 360 -1347z m55
1340 c0 -18 -166 -290 -171 -280 -4 7 -21 66 -38 132 -18 66 -34 128 -37 138
-5 16 5 17 120 17 69 0 126 -3 126 -7z m103 -384 c21 -75 37 -145 37 -154 0
-15 -139 -267 -159 -287 -4 -5 -26 63 -49 151 l-42 158 82 141 c45 78 85 139
88 134 4 -4 23 -68 43 -143z m1479 -131 c91 -45 147 -103 191 -196 l32 -67 0
-655 0 -655 -32 -67 c-44 -93 -100 -151 -191 -196 l-76 -37 -168 -4 -168 -3 0
81 0 81 138 0 c173 0 214 12 275 80 24 26 49 66 55 88 17 56 17 1208 0 1264
-6 22 -31 62 -55 88 -61 68 -102 80 -275 80 l-138 0 0 81 0 81 168 -3 168 -4
76 -37z m-1333 -417 l42 -153 -83 -145 c-97 -168 -84 -172 -139 43 l-33 131
74 129 c74 130 91 157 95 152 2 -2 21 -72 44 -157z m1216 114 l25 -24 0 -591
0 -591 -25 -24 c-23 -24 -29 -25 -160 -25 l-135 0 0 640 0 640 135 0 c131 0
137 -1 160 -25z m-1072 -650 c21 -77 37 -146 37 -154 0 -14 -148 -278 -160
-286 -4 -2 -26 66 -49 152 l-41 156 82 144 c45 78 85 139 88 135 4 -4 23 -70
43 -147z m146 -544 l42 -155 -82 -143 c-46 -79 -86 -140 -89 -136 -8 10 -80
282 -80 302 0 8 12 35 27 60 93 162 135 232 138 229 2 -2 22 -72 44 -157z
m125 -467 c7 -31 7 -31 -54 -47 l-61 -15 28 47 c15 25 34 58 42 74 l16 27 11
-27 c7 -16 15 -42 18 -59z"
        />
        <path
          d="M3120 3187 c-49 -16 -133 -102 -148 -153 -28 -94 -8 -169 63 -239 51
-52 102 -75 165 -75 63 0 114 23 165 75 98 97 101 222 8 325 -65 72 -158 97
-253 67z m135 -172 c16 -15 25 -36 25 -55 0 -19 -9 -40 -25 -55 -15 -16 -36
-25 -55 -25 -19 0 -40 9 -55 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 15 16
36 25 55 25 19 0 40 -9 55 -25z"
        />
        <path
          d="M1625 3015 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
-80 80 -19 0 -40 -9 -55 -25z"
        />
        <path
          d="M1465 2455 l-27 -26 3 -143 c4 -141 5 -143 41 -218 45 -91 103 -148
196 -191 63 -30 75 -32 209 -35 l142 -4 26 27 27 26 -4 142 c-3 134 -5 146
-35 209 -43 93 -100 151 -191 196 -75 36 -77 37 -218 41 l-143 3 -26 -27z
m289 -147 c55 -16 138 -99 154 -154 7 -23 12 -67 12 -98 l0 -56 -56 0 c-87 0
-133 18 -189 75 -57 56 -75 102 -75 189 l0 56 56 0 c31 0 75 -5 98 -12z"
        />
        <path
          d="M3225 2375 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
-80 80 -19 0 -40 -9 -55 -25z"
        />
        <path
          d="M1541 1594 c-63 -68 -116 -162 -149 -262 -22 -67 -26 -94 -26 -212 0
-118 4 -145 26 -212 39 -119 92 -204 182 -294 90 -90 175 -143 294 -182 67
-22 94 -26 212 -26 118 0 145 4 212 26 100 33 194 86 262 149 48 44 56 56 56
87 0 35 -22 59 -473 509 -450 451 -474 473 -509 473 -31 0 -43 -8 -87 -56z
m219 -269 l125 -125 -178 0 c-140 0 -177 3 -177 13 0 45 80 237 99 237 3 0 62
-56 131 -125z m120 -288 c0 -1 -56 -58 -124 -126 l-124 -124 -32 49 c-28 45
-70 158 -70 190 0 11 32 14 175 14 96 0 175 -1 175 -3z m120 -292 c0 -160 -1
-175 -17 -175 -31 1 -154 48 -195 75 l-41 27 124 124 c68 68 125 124 126 124
2 0 3 -79 3 -175z m410 -76 c0 -19 -192 -99 -237 -99 -10 0 -13 37 -13 177 l0
178 125 -125 c69 -69 125 -128 125 -131z"
        />
        <path
          d="M2185 1575 c-50 -49 -15 -135 55 -135 19 0 40 9 55 25 16 15 25 36
25 55 0 19 -9 40 -25 55 -15 16 -36 25 -55 25 -19 0 -40 -9 -55 -25z"
        />
      </g>
    </IconStyled>
  );
};

export default Drink;
