import styled from 'styled-components/macro';
import { H3, Span } from '../../typefaces/Typefaces.styles';
import { Link } from 'react-router-dom';
import { ButtonStyled } from '../../button/Button.styles';
import { IContainerProps } from './layoutOverlay.types';
import { device } from '../../../../styles/device';

export const Container = styled.div<IContainerProps>`
  display: ${({ showOverlay }) => (showOverlay ? 'flex' : 'none')};
  background-color: ${({ theme }) => theme.colors.white.faded};
  height: 100%;
  width: 100%;
  position: fixed;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.overlay};
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(100% - 15px)')};

  ${ButtonStyled} {
    width: fit-content;
    margin: 0 auto;
  }

  @media ${device.mobileL} {
    width: ${({ sliderToggle, fullWidth }) =>
      fullWidth
        ? '100%'
        : sliderToggle
        ? 'calc(100% - 101px)'
        : 'calc(100% - 50px)'};
  }

  @media ${device.tablet} {
    width: ${({ isOpen, sliderToggle, fullWidth }) =>
      fullWidth
        ? '100%'
        : isOpen
        ? sliderToggle
          ? 'calc(100% - 200px - 101px)'
          : 'calc(100% - 200px)'
        : sliderToggle
        ? 'calc(100% - 50px - 101px)'
        : 'calc(100% - 50px)'};
  }
`;

export const CardStyled = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.default.default};
  background-color: ${({ theme }) => theme.colors.white.default};
  margin: 30px auto;
  border-radius: 30px;
  box-shadow: ${({ theme }) => theme.shadow.box};
`;

export const Content = styled.div`
  width: 100%;
  padding: 60px 15px;
  display: flex;
  flex-direction: column;

  .errorMessage {
    margin: 20px 0 0;
  }
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  margin: 0 auto 60px;
`;

export const Heading = styled(H3)`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.large};
  padding-bottom: 10px;
`;

export const SubHeading = styled(Span)`
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeight.medium};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
  text-align: center;
`;

export const LinkStyled = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.button};
  color: ${({ theme }) => theme.colors.text.grey};
  text-align: center;
  margin-top: 15px;

  &:hover {
    color: ${({ theme }) => theme.colors.text.black};
  }
`;

export const Cancel = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.default.default};
    background-color: ${({ theme }) => theme.colors.background};
  }

  .cross {
    transform-origin: center;
    transform: rotate(45deg);
    font-size: ${({ theme }) => theme.fontSize.heading};
  }
`;
