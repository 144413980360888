import React, { FC, useContext, memo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { Edit, RecipeBook, Share } from '../../../../shared/icons';
import { DotsLoading } from '../../../../shared/loading';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { HeaderRow, Col, Row, Icon, Table } from './StocktakeList.styles';
import { Card, Content, Header } from '../../../../shared/card';
import { Button } from '../../../../shared/button';
import { QueryResult } from '@apollo/client';
import {
  StocktakesQuery,
  Exact,
  GetStocktakesInput,
  Stocktake,
  User,
} from '../../../../../generated/graphql';
import { getPercentageDifference } from '../../../../../utils/helper/numbers';
import useResizeDimensions from '../../../../../utils/customHooks/useResizeDimensions';

interface IStocktakeListProps {
  stocktakes: QueryResult<
    StocktakesQuery,
    Exact<{
      input: GetStocktakesInput;
    }>
  >;
  isLoading: boolean;
  handleAddStocktake: () => void;
}

interface IHandleEditSectionProps {
  id: string;
}

export const StocktakeList: FC<IStocktakeListProps> = ({
  stocktakes,
  isLoading,
  handleAddStocktake,
}) => {
  const { appWidth, toolTips, dispatch } = useContext(LayoutContext);
  const history = useHistory();
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeDimensions(pageWidthRef);
  const stocktakeDifferenceRef = useRef<HTMLLIElement>(null);
  const stocktakeDifferenceDimensions = useResizeDimensions(
    stocktakeDifferenceRef
  );
  const totalItemsRef = useRef<HTMLLIElement>(null);
  const totalItemsDimensions = useResizeDimensions(totalItemsRef);
  const totalRequiredItemsRef = useRef<HTMLLIElement>(null);
  const totalRequiredItemsDimensions = useResizeDimensions(
    totalRequiredItemsRef
  );

  const {
    totalItems,
    totalRequiredItems,
    stocktakeDifference,
  } = toolTips.stocktake;
  const noStocktakes = stocktakes.data?.stocktakes.stocktakes?.length === 0;
  const subHeading = noStocktakes
    ? 'Add a stocktake to see your stocktake history below'
    : 'Ordered by most recently completed stocktake at the top';
  const stocktakesLoading = stocktakes.loading || !stocktakes.data;

  const handleEditStocktake = ({ id }: IHandleEditSectionProps) => {
    dispatch({
      type: 'SET_SELECTED_STOCKTAKE',
      payload: id,
    });
    history.push(`/edit-stocktake/${id}`);
  };

  const onDifferenceClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { stocktakeDifference: !stocktakeDifference },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Stocktake Difference',
        content: `This gives you an insight into the difference of quanities you are ordering & the total required amount per stocktake`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: stocktakeDifferenceDimensions.top,
        xAxis: stocktakeDifferenceDimensions.width,
      },
    });
  };

  const onTotalItemsClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { totalItems: !totalItems },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Total Items',
        content: `The total count of items in this section`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: totalItemsDimensions.top,
        xAxis: totalItemsDimensions.width,
      },
    });
  };

  const onTotalRequiredItemsClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { totalRequiredItems: !totalRequiredItems },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Required Items',
        content: `The total count of required items in this section`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: totalRequiredItemsDimensions.top,
        xAxis: totalRequiredItemsDimensions.width,
      },
    });
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow $loading={stocktakesLoading || noStocktakes}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col>
            <Label>Required Items</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow $loading={stocktakesLoading || noStocktakes}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Items</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow $loading={stocktakesLoading || noStocktakes}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Items</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow $loading={stocktakesLoading || noStocktakes}>
          <Col>
            <Label>Name</Label>
          </Col>
          <Col onClick={onDifferenceClick} ref={stocktakeDifferenceRef}>
            <Label className="hasToolTipIcon">Difference</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Items</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow $loading={stocktakesLoading || noStocktakes}>
          <Col />
          <Col>
            <Label>Name</Label>
          </Col>
          <Col onClick={onDifferenceClick} ref={stocktakeDifferenceRef}>
            <Label className="hasToolTipIcon">Difference</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Items</Label>
          </Col>
          <Col onClick={onTotalRequiredItemsClick} ref={totalRequiredItemsRef}>
            <Label className="hasToolTipIcon">Required Items</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const getTableRows = (
    col: Pick<
      Stocktake,
      | 'id'
      | 'displayName'
      | 'totalItems'
      | 'totalRequiredItems'
      | 'status'
      | 'createdAt'
      | 'updatedAt'
    > & {
      creator: { __typename?: 'User' } & Pick<
        User,
        'id' | 'firstName' | 'lastName'
      >;
    },
    idx
  ) => {
    const difference = getPercentageDifference(
      col.totalRequiredItems,
      col.totalItems
    );

    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <Row key={idx} onClick={() => handleEditStocktake({ id: col.id })}>
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.totalRequiredItems}</Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row key={idx} onClick={() => handleEditStocktake({ id: col.id })}>
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.totalItems}</Span>
          </Col>
          <Col>
            <Span>{col.totalRequiredItems}</Span>
          </Col>
          <Col onClick={() => handleEditStocktake({ id: col.id })}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <Row key={idx} onClick={() => handleEditStocktake({ id: col.id })}>
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>
              {difference && difference !== 0 ? `${difference}%` : '-'}
            </Span>
          </Col>
          <Col>
            <Span>{col.totalItems}</Span>
          </Col>
          <Col>
            <Span>{col.totalRequiredItems}</Span>
          </Col>
          <Col onClick={() => handleEditStocktake({ id: col.id })}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row key={idx} onClick={() => handleEditStocktake({ id: col.id })}>
          <Col />
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>
              {difference && difference !== 0 ? `${difference}%` : '-'}
            </Span>
          </Col>
          <Col>
            <Span>{col.totalItems}</Span>
          </Col>
          <Col>
            <Span>{col.totalRequiredItems}</Span>
          </Col>
          <Col onClick={() => handleEditStocktake({ id: col.id })}>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Card ref={pageWidthRef}>
      <Header
        icon={<RecipeBook size="small" />}
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? 'Add Stocktake'
            : 'Stocktakes'
        }
        subHeading={subHeading}
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button
              color="primary"
              asCircle
              onClick={() => handleAddStocktake()}
            >
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => handleAddStocktake()}
              disabled={isLoading}
            >
              <DotsLoading
                text={(loading) => (loading ? 'Adding' : 'Add Stocktake')}
                isLoading={isLoading}
                size="small"
                lineHeight={10}
                noMargin
              />
            </Button>
          )
        }
        toolTip={{
          type: 'SET_STOCKTAKE_TOOL_TIPS',
          heading: 'Stocktake List',
          content:
            'This is a list of all your Stocktakes that you have completed in the past',
        }}
      />
      <Content fullWidth>
        <Table>
          <DotsLoading
            isLoading={stocktakesLoading}
            size="large"
            lineHeight={10}
            color="default"
          />
          {!noStocktakes && getTableHeader()}
          {stocktakes.data?.stocktakes.stocktakes
            ? stocktakes.data?.stocktakes.stocktakes?.map((col, idx) =>
                getTableRows(col, idx)
              )
            : null}
        </Table>
      </Content>
    </Card>
  );
};

export default memo(StocktakeList);
