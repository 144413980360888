import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Retail: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled
      size={size}
      viewBox="0 0 512.000000 512.000000"
      color={color}
      faded={faded}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M825 5095 c-25 -24 -25 -26 -25 -213 0 -177 1 -191 21 -216 l21 -27
-58 -142 -59 -142 -3 -305 -3 -305 31 -89 c16 -50 30 -95 30 -102 0 -6 -152
-134 -337 -282 -349 -280 -383 -313 -421 -413 -15 -40 -17 -147 -20 -1189 -2
-795 1 -1165 8 -1210 33 -197 185 -366 390 -436 52 -18 100 -19 880 -19 916 0
856 -4 992 67 136 72 255 240 281 398 2 14 5 -75 6 -198 1 -223 1 -223 26
-247 l24 -25 416 0 415 0 0 80 0 80 -360 0 -360 0 0 40 0 40 200 0 200 0 0 80
0 80 -200 0 -200 0 0 40 0 40 360 0 360 0 0 80 0 80 -363 0 -363 0 -13 62
c-27 132 -106 272 -205 366 -30 29 -52 52 -49 52 3 0 32 -7 66 -16 174 -45
573 -76 767 -60 l85 7 -3 57 c-5 108 22 97 -254 97 -263 1 -396 15 -576 60
-97 25 -202 69 -202 85 0 5 15 62 34 127 40 139 78 359 103 593 15 138 17 268
18 800 0 599 1 637 19 673 24 49 95 110 144 125 56 17 889 17 946 0 55 -16
138 -99 154 -154 7 -23 12 -67 12 -98 l0 -56 81 0 82 0 -5 89 c-10 162 -86
279 -226 349 l-76 37 -496 0 -496 0 -76 -37 c-91 -45 -147 -103 -191 -196
l-32 -67 0 -645 c-1 -690 -6 -786 -57 -1105 l-23 -140 -5 385 -5 385 -32 67
c-44 93 -100 151 -191 196 l-76 37 -656 0 -656 0 -76 -37 c-91 -45 -148 -103
-191 -196 -29 -62 -32 -77 -35 -194 l-4 -128 81 0 81 0 0 96 c0 53 5 115 12
138 16 55 99 138 154 154 59 17 1209 17 1268 0 55 -16 138 -99 154 -154 17
-59 17 -1369 0 -1428 -16 -55 -99 -138 -154 -154 -59 -17 -1209 -17 -1268 0
-55 16 -138 99 -154 154 -8 29 -12 143 -12 378 l0 336 -80 0 -80 0 0 -561 c0
-402 3 -573 12 -601 14 -49 76 -120 124 -143 51 -24 195 -44 369 -52 l150 -6
82 42 83 41 140 0 140 0 83 -41 82 -42 150 6 c174 8 318 28 369 52 48 23 110
94 124 143 8 26 12 127 12 291 l0 251 49 -30 c72 -45 174 -158 210 -233 30
-63 61 -170 61 -214 0 -22 -4 -23 -79 -23 l-79 0 -7 -55 c-9 -75 -65 -183
-121 -235 -25 -23 -75 -56 -112 -73 l-67 -32 -815 0 -815 0 -67 32 c-93 44
-151 100 -196 191 l-37 76 0 1165 0 1166 25 45 c20 35 98 103 370 321 l345
278 374 0 374 1 269 -216 c148 -118 271 -214 272 -212 1 2 20 26 42 53 22 28
43 55 47 61 4 6 -101 97 -248 214 -140 112 -255 209 -255 215 0 6 14 51 30
100 27 80 30 101 30 217 l0 128 -80 0 -80 0 0 -80 0 -80 -400 0 -400 0 0 231
0 231 67 169 66 169 267 0 267 0 66 -169 c60 -152 67 -176 67 -240 l0 -71 81
0 82 0 -5 98 c-5 89 -10 108 -63 239 l-57 142 21 27 c20 25 21 39 21 216 0
187 0 189 -25 213 l-24 25 -431 0 -431 0 -24 -25z m775 -215 l0 -80 -320 0
-320 0 0 80 0 80 320 0 320 0 0 -80z m36 -1237 l-13 -38 -343 0 -343 0 -13 38
-13 37 369 0 369 0 -13 -37z m-356 -2998 l656 0 72 36 72 35 0 -77 c0 -104
-12 -118 -116 -135 -43 -7 -137 -16 -209 -20 l-130 -7 -82 42 -83 41 -180 0
-180 0 -83 -41 -82 -42 -130 7 c-71 4 -166 13 -209 20 -104 17 -116 31 -116
135 l0 77 72 -35 72 -36 656 0z"
        />
        <path
          d="M4187 3818 c-17 -18 -39 -92 -96 -320 -72 -292 -74 -298 -98 -298
-56 0 -109 -25 -158 -75 -57 -56 -75 -102 -75 -189 0 -45 -3 -56 -16 -56 -77
0 -189 -88 -212 -166 -17 -60 -17 -2488 0 -2548 16 -55 99 -138 154 -154 30
-9 198 -12 636 -12 680 0 657 -2 731 80 24 26 49 66 55 88 17 57 17 2487 0
2544 -17 59 -88 133 -145 152 -26 9 -55 16 -65 16 -15 0 -18 9 -18 56 0 81
-18 129 -67 184 -44 49 -107 80 -164 80 l-27 0 -73 298 c-57 228 -79 302 -96
320 -20 20 -31 22 -133 22 -102 0 -113 -2 -133 -22z m211 -378 l60 -240 -138
0 -138 0 60 240 c50 199 63 240 78 240 15 0 28 -41 78 -240z m297 -425 c21
-20 25 -34 25 -80 l0 -55 -400 0 -400 0 0 55 c0 46 4 60 25 80 l24 25 351 0
351 0 24 -25z m240 -320 l25 -24 0 -256 0 -255 -640 0 -640 0 0 255 0 256 25
24 24 25 591 0 591 0 24 -25z m25 -1335 l0 -640 -640 0 -640 0 0 640 0 640
640 0 640 0 0 -640z m0 -975 c0 -173 0 -176 -25 -200 l-24 -25 -591 0 -591 0
-24 25 c-25 24 -25 27 -25 200 l0 175 640 0 640 0 0 -175z"
        />
        <path
          d="M4240 1864 c0 -48 -2 -55 -17 -50 -41 13 -129 26 -174 26 l-49 0 0
-80 0 -80 53 -1 c50 -1 128 -15 97 -18 -26 -2 -141 -60 -180 -91 -51 -40 -96
-113 -115 -185 -82 -317 206 -629 535 -578 335 52 523 447 335 703 -38 51
-115 109 -177 130 -20 8 -35 17 -32 21 2 5 31 11 64 15 l60 7 0 78 0 79 -32 0
c-30 0 -130 -19 -185 -36 -22 -6 -23 -4 -23 55 l0 61 -80 0 -80 0 0 -56z m244
-370 c66 -23 131 -87 146 -144 18 -67 6 -151 -32 -222 -35 -66 -67 -97 -142
-136 -43 -22 -64 -26 -136 -26 -78 -1 -90 2 -148 33 -100 54 -157 139 -168
249 -15 137 60 231 207 262 62 13 215 4 273 -16z"
        />
        <path
          d="M2980 3421 c-99 -31 -189 -105 -227 -186 -16 -35 -18 -35 -80 -35
-55 0 -68 -4 -88 -25 -31 -30 -31 -45 4 -180 l28 -111 -29 -26 c-15 -14 -28
-35 -28 -46 0 -11 56 -263 124 -558 105 -455 128 -541 146 -556 20 -16 48 -18
290 -18 243 0 270 2 290 18 17 14 30 54 61 187 22 92 36 172 32 175 -12 11
-143 38 -147 31 -2 -3 -16 -61 -31 -128 l-28 -123 -177 0 -176 0 -101 437
-101 437 29 27 c36 33 36 46 0 187 l-29 112 45 0 c55 0 87 29 97 88 13 85 117
152 236 152 119 0 223 -67 236 -152 4 -23 17 -52 30 -65 21 -21 32 -23 119
-23 l95 0 0 80 0 80 -48 0 c-45 0 -49 2 -65 35 -39 81 -138 160 -236 189 -72
21 -201 20 -271 -3z"
        />
        <path d="M320 1920 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
        <path
          d="M1065 1986 c-71 -18 -123 -48 -172 -98 -87 -89 -112 -209 -74 -357
35 -139 111 -242 218 -297 l58 -29 300 0 c286 0 302 1 346 21 94 44 163 139
176 242 18 158 -120 307 -282 304 -87 -2 -125 13 -125 47 0 123 -248 216 -445
167z m246 -169 c30 -12 38 -22 47 -59 6 -25 20 -56 31 -70 33 -41 115 -71 209
-75 93 -3 120 -16 147 -67 21 -40 15 -91 -17 -131 -40 -51 -66 -55 -336 -55
-240 0 -250 1 -292 23 -48 25 -103 96 -120 155 -22 73 -24 156 -5 196 21 43
65 80 115 95 46 14 176 7 221 -12z"
        />
        <path d="M1120 1680 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
        <path d="M3280 320 l0 -80 80 0 80 0 0 80 0 80 -80 0 -80 0 0 -80z" />
      </g>
    </IconStyled>
  );
};

export default Retail;
