import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useMeQuery, useUpdateUserMutation } from '../../../generated/graphql';
import { theme } from '../../../styles/theme';
import { mockData } from '../../../utils/mockData';
import { Button } from '../../shared/button';
import { Address, Input, SelectList } from '../../shared/formElements';
import { LayoutPage } from '../../shared/layout';
import { DotsLoading, LogoLoading } from '../../shared/loading';
import { Toast } from '../../shared/toast';
import { SmallButton } from '../ingredient/Ingredient.styles';
import { CTAButton, Container, Form, Group, Header } from './Profile.styles';
import { H4 } from '../../shared/typefaces/Typefaces.styles';
import { useAddress } from '../../../utils/customHooks/useAddress';
import { convertPhoneNumberCleanly } from '../../../utils/helper';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

export interface IProfileFormData {
  firstName: string;
  lastName: string;
  position: string;
  phone: string;
  internationalPhone: string;
  email: string;
  addressId: string;
  addressLookup: string;
  location: string;
  unit: string;
  streetNumber: string;
  road: string;
  shire: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const Profile = () => {
  const { appWidth, selectedVenueObject, dispatch } = useContext(LayoutContext);
  const {
    errors,
    control,
    setError,
    register,
    handleSubmit,
    getValues,
    reset,
  } = useForm<IProfileFormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { getAddressErrors } = useAddress();
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const { data, loading } = useMeQuery();
  const [errorMessage, setErrorMessage] = useState('');
  const [updateUserMutation, updateUser] = useUpdateUserMutation();

  const onSubmit = async (formData) => {
    setErrorMessage('');
    delete formData.confirmPassword;
    const phone = convertPhoneNumberCleanly(formData.phone);

    const inputData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      venueId: selectedVenueObject?.id!,
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword,
      phone,
      internationalPhone: formData.internationalPhone,
      position: formData.position,
      address: formData.country
        ? {
            addressId: formData.addressId,
            location: formData.location,
            unit: formData.unit,
            streetNumber: formData.streetNumber,
            road: formData.road,
            city: formData.city,
            shire: formData.shire,
            state: formData.state,
            country: formData.country,
            postcode: formData.postcode,
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            addressLookup: formData.addressLookup,
            lat: formData.lat,
            lng: formData.lng,
          }
        : undefined,
      totalActiveSeconds: getTotalActiveSeconds(),
    };

    try {
      const response = await updateUserMutation({
        variables: {
          input: inputData,
        },
        refetchQueries: ['useMeQuery'],
      });

      if (response.data?.updateUser.successful) {
        dispatch({ type: 'TOGGLE_SLIDER' });
        return;
      }

      if (response.data?.updateUser.error?.toLowerCase().includes('password')) {
        setError('currentPassword', { message: 'Incorrect password' });
      } else if (
        response.data?.updateUser.error?.toLowerCase().includes('email')
      ) {
        setError('email', { message: 'This email is already in use.' });
      } else {
        setErrorMessage(response.data?.updateUser.error ?? '');
      }
    } catch (err) {
      setErrorMessage('Something went wrong');
      console.log(err);
    }
  };

  if (loading || !data?.me?.user) {
    return <LogoLoading size={60} />;
  }

  const {
    firstName,
    lastName,
    email,
    phone,
    internationalPhone,
    position,
    address,
  } = data.me.user;

  const ctaButton =
    appWidth !== 0 && appWidth < theme.mQ.tablet ? (
      <SmallButton
        color="secondary"
        onClick={handleSubmit(onSubmit)}
        disabled={updateUser.loading}
      >
        <DotsLoading
          text={(loading) => (loading ? 'Saving' : 'Save')}
          isLoading={updateUser.loading}
          size="small"
          lineHeight={10}
          noMargin
        />
      </SmallButton>
    ) : (
      <Button
        color="secondary"
        onClick={handleSubmit(onSubmit)}
        disabled={updateUser.loading}
      >
        <DotsLoading
          text={(loading) => `${loading ? 'Saving' : 'Save'} Account`}
          isLoading={updateUser.loading}
          size="small"
          lineHeight={10}
          noMargin
        />
      </Button>
    );

  return (
    <LayoutPage
      backButton
      backButtonCTA={() => dispatch({ type: 'TOGGLE_SLIDER' })}
      align="center"
      withLine
      heading="Edit Account Details"
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button
                color="default"
                asCircle
                inversed
                onClick={() => dispatch({ type: 'TOGGLE_SLIDER' })}
              >
                x
              </Button>,
              ctaButton,
            ]
          : [
              <Button
                color="default"
                inversed
                onClick={() => dispatch({ type: 'TOGGLE_SLIDER' })}
              >
                Cancel
              </Button>,
              ctaButton,
            ]
      }
    >
      <Container>
        <Header>
          {firstName} {lastName} Account Details
        </Header>

        {errorMessage && (
          <Toast
            type="error"
            heading="Account update error"
            message={errorMessage}
            addMargin
          />
        )}

        <Form>
          <Input
            ref={register}
            label="firstName"
            name="firstName"
            defaultValue={firstName}
            errorText={errors.firstName?.message}
          />
          <Input
            ref={register}
            label="LastName"
            name="lastName"
            defaultValue={lastName}
            errorText={errors.lastName?.message}
          />
          <Input
            ref={register}
            label="Email"
            name="email"
            defaultValue={email}
            errorText={errors.email?.message}
          />
          <Controller
            as={<Input />}
            type="number"
            name="phone"
            label="mobile"
            control={control}
            defaultValue={phone}
            errorText={errors.phone?.message}
          />
          <SelectList
            control={control}
            name="position"
            label="Industry Position"
            options={mockData.jobPositions}
            defaultValue={position ?? ''}
            errorText={errors.position?.message}
          />
          <Group>
            <H4 className="groupHeading noPaddingBottom">Location</H4>
            <Address
              label="Address"
              control={control}
              errors={getAddressErrors(errors)}
              getValues={getValues}
              reset={reset}
              initialAddressValues={address}
            />
          </Group>
          <Group>
            <H4 className="groupHeading">Password</H4>
            <Input
              ref={register}
              label="Current Password"
              name="currentPassword"
              type="password"
              errorText={errors.currentPassword?.message}
              defaultValue=""
            />
            <Input
              ref={register}
              label="New Password"
              name="newPassword"
              type="newPassword"
              errorText={errors.newPassword?.message}
            />
            <Input
              ref={register}
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              errorText={errors.confirmPassword?.message}
            />

            <Input
              ref={register}
              name="internationalPhone"
              className="hiddenInput"
              defaultValue={internationalPhone}
            />
          </Group>
        </Form>
      </Container>
      <CTAButton>{ctaButton}</CTAButton>
    </LayoutPage>
  );
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  position: Yup.string().required('Position is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Please check email format'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), undefined],
    'New Passwords must match'
  ),
});

export default Profile;
