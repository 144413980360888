import styled from 'styled-components/macro';
import { H1 } from '../../shared/typefaces/Typefaces.styles';

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const Heading = styled(H1)`
  margin-bottom: 30px !important;
`;

export const Logo = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;
