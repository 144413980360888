import React, { FC, useContext, memo, useRef } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { theme } from '../../../../../styles/theme';
import { Edit, Email, RecipeBook } from '../../../../shared/icons';
import { Label, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { HeaderRow, Col, Row, Icon, Table } from './IngredientList.styles';
import { Card, Content, Header } from '../../../../shared/card';
import {
  convertCostCleanly,
  convertGramsCleanly,
  convertNumberCleanly,
} from '../../../../../utils/helper/numbers';
import useResizeDimensions from '../../../../../utils/customHooks/useResizeDimensions';
import { LayoutColumn } from '../../../../shared/layout';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { IIngredientProps, ISupplierDataProps } from './IngredientList';
import { Button } from '../../../../shared/button';
import { AnchorButton } from '../../../../shared/button/Button.styles';
import { useResendEmailConfirmationWithAuthMutation } from '../../../../../generated/graphql';
// import { useGeneratePDF } from '../../../../shared/pdf/useGeneratePdf';
import { isEmpty } from '../../../../../utils/helper';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';

interface IInventorySectionProps {
  supplierData: ISupplierDataProps;
  isLastSection: boolean;
}

export const InventorySection: FC<IInventorySectionProps> = ({
  supplierData,
  isLastSection,
}) => {
  const {
    appWidth,
    toolTips,
    account,
    user,
    selectedVenueObject,
    dispatch,
  } = useContext(LayoutContext);
  // const { genPurchaseOrder } = useGeneratePDF();
  const [
    resendEmailConfirmationWithAuthMutation,
  ] = useResendEmailConfirmationWithAuthMutation();

  const pageWidthRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeDimensions(pageWidthRef);
  const supplierRef = useRef<HTMLLIElement>(null);
  const supplierDimensions = useResizeDimensions(supplierRef);
  const totalItemsRef = useRef<HTMLLIElement>(null);
  const totalItemsDimensions = useResizeDimensions(totalItemsRef);
  const ingredientPurchaseCostRef = useRef<HTMLLIElement>(null);
  const ingredientPurchaseCostDimensions = useResizeDimensions(
    ingredientPurchaseCostRef
  );
  const {
    totalItems,
    ingredientPurchaseCost,
    ingredientSupplier,
  } = toolTips.stocktake;
  const filteredIngredientsToOrder = supplierData?.ingredients.filter(
    (i) => i.stockDifference > 0
  );

  const ingredientsToOrderCheck = filteredIngredientsToOrder.length !== 0;
  const supplierDisplayName = supplierData.supplier?.displayName || 'Supplier';
  const subHeading = ingredientsToOrderCheck
    ? 'Ordered by most recently completed stocktake at the top'
    : `Currently there are no ingredients that need to be ordered from ${supplierDisplayName}`;

  const onTotalItemsClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { supplier: !ingredientSupplier },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Total Items',
        content: `The total amount of items you will need to order to top up your stock levels to the required inventory amount`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: supplierDimensions.top,
        xAxis: supplierDimensions.width,
      },
    });
  };

  const onTotalUnitsClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { totalItems: !totalItems },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Total Units',
        content: `The total amount of units you will need to order to top up your stock levels to the required total items`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: totalItemsDimensions.top,
        xAxis: totalItemsDimensions.width,
      },
    });
  };

  const onPurchaseCostClick = () => {
    dispatch({
      type: 'SET_STOCKTAKE_TOOL_TIPS',
      payload: { ingredientPurchaseCost: !ingredientPurchaseCost },
    });
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: 'Ingredient Purchase Cost',
        content: `What the total cost of the ingredient is when purchased`,
        buttonText: 'Got it',
        pageWidth: width,
        yAxis: ingredientPurchaseCostDimensions.top,
        xAxis: ingredientPurchaseCostDimensions.width,
      },
    });
  };

  const handleEditIngredient = (
    ingredientId: string,
    ingredientProductId: string
  ) => {
    dispatch({
      type: 'SELECT_INGREDIENT',
      payload: ingredientId,
    });
    dispatch({
      type: 'SET_INGREDIENT_USER_EVENT',
      payload: 'edit',
    });
    dispatch({
      type: 'SELECT_INGREDIENT_PRODUCT',
      payload: ingredientProductId,
    });
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    }
  };

  // const handlePdfToSupplier = () => {
  //   genPurchaseOrder(supplierData);
  // };

  const handleUserConfirmationEmail = () => {
    dispatch({
      type: 'SET_OVERLAY',
      payload: {
        ...overlayConstants.confirmEmail,
        buttonCTA: async () => {
          const response = await resendEmailConfirmationWithAuthMutation();
          if (response.data?.resendEmailConfirmationWithAuth.successful) {
            dispatch({
              type: 'SET_POPUP',
              payload: {
                type: 'success',
                heading: 'Email Sent',
                message: `Confirmation Email Sent Successfully`,
              },
            });
          }
        },
      },
    });
  };

  const handleUpdateSupplierEmail = () => {
    dispatch({
      type: 'SET_OVERLAY',
      payload: {
        ...overlayConstants.confirmSupplierEmail,
        buttonCTA: async () => {
          dispatch({
            type: 'SELECT_SUPPLIER',
            payload: supplierData.supplier.id,
          });
          dispatch({ type: 'SET_SUPPLIER_USER_EVENT', payload: 'edit' });
          dispatch({ type: 'TOP_SLIDER_PAGE', payload: 'supplier' });
        },
      },
    });
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <HeaderRow $loading={false}>
          <Col>
            <Label>Ingredients</Label>
          </Col>
          <Col>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow $loading={false}>
          <Col>
            <Label>Ingredients</Label>
          </Col>
          <Col onClick={onTotalUnitsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Label className="hasToolTipIcon">Purchase Cost</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow $loading={false}>
          <Col>
            <Label>Ingredients To Order</Label>
          </Col>
          <Col onClick={onTotalUnitsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow $loading={false}>
          <Col>
            <Label>Ingredients To Order</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={supplierRef}>
            <Label className="hasToolTipIcon">Total Items</Label>
          </Col>
          <Col onClick={onTotalUnitsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow $loading={false}>
          <Col />
          <Col>
            <Label>Ingredients To Order</Label>
          </Col>
          <Col onClick={onTotalItemsClick} ref={supplierRef}>
            <Label className="hasToolTipIcon">Total Items</Label>
          </Col>
          <Col onClick={onTotalUnitsClick} ref={totalItemsRef}>
            <Label className="hasToolTipIcon">Total Units</Label>
          </Col>
          <Col onClick={onPurchaseCostClick} ref={ingredientPurchaseCostRef}>
            <Label className="hasToolTipIcon">Purchase Cost | Grams</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const getTableRows = (col: IIngredientProps, idx) => {
    const unit = (
      <Col>
        <Span>{convertNumberCleanly(col.totalUnits)}</Span>
      </Col>
    );

    if (appWidth !== 0 && appWidth < theme.mQ.mobileM) {
      return (
        <Row
          key={idx}
          onClick={() => handleEditIngredient(col.id, col.ingredientProductId)}
        >
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          {unit}
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <Row
          key={idx}
          onClick={() => handleEditIngredient(col.id, col.ingredientProductId)}
        >
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          {unit}
          <Col>
            <Span color="grey">
              {convertCostCleanly(col.size?.productCost)}
            </Span>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <Row
          key={idx}
          onClick={() => handleEditIngredient(col.id, col.ingredientProductId)}
        >
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.stockDifference}</Span>
          </Col>
          {unit}
          <Col>
            <LayoutColumn
              className="layoutColumn"
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
            >
              <Span color="grey">
                {convertCostCleanly(col.size?.productCost)}
              </Span>
              <Span>|</Span>
              <Span color="grey" className="last">
                {convertGramsCleanly(col.size?.productGrams)}
              </Span>
            </LayoutColumn>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row
          key={idx}
          onClick={() => handleEditIngredient(col.id, col.ingredientProductId)}
        >
          <Col />
          <Col>
            <Span color="grey">{col.displayName}</Span>
          </Col>
          <Col>
            <Span>{col.stockDifference}</Span>
          </Col>
          {unit}
          <Col>
            <LayoutColumn
              colOneWidth="50px"
              colTwoWidth="1px"
              colThreeWidth="50px"
              className="layoutColumn"
            >
              <Span color="grey">
                {convertCostCleanly(col.size?.productCost)}
              </Span>
              <Span>|</Span>
              <Span color="grey" className="last">
                {convertGramsCleanly(col.size?.productGrams)}
              </Span>
            </LayoutColumn>
          </Col>
          <Col>
            <Icon>
              <Edit size="small" color="grey" faded />
            </Icon>
          </Col>
        </Row>
      );
    }
  };

  let noTotalUnits = false;
  const ingredientsToOrder = supplierData?.ingredients.map((i) => {
    if (isNaN(i.totalUnits) || isEmpty(i.totalUnits)) {
      noTotalUnits = true;
    }
    const unitString = i.totalUnits === 1 ? 'unit' : 'units';
    return `${i.totalUnits} ${unitString} of ${i.size.unitAmount} ${i.size.unitType} ${i.displayName} %0D%0A`;
  });

  const emailButton =
    noTotalUnits || !ingredientsToOrderCheck ? null : !user.isEmailConfirmed ||
      !supplierData.supplier.email ? (
      <Button
        key="checkButton"
        color="default"
        inversed
        asCircle
        onClick={() =>
          !user.isEmailConfirmed
            ? handleUserConfirmationEmail()
            : handleUpdateSupplierEmail()
        }
      >
        <Email color="grey" size="small" />
      </Button>
    ) : (
      <AnchorButton
        key="mailToButton"
        color="default"
        target="_blank"
        inversed
        asCircle
        href={`mailto:${
          supplierData.supplier.email || ''
        }?&subject=Order from ${user.firstName} ${
          selectedVenueObject?.displayName && '@'
        } ${
          selectedVenueObject?.displayName || ''
        } Venue&body=Hi Team %0D%0A%0D%0A Can I please order these items below %0D%0A%0D%0A __ __ __ __ __ __ __ __ __ __ __ __ __ __ __ %0D%0A%0D%0A ${ingredientsToOrder.join(
          ''
        )} %0D%0A __ __ __ __ __ __ __ __ __ __ __ __ __ __ __ %0D%0A%0D%0APlease let me know if you haven't recieved payment for my last order%0D%0AHave a great day,%0D%0A%0D%0ACheers,%0D%0A${
          user.firstName
        }`}
      >
        <Email color="grey" size="small" />
      </AnchorButton>
    );

  // const pdfButton = noTotalUnits ? null : (
  //   <Button
  //     key="pdfButton"
  //     color="default"
  //     inversed
  //     asCircle
  //     onClick={handlePdfToSupplier}
  //   >
  //     <Share color="grey" size="small" />
  //   </Button>
  // );

  return (
    <Card
      ref={pageWidthRef}
      withCardLink={isLastSection ? false : true}
      withBackground={!ingredientsToOrderCheck}
    >
      <Header
        icon={<RecipeBook size="small" />}
        heading={supplierDisplayName}
        subHeading={subHeading}
        button={[emailButton]}
        toolTip={{
          type: 'SET_STOCKTAKE_TOOL_TIPS',
          heading: `${supplierDisplayName} Supplier`,
          content: `This is a list of all the ingredients you currently order from ${supplierDisplayName}. Click ingredient to update supplier details`,
        }}
      />
      <Content fullWidth>
        <Table>
          {ingredientsToOrderCheck && getTableHeader()}
          {ingredientsToOrderCheck
            ? filteredIngredientsToOrder?.map((col, idx) =>
                getTableRows(col, idx)
              )
            : null}
        </Table>
      </Content>
    </Card>
  );
};

export default memo(InventorySection);
