import React, { FC, useState } from 'react';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Card, Content, Header } from '../../../../shared/card';
import { Apple } from '../../../../shared/icons';
import { HighlightsItem, HighlightsRow } from './Recipes.styles';
import { Button } from '../../../../shared/button';
import { IStatsProps } from '../dashboard.types';
import { convertCostCleanly, roundTo } from '../../../../../utils/helper';

export const Recipes: FC<IStatsProps> = ({ stats }) => {
  const [showIngredientList, setShowIngredientList] = useState(false);

  const ctaButtons = (
    <Button
      color="default"
      inversed
      onClick={() => setShowIngredientList(!showIngredientList)}
    >
      Monthly Stats
    </Button>
  );

  return (
    <Card withCardLink>
      <Header
        icon={<Apple size="small" />}
        heading="Recipes"
        subHeading={`Total Recipes: ${stats?.totalRecipes}`}
        button={ctaButtons}
      />
      <Content fullWidth>
        <HighlightsRow>
          <HighlightsItem>
            <Span>
              {stats?.avgVenueRecipes && roundTo(stats.avgVenueRecipes)}
            </Span>
            <Span>Avg Venue Recipes</Span>
          </HighlightsItem>
          <HighlightsItem>
            <Span>{convertCostCleanly(stats?.totalRecipeRevenue)}</Span>
            <Span>Total Revenue</Span>
          </HighlightsItem>
          <HighlightsItem>
            <Span>{convertCostCleanly(stats?.totalRecipeProfit)}</Span>
            <Span>Total Profit</Span>
          </HighlightsItem>
        </HighlightsRow>
      </Content>
    </Card>
  );
};

export default Recipes;
