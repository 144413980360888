import React from 'react';
import { IIconProps } from '../icon.types';
import { IconStyled } from '../Icon.styles';

const Trolley: React.FC<IIconProps> = ({ color, size, faded }) => {
  return (
    <IconStyled size={size} viewBox="0 0 490 490" color={color} faded={faded}>
      <g>
        <path
          d="M262.166,405.649l-89.71-373.188L70.428,0l-6.196,19.479l91.051,28.968l54.934,228.534l-30.105,78.727
		c-2.424-0.265-4.883-0.412-7.377-0.412c-37.142,0-67.357,30.216-67.357,67.357c0,37.131,30.216,67.347,67.357,67.347
		c37.137,0,67.352-30.216,67.352-67.347c0-8.475-1.589-16.581-4.458-24.059l2.583-5.148l8.786,36.552l178.551-40.085l-4.481-19.938
		L262.166,405.649z M172.736,469.564c-25.87,0-46.92-21.045-46.92-46.91c0-25.875,21.051-46.921,46.92-46.921
		c25.87,0,46.915,21.046,46.915,46.921C219.651,448.519,198.605,469.564,172.736,469.564z M223.096,377.995
		c-6.377-7.184-14.261-12.993-23.155-16.938l18.718-48.951l12.145,50.523L223.096,377.995z"
        />
        <path
          d="M425.769,353.011l-45.752-195.136l-36.999,8.679l-16.702-71.206L211.653,122.23l62.452,266.346L425.769,353.011z
		 M236.221,137.459l74.867-17.543l12.01,51.221l-74.867,17.552L236.221,137.459z M364.788,182.443l36.423,155.34l-111.877,26.226
		l-36.423-155.331L364.788,182.443z"
        />
      </g>
    </IconStyled>
  );
};

export default Trolley;
