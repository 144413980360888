const API_URLS = {
  local: 'http://localhost:3000/local',
  'local-with-dev-db': 'http://localhost:3000/dev',
  'local-with-uat-db': 'http://localhost:3000/uat',
  'local-with-prod-db': 'http://localhost:3000/prod',
  dev: 'https://4axyyi3dzd.execute-api.ap-southeast-2.amazonaws.com/dev',
  uat: 'https://w0za76x1h0.execute-api.ap-southeast-2.amazonaws.com/uat',
  prod: 'https://iltlsef3kc.execute-api.ap-southeast-2.amazonaws.com/prod',
};

type Stage =
  | 'dev'
  | 'uat'
  | 'prod'
  | 'local'
  | 'local-with-dev-db'
  | 'local-with-uat-db'
  | 'local-with-prod-db';

const getStage = (location: typeof window.location): Stage | undefined => {
  const stageMap: {
    [hostname: string]: Stage;
  } = {
    '3001': 'local',
    '3005': 'dev',
    '3006': 'uat',
    '3020': 'prod',
    '3030': 'local-with-dev-db',
    '3031': 'local-with-uat-db',
    '3032': 'local-with-prod-db',
    'dev.reciperevenue.com.au': 'dev',
    'uat.reciperevenue.com.au': 'uat',
    'reciperevenue.com.au': 'prod',
    // Ready for reciperevenue.com
    'dev.reciperevenue.com': 'dev',
    'uat.reciperevenue.com': 'uat',
    'reciperevenue.com': 'prod',
  };

  if (location.hostname === 'localhost') {
    return stageMap[location.port];
  }

  return stageMap[location.hostname];
};

const RR_STAGE: Stage | undefined =
  typeof window === 'undefined'
    ? (process.env.RR_STAGE as Stage | undefined)
    : getStage(window.location);

export const CURRENT_ENV = RR_STAGE || 'local';

export const API_URL = API_URLS[CURRENT_ENV];

export const constants = {
  metric: {
    ml: {
      d: 'mL',
      p: 'mLs',
      s: 'mL',
    },
    gram: {
      d: 'gram',
      p: 'grams',
      s: 'g',
    },
  },
};

export const stripeCountryCodes = [
  { country: 'Australia', code: 'AU' },
  { country: 'Austria', code: 'AT' },
  { country: 'Belgium', code: 'BE' },
  { country: 'Brazil', code: 'BR' },
  { country: 'Bulgaria', code: 'BG' },
  { country: 'Canada', code: 'CA' },
  { country: 'Croatia', code: 'HR' },
  { country: 'Cyprus', code: 'CY' },
  { country: 'Czech Republic', code: 'CZ' },
  { country: 'Denmark', code: 'DK' },
  { country: 'Estonia', code: 'EE' },
  { country: 'Finland', code: 'FI' },
  { country: 'France', code: 'FR' },
  { country: 'Germany', code: 'DE' },
  { country: 'Gibraltar', code: 'GI' },
  { country: 'Greece', code: 'GR' },
  { country: 'Hong Kong', code: 'HK' },
  { country: 'Hungary', code: 'HU' },
  { country: 'India', code: 'IN' },
  { country: 'Indonesia', code: 'ID' },
  { country: 'Ireland', code: 'IE' },
  { country: 'Italy', code: 'IT' },
  { country: 'Japan', code: 'JP' },
  { country: 'Latvia', code: 'LV' },
  { country: 'Liechtenstein', code: 'LI' },
  { country: 'Lithuania', code: 'LT' },
  { country: 'Luxembourg', code: 'LU' },
  { country: 'Malaysia', code: 'MY' },
  { country: 'Malta', code: 'MT' },
  { country: 'Mexico ', code: 'MX' },
  { country: 'Netherlands', code: 'NL' },
  { country: 'New Zealand', code: 'NZ' },
  { country: 'Norway', code: 'NO' },
  { country: 'Poland', code: 'PL' },
  { country: 'Portugal', code: 'PT' },
  { country: 'Romania', code: 'RO' },
  { country: 'Singapore', code: 'SG' },
  { country: 'Slovakia', code: 'SK' },
  { country: 'Slovenia', code: 'SI' },
  { country: 'Spain', code: 'ES' },
  { country: 'Sweden', code: 'SE' },
  { country: 'Switzerland', code: 'CH' },
  { country: 'Thailand', code: 'TH' },
  { country: 'United Arab Emirates', code: 'AE' },
  { country: 'United Kingdom', code: 'GB' },
  { country: 'United States', code: 'US' },
];
