import { useContext } from 'react';
import { useLogoutMutation } from '../../generated/graphql';
import { useHistory } from 'react-router-dom';
import { setAccessToken, useAccessToken } from '../accessToken';
import { LayoutContext } from '../../contexts/layoutContext';

export const useAuth = () => {
  const history = useHistory();
  const [accessToken] = useAccessToken();
  const [logout, { client }] = useLogoutMutation();
  const { dispatch } = useContext(LayoutContext);

  const handleLogout = async ({ redirect }: { redirect?: boolean }) => {
    if (redirect) history.push('/');
    if (accessToken) {
      try {
        const response = await logout();
        if (response.data?.logout.successful) {
          setAccessToken('');
          window.localStorage.clear();
          client?.resetStore();
          dispatch({
            type: 'RESET_STORE',
            payload: {
              justLoggedOut: true,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return { handleLogout };
};
