import React, { FC, useContext, useRef, useState } from 'react';
import { Container } from './GoalJourney.styles';
import { RecipesProfitGraph } from '..';
import { Card, Content, Header } from '../../../../shared/card';
import { ChartUp } from '../../../../shared/icons';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { Button } from '../../../../shared/button';
import { convertCostCleanly, isNegative } from '../../../../../utils/helper';
import {
  RecipesQuery,
  RecipesQueryVariables,
  useVenueProfitTrendQuery,
} from '../../../../../generated/graphql';
import { DotsLoading } from '../../../../shared/loading';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import * as ApolloClient from '@apollo/client';
import { AnchorButton } from '../../../../shared/button/Button.styles';

interface GoalJourneyProps {
  data: ApolloClient.QueryResult<RecipesQuery, RecipesQueryVariables>;
  withCardLink?: boolean;
  showGraph?: boolean;
}

export const GoalJourney: FC<GoalJourneyProps> = ({
  data,
  withCardLink = false,
  showGraph = false,
}) => {
  const { selectedVenueObject } = useContext(LayoutContext);
  const [showGoalJourney, setShowGoalJourney] = useState(showGraph);
  const pageWidthRef = useRef<HTMLDivElement>(null);

  const originalProfit =
    data.data?.venueRecipes.recipes.reduce((accumulator, recipe) => {
      const recipeHasOriginalProfit =
        recipe.originalRecipeProfit && recipe.originalServes;

      const originalProfitPerServe = recipeHasOriginalProfit
        ? recipe.originalRecipeProfit / recipe.originalServes
        : 0;

      return accumulator + originalProfitPerServe;
    }, 0) || 0;

  const currentProfitPerServe =
    data.data?.venueRecipes.recipes.reduce(
      (accumulator, recipe) =>
        accumulator + recipe.recipeProfit / recipe.serves,
      0
    ) || 0;

  // Profit change over all recipes
  const increasedProfit =
    data.data?.venueRecipes.recipes.reduce((accumulator, recipe) => {
      const total = accumulator + recipe.recipeProfitIncreasePerServe;
      return total;
    }, 0) || 0;

  const venueProfitTrend = useVenueProfitTrendQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const isLoading = venueProfitTrend.loading || data.loading;

  const handleCtaButtonClick = () => {
    setShowGoalJourney(!showGoalJourney);
  };

  const hasMadeProgress =
    increasedProfit !== 0 ||
    (venueProfitTrend?.data?.venue?.userVenue?.venue?.venueProfitTrends &&
      venueProfitTrend?.data?.venue?.userVenue?.venue?.venueProfitTrends
        ?.length > 1);
  const increaseDescreased = isNegative(
    venueProfitTrend.data?.venue?.userVenue?.venue.recipeProfitIncreasePerYear!
  )
    ? 'decreased'
    : 'increased';

  const ctaButton = hasMadeProgress ? (
    <Button
      color="secondary"
      inversed={showGoalJourney}
      onClick={handleCtaButtonClick}
    >
      {showGoalJourney ? 'Hide Journey' : 'See Journey'}
    </Button>
  ) : (
    <AnchorButton href="add-recipe" color="secondary">
      Add Recipe
    </AnchorButton>
  );

  const subHeading = hasMadeProgress ? (
    <Span fontSize="small" color="faded">
      Total Recipe Profit Per Serve started at{' '}
      <Span fontSize="small" color="black">
        {convertCostCleanly(originalProfit)}
      </Span>
      , currently it's{' '}
      <Span fontSize="small" color="black">
        {convertCostCleanly(currentProfitPerServe)}
      </Span>
      , a profit {increaseDescreased} of{' '}
      <Span fontSize="small" color="black">
        {convertCostCleanly(increasedProfit)}
      </Span>{' '}
      across all recipe serves. Based on your yearly sales you have{' '}
      {increaseDescreased} profitability by{' '}
      <Span fontSize="small" color="black">
        {convertCostCleanly(
          venueProfitTrend.data?.venue?.userVenue?.venue
            .recipeProfitIncreasePerYear
        )}
      </Span>
    </Span>
  ) : (
    'Start optimising for profitability & see your profit journey below!'
  );

  return (
    <Container>
      <Card
        ref={pageWidthRef}
        className="journeyCard"
        withCardLink={withCardLink}
      >
        <Header
          icon={<ChartUp size="small" />}
          heading={'$5,000 in 5 Hours Journey'}
          subHeading={subHeading}
          button={ctaButton}
        />
        {showGoalJourney && (
          <Content fullWidth>
            <DotsLoading
              isLoading={isLoading}
              size="large"
              lineHeight={10}
              color="default"
            />
            {!isLoading && hasMadeProgress && venueProfitTrend.data && (
              <RecipesProfitGraph data={venueProfitTrend} />
            )}
          </Content>
        )}
      </Card>
    </Container>
  );
};
